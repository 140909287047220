import styled from '@emotion/styled';
import { ListItemText } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { Props } from './interface';

export const ListItemTextStyled = styled(ListItemText)(({ theme, size }: WCLThemeProps & Props) => (
  {
    // these apply to all variants
    fontFamily: theme?.ourTheme.typography.fontFamily,
    // if the text overflows its container, it will be cut off and an ellipsis shown
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    // TODO: fontSize in the sections below does not function
    ...(size === 'small' && {
      paddingLeft: '7px',
      paddingRight: '7px',
      margin: '2px',
      fontSize: '10px',
    }),
    ...(size === 'medium' && {
      paddingLeft: '9px',
      paddingRight: '9px',
      margin: '3px',
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem`,
    }),
    ...(size === 'large' && {
      paddingLeft: '12px',
      paddingRight: '12px',
      margin: '4px',
      fontSize: '16px',
    }),
  }
));
