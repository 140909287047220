import { graphql } from 'babel-plugin-relay/macro';

export const StuSettingsUpdatebasicMutation = graphql`
mutation StuSettingsUpdatebasicMutation(
  $password: String!
  $timezone: String!
  $age: Int!
) {
  stu_settings_updatebasic(
    password: $password
    timezone: $timezone
    age: $age
  ) {
    success
    errors
    # here we return all of the db values in the users table that
    # might have been edited, along with the relay global id
    user {
      id
      dob
      user_timezone
      password_set
      timezone_set
    }
  }
}`;
