import { graphql } from 'babel-plugin-relay/macro';

// mutation call for getting hours remaining

export const HoursReaminingMutation = graphql`
mutation HoursReaminingMutation {
  stu_orders_totalhrsremaining {
    data {
      langId
      totalHours
      maxExpiry
    }
  }
}`;
