import {
  Box, styled, WCLThemeProps,
} from '@languageconvo/wcl';

// a container for our small card color components that are used in a few components on the
// purchase main laout
export const SmallcardMaxWidthContain = styled(Box)(({
  theme,
}: WCLThemeProps) => ({
  // set a maximum width so the small cards never get really wide
  width: '320px',
  maxWidth: '320px',

  // width on xs
  [theme!.breakpoints.only('xs')]: {
    width: '380px',
    maxWidth: '380px',
  },
}));
