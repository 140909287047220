import { useRef, useEffect } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-light-react';
import { LottiePlayerProps } from './interface';

/**
 * Our main LottiePlayer component. See:
 *
 * https://lottiereact.com/
 *
*/

export const LottiePlayer = (props: LottiePlayerProps) => {
  // creating the reference for the lottie player because we need to set the playback speed
  const lottieRef = useRef() as React.MutableRefObject<LottieRefCurrentProps>;

  // destructuring the props so we can pass them in lottie player
  const {
    loop = true,
    playbackSpeed = 1,
    animationSrc,
    animationHeight = 200,
    cp,
  } = props;
  useEffect(() => {
    // checking if the current reference of lottie is present
    if (lottieRef.current && playbackSpeed) {
      // setting the playbackspeed for lottie
      lottieRef.current.setSpeed(playbackSpeed);
    }
  }, [lottieRef, playbackSpeed]);

  return (
    <Lottie
      style={{ height: animationHeight }}
      animationData={animationSrc}
      lottieRef={lottieRef}
      loop={loop}
      {...cp}
    />
  );
};
