import { graphql } from 'babel-plugin-relay/macro';

export const UsersSettingsQuery = graphql`
  query UsersSettingsQuery {
    ...UsersSettingsFragment
  }  
`;

export const UsersSettingsFragment = graphql`
  fragment UsersSettingsFragment on query_root {
    users_connection {
      edges {
        node {
          groupname
          pk
          first_name
          last_name
          email
          country_id_num
          password_set
          timezone_set
          user_timezone
          hour_cycle
          dob
          email_uuid
          relayAge
        }
      }
    }
  }
`;
