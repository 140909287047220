import {
  styled, Box, WCLThemeProps,
} from '@languageconvo/wcl';

export const LoadingBox = styled(Box)(({
  theme,
}: WCLThemeProps) => ({
  // for small screens
  height: '157.5px',

  // for larger screens
  [theme!.breakpoints.up('md')]: {
    height: '96px',
  }
}));
