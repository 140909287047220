import React from 'react';
import {
  Card, CardContent, Grid2, Grid2Ct, Skeleton,
} from '@languageconvo/wcl';

export const TeacherProfileLoader = () => (
  <SingleCard />
);

// a single card element that represents teacher cards
const SingleCard = () => (
  <Grid2 xs={12}>
    <Card cp={{ sx: { minHeight: '90vh' } }}>
      <CardContent>
        <Grid2Ct>
          {/* left side (image) */}
          <Grid2 xs={8} xsOffset={2} sm={6} smOffset={3} md={4} mdOffset={0} xl={3}>
            <Skeleton variant="rectangular" height={160} />
          </Grid2>

          {/* right side */}
          <Grid2 xs={12} md={8} xl={8} xlOffset={1}>
            <Skeleton variant="text" />
            <Skeleton variant="text" cp={{ sx: { mt: 1 } }} />
            <Skeleton variant="text" cp={{ sx: { mt: 1 } }} />
            <Skeleton variant="text" cp={{ sx: { mt: 1 } }} />
            <Skeleton variant="text" cp={{ sx: { mt: 1 } }} />
          </Grid2>
        </Grid2Ct>
      </CardContent>
    </Card>
  </Grid2>
);
