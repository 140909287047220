import React, { useEffect } from 'react';
import { useClientQuery, useFragment } from 'react-relay';
import { LessonDetailFragment } from '../relay/LessonDetail';
import { RoomLayoutFragment } from '../relay/RoomLayout';
import { getSessionToConnectWith } from '../../../../utils/getSessionToConnectWith';
import { useVonage } from '../hooks/useVonage';
import { RoomLayoutFragment$key } from '../relay/__generated__/RoomLayoutFragment.graphql';
import { MicDetailsFragment, MicDetailsQuery } from '../../../../common/relay/queries/MicDetails';
import { MicDetailsFragment$key } from '../../../../common/relay/queries/__generated__/MicDetailsFragment.graphql';

interface Props {
  roomLayoutRef: RoomLayoutFragment$key;
  initialClassroomDataRef: any;
  hasLessonStarted: boolean;
  hasLessonEnded: boolean;
}

/** responsible for managing the vonage session
 * This is the main component which is responsible for managing the vonage session in react's
 * way, for example, via this manager, session connection/disconnection become automatic
 * via the useeffect hooks...
 */
export const VonageSessionManager = ({
  roomLayoutRef,
  initialClassroomDataRef,
  hasLessonStarted,
  hasLessonEnded,
}: Props) => {
  // #region general

  const {
    initializeSession, vonageSession, connectToSession, sessionConnected, publishAudioStream,
    disconnectFromSession, subscribeToStream, previousRoomLayoutData, setPreviousRoomLayoutData,
    isMediaStopped, setIsMediaStopped, audioPublisher
  } = useVonage();

  const initialRoomData = useFragment(LessonDetailFragment, initialClassroomDataRef);
  const roomLayoutData = useFragment(RoomLayoutFragment, roomLayoutRef);

  // room layout data
  // VERY IMPORTANT: remember that rawLayoutData is a string that needs to be JSON.parsed, and
  // the result is an object. Don't use that object in useEffect dependencies otherwies you'll
  // get strange behavior -- objects should not be used in useEffect dependencies, only
  // singular values
  const roomLayoutNode = roomLayoutData.appt_group_connection.edges[0].node;
  const rawLayoutData = roomLayoutNode.appt_group_layout!.layout;

  // here we are getting the uuid of logged-in student
  const studentReservationNode = roomLayoutNode.appt_group_reservations[0];
  const studentInfo = studentReservationNode.users.user_publicinfo;
  const studentId = studentInfo!.uuid;

  // #endregion

  // #region microphone from relay

  // reading mic details from relay
  const response: any = useClientQuery(MicDetailsQuery, {});
  const fragmentR: MicDetailsFragment$key = response.RelayAppSettings;
  const micData = useFragment(MicDetailsFragment, fragmentR);
  // we need to connect the user with audio device so user can communicate without
  // any intereption so we are connecting with current audio device (this will
  // the deivce present at the 0 index of all devices) for further details see
  // comments in micManager.ts;
  const currentAudioDevice = micData.microphone.current.micId;

  // #endregion

  // #region for Publishing and Subsribing streams

  /* on session connect, we're starting subscribing and publishing student's streams
     based on roomView,
     * roomView 1 shows teacher only mode here student will subscibe to teacher's stream only
     * roomView 2 show Group Chat Mode here we are subscribing(to other student's and teacher)
     * to streams as well as publishing student streams so everyone can communicate
     * with each other in groupchat
  */
  useEffect(() => {
    const classRoomLayoutData = JSON.parse(rawLayoutData);

    if (sessionConnected) {
      // student is only able to speak in group chat mode,
      if (classRoomLayoutData.roomView === 2) {
        publishAudioStream(studentId, micData.microphone);
      }
      subscribeToStream();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionConnected]);

  /* Initializes then connects to the vonage session. This occurs at lesson start
      *and* when the mode is changed between lecture and group chat.
  */
  useEffect(() => {
    const classRoomLayoutData = JSON.parse(rawLayoutData);

    /* Based on if we are in lecture mode or group chat, and which group the user is assigned
        to, this gets the session id that the user should be connected to. If group chat
        is going on and the user is not assigned to a group yet, this is null
    */
    const sessionCredentials = getSessionToConnectWith(
      classRoomLayoutData,
      initialRoomData,
      studentId
    );

    /* We do not allow the user to connect to a session if the lesson hasn't started yet,
        or if the lesson has already ended (for obvious reasons -- they should only connect
        to a vonage session while the lesson is ongoing). We also do not allow them to conenct
        if they are in "no group room"; that's the case if group chat is ongoing but the
        teacher has not assigned them to group 1 or 2, in this case sessionCredentials
        is null.
    */
    if (sessionCredentials === null || !hasLessonStarted || hasLessonEnded) {
      return;
    }

    // Do we need to initialize, or connect? whenever we disconnect from a session we set
    // vonageSession to undefined, and that's how we know we need to initialize. if
    // vonageSession has a value, we have already initialized
    if (vonageSession === undefined) {
      initializeSession(sessionCredentials.sessionId);
    } else {
      // connecting to the Session
      connectToSession(sessionCredentials!.token);
    }

    // We explicitly disable deps rule because we *only* want to init/connect to a session
    // when either the lesson starts, or we switch between lecture/group chat modes (in which
    // case the useEffect below here disconnects the user from their current session, in turn
    // changing the value of vonageSession). We obviously do not want to init a vonage session
    // every time the layout data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vonageSession, hasLessonStarted]);

  /* Disconnect from session when layout data change tells us that the room mode has changed
      from lecture to group chat, or vice versa; also disconnect if the teacher moves the
      student from one group to another. Additionally, setPreviousRoomLayoutData when
  */
  useEffect(() => {
    const classRoomLayoutData = JSON.parse(rawLayoutData);

    // determine which room the student is in -- note that studentGroupRooms is an empty
    // array if the teacher has not yet put any student into any group
    let myRoom = null;
    if (classRoomLayoutData.rooms.studentGroupRooms[0]?.students.includes(studentId)) {
      myRoom = classRoomLayoutData.rooms.studentGroupRooms[0].uuid;
    } else if (classRoomLayoutData.rooms.studentGroupRooms[1]?.students.includes(studentId)) {
      myRoom = classRoomLayoutData.rooms.studentGroupRooms[1].uuid;
    }

    /* Disconnect from the session if we detect a change from lecture to group chat, or
        the user has been moved to a new group. Note that on initial pageload previousRoomLayoutData
        is empty, thus the first if statement. Also note that disconnectFromSession itself checks
        to see if the user is connected to a session, we do not need to do that check here. So if
        the user was in "no group" and the teacher moves them to group 1, this code *will* run
        but won't break anything because of the check in disconnectFromSession that the user
        is actually connected to a session before attempting the disconnect
    */
    if (previousRoomLayoutData) {
      // if we room mode changes from lecture to group or vice versa, we always try to disconect.
      // if the student was in "no group" and we're switching from group chat to lecture, the
      // disconnect will do nothing bc the user wasn't connected to any session
      if (previousRoomLayoutData.roomView !== classRoomLayoutData.roomView) {
        // eslint-disable-next-line no-console
        console.log('detected a need to disconnect session, lecture/group chat mode changed');
        disconnectFromSession();

        // if the student's group is changed, we *only* want to disconnect if we are currently in
        // group chat mode. if we are in lecture mode and the teacher changes what group a student
        // is in we do NOT want to disconnect the user from the lecture
      } else if (classRoomLayoutData.roomView === 2
        && previousRoomLayoutData.studentInGroup !== myRoom) {
        // need to initialize session for the student who are coming from noGroup (late comers)
        /**
         * Reason: suppose a new student have reserved the lesson, when the lesson is already
         * started and teacher and all students are in group chat mode at that time. what will
         * happen in that case?
         * well the student added in no group area. in this case our this useEffect is going
         * to run and set the previousRoomLayoutData for this student as follows:
         *  previousRoomLayoutData = {
         *    roomView: 2,
         *    studentInGroup: null (this is set to null because student is not in any group yet)
         *  }
         * now, when teacher move this student to either of the group this useEffect will
         * execute again and our else if condition will met, but previously we were only
         * disconneting from here, hence the student never gets connected to the session.
         * Now, with the below if condition, we know that this user came for no group area
         * that's why his studentInGroup has null value so we need to initialize the session
         * here for this student. In else condition we are disconnecting the student because
         * teacher is moving the student between group 1 and group 2
        */
        if (previousRoomLayoutData.studentInGroup === null) {
          /* sesssionCredential.sessionId is required to initialze the session
             that's why we are calling this function here so that we get the sessionId here and
             pass in initializeSession function
          */
          const sessionCredentials = getSessionToConnectWith(
            classRoomLayoutData,
            initialRoomData,
            studentId
          );
          // eslint-disable-next-line no-console
          console.log('detected a need to initialize session, student come into group chat from no group area');
          initializeSession(sessionCredentials!.sessionId);
        } else {
          // eslint-disable-next-line no-console
          console.log('detected a need to disconnect session, student group changed');
          disconnectFromSession();
        }
      }
    }
    // this allows us to track if the layout data change requires a disconnect or not
    setPreviousRoomLayoutData!({
      roomView: classRoomLayoutData.roomView,
      studentInGroup: myRoom
    });

    // Explicitly disable this rule, bc we only want to disconnect the user if the room
    // layout data has changed so that the user is in a new group or there was a change
    // from lecture to group chat (or vice versa)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawLayoutData]);

  // #endregion

  // this is important bcz, if user publishing, we connect correct mic, immediately.
  // this will only update the audioSource when user's current
  //  microphone(from he/she is communicating) unplugged
  useEffect(() => {
    if (isMediaStopped && audioPublisher && currentAudioDevice) {
      audioPublisher.setAudioSource(currentAudioDevice);
    }
    setIsMediaStopped(() => false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAudioDevice]);

  return (
    <div />
  );
};
