import { graphql } from 'babel-plugin-relay/macro';

export const CreateOrderForPurchase = graphql`
  mutation CreateOrderForPurchaseMutation(
    $languageId: Int!,
    $packageId: Int!,
    $price: Float!
  ) {
    orders_createpurchaseorder(
      languageId: $languageId,
      packageId: $packageId,
      price: $price
    ) {
      success
      data {
        paypalPaymentId
        invoiceNumber
      }
    }
  }

`;
