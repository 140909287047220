import React, { useState, useEffect, useLayoutEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  CardContent, Grid2, Grid2Ct, Card, Ty, Button, Ic, useTheme, Box, CardSmallColor,
  IcSvgList, Dialog,
} from '@languageconvo/wcl';
import { useTranslation } from 'react-i18next';
import { useFragment } from 'react-relay';
import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { InView } from 'react-intersection-observer';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ImageTeacher } from '../TeachersDiscover.style';
import { TeachersDiscoverDataFragment, TeachersDiscoverDataLanglearnFragment } from '../relay/TeachersDiscoverData';
import { sitedata } from '../../../../../utils/sitedata';
import { TeachersDiscoverDataFragment$key } from '../relay/__generated__/TeachersDiscoverDataFragment.graphql';
import { TeachersDiscoverDataLanglearnFragment$key } from '../relay/__generated__/TeachersDiscoverDataLanglearnFragment.graphql';
import { getStuSettingsFromLCStorage, updateDiscoverTeacherNodeInLCSettings } from '../../../../../utils/lcStorage';
import { TeacherProfile } from '../interface';
import { useFavoriteTeacher } from '../../../../../common/hooks/teachers/favorites/useFavoriteTeacher';
import { RelayFavoritedVals } from '../../../../../common/relay/resolvers/teachers/relayFavorited';

type TeacherListProps = {
  tchrs: TeachersDiscoverDataFragment$key;
  langlearn: TeachersDiscoverDataLanglearnFragment$key;
}

// main component
export const DiscoverTeacherlist = ({
  tchrs,
  langlearn,
}: TeacherListProps) => {
  // #region general

  const theme = useTheme();
  const { t } = useTranslation('languageLocations');
  const data = useFragment(TeachersDiscoverDataFragment, tchrs);
  const langlearningData = useFragment(TeachersDiscoverDataLanglearnFragment, langlearn);
  const langlearningId = langlearningData.lang_learning;
  const teachers = data?.resources_connection?.edges;
  let testidCount = 0;

  // #endregion

  // #region favorite

  const { favoriteTeacher } = useFavoriteTeacher();
  const handlefavouriteTeacher = (item: any) => {
    favoriteTeacher(
      item.relayFavorited,
      item.uuid,
      item.id,
    );
  };

  // #endregion

  // #region randomize / lock list of teachers

  // getting localstored values to check when last time, we randomised teachers.
  const localData = getStuSettingsFromLCStorage();
  const pagesData = localData.pages.findteacher;
  // calculating time in seconds for 5 days(432000)
  const fiveDays = 5 * 24 * 60 * 60;
  // represent teachers array which are randomised, and locked for 5 days.
  const [lockedTeachersArray, setLockedTeachersArray] = useState<TeacherProfile[]>([]);
  // this flag `tchDataReady` indicate the teachers data is ready to show on UI or not. 
  // We need this because we are doing calculations (i-e sorting teachers, 
  // randomised etc) before displaying them in UI
  const [tchDataReady, setTchDataReady] = useState(false);

  /* this useLayoutEffect will run everytime when lastVisited time gets updated..
     and here we are doing following things.
     1- randomising all teachers, moving teachers to last if they are not accepting new stu.     
     2- storing teachers order array (uuis) in localstorage
     3- creating map of these teachers for later comparison.
  */
  useLayoutEffect(() => {
    // if localstored in tempted or its vcalue not presnet, assigning it to 0 as 
    // need to check time diff
    const lastVisited = pagesData.lastVisited !== null ? pagesData.lastVisited : 0;
    const lastVisitedDateTime = DateTime.fromSeconds(lastVisited);
    const differenceInSeconds = DateTime.now().diff(lastVisitedDateTime).as('seconds');
    const timeDifferenceInSeconds = Math.floor(differenceInSeconds);

    // if there is no lastVisited present OR 5 days passed.
    // showing new radomising teacher's to the students...
    if (pagesData.lastVisited === null || timeDifferenceInSeconds > fiveDays) {
      const teachersList = randomisedTeachersAndSaveInLCStorage(teachers);
      // need to use this state to display users in UI.
      setLockedTeachersArray(teachersList);
    } else {
      // Create a map of UUIDs to teacher objects like follwoing
      // teacherMap = [ uuid1: teacherObj, uuid2: teacherObj ...];
      // this is creating only for comparison.
      const mapTchWithUUIDs = new Map();
      // Seting map of UUIDs to teacher objects like follwoing
      // teacherMap = [ uuid1: teacherObj, uuid2: teacherObj ...];
      if (teachers.length) {
        teachers.forEach((teacher) => {
          mapTchWithUUIDs.set(teacher.node.uuid, teacher.node);
        });
      }
      const temporaryLockedTeachersArray: TeacherProfile[] = [];
      pagesData.tchorder.forEach((uuid: string) => {
        if (mapTchWithUUIDs.has(uuid)) {
          // If UUID exists in the mapTchWithUUIDs, push the corresponding teacher 
          // object to orderedTeachers
          const teacherObject = mapTchWithUUIDs.get(uuid);
          temporaryLockedTeachersArray.push({ node: teacherObject });
          // Delete the UUID from mapTchWithUUIDs to avoid duplicates
          mapTchWithUUIDs.delete(uuid);
        }
      });
      // in case of tempered uuids, we still get some teachers left in mapTchWithUUIDs.
      // so, we are mapping and adding remaining students to lockedTeachersArray
      mapTchWithUUIDs.forEach((value) => {
        // Push any remaining teachers from teacherMap to orderedTeachers
        temporaryLockedTeachersArray.push({ node: value });
      });
      setLockedTeachersArray(temporaryLockedTeachersArray);
      // if mapTchWithUUIDs still have some values, it means, localstored in tempted OR more
      // teachers added from backend. In this case, we need to store uuids again in localstorage.
      if (mapTchWithUUIDs.size) {
        const allTeacheruuids: string[] = temporaryLockedTeachersArray.map((tch:
          TeacherProfile) => tch.node.uuid);
        // Update lastVisited timestamp in localstorage in case if students visits frequently.
        updateDiscoverTeacherNodeInLCSettings({
          lastVisited: Math.floor(DateTime.now().toSeconds()),
          tchorder: allTeacheruuids
        });
      } else {
        // Update lastVisited timestamp in localstorage in case if students visits frequently.
        updateDiscoverTeacherNodeInLCSettings({
          lastVisited:
            Math.floor(DateTime.now().toSeconds())
        });
      }
    }
    // updating flag `tchDataReady` as data is ready to show on UI. 
    setTchDataReady(() => true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Here we are doing following things.
     1- randomising all teachers, moving teachers to last if they are not accepting new stu.     
     2- storing teachers order array (uuids) in localstorage
     3- creating map of these teachers for later comparison.
  */
  const randomisedTeachersAndSaveInLCStorage = (allTeachers: any) => {
    // randomising teachers. see more comments here categorizeTeachers
    const teachersList = categorizeTeachers(allTeachers);
    // here we are getting the uuid's of the teacher's and storing them to local storage 
    // to lock randomization for 5 days
    const allTeacheruuids: string[] = teachersList.map((tch: TeacherProfile) => tch.node.uuid);
    // storing teacher's uuids and current timestamp in discover teacher node 
    updateDiscoverTeacherNodeInLCSettings(
      {
        tchorder: allTeacheruuids,
        lastVisited: Math.floor(DateTime.now().toSeconds()),
        tchscrolled: null
      }
    );

    return teachersList;
  };

  // log error if no teachers
  // log errors if no teachers after the teacher data is ready for UI
  if (tchDataReady && lockedTeachersArray?.length === 0) {
    Sentry.captureException(
      new Error('MAJOR ERROR: teachers seems to be empty'),
      {
        extra: {
          locked: lockedTeachersArray,
          all: teachers,
        }
      },
    );
  }
  // #endregion

  // #region scroll to

  /* This useEffect scrolls the student's focus to the teacher's div where they
   were previously positioned. */
  useEffect(() => {
    // if localstored in tempted or its value not presnet, assigning it to 0 as 
    // need to check time diff
    const lastVisited = pagesData.lastVisited !== null ? pagesData.lastVisited : 0;
    const lastVisitedDateTime = DateTime.fromSeconds(lastVisited!);
    const differenceInSeconds = DateTime.now().diff(lastVisitedDateTime).as('seconds');
    const timeDifferenceInSeconds = Math.floor(differenceInSeconds);
    if (lockedTeachersArray.length) {
      // #region determine if student viewed first teacher in the list

      // get the uuid of the first teacher in the list, that teaches the language the student
      // is viewing
      let firstTeacherThisLanguage: string | null = null;
      lockedTeachersArray.forEach((el) => {
        if (el.node.instructor_languages.language_id === langlearningId
          && firstTeacherThisLanguage === null) {
          firstTeacherThisLanguage = el.node.uuid;
        }
      });

      // if the student has viewed the first teacher, set this to true; if true, we'll scroll
      // to the top of the page
      let firstTeacherViewed = false;
      if (firstTeacherThisLanguage !== null && pagesData.tchscrolled === firstTeacherThisLanguage) {
        firstTeacherViewed = true;
      }

      // #endregion

      // Find the element with the corresponding UUID attribute
      const cardToScrollTo = document.querySelector(`[data-card-tchuuid="${pagesData.tchscrolled}"]`);

      // if the student has NOT viewed the first teacher most recently, and we found the element of
      // the teacher the student has most recently viewed, and the student has visited this page
      // within the past 5 days...scroll to the teacher they most recently viewed
      if (!firstTeacherViewed && cardToScrollTo && timeDifferenceInSeconds < fiveDays) {
        scrollIntoView(cardToScrollTo!, {
          behavior: 'auto',
          scrollMode: 'if-needed',
          // block: center attempts to vertically center the element in the viewport
          block: 'center',
          inline: 'nearest',
        });

        // otherwise scroll to the top of the page. why scroll to top if the first teacher has been
        // viewed? especially on mobile, it's annoying if this occurs: student scrolls to the top
        // of the page to view the "common questions", then comes back and is actually scrolled
        // *down* to the first teacher
      } else {
        const cardToScrollToTop = document.querySelector('[data-top="top"]');
        if (cardToScrollToTop) {
          scrollIntoView(cardToScrollToTop!, {
            behavior: 'auto',
            scrollMode: 'if-needed',
            block: 'start',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockedTeachersArray]);

  // #endregion

  // #region modal

  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalState(true);
  };

  // modal close action
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  // main component
  return (
    <>
      {/* discover teachers block */}
      {
        lockedTeachersArray.map((item: TeacherProfile) => {
          // #region data for each individual teacher

          // TODO: lockedTeachersArray only needs to be an array of teacher UUIDs, it does not need
          // any data about each teacher. its job is to give us the *order* in which we want to
          // display teachers.
          // the *data* we want to display should come from the relay store directly; that data
          // is inside of the var named "teachers" that we get from useFragment

          // here we get the teacher's relay data, so we can display it below. remember the relay
          // data is in teachers, not in lockedTeachersArray. lockedTeachersArray was just a copy
          // of the relay data when the page first loaded -- that data can definitely change, for
          // example it changes when clicking the "Favorite" button. so we need to use the relay
          // data directly when displaying in the UI. here we get the teacher that we want to
          // display's relay data
          const thisTeacherUuid = item.node.uuid;
          const theTeacher = teachers.find((el) => el.node.uuid === thisTeacherUuid);
          const node = theTeacher?.node;
          // if a teacher is no longer available/not teaching anymore, it's very possible to have
          // that teacher listed in lockedTeachersArray but *not* in the relay data. so here if
          // we don't find the teacher we return
          if (node === undefined) {
            return (null);
          }

          // free trial / wants new students
          const getNewStd = node.gets_new_students;
          const wantNewStd = node.wants_new_students;
          // the teacher provides free trial lessons to new students, if provides_free_trial = 1
          const providesFreeTrial = node.provides_free_trial === 1;
          // we only want to show whether the teacher provides a free trial or not, if the teacher
          // can get and wants to get new students
          let showFreeTrialInfo = false;
          if (getNewStd === 1 && wantNewStd === 1) {
            showFreeTrialInfo = true;
          }

          // teacher language. filter out teachers based on the language the user has selected
          const lngId = node.instructor_languages?.language_id;
          /* 
            if a language filter is applied, iterating through the teachers
            and filter out those who don't match the specified language.
            we are using the language ID of the teacher and comparing it with the langlearningId.
            if the language ID of the teacher does not match the langlearningId,
            we skip rendering this teacher. */
          if (lngId !== langlearningId) {
            return null;
          }

          // here we concat the teacher's about me so that it fits nicely in our ui
          let aboutme = '';
          if (node.stuprof_aboutme) {
            const substr = node.stuprof_aboutme.trim().substring(0, 100);
            aboutme = `${substr}...`;
          }

          // border radius of images
          const imgRadius = theme.ourTheme.borders.borderRadius.images;
          // link to teacher's profile
          const tchLink = `${sitedata.url.app.teachersProfile}${node.pk}&uuid=${node.uuid}`;

          // teacher's image, name
          // if the teacher hasn't yet uploaded a profile picture, this standard img
          // will show
          let tchImage = `${process.env.REACT_APP_IMAGESCDNURL}${sitedata.assets.imgDefaultProfile}`;
          if (node.resources_files_profileimgs.length > 0) {
            tchImage = `${node.resources_files_profileimgs[0].fuploads.file_cdn.base_url}${node.resources_files_profileimgs[0].fuploads.file_path}`;
          }
          const tchName = node.title;

          // boolean, whether or not the teacher is a favorite
          const isFavorite = node.relayFavorited === RelayFavoritedVals.IsFavorite;

          // for data-testid
          testidCount += 1;

          // #endregion

          return (
            <Grid2
              xs={12}
              xxl={6}
              key={node.title}
              sx={{
                display: 'flex',
              }}
            >
              {/* Scroll to element */}
              <Card
                cp={{
                  'data-testid': 'teachersUUID',
                  'data-card-tchuuid': `${node.uuid}`,
                  sx: { width: '100%' }
                }}
              >
                <CardContent>
                  <Grid2Ct>
                    {/* left side: image */}
                    <Grid2 xs={12} sm={4} smOffset={0} md={3} xxl={4}>
                      <Box display="flex" justifyContent="center">
                        <ImageTeacher
                          src={tchImage}
                          alt={tchName}
                          style={{ borderRadius: imgRadius }}
                        />
                      </Box>
                    </Grid2>

                    {/* right side: about, language, buttons */}
                    <Grid2 xs={12} sm={8} md={9} xxl={8}>
                      <Ty><strong>Name</strong>: {tchName}</Ty>
                      {getNewStd === 1 && wantNewStd === 1
                        ? (
                          <Ty removeMb>
                            <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>👋 This teacher is accepting new students</Box>
                            <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>👋 Accepting new students</Box>
                          </Ty>
                        )
                        : (
                          <Ty removeMb>
                            <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>❌ This teacher is not currently accepting new students</Box>
                            <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>❌ Not currently accepting new students</Box>
                          </Ty>
                        )}
                      {/* here we show whether or not the teacher provides a free trial, iff
                      they are accepting new students. showFreeTrialInfo gives us that info
                    */}
                      {showFreeTrialInfo && providesFreeTrial ? (
                        <Ty removeMb>
                          <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>🎉 Offers a 100% free trial lesson to new students</Box>
                          <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>🎉 Offers a 100% free trial lesson</Box>
                        </Ty>
                      )
                        : (null)}
                      {showFreeTrialInfo && !providesFreeTrial ? (
                        <Ty removeMb>
                          <Box component="span" sx={{ display: { xs: 'none', sm: 'inline' } }}>🎓 Asks for a small payment for trial lessons</Box>
                          <Box component="span" sx={{ display: { xs: 'inline', sm: 'none' } }}>🎓 Small payment for trial lesson</Box>
                        </Ty>
                      )
                        : (null)}
                      <Ty cp={{ sx: { mt: 1 } }}><strong>About</strong>: {aboutme}</Ty>
                      {/* In viewport element */}
                      {/*  
                        InView component from react-intersection-observer
                        Listens for changes in the visibility of its child elements.
                        When an element comes into view, it triggers the onChange callback,
                        in onChange callBack we are getting the uuid of element that comes into
                        view, and storing this uuid into discover teacher node in LC Settings.
                     */}
                      <InView
                        as="div"
                        onChange={(inView, entry) => {
                          if (inView) {
                            const cardElement = entry.target.querySelector('.MuiTypography-root');
                            // Accessing data-lang-tchuuid attribute
                            const tchuuid = cardElement!.getAttribute('data-lang-tchuuid');
                            updateDiscoverTeacherNodeInLCSettings({ tchscrolled: tchuuid });
                          }
                        }}
                      >
                        <Ty cp={{ 'data-lang-tchuuid': `${node.uuid}` }}><strong>Language</strong>: {t(`${node.instructor_languages?.language_id}`)}</Ty>
                      </InView>

                      {/* buttons view profile, favorite */}
                      <Grid2Ct sx={{ pt: 1 }}>
                        {/* md to xxl: buttons display beside each other, with full text */}
                        <Grid2 xs={12}>
                          <Box sx={{ display: { xs: 'none', md: 'block' }, maxWidth: { md: '490px', xxl: '100%' } }}>
                            <Grid2Ct>
                              <Grid2 xs={12} md={7} lg={7} xl={8} sx={{ width: '100%' }}>
                                <Button
                                  color="accentGreen1"
                                  component={NavLink}
                                  to={tchLink}
                                  cp={{ 'data-testid': `profileBtnDesktop${testidCount}` }}
                                  fullWidth
                                >View Profile &amp; Schedule
                                </Button>
                              </Grid2>
                              <Grid2 xs={12} md={4} lg={4} xl={4} sx={{ width: '100%' }}>
                                <Button
                                  color="accentPurple1"
                                  cp={{ 'data-testid': `favBtn${testidCount}` }}
                                  onClick={() => handlefavouriteTeacher(node)}
                                  fullWidth
                                ><Ic iconName="user-graduate" iconStyle={isFavorite ? 'duotone' : 'light'} color="accentPurple1Dark" />&nbsp;Favorite
                                </Button>
                              </Grid2>

                              {/* md to xxl: denote teacher is a favorite
                          if the teacher is a favorite, display a notif so it's clear
                          to the user that they have added this teacher as a favorite */}
                              {isFavorite ? (
                                <Grid2 xs={12} md={11} lg={11} xl={12} sx={{ width: '100%' }}>
                                  <CardSmallColor
                                    text="Favorited Teacher!"
                                    icon={IcSvgList.star1}
                                    color="accentYellow1"
                                    hovercursor="pointer"
                                    onClick={handleModalOpen}
                                  />
                                </Grid2>
                              ) : null }
                            </Grid2Ct>
                          </Box>
                        </Grid2>

                        {/* xs and sm: buttons display beside each other with shortened text */}
                        <Grid2 xs={6} sx={{ display: { xs: 'block', md: 'none' } }}>
                          <Button
                            color="accentGreen1"
                            fullWidth
                            component={NavLink}
                            to={tchLink}
                            cp={{ 'data-testid': `profileBtnMobile${testidCount}` }}
                          >View Profile
                          </Button>
                        </Grid2>
                        <Grid2 xs={6} sx={{ display: { xs: 'block', md: 'none' } }}>
                          <Button
                            color="accentPurple1"
                            fullWidth
                            cp={{ 'data-testid': `favBtn${testidCount}` }}
                            onClick={() => handlefavouriteTeacher(node)}
                          ><Ic iconName="user-graduate" iconStyle={isFavorite ? 'duotone' : 'light'} color="accentPurple1Dark" />&nbsp;Favorite
                          </Button>
                        </Grid2>

                        {/* xs, denote teacher is a favorite
                          if the teacher is a favorite, display a notif so it's clear
                          to the user that they have added this teacher as a favorite */}
                        {isFavorite ? (
                          <Grid2 xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
                            <CardSmallColor
                              text="Favorited Teacher!"
                              icon={IcSvgList.star1}
                              color="accentYellow1"
                              hovercursor="pointer"
                              onClick={handleModalOpen}
                            />
                          </Grid2>
                        ) : null }
                      </Grid2Ct>
                    </Grid2>
                  </Grid2Ct>
                </CardContent>
              </Card>
            </Grid2>
          );
        })
      }

      {/* modal */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="sm"
        color="accentYellow1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              You favorited this teacher! They will be listed on the main Lessons page so that
              you can easily schedule lessons with them.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};

/* organize teachers based on availability
  categorizeTeachers is our function in which in which we are 
  placing the teacher's who are acceptingNewStudents above and 
  teacher's who are not accepting new student below,
  after that we are shuffling our acceptingNewStudents array's 
  so when ever student's visit the page they see the shuffle teacher's,
  student also see shuffle teacher when they filter teacher by language
*/
const categorizeTeachers = (teachers: TeacherProfile[]) => {
  // initialize two arrays to store teachers who are accepting new students and those who are not
  const acceptingNewStudents = [];
  const noNewStudents = [];
  // sorting teachers and moving to last if they are not accepting new students.
  // based on these 2 flags wants_new_students, gets_new_students, we are moving 
  // teachers to last in the array.
  for (let i = 0; i < teachers.length; i += 1) {
    const { node } = teachers[i];
    // checking if the teacher wants and gets new students
    if (node.wants_new_students === 1 && node.gets_new_students === 1) {
      // if yes, add the teacher to the acceptingNewStudents array
      acceptingNewStudents.push({ node });
    } else {
      noNewStudents.push({ node }); // if no, we are pushing teacher's to noNewStudents array
    }
  }
  /*
    The Fisher-Yates algorithm begins by looping through, 
    starting from the last element, and at each iteration, it randomly 
    selects an index within the unshuffled portion of the array and 
    swaps the element at that index with the element at the current iteration index, 
    effectively shuffling the array randomly
  */
  if (acceptingNewStudents) {
    for (let i = acceptingNewStudents.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      [acceptingNewStudents[i],
        acceptingNewStudents[j]] = [acceptingNewStudents[j],
        acceptingNewStudents[i]];
    }
  }
  /* 
  here we are combining the two arrays (after radmomizing the teacher's
   who are accepting new students), placing teachers who accept new students
  first, followed by those who don't 
  */
  const combinedResult = acceptingNewStudents.concat(noNewStudents);
  return combinedResult;
};
