import styled from '@emotion/styled';
import {
  Card,
} from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';

export const CustomCard = styled(Card)(({ theme }: WCLThemeProps) => {
  return ({
    // IMPORTANT
    // if you edit styles here, you should likely also edit the styles in AvatarIcon component as it is very similar
    // to this component

    display: 'flex',
    maxWidth: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: theme?.ourTheme.borders.borderRadius.medium,
    border: 'none',
    boxShadow: 'none',
    transition: theme?.ourTheme.components.transition.item,

    // light mode
    ...(theme?.themeMode === 'light' && {
      background: `hsl(${theme?.ourTheme.colors.info.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.info.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.info.base})`,
        color: `hsl(${theme?.ourTheme.colors.info.lightest})`,
      },
    }),

    // dark mode
    ...(theme?.themeMode === 'dark' && {
      background: `hsl(${theme?.ourTheme.colors.info.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.info.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.info.base})`,
        color: `hsl(${theme?.ourTheme.colors.info.lightest})`,
      },
    }),
  });
});
