/**
 * @generated SignedSource<<02f9e90475417d99a26e0a5e5ae87172>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetUserPreferencesLangLearningFragment$data = {
  readonly lang_learning: number;
  readonly " $fragmentType": "GetUserPreferencesLangLearningFragment";
};
export type GetUserPreferencesLangLearningFragment$key = {
  readonly " $data"?: GetUserPreferencesLangLearningFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetUserPreferencesLangLearningFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetUserPreferencesLangLearningFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lang_learning",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "db329e5daa5b79c227bc03340788ef07";

export default node;
