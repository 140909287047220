import React from 'react';
import {
  Grid2Ct, Grid2, Ty, CardStandard, Link, IcSvgList,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { useClientQuery, useFragment } from 'react-relay';
import { PrivateUpcoming } from './components/PrivateUpcoming/PrivateUpcoming0';
import { PrivateSchedLsn } from './components/PrivateSchedLsn/PrivateSchedLsn0';
import { Messages } from './components/Messages';
import { DiscoverTeachers } from './components/DiscoverTeachers';
import { DiscoverTeachersNotsubscribed } from './components/DiscoverTeachersNotsubscribed';
import { GroupClassesNotsubscribed } from './components/GroupClassesNotsubscribed';
import { GroupClassesSubscribed } from './components/GroupClassesSubscribed';
import { UnReadMsgCountFragment, UnReadMsgCountQuery } from './relay/UnReadMsgCount';
import { sitedata } from '../../../../../utils/sitedata';

interface Props {
  isSubscribed: boolean,
}

// entire home page layout
export const LayoutPosttrial2 = ({ isSubscribed }: Props) => {
  // eslint-disable-next-line
  const x = 1;

  // number of unread messages (threads actually) the student has
  const response: any = useClientQuery(UnReadMsgCountQuery, {});
  const fragmentR = response.RelayAppSettings;
  const data = useFragment(UnReadMsgCountFragment, fragmentR);
  let numUnread = 0;
  // unReadMsgCount can be null, in which case we want numUnread to remain 0. in other cases
  // it should be an integer
  if (data?.message?.unReadMsgCount !== undefined && data?.message?.unReadMsgCount !== null) {
    numUnread = data.message.unReadMsgCount;
  }

  return (
    <Grid2Ct>
      {/* if the user has any unread messages, this will show the user a notif card */}
      <UnreadNotif numunread={numUnread} />

      {/* if user IS subscribed: group classes, discover teachers */}
      {isSubscribed ? (
        <Grid2 xs={12}>
          <Grid2Ct>
            <GroupClassesSubscribed />
            <DiscoverTeachers />
          </Grid2Ct>
        </Grid2>
      ) : (
        <div />
      )}

      {/* upcoming private lessons, schedule a private lesson */}
      <Grid2 xs={12}>
        <Grid2Ct>
          <PrivateUpcoming />
          <PrivateSchedLsn />
        </Grid2Ct>
      </Grid2>

      {/* if the user is NOT subscribed: group classes, discover teachers */}
      {!isSubscribed ? (
        <Grid2 xs={12}>
          <Grid2Ct>
            <GroupClassesNotsubscribed />
            <DiscoverTeachersNotsubscribed />
            <Messages numunread={numUnread} />
          </Grid2Ct>
        </Grid2>
      ) : (
        <div />
      )}

      {/* if the user IS subscribed: messages */}
      {isSubscribed ? (
        <Grid2 xs={12}>
          <Grid2Ct>
            <Messages numunread={numUnread} />
          </Grid2Ct>
        </Grid2>
      ) : (
        <div />
      )}
    </Grid2Ct>
  );
};

// if the user has any unread messages, show a notification
interface UnreadNotifProps {
  numunread: number,
}
const UnreadNotif = ({ numunread }: UnreadNotifProps) => {
  let msgstr = 'message';
  let tchstr = 'teacher';
  if (numunread > 1) {
    msgstr = 'messages';
    tchstr = 'teachers';
  }

  // link to messages page
  const messagesUrl = sitedata.url.app.messages;
  const clickableTypeObjMessages = {
    to: messagesUrl,
    component: NavLink,
  };

  return (
    numunread > 0 ? (
      <Grid2 xs={12}>
        <CardStandard
          titleText="Notification"
          titleIcon={IcSvgList.mailunread1}
          color="accentOrange1"
          clickableLink1={{ ...clickableTypeObjMessages }}
        >
          <Link
            to={messagesUrl}
            component={NavLink}
            linkStyle="nostyle"
          >
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty removeMb>
                  You have <strong>{numunread} unread {msgstr}</strong> from your {tchstr}!
                </Ty>
              </Grid2>
            </Grid2Ct>
          </Link>
        </CardStandard>
      </Grid2>
    ) : null
  );
};
