import { graphql } from 'babel-plugin-relay/macro';

export const StuApptgroupCreateresvMutation = graphql`
  mutation StuApptgroupCreateresvMutation($lessonUuid: String!) {
    stu_apptgroup_createresv(lessonUuid: $lessonUuid) {
      data {
        code
      }
      success
    }
  }
`;
