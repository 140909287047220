import { graphql } from 'babel-plugin-relay/macro';

export const PageloadQuery = graphql`
  query PageloadQuery {
    users_connection {
      edges {
        node {
          ...PageloadLanglearnFragment
          ...PageloadTimesettingsFragment
          ...PageloadRequiredsettingsFragment
        }
      }
    }
  }
`;

// language the user is learning. needed for the subscription to run
export const PageloadLanglearnFragment = graphql`
  fragment PageloadLanglearnFragment on users {
    lang_learning
  }
`;

// hour_cycle needed to display lesson times correctly to the user
export const PageloadTimesettingsFragment = graphql`
  fragment PageloadTimesettingsFragment on users {
    hour_cycle
  }
`;

// need to know if the user has set their time zone; it's possible they created an
// account, purchased group classes but did not set time zone. in that case, class times
// will not be correct
export const PageloadRequiredsettingsFragment = graphql`
  fragment PageloadRequiredsettingsFragment on users {
    timezone_set
  }
`;
