import React, { useEffect, useState, useMemo } from 'react';
import {
  Grid2Ct, Grid2, Ty, Box, CardSmallColor, ScrollArea, useTheme, useMediaQuery, Dialog, Ic,
  styled,
} from '@languageconvo/wcl';
import { useFragment } from 'react-relay';
import { SelectedStudentDialog } from './SelectedStudentDialog';
import { StudentData } from '../../interfaces/studentData';
import { RoomLayoutFragment } from '../../relay/RoomLayout';
import { RoomLayoutFragment$key } from '../../relay/__generated__/RoomLayoutFragment.graphql';
import { TeacherVideoContainer, IndividualStudentContainer } from './GroupChat.style';
import { useVonage } from '../../hooks/useVonage';
import { WhichViewTy } from '../setup/Setup4ViewSetup1';
import { VideoDivId } from '../../context/Types';

interface Props {
  fragmentRefOfRoomLayoutSubscription: RoomLayoutFragment$key;
  onlineUsers: string[];
  whichView: WhichViewTy;
}

// Layout for group chat with students and Group chat with teacher
export const GroupChat = ({
  fragmentRefOfRoomLayoutSubscription,
  onlineUsers,
  whichView,
}: Props) => {
  // #region general, react state

  // getting the array of muted student from our vonage provider so we can show which student is
  // muted by me on the UI 
  const { temporarilyMutedStudents } = useVonage();

  // whether or not we're in group chat mode
  let isGroupChat = false;
  if (whichView === WhichViewTy.Group) {
    isGroupChat = true;
  }

  // #region modals

  // group chat info
  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalState(true);
  };
  const handleModalClose = () => {
    setModalState(false);
  };

  // student details
  // state to toggle selected student dialog
  const [modalStudentState, setModalStudentState] = useState<boolean>(false);
  const handleClickForSelectedStudent = (
    event: React.MouseEvent<HTMLButtonElement>,
    std: StudentData,
  ) => {
    event.preventDefault(); // preventing onClick to auto reload
    setModalStudentState(true);
    setSelectedStudent(std);
  };
  const handleCloseForStudent = () => {
    setModalStudentState(false);
  };

  // #endregion

  // general
  const theme = useTheme();
  const smallScreens = useMediaQuery(theme.breakpoints.down('lg'));

  // state to hold information on which student the current user has clicked on
  const [selectedStudent, setSelectedStudent] = useState<StudentData>();
  // state to hold students name to display on UI
  const [studentsName, setStudentsName] = useState<any>([]);

  // true if the teacher has assigned this student to a group, false if they have not
  const [assignedAGroup, setAssignedAGroup] = useState<boolean>(true);

  // true if the teacher is in this group, false if not
  const [isTeacherInThisRoom, setIsTeacherInThisRoom] = useState(false);

  // true once initial data has loaded. this prevents a flash of the "not in a group"
  // view
  const [dataHasLoaded, setDataHasLoaded] = useState(false);

  // main data
  const roomLayoutData = useFragment(RoomLayoutFragment, fragmentRefOfRoomLayoutSubscription);

  // reading layout data from layout node
  const roomLayoutNode = roomLayoutData.appt_group_connection.edges[0].node;

  /** memoizing the classroomlayoutData after parsing if we don't memoize, it will cause re-renders
   * whenever the data changes. We have two options: parse the data in each useEffect/where 
   * it is needed, or memoize it here.We (me & Usama) decided to memoize the layout data instead of 
   * repeating code,
   * Memoizing ensures the data is only recalculated when roomLayoutNode changes,
   * thus improving performance and avoiding redundant re-renders.
 */
  const classroomlayoutData = useMemo(() => {
    if (roomLayoutNode) {
      return JSON.parse(roomLayoutNode.appt_group_layout!.layout);
    }
    return null;
  }, [roomLayoutNode]);

  const roomsData = classroomlayoutData.rooms;

  // state to track to which group teacher is speaking based on this we are showing 
  // group chat with teacher component only when teacher is speaking with a group
  const teacherSpeakingTo = classroomlayoutData.teacherStreamingTo[0];

  // TODO: this is a dangerous, unreliable way to get the current user's info. instead we should
  //  be getting it via users.user_publicinfo
  // reading current student details. These are not present in layout Data but in subscription
  // we are spreading a fragment which gets current user info.
  const studentInfoEdge = roomLayoutData.appt_group_connection.edges;
  const reservationInfo = studentInfoEdge[0].node.appt_group_reservations;
  const studentPublicInfo = reservationInfo[0].users.user_publicinfo;

  // #endregion

  // #region to store student details [{uuid, name}..] in a state

  const studentsDetails = classroomlayoutData.students;
  // we are storing student details here beacuse we need to show the 
  // name of the student on UI as we received uuid's  when student are divided into groups
  const [studentsDetailsArray, setStudentsDetails] = useState([]);

  //  storing student details in a state to avoid re-rendering
  useEffect(() => {
    // TODO: seems we should remove this -- and maybe the studentsDetailsArray state too? we
    // already have the student details...in studentsDetails. additionally, this if statement is
    // dangerous in some exceptional cases we might not update studentsDetailsArray.
    if (studentsDetails.length > studentsDetailsArray.length) {
      setStudentsDetails(studentsDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsDetails]);

  // #endregion

  // #region to find current student group and get all the student who are 
  // along with current student 

  const studentId = studentPublicInfo!.uuid;

  // storing students group in state to avoid re-rendering
  const [currentStudentGroup, setCurrentStudentGroup] = useState<string[]>([]);

  // state to track is student in group 1.OR group 2
  const [studentBelongsToGroup1, setStudentBelongsToGroup1] = useState<boolean>(false);

  // here we are adding ? to avoid code break there is possibilty that teacher 
  // divides all student's into a single group so the 2nd group will be empty.

  /** the 'group1Students' & group2Students could make the dependencies
    *  of useEffect hook change on every render. So we are wraping the initialization of 
    * 'group1Students' & 'group2Students' in its own useMemo() hook to avoid unnecessary re-renders.
   */
  const group1Students = useMemo(() => {
    if (roomsData) {
      return roomsData.studentGroupRooms[0]?.students;
    }
    return [];
  }, [roomsData]);

  const group2Students = useMemo(() => {
    if (roomsData) {
      return roomsData.studentGroupRooms[1]?.students;
    }
    return [];
  }, [roomsData]);

  // extracting the uuid's of the classroom for making decision to which group teacher is 
  // speaking. note that studentGroupRooms is an empty array if the teacher has never
  // set any group data (has not put any student into a group yet)
  const group1ClassroomId = roomsData.studentGroupRooms[0]?.uuid;
  const group2ClassroomId = roomsData.studentGroupRooms[1]?.uuid;

  // getting student current group
  useEffect(() => {
    if (roomLayoutNode) {
      // currentGroup will hold the group number in which student is found!
      const currentGroup = findStudentGroup(group1Students, group2Students, studentId);

      if (currentGroup === 1) {
        setCurrentStudentGroup(group1Students);
        setStudentBelongsToGroup1(true);
        setAssignedAGroup(() => true);
      }

      if (currentGroup === 2) {
        setCurrentStudentGroup(group2Students);
        setStudentBelongsToGroup1(false);
        setAssignedAGroup(() => true);
      }

      if (currentGroup === null) {
        setAssignedAGroup(() => false);
      }

      // we'll display nothing until this is true. it indicates that we can now display
      // the correct view, bc data has been fully processed
      setDataHasLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group1Students, group2Students, studentId]);

  // #endregion

  // #region to set Students Name in a state

  // As we need to show student's name on the UI, So Here we getting student's name from 
  // studentsDetails as we have student's uuid's in `currentStudentGroup`  
  useEffect(() => {
    // checking if both studentsDetails and currentStudentGroup defined
    if (studentsDetailsArray.length && currentStudentGroup) {
      // Filter studentsDetails based on whether their uuid is in the currentStudentGroup array
      const detailsArray = studentsDetailsArray
        .filter((student: { uuid: string; }) => currentStudentGroup.includes(student.uuid))
        // Map the filtered students to a new array with id and name properties
        .map((student: { uuid: string; name: string; }) => {
          // spliting name on the basis of space it will conver the name into array
          // ['First6177', 'Last6177']
          const name = student.name.split(' ');
          // first name is at the 0 index of the array 
          const firstName = name[0];
          // we are picking the first letter of the lastName
          const lastName = name[1].charAt(0);
          return ({
            id: student.uuid,
            name: `${firstName} ${lastName}.`,
          });
        });

      // here we are creatin new array where we are not showing loged in student 
      const filterdArray = detailsArray.filter((data) => data.id !== studentId);

      // storing detailsArray into a state variable.
      setStudentsName(filterdArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsDetailsArray, currentStudentGroup]);

  // #endregion

  // #region GroupChatWithTeacher 

  // if the teacher is in the group that the student is in, display GroupChatWithTeacher
  useEffect(() => {
    if ((studentBelongsToGroup1 && teacherSpeakingTo.value === group1ClassroomId)
      || (!studentBelongsToGroup1 && teacherSpeakingTo.value === group2ClassroomId)
    ) {
      setIsTeacherInThisRoom(true);
    } else {
      setIsTeacherInThisRoom(false);
    }
  }, [studentBelongsToGroup1, teacherSpeakingTo, group1ClassroomId, group2ClassroomId]);

  // #endregion

  // #region design if teacher is in or not in the room

  // on large screens, and on small screens when teacher is in the room, the
  // heights of the main elements:
  const topCardHeight = 70;
  const topCardHeightVal = `${topCardHeight}px`;
  let teacherVidHeight = '35%';
  let studentListHeight = `calc(65% - ${topCardHeight}px)`;

  // on small screens, when teacher is not in room, we hide the teacher video div
  // completely
  if (smallScreens && !isTeacherInThisRoom) {
    teacherVidHeight = '0%';
    studentListHeight = `calc(100% - ${topCardHeight}px)`;
  }

  // #endregion

  // #region display nothing while data is loading, or if this view is not the current one

  // this prevents the "not in a group" view from flashing briefly before the useEffects
  // above have run
  if (!dataHasLoaded) {
    return (<div />);
  }

  // #endregion

  // default ui -- group chat is going on, but the teacher is not present in this group
  return (
    <GroupChatContainer isGroupChat={isGroupChat}>
      <Grid2Ct sx={{ height: '100%' }}>
        {/* group chat small card, click for info */}
        <Grid2 xs={12} sm={6} md={4} sx={{ height: topCardHeightVal }}>
          <CardSmallColor
            icon="group1"
            text="Group Chat"
            color="accentBlue1"
            hovercursor="pointer"
            onClick={handleModalOpen}
          />
        </Grid2>

        {!assignedAGroup ? (<StudentNotInAGroup />)
          : (
            <>
              {/* teacher video. we always need the div to exist on the page, otherwise injecting
                the video could have a problem. but we hide the div when the teacher is not in this
                group classroom by setting teacherVidHeight = 0 */}
              <Grid2 xs={12} sx={{ height: teacherVidHeight }}>
                {/* center the video within the card */}
                <Box display="flex" justifyContent="center" sx={{ height: '100%' }}>
                  <TeacherVideoContainer id={VideoDivId.GroupCameraContainer} />
                </Box>
              </Grid2>

              {/* student list */}
              <Grid2 xs={12} sx={{ height: studentListHeight }}>
                {/* scroll area height is set to "inherit", so we need to wrap it in an element
                that has a 100% height in order for the scroll area to fill its container */}
                <Box sx={{ height: '100%' }}>
                  <ScrollArea>
                    <Grid2Ct>
                      <Grid2 xs={12}>
                        <Grid2Ct>
                          {studentsName && studentsName.length > 0 && (
                            studentsName.map((std: any) => {
                              const isMuted = temporarilyMutedStudents.some((id) => id === std.id);
                              const isOnline = onlineUsers.includes(std.id);
                              let onlineText = 'Offline';
                              if (isOnline) {
                                onlineText = 'Online';
                              }

                              return (
                                <Grid2 xs={12} lg={4}>
                                  {/* @ts-ignore */}
                                  <IndividualStudentContainer
                                    isStudentOnline={isOnline}
                                    onClick={(event) => handleClickForSelectedStudent(
                                      // @ts-ignore
                                      event,
                                      std,
                                    )}
                                  >
                                    <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                      <Ty align="center">{std.name}</Ty>
                                      <Ty align="center" removeMb>{onlineText}</Ty>
                                      {isMuted && (
                                        <Ty align="center" removeMb cp={{ sx: { mt: 1 } }}>
                                          <Ic iconStyle="duotone" color="accentRed1" iconName="circle-xmark" />&nbsp;Muted
                                        </Ty>
                                      )}
                                    </Box>
                                  </IndividualStudentContainer>
                                </Grid2>
                              );
                            })
                          )}
                        </Grid2Ct>
                      </Grid2>
                    </Grid2Ct>
                  </ScrollArea>
                </Box>
              </Grid2>
            </>
          )}
      </Grid2Ct>

      {/* selected student modal */}
      {selectedStudent !== null && selectedStudent !== undefined && (
        <SelectedStudentDialog
          modalState={modalStudentState}
          handleCloseForStudent={handleCloseForStudent}
          selectedStudent={selectedStudent!}
        />
      )}

      {/* group chat info modal */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="md"
        color="accentBlue1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty v="h2New">Group Chat</Ty>
            <Ty>
              During this portion of the class, practice speaking to other students. Try
              to use what you learned during the first part of the class!
            </Ty>
            <Ty v="h2New" cp={{ sx: { mt: 2 } }}>If Needed: Muting Another Student</Ty>
            <Ty>
              If someone has loud background noise or a problem with their mic, politely let
              them know. Hopefully they will be able to fix things, and will put themselves
              on mute until the problem is fixed. If for some reason they don&apos;t, you can mute
              them (only you will not hear them &mdash; this does not affect what other
              students hear). To do so, just click on their name and you will see a mute
              option. Note that if you refresh the page, you will need to mute them again.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </GroupChatContainer>
  );
};

// #region subcomponents, functions

// group chat is ongoing, but student hasnt been assigned to a group yet
const StudentNotInAGroup = () => (
  <Grid2Ct>
    <Grid2 xs={12} sm={10} smOffset={1} md={8} mdOffset={2}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Ty v="h2New">
          One moment &mdash; group chat is ongoing. Since you arrived a bit late, we are waiting
          on the teacher to assign you to a group...
        </Ty>
      </Box>
    </Grid2>
  </Grid2Ct>
);

/** findStudentGroup Determines which group a student belongs to based on their student ID
 *
 * @param studentInGroup1 - An array of student IDs representing the first group.
 * @param studentInGroup2 - An array of student IDs representing the second group.
 * @param studentId - The student ID(current loged in student id) to search for in the groups.
 * @returns {number | null} - Returns 1 if the student is in the first group, 
 *                            2 if the student is in the second group,
 *                            or null if the student is not found in either group.
 */
const findStudentGroup = (studentInGroup1: any, studentInGroup2: any, studentId: any) => {
  // Check if the student ID is in the first group
  if (studentInGroup1 && studentInGroup1.includes(studentId)) {
    // Return 1 if the student is in the first group
    return 1;
  }

  // Check if the student ID is in the second group
  if (studentInGroup2 && studentInGroup2.includes(studentId)) {
    // Return 2 if the student is in the second group
    return 2;
  }

  // Return null if the student is not found in either group
  return null;
};

// this container allows us to show/hide the div depending on if we're in lecture or group chat mode
interface GroupChatContainerProps {
  isGroupChat: boolean
}
const GroupChatContainer = styled(
  Box,
  {
    shouldForwardProp: (prop) => prop !== 'isGroupChat'
  },
)(({ isGroupChat } : GroupChatContainerProps) => (
  {
    padding: '20px 40px 20px 40px',
    height: '100%',
    display: 'none',
    ...(isGroupChat && {
      display: 'block',
    }),
  }
));

// #endregion
