// for the teacher's video
import {
  styled, Box, WCLThemeProps,
} from '@languageconvo/wcl';

export const TeacherVideoContainer = styled(
  Box,
)(({
  theme,
}: WCLThemeProps) => (
  {
    height: '100%',
    aspectRatio: '16/9',
    borderRadius: theme?.ourTheme.borders.borderRadius.medium,
    overflow: 'hidden',
  }
));

// the colored box for each student that's in the group with the current user
interface IndividualStudentContainerProps {
  isStudentOnline: boolean
}

export const IndividualStudentContainer = styled(
  Box,
  {
    shouldForwardProp: (prop) => prop !== 'isStudentOnline'
  },
)(({
  isStudentOnline,
  theme,
}: IndividualStudentContainerProps & WCLThemeProps) => (
  {
    cursor: 'pointer',
    padding: '10px',
    borderRadius: theme?.ourTheme.borders.borderRadius.medium,
    // if this student is online, bg color green. offline, purple
    backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentPurple1}`,
    ...(isStudentOnline === true && {
      backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentGreen1}`,
    }),
  }
));
