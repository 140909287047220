import { ColorsTypes } from '../interface';
import { dark } from './profiles/dark';
import { light } from './profiles/light';

// all colors used across the app.
export const colors: { light: ColorsTypes, dark: ColorsTypes, } = {
  // light mode
  light,
  // dark mode
  dark,
};
