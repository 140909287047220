import { HrCycle } from '../../common/utils/DatesTimes/HourCycle';

export const hourCycleOptions = [
  {
    value: HrCycle.hour12,
    name: '12 Hours'
  },
  {
    value: HrCycle.hour24,
    name: '24 Hours'
  }
];
