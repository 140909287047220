/**
 * @generated SignedSource<<7531bc31894ba74dad7ed1d581f11b22>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LessonsGroupclasssubFragment$data = {
  readonly groupcl: any;
  readonly " $fragmentType": "LessonsGroupclasssubFragment";
};
export type LessonsGroupclasssubFragment$key = {
  readonly " $data"?: LessonsGroupclasssubFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LessonsGroupclasssubFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonsGroupclasssubFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupcl",
      "storageKey": null
    }
  ],
  "type": "subs",
  "abstractKey": null
};

(node as any).hash = "bcf87e52209253cfdc779e9640ba1efd";

export default node;
