import { graphql } from 'babel-plugin-relay/macro';
import { DateTime } from 'luxon';
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';

/**
 * @RelayResolver
 * @onType users
 * @fieldName relayAge
 * @rootFragment relayAgeResolver
 */
export function relayAge(usersKey: any) {
  const data = readFragment(
    graphql`
      fragment relayAgeResolver on users {
        dob
      }
    `,
    usersKey
  );
  const birthdate = data?.dob ? DateTime.fromISO(data?.dob) : null;
  const age = birthdate !== null ? Math.abs(birthdate.diffNow('years').years).toFixed(0) : null;
  return age;
}
