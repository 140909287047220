import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { IconProps } from './interface';

export const CustomFontAwesomeIcon = styled(FontAwesomeIcon)(({ color, theme, hovercursor } : WCLThemeProps & IconProps) => (
  {
    // optionally show some type of cursor on hover
    ...(hovercursor === 'pointer' && {
      cursor: 'pointer',
    }),

    ...(color === 'inherit' && {
      color: 'inherit',
    }),
    ...(color === 'primary' && {
      color: `hsl(${theme?.ourTheme.colors.primary.base})`,
    }),
    ...(color === 'secondary' && {
      color: `hsl(${theme?.ourTheme.colors.secondary.base})`,
    }),
    ...(color === 'info' && {
      color: `hsl(${theme?.ourTheme.colors.info.base})`,
    }),
    ...(color === 'error' && {
      color: `hsl(${theme?.ourTheme.colors.error.base})`,
    }),
    ...(color === 'warning' && {
      color: `hsl(${theme?.ourTheme.colors.warning.base})`,
    }),
    ...(color === 'neutral' && {
      color: `hsl(${theme?.ourTheme.colors.neutral.base})`,
    }),

    // accent colors - light theme
    ...(theme?.themeMode === 'light' && color === 'accentGreen1' && {
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.base})`,
    }),
    ...(theme?.themeMode === 'light' && color === 'accentPurple1' && {
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.base})`,
    }),
    ...(theme?.themeMode === 'light' && color === 'accentPurple1Dark' && {
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.base})`,
    }),
    ...(theme?.themeMode === 'light' && color === 'accentBlue1' && {
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.base})`,
    }),
    ...(theme?.themeMode === 'light' && color === 'accentRed1' && {
      color: `hsl(${theme?.ourTheme.colors.accentRed1.base})`,
    }),
    ...(theme?.themeMode === 'light' && color === 'accentOrange1' && {
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.base})`,
    }),
    ...(theme?.themeMode === 'light' && color === 'accentYellow1' && {
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.base})`,
    }),

    // accent colors - dark theme
    ...(theme?.themeMode === 'dark' && color === 'accentGreen1' && {
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.base})`,
    }),
    // NOTE: we have to use a lighter purpler here, because in dark mode our page and card background are
    // both purple-ish. if we use base purple here, it's impossible to see the icons. BUT that means
    // purple icons cannot be used in purple buttons -- you need to use accentPurple1Dark icon color
    // if you use an icon within a button
    ...(theme?.themeMode === 'dark' && color === 'accentPurple1' && {
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentPurple1Dark' && {
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.base})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentBlue1' && {
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.base})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentRed1' && {
      color: `hsl(${theme?.ourTheme.colors.accentRed1.base})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentOrange1' && {
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.base})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentYellow1' && {
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.base})`,
    }),
  }
));
