import React from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import Gifts from './Gifts';

export const GiftPurchasesContainerQuery = graphql`
  query GiftPurchasesContainerQuery($userId: Int!, $after: String, $first: Int) {
    ...Gifts @arguments(userId: $userId, first: $first, after: $after)
  }
`;

export const GiftPurchasesContainer = ({ userData, pageSize }: any) => {
  // this query will get the data for Gifts
  const fragmentRef = useLazyLoadQuery(
    GiftPurchasesContainerQuery,
    {
      userId: userData.pk,
      first: pageSize, // count
      after: null, // cursor
    },
  );
  /*
    we are sending network request from routing and in routing, we are expecting teacher's id
    should be present and exist but what if user refreshed the page?
    What happened on page refresh. We verify JWT again and this verification can take some time
    and there are chances that teacher's id still not present in routing as JWT verification in
    on the fly.
    That's why, we are applying and checking if isAuthenticated flag is true only than allow
    next step else show the same loader glimmer.
  */
  return <Gifts fragmentRef={fragmentRef} pageSize={pageSize} />;
};
