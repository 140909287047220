import React from 'react';
import { useFragment } from 'react-relay';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  Grid2Ct, Grid2, Ty, Card, CardContent, Box, Ic,
} from '@languageconvo/wcl';
import { PurchaseRecieptFragment } from '../relay/PurchaseReciept';

interface PurchaseRecieptContainerProps {
  fragmentRef: any
}

export const PurchaseRecieptContainer = (props: PurchaseRecieptContainerProps) => {
  const {
    fragmentRef
  } = props;

  const fragmentRefPackageDetail = fragmentRef.orders_connection.edges[0].node;
  const data = useFragment(PurchaseRecieptFragment, fragmentRefPackageDetail);

  const pricePaid = `$${data.actual_purchase_price}`;
  // TODO: we need to using student's time zone from db here. right now this will be using
  //  the user's time zone from their browser which is *probably* correct, but we should
  //  use their time zone from db like we do everywhere else in the app
  const purchDate = DateTime.fromSeconds(data.created_at).toLocaleString({ dateStyle: 'medium' });
  const refNo = data.paypal_invoice_number;
  const { t } = useTranslation(['languageLocations']);
  const lang = t(`${data.locations.pk}`);
  const pkg = `${(data.duration / 3600)} Hours`;

  return (
    <Grid2Ct>
      <Grid2 xs={12} sm={10} smOffset={1} xl={6} xlOffset={3}>
        <Card>
          <CardContent>
            {/* logo, subtext */}
            <Grid2Ct sx={{ mb: 2, mt: 2 }}>
              <Grid2 xs={12}>
                <Box display="flex" justifyContent="center">
                  <img src={`${process.env.REACT_APP_CDNURL}/common/logo/logo.svg`} data-testid="logo" alt="Logo" height="auto" width="210" />
                </Box>
              </Grid2>

              <Grid2 xs={12} sx={{ mt: 1 }}>
                <Ty v="subheading" align="center"><Ic color="accentGreen1" iconName="check" iconStyle="duotone" /> Payment Successful</Ty>
              </Grid2>
            </Grid2Ct>

            {/* purchase date */}
            <LineItem
              title="Purchase Date"
              value={purchDate}
            />

            {/* amount paid */}
            <LineItem
              title="Amount Paid"
              value={pricePaid}
            />

            {/* language */}
            <LineItem
              title="Language"
              value={lang}
            />

            {/* package */}
            <LineItem
              title="Package"
              value={pkg}
            />

            {/* reference number */}
            <LineItem
              title="Reference Number"
              value={refNo}
            />

            <Box sx={{ mb: 2 }}> </Box>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};

// individual line item in the receipt
const LineItem = ({ title, value } : any) => (
  <>
    {/* sm and larger */}
    <Grid2Ct sx={{ display: { xs: 'none', sm: 'flex' } }}>
      <Grid2 xs={12} sm={6} smOffset={1}>
        <Ty>{title}</Ty>
      </Grid2>
      <Grid2 xs={12} sm={5}>
        <Ty><strong>{value}</strong></Ty>
      </Grid2>
    </Grid2Ct>

    {/* xs, mobile screens */}
    <Grid2Ct sx={{ display: { xs: 'flex', sm: 'none' } }}>
      <Grid2 xs={12} sm={6} smOffset={1}>
        <Ty removeMb align="center">{title}</Ty>
        <Ty align="center"><strong>{value}</strong></Ty>
      </Grid2>
    </Grid2Ct>
  </>
);
