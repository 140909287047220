import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  CardStandard, Ty, Grid2Ct, Grid2, Link, IcSvgList,
} from '@languageconvo/wcl';
import { GridEl } from './Common';
import { sitedata } from '../../../../../../utils/sitedata';

export const DiscoverTeachers = () => {
  // link to find a teacher page
  const clickableTypeObjFindteacher = {
    to: sitedata.url.app.teachersDiscover,
    component: NavLink,
  };

  return (
    <GridEl>
      <CardStandard
        titleText="Discover Teachers"
        titleIcon={IcSvgList.teacher1}
        color="accentRed1"
        cp={{ sx: { width: '100%' } }}
        clickableLink1={{ ...clickableTypeObjFindteacher }}
      >
        <Link
          to={sitedata.url.app.teachersDiscover}
          component={NavLink}
          linkStyle="nostyle"
        >
          <Grid2Ct>
            <Grid2 xs={12} display="flex">
              <Ty removeMb>Find a teacher for private lessons, any time</Ty>
            </Grid2>
          </Grid2Ct>
        </Link>
      </CardStandard>
    </GridEl>
  );
};
