import { commitLocalUpdate } from 'react-relay';
import AppEnvironment from '../../../../relay/AppEnvironment';
import { RelayAppSettingsVals } from '../RelayAppSettingsTypes';

// set the relay store groupClass.hasInteracted value to true
export const setHasInteractedTrue = () => {
  commitLocalUpdate(AppEnvironment, (store) => {
    const record = store.get(RelayAppSettingsVals.groupClassIdVal);
    if (record) {
      record.setValue(true, RelayAppSettingsVals.groupClassHasInteractedName);
    }
  });
};
