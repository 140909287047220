/**
 * @generated SignedSource<<1565792cc422c51d79bfce2469fabe3a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillsQuery$variables = {};
export type SkillsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SkillsAllFragment">;
};
export type SkillsQuery = {
  response: SkillsQuery$data;
  variables: SkillsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "display_order": "asc"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display_order",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_en",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SkillsAllFragment"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SkillsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "skills_levelsConnection",
        "kind": "LinkedField",
        "name": "skills_levels_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "skills_levelsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "skills_levels",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v0/*: any*/),
                    "concreteType": "skills",
                    "kind": "LinkedField",
                    "name": "skills",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description_en",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "examples_en",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": "skills(order_by:{\"display_order\":\"asc\"})"
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "skills_levels_connection(order_by:{\"display_order\":\"asc\"})"
      }
    ]
  },
  "params": {
    "cacheID": "e7f4ca5edce0ebe5e64fcec71ed4999c",
    "id": null,
    "metadata": {},
    "name": "SkillsQuery",
    "operationKind": "query",
    "text": "query SkillsQuery {\n  ...SkillsAllFragment\n}\n\nfragment SkillsAllFragment on query_root {\n  skills_levels_connection(order_by: {display_order: asc}) {\n    edges {\n      node {\n        ...SkillsDetailFragment\n        id\n      }\n    }\n  }\n}\n\nfragment SkillsDetailFragment on skills_levels {\n  display_order\n  title_en\n  skills(order_by: {display_order: asc}) {\n    title_en\n    display_order\n    description_en\n    examples_en\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a87f428099f80523ed4b48dd9ec8458";

export default node;
