import { graphql } from 'babel-plugin-relay/macro';

export const GetUnreadMessagesSubscription = graphql`
subscription GetUnreadMessagesSubscription {
  appmessaging_threads_connection {
    edges {
      node {
        id
        newest_receivedmessageid
        read_messageid
      }
    }
  }
}

`;
