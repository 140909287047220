import { graphql } from 'babel-plugin-relay/macro';

// This fragment contains lesson info i-e audio, video session tokens to connect with streaming
// library
export const LessonTimeCalculationFragment = graphql`
  fragment LessonTimeCalculationFragment on StuApptgroupClassroomenterOutput {
    dt {
      current
      duration
      endsAt
      secondsUntilEnd
      secondsUntilStart
      startsAt
    }
  }
`;
