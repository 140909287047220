import React, { Suspense } from 'react';
import { PurchaseRecieptQueryComponent } from './components/PurchaseReciept1';

// TODO: we need to handle:
//  - admin giving packages to users. right now the receipt will say price = $0 but we should
//    probably have better details than that
//  - gifts
export const PurchaseRecieptHome = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <Suspense fallback={<> </>}>
      <PurchaseRecieptQueryComponent />
    </Suspense>
  );
};
