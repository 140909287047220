import React, {
  Dispatch, SetStateAction, useEffect, useState
} from 'react';
import * as Sentry from '@sentry/react';
import { useFragment } from 'react-relay';
import {
  Grid2Ct, Grid2, Ty, Box, Card, CardContent, LottiePlayer, IcSvgList,
  useTheme, useMediaQuery, IcSvg,
} from '@languageconvo/wcl';
import scrollIntoView from 'scroll-into-view-if-needed';
import { WorldTourGetCountryDetailFragment$key } from '../relay/__generated__/WorldTourGetCountryDetailFragment.graphql';
import { WorldTourGetCountryDetailFragment } from '../relay/WorldTourGetCountryDetail';
import GiraffeRelaxed from '../../../../common/assets/lottie/giraffe_relaxed.json';
import { CountryFacts } from './CountryFunFacts';
import { AlignIconText } from '../../../../common/components/styling/AlignIconText';

interface SelectedCountryProps {
  selectedCountryNode: WorldTourGetCountryDetailFragment$key;
  setShowContinentPage: Dispatch<SetStateAction<boolean>>;
  setShouldStopRadio: Dispatch<SetStateAction<boolean>>;
  shouldStopRadio: boolean;
  shuffledFunFacts: any;
  setShuffledFunFacts: any;
  showFullOverview: boolean;
  setShowFullOverview: Dispatch<SetStateAction<boolean>>;
  showContinentPage: boolean;
}

export const SelectedCountry = ({
  selectedCountryNode, setShowContinentPage, setShouldStopRadio, shouldStopRadio,
  shuffledFunFacts, setShuffledFunFacts, setShowFullOverview, showFullOverview, showContinentPage
}: SelectedCountryProps) => {
  const selectedCountryData = useFragment(WorldTourGetCountryDetailFragment, selectedCountryNode);

  // IMPORTANT: if you change the deifnition of screensizeLarge here, you need to change the other
  //  place in global tour that we do the same
  // on small screens, we'll show a truncated version of the country overview
  const theme = useTheme();
  const screensizeLarge = useMediaQuery(theme.breakpoints.up('md'));
  let bgColor = `hsla(${theme?.ourTheme.colors.accentPurple1.lighter3}, 75%)`;
  let fontColor = `hsl(${theme?.ourTheme.colors.accentPurple1.darker1}) !important`;
  if (theme?.themeMode === 'light') {
    bgColor = `hsl(${theme?.ourTheme.colors.accentPurple1.lighter3})`;
    fontColor = `hsl(${theme?.ourTheme.colors.accentPurple1.darker1}) !important`;
  }

  // state to hold the playbackurl of the radio station, which we are using as src in audio
  // to play the
  const [radioStationUrl, setRadioStationUrl] = useState<string>('');

  // hold the currently selected radio station ID to manage loading state, we
  // are showoing loader untill a radio station starts playing
  const [selectedRadioId, setSelectedRadioId] = useState<string | null>(null);

  // whether or not the page has loaded. we set this so that we won't show contents until
  // page first loads, to avoid the page "jumping" vertically
  // eslint-disable-next-line
  const [pageHasLoaded, setPageHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    const cardToScrollTo = document.querySelector('[data-top="top"]');
    if (cardToScrollTo) {
      // Scroll to the element
      scrollIntoView(cardToScrollTo!, {
        behavior: 'auto',
        scrollMode: 'if-needed',
        block: 'start',
      });
    }
  }, [showContinentPage]);

  useEffect(() => {
    if (screensizeLarge) {
      setShowFullOverview(true);
    } else {
      setShowFullOverview(false);
    }
    setPageHasLoaded(true);
  }, [screensizeLarge, selectedCountryData, setShowFullOverview]);

  // loading state for radio playback transitions
  const [loading, setLoading] = useState(false);

  // clicking on radio station
  const handleRadioStationClick = (radio: any) => {
    setShouldStopRadio(false);
    // update URL to play the chosen station
    setRadioStationUrl(radio.playback_url);
    // storing the radioId to highlight the playing radio on the UI.
    setSelectedRadioId(radio.id);
  };

  // this useEffect will setRadioStationUrl to '' to stop the radio url
  useEffect(() => {
    if (shouldStopRadio) {
      setRadioStationUrl('');
    }
  }, [shouldStopRadio]);

  const funFactsOfSelectedCountry = selectedCountryData.practice_world_funfacts;

  // randomized fun facts when country data changes, to show the random facts
  useEffect(() => {
    if (funFactsOfSelectedCountry.length) {
      const randomizedArray = shuffleArray(funFactsOfSelectedCountry);
      setShuffledFunFacts(randomizedArray);
    } else {
      // do nothing, we are already set the inital value as empty[]
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryData]);

  // throughing an error is selected country data is null
  if (selectedCountryData === null) {
    throw new Error('Hmm, selected country data is null in global tour page');
  }

  const radioStations = selectedCountryData.practice_world_radiostations;

  if (radioStations.length === 0) {
    Sentry.captureException(
      new Error(`No Radio Station Found in the selected country page for country ${selectedCountryData.country_name}`),
      {
        extra: {
          response: selectedCountryData,
          radioStations: selectedCountryData.practice_world_radiostations
        }
      }
    );
  }

  // short overview of the country, which we'll display on small screens
  const shortOverview = `${selectedCountryData.overview?.substring(0, 100)} ...`;

  // prevent the page from briefly "jumping" on large screens, on pageload. that's caused by
  // initially, briefly, showing the short overview text then setShowFullOverview running, causing
  // the full overview to display
  if (!pageHasLoaded) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Grid2Ct>
          {/* country name, flag */}
          <Grid2 xs={12} sm={7} xl={8} order={{ xs: 2, sm: 1 }} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
            <AlignIconText>
              <Box sx={{
                borderRadius: '50%',
                width: '45px',
                height: '45px',
                background: theme.ourTheme.colors.cp.bgInnerCard.accentGreen1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              >
                <img src={selectedCountryData.flag_image!} alt="flag_image" width="28px" />
              </Box>

              <Ty v="h1New" removeMb cp={{ sx: { pl: 2 } }}>
                {selectedCountryData.country_name}
              </Ty>
            </AlignIconText>
          </Grid2>

          {/* change country button */}
          <Grid2 xs={12} sm={5} xl={4} order={{ xs: 1, sm: 2 }}>
            <Box sx={{
              display: 'flex',
              // on xs, center the button. otherwise put it at the end
              justifyContent: { xs: 'center', sm: 'flex-end' },
              mb: { xs: 3, sm: 0 },
            }}
            >
              {/* TODO: this needs to be our card small color, this was a manual hack
              to get the button to look correct */}
              <Box
                onClick={() => { setShowContinentPage(true); }}
                sx={{
                  background: bgColor,
                  color: fontColor,
                  padding: '16px 24px 16px 24px',
                  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
                  cursor: 'pointer'
                }}
              >
                <Grid2Ct>
                  <Grid2 xs={12}>
                    <AlignIconText>
                      <IcSvg icon={IcSvgList.earth2} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
                      <Ty removeMb cp={{ color: fontColor }}><strong>Change Country</strong></Ty>
                    </AlignIconText>
                  </Grid2>
                </Grid2Ct>
              </Box>
            </Box>
          </Grid2>

          {/* overview */}
          <Grid2 xs={12} xl={9} order={{ xs: 3 }}>
            <Ty v="h2New">Overview</Ty>

            {/* on large screens, show the full overview. on small screens, show a
            shortened version and allow user to click "Read More" to see all the text */}
            {showFullOverview ? (
              <Ty>{selectedCountryData.overview}</Ty>
            ) : (
              <Ty>
                {shortOverview} &nbsp;&nbsp;
                <span
                  onClick={() => setShowFullOverview(true)}
                  role="button"
                  tabIndex={0}
                  onKeyUp={() => setShowFullOverview(true)}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <strong>Read More</strong>
                </span>
              </Ty>
            )}
          </Grid2>

          {/* radio stations */}
          <Grid2 xs={12} order={{ xs: 4 }}>
            <Grid2Ct>
              <Grid2 xs={12} sx={{ pb: { xs: 1, sm: 0 } }}>
                <Ty v="h2New">Listen to Radio</Ty>
              </Grid2>
            </Grid2Ct>

            {/* audio element, if there are any radio stations for this country */}
            {radioStations.length ? (
              <Grid2Ct>
                <Grid2 xs={12} display="flex" justifyContent="center" sx={{ pt: { xs: 1, sm: 0 } }}>
                  {/* eslint-disable jsx-a11y/media-has-caption */}
                  <audio
                    controls
                    src={radioStationUrl!}
                    autoPlay
                    onLoadStart={() => setLoading(true)}
                    onPlay={() => setLoading(false)}
                    // on xs screens this stops the audio element from overflowing
                    style={{ maxWidth: '100%' }}
                  />
                </Grid2>
              </Grid2Ct>
            ) : (
              // should never happen, we should have radio stations
              <Grid2Ct>
                <Grid2 xs={12} order={{ xs: 5 }}>
                  <Ty>
                    Our apologies, we don&apos;t have any radio stations in this
                    country yet.
                  </Ty>
                </Grid2>
              </Grid2Ct>
            )}

            {/* each radio station */}
            <Grid2Ct sx={{ mt: 3 }}>
              {radioStations.map((radio) => (
                <Grid2 xs={12} md={6} xl={4}>
                  <Box
                    onClick={() => handleRadioStationClick(radio)}
                    sx={{
                      background: selectedRadioId === radio.id
                        ? theme.ourTheme.colors.cp.bgInnerCard.accentOrange1
                        : theme.ourTheme.colors.cp.bgInnerCard.accentGreen1,
                      padding: '16px 24px 16px 24px',
                      borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
                      cursor: 'pointer'
                    }}
                  >
                    <Grid2Ct>
                      <Grid2 xs={12}>
                        <AlignIconText>
                          {/* show a red radio icon if this station is currently playing. loading
                          icon if it's currently loading. green radio icon for other stations */}
                          {/* eslint-disable-next-line no-nested-ternary */}
                          <IcSvg icon={selectedRadioId === radio.id ? (loading ? IcSvgList.hulahoop1 : IcSvgList.radio1) : IcSvgList.radio2} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
                          <Ty removeMb>{radio.name}</Ty>
                        </AlignIconText>
                      </Grid2>
                      <Grid2 xs={12} sx={{ pt: 0 }}>
                        <Ty v="small" removeMb>{radio.city}, {selectedCountryData.country_name}</Ty>
                      </Grid2>
                    </Grid2Ct>
                  </Box>
                </Grid2>
              ))}
            </Grid2Ct>
          </Grid2>

          {/* fun facts */}
          {shuffledFunFacts.length > 0 && (
          <Grid2 xs={12} order={{ xs: 5 }}>
            <CountryFacts
              funFactsOfSelectedCountry={shuffledFunFacts}
            />
          </Grid2>
          )}
        </Grid2Ct>
      </CardContent>
    </Card>
  );
};

export const ErrorBoundryForGlobalTour = () => (
  <Grid2Ct>
    <Grid2 xs={12} sx={{ mt: 1 }}>
      <Card>
        <CardContent>
          <Grid2Ct sx={{ minHeight: '400px' }}>
            {/* left side text */}
            <Grid2
              xs={12}
              md={5}
              mdOffset={2}
              lg={6}
              lgOffset={1}
              xl={5}
              xlOffset={1}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Ty align="center" removeMb v="h2New">
                Hmm, something went wrong. Please try refreshing the page!
              </Ty>
            </Grid2>

            {/* right side lottie animation. justifyContent to horizontally center
            alignItems to vertically push lottie down to the bottom of the container */}
            <Grid2
              xs={12}
              md={4}
              lg={5}
              xl={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: { xs: 4, md: 0 },
                mb: { xs: 4, md: 0 },
              }}
            >
              <LottiePlayer animationSrc={GiraffeRelaxed} animationHeight={250} />
            </Grid2>
          </Grid2Ct>
        </CardContent>
      </Card>
    </Grid2>
  </Grid2Ct>
);

/*
    the shuffleArray function uses The Fisher-Yates algorithm begins by
    looping through,starting from the last element, and at each iteration,
    it randomly selects an index within the unshuffled portion of the array and
    swaps the element at that index with the element at the current iteration index,
    effectively shuffling the array randomly
  */
export const shuffleArray = (array: any) => {
  // making the copy of the array instead of mutating the actual array,
  const shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1)); // Get a random index
    // Swap the elements
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray; // Return the shuffled shuffledArray
};
