/**
 * @generated SignedSource<<88a3605c1fd622a10130af2fb18f7dc7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillsAllFragment$data = {
  readonly skills_levels_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"SkillsDetailFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "SkillsAllFragment";
};
export type SkillsAllFragment$key = {
  readonly " $data"?: SkillsAllFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillsAllFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillsAllFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "display_order": "asc"
          }
        }
      ],
      "concreteType": "skills_levelsConnection",
      "kind": "LinkedField",
      "name": "skills_levels_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "skills_levelsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "skills_levels",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SkillsDetailFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "skills_levels_connection(order_by:{\"display_order\":\"asc\"})"
    }
  ],
  "type": "query_root",
  "abstractKey": null
};

(node as any).hash = "41ac4a79083363dd4aa25d75aff8d20f";

export default node;
