import { graphql } from 'babel-plugin-relay/macro';

// mutation call for individual package detail:

export const GetPkgDetailsMutation = graphql`
mutation GetPkgDetailsMutation($invoiceNumber: String!) {
  stu_orders_getindividualpkgdetails(invoiceNumber: $invoiceNumber) {
    success
    data {
      hoursPurchased
      hoursRemaining
      hoursUsed
      invoiceNumber
    }
  }
}`;
