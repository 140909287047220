import React from 'react';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import {
  PrivateSchedLsnDataQuery,
  PrivateSchedLsnDataCountteachersFragment,
} from '../../../../relay/posttrial/PrivateSchedLsnData';
import { PrivateSchedLsnDataCountteachersFragment$key } from '../../../../relay/posttrial/__generated__/PrivateSchedLsnDataCountteachersFragment.graphql';
import {
  PrivateSchedLsnSlider,
} from './PrivateSchedLsn2Slider';
import { PrivateSchedLsnEmpty } from './PrivateSchedLsn2Empty';

export const PrivateSchedLsnGetdata = () => {
  const response: any = useLazyLoadQuery(
    PrivateSchedLsnDataQuery,
    { }
  );

  // the Countteachers fragment, to give us how many teachers the user has favorited
  // if the user has favorited at least one teacher, we'll show a slider with the teachers
  // in it. otherwise we'll show a "add teachers to your favorites!"
  const allTchFragmentRef: PrivateSchedLsnDataCountteachersFragment$key = response;
  const allTeachers = useFragment(PrivateSchedLsnDataCountteachersFragment, allTchFragmentRef);
  let countFavTeachers = 0;
  allTeachers.resources_connection.edges.forEach((el) => {
    if (el.node.relayFavorited === 1) {
      countFavTeachers += 1;
    }
  });
  const showSlider = countFavTeachers > 0;

  // list of *all* teachers (including teachers the user has *not* favorited). this is a
  // separate fragment, for use in each individual slider card
  const { resources_connection } = response;

  return (
    <>
      {/* show slider or "add a teacher to your favorites!" */}
      { showSlider ? (
        <PrivateSchedLsnSlider allTch={resources_connection} />
      ) : (<PrivateSchedLsnEmpty />)}
    </>
  );
};
