import React from 'react';
import {
  Grid2, Grid2Ct, Dialog,
} from '@languageconvo/wcl';
import { Modalview2Contents } from './Modalview2Contents';

interface Props {
  modalstate: boolean
  modalcontents: number
  handleclose: any
}

/* A modal we can use throughout the classroom, to display notifs to the user
    Here we add the modal html to the page. In the child component we handle the contents
*/
export const Modalview1Setup = ({
  modalstate,
  modalcontents,
  handleclose,
}: Props) => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <Dialog
      isOpen={modalstate}
      onClose={handleclose}
      width="sm"
      color="accentRed1"
    >
      <Grid2Ct>
        <Grid2 xs={12}>
          <Modalview2Contents contents={modalcontents} />
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};
