import React from 'react';
import {
  Card, CardContent, Grid2Ct, Grid2, Ty, LottiePlayer,
} from '@languageconvo/wcl';
import GiraffeTeacher from '../../../../common/assets/lottie/giraffeteacher.json';

// we don't yet offer group lessons for all languages yet
export const LanguageNotAvailableYet = () => (
  <Grid2Ct>
    <Grid2 xs={12} sx={{ mt: 1 }}>
      <Card>
        <CardContent>
          <Grid2Ct sx={{ minHeight: '400px' }}>
            {/* left side text */}
            <Grid2
              xs={12}
              md={5}
              mdOffset={2}
              lg={6}
              lgOffset={1}
              xl={5}
              xlOffset={1}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Ty align="center" removeMb v="h2New">
                Our apologies, group classes are only available for Spanish at the
                moment. We are adding more languages quickly, message us and check back soon!
              </Ty>
            </Grid2>

            {/* right side lottie animation. justifyContent to horizontally center
            alignItems to vertically push lottie down to the bottom of the container */}
            <Grid2
              xs={12}
              md={4}
              lg={5}
              xl={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: { xs: 4, md: 0 },
                mb: { xs: 4, md: 0 },
              }}
            >
              <LottiePlayer animationSrc={GiraffeTeacher} animationHeight={250} />
            </Grid2>
          </Grid2Ct>
        </CardContent>
      </Card>
    </Grid2>
  </Grid2Ct>
);
