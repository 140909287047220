import {
  Grid, Box, styled, WCLThemeProps
} from '@languageconvo/wcl';

// #region left side of page -- user preferences

export const GridWithNoPadding = styled(Grid)(() => ({
  padding: '0px !important',
  height: '100vh',
}));

export const LogoMain = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '30px',
}));

export const ContentMain = styled(Box)(() => ({
  width: '70%',
  marginLeft: '15%',
  paddingBottom: '30px'
}));

export const EmailBox = styled(Box)(() => ({
  marginTop: '16px'
}));

export const AlertBox = styled(Box)(() => ({
  marginTop: '11px'
}));

export const CheckboxMain = styled(Box)(() => ({
  marginTop: '20px'
}));

export const UnsubscribeBox = styled(Box)(() => ({
  marginTop: '34px',
}));

export const ContentLoader = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '87px',
  textAlign: 'center',
  padding: '0 40px'
}));

// #endregion

// #region right side of page -- background image

export const ImageGrid = styled(Grid)<WCLThemeProps>(({ theme }) => ({
  padding: '0px !important',
  height: '100vh',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const BackgroundDiv = styled(Box)(() => ({
  height: '100vh',
  backgroundImage: `url('${process.env.REACT_APP_CDNURL}/uis/pages/emailpref/bg.jpg')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}));

// #endregion
