import { ComponentsTypes } from './interface';

// here we define some values for specific, important components. values should only be put here in very specific
// use-cases, mainly:
//  - the component is not in wcl, and exists in both uis and uit
// do NOT put colors in here! put them in the colors file
export const components: ComponentsTypes = {
  // transitions
  transition: {
    // for page-level things, such as the appbar, navbar, card, grid, etc. this transition mainly applies when
    // the user changes from light to dark mode
    page: 'all .4s ease-in-out',
    // for items the user can hover on or interact with, such as buttons, avataricon, etc. we want this to be
    // fairly fast, users don't want to wait a long time for a button to show as active
    item: 'all .2s ease-in-out',
  },

  // left side navigation bar. for sizing it
  navbar: {
    // the width of the entire navbar when expanded
    navbarWidthExpanded: '260px',
    // important: this should always be navbarWidthExpanded - 32px
    navbarWidthExpandedStack: '228px',
    // the width of the entire navbar when collapsed. if this is too small, it will cut off the icons, too large
    // and there will be whitespace to the right of the icons
    navbarWidthCollapse: '108px',

    // this moves the entire column of icons left or right within the navbar. too small and all the icons will be
    // aligned near the left edge of the page
    listPadding: '16px',

    // this forces the height of each item in the navbar. too large, and the green background will stretch vertically
    // and not be square
    itemHeight: '60px',
    // the width of the green highlighted box, when the navbar is collapsed
    itemWidthCollapsed: '76px',
    // the left padding of the icon inside the green highlighted area. if it's too small, the icon will be next to
    // the left edge of the green highlighted box
    itemPaddingLeft: '20px',
    // the vertical spacing between each green box
    itemMarginTop: '8px',
    // margin to the right of the icon. when the navbar is expanded, this pushes the text to the right, away from
    // the icon so it's not bumping against the icon
    itemMarginRight: '18px',

    // drawer expanded
    // these are specific to when the drawer is expanded
    // the vertical spacing between each green box
    itemExpandedMarginTop: '6px',
    itemExpandedPaddingLeft: '30px',

    // the width and height of the icons. this needs to be sized correctly so that the icon is in the middle of the
    // green background
    iconSize: '36px',
  },
};
