/* eslint-disable */
import { graphql } from 'babel-plugin-relay/macro';

export const GetEmailPreferences = graphql`
query GetEmailPreferencesQuery {
	users_connection {
	  edges{
		node {
			email
			random_id_public
			users_emailunsub {
				getting_started_tips
				messages_coach
				messages_teacher
				unsubscribed_all
				update_announcements
				account_notices
				new_teacher_available
			}
		}
	  }
	}
}
`;
