import React from 'react';
import { LayoutPosttrial0 } from '../../posttrial/LayoutPosttrial0';

interface Props {
  dt: any
}

// TODO: when john is back from vacation, need to add some instructions for these students.
//  right now, we're just displaying the normal pages for paying students

/* there aren't currently any teachers of this language who give free trial lessons
*/
export const TrialNoTeachersAvailable = ({ dt }: Props) => {
  // eslint-disable-next-line
  console.log(dt);

  return (
    <>
      {/* normal display */}
      <LayoutPosttrial0 />
    </>
  );
};
