import * as Sentry from '@sentry/react';
import * as jose from 'jose';
import { setStuSecurityToLCStorage } from './lcStorage';
import { sitedata } from './sitedata';
import { sleep } from '../common/utils/sleep';
import { removeJWTFromLocalStorageAndLogoutUser } from './logout';
import { AxiosWrapper, AxiosErrorsExpected } from '../common/utils/AxiosWrapper/AxiosWrapper';

type ReturnJWT = {
  // the jwt the user can use for auth
  jwt: string;
  // this is our "app version timestamp", which denotes what the currently released version of
  // the app is. used for comparing to what version the user has and refreshing the page so that
  // they get the newest version
  timeStamp: any;
};

// TODO: remove this after testing the new function
// function which will generate a new jwt from refresh token
// and update the local storage accordingly.
// In case of any error loggin in sentry...
export const generateNewJWTFromRefreshToken: () => Promise<ReturnJWT|null> | null = async () => {
  let jwtObject;
  let timeStamp;
  const requestTimeout = 5000;
  const { generateJWTFromRefreshTokenErrors } = sitedata;
  for (let i = 0; i <= 2; i += 1) {
    try {
      // we wil abort the request if it takes more than 5 seconds.
      // we need to create the instance of abort here because, if we don't
      // get the response with-in 5 seconds we will cancel that request.
      const c = new AbortController();
      // this timeout will execute after 5 seconds of call being made but didn't returned anything
      // we want to cancel that request in that case that's why we have used the abort function here
      const id = setTimeout(() => c.abort(), requestTimeout);
      // eslint-disable-next-line no-await-in-loop
      const promise = await fetch(`${process.env.REACT_APP_BASE_REST_API}/usersgeneratejwt`, {
        credentials: 'include',
        signal: c.signal,
      });
      // after the timeout has been executed we also need to clear the timeout.
      clearTimeout(id);
      // eslint-disable-next-line no-await-in-loop
      const response = await promise.json();

      // here we dont always get users_generate_jwt -- the reason being, in cases of our
      // expected errors (InvalidOrExpiredRefreshToken, FailedToGenerateJWT) we dont receive
      // the users_generate_jwt node
      jwtObject = response?.users_generate_jwt || null;
      timeStamp = response?.users_generate_jwt?.data?.versionTimeStamp;
      const jwt = jwtObject?.jwt;
      const errorCode = response?.code;

      // TODO: if the last `else` in this were to hit repeatedly for a user -- the user is
      // refreshing the page numerous times, and that error is occurring each time for some
      // reason, the user will be stuck in applevel error. they won't be able to get back to
      // the app. what we need to do is:
      //    1. the applevel error should show a button to the user to go to the Log In page
      //    2. the log in page should attempt to get a valid jwt (call this code here) but
      //      if that fails, delete jwt from localstorage, delete refresh token, and prompt
      //      the user to log in. this way even if an error is occurrring repeatedly, the user
      //      will be able to log back in and get new jwt/refresh credentials
      if (jwt) {
        // decoding the JWT.
        const decodedJWT = jose.decodeJwt(jwt);

        // checking if the jwt is valid or not.
        if (Object.keys(decodedJWT).includes('exp')) {
          setStuSecurityToLCStorage({ jwt: jwtObject.jwt });
          break;
        } else {
          Sentry.captureMessage('BIG ERROR:there is a user who has a jwt without any expiration time.');
        }

        // if an error occurred, we'll check to see if it's a specific error type and
        // if so, potentially log the user out
      } else if (errorCode === generateJWTFromRefreshTokenErrors.InvalidOrExpiredRefreshToken) {
        // eslint-disable-next-line no-await-in-loop
        await removeJWTFromLocalStorageAndLogoutUser();
        break;

        // this error only occurs in exceptional circumstances; the backend
        // had some kind of problem generating a jwt. the backend itself extensively
        // logs this scenario
      } else if (errorCode === generateJWTFromRefreshTokenErrors.FailedToGenerateJWT) {
        Sentry.captureException(response.error);
        throw new Error('FailedToGenerateJWT');

        // in case of 500, we are logging a message in sentry, because this is expected maybe
        // the server is over loaded or not able to responed because it is down,
        // when the fetch call is made 
      } else if (promise.status === 500) {
        throw new Error('ServerReturned500');

        // this will occur if we get some unexpected error from our server
        // usually this shouldn't happen, if it does then we need to check is our 
        // backend and hasura working as expected?
      } else {
        Sentry.captureMessage('Unexpected Error: unable to generate jwt, non of our error codes matches nor 500 from server');
        Sentry.captureException(response.error);
        throw new Error('Unexpected Error: unable to generate jwt, non of our error codes matches nor 500 from server');
      }

      // if there is error due to database is done or backend is down
      // then we are trying again for 1 time
    } catch (err: any) {
      if (i < 2) {
        // if the error occured, before retrying we pause a bit to hopefully give the backend time
        // to recover from whatever problem it encountered
        // eslint-disable-next-line no-await-in-loop
        await sleep((i + 1) * 200);

        // we only want to log the error in sentry if the error doesn't occured due to following
        // reasons, because these are the expected cases and due to these cases we have used looping
        // so we can retry in these cases.
        if (err.message !== 'FailedToGenerateJWT' && err.message !== 'ServerReturned500' && err.name !== 'AbortError') {
          Sentry.captureException(err);
        }
      } else {
        Sentry.captureException(err);
      }
    }
  }

  if (jwtObject === null) {
    return null;
  }

  return { jwt: jwtObject.jwt, timeStamp };
};

// make an api call attempting to get a new jwt for the user. will return null if that api
// call is not successful, otherwise it will return the jwt and a timeStamp 
export const generateJwtFromRefreshTokenNew: () => Promise<ReturnJWT|null> = async () => {
  let jwtObject = null;
  const axiosWrapper = new AxiosWrapper();

  // these are expected error codes our api can return
  const { generateJWTFromRefreshTokenErrors } = sitedata;

  // we'll attempt to make this api call multiple times. its important we get a new jwt otherwise
  // the user cannot use the app, so if the backend is briefly down we pause then retry again
  for (let i = 0; i <= 2; i += 1) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const response = await axiosWrapper.postApiRequest(
        // dont need to add an endpoint to the graphql base url, thus this empty string
        '',
        {
          // our graphql api endpoint
          baseURL: `${process.env.REACT_APP_BASE_API}`,
          // this passes the cookies to our backend, which is needed; the refresh token is in a
          // http only cookie
          withCredentials: true,
        },
        {
          query: `mutation GetJwtFromRefreshToken {
              users_generate_jwt {
                jwt
                data {
                  versionTimeStamp
                }
              }
            }
          `
        }
      );

      // if we got the jwt in our response, the api call was successful. set it to local storage
      // and do a few checks
      if (response?.data?.data?.users_generate_jwt?.jwt) {
        const theJwt = response.data.data.users_generate_jwt.jwt;
        const theTs = response.data.data.users_generate_jwt.data.versionTimeStamp;
        jwtObject = {
          jwt: theJwt,
          timeStamp: theTs,
        };

        // store the jwt in localstorage
        setStuSecurityToLCStorage({ jwt: theJwt });

        // do a check to ensure that the jwt has an "exp" node. if it does not that's a huge
        // problem, which we'll log
        checkJwtExpNode(theJwt);

        // dont try the api call again, we were successful. so break out of the loop
        break;

      // the api call was not successful, but we got an error code in the format that we expect
      // from the backend. we'll check which known error code it is, and act appropriately
      } else if (response?.data?.errors[0]?.extensions?.code) {
        const errCode = response?.data?.errors[0]?.extensions?.code;

        // log the user out, their refresh token has expired or was invalid; they need to log
        // in again so they can get a new token
        if (errCode === generateJWTFromRefreshTokenErrors.InvalidOrExpiredRefreshToken) {
          // this removes jwt from localstorage, removes legacy app cookie, and does a number
          // of other important things. in the end, it redirects the user to the log in page
          // eslint-disable-next-line no-await-in-loop
          await removeJWTFromLocalStorageAndLogoutUser();

        // known error that should rarely ever happen, log to sentry
        } else if (errCode === generateJWTFromRefreshTokenErrors.FailedToGenerateJWT) {
          Sentry.captureException(
            new Error('generateJwtFromRefreshTokenNew produced FailedToGenerateJWT error code'),
            {
              extra: {
                code: errCode,
                res: response,
              }
            }
          );

        // totally unexpected code, log to sentry
        } else {
          Sentry.captureException(
            new Error('generateJwtFromRefreshTokenNew unexpected error code'),
            {
              extra: {
                code: errCode,
                resDtErr: response?.data?.errors[0],
                iVal: i,
              }
            }
          );
        }

      // else we got a completely unexpected response. we'll log
      } else {
        Sentry.captureException(
          new Error('generateJwtFromRefreshTokenNew completely undexpected response'),
          {
            extra: {
              res: response,
            }
          }
        );
      }

    // some other error occurred such as a 500, or a timeout. many of these are expected, things
    // like the user's network connection being down. AxiosErrorsExpected has those, so we'll check
    // to see if the error is one of those commonly expected errors; if it is not, log the error
    } catch (e: any) {
      let isExpectedErr = false;
      if (e?.code) {
        if (AxiosErrorsExpected.includes(e.code)) {
          isExpectedErr = true;
        }
      }

      if (!isExpectedErr) {
        Sentry.captureException(e);
      }
    }

    // add a little pause in-between our 2nd and third attempts at making the api call. gives
    // the backend a chance to recover if it's down for some reason
    // eslint-disable-next-line no-await-in-loop
    await sleep((i + 1) * 200);
  }

  return jwtObject;
};

// a simple check of the jwt to ensure it includes an "exp" node, which is a vital piece of
// security; without it, the jwt *never* expires. we log here just in case that occurs
function checkJwtExpNode(jwt: string) {
  // we don't want this breaking to break any of our main code flow, thus this try/catch
  try {
    const decodedJWT = jose.decodeJwt(jwt);
    if (!Object.keys(decodedJWT).includes('exp')) {
      Sentry.captureMessage('BIG ERROR:there is a user who has a jwt without any expiration time.');
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}
