/**
 * @generated SignedSource<<bc64164b487838db2afbb91819195471>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PurchasePayQuery$variables = {
  paypal_payment_id: number;
};
export type PurchasePayQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GetPackDetails">;
};
export type PurchasePayQuery = {
  response: PurchasePayQuery$data;
  variables: PurchasePayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "paypal_payment_id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchasePayQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "paypal_payment_id",
            "variableName": "paypal_payment_id"
          }
        ],
        "kind": "FragmentSpread",
        "name": "GetPackDetails"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PurchasePayQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "paypal_payment_id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "pk"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "packsConnection",
        "kind": "LinkedField",
        "name": "packs_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "packsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "packs",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d5d5426b34ee7c7bbb94b79630331fe",
    "id": null,
    "metadata": {},
    "name": "PurchasePayQuery",
    "operationKind": "query",
    "text": "query PurchasePayQuery(\n  $paypal_payment_id: Int!\n) {\n  ...GetPackDetails_jF91r\n}\n\nfragment GetPackDetails_jF91r on query_root {\n  packs_connection(where: {pk: {_eq: $paypal_payment_id}}) {\n    edges {\n      node {\n        location_id\n        price\n        duration\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "849ad527a5ce8cd31dcf13af2423f8a7";

export default node;
