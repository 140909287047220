import React, { useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { OrdersHistoryTableModalPurchdetails1 } from './OrdersHistoryTableModalPurchdetails1';
import { Orders } from './Orders';
import { GetUserIdForOrderQuery } from '../relay/GetUserIdForOrder';
import { OrdersHistoryTableModalNotes } from './OrdersHistoryTableModalNotes';

interface Props {
  pageSize: number,
}

// #region query and fragment

const OrderHistoryTableQuery = graphql`
  query OrdersHistoryTableQuery($after: String, $first: Int, $userId: bigint!) {
    ...Orders @arguments(first: $first, after: $after, userId: $userId)
  }
`;

// #endregion

/** History of private lesson purchases. this includes:
 * - regular, normal purchases that are active
 * - regular, normal purchases that have been refunded
 * - gifts that I *redeemed*
 * 
 * See the more detailed notes on the orders fragment, in Orders.tsx
 */
export const OrdersHistoryTable = ({ pageSize }: Props) => {
  const [noteToDisplay, setNoteToDisplay] = useState<string>('');
  const userData: any = useLazyLoadQuery(GetUserIdForOrderQuery, {});
  // holds the invoice number of the purchase that the user has clicked the "more detail"
  // button on. we use this to display the more detail modal
  const [moredetailInvoiceNumber, setMoredetailInvoiceNumber] = useState<number>();

  // more detail modal
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, invoiceNumber: number) => {
    event.preventDefault(); // preventing onClick to auto reload
    setAnchorEl(event.currentTarget);
    setMoredetailInvoiceNumber(invoiceNumber);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // notes icon modal
  const [anchorElForNoteIconModal, setAnchorElForNoteIconModal] = React.useState<
  HTMLButtonElement | null
  >(null);
  const handleClickNoteIcon = (event: React.MouseEvent<HTMLButtonElement>, note: string) => {
    event.preventDefault(); // preventing onClick to auto reload
    setAnchorElForNoteIconModal(event.currentTarget);
    setNoteToDisplay(note);
  };
  const handleCloseNoteIconModal = () => {
    setAnchorElForNoteIconModal(null);
  };

  // run the query
  const fragmentRef = useLazyLoadQuery(
    OrderHistoryTableQuery,
    {
      first: pageSize, // count
      after: null, // cursor
      userId: userData.users_connection.edges[0].node.pk
    },
  );

  return (
    <div>
      {/* table of purchases */}
      <Orders
        fragmentRef={fragmentRef}
        pageSize={pageSize}
        handleClick={handleClick}
        handleClickNoteIcon={handleClickNoteIcon}
      />

      {/* more detail modal */}
      {moredetailInvoiceNumber !== undefined && (
      <OrdersHistoryTableModalPurchdetails1
        anchorEl={anchorEl}
        handleClose={handleClose}
        refNumber={moredetailInvoiceNumber}
      />
      )}

      {/* notes modal */}
      {anchorElForNoteIconModal && (
      <OrdersHistoryTableModalNotes
        anchorEl={anchorElForNoteIconModal}
        handleClose={handleCloseNoteIconModal}
        noteToDisplay={noteToDisplay}
      />
      )}
    </div>
  );
};
