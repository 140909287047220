import { graphql } from 'babel-plugin-relay/macro';

export const LessonsQuery = graphql`
  query LessonsQuery {
    subs_connection {
      edges {
        node {
          ...LessonsGroupclasssubFragment
        }
      }
    }
  }
`;

export const LessonsGroupclasssubFragment = graphql`
  fragment LessonsGroupclasssubFragment on subs {
    groupcl
  }
`;
