import React, {
  useEffect, useState, Suspense
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Grid2Ct,
} from '@languageconvo/wcl';
import { TabsDiscoverTeachers, TabsPrivateLessons } from './components/Navtabs';
import { TeacherProfileLoader } from './components/TeacherProfileLoader';
import { updateDiscoverTeacherNodeInLCSettings } from '../../../../utils/lcStorage';
import TeacherProfileComponent from './components/TeacherProfileComponent';

export const TeachersProfile = ({ mainRoute }: { mainRoute: number }) => {
  const [waitingForLoading, setWaitingForLoading] = useState(mainRoute === 2);
  // which tab to show (profile, schedule, or reservation). if a student has come to the teacher's
  // page from the "find a teacher" page, they are most likely first interested in seeing the
  // teacher's profile (tab 1). if they have come from the "1-on-1 lessons, my teachers" page
  // they are most likely interested in seeing the teacher's schedule (tab 2) so we show
  // that one first
  let initialTabToShow = 1;
  if (mainRoute === 2) {
    initialTabToShow = 2;
  }
  const [tabToShow, setTabToShow] = useState(initialTabToShow);

  // get the teacher's id from the url; we need the id to display old legacy iframe pages
  const [searchParams] = useSearchParams();
  const tchId = searchParams.get('id');

  useEffect(() => {
    /*  we want the “Teachers Profile” tab to open (the reason for this is,
    students going here are probably most interested in the teacher’s
     profile page, not their schedule). so we are showing teacher's profile page to student */
    if (mainRoute === 2) {
      setTabToShow(2);
      setTimeout(() => {
        setWaitingForLoading(() => false);
      }, 1000);
      setWaitingForLoading(() => true);
    } else if (mainRoute === 1) {
      const uuid = searchParams.get('uuid');
      // Update tchscrolled in localstorage.
      updateDiscoverTeacherNodeInLCSettings({ tchscrolled: uuid });
    }
    // eslint-disable-next-line
  }, [tchId]);

  return (
    <Grid2Ct>
      {/* nav tabs
          if the user came to this page from the "find a teacher" page we show one set of tabs, if
          they came from the "1-on-1 lessons / my teachers" page, we show a different set of tabs
       */}
      {mainRoute === 1 ? <TabsDiscoverTeachers setTabToShow={setTabToShow} tabToShow={tabToShow} />
        : <TabsPrivateLessons setTabToShow={setTabToShow} tabToShow={tabToShow} />}

      {/* main content */}
      <Suspense fallback={<TeacherProfileLoader />}>
        <TeacherProfileComponent
          tabToShow={tabToShow}
          tchId={tchId!}
          waitingForLoading={waitingForLoading}
        />
      </Suspense>
    </Grid2Ct>
  );
};
