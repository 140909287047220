import React from 'react';
import { DataGridHeaderMain } from './DataGridHeader.style';
import { Props } from './interface';

/**
 * Our Data Grid Header component.
 */

export const DataGridHeader: React.FunctionComponent<Props> = (props) => {
  const {
    children,
  } = props;

  return (
    <DataGridHeaderMain>
      {children}
    </DataGridHeaderMain>
  );
};
