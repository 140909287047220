/**
 * @generated SignedSource<<f0b55f78cebe1145f5aa11d23b8ce177>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsersSettingsFragment$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly country_id_num: number | null;
        readonly dob: any | null;
        readonly email: string;
        readonly email_uuid: any;
        readonly first_name: string | null;
        readonly groupname: string | null;
        readonly hour_cycle: string;
        readonly last_name: string | null;
        readonly password_set: number;
        readonly pk: any;
        readonly relayAge: ReturnType<typeof usersRelayAgeResolverType> | null;
        readonly timezone_set: number;
        readonly user_timezone: string;
      };
    }>;
  };
  readonly " $fragmentType": "UsersSettingsFragment";
};
export type UsersSettingsFragment$key = {
  readonly " $data"?: UsersSettingsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsersSettingsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsersSettingsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "usersConnection",
      "kind": "LinkedField",
      "name": "users_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "usersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "users",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "groupname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pk",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "first_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "last_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "country_id_num",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "password_set",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timezone_set",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "user_timezone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hour_cycle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dob",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email_uuid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "relayAgeResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "relayAge",
                  "resolverModule": require('./../../../../../common/relay/resolvers/users/relayAge').relayAge,
                  "path": "users_connection.edges.node.relayAge"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};

(node as any).hash = "563e6a6c5cc94fdf451cdad33dcfc218";

export default node;
