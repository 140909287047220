import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction
} from 'react';

interface LangModalContextProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

const LangModalContext = createContext<LangModalContextProps>({
  openModal: false,
  setOpenModal: () => {},
});

interface LangModalProps {
  children: React.ReactNode;
}

const LangModalProvider: React.FC<LangModalProps> = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const value = React.useMemo(
    () => ({
      openModal,
      setOpenModal
    }),
    [openModal]
  );
  return <LangModalContext.Provider value={value}>{children}</LangModalContext.Provider>;
};

export { LangModalProvider, LangModalContext };
