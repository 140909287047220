import React from 'react';
import { useFragment } from 'react-relay';
import { Settings } from 'luxon';
import { ExtractPreferredLanguageFromStore } from '../../../pages/purchase/relay/fragments/ExtractPreferredLanguageFromStore';

interface Props {
    fragmentRef: any
}
export const UserPreferredLangSetter = ({ fragmentRef }: Props) => {
  // #region for setting preferred language in the whole app for later used
  // reading preferred language from relay store
  const preferredLangdata = useFragment(ExtractPreferredLanguageFromStore, fragmentRef);
  const preferredLanguage = preferredLangdata.users_connection.edges;
  // We'r setting preferredLanguage in luxon default Settings for the whole app,now we can get 
  // the same language information.
  Settings.defaultLocale = preferredLanguage[0].node.selectedLang!;
  // #endregion
  return (
    <div />
  );
};
