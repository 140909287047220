import { graphql } from 'babel-plugin-relay/macro';

export const RudderStackData = graphql`
fragment RudderStackData on query_root {
  users_connection {
    edges {
      node {
        random_id_public
        first_name
        last_name
        email
      }
    }
  }
}
`;
