import React, { useEffect } from 'react';
import { commitLocalUpdate, useFragment } from 'react-relay';
import AppEnvironment from '../../../../../relay/AppEnvironment';
import { getStuSettingsFromLCStorage } from '../../../../../utils/lcStorage';
import { GetLanguageFromAppointments } from '../../../../../pages/purchase/relay/fragments/GetLanguageFromAppointments';
import { GetLanguageFromOrders } from '../../../../../pages/purchase/relay/fragments/GetLanguageFromOrders';
import { GetLanguageFromUsers } from '../../../../../pages/purchase/relay/fragments/GetLanguageFromUsers';

const PrefferedLangKey = ({ fragmentRef }: any) => {
  // now we have each fragment ref, so we easily able to use and extract data of
  // these fragments.
  const appointmentsData = useFragment(GetLanguageFromAppointments, fragmentRef);
  const ordersData = useFragment(GetLanguageFromOrders, fragmentRef);
  const userLngData = useFragment(GetLanguageFromUsers, fragmentRef);
  const storedValues = getStuSettingsFromLCStorage();

  useEffect(() => {
    //  preferred lang from this component. Lots of other code abve and below too, related to
    //  preferred lang, should probably be removed?
    //  One strange thing is setting default language in localstorage. This seems a strange place
    //  to do that, seems like that should be in a more central, top app-level area?

    // here, we'r storing preferredLanguageKey in user_connection relay store obj, which
    // will be updated every time a user changes or book an appointment or purchase an
    // order or schedule a trail lesson.
    // Once we update/set preferred lng in relay store all connected components auto
    // re-render
    commitLocalUpdate(AppEnvironment, (store) => {
      const root = store.get('client:root:users_connection:edges:0');
      const userRecordDataId = root?.getLinkedRecord('node')?.getDataID();
      const userRecord = store.get(`${userRecordDataId}`);
      // setting the default lang in relay store
      let lang = 'en';
      if (storedValues?.lang?.value) {
        lang = storedValues.lang.value;
      }
      userRecord?.setValue(lang, 'selectedLang');
      const appointments = appointmentsData?.appointments_connection?.edges;
      const orders = ordersData?.orders_connection?.edges;
      const users = userLngData?.users_connection?.edges;
      if (appointments[0]?.node?.location_id) {
        userRecord?.setValue(appointments[0]?.node?.location_id, 'preferredLanguageKey');
      } else if (orders[0]?.node?.location_id) {
        userRecord?.setValue(orders[0]?.node?.location_id, 'preferredLanguageKey');
      } else if (users[0]?.node?.language_trial) {
        userRecord?.setValue(users[0]?.node?.language_trial, 'preferredLanguageKey');
      } else {
        // if no value is available then set whatever the first lang is.
        // in our case, currently, we have spanish in locations table.
        userRecord?.setValue(1, 'preferredLanguageKey');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div />
  );
};

export default PrefferedLangKey;
