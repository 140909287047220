import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import {
  Grid2, Grid2Ct, useTheme, Card,
} from '@languageconvo/wcl';
import { CardContentNopadding } from '../../../common/components/card/Card';

export const LegacySendmessage = () => {
  const theme = useTheme();
  const br = theme?.ourTheme.borders.borderRadius.medium;

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        <Card>
          <CardContentNopadding>
            <IframeResizer
              id="messageFrame"
              src={`${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=classroom/messages/`}
              autoResize={false}
                // since we are limiting the height of the iframe, we must allow scrolling
              scrolling
              style={{
                width: '1px',
                minWidth: '100%',
                minHeight: '80vh',
                maxHeight: '80vh',
                borderRadius: `${br}px`,
                border: 'none',
              }}
            />
          </CardContentNopadding>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};
