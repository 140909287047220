import { graphql } from 'babel-plugin-relay/macro';

// RelayAppSettings is a client-only schema node
export const ClientonlySchemaQuery = graphql`
  query ClientonlySchemaQuery {
    RelayAppSettings {
      ...ClientonlySchemaHasinteractedFragment
    }
  }
`;

// boolean, whether or not the user has interacted with our app yet. if they havent
// we display a "enter class" button on classroom pageload so that they have to
// interact with the page before we attempt playback
export const ClientonlySchemaHasinteractedFragment = graphql`
  fragment ClientonlySchemaHasinteractedFragment on appsetting {
    groupClass {
      hasInteracted
    }
  }
`;
