import { graphql } from 'babel-plugin-relay/macro';

export const StuTeachersFavoritecreateMutationNew = graphql`
  mutation StuTeachersFavoritecreateNewMutation (
    $teacherUuid: String!
  ){
    stu_teachers_favoritecreate(
      teacherUuid: $teacherUuid
    ){
      success
      user_favteachers {
        id
        favorite
      }
    }
  }
`;
