import React, { useState } from 'react';
import {
  AvatarIcon, Popover, List, ListItemTextCustom, ListItemButtonCustom, Ty,
  Box, Link, useTheme,
} from '@languageconvo/wcl';
import {
  useNavigate,
  NavLink,
} from 'react-router-dom';
import { useFragment } from 'react-relay';
import { sitedata } from '../../../../../utils/sitedata';
import { GetAppLevelDataAllteachersFragment } from '../relay/GetAppLevelData';
import { GetAppLevelDataAllteachersFragment$key } from '../relay/__generated__/GetAppLevelDataAllteachersFragment.graphql';
import { RelayFavoritedVals } from '../../../../../common/relay/resolvers/teachers/relayFavorited';

export interface Teacher {
  uuid?: string;
  favorite: number;
  pk: string;
  title?: string;
  about?: string;
  language_id?: number;
  img_url: string;
}

type PopoverProps = {
  fragmentRef: GetAppLevelDataAllteachersFragment$key;
}

export const FavouriteTeacherPopover = ({ fragmentRef }: PopoverProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const data = useFragment(GetAppLevelDataAllteachersFragment, fragmentRef);
  const allTeachers = data.resources_connection.edges;
  const favTeachers = allTeachers?.filter(
    (item: any) => item.node.relayFavorited === RelayFavoritedVals.IsFavorite
  );

  // #region open/close the popover

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (link?: string) => {
    setOpen(null);
    if (typeof link === 'string') {
      navigate(link);
    }
  };

  // #endregion

  return (
    <>
      {/* TODO: delete this component entirely. for now it's hidden, just so we have time
        to be sure we don't break any other part of the app */}
      <Box sx={{ display: 'none' }}>
        <AvatarIcon iconName="user-graduate" handleOpen={handleOpen} backgroundColor="secondary" />
      </Box>
      <Popover
        isOpen={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        width={220}
      >
        <List disablePadding>
          {(favTeachers.length > 0)
            ? favTeachers.map((item: any) => (
              <ListItemButtonCustom
                size="large"
                color="secondary"
                key={item.node.uuid}
                to={`${sitedata.url.app.privatelessonsTeacherProfile}${item.node.pk}`}
                component={NavLink}
              >
                <ListItemTextCustom
                  size="large"
                  disableTypography
                  primary={item.node.title}
                />
              </ListItemButtonCustom>
            )) : (
          // TODO: this should be a component in wcl. A good place might be
          //  /components/datadisplay/ListItemBasic  Just the two boxes below
          //  which create the correct padding, radius etc. for a basic item in
          //  our navbar
              <Box
                sx={{
                  padding: '16px',
                }}
              >
                <Box
                  sx={{
                    padding: '8px 16px 8px 16px',
                    backgroundColor: theme?.ourTheme.colors.cp.itemlist.hoverbg.secondary,
                    borderRadius: `${theme?.ourTheme.borders.borderRadius.medium}px`,
                  }}
                >
                  <Link to={sitedata.url.app.teachersDiscover} linkStyle="nostyle" component={NavLink}>
                    <Ty removeMb>
                      Find a teacher and add them to your Favorites!
                    </Ty>
                  </Link>
                </Box>
              </Box>
            )}
        </List>
      </Popover>
    </>
  );
};
