import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  Ty, Button, Grid2Ct, Grid2, Container, Box, Ic, LottiePlayer,
} from '@languageconvo/wcl';
import { useRouteError, NavLink } from 'react-router-dom';
import { sitedata } from '../../../utils/sitedata';
import SessionTimeoutAnimation from '../../assets/lottie/pandadeveloper.json';

/** If the router errors, this is what will show. The appbar/navbar will *not* 
 * be shown, so this is a complete full width page. You can test this by throwing
 * an error in our routes file, within the `loader` callback
 */
export const RouteLevelError = () => {
  const error: any = useRouteError();
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <Container maxWidth="pageContainer">
      <Box sx={{ mt: 5, pb: 5 }}>
        <Grid2Ct>
          {/* text - header */}
          <Grid2 xs={12}>
            <Ty v="h1New" align="center"><Ic color="accentRed1" iconStyle="duotone" iconName="traffic-cone" />&nbsp;&nbsp;Oops - An Error Occurred</Ty>
          </Grid2>

          {/* text - we notified the team */}
          <Grid2 xs={12} sm={8} smOffset={2} lg={6} lgOffset={3} xl={4} xlOffset={4}>
            <Ty align="center">Patrick our Software Developer Panda made a bit of a mistake. Not to worry, we have notified Pat about the problem.</Ty>
          </Grid2>

          {/* buttons refresh the page, go to home (sm and above) */}
          <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Box display="flex" justifyContent="center">
              <Button
                color="accentRed1"
                size="large"
                onClick={() => window.location.reload()}
              >
                Refresh the Page
              </Button>&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                color="accentRed1"
                size="large"
                to={sitedata.url.app.home}
                component={NavLink}
              >
                Navigate to Home
              </Button>
            </Box>
          </Grid2>
          {/* buttons refresh the page, go to home (xs) */}
          <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Grid2Ct>
              <Grid2 xs={12}>
                <Box display="flex" justifyContent="center">
                  <Button
                    color="accentRed1"
                    onClick={() => window.location.reload()}
                  >
                    Refresh the Page
                  </Button>
                </Box>
              </Grid2>
              <Grid2 xs={12}>
                <Box display="flex" justifyContent="center">
                  <Button
                    color="accentRed1"
                    to={sitedata.url.app.home}
                    component={NavLink}
                  >
                    Navigate to Home
                  </Button>
                </Box>
              </Grid2>
            </Grid2Ct>
          </Grid2>

          {/* lottie panda (sm and above) */}
          <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
              <LottiePlayer animationSrc={SessionTimeoutAnimation} animationHeight={450} />
            </Box>
          </Grid2>
          {/* lottie panda (xs) */}
          <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
              <LottiePlayer animationSrc={SessionTimeoutAnimation} animationHeight={300} />
            </Box>
          </Grid2>
        </Grid2Ct>
      </Box>
    </Container>
  );
};
