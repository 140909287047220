/**
 * @generated SignedSource<<fd27374b8041b8f7d241d4efc75953ae>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrivateUpcomingDataHomepgQuery$variables = {
  currentTs: any;
};
export type PrivateUpcomingDataHomepgQuery$data = {
  readonly Anyupcoming: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly Upcomingregular: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"PrivateUpcomingDataLessonsFragment">;
      };
    }>;
  };
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"PrivateUpcomingDataUserFragment">;
      };
    }>;
  };
};
export type PrivateUpcomingDataHomepgQuery = {
  response: PrivateUpcomingDataHomepgQuery$data;
  variables: PrivateUpcomingDataHomepgQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentTs"
  }
],
v1 = {
  "kind": "Literal",
  "name": "order_by",
  "value": {
    "starts_at": "asc"
  }
},
v2 = {
  "fields": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "_gt",
          "variableName": "currentTs"
        }
      ],
      "kind": "ObjectValue",
      "name": "ends_at"
    },
    {
      "kind": "Literal",
      "name": "real_status",
      "value": {
        "_in": [
          1,
          8
        ]
      }
    }
  ],
  "kind": "ObjectValue",
  "name": "where"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "Anyupcoming",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "concreteType": "appointmentsConnection",
  "kind": "LinkedField",
  "name": "appointments_connection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "appointmentsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "appointments",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 8
  },
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PrivateUpcomingDataHomepgQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PrivateUpcomingDataUserFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": "Upcomingregular",
        "args": (v5/*: any*/),
        "concreteType": "appointmentsConnection",
        "kind": "LinkedField",
        "name": "appointments_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appointmentsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "appointments",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PrivateUpcomingDataLessonsFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PrivateUpcomingDataHomepgQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hour_cycle",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": "Upcomingregular",
        "args": (v5/*: any*/),
        "concreteType": "appointmentsConnection",
        "kind": "LinkedField",
        "name": "appointments_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appointmentsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "appointments",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ends_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "starts_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id_random",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "resources",
                    "kind": "LinkedField",
                    "name": "resources",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4cd52349f7d08bd0dab74be0f4cb6ac7",
    "id": null,
    "metadata": {},
    "name": "PrivateUpcomingDataHomepgQuery",
    "operationKind": "query",
    "text": "query PrivateUpcomingDataHomepgQuery(\n  $currentTs: bigint!\n) {\n  users_connection {\n    edges {\n      node {\n        ...PrivateUpcomingDataUserFragment\n        id\n      }\n    }\n  }\n  Anyupcoming: appointments_connection(where: {real_status: {_in: [1, 8]}, ends_at: {_gt: $currentTs}}, first: 1, order_by: {starts_at: asc}) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  Upcomingregular: appointments_connection(where: {real_status: {_in: [1, 8]}, ends_at: {_gt: $currentTs}}, first: 8, order_by: {starts_at: asc}) {\n    edges {\n      node {\n        ...PrivateUpcomingDataLessonsFragment\n        id\n      }\n    }\n  }\n}\n\nfragment PrivateUpcomingDataLessonsFragment on appointments {\n  ends_at\n  starts_at\n  id_random\n  resources {\n    title\n    id\n  }\n}\n\nfragment PrivateUpcomingDataUserFragment on users {\n  hour_cycle\n}\n"
  }
};
})();

(node as any).hash = "1b640e613fb72e2dc646e11e28a9739e";

export default node;
