import {
  styled, WCLThemeProps, Box,
} from '@languageconvo/wcl';
import { HEADER } from '../../../../../config';

export const CardCustom = styled(Box)(({
  theme,
}: WCLThemeProps) => ({
  borderRadius: theme?.ourTheme.borders.borderRadius.medium,
  backgroundColor: `hsl(${theme?.ourTheme.colors.app.container})`,
  overflow: 'hidden',

  // we set a minimum height mainly so that on mobile, when users turn their phone
  // horizontally, they video div won't become tiny. instead, it will be 250px (which
  // probably pushes the mic/info buttons down below the viewport)
  minHeight: '250px',

  /* height on xs
    on xs, we hide the bottom bar. so to calculate the height that we want
    this main container div to be, we subtract the top bar (HEADER.MOBILE_HEIGHT) and
    the approximate height of the mic/info small cards (about 101px). note we are
    *not* forcing aspect ratio
  */
  [theme!.breakpoints.up('xs')]: {
    height: `calc(100vh - ${HEADER.MOBILE_HEIGHT}px - 101px)`,
    // @ts-ignore
    height: `calc(100dvh - ${HEADER.MOBILE_HEIGHT}px - 101px)`, // eslint-disable-line
    // on mobile we do *not* try to use 16/9 aspect ratio. doing so makes the video
    // stretch to the edges of the device which doesn't look great
    width: '100%',
  },

  /* height on sm, md
    on sm and md, we hide the bottom bar. so to calculate the height that we want
    this main container div to be, we subtract the top bar (HEADER.MOBILE_HEIGHT) and
    the approximate height of the mic/info small cards (about 101px). note we are
    forcing aspect ratio
  */
  [theme!.breakpoints.up('sm')]: {
    height: `calc(100vh - ${HEADER.MOBILE_HEIGHT}px - 101px)`,
    // @ts-ignore
    height: `calc(100dvh - ${HEADER.MOBILE_HEIGHT}px - 101px)`, // eslint-disable-line
    // our teachers use 720p cameras, which is a 16/9 aspect ratio
    aspectRatio: '16/9',
    width: 'auto',
    // stop the container from getting too wide when it's trying to maintain aspect ratio
    // otherwise it overflows the page width
    maxWidth: '97%',
  },

  /* height on lg, xl, xxl
    on larger screens, there is no bottom bar, so height is just to bar, minus
    padding, minus bottom button heights. note that we do force aspect ratio
  */
  [theme!.breakpoints.up('lg')]: {
    height: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px -13px - 101px)`,
    // @ts-ignore
    height: `calc(100dvh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px - 13px - 101px)`, // eslint-disable-line
    // our teachers use 720p cameras, which is a 16/9 aspect ratio
    aspectRatio: '16/9',
    width: 'auto',
    // stop the container from getting too wide when it's trying to maintain aspect ratio
    // otherwise it overflows the page width
    maxWidth: '97%',
  },
}));

export const CardContents = styled(Box)(({ theme }: WCLThemeProps) => ({
  position: 'relative',
  borderRadius: `${theme?.ourTheme.borders.borderRadius.medium}px`,
  width: '100%',
  height: '100%',
}));

export const CardContentsPadded = styled(Box)(({ theme }: WCLThemeProps) => ({
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  padding: `${theme?.ourTheme.padding?.cardContentPadding} !important`,
}));
