import React from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import {
  Ty, styled, Grid2Ct, Grid2, IcSvg, IcSvgList,
  Skeleton,
} from '@languageconvo/wcl';
import { DateTime } from 'luxon';
import {
  ClassroomBasicDataHandRaisValueFragment,
  ClassroomBasicDataLessoninfoFragment,
  ClassroomBasicDataQuery,
  ClassroomBasicDataUserFragment,
} from '../../../../relay/ClassroomBasicData';
import { validateHourCycle } from '../../../../../../../common/utils/DatesTimes/HourCycle';
import { AudioFeedback } from './AudioFeedback';

export const LessonInfo1GetData = () => {
  const [searchParams] = useSearchParams();
  const lsnUuid = searchParams.get('uuid');

  // query. note that this is the same big query that runs when student enters the classroom; it
  // should very very rarely, if ever, need to actually run again bc the data is already in relay
  // TODO: we *should* to be more correct, create a custom specific query just for this component
  const res: any = useLazyLoadQuery(
    ClassroomBasicDataQuery,
    { lessonUuid: lsnUuid },
  );

  // lesson information
  const lessonInfoRef = res.appt_group_connection.edges[0].node;
  const lessonData = useFragment(
    ClassroomBasicDataLessoninfoFragment,
    lessonInfoRef,
  );
  const { starts_at, ends_at } = lessonData;
  const level = lessonData.skills.skills_levels.skills_categories.title_en;
  const lesson_title = `Skill #${lessonData.skills.display_order} - ${lessonData.skills.title_en}`;

  //  user hour cycle
  const userInfoFragmentRef = res.users_connection.edges[0].node;
  const userData = useFragment(ClassroomBasicDataUserFragment, userInfoFragmentRef);
  const hrCy = validateHourCycle(userData.hour_cycle);
  const timeOpts: Intl.DateTimeFormatOptions = {
    timeStyle: 'short',
    hourCycle: hrCy,
  };

  // converting time into localString to show on UI
  const lessonStartTime = DateTime.fromISO(starts_at).toLocaleString(timeOpts);
  const lessonEndTime = DateTime.fromISO(ends_at).toLocaleString(timeOpts);

  // extracting user's hand_raised fragmentRef 
  const handRaisedValueRef = res.appt_group_connection.edges[0].node.appt_group_reservations[0];

  const handRaisedData = useFragment(
    ClassroomBasicDataHandRaisValueFragment,
    handRaisedValueRef
  );

  return (
    <Grid2Ct>
      {/* general info title */}
      <Grid2 xs={12}>
        <Ty v="h2New">General Information</Ty>
      </Grid2>

      {/* lesson time */}
      <Grid2 xs={12}>
        <AlignIconText>
          <IcSvg icon={IcSvgList.clock1} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
          <Ty removeMb>
            {lessonStartTime} - {lessonEndTime}
          </Ty>
        </AlignIconText>
      </Grid2>

      {/* lesson topic */}
      <Grid2 xs={12}>
        <AlignIconText>
          <IcSvg icon={IcSvgList.knowledge2} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
          <Ty removeMb>
            {level}&nbsp;&nbsp;&nbsp;{lesson_title}
          </Ty>
        </AlignIconText>
      </Grid2>

      {/* hand raise, explanation */}
      <Grid2 xs={12} sx={{ mt: 2 }}>
        <Ty v="h2New">Notify Teacher of Audio Problem</Ty>
        <Ty>
          If you cannot hear your teacher well, please click one of the first two buttons below
          to notify your teacher! Remember that during the lecture portion of the class your teacher
          cannot hear you or any other student, so these buttons are the only way to
          &quot;notify&quot; them of a problem.
          <br /><br />
          Once your teacher has fixed the problem,
          please click the last button to let them know that they fixed the problem.
        </Ty>
      </Grid2>

      {/* 
        we are rendering the AudioFeedback component only when handRaisedData is available. 
        this check ensures that:
        1. handRaisedData is not null, indicating that the data has successfully 
           loaded and is ready for use.
        2. If handRaisedData is null, it means the data is still loading or 
           failed to load, so we render a placeholder (AudioFeedbackSkeleton) 
           instead to provide a better user experience and avoid potential 
           errors from rendering AudioFeedback with incomplete or missing data.
      */}
      {handRaisedData === null ? (<AudioFeedbackSkeleton />)
        : (<AudioFeedback handRaisedData={handRaisedData} />)}

    </Grid2Ct>
  );
};

const AlignIconText = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const AudioFeedbackSkeleton = () => (
  <>
    {/* Skeleton for "Can't hear at all" button */}
    <Grid2 xs={12}>
      <Skeleton variant="rectangular" height={40} />
    </Grid2>

    {/* Skeleton for "Can hear, but audio is not good" button */}
    <Grid2 xs={12}>
      <Skeleton variant="rectangular" height={40} />
    </Grid2>

    {/* Skeleton for "Can hear well" button */}
    <Grid2 xs={12}>
      <Skeleton variant="rectangular" height={40} />
    </Grid2>
  </>
);
