import React from 'react';
import { CustomDivider } from './index.style';
import { Props } from './interface';

/**
 * Our main Divider component. See:
 *
 * https://mui.com/material-ui/react-divider/
 *
 * https://mui.com/material-ui/api/divider/
 */
export const Divider: React.FunctionComponent<Props> = (props) => {
  const {
    orientation = 'horizontal',
    flexItem = false,
    strength = 'base',
    cp,
  } = props;
  return (
    <CustomDivider
      orientation={orientation}
      flexItem={flexItem}
      strength={strength}
      {...cp}
    />
  );
};
