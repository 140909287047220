/**
 * @generated SignedSource<<24f7be47aed19c6e9a106969a3949edd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type relayFavoritedResolver$data = {
  readonly relayFavoritedMutated: number | null;
  readonly user_favteachers: ReadonlyArray<{
    readonly favorite: any;
  }>;
  readonly " $fragmentType": "relayFavoritedResolver";
};
export type relayFavoritedResolver$key = {
  readonly " $data"?: relayFavoritedResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"relayFavoritedResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "relayFavoritedResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "user_favteachers",
      "kind": "LinkedField",
      "name": "user_favteachers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "favorite",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relayFavoritedMutated",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "resources",
  "abstractKey": null
};

(node as any).hash = "9d70972e408fe31c3496fc2e468a1fb5";

export default node;
