import * as React from 'react';
import { CardMedia as CustomCardMedia } from '@mui/material';
import { CardMediaProps } from './interface';

/**
 * Our main Card Media component. See:
 *
 * this component will be used, when we need media inside card component
 *
 * https://mui.com/material-ui/api/card-media/
 *
 */

export const CardMedia: React.FunctionComponent<CardMediaProps> = (props) => {
  const {
    cp,
    children,
    mediaSrc,
  } = props;
  return (
    <CustomCardMedia
      {...cp}
      src={mediaSrc}
    >
      {children}
    </CustomCardMedia>
  );
};
