import React from 'react';
import { useClientQuery, useFragment } from 'react-relay';
import * as Sentry from '@sentry/react';
import {
  StuSettingsGettrialdataClientQuery,
  StuSettingsGettrialdataTrialFragment,
} from '../../../../../common/relay/mutations/students/StuSettingsGettrialdata';
import { StuSettingsGettrialdataTrialFragment$key } from '../../../../../common/relay/mutations/students/__generated__/StuSettingsGettrialdataTrialFragment.graphql';
import { LayoutPosttrial0 } from '../posttrial/LayoutPosttrial0';
import { TrialCompleted } from './components/TrialCompleted';
import { TrialMissedTwo } from './components/TrialMissedTwo';
import { TrialUpcoming } from './components/TrialUpcoming';
import { TrialNoTeachersAvailable } from './components/TrialNoTeachersAvailable';
import { TrialNoPastNoUpcoming } from './components/TrialNoPastNoUpcoming';
import { TrialPastOtherstatus } from './components/TrialPastOtherstatus';

/* Now that we've gotten the most up-to-date trial data about the user, we can display the correct
    ui. Here we determine if they have a completed trial, upcoming trial, etc. and display
*/
export const LayoutNocredits3Scneario = () => {
  // get trial data from the relay store
  const response: any = useClientQuery(
    StuSettingsGettrialdataClientQuery,
    {}
  );
  const fragmentRef: StuSettingsGettrialdataTrialFragment$key = response.RelayAppSettings;
  const data = useFragment(StuSettingsGettrialdataTrialFragment, fragmentRef);

  // #region displayScenario

  /*  displayScenario
      1   has credits
      2   completed trial
      3   missed two trials
      4   upcoming trial
      5   step2 of trial steps is completed and no teachers of this language give free trials
      6   no past and no upcoming trial exists
      7   past trial exists it is *not* status "completed"
  */
  let displayScenario;

  // has credits (this should never occur)
  if (data.trialData?.hasCredits === true) {
    /* This occurs when the user purchases lessons during the current session. hasCredits
        changes from false to true
    */
    displayScenario = 1;

    // completed trial
  } else if (data.trialData?.trial?.pastCompleted === true) {
    displayScenario = 2;

    // missed two trials
  } else if (data.trialData?.trial?.hasMissedTwo === true) {
    displayScenario = 3;

    // upcoming trial
  } else if (data.trialData?.trial?.hasUpcoming === true) {
    displayScenario = 4;

    // step2 of trial steps is completed and no teachers of this language give free trials
  } else if (data.trialData?.trial?.stepsToSchedule?.step2 === true
    && data.trialData.trial.atleastOneTeacherGivesTrials === false) {
    displayScenario = 5;

    // no past and no upcoming trial exists
  } else if (data.trialData?.trial?.pastTrialExists === false
    && data.trialData?.trial?.hasUpcoming === false) {
    displayScenario = 6;

    // past trial exists it is *not* status "completed"
  } else if (data.trialData?.trial?.pastTrialExists === true) {
    displayScenario = 7;

    // this should never happen. one of the above scenarios should always hit
  } else {
    // This should never happen, one of the above scenarios should always exist. We'll set
    // scenario 2 (completed trial) just so that we ensure we show the user post-purchase
    // normal display, but we also don't show the trial scheduling process bc we don't want
    // them scheduling a trial if something might have gone wrong
    displayScenario = 2;
    Sentry.captureException(
      new Error('MAJOR ERROR: LayoutNocredits3Scneario found inconsistent display scenario'),
      {
        extra: {
          dt: data,
        }
      }
    );
  }

  // #endregion

  // 1   has credits
  if (displayScenario === 1) {
    return (<LayoutPosttrial0 />);
  }

  // 2   completed trial
  if (displayScenario === 2) {
    return (
      <TrialCompleted />
    );
  }

  // 3   missed two trials
  if (displayScenario === 3) {
    return (
      <TrialMissedTwo />
    );
  }

  // 4   upcoming trial
  if (displayScenario === 4) {
    return (
      <TrialUpcoming />
    );
  }

  // 5   step2 of trial steps is completed and no teachers of this language give free trials
  if (displayScenario === 5) {
    return (
      <TrialNoTeachersAvailable dt={data} />
    );
  }

  // 6   no past and no upcoming trial exists
  if (displayScenario === 6) {
    return (
      <TrialNoPastNoUpcoming />
    );
  }

  // 7   past trial exists but its not status "completed"
  if (displayScenario === 7) {
    return (
      <TrialPastOtherstatus dt={data} />
    );
  }

  // default return, this will never occur
  return (
    <TrialCompleted />
  );
};
