/**
 * @generated SignedSource<<89227a02b74a84ebd33e2a894ab6c99d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageloadQuery$variables = {};
export type PageloadQuery$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"PageloadLanglearnFragment" | "PageloadRequiredsettingsFragment" | "PageloadTimesettingsFragment">;
      };
    }>;
  };
};
export type PageloadQuery = {
  response: PageloadQuery$data;
  variables: PageloadQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PageloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PageloadLanglearnFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PageloadTimesettingsFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PageloadRequiredsettingsFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PageloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lang_learning",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hour_cycle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timezone_set",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c5329ee2f481f3c0698d35bcc75785b8",
    "id": null,
    "metadata": {},
    "name": "PageloadQuery",
    "operationKind": "query",
    "text": "query PageloadQuery {\n  users_connection {\n    edges {\n      node {\n        ...PageloadLanglearnFragment\n        ...PageloadTimesettingsFragment\n        ...PageloadRequiredsettingsFragment\n        id\n      }\n    }\n  }\n}\n\nfragment PageloadLanglearnFragment on users {\n  lang_learning\n}\n\nfragment PageloadRequiredsettingsFragment on users {\n  timezone_set\n}\n\nfragment PageloadTimesettingsFragment on users {\n  hour_cycle\n}\n"
  }
};

(node as any).hash = "66d4decba2595518e8975a6b9e4ace85";

export default node;
