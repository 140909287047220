/**
 * @generated SignedSource<<1478c3bae85927dc46bbcef47e22792a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PackagesDetail$data = {
  readonly locations_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly packs: ReadonlyArray<{
          readonly duration: number;
          readonly pk: number;
          readonly price: any | null;
        }>;
        readonly pk: number;
        readonly title: string | null;
      };
    }>;
  };
  readonly " $fragmentType": "PackagesDetail";
};
export type PackagesDetail$key = {
  readonly " $data"?: PackagesDetail$data;
  readonly " $fragmentSpreads": FragmentRefs<"PackagesDetail">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PackagesDetail",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "pk": {
              "_neq": 2
            }
          }
        }
      ],
      "concreteType": "locationsConnection",
      "kind": "LinkedField",
      "name": "locations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "locationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "locations",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "package_type_id": {
                          "_eq": 1
                        }
                      }
                    }
                  ],
                  "concreteType": "packs",
                  "kind": "LinkedField",
                  "name": "packs",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "price",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "duration",
                      "storageKey": null
                    }
                  ],
                  "storageKey": "packs(where:{\"package_type_id\":{\"_eq\":1}})"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "locations_connection(where:{\"pk\":{\"_neq\":2}})"
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "6a156f914df3111b3ce7050dea444f09";

export default node;
