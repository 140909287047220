import React from 'react';
import {
  Grid2, Card,
} from '@languageconvo/wcl';
import {
  NavtabCardContent,
  NavtabItemContainer,
  NavtabItemContainerXs,
} from '../../../../../common/components/navtabs/navtabs.style';

type TabsProps = {
  setTabToShow: any,
  tabToShow: number,
}

// tabs for /privatelessons route. this includes a "back" button to the main /privatelessons
// page, and the "schedule a lesson" tab is shown first and by default. reason being, students
// going to this page have already favorited the teacher and are most likely just wanting
// to schedule a lesson with the teacher that they already know well
export const TabsPrivateLessons = ({ setTabToShow, tabToShow }: TabsProps) => (
  <>
    {/* xs
        very custom. smaller font sizes, smaller margins, etc.
     */}
    <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
      <Card>
        <NavtabCardContent data-testid="navTabs">
          <Grid2 container spacing={1} columns={18}>
            <Grid2 xs={6}>
              <NavtabItemContainerXs
                onClick={() => setTabToShow(2)}
                className={tabToShow === 2 ? 'selected' : ''}
              >Schedule<br />Lesson
              </NavtabItemContainerXs>
            </Grid2>
            <Grid2 xs={6}>
              <NavtabItemContainerXs
                data-testid="weeklyReservation"
                onClick={() => setTabToShow(3)}
                className={tabToShow === 3 ? 'selected' : ''}
              >Reserve<br />Weekly
              </NavtabItemContainerXs>
            </Grid2>
            <Grid2 xs={6}>
              <NavtabItemContainerXs
                data-testid="teacherProfileBtn1"
                onClick={() => setTabToShow(1)}
                className={tabToShow === 1 ? 'selected' : ''}
              >Teacher<br />Profile
              </NavtabItemContainerXs>
            </Grid2>
          </Grid2>
        </NavtabCardContent>
      </Card>
    </Grid2>

    {/* sm
        text is two lines e.g. "Schedule a<br />Lesson"
        font size is the same though, no changes to it
    */}
    <Grid2 xs={12} xl={9} xxl={8} sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
      <Card>
        <NavtabCardContent data-testid="navTabs">
          <Grid2 container spacing={1} columns={18}>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                onClick={() => setTabToShow(2)}
                className={tabToShow === 2 ? 'selected' : ''}
              >Schedule a<br />Lesson
              </NavtabItemContainer>
            </Grid2>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                data-testid="weeklyReservation"
                onClick={() => setTabToShow(3)}
                className={tabToShow === 3 ? 'selected' : ''}
              >Weekly<br />Reservation
              </NavtabItemContainer>
            </Grid2>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                data-testid="teacherProfileBtn2"
                onClick={() => setTabToShow(1)}
                className={tabToShow === 1 ? 'selected' : ''}
              >Teacher<br />Profile
              </NavtabItemContainer>
            </Grid2>
          </Grid2>
        </NavtabCardContent>
      </Card>
    </Grid2>

    {/* md, lg, xl, xxl
        text is flat, full width */}
    <Grid2 xs={12} xl={10} xxl={8} sx={{ display: { xs: 'none', md: 'block' } }}>
      <Card>
        <NavtabCardContent data-testid="navTabs">
          <Grid2 container spacing={1} columns={18}>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                onClick={() => setTabToShow(2)}
                className={tabToShow === 2 ? 'selected' : ''}
              >Schedule a Lesson
              </NavtabItemContainer>
            </Grid2>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                data-testid="weeklyReservation"
                onClick={() => setTabToShow(3)}
                className={tabToShow === 3 ? 'selected' : ''}
              >Weekly Reservation
              </NavtabItemContainer>
            </Grid2>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                data-testid="teacherProfileBtn3"
                onClick={() => setTabToShow(1)}
                className={tabToShow === 1 ? 'selected' : ''}
              >Teacher&apos;s Profile
              </NavtabItemContainer>
            </Grid2>
          </Grid2>
        </NavtabCardContent>
      </Card>
    </Grid2>
  </>
);

// tabs for the /teachers route (the "find a teacher" page). when going to this route, the teacher's
// profile opens in a new tab, and we show the "profile" page by default since this is most likely
// a user who is searching for a new teacher to try out
export const TabsDiscoverTeachers = ({ setTabToShow, tabToShow }: TabsProps) => (
  <>
    {/* xs
        very custom. smaller font sizes, smaller margins, etc.
     */}
    <Grid2 xs={12} xl={9} xxl={8} sx={{ display: { xs: 'block', sm: 'none' } }}>
      <Card>
        <NavtabCardContent>
          <Grid2 container spacing={1} columns={18}>
            <Grid2 xs={6}>
              <NavtabItemContainerXs
                onClick={() => setTabToShow(1)}
                className={tabToShow === 1 ? 'selected' : ''}
              >Teacher<br />Profile
              </NavtabItemContainerXs>
            </Grid2>
            <Grid2 xs={6}>
              <NavtabItemContainerXs
                onClick={() => setTabToShow(2)}
                className={tabToShow === 2 ? 'selected' : ''}
              >Schedule<br />Lesson
              </NavtabItemContainerXs>
            </Grid2>
            <Grid2 xs={6}>
              <NavtabItemContainerXs
                onClick={() => setTabToShow(3)}
                className={tabToShow === 3 ? 'selected' : ''}
              >Reserve<br />Weekly
              </NavtabItemContainerXs>
            </Grid2>
          </Grid2>
        </NavtabCardContent>
      </Card>
    </Grid2>

    {/* sm
        text is two lines e.g. "Schedule a<br />Lesson"
        font size is the same though, no changes to it
    */}
    <Grid2 xs={12} xl={9} xxl={8} sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
      <Card>
        <NavtabCardContent>
          <Grid2 container spacing={1} columns={18}>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                onClick={() => setTabToShow(1)}
                className={tabToShow === 1 ? 'selected' : ''}
              >Teacher<br />Profile
              </NavtabItemContainer>
            </Grid2>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                onClick={() => setTabToShow(2)}
                className={tabToShow === 2 ? 'selected' : ''}
              >Schedule a<br />Lesson
              </NavtabItemContainer>
            </Grid2>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                onClick={() => setTabToShow(3)}
                className={tabToShow === 3 ? 'selected' : ''}
              >Weekly<br />Reservation
              </NavtabItemContainer>
            </Grid2>
          </Grid2>
        </NavtabCardContent>
      </Card>
    </Grid2>

    {/* md, lg, xl, xxl
        text is flat, full width */}
    <Grid2 xs={12} xl={10} xxl={8} sx={{ display: { xs: 'none', md: 'block' } }}>
      <Card>
        <NavtabCardContent>
          <Grid2 container spacing={1} columns={18}>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                onClick={() => setTabToShow(1)}
                className={tabToShow === 1 ? 'selected' : ''}
              >Teacher&apos;s Profile
              </NavtabItemContainer>
            </Grid2>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                onClick={() => setTabToShow(2)}
                className={tabToShow === 2 ? 'selected' : ''}
              >Schedule a Lesson
              </NavtabItemContainer>
            </Grid2>
            <Grid2 xs={18} sm={6}>
              <NavtabItemContainer
                onClick={() => setTabToShow(3)}
                className={tabToShow === 3 ? 'selected' : ''}
              >Weekly Reservation
              </NavtabItemContainer>
            </Grid2>
          </Grid2>
        </NavtabCardContent>
      </Card>
    </Grid2>
  </>
);
