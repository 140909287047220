import { commitLocalUpdate } from 'react-relay';
import AppEnvironment from '../../../../relay/AppEnvironment';
import { RelayAppSettingsVals } from '../RelayAppSettingsTypes';

// setting current un-read message count in realy store
export const setCurrentUnReadMessageCountInRelayStore = (
  count: number | null,
) => {
  commitLocalUpdate(AppEnvironment, (store) => {
    const currentUnReadMsgRecord = store.get(RelayAppSettingsVals.messagesIDVal);
    // if unRead messages variable is present in relay store, then updating that value
    // else creating it agian in relay store to avoid code break because there are chances this
    // code executes before the the dasboardLayout1, where we are creating our custom relayAppSet...
    if (currentUnReadMsgRecord) {
      currentUnReadMsgRecord.setValue(
        count,
        RelayAppSettingsVals.unReadMsgCount
      );
    } else {
      // root record
      const record = store.get(RelayAppSettingsVals.idVal);

      // step 1 : check if messages record is present in relay store OR not
      let messagesRecord = store.get(RelayAppSettingsVals.messagesIDVal);

      if (!messagesRecord) {
      // step 2: create the messages record if it doesn't exist
        messagesRecord = store.create(
          RelayAppSettingsVals.messagesIDVal,
          RelayAppSettingsVals.messagesDetailsType
        );

        // step 3: Set count value 
        messagesRecord.setValue(count, RelayAppSettingsVals.unReadMsgCount);
      }

      //  link `messages to parent record.
      record!.setLinkedRecord(messagesRecord, RelayAppSettingsVals.messagesName);
    }
  });
};
