import * as yup from 'yup';

const valueArrayForLevel = [1, 2, 3];

const valueArrayForTranslation = [0, 1];

export const StudentPreferences = yup.object({
  skill_level: yup.number().required('Please select an option').oneOf(valueArrayForLevel),
  wants_translation: yup.number().required('Please select an option').oneOf(valueArrayForTranslation)
});

export type StudentPreferencesProps = yup.InferType<typeof StudentPreferences>
