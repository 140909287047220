import React from 'react';
import {
  library, IconName, IconPrefix, SizeProp,
} from '@fortawesome/fontawesome-svg-core';
// here we are importing all the icons with iconStyle==light
import {
  faLanguage as lightFaLanguage, faCheck as lightFaCheck, faUserGraduate as lightFaUserGraduate, faGraduationCap as lightFaGraduationCap,
  faArrowRightFromLine as lightFaArrowRightFromLine, faArrowLeftToLine as lightFaArrowLeftToLine, faHashtag as lightFaHashtag,
  faGear as lightFaGear, faEarthAmericas as lightFaEarthAmericas, faChartLine as lightFaChartLine, faBook as lightFaBook, faDisplay as lightFaDisplay,
  faHouse as lightFaHouse, faAngleRight as lightFaAngleRight, faCircleNotch as lightFaCircleNotch, faUnlock as lightFaUnlock,
  faAngleDown as lightFaAngleDown, faBars as lightFaBars, faCaretRight as lightFaCaretRight, faEnvelope as lightFaEnvelope,
  faCaretLeft as lightFaCaretLeft, faBoltLightning as lightFaBoltLightning, faQuestion as lightFaQuestion, faSquareInfo as lightFaSquareInfo,
  faMicrophoneStand as lightFatMicrophoneStand, faDesktop as lightFaDesktop, faVideo as lightFaVideo,
  faCalendarDays as lightFaCalendarDays, faBagShopping as lightFaBagShopping, faUserGear as lightFaUserGear,
  faEllipsisStrokeVertical as lightFaEllipsisStrokeVertical, faEllipsisStroke as lightFaEllipsisStroke,
  faStar as lightFaStar, faUserXmark as lightFaUserXmark, faUserCheck as lightFaUserCheck,
  faPartyHorn as lightFaPartyHorn, faMoneyBill as lightFaMoneyBill, faCircleUser as lightFaCircleUser,
  faUsers as lightFaUsers, faArrowLeft as lightFaArrowLeft, faCircleCaretLeft as lightFaCircleCaretLeft,
  faCircleCaretRight as lightFaCircleCaretRight, faUser as lightFaUser, faAddressCard as lightFaAddressCard,
  faSun as lightFaSun, faMoonStars as lightFaMoonStars, faFolderUser as lightFaFolderUser,
  faScreencast as lightFaScreencast, faCircleXmark as lightFaCircleXmark,
  faCircleChevronRight as lightFaCircleChevronRight, faTrafficCone as lightFaTrafficCone, faMusic as lightFaMusic,
  faComment as lightFaComment, faTriangleExclamation as lightFaTriangleExclamation,
  faFaceSmilingHands as lightFaFaceSmilingHands, faChalkboardUser as lightFaChalkboardUser,
  faSealQuestion as lightFaSealQuestion, faCalendarRange as lightFaCalendarRange,
  faCalendarHeart as lightFaCalendarHeart,
} from '@fortawesome/pro-light-svg-icons';
// here we are importing all the icons with iconStyle==solid
import {
  faLanguage as solidFaLanguage, faCheck as solidFaCheck, faUserGraduate as solidFaUserGraduate, faGraduationCap as solidFaGraduationCap,
  faArrowRightFromLine as solidFaArrowRightFromLine, faArrowLeftToLine as solidFaArrowLeftToLine, faHashtag as solidFaHashtag,
  faGear as solidFaGear, faEarthAmericas as solidFaEarthAmericas, faChartLine as solidFaChartLine, faBook as solidFaBook, faDisplay as solidFaDisplay,
  faHouse as solidFaHouse, faAngleRight as solidFaAngleRight, faCircleNotch as solidFaCircleNotch, faUnlock as solidFaUnlock,
  faAngleDown as solidFaAngleDown, faBars as solidFaBars, faCaretRight as solidFaCaretRight, faEnvelope as solidFaEnvelope,
  faCaretLeft as solidFaCaretLeft, faBoltLightning as solidFaBoltLightning, faQuestion as solidFaQuestion, faSquareInfo as solidFaSquareInfo,
  faMicrophoneStand as solidFatMicrophoneStand, faDesktop as solidFaDesktop, faVideo as solidFaVideo,
  faCalendarDays as solidFaCalendarDays, faBagShopping as solidFaBagShopping, faUserGear as solidFaUserGear,
  faEllipsisStrokeVertical as solidFaEllipsisStrokeVertical, faEllipsisStroke as solidFaEllipsisStroke,
  faStar as solidFaStar, faUserXmark as solidFaUserXmark, faUserCheck as solidFaUserCheck,
  faPartyHorn as solidFaPartyHorn, faMoneyBill as solidFaMoneyBill, faCircleUser as solidFaCircleUser,
  faUsers as solidFaUsers, faArrowLeft as solidFaArrowLeft, faCircleCaretLeft as solidFaCircleCaretLeft,
  faCircleCaretRight as solidFaCircleCaretRight, faUser as solidFaUser, faAddressCard as solidFaAddressCard,
  faSun as solidFaSun, faMoonStars as solidFaMoonStars, faFolderUser as solidFaFolderUser,
  faScreencast as solidFaScreencast, faCircleXmark as solidFaCircleXmark,
  faCircleChevronRight as solidFaCircleChevronRight, faTrafficCone as solidFaTrafficCone, faMusic as solidFaMusic,
  faComment as solidFaComment, faTriangleExclamation as solidFaTriangleExclamation,
  faFaceSmilingHands as solidFaFaceSmilingHands, faChalkboardUser as solidFaChalkboardUser,
  faSealQuestion as solidFaSealQuestion, faCalendarRange as solidFaCalendarRange,
  faCalendarHeart as solidFaCalendarHeart,
} from '@fortawesome/pro-solid-svg-icons';
// here we are importing all the icons with iconStyle==duotone
import {
  faLanguage as duotoneFaLanguage, faCheck as duotoneFaCheck, faUserGraduate as duotoneFaUserGraduate, faGraduationCap as duotoneFaGraduationCap,
  faArrowRightFromLine as duotoneFaArrowRightFromLine, faArrowLeftToLine as duotoneFaArrowLeftToLine,
  faGear as duotoneFaGear, faEarthAmericas as duotoneFaEarthAmericas, faHashtag as duotoneFaHashtag,
  faChartLine as duotoneFaChartLine, faBook as duotoneFaBook, faDisplay as duotoneFaDisplay,
  faHouse as duotoneFaHouse, faAngleRight as duotoneFaAngleRight, faCircleNotch as duotoneFaCircleNotch, faUnlock as duotoneFaUnlock,
  faAngleDown as duotoneFaAngleDown, faBars as duotoneFaBars, faCaretRight as duotoneFaCaretRight, faEnvelope as duotoneFaEnvelope,
  faCaretLeft as duotoneFaCaretLeft, faBoltLightning as duotoneFaBoltLightning, faQuestion as duotoneFaQuestion, faSquareInfo as duotoneFaSquareInfo,
  faMicrophoneStand as duotoneFatMicrophoneStand, faDesktop as duotoneFaDesktop, faVideo as duotoneFaVideo,
  faCalendarDays as duotoneFaCalendarDays, faBagShopping as duotoneFaBagShopping, faUserGear as duotoneFaUserGear,
  faEllipsisStrokeVertical as duotoneFaEllipsisStrokeVertical, faEllipsisStroke as duotoneFaEllipsisStroke,
  faStar as duotoneFaStar, faUserXmark as duotoneFaUserXmark, faUserCheck as duotoneFaUserCheck,
  faPartyHorn as duotoneFaPartyHorn, faMoneyBill as duotoneFaMoneyBill, faCircleUser as duotoneFaCircleUser,
  faUsers as duotoneFaUsers, faArrowLeft as duotoneFaArrowLeft, faCircleCaretLeft as duotoneFaCircleCaretLeft,
  faCircleCaretRight as duotoneFaCircleCaretRight, faUser as duotoneFaUser, faAddressCard as duotoneFaAddressCard,
  faSun as duotoneFaSun, faMoonStars as duotoneFaMoonStars, faFolderUser as duotoneFaFolderUser,
  faScreencast as duotoneFaScreencast, faCircleXmark as duotoneFaCircleXmark,
  faCircleChevronRight as duotoneFaCircleChevronRight, faTrafficCone as duotoneFaTrafficCone, faMusic as duotoneFaMusic,
  faComment as duotoneFaComment, faTriangleExclamation as duotoneFaTriangleExclamation,
  faFaceSmilingHands as duotoneFaFaceSmilingHands, faChalkboardUser as duotoneFaChalkboardUser,
  faSealQuestion as duotoneFaSealQuestion, faCalendarRange as duotoneFaCalendarRange,
  faCalendarHeart as duotoneFaCalendarHeart,
} from '@fortawesome/pro-duotone-svg-icons';
import { useTheme } from '@mui/material';
import { CustomFontAwesomeIcon } from './index.style';
import { IcProps } from './interface';

// Previously, we are adding all the icons ( which include thousansds of icons per style ) present in the
// solid, light and duotone. Due to this, bundle size is increased. So, now we are using the tree-shacking you can see the docs here :
// https://fontawesome.com/docs/web/use-with/vue/dig-deeper#tree-shaking
// Now, we are only adding the icons which we are using in our app, this will reduce the bundle size.
// library.add function is used to pre-registering icon definitions so that do not have to explicitly pass // them to render an icon.
library.add(
  lightFaLanguage,
  lightFaCheck,
  lightFaUserGraduate,
  lightFaGraduationCap,
  lightFaArrowRightFromLine,
  lightFaArrowLeftToLine,
  lightFaGear,
  lightFaEarthAmericas,
  lightFaChartLine,
  lightFaBook,
  lightFaDisplay,
  lightFaHouse,
  lightFaAngleRight,
  lightFaAngleDown,
  lightFaBars,
  lightFaCaretRight,
  lightFaCaretLeft,
  lightFaCircleNotch,
  lightFaBoltLightning,
  lightFaQuestion,
  lightFaHashtag,
  lightFaUnlock,
  lightFaEnvelope,
  lightFaSquareInfo,
  lightFatMicrophoneStand,
  lightFaDesktop,
  lightFaVideo,
  lightFaCalendarDays,
  lightFaBagShopping,
  lightFaUserGear,
  lightFaEllipsisStrokeVertical,
  lightFaEllipsisStroke,
  lightFaStar,
  lightFaUserXmark,
  lightFaUserCheck,
  lightFaPartyHorn,
  lightFaMoneyBill,
  lightFaCircleUser,
  lightFaUsers,
  lightFaArrowLeft,
  lightFaCircleCaretLeft,
  lightFaCircleCaretRight,
  lightFaUser,
  lightFaAddressCard,
  lightFaSun,
  lightFaMoonStars,
  lightFaFolderUser,
  lightFaScreencast,
  lightFaCircleXmark,
  lightFaCircleChevronRight,
  lightFaTrafficCone,
  lightFaMusic,
  lightFaComment,
  lightFaTriangleExclamation,
  lightFaFaceSmilingHands,
  lightFaChalkboardUser,
  lightFaSealQuestion,
  lightFaCalendarRange,
  lightFaCalendarHeart,
  solidFaLanguage,
  solidFaCheck,
  solidFaUserGraduate,
  solidFaGraduationCap,
  solidFaArrowRightFromLine,
  solidFaArrowLeftToLine,
  solidFaGear,
  solidFaEarthAmericas,
  solidFaChartLine,
  solidFaBook,
  solidFaDisplay,
  solidFaHouse,
  solidFaAngleRight,
  solidFaAngleDown,
  solidFaBars,
  solidFaCaretRight,
  solidFaCaretLeft,
  solidFaCircleNotch,
  solidFaBoltLightning,
  solidFaQuestion,
  solidFaHashtag,
  solidFaUnlock,
  solidFaEnvelope,
  solidFaSquareInfo,
  solidFatMicrophoneStand,
  solidFaDesktop,
  solidFaVideo,
  solidFaCalendarDays,
  solidFaBagShopping,
  solidFaUserGear,
  solidFaEllipsisStrokeVertical,
  solidFaEllipsisStroke,
  solidFaStar,
  solidFaUserXmark,
  solidFaUserCheck,
  solidFaPartyHorn,
  solidFaMoneyBill,
  solidFaCircleUser,
  solidFaUsers,
  solidFaArrowLeft,
  solidFaCircleCaretLeft,
  solidFaCircleCaretRight,
  solidFaUser,
  solidFaAddressCard,
  solidFaSun,
  solidFaMoonStars,
  solidFaFolderUser,
  solidFaScreencast,
  solidFaCircleXmark,
  solidFaCircleChevronRight,
  solidFaTrafficCone,
  solidFaMusic,
  solidFaComment,
  solidFaTriangleExclamation,
  solidFaFaceSmilingHands,
  solidFaChalkboardUser,
  solidFaSealQuestion,
  solidFaCalendarRange,
  solidFaCalendarHeart,
  duotoneFaLanguage,
  duotoneFaCheck,
  duotoneFaUserGraduate,
  duotoneFaGraduationCap,
  duotoneFaArrowRightFromLine,
  duotoneFaArrowLeftToLine,
  duotoneFaGear,
  duotoneFaEarthAmericas,
  duotoneFaChartLine,
  duotoneFaBook,
  duotoneFaDisplay,
  duotoneFaHouse,
  duotoneFaAngleRight,
  duotoneFaAngleDown,
  duotoneFaBars,
  duotoneFaCaretRight,
  duotoneFaCaretLeft,
  duotoneFaCircleNotch,
  duotoneFaBoltLightning,
  duotoneFaQuestion,
  duotoneFaHashtag,
  duotoneFaUnlock,
  duotoneFaEnvelope,
  duotoneFaSquareInfo,
  duotoneFatMicrophoneStand,
  duotoneFaDesktop,
  duotoneFaVideo,
  duotoneFaCalendarDays,
  duotoneFaBagShopping,
  duotoneFaUserGear,
  duotoneFaEllipsisStrokeVertical,
  duotoneFaEllipsisStroke,
  duotoneFaStar,
  duotoneFaUserXmark,
  duotoneFaUserCheck,
  duotoneFaPartyHorn,
  duotoneFaMoneyBill,
  duotoneFaCircleUser,
  duotoneFaUsers,
  duotoneFaArrowLeft,
  duotoneFaCircleCaretLeft,
  duotoneFaCircleCaretRight,
  duotoneFaUser,
  duotoneFaAddressCard,
  duotoneFaSun,
  duotoneFaMoonStars,
  duotoneFaFolderUser,
  duotoneFaScreencast,
  duotoneFaCircleXmark,
  duotoneFaCircleChevronRight,
  duotoneFaTrafficCone,
  duotoneFaMusic,
  duotoneFaComment,
  duotoneFaTriangleExclamation,
  duotoneFaFaceSmilingHands,
  duotoneFaChalkboardUser,
  duotoneFaSealQuestion,
  duotoneFaCalendarRange,
  duotoneFaCalendarHeart,
);

/**
 * Our main Icon component.
 */
export const Ic: React.FunctionComponent<IcProps> = (props) => {
  const {
    iconName,
    iconStyle = 'light',
    size = 'rel-default',
    // it will handle animation of icon. i-e spin | pulse etc
    animationType,
    color = 'inherit',
    onClick,
    hovercursor = 'none',
    cp,
  } = props;
  const theme = useTheme();
  return (
    <CustomFontAwesomeIcon
      // animation is applied using the className of animation, defined by font-awesome.
      className={animationType ? `fa-${animationType}` : ''}
      theme={theme}
      color={color}
      size={size === 'rel-default' ? undefined : size.substring(4) as SizeProp}
      icon={[`fa${iconStyle.charAt(0)}` as IconPrefix, iconName as IconName]}
      onClick={onClick}
      hovercursor={hovercursor}
      {...cp}
    />
  );
};
