import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { WCLThemeProps } from 'utils/wclThemeProps';

export const CustomDataGrid = styled(DataGrid)(({ theme }: WCLThemeProps) => ({
  // our fonts
  fontFamily: theme?.ourTheme.typography.fontFamily,
  [theme?.breakpoints.up('md') as any]: {
    fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem`,
  },
  [theme?.breakpoints.down('md') as any]: {
    fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem`,
  },
  fontWeight: theme?.ourTheme.typography.p.fontWeight,
  color: `hsl(${theme?.ourTheme.colors.text.base})`,
  // this is a page-level item that users don't really "interact with"
  transition: theme?.ourTheme.components.transition.page,

  // main bg color of our containers (cards, usually). we match that so the table looks
  // like a card
  backgroundColor: `hsl(${theme?.ourTheme.colors.app.container})`,

  // border-radius on top should be 0, only need to apply radius on bottom
  borderRadius: `0 0 ${theme?.ourTheme.borders.borderRadius.medium}px ${theme?.ourTheme.borders.borderRadius.medium}px`,

  // shadow for the entire component
  boxShadow: 'none',

  // this removes the border from the outer edges
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: 'none',

  // this sets the color of the very top border of the grid. other borders within the grid have to
  // be set below
  borderColor: theme?.ourTheme.colors.cp.divider.thin,

  // the column header
  ...(theme?.themeMode === 'light' && {
    '& .MuiDataGrid-columnHeaders': {
      // no radius on this header, otherwise the main bg color of the component shows through
      borderRadius: '0',
      // removes the border from the left and right edges? need to check, not sure
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      // this sets the header *bottom* border color. should be the same as we have above
      borderColor: theme?.ourTheme.colors.cp.divider.thin,
    },
  }),
  ...(theme?.themeMode === 'dark' && {
    '& .MuiDataGrid-columnHeaders': {
      // no radius on this header, otherwise the main bg color of the component shows through
      borderRadius: '0',
      // removes the border from the left and right edges? need to check, not sure
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      // in dark mode, we want the borders to be a fairly light color
      borderColor: theme?.ourTheme.colors.cp.divider.thin,
    },
  }),

  // this sets the border color near the bottom of the grid. the border that's just a bit above the pagination
  ...(theme?.themeMode === 'light' && {
    '& .MuiDataGrid-footerContainer': {
      borderColor: theme?.ourTheme.colors.cp.divider.thin,
    },
  }),
  ...(theme?.themeMode === 'dark' && {
    '& .MuiDataGrid-footerContainer': {
      borderColor: theme?.ourTheme.colors.cp.divider.thin,
    },
  }),

  // this removes the border between each cell in the header
  '& .MuiDataGrid-columnHeader': {
    borderRight: 'none',
  },

  // make the column header text the same weight as our "subheading"
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: theme?.ourTheme.typography.subheading.fontWeight,
  },

  // remove the border around each individual cell within the table. we may want to make this an
  // option in the api in future, sometimes end users might want to have border on cells/rows
  '& .MuiDataGrid-cell': {
    border: 'none',
  },

  // row hover
  ...(theme?.themeMode === 'light' && {
    '& .MuiDataGrid-row': {
      '&:hover, &.Mui-hovered': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter3})`,
      },
    },
  }),
  ...(theme?.themeMode === 'dark' && {
    '& .MuiDataGrid-row': {
      '&:hover, &.Mui-hovered': {
        // note that we add a lot of transparency in dark mode, otherwise the bg color is way too bright
        backgroundColor: `hsla(${theme?.ourTheme.colors.primary.lighter1}, .15)`,
      },
    },
  }),

  // row selected
  ...(theme?.themeMode === 'light' && {
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter3})`,
    },
  }),
  ...(theme?.themeMode === 'dark' && {
    '& .MuiDataGrid-row.Mui-selected': {
      // note that we add a lot of transparency in dark mode, otherwise the bg color is way too bright
      backgroundColor: `hsla(${theme?.ourTheme.colors.primary.lighter1}, .15)`,
    },
  }),

  // pagination buttons
  // in light mode, we only need to change the hover color
  ...(theme?.themeMode === 'light' && {
    '& .MuiIconButton-root': {
      '&:hover, &.Mui-hovered': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter3})`,
      },
    },
  }),
  // in dark mode, we have to change the color of the buttons
  ...(theme?.themeMode === 'dark' && {
    '& .MuiIconButton-root': {
      color: `hsl(${theme?.ourTheme.colors.primary.lighter3})`,
      '&:hover': {
        // note that we add a lot of transparency in dark mode, otherwise the bg color is way too bright
        backgroundColor: `hsla(${theme?.ourTheme.colors.primary.lighter1}, .15)`,
      },
    },
  }),
  ...(theme?.themeMode === 'dark' && {
    '& .MuiIconButton-root.Mui-disabled': {
      // this sets the color of the button when it's disabled
      color: `hsla(${theme?.ourTheme.colors.primary.lighter3}, .3)`,
    },
  }),

  // these remove the border around *cells* when a user clicks on a cell. the first removes them for
  // the column header, the second for individual cells within the table
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
}));

export const PaginationMain = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
}));

export const TypographyBox = styled(Box)(() => ({
  paddingTop: '4px',
}));

export const DataGridContainer = styled(Box)(() => ({
  width: '100%',
}));
