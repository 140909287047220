/**
 * @generated SignedSource<<d7b64450b72e138a614e614134b69c52>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorldTourGetCountryDetailFragment$data = {
  readonly country_name: string;
  readonly flag_image: string | null;
  readonly overview: string | null;
  readonly practice_world_funfacts: ReadonlyArray<{
    readonly funfact: string;
  }>;
  readonly practice_world_radiostations: ReadonlyArray<{
    readonly city: string;
    readonly id: string;
    readonly name: string;
    readonly playback_url: string;
    readonly practice_world_countries_id: number;
  }>;
  readonly " $fragmentType": "WorldTourGetCountryDetailFragment";
};
export type WorldTourGetCountryDetailFragment$key = {
  readonly " $data"?: WorldTourGetCountryDetailFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorldTourGetCountryDetailFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "is_displayed": {
        "_eq": 1
      }
    }
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorldTourGetCountryDetailFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overview",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "practice_world_radiostations",
      "kind": "LinkedField",
      "name": "practice_world_radiostations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "playback_url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "practice_world_countries_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": "practice_world_radiostations(where:{\"is_displayed\":{\"_eq\":1}})"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flag_image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "practice_world_funfacts",
      "kind": "LinkedField",
      "name": "practice_world_funfacts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "funfact",
          "storageKey": null
        }
      ],
      "storageKey": "practice_world_funfacts(where:{\"is_displayed\":{\"_eq\":1}})"
    }
  ],
  "type": "practice_world_countries",
  "abstractKey": null
};
})();

(node as any).hash = "49160e26ef314db1c61e7fb944298a48";

export default node;
