import React, { useState } from 'react';
import {
  CardContent, Grid2, Grid2Ct, Ty, Card, TextField, Select, Button, Box, Alert,
  SelectChangeEvent,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { useMutation } from 'react-relay';
import { DateTime, Settings } from 'luxon';
import { StuSettingsUpdatebasicMutation } from '../relay/StuSettingsUpdatebasic';
import { tz } from '../../../../../utils/Timezones';

interface TimeZone {
  name: String,
  value: String,
}

const option: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  weekday: undefined
};

export const RequiredAccountSettings = ({ userInfo }: any) => {
  const [password, setPassword] = useState<string>('');
  const [age, setAge] = useState<string>(userInfo?.relayAge);
  const [stdTimeZone, setStdTimeZone] = useState<string>(userInfo?.timezone_set === 1 ? userInfo?.user_timezone : '');
  const [currentTime, setCurrentTime] = useState<string>(userInfo?.timezone_set === 1 ? DateTime.local().toLocaleString(option) : '__');
  // password validation. must be > 4 chars
  const isPasswordValid = (usr_password: any) => usr_password.trim().length > 4;
  // TODO: ensure age is an integer (in wcl, textfield input needs to be type integer)
  // age validation. must be an integer between 1 and 109
  const isAgeValid = (usr_age: any) => parseInt(usr_age, 10) > 0 && parseInt(usr_age, 10) < 110;
  // making a new array with `nameDisplay` as name and `nameDb` as value 
  // so we can pass it to select component
  const options: TimeZone[] = tz.map((zone) => ({ name: zone.nameDisplay, value: zone.nameDb }));

  // here we are getting timeZone selected value from the dropdown 
  const handleTzChange = (event: SelectChangeEvent) => {
    // storing the selected timeZone in a state varaible
    setStdTimeZone(event.target.value);
    Settings.defaultZone = event.target.value;
    setCurrentTime(DateTime.local().toLocaleString(option));
  };

  // the "save settings" mutation. if an error occurs, we'll show a toast
  const [UpdateStdInfoMutation, isInFlight] = useMutation(StuSettingsUpdatebasicMutation);
  const handlesaveSettings = () => {
    if (isPasswordValid(password) && isAgeValid(age) && stdTimeZone !== null) {
      UpdateStdInfoMutation({
        variables: {
          password,
          age: parseInt(age, 10),
          timezone: stdTimeZone
        },
        onCompleted() {},
        onError(err: any) {
          toast.error('Hmm, something went wrong. Please try reloading the page.');
          Sentry.captureException(`UpdateStdInfoMutation: ${err}`);
        },
      });
    } else {
      toast.warn('Please set a valid password (at least 5 characters long), age, and your time zone!');
    }
  };

  return (
    <Card cp={{ sx: { mb: 2 }, 'data-testid': 'requiredSettingsContainer' }}>
      <CardContent>
        <Grid2Ct>
          {/* title */}
          <Grid2 xs={12}>
            <Ty v="h2" align="center">Last Step Before Scheduling - Finish Your Account Setup!</Ty>
          </Grid2>

          {/* password */}
          <Grid2
            xs={12}
            sm={6}
            xl={4}
            xlOffset={1}
            // we create equal height sections simply with display: flex and
            // flexDirection: column. see https://stackoverflow.com/a/59470890/3650835
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Ty v="subheading">Account Password</Ty>
              {/* flexGrow causes this grow vertically, pushing the textfield down to the
              bottom as we want */}
              <Ty cp={{ sx: { flexGrow: 1 } }}>
                You will need this to log back in to the website later on, so make sure you
                remember it!
              </Ty>
              <TextField
                id="pswd"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                cp={{ sx: { alignSelf: 'start' }, 'data-testid': 'pwdField' }}
              />
            </Box>
          </Grid2>

          {/* age */}
          <Grid2
            xs={12}
            sm={6}
            xl={4}
            xlOffset={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Ty v="subheading">Age</Ty>
            <Ty>
              Some teachers work with adults, some with children, some with both! Set this to the
              age of whomever will be taking lessons.
            </Ty>
            <TextField id="age" value={age} onChange={(e) => setAge(e.target.value)} cp={{ 'data-testid': 'ageField' }} />
          </Grid2>

          {/* time zone */}
          <Grid2 xs={12} sm={6} xl={4} xlOffset={1} sx={{ marginTop: { sm: '30px' } }}>
            <Ty v="subheading">Time Zone</Ty>
            <Ty>
              Choose your time zone &mdash; this will show all times throughout the website
              in your own time zone.
            </Ty>
            {/* @ts-ignore */}
            <Select value={stdTimeZone} onChange={handleTzChange} options={options} cp={{ 'data-testid': 'selectTz' }} />
          </Grid2>

          {/* current time */}
          <Grid2 xs={12} sm={6} xl={4} xlOffset={2} sx={{ mt: 3 }}>
            <Alert severity="info">
              It is currently <strong>{currentTime}</strong> in this time zone.
              Check your clock! If this time is not correct, you have chosen the
              wrong time zone.
            </Alert>
          </Grid2>
        </Grid2Ct>

        {/* save button */}
        <Grid2Ct sx={{ mt: 3, mb: 2 }}>
          <Grid2 xs={12}>
            <Box display="flex" sx={{ justifyContent: 'center' }}>
              <Button size="large" disabled={isInFlight} isLoading={isInFlight} onClick={handlesaveSettings} cp={{ 'data-testid': 'SaveBtn' }}>Save My Settings</Button>
            </Box>
          </Grid2>
        </Grid2Ct>
      </CardContent>
    </Card>
  );
};
