import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { commitLocalUpdate } from 'react-relay';
import { isJsonString } from '../../../utils/isJSONString';
import { useFavoriteTeacher } from '../../../common/hooks/teachers/favorites/useFavoriteTeacher';
import AppEnvironment from '../../../relay/AppEnvironment';
import { RelayFavoritedVals } from '../../../common/relay/resolvers/teachers/relayFavorited';

export const FavoriteTeacherIframeEventFromLegacy = () => {
  const { favoriteTeacher } = useFavoriteTeacher();

  useEffect(() => {
    let data: any;
    window.addEventListener('message', (e: any) => {
      if (typeof e?.data === 'string' && isJsonString(e.data)) {
        data = JSON.parse(e.data);
        if (data?.eventName === 'favoriteTeacher') {
          const thisTchUuid = data.data.teacherUuid;

          // we need the teacher's relay id, which we get here
          let thisTchRelayId: string | undefined = '';
          commitLocalUpdate(AppEnvironment, (store) => {
            // TODO: this next line is VERY fragile. if anyone edits our applevel query and forgets
            // to also udpate this here, we will not be adding teachers to a students favorites
            // via this legacy event. long term, we will get rid of old legacy app scheduling. but
            // in the short term, we could if we want to make this much more robust, write a
            // fragment and query for this component that gets the teacher's relay id
            const root = store.get('client:root:resources_connection(where:{"tutor_visibility":{"_eq":"1"},"working_status":{"_is_null":true}})');
            const edges = root?.getLinkedRecords('edges') || [];
            edges.forEach((edge) => {
              const node = edge.getLinkedRecord('node');
              const currentUuid = node?.getValue('uuid');
              const currentId = node?.getValue('id');
              if (currentUuid === thisTchUuid) {
                thisTchRelayId = currentId?.toString();
              }
            });
          });

          // now add the teacher to our favorites. log an error if we did not get id from relay
          if (thisTchRelayId === undefined || thisTchRelayId.length < 2) {
            Sentry.captureException(
              new Error('PROBLEM: FavoriteTeacherIframeEventFromLegacy failed to add teacher to favorites'),
              {
                extra: {
                  tchUuid: thisTchUuid,
                }
              }
            );
          } else {
            // our hook that actually adds the teacher as a favorite. note that we need to pass
            // the CURRENT value of "is this teacher my favorite?" which is no, thus the reason
            // RelayFavoritedVals.NotFavorite is the first argument
            favoriteTeacher(
              RelayFavoritedVals.NotFavorite,
              thisTchUuid,
              thisTchRelayId,
            );
          }
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  // no need to return anything at all
  return (null);
};
