import { vars } from '../../../utils/vars';
import { Props } from './interface';

export const IcSvg = (props: Props) => {
  const {
    width,
    height,
    icon,
    devtestingRooturl,
  } = props;

  // build the url to the icon
  const cdn = vars.imageCdn;
  const urlpart = '/wcl/svgicons/';

  // normally, the url we should use is our image cdn url. but if devtestingRooturl is set, we use the actual
  // s3 bucket link to display the image; this should ONLY be used for testing that the file exists there
  let iconFullUrl = `${cdn}${urlpart}${icon}.svg`;
  if (devtestingRooturl) {
    iconFullUrl = `${vars.rootUrlImageCdn}${urlpart}${icon}.svg`;
  }

  return (
    <img src={iconFullUrl} height={height} width={width} alt="icon" />
  );
};
