import React, { useState } from 'react';
import {
  Grid2, Grid2Ct, TextField, Button, Skeleton, Card, CardContent,
  Select, Alert, CardStandard, SelectChangeEvent, IcSvgList,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFragment, useMutation } from 'react-relay';
import { DateTime, Settings } from 'luxon';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { UsersSettingsFragment } from '../relay/UsersSettings';
import { UsersSettingsFragment$key } from '../relay/__generated__/UsersSettingsFragment.graphql';
import { sitedata } from '../../../../utils/sitedata';
import { tz } from '../../../../utils/Timezones';
import { userBasicSettingsFields } from '../schema/userSettingsFields';
import { BasicUsersSettingsUpdates } from '../relay/BasicUsersSettingsUpdates';
import { SharePackages } from './sharepackages/SharePackages';
import { EmailPrefs } from './emailprefs/EmailPrefs';
import { LeftCol, RightCol, FullwidthCol } from './Common';
import { HrCycle, validateHourCycle } from '../../../../common/utils/DatesTimes/HourCycle';

interface MainComponentProps {
  fragmentRef: UsersSettingsFragment$key
}

interface TimeZone {
  name: string,
  value: string,
}

// main component
export const Main = ({
  fragmentRef
}: MainComponentProps) => {
  // #region general

  const { t } = useTranslation('settingsPageErrorCodes');
  // we need both these  countries and hourCycleOptions in form select components
  const { countries, hourCycleOptions } = sitedata;
  // getting users data from relay store to populate in the form
  const usersSettingsData = useFragment(UsersSettingsFragment, fragmentRef);
  // extracting user node from user data
  const userNode = usersSettingsData.users_connection.edges[0].node;
  const emailUuid = userNode.email_uuid;
  // we need this state to show the current time according to user's timezone
  const [currentTime, setCurrentTime] = useState<string>();
  // we need this state to show the correct time in alert according to selected hour cycle
  const [isHourCycle12, setIsHourCycle12] = useState<boolean>(
    userNode.hour_cycle !== HrCycle.hour24
  );
  // mapping through all timezone to create timeZones options for select component.
  const timeZones: TimeZone[] = tz.map((zone) => ({ name: zone.nameDisplay, value: zone.nameDb }));

  const hrCycle = validateHourCycle(userNode.hour_cycle);

  // we have used react-hook-form here so we don't have to manually create the state and manage
  // that state, we pass in the default schema for validations and default values data we want
  // to render on the screen
  const {
    control, handleSubmit, formState: { errors }
  } = useForm <any>({
    reValidateMode: 'onChange',
    mode: 'onBlur',
    resolver: yupResolver(userBasicSettingsFields),
    defaultValues: {
      firstName: userNode.first_name,
      lastName: userNode.last_name,
      age: userNode.relayAge,
      countryIdNum: userNode.country_id_num,
      timezone: userNode.timezone_set === 1 ? userNode.user_timezone : null,
      email: userNode.email,
      hourCycle: hrCycle
    }
  });

  // #endregion

  // #region basic settings mutation

  // mutation call for the updation of user's settings and isPending flag to make sure
  // the api call has succeeded or not
  const [BasicUserSettingsMutation, isPending] = useMutation(BasicUsersSettingsUpdates);

  // this function gets triggered when user click on save button, it initiates the mutation call
  const MutationCallForBasicSettings = (data: any) => {
    // setting current time to undefined, because after the user has submitted the
    // for (saved changes). We don't want to show the alert anymore.
    setCurrentTime(undefined);
    // commit function for mutation call, takes in 3 main things
    // 1 - variables (payload)
    // 2 - onComplete function, which gets triggered when api call respond with 200 (success)
    // 3 - onError function, which gets triggered when api call respond other than 200 (500, 400)
    BasicUserSettingsMutation({
      variables: {
        firstName: data.firstName,
        lastName: data.lastName,
        age: parseInt(data.age, 10),
        countryId: parseInt(data.countryIdNum, 10),
        timezone: data.timezone,
        email: data.email,
        hourCycle: data.hourCycle,
        password: data.password
      },
      onCompleted: (res: any) => {
        // if all fields are updated successfully, we are displaying toast to user.
        if (res.stu_settings_updategeneral.success === true) {
          toast.success('Changes saved!', { autoClose: 1000 });

          // if any of the field has error then our errors array length will be greater than 0.
          // in this case we need to tell user that some of there fields are not gets saved
        } else if (res.stu_settings_updategeneral.errors.length > 0) {
          res.stu_settings_updategeneral.errors.map((item: any) => {
            toast.error(`We failed to update the ${item} field. Please try again.`);
            return null;
          });
          // this is an unexpected case, if backend has returned something that we don't expect to
          // to get we will catch that here.
        } else {
          toast.error(
            'Hmm, something went wrong. Please reload the page and verify the changes were saved.'
          );
          // for notifying us, that something strange is happening
          Sentry.captureException(
            new Error('Something unexpected happened in new settings page'),
            { extra: { response: res } },
          );
          Sentry.captureMessage(
            'Something unexpected happened, backend is returning error without success or error nodes in user settings page'
          );
        }
      },
      onError: (err) => {
        // this is the case if backend failed to load or gave us other than 200 response,
        // this function will gets triggered, and we will show user an error toast.
        // also we will capture that exception so we are notified through sentry that something
        // strange is happening right now.
        toast.error(
          'Hmm, something went wrong. Please reload the page and verify the changes were saved.'
        );
        Sentry.captureMessage(
          'Something unexpected happened, while users is trying to save his settings. Need dev attention here'
        );
        Sentry.captureException(err);
      }
    });
  };

  // #endregion

  // #region timezone change

  // these option are required for luxon to show the timezone in correct format
  const option: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: isHourCycle12,
    weekday: undefined
  };

  // we will call this function whenever there is a change in hour cycle
  const onHourCycleChange = (e: SelectChangeEvent, onChange: any) => {
    // setting the state according to select value, so our alert will always show
    // time according to there selected hour cycle
    setIsHourCycle12(e.target.value !== HrCycle.hour24);
    // updating the hour cycle, so we get updated value on frontend
    onChange(e);
    // setting the currentTime according 
    setCurrentTime(DateTime.local().toLocaleString(
      { ...option, hour12: e.target.value !== HrCycle.hour24 }
    ));
  };

  // we will call this function whenever there is a change in user's timezone
  const onTimezoneChange = (e: SelectChangeEvent, onChange: any) => {
    // updating the timezone, so we get updated value on frontend
    onChange(e);
    // making the selected timezone as default timezone using luxon
    Settings.defaultZone = e.target.value;
    // setting the currentTime according 
    setCurrentTime(DateTime.local().toLocaleString({ ...option, hour12: isHourCycle12 }));
  };

  // #endregion

  return (
    <>
      {/* main content */}
      <Grid2Ct>
        {/* general settings */}
        <CardContainers>
          <CardStandard
            titleText="General Settings"
            titleIcon={IcSvgList.settings1}
            color="accentBlue1"
            titleIconRight={0}
          >
            <Grid2Ct sx={{ mt: 2 }}>
              {/* form */}
              <Grid2 xs={12}>
                <Grid2Ct>
                  {/* first, last name */}
                  <Grid2 xs={12}>
                    <Grid2Ct>
                      {/* first */}
                      <LeftCol>
                        <Controller
                          name="firstName"
                          control={control}
                          render={
                            ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                              <TextField
                                id="first-name"
                                label="First Name"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helpertext={error ? t(`${error.message}`) : null}
                              />
                            )
                          }
                        />
                      </LeftCol>

                      {/* last */}
                      <RightCol>
                        <Controller
                          name="lastName"
                          control={control}
                          render={
                            ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                              <TextField
                                id="last-name"
                                label="Last Name"
                                value={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                error={!!error}
                                helpertext={error ? t(`${error.message}`) : null}
                              />
                            )
                          }
                        />
                      </RightCol>
                    </Grid2Ct>
                  </Grid2>

                  {/* age, email */}
                  <Grid2 xs={12}>
                    <Grid2Ct>
                      {/* email */}
                      <LeftCol>
                        <Controller
                          name="email"
                          control={control}
                          render={
                            ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                              <TextField
                                id="email-address"
                                label="Email Address"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helpertext={error ? t(`${error.message}`) : null}
                              />
                            )
                          }
                        />
                      </LeftCol>

                      {/* age */}
                      <RightCol>
                        <Controller
                          name="age"
                          control={control}
                          render={
                            ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                              <TextField
                                id="age"
                                type="text"
                                label="My Age"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helpertext={error ? t(`${error.message}`) : null}
                              />
                            )
                          }
                        />
                      </RightCol>
                    </Grid2Ct>
                  </Grid2>

                  {/* timezone, country */}
                  <Grid2 xs={12}>
                    <Grid2Ct>
                      {/* timezone */}
                      <LeftCol>
                        <Controller
                          name="timezone"
                          control={control}
                          render={
                            ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                              <Select
                                label="Time Zone"
                                options={timeZones}
                                value={value}
                                onBlur={onBlur}
                                onChange={(e) => onTimezoneChange(e, onChange)}
                                error={!!error}
                                helperText={error ? t(`${error.message}`) : null}
                              />
                            )
                          }
                        />
                        {/* this alert will show the current time according to user's timezone */}
                        {currentTime && (
                          <Alert
                            severity="info"
                            cp={{
                              sx: {
                                marginTop: '10px'
                              }
                            }}
                          >
                            It is currently <strong>{currentTime}</strong> in this time zone.
                            Check your clock! If this time is not correct, you have chosen the
                            wrong time zone.
                          </Alert>
                        )}
                      </LeftCol>

                      {/* country */}
                      <RightCol>
                        <Controller
                          name="countryIdNum"
                          control={control}
                          render={
                            ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                              <Select
                                label="Country of Residence"
                                options={countries}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error ? t(`${error.message}`) : null}
                              />
                            )
                          }
                        />
                      </RightCol>
                    </Grid2Ct>
                  </Grid2>

                  {/* password, hour cycle */}
                  <Grid2 xs={12}>
                    <Grid2Ct>
                      {/* password */}
                      <LeftCol>
                        <Controller
                          name="password"
                          control={control}
                          render={
                            ({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                              <TextField
                                id="password"
                                type="password"
                                label="Change Password"
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={!!error}
                                helpertext={error ? t(`${error.message}`) : null}
                              />
                            )
                          }
                        />
                      </LeftCol>

                      {/* hour cycle (12 or 24) */}
                      <RightCol>
                        <Controller
                          name="hourCycle"
                          control={control}
                          render={
                            ({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                              <Select
                                label="Time Display"
                                options={hourCycleOptions}
                                value={value}
                                onChange={(e) => onHourCycleChange(e, onChange)}
                                onBlur={onBlur}
                                error={!!error}
                                helperText={error ? t(`${error.message}`) : null}
                              />
                            )
                          }
                        />
                      </RightCol>
                    </Grid2Ct>
                  </Grid2>

                  {/* save button, validation error */}
                  <Grid2 xs={12}>
                    <Grid2Ct>
                      <LeftCol>
                        <Button
                          onClick={handleSubmit(MutationCallForBasicSettings)}
                          disabled={Object.keys(errors).length > 0 || isPending}
                          color="accentBlue1"
                          isLoading={isPending}
                        >
                          Save Changes
                        </Button>
                      </LeftCol>

                      {/* display err if any field is invalid */}
                      {Object.keys(errors).length > 0 && (
                        <FullwidthCol>
                          <Alert variant="standard" title="Error" severity="error">
                            One of the options you entered seems to be invalid, see above
                          </Alert>
                        </FullwidthCol>
                      )}
                    </Grid2Ct>
                  </Grid2>
                </Grid2Ct>
              </Grid2>
            </Grid2Ct>
          </CardStandard>
        </CardContainers>

        {/* share packages */}
        <CardContainers>
          <SharePackages groupNameInitialValue={userNode.groupname} />
        </CardContainers>

        {/* email preferences */}
        <CardContainers>
          <EmailPrefs uuid={emailUuid} />
        </CardContainers>
      </Grid2Ct>
    </>
  );
};

// container for each main card (general settings, group packages)
const CardContainers = ({ children }: any) => (
  <Grid2 xs={12} md={10} mdOffset={1} lg={10} lgOffset={1} xl={8} xlOffset={2}>
    {children}
  </Grid2>
);

// main component loading glimmer
export const MainLoading = () => (
  <Grid2Ct>
    <CardContainers>
      <Card>
        <CardContent>
          <Grid2Ct>
            <Grid2 xs={12} md={6}>
              <Skeleton animation="wave" variant="rectangular" width="100%" height="30px" />
            </Grid2>

            <GlimmerRow />
            <GlimmerRow />
            <GlimmerRow />
            <GlimmerRow />
            <GlimmerRow />
            <GlimmerRow />
          </Grid2Ct>
        </CardContent>
      </Card>
    </CardContainers>

    <CardContainers>
      <Card>
        <CardContent>
          <Grid2Ct>
            <Grid2 xs={12} md={6}>
              <Skeleton animation="wave" variant="rectangular" width="100%" height="30px" />
            </Grid2>

            <GlimmerRow />
          </Grid2Ct>
        </CardContent>
      </Card>
    </CardContainers>
  </Grid2Ct>
);

// single row for the glimmer
const GlimmerRow = () => (
  <Grid2 xs={12}>
    <Grid2Ct>
      <LeftCol>
        <Skeleton animation="wave" variant="rectangular" width="100%" height="40px" />
      </LeftCol>
      <RightCol>
        <Skeleton animation="wave" variant="rectangular" width="100%" height="40px" />
      </RightCol>
    </Grid2Ct>
  </Grid2>
);
