import * as Sentry from '@sentry/react';

// TODO: this should be a type shared between the front and back end
// The only two values allowed for hour_cycle in our database are h12 and h23
//  h23 is the 24 hour clock, commonly used all around the world
//  h12 is the 12 hour clock, commonly used in the US
// Note that we export this because it is used in other places
export enum HrCycle {
  hour12 = 'h12',
  hour24 = 'h23',
}

// the type that validateHourCycle returns
type HrCy = HrCycle.hour12 | HrCycle.hour24;

// This function should be used whenever we get a user's hour_cycle (from users_connection).
// It takes in hour_cycle, which could be theoretically be any string value, and coerces it
// to the allowed values of either h12 or h23. Also logs if an unallowed value is detected
export const validateHourCycle = (hourCycle: string): HrCy => {
  let ret: string = 'h12';
  if (hourCycle !== 'h12' && hourCycle !== 'h23') {
    // log an error if we ever get a value that's not alowed. should never occur because our db
    // has a hard check that only allows h12 and h23 to be set
    Sentry.captureException(
      new Error('validateHrCy detected an hour_cycle value that is not allowed'),
      {
        extra: {
          hourCycleValue: hourCycle,
        }
      }
    );
  } else {
    ret = hourCycle;
  }
  const returnWithType = ret as HrCy;
  return returnWithType;
};
