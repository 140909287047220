import { graphql } from 'babel-plugin-relay/macro';

export const PurchaseChooseQuery = graphql`
  query PurchaseChooseQuery {
    ...PackagesDetail
    users_connection {
      edges {
        node {
          ...PurchaseChooseFragment
        }
      }
    }
  }
`;

export const PurchaseChooseFragment = graphql`
  fragment PurchaseChooseFragment on users {
    lang_learning
  }
`;
