import React from 'react';
import { CircularProgress } from '@mui/material';
import { CustomButton } from './index.style';
import { Props } from './interface';
/**
 * Our main button component. See:
 *
 * https://mui.com/material-ui/react-button/
 *
 * https://mui.com/material-ui/api/button/
 */
export const Button: React.FunctionComponent<Props> = (props) => {
  const {
    children,
    size = 'medium',
    fullWidth = false,
    disabled = false,
    color = 'primary',
    variant = 'contained',
    href,
    onClick,
    dataTestId,
    isLoading = false,
    cp = undefined,
    component,
    to,
  } = props;
  return (
    <CustomButton
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      color={color}
      variant={variant}
      href={href}
      onClick={onClick}
      data-testid={dataTestId}
      // default circularProgress size is 16. so, we are applying the save.
      // https://mui.com/material-ui/api/loading-button/
      endIcon={isLoading ? <CircularProgress color="inherit" size={16} /> : null}
      {...cp}
      component={component}
      to={to}
    >
      {children}
    </CustomButton>
  );
};
