// all countries present in our db right now!
export const countries = [
  {
    value: 4,
    name: 'Afghanistan'
  },
  {
    value: 248,
    name: 'Åland Islands'
  },
  {
    value: 8,
    name: 'Albania'
  },
  {
    value: 12,
    name: 'Algeria'
  },
  {
    value: 16,
    name: 'American Samoa'
  },
  {
    value: 20,
    name: 'Andorra'
  },
  {
    value: 24,
    name: 'Angola'
  },
  {
    value: 660,
    name: 'Anguilla'
  },
  {
    value: 10,
    name: 'Antarctica'
  },
  {
    value: 28,
    name: 'Antigua and Barbuda'
  },
  {
    value: 32,
    name: 'Argentina'
  },
  {
    value: 51,
    name: 'Armenia'
  },
  {
    value: 533,
    name: 'Aruba'
  },
  {
    value: 36,
    name: 'Australia'
  },
  {
    value: 40,
    name: 'Austria'
  },
  {
    value: 31,
    name: 'Azerbaijan'
  },
  {
    value: 44,
    name: 'Bahamas'
  },
  {
    value: 48,
    name: 'Bahrain'
  },
  {
    value: 50,
    name: 'Bangladesh'
  },
  {
    value: 52,
    name: 'Barbados'
  },
  {
    value: 112,
    name: 'Belarus'
  },
  {
    value: 56,
    name: 'Belgium'
  },
  {
    value: 84,
    name: 'Belize'
  },
  {
    value: 204,
    name: 'Benin'
  },
  {
    value: 60,
    name: 'Bermuda'
  },
  {
    value: 64,
    name: 'Bhutan'
  },
  {
    value: 68,
    name: 'Bolivia'
  },
  {
    value: 70,
    name: 'Bosnia and Herzegovina'
  },
  {
    value: 72,
    name: 'Botswana'
  },
  {
    value: 74,
    name: 'Bouvet Island'
  },
  {
    value: 76,
    name: 'Brazil'
  },
  {
    value: 86,
    name: 'British Indian Ocean Territory'
  },
  {
    value: 92,
    name: 'British Virgin Islands'
  },
  {
    value: 96,
    name: 'Brunei'
  },
  {
    value: 100,
    name: 'Bulgaria'
  },
  {
    value: 854,
    name: 'Burkina Faso'
  },
  {
    value: 108,
    name: 'Burundi'
  },
  {
    value: 116,
    name: 'Cambodia'
  },
  {
    value: 120,
    name: 'Cameroon'
  },
  {
    value: 124,
    name: 'Canada'
  },
  {
    value: 132,
    name: 'Cape Verde'
  },
  {
    value: 535,
    name: 'Caribbean Netherlands'
  },
  {
    value: 136,
    name: 'Cayman Islands'
  },
  {
    value: 140,
    name: 'Central African Republic'
  },
  {
    value: 148,
    name: 'Chad'
  },
  {
    value: 152,
    name: 'Chile'
  },
  {
    value: 156,
    name: 'China'
  },
  {
    value: 162,
    name: 'Christmas Island'
  },
  {
    value: 166,
    name: 'Cocos (Keeling) Islands'
  },
  {
    value: 170,
    name: 'Colombia'
  },
  {
    value: 174,
    name: 'Comoros'
  },
  {
    value: 184,
    name: 'Cook Islands'
  },
  {
    value: 188,
    name: 'Costa Rica'
  },
  {
    value: 191,
    name: 'Croatia'
  },
  {
    value: 192,
    name: 'Cuba'
  },
  {
    value: 531,
    name: 'Curaçao'
  },
  {
    value: 196,
    name: 'Cyprus'
  },
  {
    value: 203,
    name: 'Czechia'
  },
  {
    value: 208,
    name: 'Denmark'
  },
  {
    value: 262,
    name: 'Djibouti'
  },
  {
    value: 212,
    name: 'Dominica'
  },
  {
    value: 214,
    name: 'Dominican Republic'
  },
  {
    value: 180,
    name: 'DR Congo'
  },
  {
    value: 218,
    name: 'Ecuador'
  },
  {
    value: 818,
    name: 'Egypt'
  },
  {
    value: 222,
    name: 'El Salvador'
  },
  {
    value: 226,
    name: 'Equatorial Guinea'
  },
  {
    value: 232,
    name: 'Eritrea'
  },
  {
    value: 233,
    name: 'Estonia'
  },
  {
    value: 748,
    name: 'Eswatini'
  },
  {
    value: 231,
    name: 'Ethiopia'
  },
  {
    value: 238,
    name: 'Falkland Islands'
  },
  {
    value: 234,
    name: 'Faroe Islands'
  },
  {
    value: 242,
    name: 'Fiji'
  },
  {
    value: 246,
    name: 'Finland'
  },
  {
    value: 250,
    name: 'France'
  },
  {
    value: 254,
    name: 'French Guiana'
  },
  {
    value: 258,
    name: 'French Polynesia'
  },
  {
    value: 260,
    name: 'French Southern and Antarctic Lands'
  },
  {
    value: 266,
    name: 'Gabon'
  },
  {
    value: 270,
    name: 'Gambia'
  },
  {
    value: 268,
    name: 'Georgia'
  },
  {
    value: 276,
    name: 'Germany'
  },
  {
    value: 288,
    name: 'Ghana'
  },
  {
    value: 292,
    name: 'Gibraltar'
  },
  {
    value: 300,
    name: 'Greece'
  },
  {
    value: 304,
    name: 'Greenland'
  },
  {
    value: 308,
    name: 'Grenada'
  },
  {
    value: 312,
    name: 'Guadeloupe'
  },
  {
    value: 316,
    name: 'Guam'
  },
  {
    value: 320,
    name: 'Guatemala'
  },
  {
    value: 831,
    name: 'Guernsey'
  },
  {
    value: 324,
    name: 'Guinea'
  },
  {
    value: 624,
    name: 'Guinea-Bissau'
  },
  {
    value: 328,
    name: 'Guyana'
  },
  {
    value: 332,
    name: 'Haiti'
  },
  {
    value: 334,
    name: 'Heard Island and McDonald Islands'
  },
  {
    value: 340,
    name: 'Honduras'
  },
  {
    value: 344,
    name: 'Hong Kong'
  },
  {
    value: 348,
    name: 'Hungary'
  },
  {
    value: 352,
    name: 'Iceland'
  },
  {
    value: 356,
    name: 'India'
  },
  {
    value: 360,
    name: 'Indonesia'
  },
  {
    value: 364,
    name: 'Iran'
  },
  {
    value: 368,
    name: 'Iraq'
  },
  {
    value: 372,
    name: 'Ireland'
  },
  {
    value: 833,
    name: 'Isle of Man'
  },
  {
    value: 376,
    name: 'Israel'
  },
  {
    value: 380,
    name: 'Italy'
  },
  {
    value: 384,
    name: 'Ivory Coast'
  },
  {
    value: 388,
    name: 'Jamaica'
  },
  {
    value: 392,
    name: 'Japan'
  },
  {
    value: 832,
    name: 'Jersey'
  },
  {
    value: 400,
    name: 'Jordan'
  },
  {
    value: 398,
    name: 'Kazakhstan'
  },
  {
    value: 404,
    name: 'Kenya'
  },
  {
    value: 296,
    name: 'Kiribati'
  },
  {
    value: 414,
    name: 'Kuwait'
  },
  {
    value: 417,
    name: 'Kyrgyzstan'
  },
  {
    value: 418,
    name: 'Laos'
  },
  {
    value: 428,
    name: 'Latvia'
  },
  {
    value: 422,
    name: 'Lebanon'
  },
  {
    value: 426,
    name: 'Lesotho'
  },
  {
    value: 430,
    name: 'Liberia'
  },
  {
    value: 434,
    name: 'Libya'
  },
  {
    value: 438,
    name: 'Liechtenstein'
  },
  {
    value: 440,
    name: 'Lithuania'
  },
  {
    value: 442,
    name: 'Luxembourg'
  },
  {
    value: 446,
    name: 'Macau'
  },
  {
    value: 807,
    name: 'Macedonia'
  },
  {
    value: 450,
    name: 'Madagascar'
  },
  {
    value: 454,
    name: 'Malawi'
  },
  {
    value: 458,
    name: 'Malaysia'
  },
  {
    value: 462,
    name: 'Maldives'
  },
  {
    value: 466,
    name: 'Mali'
  },
  {
    value: 470,
    name: 'Malta'
  },
  {
    value: 584,
    name: 'Marshall Islands'
  },
  {
    value: 474,
    name: 'Martinique'
  },
  {
    value: 478,
    name: 'Mauritania'
  },
  {
    value: 480,
    name: 'Mauritius'
  },
  {
    value: 175,
    name: 'Mayotte'
  },
  {
    value: 484,
    name: 'Mexico'
  },
  {
    value: 583,
    name: 'Micronesia'
  },
  {
    value: 498,
    name: 'Moldova'
  },
  {
    value: 492,
    name: 'Monaco'
  },
  {
    value: 496,
    name: 'Mongolia'
  },
  {
    value: 499,
    name: 'Montenegro'
  },
  {
    value: 500,
    name: 'Montserrat'
  },
  {
    value: 504,
    name: 'Morocco'
  },
  {
    value: 508,
    name: 'Mozambique'
  },
  {
    value: 104,
    name: 'Myanmar'
  },
  {
    value: 516,
    name: 'Namibia'
  },
  {
    value: 520,
    name: 'Nauru'
  },
  {
    value: 524,
    name: 'Nepal'
  },
  {
    value: 528,
    name: 'Netherlands'
  },
  {
    value: 540,
    name: 'New Caledonia'
  },
  {
    value: 554,
    name: 'New Zealand'
  },
  {
    value: 558,
    name: 'Nicaragua'
  },
  {
    value: 562,
    name: 'Niger'
  },
  {
    value: 566,
    name: 'Nigeria'
  },
  {
    value: 570,
    name: 'Niue'
  },
  {
    value: 574,
    name: 'Norfolk Island'
  },
  {
    value: 408,
    name: 'North Korea'
  },
  {
    value: 580,
    name: 'Northern Mariana Islands'
  },
  {
    value: 578,
    name: 'Norway'
  },
  {
    value: 512,
    name: 'Oman'
  },
  {
    value: 586,
    name: 'Pakistan'
  },
  {
    value: 585,
    name: 'Palau'
  },
  {
    value: 275,
    name: 'Palestine'
  },
  {
    value: 591,
    name: 'Panama'
  },
  {
    value: 598,
    name: 'Papua New Guinea'
  },
  {
    value: 600,
    name: 'Paraguay'
  },
  {
    value: 604,
    name: 'Peru'
  },
  {
    value: 608,
    name: 'Philippines'
  },
  {
    value: 612,
    name: 'Pitcairn Islands'
  },
  {
    value: 616,
    name: 'Poland'
  },
  {
    value: 620,
    name: 'Portugal'
  },
  {
    value: 630,
    name: 'Puerto Rico'
  },
  {
    value: 634,
    name: 'Qatar'
  },
  {
    value: 178,
    name: 'Republic of the Congo'
  },
  {
    value: 638,
    name: 'Réunion'
  },
  {
    value: 642,
    name: 'Romania'
  },
  {
    value: 643,
    name: 'Russia'
  },
  {
    value: 646,
    name: 'Rwanda'
  },
  {
    value: 652,
    name: 'Saint Barthélemy'
  },
  {
    value: 654,
    name: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    value: 659,
    name: 'Saint Kitts and Nevis'
  },
  {
    value: 662,
    name: 'Saint Lucia'
  },
  {
    value: 663,
    name: 'Saint Martin'
  },
  {
    value: 666,
    name: 'Saint Pierre and Miquelon'
  },
  {
    value: 670,
    name: 'Saint Vincent and the Grenadines'
  },
  {
    value: 882,
    name: 'Samoa'
  },
  {
    value: 674,
    name: 'San Marino'
  },
  {
    value: 678,
    name: 'São Tomé and Príncipe'
  },
  {
    value: 682,
    name: 'Saudi Arabia'
  },
  {
    value: 686,
    name: 'Senegal'
  },
  {
    value: 688,
    name: 'Serbia'
  },
  {
    value: 690,
    name: 'Seychelles'
  },
  {
    value: 694,
    name: 'Sierra Leone'
  },
  {
    value: 702,
    name: 'Singapore'
  },
  {
    value: 534,
    name: 'Sint Maarten'
  },
  {
    value: 703,
    name: 'Slovakia'
  },
  {
    value: 705,
    name: 'Slovenia'
  },
  {
    value: 90,
    name: 'Solomon Islands'
  },
  {
    value: 706,
    name: 'Somalia'
  },
  {
    value: 710,
    name: 'South Africa'
  },
  {
    value: 239,
    name: 'South Georgia'
  },
  {
    value: 410,
    name: 'South Korea'
  },
  {
    value: 728,
    name: 'South Sudan'
  },
  {
    value: 724,
    name: 'Spain'
  },
  {
    value: 144,
    name: 'Sri Lanka'
  },
  {
    value: 729,
    name: 'Sudan'
  },
  {
    value: 740,
    name: 'Suriname'
  },
  {
    value: 744,
    name: 'Svalbard and Jan Mayen'
  },
  {
    value: 752,
    name: 'Sweden'
  },
  {
    value: 756,
    name: 'Switzerland'
  },
  {
    value: 760,
    name: 'Syria'
  },
  {
    value: 158,
    name: 'Taiwan'
  },
  {
    value: 762,
    name: 'Tajikistan'
  },
  {
    value: 834,
    name: 'Tanzania'
  },
  {
    value: 764,
    name: 'Thailand'
  },
  {
    value: 626,
    name: 'Timor-Leste'
  },
  {
    value: 768,
    name: 'Togo'
  },
  {
    value: 772,
    name: 'Tokelau'
  },
  {
    value: 776,
    name: 'Tonga'
  },
  {
    value: 780,
    name: 'Trinidad and Tobago'
  },
  {
    value: 788,
    name: 'Tunisia'
  },
  {
    value: 792,
    name: 'Turkey'
  },
  {
    value: 795,
    name: 'Turkmenistan'
  },
  {
    value: 796,
    name: 'Turks and Caicos Islands'
  },
  {
    value: 798,
    name: 'Tuvalu'
  },
  {
    value: 800,
    name: 'Uganda'
  },
  {
    value: 804,
    name: 'Ukraine'
  },
  {
    value: 784,
    name: 'United Arab Emirates'
  },
  {
    value: 826,
    name: 'United Kingdom'
  },
  {
    value: 840,
    name: 'United States'
  },
  {
    value: 581,
    name: 'United States Minor Outlying Islands'
  },
  {
    value: 850,
    name: 'United States Virgin Islands'
  },
  {
    value: 858,
    name: 'Uruguay'
  },
  {
    value: 860,
    name: 'Uzbekistan'
  },
  {
    value: 548,
    name: 'Vanuatu'
  },
  {
    value: 336,
    name: 'Vatican City'
  },
  {
    value: 862,
    name: 'Venezuela'
  },
  {
    value: 704,
    name: 'Vietnam'
  },
  {
    value: 876,
    name: 'Wallis and Futuna'
  },
  {
    value: 732,
    name: 'Western Sahara'
  },
  {
    value: 887,
    name: 'Yemen'
  },
  {
    value: 894,
    name: 'Zambia'
  },
  {
    value: 716,
    name: 'Zimbabwe'
  }
];
