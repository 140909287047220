import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Publisher, Session } from '@opentok/client';

// #region teacher stream

// the name we give to the stream that the teacher publishes. if the teacher is publishing to
// lecture, the stream name will be TeacherStreamLecture. if they are publishing to a group,
// TeacherStreamGroup will be the name
export enum TeacherStreamNameTy {
  TeacherStreamLecture = 'TeacherStreamLecture',
  TeacherStreamGroup = 'TeacherStreamGroup',
}

// these are the IDs we give to the divs which will contain the teachers video. note that
// we have different divs for camera/screen, and different divs for group chat. that is very
// important -- these divs must ALWAYS exist on the page. otherwise when teacher switches between
// group chat and lecture modes, vonage can throw errors if these divs are not available
export enum VideoDivId {
  LectureScreenContainer = 'LectureScreenContainer',
  LectureCameraContainer = 'LectureCameraContainer',
  GroupCameraContainer = 'GroupCameraContainer',
}

// #endregion

export type VonageProviderProps = {
  children: ReactNode;
  setmodalviewContents: any;
  setmodalviewState: any;
};

// hold previous session info, required for comparison while connecting to new session
export type PreviousRoomLayoutDataType = {
  roomView: number;
  studentInGroup: string | null;
}

// audio/ video devices
type AVDeviceType = {
  // responsible for asking camera and microphone permissions on page load.
  demoPublish: () => void;
  // state to track whether access of mic/camer has been granted or not
  accessGranted: boolean;
  // track if Vonage has been initialized for the first time after permission granted.
  vonageFirstInitialized: boolean;
}

// Type for managing Vonage sessions, connections, and disconnections.
type VonageSessions = {
  // responsible to intialize vonage session.
  initializeSession: (_arg1: string) => any;
  // responsible to connect vonage session after successful initialization.
  connectToSession: (_arg1: string) => void;
  // hold Current Vonage session.
  vonageSession: Session | undefined;
  // upon successful connection, this is our flag which is giving us go ahead to
  // publish or subscribe to stream.
  sessionConnected: boolean;
  // this will disconnect from vonage session and reset the state to default state.
  disconnectFromSession: () => void;
  // Unsubscribes from stream.
  unSubscribeToStream: (_arg1: string) => void;
  isMediaStopped: boolean;
  setIsMediaStopped: Dispatch<SetStateAction<boolean>>;
  temporarilyMutedStudents: string[];
  setTemporarilyMutedStudents: Dispatch<SetStateAction<string[]>>

}

// Type for handling student streams.
type StudentStreams = {
  // Publishes student's audio stream.
  publishAudioStream: (_arg1: string, _arg2: any) => void;
  // Subscribes to the available stream.
  subscribeToStream: () => void;
  // state for our publisher in the begining it's undefined
  audioPublisher: Publisher | undefined;
}

type TeacherScreenSharingStream = {
  // Tracks teacher screensharing status.
  isTeacherScreensharing: boolean,
}

// Type for mute/unmute microphone state.
type ToggleMicState = {
  // responsible to mute publisher microphone
  muteMicrophone: () => void;
  // responsible to unmute publisher microphone
  unMuteMicrophone: () => void;
}

// Type for switching audio devices.
type SwitchAudioDevice = {
  // Switches to new audio device.
  switchToNewAudioDevice: (_arg1: string | null) => void;
}

type PreviousVonageRoomInfo = {
  // hold previous session info, required for comparison while connecting to new session
  previousRoomLayoutData?: PreviousRoomLayoutDataType;
  setPreviousRoomLayoutData: Dispatch<SetStateAction<PreviousRoomLayoutDataType | undefined>>;
}

// Combine all types here by & sign.
export type VonageProviderInitialStateType = AVDeviceType & VonageSessions
  & TeacherScreenSharingStream & StudentStreams & ToggleMicState
  & SwitchAudioDevice & PreviousVonageRoomInfo;
