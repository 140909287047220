import { graphql } from 'babel-plugin-relay/macro';

// RelayAppSettings is a client-only schema node. Here we are getting the backbutton value
export const BackbuttonDesktopDataQuery = graphql`
  query BackbuttonDesktopDataQuery {
    RelayAppSettings {
      ...BackbuttonDesktopDataFragment
    }
  }
`;

export const BackbuttonDesktopDataFragment = graphql`
  fragment BackbuttonDesktopDataFragment on appsetting {
    backbutton
  }
`;
