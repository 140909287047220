import React, {
  useEffect, useLayoutEffect, useState
} from 'react';
import {
  Button, Divider, Select, SelectChangeEvent, Ty, Grid2, Grid2Ct, Ic,
} from '@languageconvo/wcl';
import { useClientQuery, useFragment } from 'react-relay';
import { useVonage } from '../../../hooks/useVonage';
import { MicDetailsFragment$key, MicDetailsFragment$data } from '../../../../../../common/relay/queries/__generated__/MicDetailsFragment.graphql';
import { MicDetailsFragment, MicDetailsQuery } from '../../../../../../common/relay/queries/MicDetails';
import { getAvailableMics } from '../../../context/VonageProviderHelper';
import { setAllAvailableMicsInRelayStore, setCurrentMicInRelayStore } from '../../../../../../common/relay/clientschema/relayappsettings/microphoneFunctionality';
import { UpdateMicIDInLocalStorage } from '../../../../../../common/utils/microphone/localStore/toggleMicInLCStorage';

// Component for displaying a dialog to select microphone and toggle microphone mute/unmute state.
export const MicModal = (props: any) => {
  // #region general setup, react state variables, etc

  // reading mic details from relay
  const response: any = useClientQuery(MicDetailsQuery, {});
  const fragmentR: MicDetailsFragment$key = response.RelayAppSettings;
  const data: MicDetailsFragment$data = useFragment(MicDetailsFragment, fragmentR);
  const { microphone } = data;

  // set to true once the modal is open, so that we can know to get user devices
  const { isOpen, handleModalCloseMic } = props;

  // state for audio device sources
  const [audioSources, setAudioSources] = useState<Array<{ name: string; value: string }>>([]);

  // state for mic id from relay store
  const [selectedMic, setSelectedMic] = useState<string | null>(
    microphone.current.micId || null
  );

  // Destructuring the functions from useVonage hook
  const {
    muteMicrophone, unMuteMicrophone, switchToNewAudioDevice,
  } = useVonage();

  // #endregion

  // #region functionalities

  //  change selected microphone in dropdown(UI) 
  const changeMicroPhone = (event: SelectChangeEvent) => {
    setSelectedMic(event.target.value as string);
  };

  // `saveSelectedMic` update the  relay store & grouplesson object with new 
  // audio device selected by student.
  const saveSelectedMic = () => {
    // getting selected mic info from relay store to update currentMic in relay store.
    // note that the user ight not have any mics (microphone.all will be an empty array)
    const selectedMicDetails = microphone.all.edges.filter((mic) => mic?.id === selectedMic);

    /* Usually, the mic the user selected in the dropdown will still exist in the relay store.
        In that case, selectedMicDetails.length is true. But in some rare scenarios, the
        mic the user has selected in the dropdown won't actually exist in the relay store
        anymore (for example, they are publishing with that mic, but then it becomes
        unplugged). That rare case is the else statement below
    */
    if (selectedMicDetails[0]) {
      // switching to the selected microphone(coming from relay store.
      //  as we are reading all from relay store and showing in dropdown) 
      switchToNewAudioDevice(selectedMicDetails[0].id);
      // update relay store and local storage current with selected mic id and name
      setCurrentMicInRelayStore(selectedMicDetails[0].id, selectedMicDetails[0].title);
      UpdateMicIDInLocalStorage(selectedMicDetails[0].id);
    } else {
      // TODO: future, after group lessons v1 -- add a notif within the modal that the device
      //  the user has selected is no longer available. For now, we are doing nothing; the
      //  select options will automatically change IF the mic that was unplugged is the mic
      //  the user is publishing but they will NOT change if the mic unplugged is any other
    }
    handleModalCloseMic();
  };

  // Function to handle microphone mute/unmute state based on user action.
  const toggleMicState = () => {
    if (microphone.current.isMuted) {
      unMuteMicrophone();
    } else {
      muteMicrophone();
    }
  };

  // #endregion

  // #region get audio devices

  // here we are reading list of all microphones from the relay store
  // and transforming it into a name-value pair format,
  // because the select dropdown component expects options in this format.
  const fetchAudioSourcesAndDisplay = () => {
    const audioOptions = microphone.all.edges.map((device: any) => ({
      name: device.title,
      value: device.id,
    }));

    // after transforming the data into the required format,
    // we update the state to populate the dropdown options.
    setAudioSources(audioOptions);
  };

  // whenever the relay store mic values update, this will run. its goal is to
  // then update the dropdown values in the select
  useEffect(() => {
    fetchAudioSourcesAndDisplay();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microphone]);

  // get all the audio devices the user has plugged in. update the relay store custom value that
  // holds this list, and then update our dropdown to display that data
  const getCurrentAttachedDevices = async () => {
    await getAvailableMics().then((devices: any) => {
      // here we storing newly plugged in audio devices in relay store
      setAllAvailableMicsInRelayStore(devices);
    }).catch(() => {
      // for some reason, we failed to get audio devices. update the relay store
      setAllAvailableMicsInRelayStore([]);
    });
  };

  // any time the user opens the modal, we get all the user's microphones to ensure that
  // our list of mics in the dropdown is updated/correct
  useLayoutEffect(() => {
    if (isOpen) {
      getCurrentAttachedDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // #endregion 

  return (
    <Grid2Ct sx={{ mt: 2 }}>
      <Grid2 xs={12}>
        {/* show a warning that no mics exist */}
        {audioSources.length === 0 ? <NoMicsExist /> : <div />}

        {/* select a microphone, save setting */}
        <Select label="Select a Microphone" value={selectedMic!} onChange={changeMicroPhone} options={audioSources} />

        <Button color="accentPurple1" disabled={audioSources.length === 0} fullWidth onClick={saveSelectedMic} cp={{ sx: { mt: 1 } }}>Save</Button>

        {/* divider */}
        <Divider cp={{ sx: { mt: 4, mb: 4 } }} />

        {/* text to be clear to the user whether their mic is on or off */}
        <Ty>
          {microphone.current.isMuted ? <MicOffText /> : <MicOnText />}
        </Ty>

        {/* button to turn mic on or off */}
        {microphone.current.isMuted
          ? <MicOffButton onClick={toggleMicState} />
          : <MicOnButton onClick={toggleMicState} />}
      </Grid2>
    </Grid2Ct>
  );
};

// #region sub components

const MicOffText = () => (
  <span>
    <Ic iconName="triangle-exclamation" color="accentRed1" iconStyle="duotone" />&nbsp;
    <strong>Your microphone is off!</strong> In group chat, other students will&nbsp;
    <strong>not</strong> be able to hear you. Click the button below to turn it back on.
  </span>
);

const MicOffButton: any = (props: any) => (
  <Button color="accentPurple1" fullWidth onClick={props.onClick} cp={{ sx: { mt: 1, mb: 2 } }}>Turn Mic&nbsp;<strong>On</strong></Button>
);

const MicOnText = () => (
  <span>
    Your microphone is&nbsp;
    <strong>on</strong>. In group chat, other students should be able to hear you.
  </span>
);

const MicOnButton: any = (props: any) => (
  <Button color="accentPurple1" fullWidth onClick={props.onClick} cp={{ sx: { mt: 1, mb: 2 } }}>Turn Mic&nbsp;<strong>Off</strong></Button>
);

const NoMicsExist = () => (
  <Grid2Ct sx={{ mb: 2 }}>
    <Grid2 xs={12}>
      <Ty>
        <Ic iconName="triangle-exclamation" color="accentRed1" iconStyle="duotone" />&nbsp;
        <strong>We could not find any microphones!</strong> Check to make sure you have a microphone
        plugged in to your device. If you do, try refreshing the page.
      </Ty>
    </Grid2>
  </Grid2Ct>
);

// #endregion
