import { isJsonString } from '../../../utils/isJSONString';

/* capturing logout event from legacy app 
    logging user out from ifram if he/she is not login in legacy app (inside iframe)
*/
export const handleLogoutEventFromIframe = ({ handleLogout, setLogoutInProgress }: any) => {
  let data: any;
  window.addEventListener('message', (e: any) => {
    if (typeof e?.data === 'string' && isJsonString(e?.data)) {
      data = JSON.parse(e.data);
      if (data.eventName === 'logout') {
        handleLogout(setLogoutInProgress);
      }
    }
  });
};
