import { graphql } from 'babel-plugin-relay/macro';

export const TeacherProfileQuery = graphql`
  query TeacherProfileQuery($tchId: Int) {
    ...TeacherProfileFragment @arguments(tchId: $tchId)
    ...GetStdSettingsDataFragment
  }  
`;

export const TeacherProfileFragment = graphql`
  fragment TeacherProfileFragment on query_root @argumentDefinitions(
    tchId: {type: "Int"},     
  ) {
    resources_connection(where: {pk: {_eq: $tchId}, working_status: {_is_null: true}, tutor_visibility: {_eq: "1"}}) {
      edges {
        node {
          id
          gets_new_students
          wants_new_students
          provides_free_trial
          pk
          uuid
          title
          stuprof_aboutme
          stuprof_city
          relayFavorited
          user_favteachers {
            favorite
          }
          stuprof_country {
            common_name
          }
          instructor_languages {
            language_id
          }
          resources_files_profileimgs(limit: 10, order_by: {sort_order: asc}, where: {image_approved: {_eq: "1"}, archived: {_eq: "0"}, sort_order: {_gt: -1}}) {
            fuploads {
              file_path
              file_cdn {
                base_url
              }
            }
          }
        }
      }
    }
  }
`;
