import { graphql } from 'babel-plugin-relay/macro';

export const StuSubsCreateportalurl = graphql`
mutation StuSubsCreateportalurlMutation {
  stu_subs_createportalurl {
    portalUrl
    errors
    success
  }
}
`;
