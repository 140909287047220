import React from 'react';
import {
  Grid2Ct, Grid2, Ty, Box,
} from '@languageconvo/wcl';
import { AlmostFullInnerHeightMostPages } from '../../../common/components/containers/AlmostFullInnerHeightMostPages';

// when the user clicks the "checkout!" button, this will show. then when the pay route loads
// this shows again, so that the loading experience seems uninterrupted for the user
export const PayPageLoading = () => (
  <AlmostFullInnerHeightMostPages theMinHeight={350}>
    <Grid2Ct sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <Grid2 xs={12}>
        <Grid2Ct>
          {/* lottie */}
          <Grid2 xs={12}>
            <Box display="flex" justifyContent="center">
              {/* TODO: env var */}
              <img src="https://img.farii.com/wcl/svg/lion-sitting.svg" height={200} width={200} alt="icon" />
            </Box>
          </Grid2>

          {/* text */}
          <Grid2 xs={10} xsOffset={1}>
            <Ty v="h2New" align="center" cp={{ sx: { mt: 2 } }} removeMb>One moment, setting up your payment...</Ty>
          </Grid2>
        </Grid2Ct>
      </Grid2>
    </Grid2Ct>
  </AlmostFullInnerHeightMostPages>
);
