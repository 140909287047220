import React from 'react';
import {
  typography, colors, borders, spacing, components,
} from './index';
import { ThemeType, ThemeContextProps, ThemeProviderProps } from './interface';

// should not export it (theme) directly, otherwise, it will export both light and dark theme values
// present in color and other objects, we always try to use this theme from our provider so we get
// appropriate dark and light mode values...
const theme = {
  typography,
  colors,
  // shadows,
  borders,
  spacing: spacing.spacingArray,
  padding: spacing.padding,
  components,
};

// The context which holds the state for our theme and expose it globally.
export const ThemeContext = React.createContext<ThemeContextProps>({
  themeType: 'light',
  theme: {
    ...theme,
    colors: { ...colors.light },
    // shadows: { ...shadows.light },
  },
  setCurrentTheme: null,
});

// Our theme provider component:
// This component should wrap any other components we want to use `useTheme` hook in.
export function ThemeProvider({ children, themeMode = 'light' }: ThemeProviderProps) {
  const [currentTheme, setCurrentTheme] = React.useState<ThemeType>(themeMode);

  const themeProviderValue = React.useMemo(() => {
    return {
      themeType: themeMode,
      theme: {
        ...theme,
        colors: themeMode === 'light' ? { ...theme.colors.light } : { ...theme.colors.dark },
        // shadows: themeMode === 'light' ? { ...theme.shadows.light } : { ...theme.shadows.dark },
      },
      setCurrentTheme,
    };
  }, [currentTheme, theme, setCurrentTheme, themeMode]);

  return (
    <ThemeContext.Provider value={themeProviderValue}>
      { children }
    </ThemeContext.Provider>
  );
}

// The hook which expose the theme object and type to other components.
export const useCustomTheme = () => React.useContext(ThemeContext);
