import axios, {
  AxiosHeaders,
  AxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios';

export class AxiosWrapper {
  // default timeout of api calls, in milliseconds
  #privateTimeoutDefault = 5000;

  // #region createAxiosInstance

  /**
   * create and returns instance of "axios" class, we use this instance to make API requests
   *
   * @param baseURL (required) base url to which API call is made,
   *  for example api.languageconvo.com/v1
   * @param withCredentials (optional) boolean true or false
   * @param headers (optional) an object of API call headers, this will contain request
   *  Content-Type, Authorization header or any other headers required by this API call.
   *  Default value is {'Content-Type': 'application/json'}
   * @param timeout (optional) API call timeout in miliseconds, if this parameter is not passed
   *  default timeout is set to 5000 which is 5 seconds
   */
  // eslint-disable-next-line class-methods-use-this
  private createAxiosInstance(
    baseURL: string | undefined,
    withCredentials?: boolean | undefined,
    headers?: RawAxiosRequestHeaders | AxiosHeaders,
    timeout?: number,
    inputParams?: Record<any, any>
  ) {
    if (!baseURL) {
      throw new Error('Required field baseURL is missing when creating API request');
    }

    let params = null;
    // according to axios doc params that are null or undefined are not sent as part of url, this
    // means that if we send params as empty or null they won't be sent as part of url query string
    if (inputParams) {
      params = inputParams;
    }

    // timeout, either the default or what was passed in function params
    let theTimeout = this.#privateTimeoutDefault;
    if (timeout) {
      theTimeout = timeout;
    }

    // create an instance of Axios
    return axios.create({
      baseURL,
      withCredentials: withCredentials || false,
      headers: headers || { 'Content-Type': 'application/json' },
      // timeout handles *response* related timeouts
      timeout: theTimeout,
      // signal handles *connection* related timeouts (e.g. network connection becoming unavailable)
      // note that previously we used AbortSignal.timeout here, as noted in the axios 
      // docs: https://axios-http.com/docs/cancellation  but got lots of errors in sentry, seemingly
      // from browsers not supporting that feature yet
      signal: newAbortSignal(theTimeout),
      params,
    });
  }

  // #endregion

  // #region postApiRequest

  /**
   * makes a post API call using Axios service. Axios is a Javascript library used to make HTTP
   * requests from node js or XMLHttpRequests from the browser
   *
   * @param apiCallMethod api call method which is part of API call after the base url for
   *  example api.languageconvo.com/v1/{method}, this /{method} is what needs to be passed in
   *  apiCallMethod parameter
   * @param axiosRequestConfig an interface of AxiosRequestConfig, this is used to define API call
   *  configurations. Following data can be passed to using axiosRequestConfig:
   *  baseURL (required) base url to which API call is made, for example api.languageconvo.com/v1
   *  withCredentials (optional) boolean true or false
   *  headers (optional) API request headers, default is {'Content-Type': 'application/json'}
   *  timeout (optional) API call timeout in mili-seconds, default is 5000 mili-seconds or 5 seconds
   * @param params (optional) an object of input parameters to the API call. These are parameters
   *  which let us choose specific data we send to this Post API request. This is what gets sent as
   *  API request body or input data
   */
  async postApiRequest(
    apiCallMethod: string,
    axiosRequestConfig: AxiosRequestConfig,
    params?: Record<any, any> | string,
  ) {
    // since parameters are optional, in case parameters are not pass we send null as input parms
    // to our API request. This is to avoid issue of sending input params as undefined when making
    // post request
    let inputParams = null;
    if (params) {
      inputParams = params;
    }

    // create an instance of "axios" class, this instance is what is used to make POST API requests
    const axiosInstance = this.createAxiosInstance(
      axiosRequestConfig.baseURL,
      axiosRequestConfig?.withCredentials,
      axiosRequestConfig?.headers,
      axiosRequestConfig?.timeout,
    );

    // get data and status node returned by Axios post method, because on success axios object
    // should always return those along with headers, config and statusText. But we are only
    // interested in data and status of successful response
    const { data, status } = await axiosInstance.post(
      apiCallMethod,
      inputParams,
    );

    // Note: we don't catch any failures or errors here, because we want to catch API failures in
    // calling code so each API failure is handled according to specific situation

    return { data, status };
  }

  // #endregion

  // #region getApiRequest

  /**
   * makes a GET API call using Axios service. Axios is a Javascript library used to make HTTP
   * requests from node js or XMLHttpRequests from the browser
   *
   * @param apiCallMethod api call method which is part of API call after the base url for
   *  example api.languageconvo.com/v1/{method}, this /{method} is what needs to be passed in
   *  apiCallMethod parameter
   * @param axiosRequestConfig an interface of AxiosRequestConfig, this is used to define API call
   *  configurations. Following data can be passed to using axiosRequestConfig:
   *  baseURL (required) base url to which API call is made, for example api.languageconvo.com/v1
   *  withCredentials (optional) boolean true or false
   *  headers (optional) API request headers, default is {'Content-Type': 'application/json'}
   *  timeout (optional) API call timeout in mili-seconds, default is 5000 mili-seconds or 5 seconds
   * @param params (optional) an object of input parameters to the API call. These are parameters
   *  which let us choose specific data we send to this Post API request. This is what gets sent as
   *  API request query string
   */
  async getApiRequest(
    apiCallMethod: string,
    axiosRequestConfig: AxiosRequestConfig,
    params?: Record<any, any>,
  ) {
    // create an instance of "axios" class, this instance is what is used to make GET API requests
    const axiosInstance = this.createAxiosInstance(
      axiosRequestConfig.baseURL,
      axiosRequestConfig?.withCredentials,
      axiosRequestConfig?.headers,
      axiosRequestConfig?.timeout,
      params,
    );
    // get data and status node returned by Axios post method, because on success axios object
    // should always return those along with headers, config and statusText. But we are only
    // interested in data and status of successful response
    const { data, status } = await axiosInstance.get(
      apiCallMethod,
    );

    // Note: we don't catch any failures or errors here, because we want to catch API failures in
    // calling code so each API failure is handled according to specific situation

    return { data, status };
  }

  // #endregion
}

// helper for setting *connection* related timeouts
function newAbortSignal(timeoutMs: number) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs);
  return abortController.signal;
}

// errors that 
export enum AxiosErrors {
  // Invalid or unsupported value provided in axios configuration.
  ERR_BAD_OPTION_VALUE = 'ERR_BAD_OPTION_VALUE',
  // Invalid option provided in axios configuration.
  ERR_BAD_OPTION = 'ERR_BAD_OPTION',
  // Request timed out due to exceeding timeout specified in axios configuration.
  ECONNABORTED = 'ECONNABORTED',
  // Request timed out due to exceeding default axios timelimit.
  ETIMEDOUT = 'ETIMEDOUT',
  // Network-related issue.
  ERR_NETWORK = 'ERR_NETWORK',
  // Request is redirected too many times; exceeds max redirects specified in axios configuration.
  ERR_FR_TOO_MANY_REDIRECTS = 'ERR_FR_TOO_MANY_REDIRECTS',
  // Deprecated feature or method used in axios.
  ERR_DEPRECATED = 'ERR_DEPRECATED',
  // Response cannot be parsed properly or is in an unexpected format.
  ERR_BAD_RESPONSE = 'ERR_BAD_RESPONSE',
  // Requested has unexpected format or missing required parameters.
  ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
  // Feature or method is canceled explicitly by the user.
  // NOTE: this occurs if a user connection related timeout occurs (e.g. their network connection
  // is very slow and causes a timeout)
  ERR_CANCELED = 'ERR_CANCELED',
  // Feature or method not supported in the current axios environment.
  ERR_NOT_SUPPORT = 'ERR_NOT_SUPPORT',
  // Invalid URL provided for axios request.
  ERR_INVALID_URL = 'ERR_INVALID_URL',
}

// here we build a list of common, expected errors that can usually be ignored when making
// api calls. think of things like a user's internet connection being down -- we don't need
// to log to sentry when that occurs
export const AxiosErrorsExpected = [
  AxiosErrors.ECONNABORTED,
  AxiosErrors.ETIMEDOUT,
  AxiosErrors.ERR_NETWORK,
  AxiosErrors.ERR_CANCELED,
];
