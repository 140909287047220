import { graphql } from 'babel-plugin-relay/macro';

export const NavbarDataLanglearnQuery = graphql`
  query NavbarDataLanglearnQuery {
    users_connection {
      edges {
        node {
          ...NavbarDataLanglearnFragment
        }
      }
    }
  }
`;

export const NavbarDataLanglearnFragment = graphql`
  fragment NavbarDataLanglearnFragment on users {
    lang_learning
  }
`;
