/**
 * @generated SignedSource<<770ad82e041338ea72ccd45e2b9e4512>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetUserPreferencesFragment$data = {
  readonly aic_level: number;
  readonly aic_setsettings: any | null;
  readonly aic_translate: any;
  readonly " $fragmentType": "GetUserPreferencesFragment";
};
export type GetUserPreferencesFragment$key = {
  readonly " $data"?: GetUserPreferencesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetUserPreferencesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetUserPreferencesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aic_level",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aic_setsettings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aic_translate",
      "storageKey": null
    }
  ],
  "type": "user_settings",
  "abstractKey": null
};

(node as any).hash = "1184c50904c50ab4d07b34d78916f1de";

export default node;
