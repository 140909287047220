/* Errors that can occur during vonage tasks. See
    https://tokbox.com/developer/sdks/js/reference/Error.html#Methods for a detailed list
*/

// errors that can occur during session.connect
export enum ErrorsConnectToSession {
  // The API key does not match the token or session.
  OT_AUTHENTICATION_ERROR = 'OT_AUTHENTICATION_ERROR',
  // The JSON response from the OpenTok server was badly formed.
  OT_BADLY_FORMED_RESPONSE = 'OT_BADLY_FORMED_RESPONSE',
  // Failed to connect to session.
  OT_CONNECT_FAILED = 'OT_CONNECT_FAILED',
  // The client tried to connect to a session that has exceeded the limit for
  // simultaneous connections.
  OT_CONNECTION_LIMIT_EXCEEDED = 'OT_CONNECTION_LIMIT_EXCEEDED',
  // Received an unexpected empty response from the OpenTok server.
  OT_EMPTY_RESPONSE_BODY = 'OT_EMPTY_RESPONSE_BODY',
  // The session ID was invalid.
  OT_INVALID_SESSION_ID = 'OT_INVALID_SESSION_ID',
  // One or more parameters was not valid or not provided.
  OT_INVALID_PARAMETER = 'OT_INVALID_PARAMETER',
  // Couldn\'t perform action because you are not connected to the network.
  OT_NOT_CONNECTED = 'OT_NOT_CONNECTED',
  // Couldn\'t connect due to a terms of service violation.
  OT_TERMS_OF_SERVICE_FAILURE = 'OT_TERMS_OF_SERVICE_FAILURE',
  // Received an unexpected HTTP status.
  OT_INVALID_HTTP_STATUS = 'OT_INVALID_HTTP_STATUS',
  // There was a cross domain error or the server responded with invalid JSON.
  OT_XDOMAIN_OR_PARSING_ERROR = 'OT_XDOMAIN_OR_PARSING_ERROR',
  // The encryption secret is invalid.
  OT_INVALID_ENCRYPTION_SECRET = 'OT_INVALID_ENCRYPTION_SECRET',
  // The browser does not support end-to-end encryption.
  OT_UNSUPPORTED_BROWSER = 'OT_UNSUPPORTED_BROWSER',
}

// errors that can occur during initPublisher
export enum ErrorsInitPublisher {
  // The hardware to fulfill the user media request appears to exist but could not
  // be acquired. It might be in use by another application.
  OT_HARDWARE_UNAVAILABLE = 'OT_HARDWARE_UNAVAILABLE',
  // One or more parameters was not valid or not provided.
  OT_INVALID_PARAMETER = 'OT_INVALID_PARAMETER',
  // The ended event on the video element fired.
  OT_MEDIA_ENDED = 'OT_MEDIA_ENDED',
  // The fetching of the stream for the video element has been aborted.
  OT_MEDIA_ERR_ABORTED = 'OT_MEDIA_ERR_ABORTED',
  // A decoding error occurred while trying to play the stream in the video element.
  OT_MEDIA_ERR_DECODE = 'OT_MEDIA_ERR_DECODE',
  // A network error caused the stream to stop being fetched.
  OT_MEDIA_ERR_NETWORK = 'OT_MEDIA_ERR_NETWORK',
  // The stream has been detected to be not suitable for playback.
  OT_MEDIA_ERR_SRC_NOT_SUPPORTED = 'OT_MEDIA_ERR_SRC_NOT_SUPPORTED',
  // Something in the user media request is not supported.
  OT_NOT_SUPPORTED = 'OT_NOT_SUPPORTED',
  // No devices were found to provide the media stream.
  OT_NO_DEVICES_FOUND = 'OT_NO_DEVICES_FOUND',
  // Video and audio were both disabled. You need to enable at least one.
  OT_NO_VALID_CONSTRAINTS = 'OT_NO_VALID_CONSTRAINTS',
  // The proxyUrl has already been set. Setting it again will not have any effect.
  OT_PROXY_URL_ALREADY_SET_ERROR = 'OT_PROXY_URL_ALREADY_SET_ERROR',
  // The requested audio device does not have permissions to be used.
  OT_REQUESTED_DEVICE_PERMISSION_DENIED = 'OT_REQUESTED_DEVICE_PERMISSION_DENIED',
  // Screen sharing is not supported in the browser.
  OT_SCREEN_SHARING_NOT_SUPPORTED = 'OT_SCREEN_SHARING_NOT_SUPPORTED',
  // Screen sharing is not supported.
  OT_UNABLE_TO_CAPTURE_SCREEN = 'OT_UNABLE_TO_CAPTURE_SCREEN',
  // Screen-sharing support in this browser requires an extension, but one has not been registered.
  OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED = 'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED',
  // Screen-sharing support in this browser requires an extension, but the extension is
  // not installed.
  OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED = 'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED',
}

// errors that can occur during session.subscribe
export enum ErrorsSubscribeToStream {
  // Failed to create a connection between this client and the stream
  // publisher or the OpenTok Media Router.
  OT_CREATE_PEER_CONNECTION_FAILED ='OT_CREATE_PEER_CONNECTION_FAILED',
  // Action failed because the client is not connected to the session.
  OT_DISCONNECTED = 'OT_DISCONNECTED',
  // NOTE: tokbox docs have this listed twice! And this one the description is actually
  // "The user attempted to subscribe in an end-to-end encryption enabled session without
  // having specified an encryption secret." so that's probably a problem on their end
  // OT_UNABLE_TO_SUBSCRIBE = 'OT_UNABLE_TO_SUBSCRIBE',
  // One or more parameters was not valid or not provided.
  OT_INVALID_PARAMETER = 'OT_INVALID_PARAMETER',
  // Something went wrong while establishing WebRTC connectivity.
  OT_ICE_WORKFLOW_FAILED = 'OT_ICE_WORKFLOW_FAILED',
  // The fetching of the stream for the video element has been aborted.
  OT_MEDIA_ERR_ABORTED = 'OT_MEDIA_ERR_ABORTED',
  // A decoding error occurred while trying to play the stream in the video element.
  OT_MEDIA_ERR_DECODE = 'OT_MEDIA_ERR_DECODE',
  // A decoding error occurred while trying to play the stream in the video element.
  OT_MEDIA_ERR_NETWORK = 'OT_MEDIA_ERR_NETWORK',
  // The stream has been detected to be not suitable for playback.
  OT_MEDIA_ERR_SRC_NOT_SUPPORTED = 'OT_MEDIA_ERR_SRC_NOT_SUPPORTED',
  // Couldnt perform action because you are not connected to the network.
  OT_NOT_CONNECTED = 'OT_NOT_CONNECTED',
  // The WebRTC connection failed during setDescription.
  OT_SET_REMOTE_DESCRIPTION_FAILED = 'OT_SET_REMOTE_DESCRIPTION_FAILED',
  // The stream was destroyed before it could be subscribed to.
  OT_STREAM_DESTROYED = 'OT_STREAM_DESTROYED',
  // The client tried to subscribe to a stream in a session that has exceeded
  // the limit for simultaneous streams.
  OT_STREAM_LIMIT_EXCEEDED = 'OT_STREAM_LIMIT_EXCEEDED',
  // Couldnt subscribe to the stream because it was not found.
  OT_STREAM_NOT_FOUND = 'OT_STREAM_NOT_FOUND',
  // Couldn't subscribe to stream due to unexpected error.
  OT_UNABLE_TO_SUBSCRIBE = 'OT_UNABLE_TO_SUBSCRIBE',
}

// errors that can occur during session.publish
export enum ErrorsSessionPublish {
  // Chrome fails to get access to the microphone, due to a Chrome error. To work around
  // this issue, the end-user must restart Chrome and reload your site. This is a known
  // issue to be fixed in Chrome.
  // NOTE: supposedly this was fixed way back in chrome v63
  OT_CHROME_MICROPHONE_ACQUISITION_ERROR = 'OT_CHROME_MICROPHONE_ACQUISITION_ERROR',
  // The constraints for getting user media could not be satisfied.
  OT_CONSTRAINTS_NOT_SATISFIED = 'OT_CONSTRAINTS_NOT_SATISFIED',
  // Failed to create a connection between this client and the subscriber or the OpenTok
  // Media Router.
  OT_CREATE_PEER_CONNECTION_FAILED = 'OT_CREATE_PEER_CONNECTION_FAILED',
  // The hardware to fulfill the user media request appears to exist but could not be
  // acquired. It might be in use by another application.
  OT_HARDWARE_UNAVAILABLE = 'OT_HARDWARE_UNAVAILABLE',
  // NOTE: tokbox docs have this listed twice! And this one the description is actually
  // "The user attempted to publish in an end-to-end encryption enabled session
  // without specifying an encryption key." so that's probably a problem on their end
  // OT_STREAM_CREATE_FAILED = 'OT_STREAM_CREATE_FAILED',
  // Something went wrong while establishing WebRTC connectivity.
  OT_ICE_WORKFLOW_FAILED = 'OT_ICE_WORKFLOW_FAILED',
  // Invalid output audio device ID. It must be a valid video output device ID.
  OT_INVALID_AUDIO_OUTPUT_SOURCE = 'OT_INVALID_AUDIO_OUTPUT_SOURCE',
  // One or more parameters was not valid or not provided.
  OT_INVALID_PARAMETER = 'OT_INVALID_PARAMETER',
  // The fetching of the stream for the video element has been aborted.
  OT_MEDIA_ERR_ABORTED = 'OT_MEDIA_ERR_ABORTED',
  // A decoding error occurred while trying to play the stream in the video element.
  OT_MEDIA_ERR_DECODE = 'OT_MEDIA_ERR_DECODE',
  // A network error caused the stream to stop being fetched.
  OT_MEDIA_ERR_NETWORK = 'OT_MEDIA_ERR_NETWORK',
  // The stream has been detected to be not suitable for playback.
  OT_MEDIA_ERR_SRC_NOT_SUPPORTED = 'OT_MEDIA_ERR_SRC_NOT_SUPPORTED',
  // No devices were found to provide the media stream.
  OT_NO_DEVICES_FOUND = 'OT_NO_DEVICES_FOUND',
  // Video and audio were both disabled. You need to enable at least one.
  OT_NO_VALID_CONSTRAINTS = 'OT_NO_VALID_CONSTRAINTS',
  // Couldn't perform action because you are not connected to the network.
  OT_NOT_CONNECTED = 'OT_NOT_CONNECTED',
  // Something in the user media request is not supported.
  OT_NOT_SUPPORTED = 'OT_NOT_SUPPORTED',
  // This token does not allow publishing. The role must be at least publisher to enable
  // this funcitonality.
  OT_PERMISSION_DENIED = 'OT_PERMISSION_DENIED',
  // Screen sharing is not supported in the browser.
  OT_SCREEN_SHARING_NOT_SUPPORTED = 'OT_SCREEN_SHARING_NOT_SUPPORTED',
  // Screen-sharing support in this browser requires an extension, but one has not been registered.
  OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED = 'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED',
  // Screen-sharing support in this browser requires an extension, but the extension is
  // not installed.
  OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED = 'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED',
  // The WebRTC connection failed during setDescription.
  OT_SET_REMOTE_DESCRIPTION_FAILED = 'OT_SET_REMOTE_DESCRIPTION_FAILED',
  // Failed to create the stream in the server model.
  OT_STREAM_CREATE_FAILED = 'OT_STREAM_CREATE_FAILED',
  // Could not publish in a reasonable amount of time.
  OT_TIMEOUT = 'OT_TIMEOUT',
  // The end-user denied permission to access hardware devices, or the end-user denied access
  // to the screen (for a screen-sharing stream).
  OT_USER_MEDIA_ACCESS_DENIED = 'OT_USER_MEDIA_ACCESS_DENIED',
  // Unable to capture media. Unknown error occurred.
  OT_UNABLE_TO_CAPTURE_MEDIA = 'OT_UNABLE_TO_CAPTURE_MEDIA',
  // This indicates an unexpected error from the OpenTok server.
  OT_UNEXPECTED_SERVER_RESPONSE = 'OT_UNEXPECTED_SERVER_RESPONSE',
}
