import React from 'react';
import {
  Grid2, Grid2Ct, Ty, CardStandard, Link, IcSvgList, Box,
} from '@languageconvo/wcl';
import {
  NavLink,
  useNavigate
} from 'react-router-dom';
import { sitedata } from '../../utils/sitedata';
import { getStuSettingsFromLCStorage } from '../../utils/lcStorage';

export const PracticeHome = () => {
  // #region setup data

  // click to go to the easylistening page
  const clickableTypeObj = {
    to: sitedata.url.app.practiceEasylistening,
    component: NavLink,
  };

  // click to go to the practiceWithAITutor page
  const clickableTypeObjForAITutor = {
    to: sitedata.url.app.practiceWithAITutor,
    component: NavLink,
  };

  // #endregion

  // #region click handler for global tour

  const navigate = useNavigate();

  // reading the country_id from localStorage because if the country_id is present 
  // we need to redirect user to that specific country otherwise we need to 
  // redirect user to the continent page

  const handleClickGlobalTour = () => {
    const lcData = getStuSettingsFromLCStorage();
    const { globalTour } = lcData;

    if (globalTour.country_id) {
      // redirecting the user to selected country page
      navigate(`${sitedata.url.app.practiceGlobalTourDt.pathFull}${globalTour.country_id}`);
    } else {
      // redirecting the user to selected country page
      navigate(`${sitedata.url.app.practiceGlobalTourDt.pathFull}0`);
    }
  };

  // #endregion

  return (
    <Grid2Ct>
      <Grid2 xs={12} xl={10} xlOffset={1}>
        <Grid2Ct>
          {/* card - practiceWithAITutor */}
          <Grid2 xs={12} sm={6} sx={{ display: 'flex' }}>
            <CardStandard
              clickableLink1={{ ...clickableTypeObjForAITutor }}
              titleText="Ask Alice, AI Tutor"
              titleIcon={IcSvgList.characters_alice1}
              color="accentGreen1"
              cp={{ sx: { width: '100%' } }}
            >
              <Link
                to={sitedata.url.app.practiceWithAITutor}
                component={NavLink}
                linkStyle="nostyle"
              >
                <Grid2Ct>
                  <Grid2 xs={12}>
                    <Ty>
                      Get all your language learning questions answered 24/7 by Alice, our
                      AI language tutor!
                    </Ty>
                  </Grid2>
                </Grid2Ct>
              </Link>
            </CardStandard>
          </Grid2>

          {/* card - easylistening */}
          <Grid2 xs={12} sm={6} sx={{ display: 'flex' }}>
            <CardStandard
              clickableLink1={{ ...clickableTypeObj }}
              titleText="EasyListening Practice"
              titleIcon={IcSvgList.music1}
              color="accentRed1"
              cp={{ sx: { width: '100%' } }}
            >
              {/* we wrap the entire children/contents in a link so that it's also clickable, making
                it easy for the user to click almost anywhere in the card */}
              <Link
                to={sitedata.url.app.practiceEasylistening}
                component={NavLink}
                linkStyle="nostyle"
              >
                <Grid2Ct>
                  <Grid2 xs={12}>
                    <Ty>Type words, phrases, even entire paragraphs, and listen to how it
                      sounds in the language you&apos;re learning!
                    </Ty>
                  </Grid2>
                </Grid2Ct>
              </Link>
            </CardStandard>
          </Grid2>

          {/* card - radio */}
          <Grid2 xs={12} sm={6} sx={{ display: 'flex' }}>
            <CardStandard
              onClickEntireCard={() => handleClickGlobalTour()}
              titleText="World Radio"
              titleIcon={IcSvgList.earth2}
              color="accentPurple1"
              cp={{ sx: { width: '100%' } }}
              hovercursor="pointer"
            >
              <Box
                onClick={() => handleClickGlobalTour()}
              >
                <Grid2Ct>
                  <Grid2 xs={12}>
                    <Ty>
                      Listen to radio stations around the world (and learn a bit about
                      different countries and cultures)!
                    </Ty>
                  </Grid2>
                </Grid2Ct>
              </Box>
            </CardStandard>
          </Grid2>

          {/* more coming soon */}
          <Grid2 xs={12} sm={6} sx={{ display: 'flex' }}>
            <CardStandard
              titleText="More Coming Soon!"
              titleIcon={IcSvgList.award1}
              titleIconRight={0}
              color="accentGreen1"
              cp={{ sx: { width: '100%' } }}
            >
              <Grid2Ct>
                <Grid2 xs={12}>
                  <Ty>
                    Our #1 priority the next few months is bringing you new, free practice
                    features to use alongside your lessons/classes!
                  </Ty>
                </Grid2>
              </Grid2Ct>
            </CardStandard>
          </Grid2>
        </Grid2Ct>
      </Grid2>
    </Grid2Ct>
  );
};
