import React from 'react';
import { Props } from './interface';
import { ListItemTextStyled } from './index.style';

/**
 * This is a custom ListItemText designed to be used in conjunction with ListItemButtonCustom, for styling
 * interior "lists". Mainly in popovers. This is used heavily in our appbar/navbar.
 */
export const ListItemTextCustom: React.FunctionComponent<Props> = (props) => {
  const {
    children,
    primary,
    sx,
    disableTypography = false,
    size = 'medium',
  } = props;

  return (
    <ListItemTextStyled
      sx={sx}
      primary={primary}
      disableTypography={disableTypography}
      size={size}
    >
      {children}
    </ListItemTextStyled>
  );
};
