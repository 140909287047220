import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Grid2Ct, Grid2, Card, CardContent, Ty, LottiePlayer, Box, Button,
} from '@languageconvo/wcl';
import { AlmostFullInnerHeightMostPages } from '../../../../../common/components/containers/AlmostFullInnerHeightMostPages';
import Lion from '../../../../../common/assets/lottie/lion-sitting.json';
import { sitedata } from '../../../../../utils/sitedata';

// the user's purchase was successful
export const PaymentSuccess = () => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <AlmostFullInnerHeightMostPages minHeight={430}>
        <Card cp={{ sx: { height: '100%' } }}>
          <CardContent cp={{ sx: { height: '100%' } }}>
            {/* display flex vertically centers all the contents */}
            <Grid2Ct sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <Box sx={{ width: '100%' }}>
                <Grid2 xs={12}>
                  <LottiePlayer animationSrc={Lion} animationHeight={200} />
                </Grid2>

                {/* thank you text */}
                <Grid2 xs={12} sm={10} smOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
                  <Ty align="center">
                    Thank you so much!
                  </Ty>
                  <Ty align="center">
                    Your lessons should be available in just a few minutes, likely they are
                    already ready.
                  </Ty>
                </Grid2>

                <Grid2 xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Button
                      color="accentGreen1"
                      to={sitedata.url.app.home}
                      component={NavLink}
                      size="large"
                    >
                      Schedule a Lesson
                    </Button>
                  </Box>
                </Grid2>
              </Box>
            </Grid2Ct>
          </CardContent>
        </Card>
      </AlmostFullInnerHeightMostPages>
    </Grid2>
  </Grid2Ct>
);
