import styled from '@emotion/styled';
import { ListItemButton } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { Props } from './interface';

export const ListItemButtonStyled = styled(ListItemButton)(({ theme, size, color }: WCLThemeProps & Props) => (
  {
    // applies to all states
    position: 'relative',
    borderRadius: theme?.ourTheme.borders.borderRadius.medium,

    ...(size === 'small' && {
      padding: '6px',
      margin: '6px',
      height: '26px',
    }),
    ...(size === 'medium' && {
      padding: '7px',
      margin: '7px',
      height: '32px',
    }),
    ...(size === 'large' && {
      padding: '8px',
      margin: '8px',
      height: '40px',
    }),

    // color primary
    ...(color === 'primary' && {
      // the bg color of a selected or hovered item. we user our standard itemlist bg color
      '&.Mui-selected, &:hover, &.Mui-selected:hover': {
        backgroundColor: theme?.ourTheme.colors.cp.itemlist.hoverbg.primary,
      },
    }),

    // color secondary
    ...(color === 'secondary' && {
      // the bg color of a selected or hovered item. we user our standard itemlist bg color
      '&.Mui-selected, &:hover, &.Mui-selected:hover': {
        backgroundColor: theme?.ourTheme.colors.cp.itemlist.hoverbg.secondary,
      },
    }),
  }
));
