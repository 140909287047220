// here we customize our breakpoints based on what bootstrap does. this gives
// us more customization at small screen sizes.
// we also added a breakpoint, xxl, BUT this is just a very basic implementation
// of breakpoints. it is NOT useable in the grid, for example. we mainly have it
// so that we can set max width of the main page container to xxl, for users
// that have large screens
const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
  },
};

export default breakpoints;

// this is "module agmentation" required by mui when you add a breakpoint, as
// we did with xxl
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true; // adds xxl breakpoint, but only very basic implementation of it
  }
}
