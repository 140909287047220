import React, {
  createContext, useRef, useState, useEffect, useContext,
} from 'react';
import OT, { Publisher, Session, SubscriberProperties } from '@opentok/client';
import * as Sentry from '@sentry/react';
import {
  PreviousRoomLayoutDataType, VonageProviderInitialStateType, VonageProviderProps,
  TeacherStreamNameTy, VideoDivId,
} from './Types';
import { getAvailableMics, validateLocalData } from './VonageProviderHelper';
import { setAllAvailableMicsInRelayStore, setCurrentMicMuteStateInRelayStore } from '../../../../common/relay/clientschema/relayappsettings/microphoneFunctionality';
import { getStuSettingsFromLCStorage, setStuSettingsToLCStorage } from '../../../../utils/lcStorage';
import { micManager } from '../../../../common/utils/microphone/micManager';
import { ClassroomSetupContext } from '../GroupClassroom';
import { GroupLesson, LcTchSettings } from '../../../../utils/lcStorageInterface';
import {
  ErrorsInitPublisher,
  ErrorsConnectToSession,
  ErrorsSessionPublish,
} from '../../../../common/utils/vonage/Errors';

const apiKey = process.env.REACT_APP_VONAGE_GROUP_KEY!;

// we have explain each item type in VonageProviderInitialStateType. please check.
const initialState: VonageProviderInitialStateType = {
  demoPublish: () => { },
  accessGranted: false,
  vonageFirstInitialized: false,
  audioPublisher: undefined,
  initializeSession: () => null,
  connectToSession: () => { },
  vonageSession: undefined,
  sessionConnected: false,
  isTeacherScreensharing: false,
  disconnectFromSession: () => { },
  publishAudioStream: () => { },
  subscribeToStream: () => { },
  unSubscribeToStream: () => { },
  muteMicrophone: () => { },
  unMuteMicrophone: () => { },
  switchToNewAudioDevice: () => { },
  isMediaStopped: false,
  setIsMediaStopped: () => { },
  temporarilyMutedStudents: [],
  setTemporarilyMutedStudents: () => { },
  setPreviousRoomLayoutData: () => { }
};

// #region general, vars

// subscribe settings for teacher camera video
const subscriberOptionsTeacherCameravideo: SubscriberProperties = {
  subscribeToAudio: true,
  subscribeToVideo: true,
  // VERY important: we must show controls. since the user is not publishing a stream, browsers
  // often don't allow autoplay of the audio to occur. vonage handles this by showing the user
  // a control overlay they need to click on
  // showControls: false,
  // this stops the stream name, and a mute button, from being displayed in the controls overlay
  style: { nameDisplayMode: 'off', buttonDisplayMode: 'off' },
  height: '100%',
  width: 'inherit',
  insertMode: 'append',
  // if the teacher has their camera turned off, this shows a default ui black screen
  insertDefaultUI: true,
  // the teacher *should* hopefully have 16:9 camera, but if for some reason they don't then
  // this will "stretch" the video to fit the div
  fitMode: 'cover',
};

// subscribe settings for teacher screensharing
const subscriberOptionsTeacherScreenshare: SubscriberProperties = {
  subscribeToAudio: true,
  subscribeToVideo: true,
  // VERY important: we must show controls. since the user is not publishing a stream, browsers
  // often don't allow autoplay of the audio to occur. vonage handles this by showing the user
  // a control overlay they need to click on
  // showControls: false,
  // this stops the stream name, and a mute button, from being displayed in the controls overlay
  style: { nameDisplayMode: 'off', buttonDisplayMode: 'off' },
  height: '100%',
  width: 'inherit',
  insertMode: 'append',
  // default ui should likely never show in the case the teacher is screen sharing
  // but we allow it if for some reason the video stream isn't working
  insertDefaultUI: true,
  // nearly all monitors are 1080p these days
  preferredResolution: { width: 1920, height: 1080 },
  // for screens, we set contain (rather than cover) so that if the screen isn't
  // 16:9, the entire screen will still be shown. cover would "stretch" the video
  // to cover the entire div, cutting off parts of the screen
  fitMode: 'contain',
};

// defining stream properties for all streams. Later based on teacher or user
// stream, update some properties.
const subscriberAudioOnly: SubscriberProperties = {
  subscribeToAudio: true,
  showControls: false,
  height: '100%',
  width: 'inherit',
};

// #endregion

// creating context which later used in hook and give us provider
const VonageContext = createContext(initialState);

// VonageProvider contains communication api(OpenTok) which is being used
// in the whole group lesson module, no other part of application
// using this, that's why, we created its context so, it can be shared
// in the whole group lesson compoennts without drilling dwon the props.
const VonageProvider = ({
  children,
  setmodalviewContents,
  setmodalviewState,
}: VonageProviderProps) => {
  // #region react state

  // we can perform different operations like, devices permissions granted, enable
  // footer section etc.
  const csSetupContext = useContext(ClassroomSetupContext);
  // state to hold the audio publisher
  const [audioPublisher, setAudioPublisher] = useState<Publisher>();
  // to get the updated state of audioPublisher we are using useRef hook.
  const audioPublisherRef = useRef<Publisher>();
  // to track if Vonage has been initialized for the first time
  const [vonageFirstInitialized, setVonageFirstInitialized] = useState<boolean>(false);
  // to track whether access of mic/camer has been granted or not
  const [accessGranted, setAccessGranted] = useState<boolean>(false);
  //
  const [isMediaStopped, setIsMediaStopped] = useState<boolean>(false);
  // this array will have the id's of the student whom I muted. This is important
  // because the student i muted reloads the page then i don't subscribe to their stream.
  const [temporarilyMutedStudents, setTemporarilyMutedStudents] = useState<string[]>([]);
  // as the streamCreated event listener is a callback so we cannot access the state directly
  // in the callback function we have to access the updated values using ref
  const temporarilyMutedStudentsRef = useRef<string[]>();

  // #endregion

  // #region demoPublish

  /* `demoPublish` is responsible for asking camera and microphone permissions on page load.
   *  If permission granted then we allow student to entre to classroom
   *  https://tokbox.com/developer/sdks/js/reference/OT.html#initPublisher
   */
  const demoPublish = () => {
    const publisher = OT.initPublisher('demo-publish', {
      // to avoid unnecessary default UI that appears when a pubisher creates
      insertDefaultUI: false,
      // don't need to ask users for video permissions but unfortunately, even with this,
      // it seems vonage still does ask for camera perms
      publishVideo: false,
      // publishAudio and publishVideo are default true so we don't need to specify
      // them for permissions.
    }, handleDemoPublishError);

    // trigger, once we get permissions
    publisher.on('accessAllowed', () => {
      setAccessGranted(true);
      setVonageFirstInitialized(true);
      publisher.destroy();

      // after gettting permission, need to get all avaialble devices and set in relay store.
      initializeMicRelayValues();
    });

    // trigger, when access
    publisher.on('accessDenied', () => {
      // based on these flags, we show steps to user like how to grant permissions.
      setAccessGranted(false);
      setVonageFirstInitialized(true);
      publisher.destroy();
    });
  };

  // handles errors during demo publishing. we want to allow the user to continue the lesson
  // even if there's an error, so we set setAccessGranted and setVonageFirstInitialized. note that
  // this means the user in group chat mode may not be able to publish to the other students.
  // some errors we completely ignore, but others we need to notify the user their mic might not
  // be working, and other errors we need to log to sentry as they should not happen
  function handleDemoPublishError(error: any) {
    // for some reason, OT always calls this handleInitPublisherError even if there is not an error.
    // to determine if an error actually occurred, need this if statement
    if (error) {
      try {
        // let the user continue with the class, by assuming they have granted access
        // to their device
        setAccessGranted(true);
        setVonageFirstInitialized(true);
        initializeMicRelayValues();

        // if any of these specific errors occur, we need to notify the user that their mic might
        // not be working correctly. we still let them continue with the lesson though
        if (error.name === ErrorsInitPublisher.OT_HARDWARE_UNAVAILABLE
      || error.name === ErrorsInitPublisher.OT_NOT_SUPPORTED
      || error.name === ErrorsInitPublisher.OT_NO_DEVICES_FOUND
      || error.name === ErrorsInitPublisher.OT_REQUESTED_DEVICE_PERMISSION_DENIED
        ) {
          // display modal notifying user that their mic might not be available
          setmodalviewContents(910);
          setmodalviewState(true);

          // if any of these errors occur, we need to log to sentry, they should never
          // occur and would indicate a problem with our code
        } else if (error.name === ErrorsInitPublisher.OT_INVALID_PARAMETER
      || error.name === ErrorsInitPublisher.OT_NO_VALID_CONSTRAINTS
      || error.name === ErrorsInitPublisher.OT_PROXY_URL_ALREADY_SET_ERROR
      || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_NOT_SUPPORTED
      || error.name === ErrorsInitPublisher.OT_UNABLE_TO_CAPTURE_SCREEN
      || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED
      || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED
        ) {
          Sentry.captureException(
            new Error('unexpected, but known, error in demoPublish'),
            {
              extra: {
                errorCode: error.code,
                errorName: error.name,
                errorMsg: error.message,
              }
            }
          );

          // if any of these errors occur, that's ok, they are expected sometimes (like when user
          // loses internet connection during demoPublish process)
        } else if (error.name === ErrorsInitPublisher.OT_MEDIA_ENDED
      || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_ABORTED
      || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_DECODE
      || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_NETWORK
      || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_SRC_NOT_SUPPORTED
        ) {
          // do nothing

          // else the error was unknown, we should definitely log so we know this is occurring
        } else {
          Sentry.captureException(
            new Error('completely unexpected error in demoPublish'),
            {
              extra: {
                err: error,
              }
            }
          );
        }
      } catch (e) {
      // some error within this function itself. here we do not notify the user bc we may have
      // already notified them above. this should be a very rare occurrence
        Sentry.captureException(
          e,
          {
            extra: {
              note: 'An unexpected error occurred in handleDemoPublishError',
            }
          }
        );
      }

      // log to the console for detailed debugging if a student is having an issue in prod
      // eslint-disable-next-line no-console
      console.log('demoPublish initPublisher error occurred, but we allow lesson to continue:');
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  /** this function will gets called from the demoPublish, the reason for creating this
   * function is that, in the page load when the accessAllowed event triggers we need to set the
   * initial values in the relay store because on page load relay store becomes empty, so we need
   * to re-initialize the relay store.
  */
  const initializeMicRelayValues = () => {
    getAvailableMics().then((devices: any) => {
      // update relay and local store
      micManager(devices);

      // setting the currentMicState in relay store in the start relay store will be empty so
      // we need to get isMute state from storage. Need to set audio device here.
      const localData: LcTchSettings = getStuSettingsFromLCStorage();
      // validating the groupLesson object type via ZOD.
      const validGroupLessonData: GroupLesson = validateLocalData(localData.groupLesson);
      // here we are updating the current mic state in the relay store.
      setCurrentMicMuteStateInRelayStore(validGroupLessonData.isMute);

      // vonage supports says, they do not have any docs related to these error codes.
      // we need to manage this on its own.
      // https://tokbox.com/developer/sdks/js/reference/OT.html#getDevices
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices
      // Possible error that can occure here.
      // 1- old PC that do not have any devices.
      // 2- devices corrupted.
      // 3- malware or antivirus not allowing AV devices.
      // 4- drivers not found.
    }).catch((e: any) => {
      // display modal notifying user that their mic might not be available. log the error
      // error and set empty initial mic in relay store
      setmodalviewContents(910);
      setmodalviewState(true);
      Sentry.captureException(e);
      setAllAvailableMicsInRelayStore([]);

      // after all, make footer ready
    }).finally(() => {
      csSetupContext.setIsFooterReady(true);
    });
  };

  // #endregion

  // #region for initializeSession and connectToSession

  // state to track if the session is connected or not
  // this state is important because we had a check in our vonageSession manager, in this check
  // if the session is connected only then we start publishing or subscribing to the streams
  const [sessionConnected, setSessionConnected] = useState<boolean>(false);

  // state to hold subscribers array
  // we need this array while unsubscribingToStream, in the unsubscribe method we had to pass in the
  // the complete subscriber object that's why it's important to main this array here.
  const [subscribers, setSubscribers] = useState<OT.Subscriber[]>([]);

  // state to hold the Vonage session object
  // after initalizing the session we store the session object in this state, because this session
  // object provide us differnt methods to start publishing or subscribing in the current room
  const [vonageSession, setVonageSession] = useState<Session | undefined>(undefined);

  // in the callbacks/eventListeners we don't get the updated state value so, we have to store the
  // state in a reference to always get the updated data. We're using this ref in 2 places
  // 1 - initializeSession (when session is created we store session in this reference)
  // 2 - during unmounting when we had to turn the session off and disconnect user from session
  const vonageSessionRef = useRef<Session | undefined>(undefined);

  // state to track if the teacher is currently screen sharing
  // this state will tell us either we need to show the screen sharing UI or simple UI
  // we make this state true once we get screen-sharing stream in streamCreated event
  const [isTeacherScreensharing, setIsTeacherScreensharing] = useState<boolean>(false);

  // hold previous room layout info, required for comparison while connecting to new session
  // this state is important because we need to track either there is a change occured in the
  // previous layout value or not, on the basis of this change we have to decide which session
  // user needs to be connected with.
  const [previousRoomLayoutData, setPreviousRoomLayoutData] = useState<
    PreviousRoomLayoutDataType
  >();

  // initializes vonage session if it doesn't already exist, using provided session ID and API key.
  // this is the first step before connecting to the session, we have to initialize the session
  // OT.initSession return us the session object which we will then use to connect to session and
  // for publishing and subscribing in the session.
  const initializeSession = (sessionId: string) => {
    if (vonageSession === undefined) {
      try {
      // OT.initSession not initiate communications with the cloud. It simply initializes
      // the Session object that you can use to connect (and to perform other operations
      // once connected). that's why it does not have any error state.
        const session = OT.initSession(apiKey, sessionId);
        // eslint-disable-next-line no-console
        console.log('OT.initSession successful');
        // It is important to set vonage session in state, once, it is porperly set
        // in the vonageSessionManager component via useEffect hook, we are connecting
        // with this new session.
        setVonageSession(session);
        // we are storing session in ref so we can destory the session on route change
        vonageSessionRef.current = session;
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  };

  // connect to the vonage session using the provided token when `sessionConnected = true`
  // this is the step 2, here we have to connect user to the session using the session object
  // returned initializeSession, this is important because without connecting to session user
  // can neither publish to the session nor subscribe to any stream in the session.
  const connectToSession = (token: string) => {
    if (vonageSession !== undefined) {
      vonageSession.connect(token, (error: any) => {
        if (!error) {
          // eslint-disable-next-line no-console
          console.log('session.connect start');
          setSessionConnected(true);
        } else {
          handleSessionConnectionError(error);
        }
      });

      // this event triggers once current user session successfully connected.
      vonageSession.on('sessionConnected', (event) => {
        // eslint-disable-next-line no-console
        console.log('session.connect sessionConnected event successful');

        // once connected, subscribing to all other available streams of other users.
        // @ts-ignore
        event.target.streams.forEach((stream: any) => {
          // eslint-disable-next-line no-console
          console.log('sessionConnected, subscribing to existing stream of this user.............', stream.name);

          // the work of actually subscribing to the stream and displaying video, if the stream
          // is the teacher's stream
          baseSubscribeToSingleStream(
            stream,
            stream.name,
            stream.hasVideo,
            stream.videoType,
          );
        });
      });
    }
  };

  // handles errors for vonageSession.connect. we'll display a message to the user
  // and potentially log to sentry if it is unexpected
  function handleSessionConnectionError(error: any) {
    try {
      // if any of these occur, notify the user their internet might be down
      if (error.name === ErrorsConnectToSession.OT_CONNECT_FAILED
        || error.name === ErrorsConnectToSession.OT_NOT_CONNECTED
      ) {
        setmodalviewContents(920);
        setmodalviewState(true);

        // if this occurs, notify user they need a different browser
      } else if (error.name === ErrorsConnectToSession.OT_UNSUPPORTED_BROWSER) {
        setmodalviewContents(921);
        setmodalviewState(true);

        // these errors should not occur, and would likely indicate a problem in our
        // code if they do. log to sentry and display general error
      } else if (error.name === ErrorsConnectToSession.OT_AUTHENTICATION_ERROR
        || error.name === ErrorsConnectToSession.OT_BADLY_FORMED_RESPONSE
        || error.name === ErrorsConnectToSession.OT_CONNECTION_LIMIT_EXCEEDED
        || error.name === ErrorsConnectToSession.OT_EMPTY_RESPONSE_BODY
        || error.name === ErrorsConnectToSession.OT_INVALID_SESSION_ID
        || error.name === ErrorsConnectToSession.OT_INVALID_PARAMETER
        || error.name === ErrorsConnectToSession.OT_TERMS_OF_SERVICE_FAILURE
        || error.name === ErrorsConnectToSession.OT_INVALID_HTTP_STATUS
        || error.name === ErrorsConnectToSession.OT_XDOMAIN_OR_PARSING_ERROR
        || error.name === ErrorsConnectToSession.OT_INVALID_ENCRYPTION_SECRET
      ) {
        Sentry.captureException(
          new Error('unexpected, but known, error in vonageSession.connect'),
          {
            extra: {
              errorCode: error.code,
              errorName: error.name,
              errorMsg: error.message,
            }
          }
        );

        // displays a general error
        setmodalviewContents(900);
        setmodalviewState(true);

        // error.name was not known
      } else {
        Sentry.captureException(
          new Error('an unknown error in vonageSession.connect'),
          {
            extra: {
              errorCode: error.code,
              errorName: error.name,
              errorMsg: error.message,
            }
          }
        );

        // displays a general error
        setmodalviewContents(900);
        setmodalviewState(true);
      }

      // an error occurred within this function. should be very rare
    } catch (e) {
      Sentry.captureException(
        e,
        {
          extra: {
            note: 'An unexpected error occurred in handleSessionConnectionError',
          }
        }
      );
    }

    // log to the console for detailed debugging if a student is having an issue in prod
    // eslint-disable-next-line no-console
    console.log('vonageSession.connect error occurred. see handleSessionConnectionError');
    // eslint-disable-next-line no-console
    console.log(error);
  }

  // #endregion

  // #region to disconnect from session (i-e on lesson end and route change)

  // whenever we have to disconnect user from a session, we will call disconnectFromSession
  // this function will do the following things:
  // 1 - destory the audio publisher
  // 2 - disconnect the vonage session
  // 3 - once the session is disconnected, reset all the states to there initial values
  // we called this function in the vonageSessionManager file according to the room layout
  // data changes.
  //  NOTE: this does *not* detect when a user accidentally disconnects from a session when they
  //  lose internet connection. This just purposefully disconnects them when we want to.
  const disconnectFromSession = () => {
    if (sessionConnected) {
      if (audioPublisherRef.current) {
        audioPublisherRef.current.destroy();
      }
      // this fires first and disconnect session.
      vonageSession!.disconnect();
      // eslint-disable-next-line no-console
      console.log('disconnectFromSession started');

      // register an even listener which executes after successful disconnection.
      vonageSession!.on('sessionDisconnected', () => {
        // eslint-disable-next-line no-console
        console.log('sessionDisconnected event detected');

        // set state
        vonageSession!.off();
        setVonageSession(undefined);
        setSessionConnected(false);
        // @ts-ignore
        setAudioPublisher(undefined);
        setIsTeacherScreensharing(false);
        setSubscribers([]);
      });
    }
  };

  // #endregion

  // #region publishAudioStream

  // this will publish student's audio streams during group chat
  // with their uuids so each student's stream can be uniquely identify
  // the student's uuid is spread in the name property of stream.
  // we will call this function, when our sessionConnected state becomes true and
  // roomView value is equals to 2. Because of this function the other student in the group can hear
  // current student who is just connected to the session.
  const publishAudioStream = (uuid: string, micDetails: any) => {
    const { current } = micDetails;
    // waiting for getDevices to be resolved then making our publisher
    // because we need to get devices before publisher is made.
    // each individual user starts publish his audio stream from here.
    // https://tokbox.com/developer/sdks/js/reference/OT.html#initPublisher
    const publisher = OT.initPublisher(uuid, {
      publishVideo: false,
      publishAudio: !current.isMuted,
      insertDefaultUI: false,
      audioSource: current.micId, // setting publisher audio source
      name: uuid,
      audioFilter: {
        type: 'advancedNoiseSuppression',
      },
    }, handleInitPublisherError);
    setAudioPublisher(publisher);
    audioPublisherRef.current = publisher;

    // eslint-disable-next-line no-console
    console.log('OT.initPublisher successful');

    // this event listener trigger when any Publisher has stopped sharing
    // one or all media types (video, audio, or screen). here we are
    // capturing our audio video media
    // this event only fires for current communicating device, if you have 5 other devices and you
    // unplugged other devices while talking to your favourite device, this event not occur.
    publisher.on('mediaStopped', (event: any) => {
      if (event.track && event.track.readyState === 'ended' && event.track.kind === 'audio') {
        // eslint-disable-next-line no-console
        console.log('mediaStopped detected, readyState = ended and kind = audio');

        getAvailableMics().then((currentDevices: any) => {
          // singal to vonageSessionManager indicating time to connect the user's with
          // the current audio device
          setIsMediaStopped(true);

          // if there is at least one mic, set and use it
          if (currentDevices.length) {
            micManager(currentDevices);

            // the user has no audio devices now, so other students cannot hear them. we tell
            // the user of this situation and what they need to do to fix it
          } else {
            micManager([]);
            setmodalviewContents(901);
            setmodalviewState(true);
          }

          // some error occured, so set mics to null. for now, we are not notifying the user
          // we think this will be a very rare occurrence
        }).catch((e: any) => {
          Sentry.captureException(e);
          // TODO: see the comment in UIT VonageProvider -- maybe we should not be calling
          //  this here, and instead should be calling setAllAvailableMicsInRelayStore([])?
          micManager([]);
        });
      }
    });

    // starts stream publish
    if (sessionConnected) {
      vonageSession!.publish(publisher, (err: any) => {
        if (err) {
          handlePublishStreamError(err);
        } else {
          // do nothing, user successfully published
          // eslint-disable-next-line no-console
          console.log('vonageSession.publish successfull');
        }
      });
    }
  };

  // this will execute if any error occurs during OT.initPublisher
  function handleInitPublisherError(error: any) {
    // for some reason, OT always calls this handleInitPublisherError even if there is not an error.
    // to determine if an error actually occurred, need this if statement
    if (error) {
      try {
        // if any of these specific errors occur, we need to notify the user that their mic might
        // not be working correctly
        if (error.name === ErrorsInitPublisher.OT_HARDWARE_UNAVAILABLE
          || error.name === ErrorsInitPublisher.OT_NOT_SUPPORTED
          || error.name === ErrorsInitPublisher.OT_NO_DEVICES_FOUND
          || error.name === ErrorsInitPublisher.OT_REQUESTED_DEVICE_PERMISSION_DENIED
        ) {
          setmodalviewContents(940);
          setmodalviewState(true);

        // if any of these errors occur, we need to log to sentry, they should never occur and would
        // indicate a problem with our code. display general error
        } else if (error.name === ErrorsInitPublisher.OT_INVALID_PARAMETER
          || error.name === ErrorsInitPublisher.OT_NO_VALID_CONSTRAINTS
          || error.name === ErrorsInitPublisher.OT_PROXY_URL_ALREADY_SET_ERROR
          || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_NOT_SUPPORTED
          || error.name === ErrorsInitPublisher.OT_UNABLE_TO_CAPTURE_SCREEN
          || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED
          || error.name === ErrorsInitPublisher.OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED
        ) {
          setmodalviewContents(941);
          setmodalviewState(true);
          Sentry.captureException(
            new Error('unexpected, but known, error in handleInitPublisherError'),
            {
              extra: {
                errorCode: error.code,
                errorName: error.name,
                errorMsg: error.message,
              }
            }
          );

        // if any of these errors occur, that's ok, they are expected sometimes (like when user
        // loses internet connection during publish process). notify them with a general error
        } else if (error.name === ErrorsInitPublisher.OT_MEDIA_ENDED
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_ABORTED
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_DECODE
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_NETWORK
          || error.name === ErrorsInitPublisher.OT_MEDIA_ERR_SRC_NOT_SUPPORTED
        ) {
          setmodalviewContents(941);
          setmodalviewState(true);

        // else the error was unknown, we should definitely log so we know this is occurring and
        // display general error
        } else {
          setmodalviewContents(941);
          setmodalviewState(true);
          Sentry.captureException(
            new Error('completely unexpected error in handleInitPublisherError'),
            {
              extra: {
                err: error,
              }
            }
          );
        }
      } catch (e) {
      // some error within this function itself. here we do not notify the user bc we may have
      // already notified them above. this should be a very rare occurrence
        Sentry.captureException(
          e,
          {
            extra: {
              note: 'An unexpected error occurred in handleInitPublisherError',
            }
          }
        );
      }

      // log to the console for detailed debugging if a student is having an issue in prod
      // eslint-disable-next-line no-console
      console.log('OT.initPublisher error occurred:');
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  // this will execute if any error occured during vonageSession.publish
  function handlePublishStreamError(error: any) {
    try {
      // errors that might mean the user's mic is not available, maybe it's being used
      // by another program or it was unplugged. we'll notify the user
      if (error.name === ErrorsSessionPublish.OT_HARDWARE_UNAVAILABLE
        || error.name === ErrorsSessionPublish.OT_NO_DEVICES_FOUND
        || error.name === ErrorsSessionPublish.OT_USER_MEDIA_ACCESS_DENIED
        || error.name === ErrorsSessionPublish.OT_CHROME_MICROPHONE_ACQUISITION_ERROR
      ) {
        setmodalviewContents(930);
        setmodalviewState(true);

        // errors that might mean the user's internet connection went down. notify them
      } else if (error.name === ErrorsSessionPublish.OT_MEDIA_ERR_NETWORK
        || error.name === ErrorsSessionPublish.OT_NOT_CONNECTED
      ) {
        setmodalviewContents(931);
        setmodalviewState(true);

        // errors that are expected sometimes. we dont log to sentry, but we do show
        // the user a message that something might have gone wrong
      } else if (error.name === ErrorsSessionPublish.OT_CREATE_PEER_CONNECTION_FAILED
        || error.name === ErrorsSessionPublish.OT_ICE_WORKFLOW_FAILED
        || error.name === ErrorsSessionPublish.OT_INVALID_AUDIO_OUTPUT_SOURCE
        || error.name === ErrorsSessionPublish.OT_MEDIA_ERR_ABORTED
        || error.name === ErrorsSessionPublish.OT_TIMEOUT
        || error.name === ErrorsSessionPublish.OT_UNABLE_TO_CAPTURE_MEDIA
      ) {
        setmodalviewContents(932);
        setmodalviewState(true);

        // errors we do not expect and likely indicate a problem with our code. log to sentry
      } else if (error.name === ErrorsSessionPublish.OT_CONSTRAINTS_NOT_SATISFIED
        || error.name === ErrorsSessionPublish.OT_INVALID_PARAMETER
        || error.name === ErrorsSessionPublish.OT_MEDIA_ERR_DECODE
        || error.name === ErrorsSessionPublish.OT_MEDIA_ERR_SRC_NOT_SUPPORTED
        || error.name === ErrorsSessionPublish.OT_NO_VALID_CONSTRAINTS
        || error.name === ErrorsSessionPublish.OT_NOT_SUPPORTED
        || error.name === ErrorsSessionPublish.OT_PERMISSION_DENIED
        || error.name === ErrorsSessionPublish.OT_SCREEN_SHARING_NOT_SUPPORTED
        || error.name === ErrorsSessionPublish.OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED
        || error.name === ErrorsSessionPublish.OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED
        || error.name === ErrorsSessionPublish.OT_SET_REMOTE_DESCRIPTION_FAILED
        || error.name === ErrorsSessionPublish.OT_STREAM_CREATE_FAILED
        || error.name === ErrorsSessionPublish.OT_UNEXPECTED_SERVER_RESPONSE
      ) {
        setmodalviewContents(932);
        setmodalviewState(true);
        Sentry.captureException(
          new Error('unexpected, but known, error in vonageSession.publish'),
          {
            extra: {
              errorCode: error.code,
              errorName: error.name,
              errorMsg: error.message,
            }
          }
        );

        // unknown error.name
      } else {
        setmodalviewContents(932);
        setmodalviewState(true);
        Sentry.captureException(
          new Error('an unknown error in vonageSession.publish'),
          {
            extra: {
              errorCode: error.code,
              errorName: error.name,
              errorMsg: error.message,
            }
          }
        );
      }

      // some error within this function itself. here we do not notify the user bc we may have
      // already notified them above. this should be a very rare occurrence
    } catch (e) {
      Sentry.captureException(
        e,
        {
          extra: {
            note: 'An unexpected error occurred in handlePublishStreamError',
          }
        }
      );
    }

    // log to the console for detailed debugging if a student is having an issue in prod
    // eslint-disable-next-line no-console
    console.log('vonageSession.publish error occurred:');
    // eslint-disable-next-line no-console
    console.log(error);
  }

  // #endregion

  // #region for subscribing to stream

  // TODO: we should likely add custom error handling to vonageSession.subscribe events,
  //  similar to how we handle initPublisher, session.connect and session.publish. currently
  //  we are completely ignoring subscribe errors

  // After session connect, we are subscribing to all available streams.
  // because of this function, the current logged in student can hear the other students
  // who are publishing there audio in this session
  const subscribeToStream = () => {
    if (sessionConnected) {
      if (vonageSession) {
        // this will trigger whenever a student or teacher enters the class after the current
        // user has already connected and subscribed to the streams that were alreadye existing
        vonageSession.on('streamCreated', (event: any) => {
          // eslint-disable-next-line no-console
          console.log('streamCreated event detected. stream.name is: ', event.stream.name);

          // the work of actually subscribing to the stream and displaying video, if the stream
          // is the teacher's stream
          baseSubscribeToSingleStream(
            event.stream,
            event.stream.name,
            event.stream.hasVideo,
            event.stream.videoType,
          );
        });

        // stream destroyed, e.g, screensharing or internet down OR unstable.
        vonageSession.on('streamDestroyed', (event: any) => {
          // eslint-disable-next-line no-console
          console.log('Stream Destroyed.............', event);

          // if a screen stream was destroyed, this means that the teacher stopped their screen
          // sharing. we set a state so that the ui can be updated appropriately
          if (event.stream.videoType === 'screen') {
            setIsTeacherScreensharing(false);
          }

          // removing the subscriber whose stream is destroyed,
          // reason, we always has to maintain the subscribers array,
          // it should only contains the subscriber's object with whom the user is subscribed to.
          const newSubscribersArray = subscribers.filter(
            (subs) => subs.stream?.streamId !== event.stream?.streamId
          );

          // setting the updated array of subscribers after removing the destroyed one.
          setSubscribers(() => newSubscribersArray);
        });
      }
    }
  };

  /** Resuable base function for subscribing to a single stream
   *  This is used on both session connect, and then later in the session when streamCreated events
   *  occur. We handle every possible case of a stream coming in, whether it be a teacher's stream
   *  or a student's stream.
  * */
  const baseSubscribeToSingleStream = (
    stream: any,
    streamName: string,
    streamHasVideo: boolean,
    streamVideoType: any,
  ) => {
    try {
      // if stream hasVideo we subsribe to both audio and video. this could be a camera stream
      // or a screen sharing stream. note that only teachers are allowed to stream video
      if (streamHasVideo) {
        // the video being streamed is of type screen sharing
        if (streamVideoType === 'screen') {
          // eslint-disable-next-line no-console
          console.log('baseSubscribeToSingleStream stream.videoType is screen');

          // only teachers can stream their screen, and they can only do it during lecture
          // here we check to ensure that this stream is the teachers, and log/throw if
          // for some reason it is not
          if (streamName !== TeacherStreamNameTy.TeacherStreamLecture
            && streamName !== TeacherStreamNameTy.TeacherStreamGroup
          ) {
            Sentry.captureException(
              new Error('In baseSubscribeToSingleStream, screen stream detected that was not the teachers!'),
              {
                extra: {
                  theStreamName: streamName,
                }
              }
            );
            throw new Error('In baseSubscribeToSingleStream, screen stream detected that was not the teachers!');
          } else if (streamName !== TeacherStreamNameTy.TeacherStreamLecture) {
            // eslint-disable-next-line no-console
            console.log('In baseSubscribeToSingleStream, screen stream detected that was not teacher lecture!', streamName);
            Sentry.captureException(
              new Error('In baseSubscribeToSingleStream, screen stream detected that was not teacher lecture!'),
              {
                extra: {
                  theStreamName: streamName,
                }
              }
            );
          }

          const subscriber = vonageSession!.subscribe(
            // the stream we're subscribing to
            stream,
            // the html element to put the stream into. teachers cannot screen share during
            // group chat, so this is always simply the lecture screen container
            VideoDivId.LectureScreenContainer,
            // properties/options
            {
              ...subscriberOptionsTeacherScreenshare,
            }
          );
          setIsTeacherScreensharing(true);
          setSubscribers([...subscribers, subscriber]);

          // the video being streamed is from a camera
        } else if (streamVideoType === 'camera') {
          // eslint-disable-next-line no-console
          console.log('baseSubscribeToSingleStream stream.videoType is camera');

          // only teachers can stream their camera, log/throw if for some reason it is not
          // so that, in a crazy situation of a student hacker trying to stream their own
          // video, we never allow it
          if (streamName !== TeacherStreamNameTy.TeacherStreamLecture
            && streamName !== TeacherStreamNameTy.TeacherStreamGroup
          ) {
            Sentry.captureException(
              new Error('In baseSubscribeToSingleStream, camera stream detected that was not the teachers!'),
              {
                extra: {
                  theStreamName: streamName,
                }
              }
            );
            throw new Error(`In baseSubscribeToSingleStream, camera stream detected that was not the teachers! ${streamName}`);
          }

          // determine whether we should insert this camera stream into the lecture component
          // component, or group chat component
          let divIdToInsert = VideoDivId.LectureCameraContainer;
          if (streamName === TeacherStreamNameTy.TeacherStreamGroup) {
            divIdToInsert = VideoDivId.GroupCameraContainer;
          }

          const subscriber = vonageSession!.subscribe(
            // the stream we're subscribing to
            stream,
            // the html element to put the stream into; either lecture or group component
            divIdToInsert,
            // properties/options
            {
              ...subscriberOptionsTeacherCameravideo,
            },
            // TODO: we do not need to log all errors, only specific ones
            // TODO: probably need to show the user a popup
            // TODO: probably need to do this kind of error handling when subscribing to other
            //  student streams too
            // if an error occurs subscribing to teacher stream, we log to sentry
            (error: any) => {
              if (error) {
                Sentry.captureException(
                  new Error('Student failed to subscribe to teachers camera or screenshare stream'),
                  {
                    extra: {
                      e: error,
                      code: error?.code,
                      message: error?.message,
                      name: error?.name,
                    }
                  }
                );
              } else {
                // eslint-disable-next-line no-console
                console.log('successfully subscribed to stream');
              }
            }
          );
          setSubscribers([...subscribers, subscriber]);
        } else {
          Sentry.captureException(
            new Error('BIG PROBLEM: streamHasVideo was not screen or camera! user not subscribed to stream'),
            {
              extra: {
                str: stream,
                strName: streamName,
                strVt: streamVideoType,
              }
            }
          );
        }

        // if stream has no video we subscribe to audio only. this is usually another
        // student's audio stream but it could also be the teacher, if they have their
        // camera turned off
      } else {
        // eslint-disable-next-line no-console
        console.log('baseSubscribeToSingleStream hasVideo is false, subscribing audio only. stream.name is: ', streamName);

        // determine if this stream is the teacher streaming only their audio, meaning they
        // have their camera turned off
        let videoDivToInsert;
        let isTeacherStreamingAudioOnly = false;
        if (streamName === TeacherStreamNameTy.TeacherStreamGroup
          || streamName === TeacherStreamNameTy.TeacherStreamLecture
        ) {
          isTeacherStreamingAudioOnly = true;

          // determine if the teacher is streaming to a group, or to lecture mode. then
          // set the div we will insert the default video ui into based on that
          if (streamName === TeacherStreamNameTy.TeacherStreamGroup) {
            videoDivToInsert = VideoDivId.GroupCameraContainer;
          } else {
            videoDivToInsert = VideoDivId.LectureCameraContainer;
          }

          // eslint-disable-next-line no-console
          console.log('teacher is streaming audio-only, meaning their camera is turned off');
        }

        // determine if this is a student's stream that the current user has muted. if it is,
        // we won't subscribe to their stream
        let isMutedStudent = false;
        if (temporarilyMutedStudentsRef.current
            && temporarilyMutedStudentsRef.current.includes(streamName)
        ) {
          isMutedStudent = true;
        }

        // subscribe if this is not a student the current user muted
        if (!isMutedStudent) {
          const subscriber = vonageSession!.subscribe(
            // the stream we're subscribing to
            stream,

            // the html element to put the stream into. this is only for the case that the
            // teacher has their video turned off; in that situation we'll inser the vonage
            // default ui
            isTeacherStreamingAudioOnly ? videoDivToInsert : undefined,

            // properties/options
            {
              ...subscriberAudioOnly,

              // in the special case of teacher streaming audio only (their camera is turned
              // off) we insert default ui and subscribe to video bc they may turn their
              // camera back on!
              insertDefaultUI: isTeacherStreamingAudioOnly,
              subscribeToVideo: isTeacherStreamingAudioOnly,
            }
          );
          setSubscribers([...subscribers, subscriber]);
        } else {
          // eslint-disable-next-line no-console
          console.log('Did not subscribe to this user, bc they are temporarily muted', streamName);
        }
      }
    } catch (e: any) {
      Sentry.captureException(e);
      // eslint-disable-next-line no-console
      console.log('baseSubscribeToSingleStream code failed.............', e);
    }
  };

  // #endregion

  // #region to unSubscribeToStream streams

  // `unSubscribeToStream` function takes id of the student's stream to whom need to
  // unsubscribe as parameter and unsubsribe that student stream, so the currently logged in
  // student cannot hear the student anymore whom he unsubscribed.
  // we have called this function from 2 places:
  // 1 - student blocking another student
  // 2 - student temorarily muting other student
  const unSubscribeToStream = (id: string) => {
    // this will execute whenever one student mute other.
    // on page refresh this stream, will again subcribed, so only temperary mute.
    subscribers.forEach((subscriber: OT.Subscriber) => {
      if (subscriber.stream?.name === id && vonageSession !== undefined) {
        // removing the subscriber whose stream we want to unsubscribe,
        // reason, we always has to maintain the subscribers array,
        // it should only contains the subscriber's object with whom the user is subscribed to.
        const newSubscribersArray = subscribers.filter(
          (subs) => subs.stream?.streamId !== subscriber.stream?.streamId
        );
        // unsubscribing the subscriber, so user will not hear them anymore
        vonageSession?.unsubscribe(subscriber);
        // setting the updated array of subscribers after removing the destroyed one.
        setSubscribers(() => newSubscribersArray);
        // setting the id of the user in TemporarilyMutedStudents state, so next time when they
        // create their new stream or reload the page, we don't subscribe to their stream.
        setTemporarilyMutedStudents(() => [...temporarilyMutedStudents, id]);
      }
    });
  };

  // this useEffect will run whenever there is a change in the temporarilyMutedStudents,
  // it's important to set this array to temporarilyMutedStudentsRef.current so that we get
  // updated array always.
  // as the streamCreated event listener is a callback so we cannot access the state directly
  // in the callback function we have to access the updated values using ref
  useEffect(() => {
    temporarilyMutedStudentsRef.current = temporarilyMutedStudents;
  }, [temporarilyMutedStudents]);

  // #endregion

  // #region to handle mute/unmute mic

  // function to mute the microphone
  // we stop publishing their audio (if they are currently publishing it), then update
  // our relay store and local storage
  const muteMicrophone = () => {
    try {
      // during group chat the student is publishing audio, but during lecture they
      // are not. during lecture, we just update relay store and local storage
      if (audioPublisher) {
        audioPublisher.publishAudio(false);
        setCurrentMicMuteStateInRelayStore(true);
        updatMicStateInLCStorage(true);
      } else {
        setCurrentMicMuteStateInRelayStore(true);
        updatMicStateInLCStorage(true);
      }

      // for now, we are not notifying the user as we think this will be a very
      // rare occurrence. but if it's frequent we may want to ad a notification
    } catch (e: any) {
      Sentry.captureException(e);
    }
  };

  // function to unmute the microphone
  // we start publishing their audio (if they are in group chat), then update
  // our relay store and local storage
  const unMuteMicrophone = () => {
    try {
      // in group chat, the user will have an audioPublisher object, but in lecture
      // they will not
      if (audioPublisher) {
        audioPublisher.publishAudio(true);
        setCurrentMicMuteStateInRelayStore(false);
        updatMicStateInLCStorage(false);
      } else {
        setCurrentMicMuteStateInRelayStore(false);
        updatMicStateInLCStorage(false);
      }

      // for now, we are not notifying the user as we think this will be a very
      // rare occurrence. but if it's frequent we may want to ad a notification
    } catch (e: any) {
      Sentry.captureException(e);
    }
  };

  /** updates the microphone state in localStorage.
   *  this helps maintain the current microphone state across page reloads.
   *  since the relay store will be empty on a page reload, we retrieve the current
   *  microphone state from localStorage and update the relay store accordingly.
   *
   * @param micState - The current state of the microphone (true for mute, false for unmute).
   */
  const updatMicStateInLCStorage = (muteState: boolean) => {
    const localDataObj = getStuSettingsFromLCStorage();
    const newSettings = {
      ...localDataObj,
      groupLesson: {
        ...localDataObj.groupLesson,
        isMute: muteState
      }
    };
    setStuSettingsToLCStorage(newSettings);
  };

  // #endregion

  // #region to switch new audio device

  // whenever user change device from mic modal and click save we had to change the microphone
  // for current publisher, this is the where switchToNewAudioDevice come into play. it will change
  // the audio source to new device that user has selected from mic modal
  const switchToNewAudioDevice = (micId: string | null) => {
    if (micId) {
      // TODO: this if statement is probably hiding errors
      //  audioPublisher is the publisher for video stream
      if (audioPublisher) {
        // Set the new audio source for the publisher
        audioPublisher.setAudioSource(micId);
      }
    }
  };

  // #endregion

  // #region unmount

  // we need to reset all the states when student accidently change the route
  // this useEffect will trigger on component unMount(route change) and disconnect the student from
  // session and destory the publisher
  useEffect(() => (() => {
    setVonageSession(() => undefined);
    setSessionConnected(() => false);
    // @ts-ignore
    setAudioPublisher(() => undefined);
    setIsTeacherScreensharing(() => false);
    setSubscribers(() => []);
    // we are using vonageSessionRef.current to always get the updated value
    // if we don't do so we will not get updated values
    if (audioPublisherRef.current) {
      audioPublisherRef.current.destroy();
    }

    if (vonageSessionRef.current) {
      vonageSessionRef.current.disconnect();
      vonageSessionRef.current!.off();
    }
  }), []);

  // #endregion

  const value = React.useMemo(
    () => ({
      demoPublish,
      accessGranted,
      vonageFirstInitialized,
      initializeSession,
      connectToSession,
      audioPublisher,
      vonageSession,
      sessionConnected,
      previousRoomLayoutData,
      setPreviousRoomLayoutData,
      isTeacherScreensharing,
      disconnectFromSession,
      publishAudioStream,
      subscribeToStream,
      unSubscribeToStream,
      muteMicrophone,
      unMuteMicrophone,
      switchToNewAudioDevice,
      isMediaStopped,
      setIsMediaStopped,
      temporarilyMutedStudents,
      setTemporarilyMutedStudents
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vonageFirstInitialized, sessionConnected, vonageSession, audioPublisher,
      isTeacherScreensharing, subscribers, isMediaStopped, previousRoomLayoutData,
      setIsMediaStopped, temporarilyMutedStudents, setTemporarilyMutedStudents]
  );

  return (
    <VonageContext.Provider value={value}>
      {children}
    </VonageContext.Provider>
  );
};

export { VonageProvider, VonageContext };
