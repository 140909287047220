import {
  styled,
} from '@languageconvo/wcl';

export const AlignTitle = styled('div')(() => ({
  display: 'flex',
  // vertically align icon and text
  alignItems: 'center',
  // horizontally align
  justifyContent: 'center',
}));
