import { graphql } from 'babel-plugin-relay/macro';

// set lang_learning value of the users table, which represents the language the student is
// currently studying
export const LangLearningMutateQuery = graphql`
  mutation LangLearningMutateQuery (
    $languageId: Int!
  ) {
    stu_settings_langlearningupdate(
      languageId: $languageId
    ) {
        success
        errors
        user {
          id
          lang_learning
        }
      }
  }
`;
