/**
 * @generated SignedSource<<e10d9f75ce789335a1f2c9a478cfbf0c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StuSettingsGettrialdataTeachersAvailibilityFragment$data = {
  readonly trialData: {
    readonly trial: {
      readonly atleastOneTeacherGivesTrials: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "StuSettingsGettrialdataTeachersAvailibilityFragment";
};
export type StuSettingsGettrialdataTeachersAvailibilityFragment$key = {
  readonly " $data"?: StuSettingsGettrialdataTeachersAvailibilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"StuSettingsGettrialdataTeachersAvailibilityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StuSettingsGettrialdataTeachersAvailibilityFragment",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TrialDataDetail",
          "kind": "LinkedField",
          "name": "trialData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TrialDetail",
              "kind": "LinkedField",
              "name": "trial",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "atleastOneTeacherGivesTrials",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "appsetting",
  "abstractKey": null
};

(node as any).hash = "e9f7381f9999bfbf02a7e3cd88e25724";

export default node;
