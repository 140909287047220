import { graphql } from 'babel-plugin-relay/macro';

// #region mutation to get the data from api call

/* This "mutation" is used to make an api call to our backend to schedule
*  a lesson.
*/
export const StuApptScheduletrialMutation = graphql`
  mutation StuApptScheduletrialMutation(
  $unixTs: Int!
  $tchUuid: String!
  ) {
    stu_appt_scheduletrial (
      unixTs: $unixTs
      tchUuid: $tchUuid
    ) {
      success
      errors
      data {
        trial {
          id 
          hasUpcoming
        }
      }
    }
  }
`;
