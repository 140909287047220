import { graphql } from 'babel-plugin-relay/macro';

export const VerifyActivateMutation = graphql`
  mutation VerifyActivateMutation($invoiceNumber: String!) {
    stu_orders_verifyactivate(
      invoiceNumber: $invoiceNumber
    ) {
      success
      data {
        status
      }
    }
  }
`;
