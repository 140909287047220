import { graphql } from 'babel-plugin-relay/macro';

// RelayAppSettings is a client-only schema node. Here we are getting the backbutton value
export const BackbuttonDataQuery = graphql`
  query BackbuttonDataQuery {
    RelayAppSettings {
      ...BackbuttonDataFragment
    }
  }
`;

export const BackbuttonDataFragment = graphql`
  fragment BackbuttonDataFragment on appsetting {
    backbutton
  }
`;
