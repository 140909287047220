import React, { useState, useEffect } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import {
  DataGrid
} from '@languageconvo/wcl';
import { DateTime } from 'luxon';
import { usePaginationFragment } from 'react-relay';
import { Gifts$key } from './__generated__/Gifts.graphql';
import { GiftPurchasesContainerQuery } from './__generated__/GiftPurchasesContainerQuery.graphql';

/** defining here the paginated refetchable fragment
 * Purpose: relay support cursor base pagination, to implement cursor base pagination
 * we need to define refetchable query which will be refetched based on the unique connection
 * we don't need to define pagination info on each refetchable query as relay will handle it
 * via its below specified defined sysntax and on each refetch, first and last cursor automatically
 * set by relay.
 * We are using this fragment in relay hook (i-e usePaginationFragment) whcih will take 2 arguments
 * first one is refetchable fragment and 2nd one is query whcih contains where cluse information.
 * More Info: https://relay.dev/docs/api-reference/use-refetchable-fragment/
 */
const fragment = graphql`
  fragment Gifts on query_root
  @argumentDefinitions(userId: {type: "Int!"}, first: {type: "Int"}, after: {type: "String"})
  @refetchable(queryName: "GiftPaginatedFragment") {
    orders_connection(where: {
      gift_purchaser: {_eq: $userId},
      gift_is_active: {_eq: 1}
    },
    order_by: {created_at: desc}, after: $after, first: $first)
    @connection(key: "Gift_orders_connection") {
      edges {
        node {
          gift_id_random
          duration
          actual_purchase_price
          locations {
            title
          }
          created_at
          valid_to
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const Gifts = ({ fragmentRef, pageSize }: any) => {
  // #region general

  // As we are using MUI dataGrid (present in WCL) and to show the paginated current page count
  // ,we need to update this variable each time user click on next and previous button.
  const [page, setPage] = useState(0);
  // this is a helping hand to disable/enable next and previous buttons inside MUI
  // dataGrid (WCL) if totalPages count equals to the current page.
  // updating this variable when there is no "hasNext" record from "usePaginationFragment"
  const [totalPages, setTotalPages] = useState(-1);
  // "usePaginationFragment" query returns accoumulated data for all the pages instead of
  // the current page but in MUI dataGrid we need to show data page by page that's why
  // below startIndex and endIndex are helping us to extract page specific data from
  // accoumulated data array returned from "usePaginationFragment"
  const [startIndex, setStartIndex] = useState<number>(0); // starting index of the visible rows
  const [endIndex, setEndIndex] = useState<number>(pageSize); // ending index of the visible rows

  // using state to store the gift's data to ensure that the table remains visible
  // even when new data is being fetched. this approach prevents the table from
  // briefly disappearing when the gift array is temporarily empty(when the network call is 
  // being made) during the  data fetch. the table will now continue to display the 
  // previous data until the new data is fetched and the state is updated.
  const [giftsData, setGiftsData] = useState([]);

  // MUI: preparing columns for dataGrid...
  const columns: any = [
    {
      field: 'locations',
      headerName: 'Language',
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 150
    },
    {
      field: 'gift_id_random',
      headerName: 'Gift Code',
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 180,
      renderCell: (params: any) => (
        <span>{params.value !== null ? params.value : 'N/A'}</span>
      ),
    },
    {
      field: 'duration',
      headerName: 'Hours',
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 80
    },
    {
      field: 'created_at',
      headerName: 'Purchased',
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 180
    },
    {
      field: 'valid_to',
      headerName: 'Expiration Date',
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 180
    },
    {
      field: 'cost',
      headerName: 'Cost',
      headerAlign: 'center',
      sortable: false,
      align: 'center',
      minWidth: 80
    },
  ];

  /**
   * data: this readOnly variable holds accoumulated data of all pages instead of current
   * page, relay team is working on this advance use case to update it with only current
   * page data instead of accoumulated all previous pages data.
   *
   * don't need loadPrevious, as "data" variable always accoumulate previous records too.
   */
  const {
    data,
    isLoadingNext,
    loadNext,
    hasNext,
  } = usePaginationFragment<GiftPurchasesContainerQuery, Gifts$key>(
    fragment,
    fragmentRef,
  );
  useEffect(() => {
    // At ths point, you know relay accoumulate the data in single readOnly object (data)
    // returned from "usePaginationFragment" and relay cache this data.
    // It means when user changes the route and came back relay will not send API call as
    // it already have data cached, so "hasNext" value can be false if we get its falsy
    // value, we set the totalPages by dividing the page lenght with page Size so user
    // are able to click on next page button and can see data there.
    if (!hasNext) {
      setTotalPages(Math.floor(
        data.orders_connection.edges.length / pageSize
      ));
    } else {
      setTotalPages(-1);
    }
  }, [hasNext, page, totalPages,
    data.orders_connection.edges.length, pageSize]);
  // This callback will trigger on page change...
  const handlePageChange = (newPage: number) => {
    const start = newPage * pageSize; // calculating new starting index...
    const end = start + pageSize;
    setStartIndex(start);
    setEndIndex(end); // calculating new ending index...
    // Only load new records in case of next page as it accoumulate prevous page records too...
    if (newPage > page) {
      loadNext(pageSize);
    }
    setPage(newPage); // updating current page ...
  };

  useEffect(() => {
    // Filtering the visible rows for each page as "data" have accoumulated recoreds instead
    // of page specific records.
    // we are filtering here the page specific records from whole data.
    // This useEffect is preventing any potential flickering or layout shift that could 
    // occur if the DOM updates were delayed. By using `useEffect`, we ensure that 
    // the visible rows are ready for display in sync with the rendering, providing a smoother 
    // and more responsive user experience.
    const tempGifts: any = [];

    if (data && data.orders_connection?.edges.length) {
      data.orders_connection?.edges?.forEach((info: any, index: number) => {
        if (index >= startIndex && index < endIndex) {
          const newObj = {
            id: index + 1,
            gift_id_random: info.node.gift_id_random,
            duration: (info.node.duration / 3600),
            locations: info.node.locations.title,
            cost: `$${info.node.actual_purchase_price}`,
            created_at: DateTime.fromSeconds(info.node.created_at).toLocaleString({ dateStyle: 'medium' }),
            valid_to: DateTime.fromSeconds(info.node.valid_to).toLocaleString({ dateStyle: 'medium' }),
          };
          tempGifts.push(newObj);
        }
      });

      if (tempGifts.length) {
        setGiftsData(tempGifts);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, data.orders_connection.edges.length, pageSize]);

  // #endregion

  // Ending calculations for creating visible rows for each page...
  return (
    <DataGrid
      rows={giftsData}
      columns={columns}
      autoHeight
      paginationMode="server"
      loading={isLoadingNext}
      handlePageChange={handlePageChange}
      currentPage={page}
      totalPages={totalPages}
      // server-side pagination for an unknown number of items
      // https://github.com/mui/mui-x/issues/409
      rowCount={-1}
    />
  );
};

export default Gifts;
