/**
 * @generated SignedSource<<1022db22e473f62958847427436796be>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetAppLevelDataLanglearnFragment$data = {
  readonly lang_learning: number;
  readonly " $fragmentType": "GetAppLevelDataLanglearnFragment";
};
export type GetAppLevelDataLanglearnFragment$key = {
  readonly " $data"?: GetAppLevelDataLanglearnFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetAppLevelDataLanglearnFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetAppLevelDataLanglearnFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lang_learning",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "35d76c806f9a902a08f23841a0b1dd5b";

export default node;
