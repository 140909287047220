import styled from '@emotion/styled';
import { RadioGroup } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';

export const CustomRadioGroup = styled(RadioGroup)(({ theme }: WCLThemeProps) => (
  {
    // TODO: this doesnt work, mui theme color is being used. we shold probably just use
    // a different radio component, from radix or something
    color: theme?.ourTheme?.colors?.primary?.base,
  }
));
