/**
 * @generated SignedSource<<6515e45be1b29fdd1459b361c2327cbe>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClassroomBasicDataHandRaisValueFragment$data = {
  readonly hand_raised: any | null;
  readonly id: string;
  readonly " $fragmentType": "ClassroomBasicDataHandRaisValueFragment";
};
export type ClassroomBasicDataHandRaisValueFragment$key = {
  readonly " $data"?: ClassroomBasicDataHandRaisValueFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClassroomBasicDataHandRaisValueFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClassroomBasicDataHandRaisValueFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hand_raised",
      "storageKey": null
    }
  ],
  "type": "appt_group_reservations",
  "abstractKey": null
};

(node as any).hash = "b25e999d3503ed804fbd6827c26577c5";

export default node;
