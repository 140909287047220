import { graphql } from 'babel-plugin-relay/macro';

export const SkillsQuery = graphql`
  query SkillsQuery {
    ...SkillsAllFragment
  }
`;

// a single, individual skill
export const SkillsDetailFragment = graphql`
  fragment SkillsDetailFragment on skills_levels {
    display_order
    title_en
    skills(order_by: {display_order: asc}) {
      title_en
      display_order
      description_en
      examples_en
    }
  }
`;

// all levels, and each skill (SkillsDetailFragment) within every level
export const SkillsAllFragment = graphql`
  fragment SkillsAllFragment on query_root {
    skills_levels_connection(order_by: {display_order: asc}) {
      edges {
        node {
          ...SkillsDetailFragment
        }
      }
    }
  }
`;
