import React, {
  useEffect, useState, useContext,
} from 'react';
import { DateTime } from 'luxon';
import { useFragment } from 'react-relay';
import { toast } from 'react-toastify';
import { LessonTimeCalculationFragment } from '../../relay/LessonTimeCalculation';
import { useVonage } from '../../hooks/useVonage';
import { ClassroomSetupContext } from '../../GroupClassroom';
import { Setup4ViewSetup3Countdownlock } from './Setup4ViewSetup3Countdownlock';

interface LessonTimeCalculationProps {
  initialClassroomDataRef: any;
  lessonInfo: any;
  hasLessonStarted: boolean;
  setHasLessonStarted: any;
  hasLessonEnded: boolean;
  setHasLessonEnded: any;
  setmodalviewContents: any;
  setmodalviewState: any;
}

// Component responsible for synchronizing browser time with backend time 
// and managing countdown timers.
export const Setup4ViewSetup2LessonTimeCalc = (
  {
    initialClassroomDataRef,
    lessonInfo,
    hasLessonStarted,
    setHasLessonStarted,
    hasLessonEnded,
    setHasLessonEnded,
    setmodalviewContents,
    setmodalviewState,
  }: LessonTimeCalculationProps
) => {
  // #region general

  const csSetupContext = useContext(ClassroomSetupContext);
  const { disconnectFromSession } = useVonage();

  // to store time diff between browsers & time received from backend.
  const [timeDifference, setTimeDifference] = useState(0);
  // initalClassRoom data for this component
  const initialClassroomData = useFragment(LessonTimeCalculationFragment, initialClassroomDataRef);

  // seconds until lesson starts and ends
  const { secondsUntilStart, secondsUntilEnd } = lessonInfo.dt;
  const [secondsRemainingInLectureStart, setSecondsRemainingInLectureStart] = useState<number>(
    secondsUntilStart
  );
  const [secondsRemainingInLectureEnd, setSecondsRemainingInLectureEnd] = useState<number>(
    secondsUntilEnd
  );

  // will set to true once the data on this page has initially processed, so that we can stop the
  // countdown from "flashing" briefly on pageload
  const [dataHasProcessed, setDataHasProcessed] = useState(false);

  // this state tracks whether the lesson-ending toast has been displayed when 
  // there are 60 seconds left.
  const [isLsnEndingToastDisplayed, setIsLsnEndingToastDisplayed] = useState<boolean>(false);

  // #endregion

  // #region time synchronization 

  // checking browser's time and if diff is within 10 seconds, we use browser's time else
  // use backend time. 
  useEffect(() => {
    // current time of browser
    const currentBrowserTime = DateTime.now();
    // current time which backend is giving us
    const currentTimeFromBackend = DateTime.fromISO(initialClassroomData.dt.current);

    // differnce between current time and currentTime received from backend
    // currentBrowserTime <=> greater <=> future <=> we get + number.
    // currentBrowserTime <=> lesser <=> past <=> we get - number.
    const difference = currentBrowserTime.diff(currentTimeFromBackend, ['second']);
    const currentTimeDifference: number = difference.toObject().seconds!;

    // if the diff between backend and frontend times is less than 30 seconds, we assume the user's
    // browser is correct. 30 seconds is pretty large; the reason for it being so large is we
    // have to account for the fact that the api call(s) on classroom load can take awhile sometimes
    // and we don't want to incorrectly notify the user in those cases
    if (currentTimeDifference <= 30 && currentTimeDifference >= -30) {
      // using the browser's current time.
      setTimeDifference(0);
    } else {
      // display modal notifying user that their clock seems to be incorrect
      setmodalviewContents(950);
      setmodalviewState(true);
      setTimeDifference(currentTimeDifference);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #endregion

  // #region timer, updated every second. lesson start and end events

  // here we use a setInterval to update secondsRemainingInLectureStart and
  // secondsRemainingInLectureEnd every second. we'll use this to know when the lesson
  // starts and ends so we can take the appropriate action when those events occur
  useEffect(() => {
    // indicates that we have done the initial processing and can display
    setDataHasProcessed(true);

    const intervalCall = setInterval(async () => {
      let timeToBeUsed;
      if (timeDifference > 0) {
        timeToBeUsed = DateTime.now().minus({ seconds: timeDifference });
      } else {
        timeToBeUsed = DateTime.now().plus({ seconds: timeDifference });
      }

      // checking if initialRoomData is available
      if (initialClassroomData) {
        // getting the start time from initialLoadData.
        const startTime = DateTime.fromISO(initialClassroomData.dt.startsAt);
        // getting the end time from initialLoadData
        const endTime = DateTime.fromISO(initialClassroomData.dt.endsAt);

        // calculating the time remaining until lesson starts
        const timeRemainingInStart = startTime.diff(timeToBeUsed, ['second']);
        const remainingStartTimeInSeconds = timeRemainingInStart.toObject().seconds;
        setSecondsRemainingInLectureStart(remainingStartTimeInSeconds!);

        // calculating the time remaining until lesson ends
        const timeRemainingInEnd = endTime.diff(timeToBeUsed, ['second']);
        const remainingEndTimeInSeconds = timeRemainingInEnd.toObject().seconds;
        setSecondsRemainingInLectureEnd(remainingEndTimeInSeconds!);
      }
    }, 1000);

    return () => {
      // clean up, for clearing the interval
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialClassroomData, timeDifference]);

  // lesson start -- update ui, connect to vonage
  useEffect(() => {
    // the lesson is starting! we tell the parent component that we are going to remove
    // the countdown clock, and we also connect to the vonage session
    if (secondsRemainingInLectureStart <= 4 && !hasLessonStarted) {
      setHasLessonStarted(true);
    }
  }, [secondsRemainingInLectureStart, setHasLessonStarted, hasLessonStarted]);

  // lesson end -- update state var, disconnect from vonage session
  useEffect(() => {
    if (secondsRemainingInLectureEnd <= 0 && !hasLessonEnded) {
      setHasLessonEnded(true);
      disconnectFromSession();

      // showing a toast to notify the student the class is ending soon
    } else if (secondsRemainingInLectureEnd <= 60 && !hasLessonEnded
      && !isLsnEndingToastDisplayed) {
      // if the user reloads the page with say 15 seconds remaining, the else case will be
      // displayed. the typical/normal case is the user is just in the class and hasn't
      // reloaded the page recently
      if (secondsRemainingInLectureEnd > 30) {
        toast.success('Class is ending in about a minute!');
      } else {
        toast.success('Class is ending very soon!');
      }
      setIsLsnEndingToastDisplayed(true);
    } else {
      // do nothing.
    }
  }, [secondsRemainingInLectureEnd, hasLessonEnded, setHasLessonEnded, disconnectFromSession,
    isLsnEndingToastDisplayed]);

  // #endregion

  // don't display anything until the data has processed
  if (!dataHasProcessed) {
    return (<div />);
  }

  // display lesson has ended
  if (hasLessonEnded) {
    csSetupContext.setPageviewState(204);
    // return null rather than div, so there is no div rerendering on page as the timer continues
    // counting even after lesson end
    return (null);
  }

  // display countdown clock until the lesson starts
  if (!hasLessonStarted) {
    return (
      <Setup4ViewSetup3Countdownlock
        secondsRemainingInLessonStart={secondsRemainingInLectureStart}
      />
    );
  }

  // here we *specifically* return null, instead of <div />. the reason being, a div renders
  // to the page (even though it takes no space); bc of the setInterval running every second,
  // that div rerenders every second. by returning null, we don't get any rerendering on the page
  return (
    null
  );
};
