import {
  styled, Box, WCLThemeProps,
} from '@languageconvo/wcl';

// TODO: probably move this to common component, maybe wcl?
export const MarkdownContainer = styled(Box)(({ theme }: WCLThemeProps) => ({
  fontFamily: theme?.ourTheme.typography.fontFamily,

  // we set the font size for everything -- paragraphs, headings, etc. to our paragraph style. this
  // just seems to look best
  [theme?.breakpoints.up('md') as any]: {
    fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem !important`,
    h1: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem !important`,
    },
    h2: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem !important`,
    },
    h3: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem !important`,
    },
    h4: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem !important`,
    },
    h5: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem !important`,
    },
    h6: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem !important`,
    },
  },
  [theme?.breakpoints.down('md') as any]: {
    fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem !important`,
    h1: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem !important`,
    },
    h2: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem !important`,
    },
    h3: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem !important`,
    },
    h4: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem !important`,
    },
    h5: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem !important`,
    },
    h6: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem !important`,
    },
  },
}));

// container for user's questions
export const MyQuestionContainer = styled(Box)(({ theme }: WCLThemeProps) => ({
  padding: '20px',
  borderRadius: theme?.ourTheme.borders.borderRadius.medium,
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentGreen1}`,
}));
