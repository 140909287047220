import {
  styled, WCLThemeProps,
} from '@languageconvo/wcl';

export const ImageMainProfile = styled('img')(({ theme }: WCLThemeProps) => ({
  width: '100%',
  aspectRatio: '1 / 1', /* defining the aspect ratio of the image */
  objectFit: 'cover', /* making sure the image isn't distorted */
  [theme!.breakpoints.up('xs')]: {
    maxWidth: '200px',
  },
  [theme!.breakpoints.up('lg')]: {
    maxWidth: '250px',
  },
}));
