import { TypographyTypes } from './interface';
import '@fontsource/poppins';

// fb uses this as their main font size, so we decided to do the same
const fontSizePrimaryRem = 0.9375;
const fontSizePrimaryRemSmallscreen = 1;

export const typography: TypographyTypes = {
  fontFamily: 'Poppins, sans-serif',
  h1: {
    fontSizeRem: 1.72,
    fontSizeRemSmallscrn: 2,
    fontWeight: 500,
  },
  h1New: {
    fontSizeRem: 1.72,
    fontSizeRemSmallscrn: 1.7,
    fontWeight: 500,
  },
  h2: {
    fontSizeRem: 1.4,
    fontSizeRemSmallscrn: 1.7,
    fontWeight: 400,
  },
  h2New: {
    fontSizeRem: 1.3,
    fontSizeRemSmallscrn: 1.2,
    fontWeight: 500,
  },
  subheading: {
    fontSizeRem: 1.05,
    fontSizeRemSmallscrn: 1.05,
    fontWeight: 600,
  },
  p: {
    fontSizeRem: fontSizePrimaryRem,
    fontSizeRemSmallscrn: fontSizePrimaryRemSmallscreen,
    fontWeight: 400,
  },
  // for new typography variants
  small: {
    fontSizeRem: 0.77,
    fontSizeRemSmallscrn: 0.9,
    fontWeight: 400,
  },
  // we decided we want our button text to be same as paragraph font size, so:
  button: {
    fontSizeRem: fontSizePrimaryRem,
    fontSizeRemSmallscrn: fontSizePrimaryRemSmallscreen,
    fontWeight: 400,
  },
};
