import React from 'react';
import { Checkbox as CustomCheckbox } from '@mui/material';
import { Props } from './interface';

/**
 * Our main Checkbox component. See:
 *
 * https://mui.com/material-ui/react-checkbox/
 *
 * https://mui.com/material-ui/api/checkbox/
 */

export const Checkbox: React.FunctionComponent<Props> = (props) => {
  const {
    checked = false,
    color = 'primary',
    disabled = false,
    onChange,
    required = false,
    size = 'medium',
    value = undefined,
    cp,
  } = props;

  return (
    <CustomCheckbox
      checked={checked}
      color={color}
      disabled={disabled}
      onChange={onChange}
      required={required}
      size={size}
      value={value}
      {...cp}
    />
  );
};
