import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Grid2Ct, Grid2, CardStandard, IcSvgList, Ty, Link,
} from '@languageconvo/wcl';
import { LayoutPosttrial0 } from '../../posttrial/LayoutPosttrial0';
import { sitedata } from '../../../../../../utils/sitedata';

/* Student has missed 2 trial lessons; show them a note that they can't schedule any more,
    but they can purchase a small package to get started
*/
export const TrialMissedTwo = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <>
      {/* how to purchase, contact customer service */}
      <Grid2Ct sx={{ mb: 1 }}>
        <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
          <CantReschedule />
        </Grid2>
        <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
          <ContactUs />
        </Grid2>
      </Grid2Ct>

      {/* normal display */}
      <LayoutPosttrial0 />
    </>
  );
};

// #region subcomponents

const CantReschedule = () => {
  // link to purchase page
  const purchUrl = sitedata.url.app.purchaseHome;
  const clickableTypeObjPurch = {
    to: purchUrl,
    component: NavLink,
  };

  return (
    <CardStandard
      titleText="Purchasing Lessons"
      titleIcon={IcSvgList.purchase1}
      color="accentGreen1"
      cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
      clickableLink1={{ ...clickableTypeObjPurch }}
    >
      <Link
        to={purchUrl}
        component={NavLink}
        linkStyle="nostyle"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty removeMb>
              It looks like we missed you in another free trial lesson. If you are ready
              to get started with lessons we have a small 2 hour package of lessons that
              you can purchase, which you can then use to schedule with any teacher.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Link>
    </CardStandard>
  );
};

const ContactUs = () => {
  // link to more page
  const moreUrl = sitedata.url.app.moreHome;
  const clickableTypeObjMore = {
    to: moreUrl,
    component: NavLink,
  };

  return (
    <CardStandard
      titleText="Contact Us"
      titleIcon={IcSvgList.customersupport1}
      color="accentBlue1"
      cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
      clickableLink1={{ ...clickableTypeObjMore }}
    >
      <Link
        to={moreUrl}
        component={NavLink}
        linkStyle="nostyle"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty removeMb>
              We have a customer support team that can help if you have any questions
              you would like to ask. Just head to the &quot;More&quot; page to chat
              with us anytime, we are real (friendly) people!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Link>
    </CardStandard>
  );
};

// #endregion
