import React, { Suspense } from 'react';
import {
  Grid2, Stack, Ic, Link, useTheme, Skeleton,
} from '@languageconvo/wcl';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../../../hooks/useResponsive';
// components, styles
import { AccountPopover } from './components/accountpopover/AccountPopover';
// we will uncomment this as we need translaiton
// import { LanguagePopover } from './components/languagepopover/LanguagePopover';
import * as S from './Header.styles';
import { FavouriteTeacherPopover } from './components/FavouriteTeacherPopover/FavouriteTeacherPopover';
import PrefferedLangKey from './components/prefferedLangKey';
import { MobileTopBar } from './components/mobiletopbar/MobileTopBar';
import { DesktopTopBarCenterleft } from './components/desktoptopbar/DesktopTopBarCenterleft';

type Props = {
  onOpenSidebar: () => void;
  fragmentRef: any
};

// this is the main app header, the bar that goes across the top of the app. mui calls it
// the "appbar"
export const DashboardHeader = ({
  onOpenSidebar, fragmentRef
}: Props) => {
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      {/*  lg and larger screens appbar
            RootStyle overriding MUI appbar with some custom styling, instead of messing up
            here with sx prop we made a styled component and used it.
      */}
      <S.RootStyle isDesktop={isDesktop} sx={{ display: { xs: 'none', lg: 'flex' } }}>
        <S.ToolBar>
          {/* logo and menu hamburger */}
          <Stack minWidth={{ lg: theme?.ourTheme.components.navbar.navbarWidthExpandedStack }} direction="row" alignItems="center" justifyContent="space-between">
            {/* logo. only on large screens */}
            {isDesktop && (
            <Link onClick={() => {
              navigate('/home');
            }}
            >
              <S.Logo src={`${process.env.REACT_APP_CDNURL}/common/logo/logo.svg`} data-testid="logo" alt="Logo" width="160" />
            </Link>
            )}

            {/* hamburger icon, to open the drawer. only on small screens */}
            {!isDesktop && (
            <S.Hamburger
              onClick={onOpenSidebar}
              onKeyDown={onOpenSidebar}
              role="presentation"
            >
              <Ic iconName="bars" size="rel-lg" />
            </S.Hamburger>
            )}
          </Stack>

          {/* back button */}
          <DesktopTopBarCenterleft />

          {/* moving rest of the below components to right side of header */}
          <S.HeaderSpace />

          {fragmentRef !== null && <PrefferedLangKey fragmentRef={fragmentRef} />}

          {/* this is the right side of the header, including the settings icon and lang popover */}
          <Grid2 container spacing={2} columnSpacing={2}>
            {/* favourite teacher popover */}
            <Grid2 xs={6}>
              <Suspense fallback={<Skeleton width={40} height={40} variant="rectangular" />}>
                <FavouriteTeacherPopover fragmentRef={fragmentRef} />
              </Suspense>
            </Grid2>

            {/* my language popover */}
            {/* <Grid2 xs={4}>
            <LanguagePopover />
            </Grid2> */}

            {/* account settings popover */}
            <Grid2 xs={6}>
              <Suspense fallback={<Skeleton width={40} height={40} variant="rectangular" />}>
                <AccountPopover fragmentRef={fragmentRef} />
              </Suspense>
            </Grid2>
          </Grid2>
        </S.ToolBar>
      </S.RootStyle>

      {/* sm and smaller screens appbar */}
      <MobileTopBar />
    </>
  );
};
