// @ts-nocheck
// This wrapper was taken directly from swiperjs docs, which is not typescript ready

import React, { useEffect, useRef } from 'react';
import { register } from 'swiper/element/bundle';
import { useTheme } from '@mui/material';

// this is a wraper component for swiperjs web components. necessary since web components
// are not available natively in react yet. taken from:
// https://swiperjs.com/blog/using-swiper-element-in-react
export const Swiper = (props: any) => {
  const swiperRef = useRef(null);
  const theme = useTheme();
  const {
    children,
    ...rest
  } = props;

  // #region our custom props

  // IMPORTANT: when using this component you must include both of these props:
  //  ourcustomwidth
  //  ourcustomcolor

  // IMPORTANT: these are custom props we have created that are not part of the swiperjs
  // api. It's important they are named all lowercase because they do get passed on to
  // the final component.

  // TODO: use our icon svgs as the arrows instead of theirs
  // here we set the color of the navigation buttons
  let navbtnColor = `hsl(${theme?.ourTheme.colors.neutral.lighter1})`;
  if (rest.ourcustomcolor === 'accentGreen1') {
    navbtnColor = `hsl(${theme?.ourTheme.colors.accentGreen1.base})`;
  } else if (rest.ourcustomcolor === 'accentOrange1') {
    navbtnColor = `hsl(${theme?.ourTheme.colors.accentOrange1.base})`;
  } else if (rest.ourcustomcolor === 'accentPurple1') {
    navbtnColor = `hsl(${theme?.ourTheme.colors.accentPurple1.base})`;
  } else if (rest.ourcustomcolor === 'accentBlue1') {
    navbtnColor = `hsl(${theme?.ourTheme.colors.accentBlue1.base})`;
  } else if (rest.ourcustomcolor === 'accentYellow1') {
    navbtnColor = `hsl(${theme?.ourTheme.colors.accentYellow1.base})`;
  } else if (rest.ourcustomcolor === 'accentRed1') {
    navbtnColor = `hsl(${theme?.ourTheme.colors.accentRed1.base})`;
  }

  // the width of the main container
  const width = rest.ourcustomwidth;

  // #endregion

  useEffect(() => {
    // Register Swiper web component
    register();

    // pass component props to parameters
    const params = {
      ...rest,
      //  styles that can be set below, in the injectStyles section, are listed here:
      //  https://swiperjs.com/element#parts
      //  regarding navigation arrow size, the following didnt seem to work correctly:
      //    --swiper-navigation-size: "50px";
      //    so instead, we manually set the height and width, as noted here:
      //    https://github.com/nolimits4web/swiper/issues/7196
      injectStyles: [
        `
        .swiper {
          width: ${width};
        }
        .swiper-button-next, .swiper-button-prev {
          width: 20px !important;
          height: 20px !important;
        }
        .swiper-button-prev {
          color: ${navbtnColor};
        }
        .swiper-button-next {
          color: ${navbtnColor};
        }

        .swiper-pagination {
          bottom: 0px !important;
        }

        .swiper-pagination-bullet {
          background-color: #007bff; /* Change dot color */
          width: 8px;
          height: 8px;
        }

        .swiper-pagination-bullet-active {
          background-color: ${navbtnColor};
          width: 20px;
          border-radius: 8px
        }
        `,
      ],
    };

    // Assign it to swiper element
    Object.assign(swiperRef.current, params);

    // initialize swiper
    swiperRef.current.initialize();
  }, []);

  return (
    <swiper-container init="false" ref={swiperRef}>
      {children}
    </swiper-container>
  );
};

// each individual slide
export const SwiperSlide = (props: any) => {
  const {
    children,
    ...rest
  } = props;

  return (
    <swiper-slide {...rest}>
      {children}
    </swiper-slide>
  );
};
