import { graphql } from 'babel-plugin-relay/macro';

export const StuTeachersFavoriteremoveMutationNew = graphql`
  mutation StuTeachersFavoriteremoveNewMutation(
    $teacherUuid: String!
  ){
    stu_teachers_favoriteremove(
      teacherUuid: $teacherUuid
    ){
      success
      user_favteachers {
        id
        favorite
      }
    }
  }
`;
