/**
 * @generated SignedSource<<a81f9d943b0527675a5bee81366c61af>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MicDetailsFragment$data = {
  readonly microphone: {
    readonly all: {
      readonly edges: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
      } | null>;
    };
    readonly current: {
      readonly isMuted: boolean;
      readonly micId: string | null;
      readonly title: string | null;
    };
  };
  readonly " $fragmentType": "MicDetailsFragment";
};
export type MicDetailsFragment$key = {
  readonly " $data"?: MicDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MicDetailsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MicDetailsFragment",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MicrophoneDetails",
          "kind": "LinkedField",
          "name": "microphone",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CurrentMicrophoneDetail",
              "kind": "LinkedField",
              "name": "current",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "micId",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isMuted",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AllAvailableMicDetailEdges",
              "kind": "LinkedField",
              "name": "all",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AllMicrophoneDetailItem",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "appsetting",
  "abstractKey": null
};
})();

(node as any).hash = "10edce977647438ffef98ae9d46713e1";

export default node;
