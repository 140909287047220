import { graphql } from 'babel-plugin-relay/macro';

/* Use this query to get mic data from the relay store. */
export const MicDetailsQuery = graphql`
  query MicDetailsQuery {
    RelayAppSettings {
      ...MicDetailsFragment
    }
  }
`;

export const MicDetailsFragment = graphql`
  fragment MicDetailsFragment on appsetting {
    microphone {
      current {
          micId
          title
          isMuted
      }
      all {
          edges {
              id
              title
          }
      }
    }
  }
`;
