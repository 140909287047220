import { graphql } from 'babel-plugin-relay/macro';

export const DeleteUserGroupSettings = graphql`
  mutation DeleteUserGroupSettingMutation {
  stu_settings_groupcredsdelete {
      success
    errors
    user {
      id
      groupname
    }
    }
  }
`;
