import { graphql } from 'babel-plugin-relay/macro';

export const GetStdSettingsDataFragment = graphql`
fragment GetStdSettingsDataFragment on query_root {
  users_connection {
    edges {
      node {
        user_timezone
        dob
        password_set
        timezone_set
        relayAge
      }
    }
  }
}
`;
