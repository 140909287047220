/**
 * @generated SignedSource<<cf3d375a144d1c56d923fee25ab33e9e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BackbuttonDataFragment$data = {
  readonly backbutton: string | null;
  readonly " $fragmentType": "BackbuttonDataFragment";
};
export type BackbuttonDataFragment$key = {
  readonly " $data"?: BackbuttonDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BackbuttonDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BackbuttonDataFragment",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backbutton",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "appsetting",
  "abstractKey": null
};

(node as any).hash = "9504e8544d577faca3f8000b7c118868";

export default node;
