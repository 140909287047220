import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface Props {
  overflowX: 'hidden' | 'scroll' | 'auto';
  overflowY: 'hidden' | 'scroll' | 'auto';
}

export const StyleContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'overflowX' && prop !== 'overflowY',
})<Props>(({
  overflowX, overflowY,
}) => ({
  overflowX,
  overflowY,
}));
