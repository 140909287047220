import { graphql } from 'babel-plugin-relay/macro';

// TODO: dont think this is needed now that we have user explicitly choose language
//  they are learning. should remove this and cleanup wherever it's used
export const GetLanguageFromOrders = graphql`
fragment GetLanguageFromOrders on query_root {
  orders_connection(
    where: {
      is_active: {_eq: 1}
    },
    first: 1,
    order_by: {valid_from: desc}
  ) {
    edges {
      node {
        location_id
      }
    }
  }
}
`;
