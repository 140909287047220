import React from 'react';
import * as Sentry from '@sentry/react';
import {
  Grid2Ct, Grid2, CardStandard, IcSvgList, Ty,
} from '@languageconvo/wcl';
import { StepsToSchedule1 } from './StepsToSchedule/StepsToSchedule1';
import { TrialCompleted } from './TrialCompleted';

interface Props {
  dt: any
}

/* if there is a past trial but the status is != completed
*/
export const TrialPastOtherstatus = ({ dt }: Props) => {
  /* log error if status unexpected
      these are the only past trial statues that should occur here, log an error if any other
      status occurs. status = 3 is "student canceled more than 24 hours in advance" and status
      8 = is "completed trial", neither of which should occur here
  */
  if (dt.trialData.trial.pastTrial.status !== 1 && dt.trialData.trial.pastTrial.status !== 2
    && dt.trialData.trial.pastTrial.status !== 4 && dt.trialData.trial.pastTrial.status !== 5
    && dt.trialData.trial.pastTrial.status !== 6 && dt.trialData.trial.pastTrial.status !== 7
  ) {
    Sentry.captureException(
      new Error('MAJOR ERROR: past trial status was not as expected, should not occur'),
      {
        extra: {
          data: dt,
        }
      }
    );
  }

  /* past trial status not yet set
      For now, we'll assume that the lesson was completed. We show a thank you note and the
      normal home page (that students who've purchased see).
      TODO: in near future, we should have the backend get lesson events and attempt to
      guess whether the lesson was actually completed or not.
  */
  if (dt.trialData.trial.pastTrial.status === 1) {
    return (
      <TrialCompleted />
    );
  }

  /* past trial canceled by teacher
      Show an apology, and the steps to reschedule
  */
  if (dt.trialData.trial.pastTrial.status === 2) {
    return (
      <>
        {/* apology */}
        <Grid2Ct sx={{ mb: 1 }}>
          <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
            <TeacherCanceled />
          </Grid2>
          <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
            <TeacherAttendanceRate />
          </Grid2>
        </Grid2Ct>

        {/* reschedule trial */}
        <StepsToSchedule1 />
      </>
    );
  }

  /* past trial missed by teacher
      Show an apology, and the steps to reschedule
  */
  if (dt.trialData.trial.pastTrial.status === 4) {
    return (
      <>
        {/* apology */}
        <Grid2Ct sx={{ mb: 1 }}>
          <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
            <TeacherMissed />
          </Grid2>
          <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
            <TeacherAttendanceRate />
          </Grid2>
        </Grid2Ct>

        {/* reschedule trial */}
        <StepsToSchedule1 />
      </>
    );
  }

  /* student canceled the lesson less than 24 hours in advance
      Explain they can't miss 2 lessons, and the steps to reschedule
  */
  if (dt.trialData.trial.pastTrial.status === 5) {
    return (
      <>
        {/* explain can't miss 2 */}
        <Grid2Ct sx={{ mb: 1 }}>
          <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
            <RescheduleBelow />
          </Grid2>
          <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
            <CancelationPolicy />
          </Grid2>
        </Grid2Ct>

        {/* reschedule trial */}
        <StepsToSchedule1 />
      </>
    );
  }

  /* missed by student
      Explain they can't miss 2 lessons, and the steps to reschedule
  */
  if (dt.trialData.trial.pastTrial.status === 6) {
    return (
      <>
        {/* explain can't miss 2 */}
        <Grid2Ct sx={{ mb: 1 }}>
          <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
            <RescheduleBelowStumissed />
          </Grid2>
          <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
            <CancelationPolicyStumissed />
          </Grid2>
        </Grid2Ct>

        {/* reschedule trial */}
        <StepsToSchedule1 />
      </>
    );
  }

  /* student technical problem
      Show an apology, and the steps to reschedule
  */
  if (dt.trialData.trial.pastTrial.status === 7) {
    return (
      <>
        {/* reschedule, contact us */}
        <Grid2Ct sx={{ mb: 1 }}>
          <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
            <RescheduleBelowTechproblem />
          </Grid2>
          <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
            <ContactUs />
          </Grid2>
        </Grid2Ct>

        {/* reschedule trial */}
        <StepsToSchedule1 />
      </>
    );
  }

  // this should not occur, it's just a backup in case the past trial status is not
  // what this component expects (the error logging above will hit in this case)
  return (
    <StepsToSchedule1 />
  );
};

// #region sub-components

const TeacherCanceled = () => (
  <CardStandard
    titleText="We Humbly Apologize"
    titleIcon={IcSvgList.graduationcap1}
    color="accentGreen1"
    titleIconRight={0}
    cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty>
          <Ty removeMb>
            It looks like your teacher had to cancel your lesson due to a personal issue.
            We&nbsp;<strong>really</strong>&nbsp;apologize; this is not common, and we
            hope that you will consider rescheduling your trial lesson.
          </Ty>
        </Ty>
      </Grid2>
    </Grid2Ct>
  </CardStandard>
);

const TeacherMissed = () => (
  <CardStandard
    titleText="We Humbly Apologize"
    titleIcon={IcSvgList.graduationcap1}
    color="accentGreen1"
    titleIconRight={0}
    cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty removeMb>
          It looks like your teacher missed your lesson due to an unexpected personal issue.
          We&nbsp;<strong>really</strong>&nbsp;apologize; this is not common, and we
          hope that you will consider rescheduling your trial lesson.
        </Ty>
      </Grid2>
    </Grid2Ct>
  </CardStandard>
);

const TeacherAttendanceRate = () => (
  <CardStandard
    titleText="Teacher Attendance"
    titleIcon={IcSvgList.calendar1}
    color="accentBlue1"
    titleIconRight={0}
    cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty removeMb>
          All of our teachers must have above a 95% attendance rate, and most are above 97%.
          A teacher missing a lesson, especially with a new student,
          is&nbsp;<strong>not</strong>&nbsp;common
          and not something you should expect moving forward.
        </Ty>
      </Grid2>
    </Grid2Ct>
  </CardStandard>
);

const RescheduleBelow = () => (
  <CardStandard
    titleText="Reschedule Below"
    titleIcon={IcSvgList.star1}
    color="accentBlue1"
    titleIconRight={0}
    cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty removeMb>
          Please feel free to reschedule your trial lesson below, anytime that
          works well for you. If you have any questions, you can quickly message
          us on the &quot;More&quot; page.
        </Ty>
      </Grid2>
    </Grid2Ct>
  </CardStandard>
);

const CancelationPolicy = () => (
  <CardStandard
    titleText="Cancellation Policy"
    titleIcon={IcSvgList.book1}
    color="accentBlue1"
    titleIconRight={0}
    cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty removeMb>
          Our cancelation policy is 24 hours; it looks like you had to cancel your previous
          lesson within that 24 hour period. If you need to cancel/reschedule again, please cancel
          further in advance.
        </Ty>
      </Grid2>
    </Grid2Ct>
  </CardStandard>
);

const RescheduleBelowStumissed = () => (
  <CardStandard
    titleText="Reschedule Below"
    titleIcon={IcSvgList.star1}
    color="accentBlue1"
    titleIconRight={0}
    cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty removeMb>
          It looks like we missed you for your trial lesson, we were looking forward
          to seeing you! Please feel free to reschedule your trial lesson below,
          anytime that works well for you.
        </Ty>
      </Grid2>
    </Grid2Ct>
  </CardStandard>
);

const CancelationPolicyStumissed = () => (
  <CardStandard
    titleText="Cancellation Policy"
    titleIcon={IcSvgList.book1}
    color="accentBlue1"
    titleIconRight={0}
    cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty removeMb>
          Our cancelation policy is 24 hours. Please keep in mind that you are scheduling a lesson
          with a real person, and commit to attending. If something comes up please cancel more than
          24 hours in advance.
        </Ty>
      </Grid2>
    </Grid2Ct>
  </CardStandard>
);

const RescheduleBelowTechproblem = () => (
  <CardStandard
    titleText="Reschedule Below"
    titleIcon={IcSvgList.star1}
    color="accentBlue1"
    titleIconRight={0}
    cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty removeMb>
          It looks like there was a technical problem during your lesson, which is
          extremely rare. Please feel free to reschedule your trial lesson below, anytime that
          works well for you.
        </Ty>
      </Grid2>
    </Grid2Ct>
  </CardStandard>
);

const ContactUs = () => (
  <CardStandard
    titleText="Contact Us"
    titleIcon={IcSvgList.customersupport1}
    color="accentBlue1"
    titleIconRight={0}
    cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
  >
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty removeMb>
          We have a customer support team that can help if you aren&apos;t sure
          what the problem is. Just head to the &quot;More&quot; page to chat
          with us anytime, we are real (friendly) people!
        </Ty>
      </Grid2>
    </Grid2Ct>
  </CardStandard>
);

// #endregion
