import React from 'react';
import {
  FormControl, FormLabel, FormControlLabel, Radio,
} from '@mui/material';
import { CustomRadioGroup } from './index.style';
import { RadioGroupProps, RadioOption } from './interface';
import { Ty } from '../../datadisplay';

/**
 * Our main RadioGroup component. See:
 *
 * https://mui.com/material-ui/react-radio-button/#radio-group
 *
 * https://mui.com/material-ui/api/radio-group/
 */

export const RadioGroup: React.FunctionComponent<RadioGroupProps> = (props) => {
  const {
    groupLabel,
    defaultValue = undefined,
    options,
    value = undefined,
    name,
    onChange,
    disabled = false,
    size = 'medium',
    required = false,
    color = 'primary',
    cp,
    radioCp,
  } = props;

  return (
    <FormControl {...cp}>
      {groupLabel && <FormLabel color={color} disabled={disabled}>{groupLabel}</FormLabel>}
      <CustomRadioGroup
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        {...radioCp}
      >
        {options.map((option:RadioOption) => (
          <FormControlLabel
            key={option.label}
            // to have same font size of placeholder like Typography variant p
            label={<Ty removeMb>{option.label}</Ty>}
            value={option.value}
            control={(
              <Radio
                size={size}
                color={color}
                disabled={disabled}
                required={required}
                {...option.props}
              />
            )}
          />
        ))}

      </CustomRadioGroup>
    </FormControl>
  );
};
