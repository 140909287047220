import { styled, WCLThemeProps } from '@languageconvo/wcl';

export const RootStyle = styled('div')(({ theme }:any) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  transition: theme?.ourTheme.components.transition.page,
}));

export const RootStyles = styled('div')(({ theme }: WCLThemeProps & any) => ({
  [theme!.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme?.ourTheme.components.transition.page,
  },
}));

export const CustomScrollbar = {
  height: 1,
  '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
};

export const CustomStack = {
  padding: 2,
  flexShrink: 0,
};

export const DrawerOverrides = {
  width: (theme: any) => theme?.ourTheme.components.navbar.navbarWidthExpanded,
  transition: (theme: any) => theme?.ourTheme.components.transition.page,
  // this is important -- it allows the open/close icon to be visible even though it
  // overflows the drawer container
  overflow: 'visible',
};

// container for the open/close icon
export const OpencloseAppbarContainer = styled('div')(() => ({
  position: 'absolute',
  bottom: '20px',
  right: '0px',
  transform: 'translateX(50%)',
  zIndex: 1,
}));
