/**
 * @generated SignedSource<<c3a08999b9abe6f4647e120d51946afe>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuSettingsUpdateaicsettingsMutation$variables = {
  level: number;
  translation: any;
};
export type StuSettingsUpdateaicsettingsMutation$data = {
  readonly stu_settings_updateaicsettings: {
    readonly data: {
      readonly user_settings: {
        readonly aic_level: number;
        readonly aic_setsettings: any | null;
        readonly aic_translate: any;
        readonly id: string;
      };
    };
    readonly errors: ReadonlyArray<string | null> | null;
    readonly success: boolean;
  } | null;
};
export type StuSettingsUpdateaicsettingsMutation = {
  response: StuSettingsUpdateaicsettingsMutation$data;
  variables: StuSettingsUpdateaicsettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "level"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "translation"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "level",
        "variableName": "level"
      },
      {
        "kind": "Variable",
        "name": "translation",
        "variableName": "translation"
      }
    ],
    "concreteType": "StuSettingsUpdateaicsettingsOutput",
    "kind": "LinkedField",
    "name": "stu_settings_updateaicsettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StuSettingsUpdateaicsettingsdata",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_settings",
            "kind": "LinkedField",
            "name": "user_settings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aic_translate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aic_level",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aic_setsettings",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StuSettingsUpdateaicsettingsMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StuSettingsUpdateaicsettingsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e072a59aa4b2e8a2f9022acfec2cca92",
    "id": null,
    "metadata": {},
    "name": "StuSettingsUpdateaicsettingsMutation",
    "operationKind": "mutation",
    "text": "mutation StuSettingsUpdateaicsettingsMutation(\n  $translation: smallint!\n  $level: Int!\n) {\n  stu_settings_updateaicsettings(translation: $translation, level: $level) {\n    success\n    errors\n    data {\n      user_settings {\n        id\n        aic_translate\n        aic_level\n        aic_setsettings\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8c553ccffeb8cee1d445059e4cb708f";

export default node;
