/**
 * @generated SignedSource<<8f5d41ebc23ba396bfaa05331e3226bb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetGroupClassroomFragment$data = {
  readonly groupcl: any;
  readonly " $fragmentType": "GetGroupClassroomFragment";
};
export type GetGroupClassroomFragment$key = {
  readonly " $data"?: GetGroupClassroomFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetGroupClassroomFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetGroupClassroomFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupcl",
      "storageKey": null
    }
  ],
  "type": "subs",
  "abstractKey": null
};

(node as any).hash = "e610bf28dce2c8f93aa5406189afe17e";

export default node;
