/**
 * @generated SignedSource<<ab401bf791ee14168d898394c20a3a5c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ClientRequest, ClientQuery } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientonlySchemaQuery$variables = {};
export type ClientonlySchemaQuery$data = {
  readonly RelayAppSettings: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientonlySchemaHasinteractedFragment">;
  } | null;
};
export type ClientonlySchemaQuery = {
  response: ClientonlySchemaQuery$data;
  variables: ClientonlySchemaQuery$variables;
};

const node: ClientRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientonlySchemaQuery",
    "selections": [
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appsetting",
            "kind": "LinkedField",
            "name": "RelayAppSettings",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientonlySchemaHasinteractedFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClientonlySchemaQuery",
    "selections": [
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appsetting",
            "kind": "LinkedField",
            "name": "RelayAppSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GroupClassDetail",
                "kind": "LinkedField",
                "name": "groupClass",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasInteracted",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5878f032f84443e22dc2edc91eb5ad1a",
    "id": null,
    "metadata": {},
    "name": "ClientonlySchemaQuery",
    "operationKind": "query",
    "text": null
  }
};

(node as any).hash = "27cf77840571ca3c74a3867cadc3ff27";

export default node;
