import { graphql } from 'babel-plugin-relay/macro';

// This fragment contains pusher info i-e channel name and time remaining in lesson start and end 
export const ClassroomViewFragment = graphql`
  fragment ClassroomViewFragment on StuApptgroupClassroomenterOutput {
    dt {
      secondsUntilEnd
      secondsUntilStart
    }
    pusherChannel
  }
`;
