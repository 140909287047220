import React from 'react';
import {
  Grid2,
} from '@languageconvo/wcl';

export const LeftCol = ({ children }: any) => (
  <Grid2 xs={12} sm={6} md={6}>
    {children}
  </Grid2>
);

export const RightCol = ({ children }: any) => (
  <Grid2 xs={12} sm={6} md={6}>
    {children}
  </Grid2>
);

export const FullwidthCol = ({ children }: any) => (
  <Grid2 xs={12} md={10}>
    {children}
  </Grid2>
);
