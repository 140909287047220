import React, { Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { ProgressHome2 } from './ProgressHome2';
import { SkillsQuery } from './relay/Skills';

export const ProgressHome1 = () => {
  const response: any = useLazyLoadQuery(
    SkillsQuery,
    {}
  );

  return (
    // note that our loading state for now is just a blank page. it looks pretty good like that, but
    // in future we could create glimmers if we want
    <Suspense fallback={<span> </span>}>
      <ProgressHome2 fragmentRef={response} />
    </Suspense>
  );
};
