import { Box, Grid2, Skeleton } from '@languageconvo/wcl';
import React from 'react';

export const UpComingLessonLoader = () => (
  <Grid2 container spacing={2}>
    <Grid2 xs={12}>
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="text" width={100} height={20} />
      </Box>
    </Grid2>
    <Grid2 xs={12}>
      <Box display="flex" justifyContent="center">
        <Skeleton variant="rectangular" width={150} height={50} />
      </Box>
    </Grid2>
  </Grid2>
);
