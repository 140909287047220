import React from 'react';
import { Ic } from '../../datadisplay/Icon';
import { Props } from './interface';
import { StyledBackdrop } from './Backdrop.styles';

/**
 * Our main Backdrop component. See:
 *
 * https://mui.com/material-ui/react-backdrop/
 *
 * https://mui.com/material-ui/api/backdrop/
 */

export const Backdrop: React.FunctionComponent<Props> = (props) => {
  const {
    open = false,
    loading = false,
    cp = undefined,
    children,
  } = props;
  return (
    <StyledBackdrop
      open={open}
      {...cp}
    >
      {loading && <Ic iconName="circle-notch" animationType="spin" iconStyle="light" color="inherit" size="rel-lg" />}
      {children}
    </StyledBackdrop>
  );
};
