/**
 * @generated SignedSource<<8607583417d94942f4bfb2cc6525f171>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ClientRequest, ClientQuery } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StuSettingsGettrialdataClientQuery$variables = {};
export type StuSettingsGettrialdataClientQuery$data = {
  readonly RelayAppSettings: {
    readonly " $fragmentSpreads": FragmentRefs<"StuSettingsGettrialdataTeachersAvailibilityFragment" | "StuSettingsGettrialdataTrialFragment">;
  } | null;
};
export type StuSettingsGettrialdataClientQuery = {
  response: StuSettingsGettrialdataClientQuery$data;
  variables: StuSettingsGettrialdataClientQuery$variables;
};

const node: ClientRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StuSettingsGettrialdataClientQuery",
    "selections": [
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appsetting",
            "kind": "LinkedField",
            "name": "RelayAppSettings",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StuSettingsGettrialdataTrialFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StuSettingsGettrialdataTeachersAvailibilityFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StuSettingsGettrialdataClientQuery",
    "selections": [
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appsetting",
            "kind": "LinkedField",
            "name": "RelayAppSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TrialDataDetail",
                "kind": "LinkedField",
                "name": "trialData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasCredits",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrialDetail",
                    "kind": "LinkedField",
                    "name": "trial",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pastCompleted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasMissedTwo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasUpcoming",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StepsToScheduleDetail",
                        "kind": "LinkedField",
                        "name": "stepsToSchedule",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "step1",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "step2",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "atleastOneTeacherGivesTrials",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pastTrialExists",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PastTrialDetail",
                        "kind": "LinkedField",
                        "name": "pastTrial",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5488f147c111c6468c272657d3d79d1c",
    "id": null,
    "metadata": {},
    "name": "StuSettingsGettrialdataClientQuery",
    "operationKind": "query",
    "text": null
  }
};

(node as any).hash = "99f37094996368221dda26e3a7f26290";

export default node;
