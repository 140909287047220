import {
  Box, styled, WCLThemeProps,
} from '@languageconvo/wcl';
import MuxPlayer from '@mux/mux-player-react';

// to style each individual slide, we wrap the swiper in a generic div and then add
// swiper-slide css
export const SwiperContainerHowGroupClsWork = styled(Box)<WCLThemeProps>(({ theme }) => ({
  'swiper-slide': {
    backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentPurple1}`,
    borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
    // creates equal height containers, based on the height of the largest
    height: 'auto',
    padding: '20px',
    width: GroupOverViewClsSlideMeasurements.width,
    [theme!.breakpoints.down('sm')]: {
      width: GroupOverViewClsSlideMeasurements.mobileWidth,
    },
  },
}));

// the height and width of the slides for "groupClass overview" slider.
// these measures are used by the groupClass overview slider,so we define
// them centrally here
const h = 175;
const hPlusPadding = h + 16;
export const GroupOverViewClsSlideMeasurements = {
  height: `${h}px`,
  width: '270px',
  mobileWidth: '200px',
  heightPlusPadding: `${hPlusPadding}px`,
};

// mux video player styling
export const MuxPlayerCustom = styled(MuxPlayer)(() => ({
  height: '170px !important',
  width: 'auto',
  aspectRatio: 9 / 16,
}));
