import React, { Suspense } from 'react';
import {
  Grid2Ct, Grid2, Box,
} from '@languageconvo/wcl';
import { CardCustom, CardContents } from './MainContainer.style';
import { Setup0BasicData } from '../setup/Setup0BasicData';
import { Pageview } from '../pageview/Pageview';

interface Props {
  setmodalviewContents: any
  setmodalviewState: any
}

export const MainContainer = ({
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <Grid2Ct sx={{ mb: 1 }}>
      <Grid2 xs={12}>
        <Box display="flex" justifyContent="center">
          <CardCustom>
            <CardContents>
              {/* loading glimmer, errors, etc. this does not include countdown
                clock, group chat, and lecture. but will have views for everything else */}
              <Pageview />

              {/* main flow to connect to session */}
              {/* We need to add suspense on the parent component where we are executing our 
                   query 
              */}
              <Suspense>
                <Setup0BasicData
                  setmodalviewContents={setmodalviewContents}
                  setmodalviewState={setmodalviewState}
                />
              </Suspense>
            </CardContents>
          </CardCustom>
        </Box>
      </Grid2>
    </Grid2Ct>
  );
};
