import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Props } from './interface';

/**
 * Grid2Ct is simply "Grid2 container columns={12} spacing={2}".
 * We should use Grid2Ct throughout our app, rather than typing out
 * "Grid2 container columns={12} spacing={2}" for a few reasons. One, it's shorter to type.
 * Two, it allows us to have one central single place where we've set spacing.
 * We expose all of the MUI props, so this component can be used as an easy complete
 * replacement for Grid2 container.
 */
export const Grid2Ct: React.FunctionComponent<Props> = (props) => {
  const {
    children,
    classes,
    columns = 12,
    columnSpacing,
    component,
    direction,
    lg,
    md,
    rowSpacing,
    sm,
    spacing = 2,
    sx,
    wrap,
    xl,
    xs,
  } = props;

  return (
    <Grid2
      container
      classes={classes}
      columns={columns}
      columnSpacing={columnSpacing}
      component={component}
      direction={direction}
      lg={lg}
      md={md}
      rowSpacing={rowSpacing}
      sm={sm}
      spacing={spacing}
      sx={sx}
      wrap={wrap}
      xl={xl}
      xs={xs}
    >
      {children}
    </Grid2>
  );
};
