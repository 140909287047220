import { graphql } from 'babel-plugin-relay/macro';

export const RoomLayoutFragment = graphql`
  fragment RoomLayoutFragment on subscription_root {
    appt_group_connection(where: {uuid: { _eq: $lessonUuid }}) {
      edges {
        node {
          appt_group_layout {
            layout
          }
          appt_group_reservations {
            users {
              user_publicinfo {
                uuid
                display_firstname
                display_lastname
              }
            }
          }
        }
      }
    }
  }
`;
