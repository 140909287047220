import React from 'react';
import * as Sentry from '@sentry/react';
import { Alert, Ty } from '@languageconvo/wcl';
import { ErrorsStuSubsCreatecheckoutlinkMutation, ErrorsStuSubsCreateportalurlMutation } from '../Errors/Errors';

// here we display errors for two situations:
//  1. the user is not subscribed, and when we attempted to create a stripe checkout
//      link an error occurred
//  2. user is subscribed, and when we attempted to create a stripe portal link an
//      error occurred
export const ErrorComponent = (props: any) => {
  const { errorCode, groupClassroomSubscriptionData } = props;

  // the user is NOT subscribed. error occurred when we tried to create subscribe
  // checkout link
  if (
    groupClassroomSubscriptionData.groupcl === 0
    && (errorCode === ErrorsStuSubsCreatecheckoutlinkMutation.TryAgain
    || errorCode === ErrorsStuSubsCreatecheckoutlinkMutation.UnexpectedOrTimeout)
  ) {
    return (
      <Alert variant="standard" title="Oops! An Error Occurred" severity="error">
        <Ty removeMb>
          Our apologies, something went wrong. Please close this popup and try that
          again.
        </Ty>
      </Alert>
    );
  }

  // for subscribed users, if create portal url returned an error
  if (
    groupClassroomSubscriptionData.groupcl === 1
    && (errorCode === ErrorsStuSubsCreateportalurlMutation.TryAgain
    || errorCode === ErrorsStuSubsCreateportalurlMutation.UnexpectedOrTimeout)
  ) {
    return (
      <Alert variant="standard" title="Oops! An Error Occured" severity="error">
        <Ty removeMb>
          An error occurred, please reload the page. If you continue to see this error,
          please let us know!
        </Ty>
      </Alert>
    );
  }

  // both subscribed and not subscribed users, a NotAllowed error means their account
  // is suspended
  if (
    errorCode === ErrorsStuSubsCreateportalurlMutation.NotAllowed
    || errorCode === ErrorsStuSubsCreatecheckoutlinkMutation.NotAllowed
  ) {
    return (
      <Alert variant="standard" title="Oops! An Error Occured" severity="error">
        <Ty removeMb>
          Hmm, something went wrong. Please contact our customer service team.
        </Ty>
      </Alert>
    );
  }

  Sentry.captureException(
    new Error("Important! We get something from our backend that we don't expect"),
    {
      extra: {
        error: errorCode
      }
    }
  );

  return (
    <Alert variant="standard" title="Oops! An Error Occured" severity="error">
      <Ty removeMb>
        An error occurred, please try reloading the page. If you continue to see this error,
        *please* let us know!
      </Ty>
    </Alert>
  );
};
