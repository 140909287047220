/**
 * @generated SignedSource<<473acd3626347bc66f5e9cf722ea8430>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LessonTimeCalculationFragment$data = {
  readonly dt: {
    readonly current: string;
    readonly duration: number;
    readonly endsAt: string;
    readonly secondsUntilEnd: number;
    readonly secondsUntilStart: number;
    readonly startsAt: string;
  };
  readonly " $fragmentType": "LessonTimeCalculationFragment";
};
export type LessonTimeCalculationFragment$key = {
  readonly " $data"?: LessonTimeCalculationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LessonTimeCalculationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonTimeCalculationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StuApptgroupClassroomenterdt",
      "kind": "LinkedField",
      "name": "dt",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "current",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondsUntilEnd",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondsUntilStart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startsAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StuApptgroupClassroomenterOutput",
  "abstractKey": null
};

(node as any).hash = "b02773e85301a7122724c1b56b685c41";

export default node;
