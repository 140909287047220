import React, { useState, Suspense } from 'react';
import {
  Grid2, Grid2Ct, Ty, CardStandard, Dialog, Link, Ic, IcSvgList,
} from '@languageconvo/wcl';
import {
  NavLink
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useLazyLoadQuery } from 'react-relay';
import { sitedata } from '../../../utils/sitedata';
import { removeJWTFromLocalStorageAndLogoutUser } from '../../../utils/logout';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import { UserDetailsDataQuery } from './relay/UserDetailsData';
import { UserDetailsComponent, UserDetailsLoading, UserDetailsError } from './components/UserDetails';

export const SettingsHome = () => {
  // #region general setup

  // settings page link object
  const clickableTypeObjSettings = {
    to: sitedata.url.app.moreSettings,
    component: NavLink,
  };

  // my files link object
  const clickableTypeObjMyfiles = {
    to: sitedata.url.app.moreFiles,
    component: NavLink,
  };

  // audiovideo link object
  const clickableTypeObjAudiovideo = {
    href: sitedata.url.app.moreAvtest,
    newtab: true,
  };

  // when user clicks log out, this allows us to show the logout animation
  const { setLogoutInProgress } = useAuth();

  // when user changes light / dark mode
  const { onToggleMode } = useSettings();

  // #endregion

  // #region query data

  const fragmentRefUserDetails: any = useLazyLoadQuery(
    UserDetailsDataQuery,
    { }
  );

  // #endregion

  // #region customer service modal

  // customer service modal state. true=open, false=closed. closed by default
  const [custserveModalState, setCustserveModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleCustserveModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setCustserveModalState(true);
  };

  // modal close action
  const handleCustserveModalClose = () => {
    setCustserveModalState(false);
  };

  // #endregion

  return (
    <>
      {/* main content */}
      <Grid2Ct>
        <Grid2 xs={12} sm={10} smOffset={1} md={12} mdOffset={0} xl={10} xlOffset={1}>
          <Grid2Ct>
            {/* settings. includes the UserDetailsDataQuery */}
            <Grid2
              xs={12}
              md={6}
              // display flex so that we can get this card and the customer support card to be
              // equal heights
              sx={{
                display: 'flex',
              }}
            >
              <CardStandard
                titleText="Settings"
                titleIcon={IcSvgList.settings1}
                color="accentBlue1"
                clickableLink1={{ ...clickableTypeObjSettings }}
                // with cards, width=100% needed when using display:flex on parent
                cp={{ sx: { width: '100%' } }}
              >
                {/* we wrap the entire children/contents in a link so that it's also
                clickable, making it easy for the user to click almost anywhere in the card */}
                <Link
                  to={sitedata.url.app.moreSettings}
                  component={NavLink}
                  linkStyle="nostyle"
                >
                  <Grid2Ct>
                    <Grid2 xs={12}>
                      {/* if getting this data fails, we don't want the entire page to error
                          so we wrap in an error boundary and fallback component */}
                      <Sentry.ErrorBoundary fallback={<UserDetailsError />}>
                        <Suspense fallback={<UserDetailsLoading />}>
                          <UserDetailsComponent
                            fragmentRef={fragmentRefUserDetails}
                          />
                        </Suspense>
                      </Sentry.ErrorBoundary>
                    </Grid2>
                  </Grid2Ct>
                </Link>
              </CardStandard>
            </Grid2>

            {/* customer support */}
            <Grid2
              xs={12}
              md={6}
              // display flex so that we can get this card and the settings card to be
              // equal heights
              sx={{
                display: 'flex',
              }}
            >
              <CardStandard
                titleText="Customer Support"
                titleIcon={IcSvgList.customersupport1}
                color="accentGreen1"
                titleIconRight={0}
                onClickEntireCard={handleCustserveModalOpen}
                hovercursor="pointer"
                cp={{ sx: { width: '100%' } }}
              >
                <Ty cp={{ sx: { mt: 2 } }} removeMb>
                  Click here to learn how to get help from our awesome customer support team!
                </Ty>
              </CardStandard>
            </Grid2>

            {/* dark / light mode */}
            <Grid2 xs={12} md={6}>
              <CardStandard
                titleText="Dark / Light Mode"
                titleIcon={IcSvgList.sun1}
                color="accentYellow1"
                titleIconRight={0}
                titleRemoveMb
                hovercursor="pointer"
                onClickEntireCard={onToggleMode}
              />
            </Grid2>

            {/* my files */}
            <Grid2 xs={12} md={6}>
              <CardStandard
                titleText="My Files"
                titleIcon={IcSvgList.folder1}
                color="accentOrange1"
                titleRemoveMb
                clickableLink1={{ ...clickableTypeObjMyfiles }}
              />
            </Grid2>

            {/* audio / video test */}
            <Grid2 xs={12} md={6}>
              <CardStandard
                titleText="Audio / Video Test"
                titleIcon={IcSvgList.soundmixer1}
                color="accentPurple1"
                clickableLink2={{ ...clickableTypeObjAudiovideo }}
                titleRemoveMb
              />
            </Grid2>

            {/* log out */}
            <Grid2 xs={12} md={6}>
              <CardStandard
                titleText="Log Out"
                titleIcon={IcSvgList.exit1}
                color="accentRed1"
                titleRemoveMb
                hovercursor="pointer"
                titleIconRight={0}
                onClickEntireCard={
                  () => removeJWTFromLocalStorageAndLogoutUser(setLogoutInProgress)
                }
              />
            </Grid2>
          </Grid2Ct>
        </Grid2>
      </Grid2Ct>

      {/* modal - customer service */}
      <Dialog
        isOpen={custserveModalState}
        onClose={handleCustserveModalClose}
        width="md"
        color="accentGreen1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty v="h2New"><Ic iconName="comment" iconStyle="duotone" color="accentPurple1" />&nbsp;&nbsp;Live Chat - Fastest Option</Ty>
            <Ty>
              In the bottom-right corner of this page, you should see a chat bubble. Click that to
              chat with a real person on customer support team, any time! It also has our help
              center articles built in.
            </Ty>
            <Ty v="h2New" cp={{ sx: { mt: 3 } }}><Ic iconName="triangle-exclamation" iconStyle="duotone" color="accentRed1" />&nbsp;&nbsp;What if you don&apos;t see the chat support bubble?</Ty>
            <Ty>
              Try using Google Chrome in
              &quot;Incognito&quot; mode, or disable your adblocker extension in your
              browser (Google Chrome, Firefox, Safari, etc.).

              The reason you can&apos;t see our chat support bubble is an extension that you have
              added to your browser &ndash; usually an adblocker extension &ndash; is blocking
              our chat support widget from functioning.
            </Ty>
            <Ty v="h2New" cp={{ sx: { mt: 3 } }}><Ic iconName="envelope" iconStyle="duotone" color="accentYellow1" />&nbsp;&nbsp;Email Support</Ty>
            <Ty>
              Send an email to contact@languageconvo.com and we will get back to
              you as soon as possible! Please let us know the email address of your account
              so that we can find your account details.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};
