import React from 'react';

export const PageError = () => {
  throw new Error('Ignore - test/pageerror testing page');

  // eslint-disable-next-line
  return (
    <>hello world</>
  );
};
