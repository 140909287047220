import { Badge, useTheme } from '@mui/material';
import { AvatarIconContainer } from './index.style';
import { AvatarIconProps } from './interface';
import { Ic } from '../Icon';

/**
 * This avatar icon is used heavily throughout the app to denote that a popover is available; clicking on the icon
 * displays the popover.
 *
 * https://mui.com/material-ui/react-avatar/
 *
 * https://mui.com/material-ui/api/avatar/
 */
export const AvatarIcon = (props: AvatarIconProps) => {
  const {
    backgroundColor = 'primary',
    hideBadge = true,
    handleOpen,
    iconName = 'microphone-stand',
  } = props;
  const theme = useTheme();
  return (
    <Badge color={backgroundColor} variant="dot" invisible={hideBadge}>
      <AvatarIconContainer
        theme={theme}
        variant="rounded"
        aria-controls="menu-list-grow"
        aria-haspopup="true"
        backgroundColor={backgroundColor}
        onClick={handleOpen}
      >
        <Ic
          iconName={iconName}
          size="rel-sm"
        />
      </AvatarIconContainer>
    </Badge>
  );
};
