import React, { useLayoutEffect, useContext } from 'react';
import { useFragment } from 'react-relay';
import { Setup1MicAccess } from './Setup1MicAccess';
import { ClientonlySchemaHasinteractedFragment } from '../../relay/ClientonlySchema';
import { ClientonlySchemaHasinteractedFragment$key } from '../../relay/__generated__/ClientonlySchemaHasinteractedFragment.graphql';
import { ClassroomSetupContext } from '../../GroupClassroom';

interface Props {
  clientDt: ClientonlySchemaHasinteractedFragment$key
  setmodalviewContents: any
  setmodalviewState: any
}

// if the user needs to interact with the app (likely because they refreshed the
// classroom page) show a button to allow them to do so. this is needed to ensure
// autoplay works
export const Setup0BasicData2Hasinteracted = ({
  clientDt,
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  const csSetupContext = useContext(ClassroomSetupContext);
  const clientonlyData = useFragment(ClientonlySchemaHasinteractedFragment, clientDt);

  // We need to determine if the user has interacted with the app (clicked on something)
  // at least once. If they haven't, then autoplay won't work -- meaning that if we're
  // in lecture mode, the user won't be able to hear the teacher. We use a custom
  // relay client value, RelayAppSettings.groupClass.hasInteracted; when the app
  // first loads, it's set to false. If user clicks "join!" group class button, it's
  // set to true. If false, we need to show the user a button to click, to "force"
  // the user to make an interaction with the app
  useLayoutEffect(() => {
    if (!clientonlyData.groupClass?.hasInteracted) {
      csSetupContext.setPageviewState(202);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientonlyData.groupClass?.hasInteracted]);

  // dont allow setup steps to continue
  if (clientonlyData.groupClass?.hasInteracted !== true) {
    return <div />;
  }

  return (
    <Setup1MicAccess
      setmodalviewContents={setmodalviewContents}
      setmodalviewState={setmodalviewState}
    />
  );
};
