import React, { Dispatch, SetStateAction } from 'react';
import {
  Button, Dialog, Grid2, Grid2Ct, Ty
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { commitLocalUpdate, useMutation } from 'react-relay';
import { StuApptScheduletrialMutation } from './relay/StuApptScheduletrial';
import { useFavoriteTeacher } from '../../../../../../../../common/hooks/teachers/favorites/useFavoriteTeacher';
import AppEnvironment from '../../../../../../../../relay/AppEnvironment';
import { RelayFavoritedVals } from '../../../../../../../../common/relay/resolvers/teachers/relayFavorited';

interface Step3ConfirmationModalProps {
  openModal: boolean
  handleCloseModal: () => void
  selectedSlot: any
  mutationCallToGetAvailabilityData: () => void
  setSchedData: Dispatch<SetStateAction<any>>
  setSchedulingError: Dispatch<SetStateAction<string>>
}

export const Step3ConfirmationModal = ({
  openModal,
  handleCloseModal,
  selectedSlot,
  mutationCallToGetAvailabilityData,
  setSchedData,
  setSchedulingError
}: Step3ConfirmationModalProps) => {
  const [TheTrialScheduleMutation, isInFlight] = useMutation(StuApptScheduletrialMutation);

  // need to make this teacher as favourite
  const { favoriteTeacher } = useFavoriteTeacher();
  const makeTeacherFav = () => {
    // we need the teacher's relay id, which we get here
    let thisTchRelayId: string | undefined = '';
    commitLocalUpdate(AppEnvironment, (store: any) => {
      // TODO: this next line is VERY fragile. if anyone edits our applevel query and forgets
      // to also udpate this here, we will not be adding teachers to a students favorites
      // via this legacy event. long term, we will get rid of old legacy app scheduling. but
      // in the short term, we could if we want to make this much more robust, write a
      // fragment and query for this component that gets the teacher's relay id
      const root = store.get('client:root:resources_connection(where:{"tutor_visibility":{"_eq":"1"},"working_status":{"_is_null":true}})');
      const edges = root?.getLinkedRecords('edges') || [];
      edges.forEach((edge: any) => {
        const node = edge.getLinkedRecord('node');
        const currentUuid = node?.getValue('uuid');
        const currentId = node?.getValue('id');
        if (currentUuid === selectedSlot.tchUuid) {
          thisTchRelayId = currentId?.toString();
        }
      });
    });

    // need to make that teacher as fav of this user.
    // our hook that actually adds the teacher as a favorite. note that we need to pass
    // the CURRENT value of "is this teacher my favorite?" which is no, thus the reason
    // RelayFavoritedVals.NotFavorite is the first argument
    favoriteTeacher(
      RelayFavoritedVals.NotFavorite,
      selectedSlot.tchUuid,
      thisTchRelayId,
    );
  };

  // this function will execute the mutation call when user clicks on confirm button
  const handleClickConfirmButton = () => {
    TheTrialScheduleMutation({
      variables: {
        unixTs: selectedSlot.unixTs,
        tchUuid: selectedSlot.tchUuid
      },
      onCompleted: () => {
        handleCloseModal();
        makeTeacherFav();
      },
      onError(error) {
        const errObject = JSON.parse(error.message);
        // TODO: need to decide should we log this error to sentry?
        if (errObject.extensions.code === 'SchedulingConflict') {
          // setting the empty array in schedule data so the glimmer display imediately,
          setSchedData([]);
          // this state is responsible for displaying alert to user,
          // making it true will display the alert in UI
          setSchedulingError(errObject.extensions.code);
          // getting the schedule data again so we can show user the updated data
          mutationCallToGetAvailabilityData();
        } else if (errObject.extensions.code === 'AlreadyScheduled') {
          setSchedulingError(errObject.extensions.code);
        } else {
          Sentry.captureException(error);
          setSchedulingError('GeneralError');
        }
        handleCloseModal();
      },
    });
  };

  return (
    <Dialog
      isOpen={openModal}
      onClose={handleCloseModal}
      width="xs"
      color="accentGreen1"
    >
      <Grid2Ct>
        <Grid2 xs={12}>
          <Ty>You are about to schedule
            a&nbsp;<strong>real lesson</strong>&nbsp;with
            a&nbsp;<strong>real, professional teacher</strong>!
            <br /><br />
            Please be considerate of them and carefully check the date and time:
          </Ty>

          <Ty align="center" cp={{ sx: { mt: 2 } }}>
            <strong>
              {DateTime.fromSeconds(selectedSlot?.unixTs).toLocaleString({
                weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
              })}
            </strong>
          </Ty>
        </Grid2>
      </Grid2Ct>

      <Grid2Ct sx={{ mt: 3 }}>
        <Grid2 xs={6}>
          <Button color="accentRed1" fullWidth onClick={handleCloseModal}>Go Back</Button>
        </Grid2>
        <Grid2 xs={6}>
          <Button color="accentGreen1" fullWidth onClick={handleClickConfirmButton} disabled={isInFlight} isLoading={isInFlight}> Schedule! </Button>
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};
