import { graphql } from 'babel-plugin-relay/macro';

export const UpdateEmailPreferences = graphql`
mutation UpdateEmailPreferencesMutation(
  $userRandomId: String!,
  $updateAnnouncements: smallint!,
  $messagesTeacher: smallint!,
  $messagesCoach: smallint!,
  $gettingStartedTips: smallint!,
  $unsubscribedAll: smallint!,
  $accountNotices: smallint!,
  $newTeacherAvailable: smallint!,
) {
  users_emailunsub_insert(
    objects: {
      user_random_id: $userRandomId,
      update_announcements: $updateAnnouncements,
      messages_teacher: $messagesTeacher,
      messages_coach: $messagesCoach,
      getting_started_tips: $gettingStartedTips,
      unsubscribed_all: $unsubscribedAll,
      account_notices: $accountNotices,
      new_teacher_available: $newTeacherAvailable
    },
    on_conflict: {
      constraint: users_emailunsub_user_random_id_key,
      update_columns: [
        update_announcements,
        messages_teacher,
        messages_coach,
        getting_started_tips,
        unsubscribed_all,
        account_notices,
        new_teacher_available
      ]
    }
  ) {
    affected_rows
  }
}
`;
