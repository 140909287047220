import { graphql } from 'babel-plugin-relay/macro';

export const StuApptgroupCancelresvMutation = graphql`
  mutation StuApptgroupCancelresvMutation($lessonUuid: String!) {
    stu_apptgroup_cancelresv(lessonUuid: $lessonUuid) {
      data {
        code
      }
      success
    }
  }
`;
