import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { PrivateUpcomingDataHomepgQuery } from '../../../../relay/posttrial/PrivateUpcomingData';
import { PrivateUpcomingSlider } from './PrivateUpcoming2Slider';
import { PrivateUpcomingEmpty } from './PrivateUpcoming2Empty';

interface Props {
   // current unix timestamp. used to get lessons ending in the future
  currentTmsp: number,
}

export const PrivateUpcomingGetdata = ({ currentTmsp }: Props) => {
  // We must *always* go to the network to get upcoming lessons. The reason being, our
  // scheduling pages are in our legacy app (iframes), so we do not get scheduled lesson
  // data in relay
  const response: any = useLazyLoadQuery(
    PrivateUpcomingDataHomepgQuery,
    { currentTs: currentTmsp },
    {
      fetchPolicy: 'network-only',
      // fetchKey is VERY IMPORTANT -- it must be set to a unique value to ensure we always
      // go to the network to get fresh data
      fetchKey: currentTmsp,
    },
  );

  // list of upcoming lessons
  const { Upcomingregular } = response;
  // user data for display upcoming lessons
  const { users_connection } = response;

  // here we're checking to see if there is at least one upcoming lesson
  const { Anyupcoming } = response;
  const anyUpcomingLessons = Anyupcoming.edges.length > 0;

  return (
    <>
      {/* if there's at least one upcoming lesson, show the slider. if not, show a note about
       "go schedule a lesson!" */}
      { anyUpcomingLessons ? (
        <PrivateUpcomingSlider lsn={Upcomingregular} usr={users_connection} />
      ) : (<PrivateUpcomingEmpty />)}
    </>
  );
};
