export const tz = [
  {
    id: 1,
    nameDb: 'Pacific/Honolulu',
    nameDisplay: 'US - Hawaiian (HST)'
  },
  {
    id: 2,
    nameDb: 'America/Anchorage',
    nameDisplay: 'US - Alaskan'
  },
  {
    id: 3,
    nameDb: 'America/Los_Angeles',
    nameDisplay: 'US - Pacific'
  },
  {
    id: 4,
    nameDb: 'America/Denver',
    nameDisplay: 'US - Mountain'
  },
  {
    id: 5,
    nameDb: 'America/Phoenix',
    nameDisplay: 'US - Arizona (No Daylight Savings)'
  },
  {
    id: 6,
    nameDb: 'America/Chicago',
    nameDisplay: 'US - Central'
  },
  {
    id: 7,
    nameDb: 'America/New_York',
    nameDisplay: 'US - Eastern'
  },
  {
    id: 8,
    nameDb: 'Europe/London',
    nameDisplay: 'United Kingdom'
  },
  {
    id: 9,
    nameDb: 'Africa/Abidjan',
    nameDisplay: 'Africa/Abidjan'
  },
  {
    id: 10,
    nameDb: 'Africa/Accra',
    nameDisplay: 'Africa/Accra'
  },
  {
    id: 11,
    nameDb: 'Africa/Addis_Ababa',
    nameDisplay: 'Africa/Addis_Ababa'
  },
  {
    id: 12,
    nameDb: 'Africa/Algiers',
    nameDisplay: 'Africa/Algiers'
  },
  {
    id: 13,
    nameDb: 'Africa/Asmara',
    nameDisplay: 'Africa/Asmara'
  },
  {
    id: 14,
    nameDb: 'Africa/Bamako',
    nameDisplay: 'Africa/Bamako'
  },
  {
    id: 15,
    nameDb: 'Africa/Bangui',
    nameDisplay: 'Africa/Bangui'
  },
  {
    id: 16,
    nameDb: 'Africa/Banjul',
    nameDisplay: 'Africa/Banjul'
  },
  {
    id: 17,
    nameDb: 'Africa/Bissau',
    nameDisplay: 'Africa/Bissau'
  },
  {
    id: 18,
    nameDb: 'Africa/Blantyre',
    nameDisplay: 'Africa/Blantyre'
  },
  {
    id: 19,
    nameDb: 'Africa/Brazzaville',
    nameDisplay: 'Africa/Brazzaville'
  },
  {
    id: 20,
    nameDb: 'Africa/Bujumbura',
    nameDisplay: 'Africa/Bujumbura'
  },
  {
    id: 21,
    nameDb: 'Africa/Cairo',
    nameDisplay: 'Africa/Cairo'
  },
  {
    id: 22,
    nameDb: 'Africa/Casablanca',
    nameDisplay: 'Africa/Casablanca'
  },
  {
    id: 23,
    nameDb: 'Africa/Ceuta',
    nameDisplay: 'Africa/Ceuta'
  },
  {
    id: 24,
    nameDb: 'Africa/Conakry',
    nameDisplay: 'Africa/Conakry'
  },
  {
    id: 25,
    nameDb: 'Africa/Dakar',
    nameDisplay: 'Africa/Dakar'
  },
  {
    id: 26,
    nameDb: 'Africa/Dar_es_Salaam',
    nameDisplay: 'Africa/Dar_es_Salaam'
  },
  {
    id: 27,
    nameDb: 'Africa/Djibouti',
    nameDisplay: 'Africa/Djibouti'
  },
  {
    id: 28,
    nameDb: 'Africa/Douala',
    nameDisplay: 'Africa/Douala'
  },
  {
    id: 29,
    nameDb: 'Africa/El_Aaiun',
    nameDisplay: 'Africa/El_Aaiun'
  },
  {
    id: 30,
    nameDb: 'Africa/Freetown',
    nameDisplay: 'Africa/Freetown'
  },
  {
    id: 31,
    nameDb: 'Africa/Gaborone',
    nameDisplay: 'Africa/Gaborone'
  },
  {
    id: 32,
    nameDb: 'Africa/Harare',
    nameDisplay: 'Africa/Harare'
  },
  {
    id: 33,
    nameDb: 'Africa/Johannesburg',
    nameDisplay: 'Africa/Johannesburg'
  },
  {
    id: 34,
    nameDb: 'Africa/Juba',
    nameDisplay: 'Africa/Juba'
  },
  {
    id: 35,
    nameDb: 'Africa/Kampala',
    nameDisplay: 'Africa/Kampala'
  },
  {
    id: 36,
    nameDb: 'Africa/Khartoum',
    nameDisplay: 'Africa/Khartoum'
  },
  {
    id: 37,
    nameDb: 'Africa/Kigali',
    nameDisplay: 'Africa/Kigali'
  },
  {
    id: 38,
    nameDb: 'Africa/Kinshasa',
    nameDisplay: 'Africa/Kinshasa'
  },
  {
    id: 39,
    nameDb: 'Africa/Lagos',
    nameDisplay: 'Africa/Lagos'
  },
  {
    id: 40,
    nameDb: 'Africa/Libreville',
    nameDisplay: 'Africa/Libreville'
  },
  {
    id: 41,
    nameDb: 'Africa/Lome',
    nameDisplay: 'Africa/Lome'
  },
  {
    id: 42,
    nameDb: 'Africa/Luanda',
    nameDisplay: 'Africa/Luanda'
  },
  {
    id: 43,
    nameDb: 'Africa/Lubumbashi',
    nameDisplay: 'Africa/Lubumbashi'
  },
  {
    id: 44,
    nameDb: 'Africa/Lusaka',
    nameDisplay: 'Africa/Lusaka'
  },
  {
    id: 45,
    nameDb: 'Africa/Malabo',
    nameDisplay: 'Africa/Malabo'
  },
  {
    id: 46,
    nameDb: 'Africa/Maputo',
    nameDisplay: 'Africa/Maputo'
  },
  {
    id: 47,
    nameDb: 'Africa/Maseru',
    nameDisplay: 'Africa/Maseru'
  },
  {
    id: 48,
    nameDb: 'Africa/Mbabane',
    nameDisplay: 'Africa/Mbabane'
  },
  {
    id: 49,
    nameDb: 'Africa/Mogadishu',
    nameDisplay: 'Africa/Mogadishu'
  },
  {
    id: 50,
    nameDb: 'Africa/Monrovia',
    nameDisplay: 'Africa/Monrovia'
  },
  {
    id: 51,
    nameDb: 'Africa/Nairobi',
    nameDisplay: 'Africa/Nairobi'
  },
  {
    id: 52,
    nameDb: 'Africa/Ndjamena',
    nameDisplay: 'Africa/Ndjamena'
  },
  {
    id: 53,
    nameDb: 'Africa/Niamey',
    nameDisplay: 'Africa/Niamey'
  },
  {
    id: 54,
    nameDb: 'Africa/Nouakchott',
    nameDisplay: 'Africa/Nouakchott'
  },
  {
    id: 55,
    nameDb: 'Africa/Ouagadougou',
    nameDisplay: 'Africa/Ouagadougou'
  },
  {
    id: 56,
    nameDb: 'Africa/Porto-Novo',
    nameDisplay: 'Africa/Porto-Novo'
  },
  {
    id: 57,
    nameDb: 'Africa/Sao_Tome',
    nameDisplay: 'Africa/Sao_Tome'
  },
  {
    id: 58,
    nameDb: 'Africa/Tripoli',
    nameDisplay: 'Africa/Tripoli'
  },
  {
    id: 59,
    nameDb: 'Africa/Tunis',
    nameDisplay: 'Africa/Tunis'
  },
  {
    id: 60,
    nameDb: 'Africa/Windhoek',
    nameDisplay: 'Africa/Windhoek'
  },
  {
    id: 61,
    nameDb: 'America/Adak',
    nameDisplay: 'America/Adak'
  },
  {
    id: 62,
    nameDb: 'America/Anguilla',
    nameDisplay: 'America/Anguilla'
  },
  {
    id: 63,
    nameDb: 'America/Antigua',
    nameDisplay: 'America/Antigua'
  },
  {
    id: 64,
    nameDb: 'America/Araguaina',
    nameDisplay: 'America/Araguaina'
  },
  {
    id: 65,
    nameDb: 'America/Argentina/Buenos_Aires',
    nameDisplay: 'America/Argentina/Buenos_Aires'
  },
  {
    id: 66,
    nameDb: 'America/Argentina/Catamarca',
    nameDisplay: 'America/Argentina/Catamarca'
  },
  {
    id: 67,
    nameDb: 'America/Argentina/Cordoba',
    nameDisplay: 'America/Argentina/Cordoba'
  },
  {
    id: 68,
    nameDb: 'America/Argentina/Jujuy',
    nameDisplay: 'America/Argentina/Jujuy'
  },
  {
    id: 69,
    nameDb: 'America/Argentina/La_Rioja',
    nameDisplay: 'America/Argentina/La_Rioja'
  },
  {
    id: 70,
    nameDb: 'America/Argentina/Mendoza',
    nameDisplay: 'America/Argentina/Mendoza'
  },
  {
    id: 71,
    nameDb: 'America/Argentina/Rio_Gallegos',
    nameDisplay: 'America/Argentina/Rio_Gallegos'
  },
  {
    id: 72,
    nameDb: 'America/Argentina/Salta',
    nameDisplay: 'America/Argentina/Salta'
  },
  {
    id: 73,
    nameDb: 'America/Argentina/San_Juan',
    nameDisplay: 'America/Argentina/San_Juan'
  },
  {
    id: 74,
    nameDb: 'America/Argentina/San_Luis',
    nameDisplay: 'America/Argentina/San_Luis'
  },
  {
    id: 75,
    nameDb: 'America/Argentina/Tucuman',
    nameDisplay: 'America/Argentina/Tucuman'
  },
  {
    id: 76,
    nameDb: 'America/Argentina/Ushuaia',
    nameDisplay: 'America/Argentina/Ushuaia'
  },
  {
    id: 77,
    nameDb: 'America/Aruba',
    nameDisplay: 'America/Aruba'
  },
  {
    id: 78,
    nameDb: 'America/Asuncion',
    nameDisplay: 'America/Asuncion'
  },
  {
    id: 79,
    nameDb: 'America/Atikokan',
    nameDisplay: 'America/Atikokan'
  },
  {
    id: 80,
    nameDb: 'America/Bahia',
    nameDisplay: 'America/Bahia'
  },
  {
    id: 81,
    nameDb: 'America/Bahia_Banderas',
    nameDisplay: 'America/Bahia_Banderas'
  },
  {
    id: 82,
    nameDb: 'America/Barbados',
    nameDisplay: 'America/Barbados'
  },
  {
    id: 83,
    nameDb: 'America/Belem',
    nameDisplay: 'America/Belem'
  },
  {
    id: 84,
    nameDb: 'America/Belize',
    nameDisplay: 'America/Belize'
  },
  {
    id: 85,
    nameDb: 'America/Blanc-Sablon',
    nameDisplay: 'America/Blanc-Sablon'
  },
  {
    id: 86,
    nameDb: 'America/Boa_Vista',
    nameDisplay: 'America/Boa_Vista'
  },
  {
    id: 87,
    nameDb: 'America/Bogota',
    nameDisplay: 'America/Bogota'
  },
  {
    id: 88,
    nameDb: 'America/Boise',
    nameDisplay: 'America/Boise'
  },
  {
    id: 89,
    nameDb: 'America/Cambridge_Bay',
    nameDisplay: 'America/Cambridge_Bay'
  },
  {
    id: 90,
    nameDb: 'America/Campo_Grande',
    nameDisplay: 'America/Campo_Grande'
  },
  {
    id: 91,
    nameDb: 'America/Cancun',
    nameDisplay: 'America/Cancun'
  },
  {
    id: 92,
    nameDb: 'America/Caracas',
    nameDisplay: 'America/Caracas'
  },
  {
    id: 93,
    nameDb: 'America/Cayenne',
    nameDisplay: 'America/Cayenne'
  },
  {
    id: 94,
    nameDb: 'America/Cayman',
    nameDisplay: 'America/Cayman'
  },
  {
    id: 95,
    nameDb: 'America/Chihuahua',
    nameDisplay: 'America/Chihuahua'
  },
  {
    id: 96,
    nameDb: 'America/Costa_Rica',
    nameDisplay: 'America/Costa_Rica'
  },
  {
    id: 97,
    nameDb: 'America/Creston',
    nameDisplay: 'America/Creston'
  },
  {
    id: 98,
    nameDb: 'America/Cuiaba',
    nameDisplay: 'America/Cuiaba'
  },
  {
    id: 99,
    nameDb: 'America/Curacao',
    nameDisplay: 'America/Curacao'
  },
  {
    id: 100,
    nameDb: 'America/Danmarkshavn',
    nameDisplay: 'America/Danmarkshavn'
  },
  {
    id: 101,
    nameDb: 'America/Dawson',
    nameDisplay: 'America/Dawson'
  },
  {
    id: 102,
    nameDb: 'America/Dawson_Creek',
    nameDisplay: 'America/Dawson_Creek'
  },
  {
    id: 103,
    nameDb: 'America/Detroit',
    nameDisplay: 'America/Detroit'
  },
  {
    id: 104,
    nameDb: 'America/Dominica',
    nameDisplay: 'America/Dominica'
  },
  {
    id: 105,
    nameDb: 'America/Edmonton',
    nameDisplay: 'America/Edmonton'
  },
  {
    id: 106,
    nameDb: 'America/Eirunepe',
    nameDisplay: 'America/Eirunepe'
  },
  {
    id: 107,
    nameDb: 'America/El_Salvador',
    nameDisplay: 'America/El_Salvador'
  },
  {
    id: 108,
    nameDb: 'America/Fort_Nelson',
    nameDisplay: 'America/Fort_Nelson'
  },
  {
    id: 109,
    nameDb: 'America/Fortaleza',
    nameDisplay: 'America/Fortaleza'
  },
  {
    id: 110,
    nameDb: 'America/Glace_Bay',
    nameDisplay: 'America/Glace_Bay'
  },
  {
    id: 111,
    nameDb: 'America/Goose_Bay',
    nameDisplay: 'America/Goose_Bay'
  },
  {
    id: 112,
    nameDb: 'America/Grand_Turk',
    nameDisplay: 'America/Grand_Turk'
  },
  {
    id: 113,
    nameDb: 'America/Grenada',
    nameDisplay: 'America/Grenada'
  },
  {
    id: 114,
    nameDb: 'America/Guadeloupe',
    nameDisplay: 'America/Guadeloupe'
  },
  {
    id: 115,
    nameDb: 'America/Guatemala',
    nameDisplay: 'America/Guatemala'
  },
  {
    id: 116,
    nameDb: 'America/Guayaquil',
    nameDisplay: 'America/Guayaquil'
  },
  {
    id: 117,
    nameDb: 'America/Guyana',
    nameDisplay: 'America/Guyana'
  },
  {
    id: 118,
    nameDb: 'America/Halifax',
    nameDisplay: 'America/Halifax'
  },
  {
    id: 119,
    nameDb: 'America/Havana',
    nameDisplay: 'America/Havana'
  },
  {
    id: 120,
    nameDb: 'America/Hermosillo',
    nameDisplay: 'America/Hermosillo'
  },
  {
    id: 121,
    nameDb: 'America/Indiana/Indianapolis',
    nameDisplay: 'America/Indiana/Indianapolis'
  },
  {
    id: 122,
    nameDb: 'America/Indiana/Knox',
    nameDisplay: 'America/Indiana/Knox'
  },
  {
    id: 123,
    nameDb: 'America/Indiana/Marengo',
    nameDisplay: 'America/Indiana/Marengo'
  },
  {
    id: 124,
    nameDb: 'America/Indiana/Petersburg',
    nameDisplay: 'America/Indiana/Petersburg'
  },
  {
    id: 125,
    nameDb: 'America/Indiana/Tell_City',
    nameDisplay: 'America/Indiana/Tell_City'
  },
  {
    id: 126,
    nameDb: 'America/Indiana/Vevay',
    nameDisplay: 'America/Indiana/Vevay'
  },
  {
    id: 127,
    nameDb: 'America/Indiana/Vincennes',
    nameDisplay: 'America/Indiana/Vincennes'
  },
  {
    id: 128,
    nameDb: 'America/Indiana/Winamac',
    nameDisplay: 'America/Indiana/Winamac'
  },
  {
    id: 129,
    nameDb: 'America/Inuvik',
    nameDisplay: 'America/Inuvik'
  },
  {
    id: 130,
    nameDb: 'America/Iqaluit',
    nameDisplay: 'America/Iqaluit'
  },
  {
    id: 131,
    nameDb: 'America/Jamaica',
    nameDisplay: 'America/Jamaica'
  },
  {
    id: 132,
    nameDb: 'America/Juneau',
    nameDisplay: 'America/Juneau'
  },
  {
    id: 133,
    nameDb: 'America/Kentucky/Louisville',
    nameDisplay: 'America/Kentucky/Louisville'
  },
  {
    id: 134,
    nameDb: 'America/Kentucky/Monticello',
    nameDisplay: 'America/Kentucky/Monticello'
  },
  {
    id: 135,
    nameDb: 'America/Kralendijk',
    nameDisplay: 'America/Kralendijk'
  },
  {
    id: 136,
    nameDb: 'America/La_Paz',
    nameDisplay: 'America/La_Paz'
  },
  {
    id: 137,
    nameDb: 'America/Lima',
    nameDisplay: 'America/Lima'
  },
  {
    id: 138,
    nameDb: 'America/Lower_Princes',
    nameDisplay: 'America/Lower_Princes'
  },
  {
    id: 139,
    nameDb: 'America/Maceio',
    nameDisplay: 'America/Maceio'
  },
  {
    id: 140,
    nameDb: 'America/Managua',
    nameDisplay: 'America/Managua'
  },
  {
    id: 141,
    nameDb: 'America/Manaus',
    nameDisplay: 'America/Manaus'
  },
  {
    id: 142,
    nameDb: 'America/Marigot',
    nameDisplay: 'America/Marigot'
  },
  {
    id: 143,
    nameDb: 'America/Martinique',
    nameDisplay: 'America/Martinique'
  },
  {
    id: 144,
    nameDb: 'America/Matamoros',
    nameDisplay: 'America/Matamoros'
  },
  {
    id: 145,
    nameDb: 'America/Mazatlan',
    nameDisplay: 'America/Mazatlan'
  },
  {
    id: 146,
    nameDb: 'America/Menominee',
    nameDisplay: 'America/Menominee'
  },
  {
    id: 147,
    nameDb: 'America/Merida',
    nameDisplay: 'America/Merida'
  },
  {
    id: 148,
    nameDb: 'America/Metlakatla',
    nameDisplay: 'America/Metlakatla'
  },
  {
    id: 149,
    nameDb: 'America/Mexico_City',
    nameDisplay: 'America/Mexico_City'
  },
  {
    id: 150,
    nameDb: 'America/Miquelon',
    nameDisplay: 'America/Miquelon'
  },
  {
    id: 151,
    nameDb: 'America/Moncton',
    nameDisplay: 'America/Moncton'
  },
  {
    id: 152,
    nameDb: 'America/Monterrey',
    nameDisplay: 'America/Monterrey'
  },
  {
    id: 153,
    nameDb: 'America/Montevideo',
    nameDisplay: 'America/Montevideo'
  },
  {
    id: 154,
    nameDb: 'America/Montserrat',
    nameDisplay: 'America/Montserrat'
  },
  {
    id: 155,
    nameDb: 'America/Nassau',
    nameDisplay: 'America/Nassau'
  },
  {
    id: 156,
    nameDb: 'America/Nipigon',
    nameDisplay: 'America/Nipigon'
  },
  {
    id: 157,
    nameDb: 'America/Nome',
    nameDisplay: 'America/Nome'
  },
  {
    id: 158,
    nameDb: 'America/Noronha',
    nameDisplay: 'America/Noronha'
  },
  {
    id: 159,
    nameDb: 'America/North_Dakota/Beulah',
    nameDisplay: 'America/North_Dakota/Beulah'
  },
  {
    id: 160,
    nameDb: 'America/North_Dakota/Center',
    nameDisplay: 'America/North_Dakota/Center'
  },
  {
    id: 161,
    nameDb: 'America/North_Dakota/New_Salem',
    nameDisplay: 'America/North_Dakota/New_Salem'
  },
  {
    id: 162,
    nameDb: 'America/Nuuk',
    nameDisplay: 'America/Nuuk'
  },
  {
    id: 163,
    nameDb: 'America/Ojinaga',
    nameDisplay: 'America/Ojinaga'
  },
  {
    id: 164,
    nameDb: 'America/Panama',
    nameDisplay: 'America/Panama'
  },
  {
    id: 165,
    nameDb: 'America/Pangnirtung',
    nameDisplay: 'America/Pangnirtung'
  },
  {
    id: 166,
    nameDb: 'America/Paramaribo',
    nameDisplay: 'America/Paramaribo'
  },
  {
    id: 167,
    nameDb: 'America/Port-au-Prince',
    nameDisplay: 'America/Port-au-Prince'
  },
  {
    id: 168,
    nameDb: 'America/Port_of_Spain',
    nameDisplay: 'America/Port_of_Spain'
  },
  {
    id: 169,
    nameDb: 'America/Porto_Velho',
    nameDisplay: 'America/Porto_Velho'
  },
  {
    id: 170,
    nameDb: 'America/Puerto_Rico',
    nameDisplay: 'America/Puerto_Rico'
  },
  {
    id: 171,
    nameDb: 'America/Punta_Arenas',
    nameDisplay: 'America/Punta_Arenas'
  },
  {
    id: 172,
    nameDb: 'America/Rainy_River',
    nameDisplay: 'America/Rainy_River'
  },
  {
    id: 173,
    nameDb: 'America/Rankin_Inlet',
    nameDisplay: 'America/Rankin_Inlet'
  },
  {
    id: 174,
    nameDb: 'America/Recife',
    nameDisplay: 'America/Recife'
  },
  {
    id: 175,
    nameDb: 'America/Regina',
    nameDisplay: 'America/Regina'
  },
  {
    id: 176,
    nameDb: 'America/Resolute',
    nameDisplay: 'America/Resolute'
  },
  {
    id: 177,
    nameDb: 'America/Rio_Branco',
    nameDisplay: 'America/Rio_Branco'
  },
  {
    id: 178,
    nameDb: 'America/Santarem',
    nameDisplay: 'America/Santarem'
  },
  {
    id: 179,
    nameDb: 'America/Santiago',
    nameDisplay: 'America/Santiago'
  },
  {
    id: 180,
    nameDb: 'America/Santo_Domingo',
    nameDisplay: 'America/Santo_Domingo'
  },
  {
    id: 181,
    nameDb: 'America/Sao_Paulo',
    nameDisplay: 'America/Sao_Paulo'
  },
  {
    id: 182,
    nameDb: 'America/Scoresbysund',
    nameDisplay: 'America/Scoresbysund'
  },
  {
    id: 183,
    nameDb: 'America/Sitka',
    nameDisplay: 'America/Sitka'
  },
  {
    id: 184,
    nameDb: 'America/St_Barthelemy',
    nameDisplay: 'America/St_Barthelemy'
  },
  {
    id: 185,
    nameDb: 'America/St_Johns',
    nameDisplay: 'America/St_Johns'
  },
  {
    id: 186,
    nameDb: 'America/St_Kitts',
    nameDisplay: 'America/St_Kitts'
  },
  {
    id: 187,
    nameDb: 'America/St_Lucia',
    nameDisplay: 'America/St_Lucia'
  },
  {
    id: 188,
    nameDb: 'America/St_Thomas',
    nameDisplay: 'America/St_Thomas'
  },
  {
    id: 189,
    nameDb: 'America/St_Vincent',
    nameDisplay: 'America/St_Vincent'
  },
  {
    id: 190,
    nameDb: 'America/Swift_Current',
    nameDisplay: 'America/Swift_Current'
  },
  {
    id: 191,
    nameDb: 'America/Tegucigalpa',
    nameDisplay: 'America/Tegucigalpa'
  },
  {
    id: 192,
    nameDb: 'America/Thule',
    nameDisplay: 'America/Thule'
  },
  {
    id: 193,
    nameDb: 'America/Thunder_Bay',
    nameDisplay: 'America/Thunder_Bay'
  },
  {
    id: 194,
    nameDb: 'America/Tijuana',
    nameDisplay: 'America/Tijuana'
  },
  {
    id: 195,
    nameDb: 'America/Toronto',
    nameDisplay: 'America/Toronto'
  },
  {
    id: 196,
    nameDb: 'America/Tortola',
    nameDisplay: 'America/Tortola'
  },
  {
    id: 197,
    nameDb: 'America/Vancouver',
    nameDisplay: 'America/Vancouver'
  },
  {
    id: 198,
    nameDb: 'America/Whitehorse',
    nameDisplay: 'America/Whitehorse'
  },
  {
    id: 199,
    nameDb: 'America/Winnipeg',
    nameDisplay: 'America/Winnipeg'
  },
  {
    id: 200,
    nameDb: 'America/Yakutat',
    nameDisplay: 'America/Yakutat'
  },
  {
    id: 201,
    nameDb: 'America/Yellowknife',
    nameDisplay: 'America/Yellowknife'
  },
  {
    id: 202,
    nameDb: 'Antarctica/Casey',
    nameDisplay: 'Antarctica/Casey'
  },
  {
    id: 203,
    nameDb: 'Antarctica/Davis',
    nameDisplay: 'Antarctica/Davis'
  },
  {
    id: 204,
    nameDb: 'Antarctica/DumontDUrville',
    nameDisplay: 'Antarctica/DumontDUrville'
  },
  {
    id: 205,
    nameDb: 'Antarctica/Macquarie',
    nameDisplay: 'Antarctica/Macquarie'
  },
  {
    id: 206,
    nameDb: 'Antarctica/Mawson',
    nameDisplay: 'Antarctica/Mawson'
  },
  {
    id: 207,
    nameDb: 'Antarctica/McMurdo',
    nameDisplay: 'Antarctica/McMurdo'
  },
  {
    id: 208,
    nameDb: 'Antarctica/Palmer',
    nameDisplay: 'Antarctica/Palmer'
  },
  {
    id: 209,
    nameDb: 'Antarctica/Rothera',
    nameDisplay: 'Antarctica/Rothera'
  },
  {
    id: 210,
    nameDb: 'Antarctica/Syowa',
    nameDisplay: 'Antarctica/Syowa'
  },
  {
    id: 211,
    nameDb: 'Antarctica/Troll',
    nameDisplay: 'Antarctica/Troll'
  },
  {
    id: 212,
    nameDb: 'Antarctica/Vostok',
    nameDisplay: 'Antarctica/Vostok'
  },
  {
    id: 213,
    nameDb: 'Arctic/Longyearbyen',
    nameDisplay: 'Arctic/Longyearbyen'
  },
  {
    id: 214,
    nameDb: 'Asia/Aden',
    nameDisplay: 'Asia/Aden'
  },
  {
    id: 215,
    nameDb: 'Asia/Almaty',
    nameDisplay: 'Asia/Almaty'
  },
  {
    id: 216,
    nameDb: 'Asia/Amman',
    nameDisplay: 'Asia/Amman'
  },
  {
    id: 217,
    nameDb: 'Asia/Anadyr',
    nameDisplay: 'Asia/Anadyr'
  },
  {
    id: 218,
    nameDb: 'Asia/Aqtau',
    nameDisplay: 'Asia/Aqtau'
  },
  {
    id: 219,
    nameDb: 'Asia/Aqtobe',
    nameDisplay: 'Asia/Aqtobe'
  },
  {
    id: 220,
    nameDb: 'Asia/Ashgabat',
    nameDisplay: 'Asia/Ashgabat'
  },
  {
    id: 221,
    nameDb: 'Asia/Atyrau',
    nameDisplay: 'Asia/Atyrau'
  },
  {
    id: 222,
    nameDb: 'Asia/Baghdad',
    nameDisplay: 'Asia/Baghdad'
  },
  {
    id: 223,
    nameDb: 'Asia/Bahrain',
    nameDisplay: 'Asia/Bahrain'
  },
  {
    id: 224,
    nameDb: 'Asia/Baku',
    nameDisplay: 'Asia/Baku'
  },
  {
    id: 225,
    nameDb: 'Asia/Bangkok',
    nameDisplay: 'Asia/Bangkok'
  },
  {
    id: 226,
    nameDb: 'Asia/Barnaul',
    nameDisplay: 'Asia/Barnaul'
  },
  {
    id: 227,
    nameDb: 'Asia/Beirut',
    nameDisplay: 'Asia/Beirut'
  },
  {
    id: 228,
    nameDb: 'Asia/Bishkek',
    nameDisplay: 'Asia/Bishkek'
  },
  {
    id: 229,
    nameDb: 'Asia/Brunei',
    nameDisplay: 'Asia/Brunei'
  },
  {
    id: 230,
    nameDb: 'Asia/Chita',
    nameDisplay: 'Asia/Chita'
  },
  {
    id: 231,
    nameDb: 'Asia/Choibalsan',
    nameDisplay: 'Asia/Choibalsan'
  },
  {
    id: 232,
    nameDb: 'Asia/Colombo',
    nameDisplay: 'Asia/Colombo'
  },
  {
    id: 233,
    nameDb: 'Asia/Damascus',
    nameDisplay: 'Asia/Damascus'
  },
  {
    id: 234,
    nameDb: 'Asia/Dhaka',
    nameDisplay: 'Asia/Dhaka'
  },
  {
    id: 235,
    nameDb: 'Asia/Dili',
    nameDisplay: 'Asia/Dili'
  },
  {
    id: 236,
    nameDb: 'Asia/Dubai',
    nameDisplay: 'Asia/Dubai'
  },
  {
    id: 237,
    nameDb: 'Asia/Dushanbe',
    nameDisplay: 'Asia/Dushanbe'
  },
  {
    id: 238,
    nameDb: 'Asia/Famagusta',
    nameDisplay: 'Asia/Famagusta'
  },
  {
    id: 239,
    nameDb: 'Asia/Gaza',
    nameDisplay: 'Asia/Gaza'
  },
  {
    id: 240,
    nameDb: 'Asia/Hebron',
    nameDisplay: 'Asia/Hebron'
  },
  {
    id: 241,
    nameDb: 'Asia/Ho_Chi_Minh',
    nameDisplay: 'Asia/Ho_Chi_Minh'
  },
  {
    id: 242,
    nameDb: 'Asia/Hong_Kong',
    nameDisplay: 'Asia/Hong_Kong'
  },
  {
    id: 243,
    nameDb: 'Asia/Hovd',
    nameDisplay: 'Asia/Hovd'
  },
  {
    id: 244,
    nameDb: 'Asia/Irkutsk',
    nameDisplay: 'Asia/Irkutsk'
  },
  {
    id: 245,
    nameDb: 'Asia/Jakarta',
    nameDisplay: 'Asia/Jakarta'
  },
  {
    id: 246,
    nameDb: 'Asia/Jayapura',
    nameDisplay: 'Asia/Jayapura'
  },
  {
    id: 247,
    nameDb: 'Asia/Jerusalem',
    nameDisplay: 'Asia/Jerusalem'
  },
  {
    id: 248,
    nameDb: 'Asia/Kabul',
    nameDisplay: 'Asia/Kabul'
  },
  {
    id: 249,
    nameDb: 'Asia/Kamchatka',
    nameDisplay: 'Asia/Kamchatka'
  },
  {
    id: 250,
    nameDb: 'Asia/Karachi',
    nameDisplay: 'Asia/Karachi'
  },
  {
    id: 251,
    nameDb: 'Asia/Kathmandu',
    nameDisplay: 'Asia/Kathmandu'
  },
  {
    id: 252,
    nameDb: 'Asia/Khandyga',
    nameDisplay: 'Asia/Khandyga'
  },
  {
    id: 253,
    nameDb: 'Asia/Kolkata',
    nameDisplay: 'Asia/Kolkata'
  },
  {
    id: 254,
    nameDb: 'Asia/Krasnoyarsk',
    nameDisplay: 'Asia/Krasnoyarsk'
  },
  {
    id: 255,
    nameDb: 'Asia/Kuala_Lumpur',
    nameDisplay: 'Asia/Kuala_Lumpur'
  },
  {
    id: 256,
    nameDb: 'Asia/Kuching',
    nameDisplay: 'Asia/Kuching'
  },
  {
    id: 257,
    nameDb: 'Asia/Kuwait',
    nameDisplay: 'Asia/Kuwait'
  },
  {
    id: 258,
    nameDb: 'Asia/Macau',
    nameDisplay: 'Asia/Macau'
  },
  {
    id: 259,
    nameDb: 'Asia/Magadan',
    nameDisplay: 'Asia/Magadan'
  },
  {
    id: 260,
    nameDb: 'Asia/Makassar',
    nameDisplay: 'Asia/Makassar'
  },
  {
    id: 261,
    nameDb: 'Asia/Manila',
    nameDisplay: 'Asia/Manila'
  },
  {
    id: 262,
    nameDb: 'Asia/Muscat',
    nameDisplay: 'Asia/Muscat'
  },
  {
    id: 263,
    nameDb: 'Asia/Nicosia',
    nameDisplay: 'Asia/Nicosia'
  },
  {
    id: 264,
    nameDb: 'Asia/Novokuznetsk',
    nameDisplay: 'Asia/Novokuznetsk'
  },
  {
    id: 265,
    nameDb: 'Asia/Novosibirsk',
    nameDisplay: 'Asia/Novosibirsk'
  },
  {
    id: 266,
    nameDb: 'Asia/Omsk',
    nameDisplay: 'Asia/Omsk'
  },
  {
    id: 267,
    nameDb: 'Asia/Oral',
    nameDisplay: 'Asia/Oral'
  },
  {
    id: 268,
    nameDb: 'Asia/Phnom_Penh',
    nameDisplay: 'Asia/Phnom_Penh'
  },
  {
    id: 269,
    nameDb: 'Asia/Pontianak',
    nameDisplay: 'Asia/Pontianak'
  },
  {
    id: 270,
    nameDb: 'Asia/Pyongyang',
    nameDisplay: 'Asia/Pyongyang'
  },
  {
    id: 271,
    nameDb: 'Asia/Qatar',
    nameDisplay: 'Asia/Qatar'
  },
  {
    id: 272,
    nameDb: 'Asia/Qostanay',
    nameDisplay: 'Asia/Qostanay'
  },
  {
    id: 273,
    nameDb: 'Asia/Qyzylorda',
    nameDisplay: 'Asia/Qyzylorda'
  },
  {
    id: 274,
    nameDb: 'Asia/Riyadh',
    nameDisplay: 'Asia/Riyadh'
  },
  {
    id: 275,
    nameDb: 'Asia/Sakhalin',
    nameDisplay: 'Asia/Sakhalin'
  },
  {
    id: 276,
    nameDb: 'Asia/Samarkand',
    nameDisplay: 'Asia/Samarkand'
  },
  {
    id: 277,
    nameDb: 'Asia/Seoul',
    nameDisplay: 'Asia/Seoul'
  },
  {
    id: 278,
    nameDb: 'Asia/Shanghai',
    nameDisplay: 'Asia/Shanghai'
  },
  {
    id: 279,
    nameDb: 'Asia/Singapore',
    nameDisplay: 'Asia/Singapore'
  },
  {
    id: 280,
    nameDb: 'Asia/Srednekolymsk',
    nameDisplay: 'Asia/Srednekolymsk'
  },
  {
    id: 281,
    nameDb: 'Asia/Taipei',
    nameDisplay: 'Asia/Taipei'
  },
  {
    id: 282,
    nameDb: 'Asia/Tashkent',
    nameDisplay: 'Asia/Tashkent'
  },
  {
    id: 283,
    nameDb: 'Asia/Tbilisi',
    nameDisplay: 'Asia/Tbilisi'
  },
  {
    id: 284,
    nameDb: 'Asia/Tehran',
    nameDisplay: 'Asia/Tehran'
  },
  {
    id: 285,
    nameDb: 'Asia/Thimphu',
    nameDisplay: 'Asia/Thimphu'
  },
  {
    id: 286,
    nameDb: 'Asia/Tokyo',
    nameDisplay: 'Asia/Tokyo'
  },
  {
    id: 287,
    nameDb: 'Asia/Tomsk',
    nameDisplay: 'Asia/Tomsk'
  },
  {
    id: 288,
    nameDb: 'Asia/Ulaanbaatar',
    nameDisplay: 'Asia/Ulaanbaatar'
  },
  {
    id: 289,
    nameDb: 'Asia/Urumqi',
    nameDisplay: 'Asia/Urumqi'
  },
  {
    id: 290,
    nameDb: 'Asia/Ust-Nera',
    nameDisplay: 'Asia/Ust-Nera'
  },
  {
    id: 291,
    nameDb: 'Asia/Vientiane',
    nameDisplay: 'Asia/Vientiane'
  },
  {
    id: 292,
    nameDb: 'Asia/Vladivostok',
    nameDisplay: 'Asia/Vladivostok'
  },
  {
    id: 293,
    nameDb: 'Asia/Yakutsk',
    nameDisplay: 'Asia/Yakutsk'
  },
  {
    id: 294,
    nameDb: 'Asia/Yangon',
    nameDisplay: 'Asia/Yangon'
  },
  {
    id: 295,
    nameDb: 'Asia/Yekaterinburg',
    nameDisplay: 'Asia/Yekaterinburg'
  },
  {
    id: 296,
    nameDb: 'Asia/Yerevan',
    nameDisplay: 'Asia/Yerevan'
  },
  {
    id: 297,
    nameDb: 'Atlantic/Azores',
    nameDisplay: 'Atlantic/Azores'
  },
  {
    id: 298,
    nameDb: 'Atlantic/Bermuda',
    nameDisplay: 'Atlantic/Bermuda'
  },
  {
    id: 299,
    nameDb: 'Atlantic/Canary',
    nameDisplay: 'Atlantic/Canary'
  },
  {
    id: 300,
    nameDb: 'Atlantic/Cape_Verde',
    nameDisplay: 'Atlantic/Cape_Verde'
  },
  {
    id: 301,
    nameDb: 'Atlantic/Faroe',
    nameDisplay: 'Atlantic/Faroe'
  },
  {
    id: 302,
    nameDb: 'Atlantic/Madeira',
    nameDisplay: 'Atlantic/Madeira'
  },
  {
    id: 303,
    nameDb: 'Atlantic/Reykjavik',
    nameDisplay: 'Atlantic/Reykjavik'
  },
  {
    id: 304,
    nameDb: 'Atlantic/South_Georgia',
    nameDisplay: 'Atlantic/South_Georgia'
  },
  {
    id: 305,
    nameDb: 'Atlantic/St_Helena',
    nameDisplay: 'Atlantic/St_Helena'
  },
  {
    id: 306,
    nameDb: 'Atlantic/Stanley',
    nameDisplay: 'Atlantic/Stanley'
  },
  {
    id: 307,
    nameDb: 'Australia/Adelaide',
    nameDisplay: 'Australia/Adelaide'
  },
  {
    id: 308,
    nameDb: 'Australia/Brisbane',
    nameDisplay: 'Australia/Brisbane'
  },
  {
    id: 309,
    nameDb: 'Australia/Broken_Hill',
    nameDisplay: 'Australia/Broken_Hill'
  },
  {
    id: 310,
    nameDb: 'Australia/Darwin',
    nameDisplay: 'Australia/Darwin'
  },
  {
    id: 311,
    nameDb: 'Australia/Eucla',
    nameDisplay: 'Australia/Eucla'
  },
  {
    id: 312,
    nameDb: 'Australia/Hobart',
    nameDisplay: 'Australia/Hobart'
  },
  {
    id: 313,
    nameDb: 'Australia/Lindeman',
    nameDisplay: 'Australia/Lindeman'
  },
  {
    id: 314,
    nameDb: 'Australia/Lord_Howe',
    nameDisplay: 'Australia/Lord_Howe'
  },
  {
    id: 315,
    nameDb: 'Australia/Melbourne',
    nameDisplay: 'Australia/Melbourne'
  },
  {
    id: 316,
    nameDb: 'Australia/Perth',
    nameDisplay: 'Australia/Perth'
  },
  {
    id: 317,
    nameDb: 'Australia/Sydney',
    nameDisplay: 'Australia/Sydney'
  },
  {
    id: 318,
    nameDb: 'Europe/Amsterdam',
    nameDisplay: 'Europe/Amsterdam'
  },
  {
    id: 319,
    nameDb: 'Europe/Andorra',
    nameDisplay: 'Europe/Andorra'
  },
  {
    id: 320,
    nameDb: 'Europe/Astrakhan',
    nameDisplay: 'Europe/Astrakhan'
  },
  {
    id: 321,
    nameDb: 'Europe/Athens',
    nameDisplay: 'Europe/Athens'
  },
  {
    id: 322,
    nameDb: 'Europe/Belgrade',
    nameDisplay: 'Europe/Belgrade'
  },
  {
    id: 323,
    nameDb: 'Europe/Berlin',
    nameDisplay: 'Europe/Berlin'
  },
  {
    id: 324,
    nameDb: 'Europe/Bratislava',
    nameDisplay: 'Europe/Bratislava'
  },
  {
    id: 325,
    nameDb: 'Europe/Brussels',
    nameDisplay: 'Europe/Brussels'
  },
  {
    id: 326,
    nameDb: 'Europe/Bucharest',
    nameDisplay: 'Europe/Bucharest'
  },
  {
    id: 327,
    nameDb: 'Europe/Budapest',
    nameDisplay: 'Europe/Budapest'
  },
  {
    id: 328,
    nameDb: 'Europe/Busingen',
    nameDisplay: 'Europe/Busingen'
  },
  {
    id: 329,
    nameDb: 'Europe/Chisinau',
    nameDisplay: 'Europe/Chisinau'
  },
  {
    id: 330,
    nameDb: 'Europe/Copenhagen',
    nameDisplay: 'Europe/Copenhagen'
  },
  {
    id: 331,
    nameDb: 'Europe/Dublin',
    nameDisplay: 'Europe/Dublin'
  },
  {
    id: 332,
    nameDb: 'Europe/Gibraltar',
    nameDisplay: 'Europe/Gibraltar'
  },
  {
    id: 333,
    nameDb: 'Europe/Guernsey',
    nameDisplay: 'Europe/Guernsey'
  },
  {
    id: 334,
    nameDb: 'Europe/Helsinki',
    nameDisplay: 'Europe/Helsinki'
  },
  {
    id: 335,
    nameDb: 'Europe/Isle_of_Man',
    nameDisplay: 'Europe/Isle_of_Man'
  },
  {
    id: 336,
    nameDb: 'Europe/Istanbul',
    nameDisplay: 'Europe/Istanbul'
  },
  {
    id: 337,
    nameDb: 'Europe/Jersey',
    nameDisplay: 'Europe/Jersey'
  },
  {
    id: 338,
    nameDb: 'Europe/Kaliningrad',
    nameDisplay: 'Europe/Kaliningrad'
  },
  {
    id: 339,
    nameDb: 'Europe/Kirov',
    nameDisplay: 'Europe/Kirov'
  },
  {
    id: 340,
    nameDb: 'Europe/Kyiv',
    nameDisplay: 'Europe/Kyiv'
  },
  {
    id: 341,
    nameDb: 'Europe/Lisbon',
    nameDisplay: 'Europe/Lisbon'
  },
  {
    id: 342,
    nameDb: 'Europe/Ljubljana',
    nameDisplay: 'Europe/Ljubljana'
  },
  {
    id: 343,
    nameDb: 'Europe/Luxembourg',
    nameDisplay: 'Europe/Luxembourg'
  },
  {
    id: 344,
    nameDb: 'Europe/Madrid',
    nameDisplay: 'Europe/Madrid'
  },
  {
    id: 345,
    nameDb: 'Europe/Malta',
    nameDisplay: 'Europe/Malta'
  },
  {
    id: 346,
    nameDb: 'Europe/Mariehamn',
    nameDisplay: 'Europe/Mariehamn'
  },
  {
    id: 347,
    nameDb: 'Europe/Minsk',
    nameDisplay: 'Europe/Minsk'
  },
  {
    id: 348,
    nameDb: 'Europe/Monaco',
    nameDisplay: 'Europe/Monaco'
  },
  {
    id: 349,
    nameDb: 'Europe/Moscow',
    nameDisplay: 'Europe/Moscow'
  },
  {
    id: 350,
    nameDb: 'Europe/Oslo',
    nameDisplay: 'Europe/Oslo'
  },
  {
    id: 351,
    nameDb: 'Europe/Paris',
    nameDisplay: 'Europe/Paris'
  },
  {
    id: 352,
    nameDb: 'Europe/Podgorica',
    nameDisplay: 'Europe/Podgorica'
  },
  {
    id: 353,
    nameDb: 'Europe/Prague',
    nameDisplay: 'Europe/Prague'
  },
  {
    id: 354,
    nameDb: 'Europe/Riga',
    nameDisplay: 'Europe/Riga'
  },
  {
    id: 355,
    nameDb: 'Europe/Rome',
    nameDisplay: 'Europe/Rome'
  },
  {
    id: 356,
    nameDb: 'Europe/Samara',
    nameDisplay: 'Europe/Samara'
  },
  {
    id: 357,
    nameDb: 'Europe/San_Marino',
    nameDisplay: 'Europe/San_Marino'
  },
  {
    id: 358,
    nameDb: 'Europe/Sarajevo',
    nameDisplay: 'Europe/Sarajevo'
  },
  {
    id: 359,
    nameDb: 'Europe/Saratov',
    nameDisplay: 'Europe/Saratov'
  },
  {
    id: 360,
    nameDb: 'Europe/Simferopol',
    nameDisplay: 'Europe/Simferopol'
  },
  {
    id: 361,
    nameDb: 'Europe/Skopje',
    nameDisplay: 'Europe/Skopje'
  },
  {
    id: 362,
    nameDb: 'Europe/Sofia',
    nameDisplay: 'Europe/Sofia'
  },
  {
    id: 363,
    nameDb: 'Europe/Stockholm',
    nameDisplay: 'Europe/Stockholm'
  },
  {
    id: 364,
    nameDb: 'Europe/Tallinn',
    nameDisplay: 'Europe/Tallinn'
  },
  {
    id: 365,
    nameDb: 'Europe/Tirane',
    nameDisplay: 'Europe/Tirane'
  },
  {
    id: 366,
    nameDb: 'Europe/Ulyanovsk',
    nameDisplay: 'Europe/Ulyanovsk'
  },
  {
    id: 367,
    nameDb: 'Europe/Vaduz',
    nameDisplay: 'Europe/Vaduz'
  },
  {
    id: 368,
    nameDb: 'Europe/Vatican',
    nameDisplay: 'Europe/Vatican'
  },
  {
    id: 369,
    nameDb: 'Europe/Vienna',
    nameDisplay: 'Europe/Vienna'
  },
  {
    id: 370,
    nameDb: 'Europe/Vilnius',
    nameDisplay: 'Europe/Vilnius'
  },
  {
    id: 371,
    nameDb: 'Europe/Volgograd',
    nameDisplay: 'Europe/Volgograd'
  },
  {
    id: 372,
    nameDb: 'Europe/Warsaw',
    nameDisplay: 'Europe/Warsaw'
  },
  {
    id: 373,
    nameDb: 'Europe/Zagreb',
    nameDisplay: 'Europe/Zagreb'
  },
  {
    id: 374,
    nameDb: 'Europe/Zurich',
    nameDisplay: 'Europe/Zurich'
  },
  {
    id: 375,
    nameDb: 'Indian/Antananarivo',
    nameDisplay: 'Indian/Antananarivo'
  },
  {
    id: 376,
    nameDb: 'Indian/Chagos',
    nameDisplay: 'Indian/Chagos'
  },
  {
    id: 377,
    nameDb: 'Indian/Christmas',
    nameDisplay: 'Indian/Christmas'
  },
  {
    id: 378,
    nameDb: 'Indian/Cocos',
    nameDisplay: 'Indian/Cocos'
  },
  {
    id: 379,
    nameDb: 'Indian/Comoro',
    nameDisplay: 'Indian/Comoro'
  },
  {
    id: 380,
    nameDb: 'Indian/Kerguelen',
    nameDisplay: 'Indian/Kerguelen'
  },
  {
    id: 381,
    nameDb: 'Indian/Mahe',
    nameDisplay: 'Indian/Mahe'
  },
  {
    id: 382,
    nameDb: 'Indian/Maldives',
    nameDisplay: 'Indian/Maldives'
  },
  {
    id: 383,
    nameDb: 'Indian/Mauritius',
    nameDisplay: 'Indian/Mauritius'
  },
  {
    id: 384,
    nameDb: 'Indian/Mayotte',
    nameDisplay: 'Indian/Mayotte'
  },
  {
    id: 385,
    nameDb: 'Indian/Reunion',
    nameDisplay: 'Indian/Reunion'
  },
  {
    id: 386,
    nameDb: 'Pacific/Apia',
    nameDisplay: 'Pacific/Apia'
  },
  {
    id: 387,
    nameDb: 'Pacific/Auckland',
    nameDisplay: 'Pacific/Auckland'
  },
  {
    id: 388,
    nameDb: 'Pacific/Bougainville',
    nameDisplay: 'Pacific/Bougainville'
  },
  {
    id: 389,
    nameDb: 'Pacific/Chatham',
    nameDisplay: 'Pacific/Chatham'
  },
  {
    id: 390,
    nameDb: 'Pacific/Chuuk',
    nameDisplay: 'Pacific/Chuuk'
  },
  {
    id: 391,
    nameDb: 'Pacific/Easter',
    nameDisplay: 'Pacific/Easter'
  },
  {
    id: 392,
    nameDb: 'Pacific/Efate',
    nameDisplay: 'Pacific/Efate'
  },
  {
    id: 393,
    nameDb: 'Pacific/Fakaofo',
    nameDisplay: 'Pacific/Fakaofo'
  },
  {
    id: 394,
    nameDb: 'Pacific/Fiji',
    nameDisplay: 'Pacific/Fiji'
  },
  {
    id: 395,
    nameDb: 'Pacific/Funafuti',
    nameDisplay: 'Pacific/Funafuti'
  },
  {
    id: 396,
    nameDb: 'Pacific/Galapagos',
    nameDisplay: 'Pacific/Galapagos'
  },
  {
    id: 397,
    nameDb: 'Pacific/Gambier',
    nameDisplay: 'Pacific/Gambier'
  },
  {
    id: 398,
    nameDb: 'Pacific/Guadalcanal',
    nameDisplay: 'Pacific/Guadalcanal'
  },
  {
    id: 399,
    nameDb: 'Pacific/Guam',
    nameDisplay: 'Pacific/Guam'
  },
  {
    id: 400,
    nameDb: 'Pacific/Kanton',
    nameDisplay: 'Pacific/Kanton'
  },
  {
    id: 401,
    nameDb: 'Pacific/Kiritimati',
    nameDisplay: 'Pacific/Kiritimati'
  },
  {
    id: 402,
    nameDb: 'Pacific/Kosrae',
    nameDisplay: 'Pacific/Kosrae'
  },
  {
    id: 403,
    nameDb: 'Pacific/Kwajalein',
    nameDisplay: 'Pacific/Kwajalein'
  },
  {
    id: 404,
    nameDb: 'Pacific/Majuro',
    nameDisplay: 'Pacific/Majuro'
  },
  {
    id: 405,
    nameDb: 'Pacific/Marquesas',
    nameDisplay: 'Pacific/Marquesas'
  },
  {
    id: 406,
    nameDb: 'Pacific/Midway',
    nameDisplay: 'Pacific/Midway'
  },
  {
    id: 407,
    nameDb: 'Pacific/Nauru',
    nameDisplay: 'Pacific/Nauru'
  },
  {
    id: 408,
    nameDb: 'Pacific/Niue',
    nameDisplay: 'Pacific/Niue'
  },
  {
    id: 409,
    nameDb: 'Pacific/Norfolk',
    nameDisplay: 'Pacific/Norfolk'
  },
  {
    id: 410,
    nameDb: 'Pacific/Noumea',
    nameDisplay: 'Pacific/Noumea'
  },
  {
    id: 411,
    nameDb: 'Pacific/Pago_Pago',
    nameDisplay: 'Pacific/Pago_Pago'
  },
  {
    id: 412,
    nameDb: 'Pacific/Palau',
    nameDisplay: 'Pacific/Palau'
  },
  {
    id: 413,
    nameDb: 'Pacific/Pitcairn',
    nameDisplay: 'Pacific/Pitcairn'
  },
  {
    id: 414,
    nameDb: 'Pacific/Pohnpei',
    nameDisplay: 'Pacific/Pohnpei'
  },
  {
    id: 415,
    nameDb: 'Pacific/Port_Moresby',
    nameDisplay: 'Pacific/Port_Moresby'
  },
  {
    id: 416,
    nameDb: 'Pacific/Rarotonga',
    nameDisplay: 'Pacific/Rarotonga'
  },
  {
    id: 417,
    nameDb: 'Pacific/Saipan',
    nameDisplay: 'Pacific/Saipan'
  },
  {
    id: 418,
    nameDb: 'Pacific/Tahiti',
    nameDisplay: 'Pacific/Tahiti'
  },
  {
    id: 419,
    nameDb: 'Pacific/Tarawa',
    nameDisplay: 'Pacific/Tarawa'
  },
  {
    id: 420,
    nameDb: 'Pacific/Tongatapu',
    nameDisplay: 'Pacific/Tongatapu'
  },
  {
    id: 421,
    nameDb: 'Pacific/Wake',
    nameDisplay: 'Pacific/Wake'
  },
  {
    id: 422,
    nameDb: 'Pacific/Wallis',
    nameDisplay: 'Pacific/Wallis'
  },
  {
    id: 423,
    nameDb: 'UTC',
    nameDisplay: 'UTC'
  }
];
