import {
  styled,
} from '@languageconvo/wcl';

// this is how we conditionally show the back button only on subpages, not all pages. we need to
// use visibility: hidden so that the height of the div is maintained even when hidden, otherwise
// there's a vertical jump in the screen due to varying vertical centering
export const BackButtonContainer = styled('div')(({ shouldDisplay }: any) => ({
  paddingTop: '7px',
  ...(shouldDisplay === false && {
    visibility: 'hidden',
  }),
}));
