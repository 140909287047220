import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-relay';
import * as Sentry from '@sentry/react';
import { StuSettingsGettrialdataMutation } from '../../../../../common/relay/mutations/students/StuSettingsGettrialdata';
import { LayoutNocredits3Scneario } from './LayoutNocredits3Scneario';

interface Props {
langId: number
}

/* Get the custom trial data via api call
  We must do this every time the student loads this route and does not have credits, because
  their trial status might have changed just now if they purchased lessons, if they completed
  a trial, etc.
*/
export const LayoutNocredits2Gettrialdata = ({ langId }: Props) => {
  // State to handle errors in case the mutation fails and show page level error.
  const [error, setError] = useState();

  useEffect(() => {
    if (error) {
      throw new Error(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const [TheTrialData] = useMutation(StuSettingsGettrialdataMutation);

  /* Get our custom trial data via api call
    IMPORTANT NOTE: notice that we do *not* have a loading state for this mutation, meaning
    that when this route loads the trial data we got from the app-level query will be
    displayed. Then this query will run, and that app-level data will still appear
    *until* this query resolves...then the results of this query *might* change
    the data, or it might not. This is a better user experience than showing a loading
    glimmer --- if the data doesn't change, then the user doesn't see any change in the
    ui. If the data does change the they only see the old outdated ui for just a second
    or two while this resolves.
*/

  useEffect(() => {
    if (langId) {
      TheTrialData({
        variables: {
          languageId: langId,
        },
        onCompleted() {
        // success, don't need to do anything
        },
        onError(err: any) {
          Sentry.captureException(err);
          setError(err);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langId]);

  return (
    <LayoutNocredits3Scneario />
  );
};
