import { Box, IconButton } from '@mui/material';
import { Ty } from '../../datadisplay/Typography';
import { Ic } from '../../datadisplay/Icon';
import { TypographyBox, PaginationMain } from './DataGrid.Style';
import { PaginationProps } from './interface';

function CustomPagination(props: PaginationProps) {
  const {
    currentPage, handlePageChange, totalPages,
  } = props;

  return (
    <PaginationMain>
      <Box>
        <IconButton
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 0}
          aria-label="previous page"
          data-testid="prePage"
        >
          <Ic iconName="caret-left" size="rel-2xs" iconStyle="solid" />
        </IconButton>
      </Box>
      <TypographyBox>
        <Ty>
          {currentPage + 1}
        </Ty>
      </TypographyBox>
      <Box>
        <IconButton
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          aria-label="next page"
          data-testid="nextPage"
        >
          <Ic iconName="caret-right" size="rel-2xs" iconStyle="solid" />
        </IconButton>
      </Box>
    </PaginationMain>
  );
}

export default CustomPagination;
