/**
 * @generated SignedSource<<2f199387bb47fe07b37470e492d9b6ef>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type GetUnreadMessagesSubscription$variables = {};
export type GetUnreadMessagesSubscription$data = {
  readonly appmessaging_threads_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly newest_receivedmessageid: any | null;
        readonly read_messageid: any | null;
      };
    }>;
  };
};
export type GetUnreadMessagesSubscription = {
  response: GetUnreadMessagesSubscription$data;
  variables: GetUnreadMessagesSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "appmessaging_threadsConnection",
    "kind": "LinkedField",
    "name": "appmessaging_threads_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "appmessaging_threadsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appmessaging_threads",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newest_receivedmessageid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "read_messageid",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetUnreadMessagesSubscription",
    "selections": (v0/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetUnreadMessagesSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b37f477f9c6f89dbbe3afed352a3e3cf",
    "id": null,
    "metadata": {},
    "name": "GetUnreadMessagesSubscription",
    "operationKind": "subscription",
    "text": "subscription GetUnreadMessagesSubscription {\n  appmessaging_threads_connection {\n    edges {\n      node {\n        id\n        newest_receivedmessageid\n        read_messageid\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbd07d6272edb677111033697baf74d2";

export default node;
