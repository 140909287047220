import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { StyledContainerProps } from './interface';

export const StyleContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'maxHeight',
})<StyledContainerProps>(({
  maxHeight,
}) => ({
  maxHeight,
}));
