import { StyleContainer } from './GlobalStyleContainer.style';
import { GlobalStyleContainerProps } from './interface';

export const GlobalStyleContainer = (props: GlobalStyleContainerProps) => {
  const {
    overflowX = 'hidden',
    overflowY = 'hidden',
    children,
    cp,
  } = props;

  return (
    <StyleContainer
      overflowX={overflowX}
      overflowY={overflowY}
      {...cp}
    >
      {children}
    </StyleContainer>
  );
};
