import React, { useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { useSubscription } from 'react-relay';
import { Step2DisplaySetup } from './Step2DisplaySetup';
import {
  GroupLessonsSubscription$data,
  GroupLessonsSubscription as GroupLessonsSubscriptionGenerated
} from '../../relay/__generated__/GroupLessonsSubscription.graphql';
import { GroupLessonsSubscription } from '../../relay/GroupLessons';
import { Loading } from '../Loading';
import { DaysSelector } from '../../GroupSchedule2';

interface Props {
  languageId: number
  tsSettings: {
    hourCycle: string
  }
  daysSelector: DaysSelector
  setDaysSelector: any
}

// subscribe to group classes
export const Step1Subscription = ({
  languageId,
  tsSettings,
  daysSelector,
  setDaysSelector,
}: Props) => {
  // endOfDay is a luxon dt set to the end of the current day. in our code that checks
  // the current time, we'll constantly check if a new day has started and if it has
  // we'll update this var. we use this for doing things like re-executing the subscription
  // when a new day begins
  const [endOfDay, setEndOfDay] = useState(DateTime.now().endOf('day'));

  // once subscription executes, subscription onNext callback udpates this reference.
  const [fragmentRef, setFragmentRef] = useState<
    GroupLessonsSubscription$data | undefined | null>();

  // run the subscription. note that if language id or endOfDay change, the subscription
  // will re-run
  const groupLessonConfig = useMemo(() => ({
    subscription: GroupLessonsSubscription,
    variables: {
      langId: languageId,
      currentTime: DateTime.now().toISO(),
      endTime: DateTime.now().plus({ days: 3 }).toISO(),
    },
    // a callback that is executed when a subscription payload is received
    onNext: (res: GroupLessonsSubscription$data | undefined | null) => {
      setFragmentRef(res); // here we will get the fragment Reference
    },
    // a callback that is executed when the subscription errors. throw error
    // so user will get page level error and be prompted to reload
    onError: (err: any) => {
      Sentry.captureException(err);
      throw new Error('upcoming group lessons subscription failed');
    },
    // a callback that is executed when the server ends the subscription
    onCompleted: () => {
      // do nothing, code will continue
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [endOfDay, languageId]);
  useSubscription<GroupLessonsSubscriptionGenerated>(groupLessonConfig);

  // continue to display setup
  return (
    fragmentRef
      ? (
        <Step2DisplaySetup
          fragmentRef={fragmentRef}
          setEndOfDay={setEndOfDay}
          tsSettings={tsSettings}
          daysSelector={daysSelector}
          setDaysSelector={setDaysSelector}
        />
      )
      : <Loading />
  );
};
