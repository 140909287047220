import { graphql } from 'babel-plugin-relay/macro';

// query for the posttrial PrivateUpcoming component
export const PrivateUpcomingDataHomepgQuery = graphql`
  query PrivateUpcomingDataHomepgQuery($currentTs: bigint!) {
    # user data needed for displaying upcoming lessons
    users_connection {
      edges {
        node {
          ...PrivateUpcomingDataUserFragment
        }
      }
    }

    # does at least one upcoming lesson exist? this is used to determine that, and display a slider
    # if one does, or a notif "schedule a lesson!" if one does not
    Anyupcoming: appointments_connection(where: {real_status: {_in: [1, 8]}, ends_at: {_gt: $currentTs}}, first: 1, order_by: {starts_at: asc}) {
      edges {
        node {
          id
        }
      }
    }

    # next 8 upcoming lessons
    Upcomingregular: appointments_connection(where: {real_status: {_in: [1, 8]}, ends_at: {_gt: $currentTs}}, first: 8, order_by: {starts_at: asc}) {
      edges {
        node {
          ...PrivateUpcomingDataLessonsFragment
        }
      }
    }
  }  
`;

// users data needed for displaying lesson date/time of upcoming lessons
export const PrivateUpcomingDataUserFragment = graphql`
  fragment PrivateUpcomingDataUserFragment on users {
    hour_cycle
  }
`;

// upcoming regular lessons, used for the "upcoming 1-on-1 lessons" slider
export const PrivateUpcomingDataLessonsFragment = graphql`
  fragment PrivateUpcomingDataLessonsFragment on appointments {
    ends_at
    starts_at
    id_random
    resources {
      title
    }
  }
`;
