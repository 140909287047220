import { graphql } from 'babel-plugin-relay/macro';

export const TeachersDiscoverDataQuery = graphql`
  query TeachersDiscoverDataQuery {
    ...TeachersDiscoverDataFragment
    
    # lang_learning
    users_connection {
      edges {
        node {
          ...TeachersDiscoverDataLanglearnFragment
        }
      }
    }
  }  
`;

export const TeachersDiscoverDataFragment = graphql`
  fragment TeachersDiscoverDataFragment on query_root {
   resources_connection(where: {working_status: {_is_null: true}, tutor_visibility: {_eq: "1"}}) {
      edges {
        node {
          id
          pk
          uuid
          gets_new_students
          wants_new_students
          provides_free_trial
          title
          stuprof_aboutme
          relayFavorited
          instructor_languages {
            language_id
          }
          resources_files_profileimgs(limit: 1, order_by: {sort_order: asc}, where: {image_approved: {_eq: "1"}, archived: {_eq: "0"}, sort_order: {_gt: -1}}) {
            fuploads {
              file_path
              file_cdn {
                base_url
              }
            }
          }
        }
      }
    }  
  }
`;

export const TeachersDiscoverDataLanglearnFragment = graphql`
  fragment TeachersDiscoverDataLanglearnFragment on users {
    lang_learning
  }
`;
