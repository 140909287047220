import React from 'react';
import {
  CardStandard, Ty, Grid2Ct, Grid2, IcSvgList, Link,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { GridEl } from './Common';
import { sitedata } from '../../../../../../utils/sitedata';

export const GroupClassesSubscribed = () => {
  // link to find a teacher page
  const pageUrl = sitedata.url.app.grouplsnUpcomingDt.pathFull;
  const clickableTypeObjGroupclasses = {
    to: pageUrl,
    component: NavLink,
  };

  return (
    <>
      {/* main component */}
      <GridEl>
        <CardStandard
          titleText="Group Classes"
          titleIcon={IcSvgList.group1}
          color="accentBlue1"
          clickableLink1={{ ...clickableTypeObjGroupclasses }}
          cp={{ sx: { width: '100%' }, 'data-intercom': 'homegroupclassSubscribed' }}
        >
          <Link
            to={pageUrl}
            component={NavLink}
            linkStyle="nostyle"
          >
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty removeMb>
                  You&apos;re subscribed! Click here to get to go to classes
                </Ty>
              </Grid2>
            </Grid2Ct>
          </Link>
        </CardStandard>
      </GridEl>
    </>
  );
};
