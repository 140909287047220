import React from 'react';
import {
  // List,
  Box
} from '@languageconvo/wcl';
import { NavSectionProps } from '../../../../types/nav-section.type';
import { NavbarInnerList } from './NavbarInnerList';
import {
  ListStyleParent,
  ListItemStyleParent,
} from './style';
import useResponsive from '../../../../hooks/useResponsive';
import { LangLearningDialog } from '../../components/langlearningdialog/LangLearningDialog0';
import { NavbarLanglearning } from './langlearning/NavbarLanglearning';
import useLangModal from '../../../../hooks/useLangModal';

// this builds the main content of the left side navbar
export const NavbarInner = ({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) => {
  const isDesktop = useResponsive('up', 'lg');

  // handling a lang learning dialog
  const { openModal, setOpenModal } = useLangModal();

  return (
    <>
      <Box {...other} sx={{ mb: 5 }}>

        {/* Removing default mui list padding. */}
        <ListStyleParent
          disablePadding
        >
          {/* on desktop, this is lang_learning. the language the student is currently studying */}
          {isDesktop && (
            <ListItemStyleParent
              bgsecondary
              onClick={() => setOpenModal(!openModal)}
            >
              <NavbarLanglearning
                isCollapse={isCollapse}
              />
            </ListItemStyleParent>
          )}

          {/* the main contents of the left-side navbar */}
          {navConfig.items.map((list) => (
            <NavbarInnerList
              key={list.title}
              navConfig={navConfig}
              list={list}
              isCollapse={isCollapse}
            />
          ))}
        </ListStyleParent>
      </Box>
      {/* Render the GetStdLearningLang component only when the modal opens */}
      <LangLearningDialog openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};
