/**
 * @generated SignedSource<<ac60da9fd1849b134c681fa227dd2310>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ClientRequest, ClientQuery } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MicDetailsQuery$variables = {};
export type MicDetailsQuery$data = {
  readonly RelayAppSettings: {
    readonly " $fragmentSpreads": FragmentRefs<"MicDetailsFragment">;
  } | null;
};
export type MicDetailsQuery = {
  response: MicDetailsQuery$data;
  variables: MicDetailsQuery$variables;
};

const node: ClientRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MicDetailsQuery",
    "selections": [
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appsetting",
            "kind": "LinkedField",
            "name": "RelayAppSettings",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MicDetailsFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MicDetailsQuery",
    "selections": [
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appsetting",
            "kind": "LinkedField",
            "name": "RelayAppSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MicrophoneDetails",
                "kind": "LinkedField",
                "name": "microphone",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentMicrophoneDetail",
                    "kind": "LinkedField",
                    "name": "current",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "micId",
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isMuted",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AllAvailableMicDetailEdges",
                    "kind": "LinkedField",
                    "name": "all",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AllMicrophoneDetailItem",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "24ddac3834e2dee474c451dff2074831",
    "id": null,
    "metadata": {},
    "name": "MicDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "20474ffb1af2253560a84a140819a781";

export default node;
