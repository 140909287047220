import React from 'react';
import {
  Swiper, Grid2,
} from '@languageconvo/wcl';
import { SwiperContainerUpcominglsn } from './PrivateUpcoming.style';
import { PrivateSchedLsnSliderCard } from './PrivateUpcoming3SliderCard';

interface Props {
  lsn: any
  usr: any
}

// slider component. we'll loop through each upcoming lesson and display PrivateUpcomingSliderCard
export const PrivateUpcomingSlider = ({ lsn, usr } : Props) => (
  <Grid2 xs={12}>
    <SwiperContainerUpcominglsn>
      <Swiper
        ourcustomcolor="accentOrange1"
        ourcustomwidth="100%"
          // number of slides shown is automatically determined by user's screen width
        slidesPerView="auto"
          // kind of a "smooth scrolling" through slides type of effect
        freeMode={{
          enabled: true,
          // this stops a "bounce back" effect which is especially annoying on mobile
          momentumBounce: false,
        }}
        spaceBetween="15"
      >
        {/* loop through each lesson, and display a card with the lesson details */}
        {lsn.edges.map(({ node }: any) => (
          <PrivateSchedLsnSliderCard lesson={node} user={usr.edges[0].node} />
        ))}
      </Swiper>
    </SwiperContainerUpcominglsn>
  </Grid2>
);
