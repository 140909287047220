import { graphql } from 'babel-plugin-relay/macro';

export const WorldTourGetCountriesQuery = graphql`
  query WorldTourGetCountriesQuery {
    practice_world_continents_connection {
      edges {
        node {
          ...WorldTourGetCountriesFragment
        }
      }
    }

    users_connection {
      edges {
        node {
          ...WorldTourGetCountriesLangLearningFragment
        }
      }
    }
  }
`;

export const WorldTourGetCountriesFragment = graphql`
  fragment WorldTourGetCountriesFragment on practice_world_continents {
    id
    pk
    continent_name
    practice_world_countries {
      country_name
      location_id
      locations {
        title
      }
      flag_image
      pk
      id
    }
  }
`;

export const WorldTourGetCountriesLangLearningFragment = graphql`
  fragment WorldTourGetCountriesLangLearningFragment on users {
    lang_learning
  }
`;
