import { graphql } from 'babel-plugin-relay/macro';

/* Use this query to get mic data from the relay store. */
export const UnReadMsgCountQuery = graphql`
  query UnReadMsgCountQuery {
    RelayAppSettings {
      ...UnReadMsgCountFragment
    }
  }
`;

export const UnReadMsgCountFragment = graphql`
  fragment UnReadMsgCountFragment on appsetting {
    message {
        unReadMsgCount
    }
  }
`;
