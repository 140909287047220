import { graphql } from 'babel-plugin-relay/macro';

export const GetUserIdForOrderQuery = graphql`
  query GetUserIdForOrderQuery {
    users_connection {
      edges {
        node {
          pk
        }
      }
    }
  }  
`;
