import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import {
  Grid2, Grid2Ct, useTheme, Card, CardContent,
} from '@languageconvo/wcl';

export const LegacyMyfiles = () => {
  const theme = useTheme();
  const br = theme?.ourTheme.borders.borderRadius.medium;

  return (
    <>
      {/* main content, iframe */}
      <Grid2Ct>
        <Grid2 xs={12}>
          <Card>
            <CardContent>
              <IframeResizer
                id="myFilesFrame"
                src={`${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=classroom/myfiles/`}
                style={{
                  width: '1px',
                  minWidth: '100%',
                  minHeight: '90vh',
                  borderRadius: `${br}px`,
                  border: 'none',
                }}
              />
            </CardContent>
          </Card>
        </Grid2>
      </Grid2Ct>
    </>
  );
};
