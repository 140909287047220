import * as yup from 'yup';
import { tz } from '../../../../../../utils/Timezones';

const timeZonesNames = tz.map((a) => a.nameDb);

export const Step2ValidationFields = yup.object({
  age: yup.string().required('Please set your age').matches(
    /^(100|101|102|103|104|105|106|107|108|109|[1-9][0-9]?|110)$/,
    'Your age seems to be incorrect.',
  ),
  prefTeacherGender: yup.number().required('Please select an option').oneOf([1, 2, 3, 4, 5], 'Please select an option'),
  teacherStyle: yup.number().required('Please select an option').oneOf([1, 2, 3], 'Please select an option'),
  homework: yup.number().required('Please select an option').oneOf([1, 2, 3], 'Please select an option'),
  password: yup.string().required('Please set a password').test(
    'is-empty-or-valid-length',
    'For security, set a password longer than 5 characters',
    (value) => !value || (value.trim().length >= 5 && value.trim().length <= 50),
  ),
  passwordBackend: yup.string().trim().min(5).max(50),
  timezone: yup.string().trim().required('Please select your time zone').oneOf(timeZonesNames, 'Please select an option'),
});

export type Step2ValidationFieldsProps = yup.InferType<typeof Step2ValidationFields>
