/**
 * @generated SignedSource<<caa6627fcb3df0cac3a7e2c68b462b10>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LangLearningDialogFragment$data = {
  readonly id: string;
  readonly lang_learning: number;
  readonly " $fragmentType": "LangLearningDialogFragment";
};
export type LangLearningDialogFragment$key = {
  readonly " $data"?: LangLearningDialogFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LangLearningDialogFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LangLearningDialogFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lang_learning",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "9c0020183e4dffb1299805731653bd9b";

export default node;
