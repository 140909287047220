import React from 'react';
import {
  Swiper,
} from '@languageconvo/wcl';
import { SwiperContainerSchedulelsn } from './PrivateSchedLsn.style';
import { PrivateSchedLsnSliderCard } from './PrivateSchedLsn3SliderCard';

// slider component. we'll loop through each teacher and display PrivateSchedLsnSliderCard
// if the teacher is a favorite
export const PrivateSchedLsnSlider = (allTch: any) => {
  const allTeachers = allTch.allTch;

  return (
    <SwiperContainerSchedulelsn>
      <Swiper
        ourcustomcolor="accentPurple1"
        ourcustomwidth="100%"
        // number of slides shown is automatically determined by user's screen width
        slidesPerView="auto"
        // kind of a "smooth scrolling" through slides type of effect
        freeMode={{
          enabled: true,
          // this stops a "bounce back" effect which is especially annoying on mobile
          momentumBounce: false,
        }}
        spaceBetween="15"
      >
        {/* loop through each teacher, and display card if they are a favorite */}
        {allTeachers.edges.map(({ node }: any) => (
          <PrivateSchedLsnSliderCard fragmentRef={node} />
        ))}
      </Swiper>
    </SwiperContainerSchedulelsn>
  );
};
