import { graphql } from 'babel-plugin-relay/macro';

export const UserDetailsDataQuery = graphql`
  query UserDetailsDataQuery {
    ...UserDetailsDataFragment
  }  
`;

export const UserDetailsDataFragment = graphql`
  fragment UserDetailsDataFragment on query_root {
    users_connection {
      edges {
        node {
          first_name
          last_name
          email
        }
      }
    }
  }
`;
