import React from 'react';
import { useLazyLoadQuery, useFragment, useMutation } from 'react-relay';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import {
  Ty, Grid2Ct, Grid2, Box, IcSvg, IcSvgList,
} from '@languageconvo/wcl';
import { AlignTitle, LanglearnItemContainer } from './LangLearningDialog.style';
import { sitedata } from '../../../../utils/sitedata';
import { LangLearningDialogQuery, LangLearningDialogFragment } from './relay/LangLearningDialog';
import { LangLearningDialogFragment$key } from './relay/__generated__/LangLearningDialogFragment.graphql';
import { LangLearningMutateQuery } from './relay/LangLearningMutate';
import { ModalState } from './LangLearningDialog0';

type LangOptions = {
  id: number;
  title: string;
  flagIcon: IcSvgList;
}

// component for showing list of all language
export const LangLearningDialog1 = ({ setOpenModal, openModal } : ModalState) => {
  // #region get current lang_learning

  const { t } = useTranslation(['languageLocations']);

  // the lang_learning value the user currently has set
  const response: any = useLazyLoadQuery(
    LangLearningDialogQuery,
    {}
  );
  const fragmentRef: LangLearningDialogFragment$key = response.users_connection.edges[0].node;
  const data = useFragment(LangLearningDialogFragment, fragmentRef);
  const studentLangLearnKey = data.lang_learning;
  const userRelayId = data.id;

  // all languages we currently offer, their names and flags
  const languageOptions: LangOptions[] = [];
  sitedata.languages.forEach((el) => {
    const obj = {
      id: el.id,
      title: t(`${el.id}`, { ns: 'languageLocations' }),
      flagIcon: el.flagIcon,
    };
    languageOptions.push(obj);
  });

  // #endregion

  // #region mutate lang_learning

  // when the user clicks on a language, we:
  //  1. optimistically update lang_learning value
  //  2. make the mutation call
  //  3. close the dialog
  const [commitMutation] = useMutation(LangLearningMutateQuery);
  const handleChangeLanglearning = (item: any) => {
    commitMutation({
      variables: {
        languageId: item,
      },
      // this creates our optimistic update. we provide the the data we
      // *expect* to be returned by the mutation. whether the mutation succeeds or
      // fails this gets overwritten once the response is received. note that the relay
      // docs say using optimisticResponse is usually better/easier than using
      // optimisticUpdater to accomplish an optimistic update
      optimisticResponse: {
        stu_settings_langlearningupdate: {
          success: true,
          errors: [],
          user: {
            id: userRelayId,
            lang_learning: item
          }
        }
      },
      onCompleted() {
        // nothing needs to be done if mutation is successful
      },
      onError(err: any) {
        // note: this erroring will be rare, so we are choosing not to show the user a notif that
        // the mutation failed
        Sentry.captureException(err);
      },
    });
    // here we close the modal, in both cases of success and error
    setOpenModal(!openModal);
  };

  // #endregion

  return (
    <Grid2Ct sx={{ mb: 2 }}>
      {/* title */}
      <Grid2 xs={12}>
        <Ty align="center">
          Select the language you&apos;re learning!
        </Ty>
      </Grid2>

      {/* language selections */}
      <Grid2 xs={12} sx={{ mt: 2 }} display="flex" justifyContent="center">
        <Box sx={{ maxWidth: { xs: '270px', sm: '500px' } }}>
          <Grid2Ct>
            { languageOptions.map((el) => (
              <Grid2 xs={12} sm={6} onClick={() => { handleChangeLanglearning(el.id); }}>
                {/* set isSelected for the language the user currently has set lang_learning */}
                <LanglearnItemContainer isSelected={el.id === studentLangLearnKey}>
                  <AlignTitle>
                    <IcSvg icon={el.flagIcon} width="36px" height="36px" />&nbsp;&nbsp;&nbsp;<Ty v="h2New" removeMb>{el.title}</Ty>
                  </AlignTitle>
                </LanglearnItemContainer>
              </Grid2>
            ))}
          </Grid2Ct>
        </Box>
      </Grid2>
    </Grid2Ct>
  );
};
