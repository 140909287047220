import React from 'react';
import {
  Dialog, Ty, Button, Grid2Ct, Grid2, Ic,
} from '@languageconvo/wcl';
import { StudentData } from '../../interfaces/studentData';
import { useVonage } from '../../hooks/useVonage';

interface Props {
  modalState: boolean,
  handleCloseForStudent: () => void,
  selectedStudent: StudentData,
}

export const SelectedStudentDialog = ({
  handleCloseForStudent,
  modalState,
  selectedStudent,
}: Props) => {
  // destructures the unsubscription function from Vonage hook
  const {
    unSubscribeToStream,
    temporarilyMutedStudents,
    setTemporarilyMutedStudents,
  } = useVonage();

  // is this student muted by the current user?
  const thisStudentMuted = temporarilyMutedStudents.includes(selectedStudent.id);

  return (
    <Dialog
      isOpen={modalState}
      onClose={handleCloseForStudent}
      width="sm"
      color="accentPurple1"
    >
      <Grid2Ct>
        <Grid2 xs={12} sx={{ mt: 2, mb: 1 }}>
          <Ty v="h2New">{selectedStudent.name}</Ty>

          {thisStudentMuted ? (
            <Ty cp={{ sx: { mt: 4 } }}>
              <Ic iconStyle="duotone" color="accentRed1" iconName="circle-xmark" />&nbsp;&nbsp;You have muted this student. If you want to unmute them, please refresh the page.
            </Ty>
          ) : (
            <>
              <Ty cp={{ sx: { mt: 4 } }}>
                If this student has bad background noise or a problem with their mic, politely ask
                them to address the problem. If they are not able to, you can click the button below
                to temporarily mute them (this will only cause you to not be able to hear them, it
                will not affect what other students hear). If you want to unmute them,
                refresh/reload the page.
              </Ty>
              <Button
                color="accentPurple1"
                onClick={() => {
                  unSubscribeToStream(selectedStudent.id);
                  setTemporarilyMutedStudents((prev) => [...prev, selectedStudent.id]);
                }}
                cp={{ sx: { mt: 1 } }}
              >
                Temporarily Mute
              </Button>
            </>
          )}
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};
