import {
  Box, styled, WCLThemeProps,
} from '@languageconvo/wcl';
import { UpcomingSlideMeasurements } from '../Common';

// to style each individual slide, we wrap the swiper in a generic div and then add
// swiper-slide css
export const SwiperContainerUpcominglsn = styled(Box)<WCLThemeProps>(({ theme }) => ({
  'swiper-slide': {
    backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentOrange1}`,
    borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
    height: UpcomingSlideMeasurements.height,
    width: UpcomingSlideMeasurements.width,
  },
}));

// colored container for "no upcoming lessons" alert
export const NoUpcomingAlertContainer = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentOrange1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  paddingLeft: '20px',
  // for some reason, this padding top and bottom MUST be here, otherwise this box does
  // not align with the "no teachers favorited" alert box
  paddingTop: '20px',
  paddingBottom: '20px',
  height: UpcomingSlideMeasurements.height,
}));

// colored container for data error
export const ErrorAlertContainer = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentOrange1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  paddingLeft: '20px',
  paddingRight: '20px',
  // for some reason, this padding top and bottom MUST be here, otherwise this box does
  // not align with the "no teachers favorited" alert box
  paddingTop: '20px',
  paddingBottom: '20px',
  height: UpcomingSlideMeasurements.height,
}));
