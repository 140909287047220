import * as React from 'react';
import { CustomCardContent } from './index.style';
import { CardContentProps } from './interface';

/**
 * Our main Card Content component. See:
 *
 * this component will be used, when we need padding inside card component
 *
 * https://mui.com/material-ui/api/card-content/
 *
 */

export const CardContent: React.FunctionComponent<CardContentProps> = (props) => {
  const {
    cp,
    children,
  } = props;
  return (
    <CustomCardContent
      {...cp}
    >
      {children}
    </CustomCardContent>
  );
};
