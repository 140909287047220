import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Link, IcSvg, IcSvgList,
} from '@languageconvo/wcl';
import { useFragment } from 'react-relay';
import { BackbuttonDataFragment } from '../relay/BackbuttonData';
import { BackbuttonDataFragment$key } from '../relay/__generated__/BackbuttonDataFragment.graphql';
import { BackButtonContainer } from './BackButton.style';

interface Props {
  backbtn: BackbuttonDataFragment$key
}

// back button in the top bar. conditionally rendeder; if the user is on a main page, no
// button is rendered. if they're on a subpage, button will be rendered
export const BackButton = ({ backbtn }: Props) => {
  const backButtonData = useFragment(BackbuttonDataFragment, backbtn);

  let displayButton = false;
  let linkTo = '';
  if (backButtonData?.backbutton) {
    displayButton = true;
    linkTo = backButtonData.backbutton;
  }

  return (
    <BackButtonContainer shouldDisplay={displayButton}>
      <Link
        to={linkTo}
        component={NavLink}
        linkStyle="nostyle"
      >
        <IcSvg icon={IcSvgList.arrowleft1} width="24px" height="24px" />
      </Link>
    </BackButtonContainer>
  );
};
