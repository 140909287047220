/**
 * @generated SignedSource<<de3540884a4c898aee91f8ab4e44448e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClassroomBasicDataTermsprivacyMutation$variables = {};
export type ClassroomBasicDataTermsprivacyMutation$data = {
  readonly stu_settings_acceptgrplsnterms: {
    readonly errors: ReadonlyArray<string | null> | null;
    readonly success: boolean;
    readonly user_settings: {
      readonly id: string;
      readonly termsprivacy_grouplsn_id: number | null;
    };
  } | null;
};
export type ClassroomBasicDataTermsprivacyMutation = {
  response: ClassroomBasicDataTermsprivacyMutation$data;
  variables: ClassroomBasicDataTermsprivacyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StuSettingsAcceptgrplsntermsOutput",
    "kind": "LinkedField",
    "name": "stu_settings_acceptgrplsnterms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "user_settings",
        "kind": "LinkedField",
        "name": "user_settings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "termsprivacy_grouplsn_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClassroomBasicDataTermsprivacyMutation",
    "selections": (v0/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClassroomBasicDataTermsprivacyMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "cf2c377da3ec2ec84f7fbf284a375633",
    "id": null,
    "metadata": {},
    "name": "ClassroomBasicDataTermsprivacyMutation",
    "operationKind": "mutation",
    "text": "mutation ClassroomBasicDataTermsprivacyMutation {\n  stu_settings_acceptgrplsnterms {\n    success\n    errors\n    user_settings {\n      id\n      termsprivacy_grouplsn_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9df9676903dfe4d29b509bee955a497f";

export default node;
