import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import {
  Box,
} from '@mui/material';
import { CustomDialog, CustomDialogContent } from './index.style';
import { DialogProps } from './interface';
import { Ic } from '../../datadisplay';
import { Grid2Ct } from '../../layouts/Grid2Ct';

/**
 * Our main Dialog component. See:
 *
 * https://mui.com/material-ui/react-dialog/
 *
 * https://mui.com/material-ui/api/dialog/
 */
export const Dialog: React.FunctionComponent<DialogProps> = (props) => {
  const {
    children,
    width,
    color,
    isOpen = false,
    onClose = undefined,
    showCloseButton = true,
    cp,
  } = props;

  return (
    <CustomDialog
      open={isOpen}
      maxWidth={width}
      fullWidth // we hard code this to true, bc this must be set in order for the maxWidth value to be applied
      color={color}
      onClose={onClose}
      {...cp}
      PaperProps={{
        sx: {
          boxShadow: 'none',
        },
      }}
    >
      {/* close button */}
      {showCloseButton && (
        <Box
          sx={{
            paddingTop: '12px',
            paddingRight: '12px',
          }}
        >
          <Grid2Ct sx={{ mb: 1 }}>
            <Grid2 xs={12}>
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <Ic
                  color={color}
                  iconName="circle-xmark"
                  iconStyle="duotone"
                  size="rel-xl"
                  onClick={onClose}
                  hovercursor="pointer"
                />
              </Box>
            </Grid2>
          </Grid2Ct>
        </Box>
      )}

      {/* main content */}
      <CustomDialogContent>
        {children}
      </CustomDialogContent>
    </CustomDialog>
  );
};
