import React from 'react';
import * as Sentry from '@sentry/react';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { useTranslation } from 'react-i18next';
import { IcSvg, useTheme } from '@languageconvo/wcl';
import { ListItemIconStyle, ListItemTextStyleParent } from '../style';
import { NavbarDataLanglearnQuery, NavbarDataLanglearnFragment } from '../../relay/NavbarData';
import { NavbarDataLanglearnFragment$key } from '../../relay/__generated__/NavbarDataLanglearnFragment.graphql';
import { sitedata } from '../../../../../utils/sitedata';

type Props = {
  isCollapse?: boolean;
}

// the language the student is learning. we get lang_learning from the users table, and display
// both a flag and the language name
export const NavbarLanglearning = ({ isCollapse }: Props) => {
  const { t } = useTranslation(['languageLocations']);
  const theme = useTheme();

  // get the lang_learning value. we *should* already have this value, as it is gotten on pageload,
  // but as always we get it via relay with a query just in case it was garbage collected
  const response: any = useLazyLoadQuery(
    NavbarDataLanglearnQuery,
    {}
  );
  const fragmentRef: NavbarDataLanglearnFragment$key = response.users_connection.edges[0].node;
  const data = useFragment(NavbarDataLanglearnFragment, fragmentRef);
  const studentLangLearnId = data.lang_learning;

  // all languages we currently offer, their names and flags
  let langlearnIcon;
  let langlearnName;
  sitedata.languages.forEach((el) => {
    if (el.id === studentLangLearnId) {
      langlearnIcon = el.flagIcon;
      langlearnName = t(`${el.id}`, { ns: 'languageLocations' });
    }
  });

  // log if we didn't find an icon, and set the flag to a default
  if (!langlearnIcon) {
    langlearnIcon = sitedata.languages[0].flagIcon;
    Sentry.captureException(
      new Error('IMPORTANT - should never happen. No icon was found in MobileLanglearning'),
      {
        extra: {
          icon: langlearnIcon,
        }
      }
    );
  }

  return (
    <>
      <ListItemIconStyle>
        <IcSvg
          icon={langlearnIcon}
          width={theme?.ourTheme.components.navbar.iconSize}
          height={theme?.ourTheme.components.navbar.iconSize}
        />
      </ListItemIconStyle>
      <ListItemTextStyleParent
        disableTypography
        primary={langlearnName}
        isCollapse={isCollapse}
      />
    </>
  );
};
