/**
 * @generated SignedSource<<adff8810e29301a71517c24f47ca09c1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuApptgroupCancelresvMutation$variables = {
  lessonUuid: string;
};
export type StuApptgroupCancelresvMutation$data = {
  readonly stu_apptgroup_cancelresv: {
    readonly data: {
      readonly code: string;
    };
    readonly success: boolean;
  } | null;
};
export type StuApptgroupCancelresvMutation = {
  response: StuApptgroupCancelresvMutation$data;
  variables: StuApptgroupCancelresvMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonUuid"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "lessonUuid",
        "variableName": "lessonUuid"
      }
    ],
    "concreteType": "StuApptgroupCancelresvOutput",
    "kind": "LinkedField",
    "name": "stu_apptgroup_cancelresv",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StuApptgroupCancelresvOutputdata",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StuApptgroupCancelresvMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StuApptgroupCancelresvMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0f98aa49fb88da9b4c3cc959e8a7d9e9",
    "id": null,
    "metadata": {},
    "name": "StuApptgroupCancelresvMutation",
    "operationKind": "mutation",
    "text": "mutation StuApptgroupCancelresvMutation(\n  $lessonUuid: String!\n) {\n  stu_apptgroup_cancelresv(lessonUuid: $lessonUuid) {\n    data {\n      code\n    }\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "e59a8fb34eb67d60e31c86d4f72d52a4";

export default node;
