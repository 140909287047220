/**
 * @generated SignedSource<<85de2769078d0e55b16b0b8294298cb9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ClientRequest, ClientQuery } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnReadMsgCountQuery$variables = {};
export type UnReadMsgCountQuery$data = {
  readonly RelayAppSettings: {
    readonly " $fragmentSpreads": FragmentRefs<"UnReadMsgCountFragment">;
  } | null;
};
export type UnReadMsgCountQuery = {
  response: UnReadMsgCountQuery$data;
  variables: UnReadMsgCountQuery$variables;
};

const node: ClientRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnReadMsgCountQuery",
    "selections": [
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appsetting",
            "kind": "LinkedField",
            "name": "RelayAppSettings",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UnReadMsgCountFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UnReadMsgCountQuery",
    "selections": [
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appsetting",
            "kind": "LinkedField",
            "name": "RelayAppSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnReadMessageDetails",
                "kind": "LinkedField",
                "name": "message",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unReadMsgCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "d241151701248330adac0d798624dba3",
    "id": null,
    "metadata": {},
    "name": "UnReadMsgCountQuery",
    "operationKind": "query",
    "text": null
  }
};

(node as any).hash = "2021663df71747fa5a2f514e2c6638ea";

export default node;
