/**
 * @generated SignedSource<<2f2ee51464b7313a623d96107fa82e2d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuSubsCreatecheckoutlinkMutation$variables = {};
export type StuSubsCreatecheckoutlinkMutation$data = {
  readonly stu_subs_createcheckoutlink: {
    readonly checkoutUrl: string;
    readonly errors: ReadonlyArray<string | null>;
    readonly success: boolean;
  } | null;
};
export type StuSubsCreatecheckoutlinkMutation = {
  response: StuSubsCreatecheckoutlinkMutation$data;
  variables: StuSubsCreatecheckoutlinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StuSubsCreatecheckoutlinkOutput",
    "kind": "LinkedField",
    "name": "stu_subs_createcheckoutlink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "checkoutUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StuSubsCreatecheckoutlinkMutation",
    "selections": (v0/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StuSubsCreatecheckoutlinkMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5a0d6692fcb24f9894a88320bac528db",
    "id": null,
    "metadata": {},
    "name": "StuSubsCreatecheckoutlinkMutation",
    "operationKind": "mutation",
    "text": "mutation StuSubsCreatecheckoutlinkMutation {\n  stu_subs_createcheckoutlink {\n    checkoutUrl\n    errors\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "879230d2460af6e92ff72ae01686fd74";

export default node;
