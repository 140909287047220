import React, { Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { UsersSettingsQuery } from './relay/UsersSettings';
import { Main, MainLoading } from './components/Main';

export const Settings = () => {
  const fragmentRef: any = useLazyLoadQuery(
    UsersSettingsQuery,
    { }
  );

  return (
    <Suspense fallback={<MainLoading />}>
      <Main
        fragmentRef={fragmentRef}
      />
    </Suspense>
  );
};
