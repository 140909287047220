import * as React from 'react';
import {
  Container as CustomContainer,
} from '@mui/material';
import { ContainerProps } from './interface';

/**
 * Our main Card component. See:
 *
 * https://mui.com/material-ui/react-container/
 *
 * https://mui.com/material-ui/api/container/
 */

export const Container: React.FunctionComponent<ContainerProps> = (props) => {
  const {
    // default width is xxl, max page width
    maxWidth = 'xxl',
    children,
    cp,
  } = props;
  return (
    <CustomContainer
      maxWidth={maxWidth === 'pageContainer' ? 'xxl' : maxWidth}
      {...cp}
    >
      {children}
    </CustomContainer>
  );
};
