import React, { useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {
  Box, Card, CardContent, Grid2, Grid2Ct, IcSvg, IcSvgList, Ty, Skeleton,
} from '@languageconvo/wcl';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WorldTourGetCountriesQuery } from './relay/WorldTourGetCountries';
import { GlobalTourDataReading } from './GlobalTour1';
import { WorldTourGetCountryDetailQuery } from './relay/WorldTourGetCountryDetail';
import { SelectedCountry } from './components/SelectedCountry0';
import { Countries } from './GlobalTour2';

export const GlobalTourLoadData = () => {
  // #region general for state variables

  const { t } = useTranslation(['languageLocations']);

  // state to toggle between continent and country detail views
  const [showContinentPage, setShowContinentPage] = useState(false);

  // whether to show the full text of the country overview, or a shortened version
  const [showFullOverview, setShowFullOverview] = useState<boolean>(false);

  // state to control radio playback.
  // we keep playing the radio in the background, we only stop the
  // radio when user/student go to another country page and select the new radio station,
  // else we keep playing the radio in the background
  const [shouldStopRadio, setShouldStopRadio] = useState(false);

  // state to hold randomized fun facts, to show the facts on the UI.
  const [shuffledFunFacts, setShuffledFunFacts] = useState([]);

  // extracting `countryId` from the URL to load details of a selected country
  const [searchParams] = useSearchParams();
  const countryId = searchParams.get('id');
  const globalTourCountryIdFromParams = Number(countryId);

  // state for countries which speaks user selected language
  const [userSelectedLangCountries, setUserSelectedLangCountries] = useState<any>([]);

  const [pageLoaded, setPageLoaded] = useState(false);

  // state to store all countries
  const [allCountries, setAllCountries] = useState([]);

  // state to store selected language id
  const [userSelectedLangId, setUserSelectedLangId] = useState(0);

  // here we are filtering the countries where language speak === userSelectedLang
  // beacuse we want to show these countries at top of the page.
  useEffect(() => {
    if (allCountries.length > 0 && userSelectedLangId !== 0) {
      const filteredCountries = allCountries.filter(
        (item: any) => item.location_id === userSelectedLangId
      );

      setUserSelectedLangCountries(() => [...filteredCountries]);
      setPageLoaded(true);
    }
  }, [userSelectedLangId, allCountries]);

  // #endregion

  // #region executing queries
  const response: any = useLazyLoadQuery(
    WorldTourGetCountriesQuery,
    { },
  );

  // reading all edges
  const allEdges = response.practice_world_continents_connection.edges;

  // reading langLeanring node
  const langLeanringRef = response.users_connection.edges[0].node;

  // query of getting selected language data
  const responseOfCountryDetails: any = useLazyLoadQuery(
    WorldTourGetCountryDetailQuery,
    {
      // passing countryId to the query
      countryId
    },
  );

  // reading edges of selected country
  const selectedCountryEdges = responseOfCountryDetails.practice_world_countries_connection.edges;

  // get the selected country node, if there is no node initialize it to null
  const selectedCountryNode = selectedCountryEdges.length > 0 ? selectedCountryEdges[0].node : null;

  // #endregion

  return (
    <>
      <Box
        sx={pageLoaded ? {
          display: 'block'
        } : {
          display: 'none',
        }}
      >
        {/* continent page */}
        <Box
          sx={globalTourCountryIdFromParams === 0
            || showContinentPage || selectedCountryNode === null
            ? {
              display: 'block'
            } : {
              display: 'none',
            }}
        >
          {/* countries where language speak === selected language */}
          <Grid2Ct>
            <Grid2 xs={12}>
              <Card>
                <CardContent>
                  {/* title */}
                  <Grid2Ct>
                    <Grid2 xs={10}>
                      <Ty v="h1New">{t(`${userSelectedLangId}`)} - Speaking Countries</Ty>
                    </Grid2>

                    {/*
                    conditionally render the close icon to navigate back to the previous view.
                    only displayed if the continent page is shown and the cross is enabled.  
                  */}
                    {showContinentPage && (
                    <Grid2 xs={2}>
                      <Box
                        sx={{
                          cursor: 'pointer',
                        }}
                        display="flex"
                        justifyContent="flex-end"
                        onClick={() => setShowContinentPage(false)}
                      >
                        <IcSvg
                          icon={IcSvgList.cancel2}
                          width="32px"
                          height="32px"
                        />
                      </Box>
                    </Grid2>
                    )}
                  </Grid2Ct>

                  {/* each individual country */}
                  <Grid2Ct sx={{ mt: 2 }}>
                    {userSelectedLangCountries.length > 0 ? (
                      <Countries
                        countires={userSelectedLangCountries}
                        setShowContinentPage={setShowContinentPage}
                        setShouldStopRadio={setShouldStopRadio}
                        setShuffledFunFacts={setShuffledFunFacts}
                        setShowFullOverview={setShowFullOverview}
                      />
                    ) : (
                      <Grid2 xs={12}>
                        <Ty v="h2New">
                          Our apologies, there aren&apos;t any countries for this
                          language in our list yet.
                        </Ty>
                      </Grid2>
                    )}
                  </Grid2Ct>
                </CardContent>
              </Card>
            </Grid2>

            {/* countries rendered based on continents */}
            <Grid2 xs={12}>
              <Card>
                <CardContent>
                  {allEdges.map((singleEdge: any) => {
                    const { node } = singleEdge;
                    return (
                      <GlobalTourDataReading
                        fragmentRef={node}
                        langLearningFragmentRef={langLeanringRef}
                        setShowContinentPage={setShowContinentPage}
                        setShouldStopRadio={setShouldStopRadio}
                        setShuffledFunFacts={setShuffledFunFacts}
                        setUserSelectedLangId={setUserSelectedLangId}
                        setAllCountries={setAllCountries}
                        setShowFullOverview={setShowFullOverview}
                      />
                    );
                  })}
                </CardContent>
              </Card>
            </Grid2>
          </Grid2Ct>
        </Box>

        {/* selected country page */}
        <Box
          sx={globalTourCountryIdFromParams === 0 || showContinentPage
            ? {
              display: 'none'
            } : {
              display: 'block',
            }}
        >
          {selectedCountryNode !== null && (
          <SelectedCountry
            selectedCountryNode={selectedCountryNode}
            setShowContinentPage={setShowContinentPage}
            setShouldStopRadio={setShouldStopRadio}
            shouldStopRadio={shouldStopRadio}
            shuffledFunFacts={shuffledFunFacts}
            setShuffledFunFacts={setShuffledFunFacts}
            setShowFullOverview={setShowFullOverview}
            showFullOverview={showFullOverview}
            showContinentPage={showContinentPage}
          />
          )}
        </Box>
      </Box>
      <Box
        sx={!pageLoaded ? {
          display: 'block'
        } : {
          display: 'none',
        }}
      >
        <GlobalTourLoading />
      </Box>
    </>
  );
};

// loading screen
export const GlobalTourLoading = () => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <Card>
        <CardContent>
          <Grid2Ct>
            <Grid2 xs={12} sm={6} md={5}>
              <Skeleton variant="text" width="100%" height={80} />
            </Grid2>
          </Grid2Ct>
          <Grid2Ct>
            <Grid2 xs={10} sm={5} md={4}>
              <Skeleton variant="text" width="100%" height={40} />
            </Grid2>
          </Grid2Ct>
          <Grid2Ct>
            <Grid2 xs={12}>
              <Skeleton variant="rectangular" width="100%" height={200} />
            </Grid2>
          </Grid2Ct>
          <Grid2Ct>
            <Grid2 xs={10} sm={5} md={4}>
              <Skeleton variant="text" width="100%" height={40} />
            </Grid2>
          </Grid2Ct>
          <Grid2Ct>
            <Grid2 xs={12}>
              <Skeleton variant="rectangular" width="100%" height={200} />
            </Grid2>
          </Grid2Ct>
          <Grid2Ct>
            <Grid2 xs={10} sm={5} md={4}>
              <Skeleton variant="text" width="100%" height={40} />
            </Grid2>
          </Grid2Ct>
          <Grid2Ct>
            <Grid2 xs={12}>
              <Skeleton variant="rectangular" width="100%" height={200} />
            </Grid2>
          </Grid2Ct>
        </CardContent>
      </Card>
    </Grid2>
  </Grid2Ct>
);
