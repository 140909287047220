import {
  Box, styled, WCLThemeProps,
} from '@languageconvo/wcl';

// TODO: all three of these containers could probably one single component with
// some props, but we can do that in future
export const OptionsContainers = styled(Box)<WCLThemeProps>(({ theme }) => ({
  // width 100% is needed for equal height boxes, see our mui documentation
  width: '100%',
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentOrange1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  padding: '20px 20px 20px 20px',
}));

export const OptionsContainers2 = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentPurple1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  padding: '20px 20px 20px 20px',
}));

export const OptionsContainers3 = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentRed1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  padding: '20px 20px 20px 20px',
}));
