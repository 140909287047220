import React from 'react';
import {
  Grid2,
} from '@languageconvo/wcl';

// The main Grid2 element that wraps each main CardStandard
export const GridEl = (props: any) => {
  const {
    children,
  } = props;

  return (
    <Grid2
      xs={12}
      sm={12}
      lg={6}
      sx={{
        display: 'flex',
      }}
    >
      {children}
    </Grid2>
  );
};

// the height and width of the slides for "upcoming lessons" slider.
// these measures are used by both the upcoming lessons slider, an error container for that
// slider, AND an error container for the "schedule a 1-on-1 lesson" slider, so we define
// them centrally here
const h = 175;
const hPlusPadding = h + 16;
export const UpcomingSlideMeasurements = {
  height: `${h}px`,
  width: '180px',
  heightPlusPadding: `${hPlusPadding}px`,
};
