// data which give the brief overview of how our group classes work
export const groupClsOverviewDataNotsubbed = [
  {
    iconName: 'users',
    iconStyle: 'duotone',
    iconColor: 'accentPurple1',
    title: 'Class Format',
    text: "Welcome to one of the most effective, affordable ways to improve your foreign language skills! Group classes are just what they sound like: you, one of our professional language teachers, and a few other students. The format of most classes is split into two parts; in the first half of the class, your teacher will teach you a topic. In the second half of the class, you'll practice what you learned by conversing with other students. In each class there can be a total of 8 students, which your teacher will split into two conversation groups of 4 students each. So you'll be conversing with 3 other students, practicing what you learned in the first half of the class! Your teacher will jump between the two conversation groups."
  },
  {
    iconName: 'language',
    iconStyle: 'duotone',
    iconColor: 'accentPurple1',
    title: 'Class Schedule',
    text: "We vary the schedule and class topics every single day, so that no matter your schedule there will be many classes every week that will fit your schedule. If you don't see a time that works for you, *please* message us -- we can pretty easily add new times, especially as more students join."
  },
  {
    iconName: 'language',
    iconStyle: 'duotone',
    iconColor: 'accentPurple1',
    title: 'Class Topics',
    text: "Each class has a topic, and you may think to yourself that you need to carefully consider which topics you study. What research, and our collective experience as language teachers shows is that you will do best if you practice and use the language you want to learn! So don't worry about trying to cover every specific topic in some predefined order; instead, attend classes so that you hear and use the language! Just jump in. Practice using the language."
  },
  {
    iconName: 'chart-line',
    iconStyle: 'duotone',
    iconColor: 'accentPurple1',
    title: 'Ability Levels',
    text: 'Every class has a level listed: Beginner, Beginner+, Intermediate, Intermediate+, Advanced, or Advanced+. Try to attend classes that are at or below your level. Consider Intermediate level to mean you are able to hold some conversation, whether it be relatively simple conversation ("Intermediate") or more complex conversation ("Intermediate"). Beginner levels are of course easier than that, and advanced levels are more difficult.'
  },
  {
    iconName: 'language',
    iconStyle: 'duotone',
    iconColor: 'accentPurple1',
    title: 'Other Students',
    text: "During the second half of the class you'll practice what you learned, by conversing with the other students in the class. Your teacher will split you into two groups of 4. So, your conversation group will include you and up to 3 other students. This small group allows you to get as much conversational practice as you want! Your teacher will hop between the two groups."
  },
  {
    iconName: 'language',
    iconStyle: 'duotone',
    iconColor: 'accentPurple1',
    title: 'The Teacher',
    text: "All of our teachers are expert language instructors, not part time 'tutors'. This is true in group classes as well as private lessons! In private lessons you do get the same teacher every lesson; here in group classes, we have a number of different teachers that teach classes."
  },
  {
    iconName: 'language',
    iconStyle: 'duotone',
    iconColor: 'accentPurple1',
    title: 'Free Trial',
    text: "You probably haven't tried small group classes in the past, so we want you to be able to try it out and see if it's a good learning avenue for you. We allow you to attend classes on two days, completely for free. Attend as many classes as you want / have time for on those two days. We think you'll enjoy it!"
  },
  {
    iconName: 'language',
    iconStyle: 'duotone',
    iconColor: 'accentPurple1',
    title: 'Unlimited Classes',
    text: "You've probably already read about our really unique payment format -- group classes are a low-cost subscription, sort of like Netflix, that allows you to attend unlimited classes. Attend as many classes as you have time for! You can stop or restart your subscription at any time, easily through the website."
  },
  {
    iconName: 'language',
    iconStyle: 'duotone',
    iconColor: 'accentPurple1',
    title: 'Start Subscription',
    text: "You don't have to call or come into our office to start your subscription, you can do so right on the Purchase page here in your account. It takes about 30 seconds!"
  },
  {
    iconName: 'language',
    iconStyle: 'duotone',
    iconColor: 'accentPurple1',
    title: 'Cancel Subscription',
    text: "You can cancel your subscription any time, easily here on the Purchase page in your account. You don't have to call or email or come into our office to cancel. After canceling, you can still attend classes through the end of your current month billing period; once the current billing period ends you won't be charged anymore."
  }
];
