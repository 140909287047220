import React, { Suspense } from 'react';
import {
  Grid2Ct, Grid2, CardStandard, IcSvgList,
} from '@languageconvo/wcl';
import { OrdersHistoryTable } from './components/OrdersHistoryTable';
import { TableLoader } from '../../../common/loaders/TableLoader';
import { TipsHistoryTable } from './components/TipsHistoryTable';
import { GetUserIdForGiftsHistoryTable } from './components/GetUserIdForGiftsHistoryTable';

export const PurchaseHistoryHome = ({ pageSize }: { pageSize: number }) => {
  // dont allow access to this page in prod
  // if (process.env.REACT_APP_ENV === 'prod') {
  //   throw new Error('no prod access');
  // }
  // eslint-disable-next-line
  const x = 1;

  return (
    <>
      {/* main content */}
      <Grid2Ct>
        {/* TODO: expired packages should be highlighted in red */}
        {/* TODO: for admincreate packages, we do not currently show the admincreate_description
              That is the note an admin wrote to the student about giving them a package. We need
              to show it!
        */}
        {/* TODO: we should wrap the suspense in an err boundary, so we don't get a page err */}
        {/* 1-on-1 lesson packages (orders). this includes:
              - regular, normal purchases that are active
              - regular, normal purchases that have been refunded
              - gifts that I *redeemed*
        */}
        <Grid2 xs={12}>
          <CardStandard
            titleText="Private Lessons"
            titleIcon={IcSvgList.graduationcap1}
            color="accentGreen1"
            titleIconRight={0}
          >
            <Grid2Ct sx={{ mt: 2 }}>
              <Grid2 xs={12}>
                <Suspense fallback={<TableLoader />}>
                  <OrdersHistoryTable pageSize={pageSize} />
                </Suspense>
              </Grid2>
            </Grid2Ct>
          </CardStandard>
        </Grid2>

        {/* teacher tips */}
        {/* TODO: we are showing refunded tips, but not noting that in the ui */}
        {/* TODO: we should wrap the suspense in an err boundary, so we don't get a page err */}
        <Grid2 xs={12}>
          <CardStandard
            titleText="Teacher Tips"
            titleIcon={IcSvgList.teacher4}
            color="accentOrange1"
            titleIconRight={0}
          >
            <Grid2Ct sx={{ mt: 2 }}>
              <Grid2 xs={12}>
                <Suspense fallback={<TableLoader />}>
                  <TipsHistoryTable pageSize={pageSize} />
                </Suspense>
              </Grid2>
            </Grid2Ct>
          </CardStandard>
        </Grid2>

        {/* gift purchases */}
        {/* TODO: we are not getting/showing gift purchases that were refunded */}
        {/* TODO: we should wrap the suspense in an err boundary, so we don't get a page err */}
        <Grid2 xs={12}>
          <CardStandard
            titleText="Private Lesson Gifts"
            titleIcon={IcSvgList.gift1}
            color="accentOrange1"
            titleIconRight={0}
          >
            <Grid2Ct sx={{ mt: 2 }}>
              <Grid2 xs={12}>
                <Suspense fallback={<TableLoader />}>
                  <GetUserIdForGiftsHistoryTable pageSize={pageSize} />
                </Suspense>
              </Grid2>
            </Grid2Ct>
          </CardStandard>
        </Grid2>
      </Grid2Ct>
    </>
  );
};
