/**
 * @generated SignedSource<<c00b833569887ca0082a30910267cb3c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDetailsDataQuery$variables = {};
export type UserDetailsDataQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserDetailsDataFragment">;
};
export type UserDetailsDataQuery = {
  response: UserDetailsDataQuery$data;
  variables: UserDetailsDataQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserDetailsDataQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserDetailsDataFragment"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserDetailsDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a217b4c9f09db50770e9dfc21c046841",
    "id": null,
    "metadata": {},
    "name": "UserDetailsDataQuery",
    "operationKind": "query",
    "text": "query UserDetailsDataQuery {\n  ...UserDetailsDataFragment\n}\n\nfragment UserDetailsDataFragment on query_root {\n  users_connection {\n    edges {\n      node {\n        first_name\n        last_name\n        email\n        id\n      }\n    }\n  }\n}\n"
  }
};

(node as any).hash = "b8760809050b0779172efaeb18819d84";

export default node;
