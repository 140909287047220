import {
  AppBar, Toolbar, Box, styled, WCLThemeProps,
} from '@languageconvo/wcl';
import cssStyles from '../../../utils/cssStyles';
import { HEADER } from '../../../config';

type RootStyleProps = {
    isDesktop: boolean | null;
};

// #region desktop appbar - lg and larger screens

export const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isDesktop',
})<RootStyleProps & WCLThemeProps>(({
  isDesktop, theme
}) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  transition: theme?.ourTheme.components.transition.page,
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  },
  // to make clipped appBar, we need to apply z-index greater than drawer.
  zIndex: isDesktop ? theme.zIndex.drawer + 1 : theme.zIndex.appBar + 1,
  // bg color of the entire header
  backgroundColor: `hsl(${theme.ourTheme.colors.app.navbar})`,
}));

export const ToolBar = styled(Toolbar)(({ theme }: WCLThemeProps) => ({
  minHeight: '100% !important',
  [theme!.breakpoints.up('lg')]: {
    px: 5
  }
}));

// logo in the header
export const Logo = styled('img')({
  position: 'relative'
});

// on small screens, this is the hamburger icon that opens the drawer
// TODO: this should be a wcl component
export const Hamburger = styled(Box)(({ theme }: WCLThemeProps) => ({
  transition: theme?.ourTheme.components.transition.item,
  width: '40px',
  height: '40px',
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  ...(theme?.themeMode === 'light' && {
    color: `hsl(${theme?.ourTheme.colors.text.base})`,
    '&:hover': {
      backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter2})`,
    },
  }),
  ...(theme?.themeMode === 'dark' && {
    color: `hsl(${theme?.ourTheme.colors.text.base})`,
    '&:hover': {
      backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter1})`,
      color: `hsl(${theme?.ourTheme.colors.primary.darkest})`,
    },
  }),
}));

export const HeaderSpace = styled(Box)(() => ({
  flexGrow: 1
}));

// #endregion

// #region mobile appbar - md and smaller screens

export const MobileNavbar = styled(Box)(({ theme }: WCLThemeProps) => ({
  // position the navbar fixed to the top of the page
  position: 'absolute',
  top: 0,
  bottom: 0,
  height: '75px',
  minHeight: '75px',
  maxHeight: '75px',
  width: '100%',
  // overflow ensures there's no vertical scrollbar, but there is one vertically
  overflowX: 'auto',
  overflowY: 'hidden',
  // not sure why nowrap is needed. multiple tutorials online listed it
  whiteSpace: 'nowrap',
  // our appbar/navbar bg color
  backgroundColor: `hsl(${theme?.ourTheme.colors.app.navbar})`,
  // this removes a blue background color that was happening when clicking on
  // navbar items
  WebkitTapHighlightColor: 'transparent',
  // centers the div. only applicable on larger screens, where the width of the screen
  // is wider than all the icon divs put together
  textAlign: 'center',
  // on small screens the home and more icons are too close to the edges. adding padding
  // fixes that problem
  paddingLeft: '9px',
  paddingRight: '11px',
}));

// #endregion
