import React from 'react';
import { useFragment } from 'react-relay';
import { Settings } from 'luxon';
import { GetUserData } from '../../relay/queries/GetUserData';

// #region for interface
interface Props {
    fragmentRef: any
}
// #endregion
export const UserTimeZoneSetter = ({ fragmentRef }: Props) => {
  // #region for setting user's default time zone in whole app 
  // reading user's data via fragment
  const userData = useFragment(GetUserData, fragmentRef);
  const userInfo = userData.users_connection.edges[0].node;
  // We'r setting timeZone info in luxon default Settings for the whole app, now we get the same
  // timezone information.
  Settings.defaultZone = userInfo.user_timezone;
  // #endregion
  return (
    <div />
  );
};
