import { graphql } from 'babel-plugin-relay/macro';

export const BasicUsersSettingsUpdates = graphql`
  mutation BasicUsersSettingsUpdatesMutation(
    $password: String
    $timezone: String!
    $age: Int!
    $firstName: String!
    $lastName: String!
    $email: String!
    $countryId: Int!
    $hourCycle: String!
) {
stu_settings_updategeneral(
password: $password
timezone: $timezone
age: $age,
  firstName: $firstName,
  lastName: $lastName,
  email: $email,
  country: $countryId,
  hourCycle: $hourCycle
) {
    success
    errors
    # here we return all of the db values in the users table that
    # might have been edited, along with the relay global id
    user {
      id
      dob 
      user_timezone
      password_set
      timezone_set
          first_name
          last_name
          email
          hour_cycle
          country_id_num
    }
  }
}
`;
