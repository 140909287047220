import React, { useContext, useLayoutEffect } from 'react';
import {
  Ty,
} from '@languageconvo/wcl';
import { ClassroomSetupContext } from '../../GroupClassroom';
import { StandardLottieScreen, PageloadRabbitLottie } from '../pageview/Pageview';

interface Props {
  secondsRemainingInLessonStart: number,
}

// here we are showing countdown when student is enter in the classroom when time remaining in
// lesson start is less than 5 minutes.
export const Setup4ViewSetup3Countdownlock = ({
  secondsRemainingInLessonStart,
}: Props) => {
  // completely hide all page views, the countdown clock is its own view
  const csSetupContext = useContext(ClassroomSetupContext);
  useLayoutEffect(() => {
    csSetupContext.setPageviewState(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // IMPORTANT: if you change the design of the inner text, you also need to change the
  // design in Pageview.tsx. We should probably refactor things so there is not these
  // weird interdependencies, maybe setting secondsRemainingInLessonStart state at the
  // very top of the classroom code flow and using that in Pageview
  return (
    <StandardLottieScreen
      topsection={(<PageloadRabbitLottie />)}
      bottomsection={(
        <Ty v="h2New" align="center">Class starts in...&nbsp;{Math.floor(secondsRemainingInLessonStart / 60).toString()}:{String(Math.floor(secondsRemainingInLessonStart % 60).toFixed(0)).padStart(2, '0')}</Ty>
    )}
    />
  );
};
