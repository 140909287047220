/**
 * @generated SignedSource<<07d11312ec2b94c57e6d172f761e0452>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ClientRequest, ClientQuery } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BackbuttonDesktopDataQuery$variables = {};
export type BackbuttonDesktopDataQuery$data = {
  readonly RelayAppSettings: {
    readonly " $fragmentSpreads": FragmentRefs<"BackbuttonDesktopDataFragment">;
  } | null;
};
export type BackbuttonDesktopDataQuery = {
  response: BackbuttonDesktopDataQuery$data;
  variables: BackbuttonDesktopDataQuery$variables;
};

const node: ClientRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BackbuttonDesktopDataQuery",
    "selections": [
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appsetting",
            "kind": "LinkedField",
            "name": "RelayAppSettings",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BackbuttonDesktopDataFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BackbuttonDesktopDataQuery",
    "selections": [
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appsetting",
            "kind": "LinkedField",
            "name": "RelayAppSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backbutton",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "ae2a56b0a2b96cec1d9cd543cd261831",
    "id": null,
    "metadata": {},
    "name": "BackbuttonDesktopDataQuery",
    "operationKind": "query",
    "text": null
  }
};

(node as any).hash = "054b0299a95d4030444e83cedac81717";

export default node;
