import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import {
  Grid2, Grid2Ct, useTheme,
} from '@languageconvo/wcl';

export const LegacyAvtest = () => {
  const theme = useTheme();
  const br = theme?.ourTheme.borders.borderRadius.medium;

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        <IframeResizer
          src={`${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=classroom/test/`}
          style={{
            width: '1px',
            minWidth: '100%',
            borderRadius: `${br}px`,
            border: 'none',
          }}
        />
      </Grid2>
    </Grid2Ct>
  );
};
