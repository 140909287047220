import { graphql } from 'babel-plugin-relay/macro';

export const PackagesDetail = graphql`
  fragment PackagesDetail on query_root {
    locations_connection(where: {pk: {_neq: 2}}) {
      edges {
        node {
          pk
          title
          packs(where: {package_type_id: {_eq: 1}}) {
            pk
            price
            duration
          }
        }
      }
    }
  }
`;
