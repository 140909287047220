import { ScrollArea } from '../../layouts/ScrollArea';
import { StyleContainer } from './HeightContainer.style';
import { HeightContainerProps } from './interface';

export const HeightContainer = (props: HeightContainerProps) => {
  const {
    maxHeight,
    children,
    cp,
  } = props;

  return (
    <StyleContainer
      maxHeight={maxHeight}
      {...cp}
    >
      <ScrollArea>
        {children}
      </ScrollArea>
    </StyleContainer>
  );
};
