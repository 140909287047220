import { graphql } from 'babel-plugin-relay/macro';

export const PurchaseRecieptQuery = graphql`
  query PurchaseRecieptQuery($invoiceNumber: String!) {
    orders_connection(where: {
      paypal_invoice_number: {
        _eq: $invoiceNumber
      }
    }) {
      edges {
        node {
          ...PurchaseRecieptFragment
        }
      }
    }
  }
`;

export const PurchaseRecieptFragment = graphql`
  fragment PurchaseRecieptFragment on orders {
    actual_purchase_price
    created_at
    duration
    locations {
      pk
    }
    paypal_invoice_number
  }
`;
