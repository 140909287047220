import React, { useState, useEffect, useContext } from 'react';
import { useFragment } from 'react-relay';
import * as Sentry from '@sentry/react';
import { RoomLayoutFragment } from '../../relay/RoomLayout';
import { usePusher } from '../../hooks/usePusher';
import { Setup4ViewSetup2LessonTimeCalc } from './Setup4ViewSetup2LessonTimeCalc';
import { VonageSessionManager } from '../../utils/VonageSessionManager';
import { ClassroomViewFragment } from '../../relay/ClassroomView';
import { RoomLayoutFragment$key } from '../../relay/__generated__/RoomLayoutFragment.graphql';
import { TeacherOnly } from '../lecture/TeacherOnly';
import { GroupChat } from '../groupchat/GroupChat';
import { ClassroomSetupContext } from '../../GroupClassroom';
import { CustomErrBoundaries } from '../maincontainer/CustomErrBoundary';

interface Props {
  roomLayoutRef: RoomLayoutFragment$key
  initialClassroomDataRef: any
  setmodalviewContents: any
  setmodalviewState: any
}

/*  The three main views we can display. In future, something like this should probably
    replace the "Pageview context" we created.

    PrePostClass = the lesson has not started, or has already ended
    Lecture = lesson is in lecture (teacher only) mode
    Group = lesson is in group chat mode
*/
export enum WhichViewTy {
  PrePostClass = 'PrePostClass',
  Lecture = 'Lecture',
  Group = 'Group',
}

// This is our Decider component it will decide which view needs to be show 
// to the student(i-e coundown clock, teacher only mode/group chat mode or lessonende  
// based on second remaining in lesson start and lessonended state) 
export const Setup4ViewSetup1 = ({
  roomLayoutRef,
  initialClassroomDataRef,
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  // reading pusher channel name from initial classroom data.
  const lessonInfo = useFragment(ClassroomViewFragment, initialClassroomDataRef);

  // context for showing page view
  const csSetupContext = useContext(ClassroomSetupContext);

  // reading roomLayout Data via useFragment 
  const roomLayoutData = useFragment(RoomLayoutFragment, roomLayoutRef);
  const roomLayoutNode = roomLayoutData.appt_group_connection.edges[0].node;
  const classRoomLayoutData = JSON.parse(roomLayoutNode!.appt_group_layout!.layout);
  const { roomView } = classRoomLayoutData;
  // TODO: this is a dangerous, unreliable way to get the current user's info. instead we should
  //  be getting it via users.user_publicinfo
  // current student's uuid (from user_publicinfo table)
  const currentStudentUuuid = roomLayoutNode.appt_group_reservations[0].users.user_publicinfo!.uuid;

  // connect to pusher channel. onlineUsers is an array of user uuids that are currently online
  // in the class. thisUserEnteredTwice is a boolean, if true it means that this user has
  // entered the class in two different browsers/tabs
  const { onlineUsers, thisUserEnteredTwice } = usePusher(
    lessonInfo.pusherChannel,
    currentStudentUuuid,
  );

  // true once the lesson has started, false otherwise
  // we use this many places. it's used to update the ui (remove the countdown clock
  // once the lesson starts), allow user to connect to the vonage session, etc.
  const [hasLessonStarted, setHasLessonStarted] = useState<boolean>(false);

  // true once the lesson has ended, false otherwise
  // we use this in many places. it's used to update the ui (show the "lesson ended" screen),
  // not allow the user to connect to vonage anymore, etc.
  const [hasLessonEnded, setHasLessonEnded] = useState<boolean>(false);

  // which main "view" are we in? see WhichViewTy for details
  const [whichView, setWhichView] = useState<WhichViewTy>(WhichViewTy.PrePostClass);
  useEffect(() => {
    if (!hasLessonStarted || hasLessonEnded) {
      setWhichView(WhichViewTy.PrePostClass);
    } else if (hasLessonStarted && roomView === 1) {
      setWhichView(WhichViewTy.Lecture);
      csSetupContext.setPageviewState(0);
    } else if (hasLessonStarted && roomView === 2) {
      setWhichView(WhichViewTy.Group);
      csSetupContext.setPageviewState(0);
    } else {
      // log errr, should not occur
      Sentry.captureException(
        new Error('WhichView had unexpected values, not good!'),
        {
          extra: {
            hasLessonStartedDt: hasLessonStarted,
            hasLessonEndedDt: hasLessonEnded,
            roomViewDt: roomView,
          }
        }
      );
    }
  }, [hasLessonStarted, hasLessonEnded, roomView, csSetupContext]);

  // throw if we detect the user has entered twice. in our custom error handler we'll
  // display a special screen. note that we do not need to disconnect from vonage or
  // pusher, those disconnect when the error occurs
  useEffect(() => {
    if (thisUserEnteredTwice === true) {
      throw new Error(CustomErrBoundaries.UserEnteredTwice);
    }
  }, [thisUserEnteredTwice]);

  return (
    <>
      {/* hold vonage session connection/disconnection, connect 
      to new session on room layout change logic */}
      <VonageSessionManager
        roomLayoutRef={roomLayoutRef}
        initialClassroomDataRef={initialClassroomDataRef}
        hasLessonStarted={hasLessonStarted}
        hasLessonEnded={hasLessonEnded}
      />

      {/* Here we are calculating lesson time(time remaining in lesson start & end) 
      this componnet is also responsible for `initializeSession` when 
      secondsRemainingInLessonStart <= 4 seconds we update our connectToVonage flage 
      to true as connectToVonage is true we initialize vonage session  */}
      <Setup4ViewSetup2LessonTimeCalc
        initialClassroomDataRef={initialClassroomDataRef}
        lessonInfo={lessonInfo}
        hasLessonStarted={hasLessonStarted}
        setHasLessonStarted={setHasLessonStarted}
        hasLessonEnded={hasLessonEnded}
        setHasLessonEnded={setHasLessonEnded}
        setmodalviewContents={setmodalviewContents}
        setmodalviewState={setmodalviewState}
      />

      {/* lecture (teacher only) view. whichView will hide this if not in lecture mode */}
      <TeacherOnly whichView={whichView} />

      { /* group chat view. whichView will hide this if not in group chat mode */}
      <GroupChat
        fragmentRefOfRoomLayoutSubscription={roomLayoutRef}
        onlineUsers={onlineUsers}
        whichView={whichView}
      />
    </>
  );
};
