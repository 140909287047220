/**
 * @generated SignedSource<<8e7219c70cc2e9f3d9788c6604868b67>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateEmailPreferencesMutation$variables = {
  accountNotices: any;
  gettingStartedTips: any;
  messagesCoach: any;
  messagesTeacher: any;
  newTeacherAvailable: any;
  unsubscribedAll: any;
  updateAnnouncements: any;
  userRandomId: string;
};
export type UpdateEmailPreferencesMutation$data = {
  readonly users_emailunsub_insert: {
    readonly affected_rows: number;
  } | null;
};
export type UpdateEmailPreferencesMutation = {
  response: UpdateEmailPreferencesMutation$data;
  variables: UpdateEmailPreferencesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountNotices"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gettingStartedTips"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "messagesCoach"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "messagesTeacher"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newTeacherAvailable"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unsubscribedAll"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updateAnnouncements"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userRandomId"
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "account_notices",
            "variableName": "accountNotices"
          },
          {
            "kind": "Variable",
            "name": "getting_started_tips",
            "variableName": "gettingStartedTips"
          },
          {
            "kind": "Variable",
            "name": "messages_coach",
            "variableName": "messagesCoach"
          },
          {
            "kind": "Variable",
            "name": "messages_teacher",
            "variableName": "messagesTeacher"
          },
          {
            "kind": "Variable",
            "name": "new_teacher_available",
            "variableName": "newTeacherAvailable"
          },
          {
            "kind": "Variable",
            "name": "unsubscribed_all",
            "variableName": "unsubscribedAll"
          },
          {
            "kind": "Variable",
            "name": "update_announcements",
            "variableName": "updateAnnouncements"
          },
          {
            "kind": "Variable",
            "name": "user_random_id",
            "variableName": "userRandomId"
          }
        ],
        "kind": "ObjectValue",
        "name": "objects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "users_emailunsub_user_random_id_key",
          "update_columns": [
            "update_announcements",
            "messages_teacher",
            "messages_coach",
            "getting_started_tips",
            "unsubscribed_all",
            "account_notices",
            "new_teacher_available"
          ]
        }
      }
    ],
    "concreteType": "users_emailunsub_mutation_response",
    "kind": "LinkedField",
    "name": "users_emailunsub_insert",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateEmailPreferencesMutation",
    "selections": (v8/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateEmailPreferencesMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "5247f8daaa918b96eb18f436aeb6eb4a",
    "id": null,
    "metadata": {},
    "name": "UpdateEmailPreferencesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateEmailPreferencesMutation(\n  $userRandomId: String!\n  $updateAnnouncements: smallint!\n  $messagesTeacher: smallint!\n  $messagesCoach: smallint!\n  $gettingStartedTips: smallint!\n  $unsubscribedAll: smallint!\n  $accountNotices: smallint!\n  $newTeacherAvailable: smallint!\n) {\n  users_emailunsub_insert(objects: {user_random_id: $userRandomId, update_announcements: $updateAnnouncements, messages_teacher: $messagesTeacher, messages_coach: $messagesCoach, getting_started_tips: $gettingStartedTips, unsubscribed_all: $unsubscribedAll, account_notices: $accountNotices, new_teacher_available: $newTeacherAvailable}, on_conflict: {constraint: users_emailunsub_user_random_id_key, update_columns: [update_announcements, messages_teacher, messages_coach, getting_started_tips, unsubscribed_all, account_notices, new_teacher_available]}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "6aec3c8cfeb2c63cfc16820b0d7f83bf";

export default node;
