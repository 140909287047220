import React from 'react';
import { Props } from './interface';
import { CustomPopoverContainer } from './index.style';
/**
 * Our main popover component. See:
 *
 * https://mui.com/material-ui/react-popover/
 *
 * https://mui.com/material-ui/api/popover/
 */
export const Popover: React.FunctionComponent<Props> = (props) => {
  const {
    children,
    isOpen = false,
    anchorEl = null,
    onClose,
    cp,
    onMouseEnter = undefined,
    onMouseLeave = undefined,
    anchorV = 'bottom',
    anchorH = 'right',
    transformH = 'right',
    transformV = 'top',
    width,
    height,
  } = props;

  return (
    <CustomPopoverContainer
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: anchorV, horizontal: anchorH }}
      transformOrigin={{ vertical: transformV, horizontal: transformH }}
      width={width}
      height={height}
      // paperProps are deprecated and being used like following
      // you can read more about it on: https://mui.com/material-ui/api/popover/#Popover-prop-PaperProps
      slotProps={{
        paper: {
          onMouseEnter,
          onMouseLeave,
        },
      }}
      sx={{
        pointerEvents: onMouseEnter !== undefined ? 'none' : 'auto',
      }}
      {...cp}
    >
      {children}
    </CustomPopoverContainer>
  );
};
