import React, { Dispatch, SetStateAction } from 'react';
import {
  Button, Dialog, Grid2, Ty, Grid2Ct, Box,
} from '@languageconvo/wcl';
import { ErrorComponent } from './ErrorsComponent';

interface ModalState {
  openModal: boolean,
  setOpenModal: Dispatch<SetStateAction<boolean>>,
  checkoutUrl: string,
  isInFlight: boolean,
  errorCode: null | string,
  groupClassroomSubscriptionData: any,
}

export const GroupSubscriptionModal = ({
  openModal, setOpenModal, checkoutUrl, isInFlight,
  errorCode, groupClassroomSubscriptionData
}: ModalState) => (
  <Dialog
    isOpen={openModal}
    onClose={() => setOpenModal(!openModal)}
    width="sm"
    color="accentGreen1"
  >
    <Grid2Ct>
      {/* explanation/agreement text */}
      <Grid2 xs={12}>
        <Ty v="h2New">
          Free Trial Details
        </Ty>
        <Ty>
          Once you start your free trial, you can join unlimited classes for free, for 2 days! If
          you don&apos;t enjoy them (we think that&apos;s unlikely!) just cancel before the 2 day
          period is up and your subscription won&apos;t start / you will NOT be charged anything.
        </Ty>

        <Ty v="h2New" cp={{ sx: { mt: 2 } }}>
          Spanish only, check the schedule!
        </Ty>
        <Ty>
          Group classes just started very recently; we are working on adding all languages but
          to start, only Spanish is offered. Please check the schedule (which you can get to from
          the main &quot;Lessons&quot; page)!
        </Ty>

        <Ty v="h2New" cp={{ sx: { mt: 2 } }}>
          By Subscribing, You Agree Not to Share
        </Ty>
        <Ty>
          Your subscription to unlimited group classes is for one person only.
          Allowing more than one person to use a subscription will be considered fraud. We are
          a small business, please help us make this new way of learning a language work!
        </Ty>
      </Grid2>

      {/* if an error occurs, we display an alert */}
      {(groupClassroomSubscriptionData.groupcl === 0 && errorCode !== null) && (
      <Grid2 xs={12}>
        <ErrorComponent
          errorCode={errorCode}
          groupClassroomSubscriptionData={groupClassroomSubscriptionData}
        />
      </Grid2>
      )}

      <Grid2 xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            color="accentGreen1"
            cp={{ sx: { mb: 2 } }}
            disabled={checkoutUrl === '' || isInFlight}
            isLoading={checkoutUrl === '' || isInFlight}
            onClick={() => { window.location.href = checkoutUrl; }}
          >
            I Agree, Continue!
          </Button>
        </Box>
      </Grid2>
    </Grid2Ct>
  </Dialog>
);
