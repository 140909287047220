import React from 'react';
import { Props } from './interface';
import { CustomLinkContainer } from './index.style';

interface UnderlineTypes {
  underline: 'hover' | 'none';
}

/**
 * Our main Link component, see:
 * * [https://mui.com/material-ui/react-link/](https://mui.com/material-ui/react-link/)
 * * [https://mui.com/material-ui/api/link/](https://mui.com/material-ui/api/link/)
 */
export const Link: React.FunctionComponent<Props> = (props) => {
  const {
    linkStyle = 'standard',
    href = undefined,
    onClick = undefined,
    color = 'primary',
    id = undefined,
    classes = undefined,
    component,
    to,
    children,
    target,
    rel,
    cp,
  } = props;

  // if style = 'nostyle', the user wants the link to be completely unstyled. so we remove
  // underline
  let underlineStyle: UnderlineTypes = {
    underline: 'hover',
  };
  if (linkStyle === 'nostyle') {
    underlineStyle = {
      underline: 'none',
    };
  }

  return (
    <CustomLinkContainer
      id={id}
      classes={classes}
      linkStyle={linkStyle}
      color={color}
      onClick={onClick}
      underline={underlineStyle.underline}
      href={href}
      component={component}
      to={to}
      rel={rel}
      target={target}
      {...cp}
    >
      {children}
    </CustomLinkContainer>
  );
};
