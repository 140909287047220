import { graphql } from 'babel-plugin-relay/macro';

export const StuSettingsUpdateaicsettingsMutation = graphql`
  mutation StuSettingsUpdateaicsettingsMutation($translation: smallint!, $level: Int!) {
    stu_settings_updateaicsettings(translation: $translation, level: $level) {
      success
      errors
      data {
        user_settings {
          id
          aic_translate
          aic_level
          aic_setsettings
        }
      }
    }
  }
`;
