import React, { useState, useMemo, createContext } from 'react';
import * as Sentry from '@sentry/react';
import { Footer } from './components/footer/Footer';
import { MainContainer } from './components/maincontainer/MainContainer';
import { VonageProvider } from './context/VonageProvider';
import { Modalview1Setup } from './components/modalview/Modalview1Setup';
import { CustomErrBoundary } from './components/maincontainer/CustomErrBoundary';

// TODO: syed/usama reivew this context stuff; I just followed online tutorials and don't know
//  many details
// this context will be used for page loading -- things that occur before the user is connected
// to the session
export const ClassroomSetupContext = createContext(null as any);

export const GroupClassroom = () => {
  // #region context

  // pageview
  //  this is an integer we'll use to display various things in the main card. things like
  //  loading glimmer, device access not allowed error, etc. we will *not* use this to display
  //  lesson countdown clock, group chat, or lecture, because those need data further down
  //  the setup pipeline
  //    0: display nothing
  //        important note: when lesson count down clock is running, or when lesson is in group
  //        chat mode, or when lesson is in lecture, this value should be 0
  //    1xx: errors (see Pageview component for details)
  //    2xx: non-errors (see Pageview component for details)
  //      200 is the initial state we set here, it is the initial "classroom loading" view
  const [pageviewState, setPageviewState] = useState(200);

  // isFooterReady
  //  false by default, we'll set this to true once the user has allowed device access and
  //  we've gotten a list of all their devices (and updated relay store). until it's true
  //  we won't allow the user to click on the mic button at the bottom of the page (because)
  //  if we did, they'd just see an empty list of microphones
  const [isFooterReady, setIsFooterReady] = useState(false);

  const context = useMemo(
    () => (
      {
        isFooterReady,
        setIsFooterReady,
        pageviewState,
        setPageviewState,
      }),
    [isFooterReady, pageviewState]
  );

  // #endregion

  // #region notif modal

  // modal state. true=open, false=closed. closed by default
  const [modalviewState, setModalviewState] = useState<boolean>(false);
  const handleModalviewClose = () => {
    setModalviewState(false);
  };

  // determines the contents of the modal
  const [modalviewContents, setModalviewContents] = useState<number>(0);

  // #endregion

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <CustomErrBoundary err={error} />
      )}
    >
      <ClassroomSetupContext.Provider value={context}>
        <VonageProvider
          setmodalviewContents={setModalviewContents}
          setmodalviewState={setModalviewState}
        >
          {/* main card */}
          <MainContainer
            setmodalviewContents={setModalviewContents}
            setmodalviewState={setModalviewState}
          />

          {/* footer (mic and info buttons) */}
          <Footer />

          {/* We use this modal to display notifs to the user about things like
            they don't have a mic plugged in, there was a problem connecting to
            a stream, etc. It's a bit of a universal modal for various use cases
        */}
          <Modalview1Setup
            modalstate={modalviewState}
            modalcontents={modalviewContents}
            handleclose={handleModalviewClose}
          />
        </VonageProvider>
      </ClassroomSetupContext.Provider>
    </Sentry.ErrorBoundary>
  );
};
