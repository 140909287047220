import React, { useLayoutEffect, useContext } from 'react';
import { useFragment } from 'react-relay';
import { Setup0BasicData2Hasinteracted } from './Setup0BasicData2Hasinteracted';
import { ClientonlySchemaHasinteractedFragment$key } from '../../relay/__generated__/ClientonlySchemaHasinteractedFragment.graphql';
import { ClassroomSetupContext } from '../../GroupClassroom';
import {
  ClassroomBasicDataTermsprivacy1Fragment,
  ClassroomBasicDataTermsprivacy2Fragment
} from '../../relay/ClassroomBasicData';
import { ClassroomBasicDataTermsprivacy1Fragment$key } from '../../relay/__generated__/ClassroomBasicDataTermsprivacy1Fragment.graphql';
import { ClassroomBasicDataTermsprivacy2Fragment$key } from '../../relay/__generated__/ClassroomBasicDataTermsprivacy2Fragment.graphql';

interface Props {
  clientdt: ClientonlySchemaHasinteractedFragment$key
  termsprivacy: any
  setmodalviewContents: any
  setmodalviewState: any
}

// determine if user needs to accept group class rules and display them if needed
export const Setup0BasicData1Termsprivacy = ({
  clientdt,
  termsprivacy,
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  const csSetupContext = useContext(ClassroomSetupContext);

  // the current version (row id) of our group lesson terms of use
  const currentVal: ClassroomBasicDataTermsprivacy2Fragment$key = termsprivacy
    .terms_privacy_grouplsn_connection.edges[0].node;
  const currentTermsPk = useFragment(
    ClassroomBasicDataTermsprivacy2Fragment,
    currentVal,
  );

  // the version (row id) of our group lesson terms of use that the user has accepted, if  any
  const acceptedVal: ClassroomBasicDataTermsprivacy1Fragment$key = termsprivacy
    .user_settings_connection.edges[0].node;
  const acceptedTermsPk = useFragment(
    ClassroomBasicDataTermsprivacy1Fragment,
    acceptedVal,
  );

  // if the user has not accepted the current rules, display rules and button to allow them
  // to agree to the rules
  useLayoutEffect(() => {
    if (currentTermsPk.pk !== acceptedTermsPk.termsprivacy_grouplsn_id) {
      csSetupContext.setPageviewState(203);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTermsPk, acceptedTermsPk]);

  // dont allow setup steps to continue
  if (currentTermsPk.pk !== acceptedTermsPk.termsprivacy_grouplsn_id) {
    return <div />;
  }

  return (
    <Setup0BasicData2Hasinteracted
      clientDt={clientdt}
      setmodalviewContents={setmodalviewContents}
      setmodalviewState={setmodalviewState}
    />
  );
};
