import { colors } from '../ourTheme';

// override the MUI default palette values by including a palette object
// Look: https://mui.com/material-ui/customization/palette/

const palette = {
  light: {
    primary: {
      main: `hsl(${colors.light.primary.base})`,
      // button text color
      // contrastText: colors.light.primary.midTextcolor,
    },
    secondary: {
      main: `hsl(${colors.light.secondary.base})`,
    },
    info: {
      main: `hsl(${colors.light.info.base})`,
    },
    error: {
      main: `hsl(${colors.light.error.base})`,
    },
    warning: {
      main: `hsl(${colors.light.warning.base})`,
    },
    success: {
      main: `hsl(${colors.light.primary.base})`,
    },
    neutral: {
      main: `hsl(${colors.light.neutral.base})`,
    },
    accentGreen1: {
      main: `hsl(${colors.light.accentGreen1.base})`,
    },
    accentPurple1: {
      main: `hsl(${colors.light.accentPurple1.base})`,
    },
    // note: this is just so that this color will exist in mui colors palette
    accentPurple1Dark: {
      main: `hsl(${colors.light.accentPurple1.base})`,
    },
    accentBlue1: {
      main: `hsl(${colors.light.accentBlue1.base})`,
    },
    accentRed1: {
      main: `hsl(${colors.light.accentRed1.base})`,
    },
    accentOrange1: {
      main: `hsl(${colors.light.accentOrange1.base})`,
    },
    accentYellow1: {
      main: `hsl(${colors.light.accentYellow1.base})`,
    },

    // mui text colors. we will likely override these in our components anyway
    text: {
      primary: `hsl(${colors.light.text.base})`,
      secondary: `hsl(${colors.light.text.sub})`,
      disabled: `hsl(${colors.light.text.disabled})`,
    },

    // mui background colors
    background: {
      paper: `hsl(${colors.light.app.container})`,
      // this is the main bg for the entire app. we use it to color the drawer and navbar; the page bg is colored
      // somewhere else
      default: `hsl(${colors.light.app.navbar})`,
      neutral: `hsl(${colors.light.neutral.lighter2})`,
    },
  },

  // TODO
  dark: {
    primary: {
      main: `hsl(${colors.dark.primary.base})`,
      // button text color
      // contrastText: colors.light.primary.midTextcolor,
    },
    secondary: {
      main: `hsl(${colors.dark.secondary.base})`,
    },
    info: {
      main: `hsl(${colors.dark.info.base})`,
    },
    error: {
      main: `hsl(${colors.dark.error.base})`,
    },
    warning: {
      main: `hsl(${colors.dark.warning.base})`,
    },
    success: {
      main: `hsl(${colors.dark.primary.base})`,
    },
    neutral: {
      main: `hsl(${colors.dark.neutral.base})`,
    },
    accentGreen1: {
      main: `hsl(${colors.dark.accentGreen1.base})`,
    },
    accentPurple1: {
      main: `hsl(${colors.dark.accentPurple1.base})`,
    },
    // note: this is just so that this color will exist in mui colors palette
    accentPurple1Dark: {
      main: `hsl(${colors.dark.accentPurple1.base})`,
    },
    accentBlue1: {
      main: `hsl(${colors.dark.accentBlue1.base})`,
    },
    accentRed1: {
      main: `hsl(${colors.dark.accentRed1.base})`,
    },
    accentOrange1: {
      main: `hsl(${colors.dark.accentOrange1.base})`,
    },
    accentYellow1: {
      main: `hsl(${colors.dark.accentYellow1.base})`,
    },

    // mui text colors. we will likely override these in our components anyway
    text: {
      primary: `hsl(${colors.dark.text.base})`,
      secondary: `hsl(${colors.dark.text.sub})`,
      disabled: `hsl(${colors.dark.text.disabled})`,
    },

    // mui background colors
    background: {
      paper: `hsl(${colors.dark.app.container})`,
      // this is the main bg for the entire app. we use it to color the drawer and navbar; the page bg is colored
      // somewhere else
      default: `hsl(${colors.dark.app.navbar})`,
      neutral: `hsl(${colors.dark.neutral.lighter2})`,
    },
  },
};

export default palette;
