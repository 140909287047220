import React from 'react';
import useRudderStackAnalytics from '../../hooks/useRudderStackAnalytics';

const RudderStackAnalyticsComponent = ({ fragmentRefForRudderStackData }: any) => {
  useRudderStackAnalytics(fragmentRefForRudderStackData);

  return (
    <div />
  );
};

export default RudderStackAnalyticsComponent;
