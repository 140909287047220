import React from 'react';
// import { Typography } from '@mui/material';
import { Props } from './interface';
import { CustomTypography } from './index.style';
/**
 * Our main Typography component, see:
 * * [material-ui/react-typography/](https://mui.com/material-ui/react-typography/)
 * * [material-ui/api/typography/](https://mui.com/material-ui/api/typography/)
 */
export const Ty: React.FunctionComponent<Props> = (props) => {
  const {
    v = 'p',
    id = undefined,
    classes = undefined,
    align = 'inherit',
    noWrap = false,
    removeMb = false,
    cp,
    children,
  } = props;

  // // mapping our value 'p' to MUIs value 'body1' might be helpful for future.
  // const getMUIVariant = () => {
  //   if (v === 'p') return 'body1';
  //   if (v === 'small') return 'body1';
  //   if (v === 'subheading') return 'subtitle1';
  //   return v;
  // };

  return (
    <CustomTypography
      id={id}
      classes={classes}
      // variant={getMUIVariant()}
      v={v}
      align={align}
      noWrap={noWrap}
      removeMb={removeMb}
      {...cp}
    >
      {children}
    </CustomTypography>
  );
};
