import {
  styled, Box, WCLThemeProps,
} from '@languageconvo/wcl';

export const AlignTitle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

type LanglearnItemContainerProps = {
  isSelected?: boolean;
}

export const LanglearnItemContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})(({ theme, isSelected }:
  WCLThemeProps & LanglearnItemContainerProps) => ({
  paddingTop: '12px',
  paddingBottom: '12px',
  paddingLeft: '20px',
  paddingRight: '20px',
  borderRadius: '11px',
  fontSize: '16px',
  ...(isSelected && {
    backgroundColor: theme?.ourTheme.colors.cp.itemlist.hoverbg.accentOrange1,
  }),
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme?.ourTheme.colors.cp.itemlist.hoverbg.accentOrange1,
  },
}));
