import React, {
  useState, useEffect, SetStateAction, Dispatch
} from 'react';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import {
  CardSmallColor, Grid2Ct, Grid2, Box, Dialog, Ty, CardSmallColorIcon, IcSvgList
} from '@languageconvo/wcl';
import { calculateAndSetDaysSelector, DaysSelector } from '../GroupSchedule2';

interface Props {
  daysSelector: DaysSelector
  setDaysSelector: any
  modalStateInfo: boolean,
  setModalStateInfo: Dispatch<SetStateAction<boolean>>
}

// day selector and info
export const Header = ({
  daysSelector, setDaysSelector,
  modalStateInfo,
  setModalStateInfo
}: Props) => {
  // #region general

  // allDays holds the text and icons for each of the 3 days that we'll display
  // in the modal
  const [allDays, setAllDays] = useState<any>();
  // selectedDayText and selectedDayIcon are the text and icon for the day the
  // user currently has selected
  const [selectedDayText, setSelectedDayDisplay] = useState<any>();
  const [selectedDayIcon, setSelectedDayIcon] = useState<any>();

  useEffect(() => {
    const day2Text = DateTime.fromMillis(daysSelector.day2.startTs).toLocaleString({
      weekday: 'long',
    });
    const day3Text = DateTime.fromMillis(daysSelector.day3.startTs).toLocaleString({
      weekday: 'long',
    });
    const daysDisplay = {
      day1: {
        text: 'Today',
        icon: 'sun1_v2',
      },
      day2: {
        text: day2Text,
        icon: 'sun2',
      },
      day3: {
        text: day3Text,
        icon: 'sun3_v2',
      }
    };
    setAllDays((prevState: any) => ({
      ...prevState,
      ...daysDisplay,
    }));

    if (daysSelector.selectedDay.startTs === daysSelector.day1.startTs) {
      setSelectedDayDisplay(daysDisplay.day1.text);
      setSelectedDayIcon(daysDisplay.day1.icon);
    } else if (daysSelector.selectedDay.startTs === daysSelector.day2.startTs) {
      setSelectedDayDisplay(daysDisplay.day2.text);
      setSelectedDayIcon(daysDisplay.day2.icon);
    } else if (daysSelector.selectedDay.startTs === daysSelector.day3.startTs) {
      setSelectedDayDisplay(daysDisplay.day3.text);
      setSelectedDayIcon(daysDisplay.day3.icon);
    } else {
      // log error, should never happen
      Sentry.captureException(
        new Error('daysSelector error in in Header.tsx'),
        {
          extra: {
            startTs: daysSelector.selectedDay.startTs,
            day1StartTs: daysSelector.day1.startTs,
            day2StartTs: daysSelector.day2.startTs,
            day3StartTs: daysSelector.day3.startTs,
          }
        }
      );
    }
  }, [daysSelector, selectedDayText, selectedDayIcon]);

  // #endregion

  // #region today/tomorrow/next day modal

  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    // every time the modal opens, we'll check to ensure that the timestamps for
    // day1, day2, day3 in our daysSelector is correct. mainly for the use
    // case that a new day has started but for some reason our code which checks that
    // has not run yet
    calculateAndSetDaysSelector(0, daysSelector, setDaysSelector);
    setModalState(true);
  };

  // modal close action
  const handleModalClose = () => {
    setModalState(false);
  };

  // handle clicks on the different days. day 1 is today, day 2 is  tomorrow, day 3 is
  // 2 days in the future
  const clickSelectDay1 = () => {
    calculateAndSetDaysSelector(1, daysSelector, setDaysSelector);
    handleModalClose();
  };
  const clickSelectDay2 = () => {
    calculateAndSetDaysSelector(2, daysSelector, setDaysSelector);
    handleModalClose();
  };
  const clickSelectDay3 = () => {
    calculateAndSetDaysSelector(3, daysSelector, setDaysSelector);
    handleModalClose();
  };

  // #endregion

  return (
    <>
      {/* daypicker, and info card */}
      <Grid2Ct sx={{ mb: 2 }}>
        {/* day picker */}
        <Grid2 xs={9}>
          <CardSmallColor
            smallerfont
            icon={selectedDayIcon}
            text={selectedDayText}
            color="cardstandard"
            hovercursor="pointer"
            onClick={handleModalOpen}
            // set the width otherwise it gets really wide
            cp={{ sx: { maxWidth: '300px' }, 'data-intercom': 'groupscheddaypick' }}
          />
        </Grid2>

        {/* info card */}
        <Grid2 xs={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CardSmallColorIcon
              icon={IcSvgList.question1}
              color="cardstandard"
              hovercursor="pointer"
              onClick={() => setModalStateInfo(!modalStateInfo)}
              cp={{ 'data-intercom': 'groupschedinfo' }}
            />
          </Box>
        </Grid2>
      </Grid2Ct>

      {/* today/tomorrow/next day modal */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="xs"
        color="accentGreen1"
      >
        <DayModalContents
          allDays={allDays}
          clickSelectDay1={clickSelectDay1}
          clickSelectDay2={clickSelectDay2}
          clickSelectDay3={clickSelectDay3}
        />
      </Dialog>

    </>
  );
};

// #region sub-components

// contents of the day selection modal
const DayModalContents = ({
  allDays, clickSelectDay1, clickSelectDay2, clickSelectDay3
} : any) => (
  <Grid2Ct sx={{ mb: 2 }}>
    {/* text */}
    <Grid2 xs={12} sx={{ mt: 2 }}>
      <Ty align="center">Select the day you want to view!</Ty>
    </Grid2>

    {/* today */}
    <Grid2 xs={12}>
      {/* centers the card */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardSmallColor
          smallerfont
          icon={allDays.day1.icon}
          text={allDays.day1.text}
          color="accentGreen1"
          hovercursor="pointer"
          onClick={clickSelectDay1}
          // stop card from getting really wide on larger screens
          cp={{ sx: { width: { xs: '100%', sm: '260px' } } }}
        />
      </Box>
    </Grid2>

    {/* next day */}
    <Grid2 xs={12}>
      {/* centers the card */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardSmallColor
          smallerfont
          icon={allDays.day2.icon}
          text={allDays.day2.text}
          color="accentGreen1"
          hovercursor="pointer"
          onClick={clickSelectDay2}
          // stop card from getting really wide on larger screens
          cp={{ sx: { width: { xs: '100%', sm: '260px' } } }}
        />
      </Box>
    </Grid2>

    {/* 2 days from now */}
    <Grid2 xs={12}>
      {/* centers the card */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardSmallColor
          smallerfont
          icon={allDays.day3.icon}
          text={allDays.day3.text}
          color="accentGreen1"
          hovercursor="pointer"
          onClick={clickSelectDay3}
          // stop card from getting really wide on larger screens
          cp={{ sx: { width: { xs: '100%', sm: '260px' } } }}
        />
      </Box>
    </Grid2>
  </Grid2Ct>
);
