import React from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { GiftsHistoryTable } from './GiftsHistoryTable';

interface Props {
  pageSize: number,
}

// Query for getting user's primary key (pk), because we need that pk field in
// Gift's table fragment to get all the gifts that belongs to this user.
const GetUserIdForGiftsHistoryTableQuery = graphql`
  query GetUserIdForGiftsHistoryTableQuery {
    users_connection {
      edges {
        node {
          ...GiftsHistoryTableFragment
        }
      }
    }
  }
`;

// this component will read the fragment reference for user's primary key
// from relay store and pass it to "GiftsHistoryTable" component where we can use it.
export const GetUserIdForGiftsHistoryTable = ({ pageSize }: Props) => {
  // this query will get the data of all orders and tips to teacher
  const userData: any = useLazyLoadQuery(
    GetUserIdForGiftsHistoryTableQuery,
    {}
  );
  // extracting the fragment reference from user node.
  const fragmentRef = userData.users_connection.edges[0].node;

  return (
    <GiftsHistoryTable fragmentRefForUserId={fragmentRef} pageSize={pageSize} />
  );
};
