import * as React from 'react';
import { CustomCard } from './index.style';
import { CardProps } from './interface';

/**
 * Our main Card component. For most use cases, you'll want to have <FlexColumn> and <CardContent> children, which will
 * add our standard inner padding.
 *
 * See:
 * https://mui.com/material-ui/react-card/
 * https://mui.com/material-ui/api/card/
 */

export const Card: React.FunctionComponent<CardProps> = (props) => {
  const {
    ref,
    onMouseEnter,
    onMouseLeave,
    cp,
    children,
    onClick,
    growsOnHover = false,
    align = 'default',
    cursor = false,
  } = props;
  return (
    <CustomCard
      ref={ref}
      align={align}
      cursor={cursor}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      growsOnHover={growsOnHover}
      {...cp}
    >
      {children}
    </CustomCard>
  );
};
