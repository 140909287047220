import React, { Dispatch, SetStateAction } from 'react';
import { useOutletContext } from 'react-router-dom';
import { HowGroupClassWork } from './HowGroupClassWork';

interface Props {
    modalStateInfo: boolean,
    setModalStateInfo: Dispatch<SetStateAction<boolean>>
}

// TODO: try naming the files something like this:
//  HowGroupClassWork1, HowGroupClassWork2, etc.
//  That will make it easier to see the order in which files are called/used
export const HowGroupClassWorkContainer = ({
  modalStateInfo,
  setModalStateInfo
}: Props) => {
  // TODO: didn't 
  // reading the fragment from outletContext, because this is created in dashboard layout and passed
  // down so we can use it wherever we want
  const { groupClassroomSubscriptionFragmentRef }: any = useOutletContext();

  return (
    <div>
      {/* TODO: the comment below just tells us what, it doesn't tell us why other than it
      is to "prevent errors". explain why we do this here -- isn't it because the graphl
      subscription might not have resolved yet once the code gets here? I can't exactly remember */}
      {/* 
        We are checking if groupClassroomSubscriptionFragmentRef is neither null nor undefined.
        If valid, the HowGroupClassWork component is rendered with the fragment 
        reference as a prop. This prevents errors while reading data via fragment.
      */}
      {groupClassroomSubscriptionFragmentRef !== null
        && groupClassroomSubscriptionFragmentRef !== undefined && (
        <HowGroupClassWork
          groupClassroomSubscriptionFragmentRef={groupClassroomSubscriptionFragmentRef}
          modalStateInfo={modalStateInfo}
          setModalStateInfo={setModalStateInfo}
        />
      )}
    </div>
  );
};
