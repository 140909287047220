import { graphql } from 'babel-plugin-relay/macro';

export const StuSettingsUpdatetrialstep1Mutation = graphql`
  mutation StuSettingsUpdatetrialstep1Mutation (
    $interestPrivate: Int!
    $interestGroup: Int!
  ) {
    stu_settings_updatetrialstep1(
      interestPrivate: $interestPrivate
      interestGroup: $interestGroup
    ) {
      success
      errors
      data {
        user_settings {
          id
          interest_private
          interest_group
        }
      }
    }
  }
`;
