import { graphql } from 'babel-plugin-relay/macro';

export const GetGroupClassroomSubscription = graphql`
subscription GetGroupClassroomSubscription {
  subs_connection {
    edges {
      node {
        ...GetGroupClassroomFragment
      }
    }
  }
}
`;

// fragment to fetch specific fields from the subs table
// fragment includes the groupcl value,
// which can be reused in multiple components if needed.
export const GetGroupClassroomFragment = graphql`
  fragment GetGroupClassroomFragment on subs {
    groupcl
  }
`;
