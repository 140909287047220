/**
 * @generated SignedSource<<31c75b1c09f6d5235f965ad95f865720>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorldTourGetCountryDetailQuery$variables = {
  countryId: number;
};
export type WorldTourGetCountryDetailQuery$data = {
  readonly practice_world_countries_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"WorldTourGetCountryDetailFragment">;
      };
    }>;
  };
};
export type WorldTourGetCountryDetailQuery = {
  response: WorldTourGetCountryDetailQuery$data;
  variables: WorldTourGetCountryDetailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "countryId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "countryId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "is_displayed": {
        "_eq": 1
      }
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorldTourGetCountryDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "practice_world_countriesConnection",
        "kind": "LinkedField",
        "name": "practice_world_countries_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "practice_world_countriesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "practice_world_countries",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "WorldTourGetCountryDetailFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorldTourGetCountryDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "practice_world_countriesConnection",
        "kind": "LinkedField",
        "name": "practice_world_countries_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "practice_world_countriesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "practice_world_countries",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overview",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "practice_world_radiostations",
                    "kind": "LinkedField",
                    "name": "practice_world_radiostations",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "playback_url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "practice_world_countries_id",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": "practice_world_radiostations(where:{\"is_displayed\":{\"_eq\":1}})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flag_image",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "practice_world_funfacts",
                    "kind": "LinkedField",
                    "name": "practice_world_funfacts",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "funfact",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": "practice_world_funfacts(where:{\"is_displayed\":{\"_eq\":1}})"
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "272928b07b0759d2638b450d3ff5d24b",
    "id": null,
    "metadata": {},
    "name": "WorldTourGetCountryDetailQuery",
    "operationKind": "query",
    "text": "query WorldTourGetCountryDetailQuery(\n  $countryId: Int!\n) {\n  practice_world_countries_connection(where: {pk: {_eq: $countryId}}) {\n    edges {\n      node {\n        ...WorldTourGetCountryDetailFragment\n        id\n      }\n    }\n  }\n}\n\nfragment WorldTourGetCountryDetailFragment on practice_world_countries {\n  overview\n  country_name\n  practice_world_radiostations(where: {is_displayed: {_eq: 1}}) {\n    playback_url\n    city\n    name\n    practice_world_countries_id\n    id\n  }\n  flag_image\n  practice_world_funfacts(where: {is_displayed: {_eq: 1}}) {\n    funfact\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "200918e51b5948d7dab63317f6923cf3";

export default node;
