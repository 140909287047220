import * as Sentry from '@sentry/react';
import { BroadcastChannel } from 'broadcast-channel';
import { handleLogoutAndRemoveRefreshToken } from '../services/fetchService';
import { sitedata } from './sitedata';
import { sleep } from '../common/utils/sleep';

// this function will remove jwt from localStorage and log user out of the
// app, the user will be redirected to login page and has to login again.
export const removeJWTFromLocalStorageAndLogoutUser = async (setLogoutInProgress?: any) => {
  const { studentLocalStorageKeys, url } = sitedata;
  if (setLogoutInProgress) {
    setLogoutInProgress(true);
  }

  // first, we delete their jwt from localstorage
  localStorage.removeItem(studentLocalStorageKeys.studentSecurity);

  // we don't want this function to fail to redirect the user if any of these steps
  // fail. we've already removed the jwt from localstorage, the user will definitely need
  // to log in again
  try {
    // notify the user's other open tabs that they should log out
    const logoutChannel = new BroadcastChannel('logout');
    logoutChannel.postMessage('Logout');

    // log out method of our cdp
    // @ts-ignore type mismatch for RudderAnalytics
    window.rudderanalytics.ready(() => {
      // @ts-ignore
      window.rudderanalytics.track('Signed Out');
      // wait a bit, so track event is called before we delete user trait data from
      // the user's browser. otherwise the track event above will be sent with anon id
      setTimeout(
        () => {
        // @ts-ignore
          window.rudderanalytics.reset();
        },
        300,
      );
    });

    // then we delete their refresh token and nts-session cookie. this is done by php server, as
    // its an httponly cookie
    await handleLogoutAndRemoveRefreshToken();
  } catch (e) {
    Sentry.captureException(e);
  }

  // finally we redirect them to the old login page
  window.location.href = url.app.login;

  // window.location.href is not aysnc, code after it will run! so we add this to be sure
  // code doesn't keep running. if you remove this, whatever code you have after calling
  // removeJWTFromLocalStorageAndLogoutUser will run, until the broswer redirects. this can
  // cause unexpected issues, because code calling this function expects the app to redirect
  // and then stop other execution
  await sleep(10000);
};
