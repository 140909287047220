/**
 * @generated SignedSource<<91eed475125e30a00f7d62d3c0b7c1c2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrivateSchedLsnDataCardFragment$data = {
  readonly pk: number;
  readonly relayFavorited: ReturnType<typeof resourcesRelayFavoritedResolverType> | null;
  readonly resources_files_profileimgs: ReadonlyArray<{
    readonly fuploads: {
      readonly file_cdn: {
        readonly base_url: string;
      };
      readonly file_path: string;
    };
  }>;
  readonly title: string;
  readonly " $fragmentType": "PrivateSchedLsnDataCardFragment";
};
export type PrivateSchedLsnDataCardFragment$key = {
  readonly " $data"?: PrivateSchedLsnDataCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrivateSchedLsnDataCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrivateSchedLsnDataCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "relayFavoritedResolver"
      },
      "kind": "RelayResolver",
      "name": "relayFavorited",
      "resolverModule": require('./../../../../../../common/relay/resolvers/teachers/relayFavorited').relayFavorited,
      "path": "relayFavorited"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pk",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "sort_order": "asc"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "archived": {
              "_eq": "0"
            },
            "image_approved": {
              "_eq": "1"
            },
            "sort_order": {
              "_gt": -1
            }
          }
        }
      ],
      "concreteType": "resources_files_profileimg",
      "kind": "LinkedField",
      "name": "resources_files_profileimgs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "fuploads",
          "kind": "LinkedField",
          "name": "fuploads",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "file_path",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "file_cdn",
              "kind": "LinkedField",
              "name": "file_cdn",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "base_url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "resources_files_profileimgs(limit:1,order_by:{\"sort_order\":\"asc\"},where:{\"archived\":{\"_eq\":\"0\"},\"image_approved\":{\"_eq\":\"1\"},\"sort_order\":{\"_gt\":-1}})"
    }
  ],
  "type": "resources",
  "abstractKey": null
};

(node as any).hash = "0eddcabf27c8a10bef082d75aef5df88";

export default node;
