import React from 'react';
import {
  Ty, Skeleton,
} from '@languageconvo/wcl';
import { useFragment } from 'react-relay';
import { UserDetailsDataFragment } from '../relay/UserDetailsData';
import { UserDetailsDataFragment$key } from '../relay/__generated__/UserDetailsDataFragment.graphql';

interface UserDetailsComponentProps {
  fragmentRef: UserDetailsDataFragment$key
}

// user details component. just displays their name and email address
export const UserDetailsComponent = ({
  fragmentRef
}: UserDetailsComponentProps) => {
  const usersSettingsData = useFragment(UserDetailsDataFragment, fragmentRef);
  const userNode = usersSettingsData.users_connection.edges[0].node;
  const dt = {
    name: `${userNode.first_name} ${userNode.last_name}`,
    email: `${userNode.email}`,
  };

  return (
    <>
      <Ty cp={{ sx: { mt: 1 } }} removeMb noWrap>{dt.name}</Ty>
      <Ty removeMb noWrap>{dt.email}</Ty>
    </>
  );
};

// loading glimmer
export const UserDetailsLoading = () => (
  <>
    <Skeleton variant="text" cp={{ sx: { mt: 1 } }} />
    <Skeleton variant="text" />
  </>
);

// if an error occurs getting user name or email, we don't want the entire page to error.
// this will be displayed instead
export const UserDetailsError = () => (
  <Ty cp={{ sx: { mt: 1 } }}>
    Head to this page to set all of your important details such as your name,
    email, and lesson reminders!
  </Ty>
);
