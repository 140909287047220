import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import {
  Grid2, Grid2Ct, useTheme, Card,
} from '@languageconvo/wcl';
import { CardContentNopadding } from '../../../common/components/card/Card';

export const LegacyMycalendar = () => {
  const theme = useTheme();
  const br = theme?.ourTheme.borders.borderRadius.medium;

  return (
    <>
      {/* main content */}
      <Grid2Ct>
        <Grid2 xs={12}>
          <Card>
            <CardContentNopadding>
              <IframeResizer
                id="myscheduleFrame"
                src={`${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=customer/schedule/myschedule/`}
                style={{
                  width: '1px',
                  minWidth: '100%',
                  minHeight: '90vh',
                  borderRadius: `${br}px`,
                  border: 'none',
                }}
              />
            </CardContentNopadding>
          </Card>
        </Grid2>
      </Grid2Ct>
    </>
  );
};
