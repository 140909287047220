/**
 * @generated SignedSource<<36fdf3e83ba76d929e275492e5b65844>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BasicUsersSettingsUpdatesMutation$variables = {
  age: number;
  countryId: number;
  email: string;
  firstName: string;
  hourCycle: string;
  lastName: string;
  password?: string | null;
  timezone: string;
};
export type BasicUsersSettingsUpdatesMutation$data = {
  readonly stu_settings_updategeneral: {
    readonly errors: ReadonlyArray<string | null> | null;
    readonly success: boolean;
    readonly user: {
      readonly country_id_num: number | null;
      readonly dob: any | null;
      readonly email: string;
      readonly first_name: string | null;
      readonly hour_cycle: string;
      readonly id: string;
      readonly last_name: string | null;
      readonly password_set: number;
      readonly timezone_set: number;
      readonly user_timezone: string;
    };
  } | null;
};
export type BasicUsersSettingsUpdatesMutation = {
  response: BasicUsersSettingsUpdatesMutation$data;
  variables: BasicUsersSettingsUpdatesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "age"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hourCycle"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timezone"
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "age",
        "variableName": "age"
      },
      {
        "kind": "Variable",
        "name": "country",
        "variableName": "countryId"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "hourCycle",
        "variableName": "hourCycle"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "timezone",
        "variableName": "timezone"
      }
    ],
    "concreteType": "StuSettingsUpdategeneralOutput",
    "kind": "LinkedField",
    "name": "stu_settings_updategeneral",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dob",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user_timezone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "password_set",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timezone_set",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hour_cycle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country_id_num",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BasicUsersSettingsUpdatesMutation",
    "selections": (v8/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v7/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "BasicUsersSettingsUpdatesMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "5bbf0440ef1a3a3864f5199bf50950d3",
    "id": null,
    "metadata": {},
    "name": "BasicUsersSettingsUpdatesMutation",
    "operationKind": "mutation",
    "text": "mutation BasicUsersSettingsUpdatesMutation(\n  $password: String\n  $timezone: String!\n  $age: Int!\n  $firstName: String!\n  $lastName: String!\n  $email: String!\n  $countryId: Int!\n  $hourCycle: String!\n) {\n  stu_settings_updategeneral(password: $password, timezone: $timezone, age: $age, firstName: $firstName, lastName: $lastName, email: $email, country: $countryId, hourCycle: $hourCycle) {\n    success\n    errors\n    user {\n      id\n      dob\n      user_timezone\n      password_set\n      timezone_set\n      first_name\n      last_name\n      email\n      hour_cycle\n      country_id_num\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a363a0e64218751aced8d6f615513fe7";

export default node;
