import React, { Dispatch, SetStateAction, useState } from 'react';
import { Grid2 } from '@languageconvo/wcl';
import { DateTime } from 'luxon';
import { Step3ConfirmationModal } from './Step3ConfirmationModal';
import {
  ColumnContainerHeadings,
  ColumnContainerTimes,
  PanelsSingleLessonScheduleAvTimIndividualBox,
  PanelsSingleLessonScheduleAvTimGuidelinecolGridright,
} from './Step3Availability.style';

interface Props {
  // the full trial availability for the upcoming 7 days
  sched: any
  mutationCallToGetAvailabilityData: () => void
  setSchedData: Dispatch<SetStateAction<any>>
  setSchedulingError: Dispatch<SetStateAction<string>>
}

export const Step3Availability = ({
  sched, mutationCallToGetAvailabilityData, setSchedData, setSchedulingError
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<any>();
  // TODO: need user's time zone

  // #region build data for column 1, the times headings

  // Here we should loop through sched[0].times, converting each to a time in this format:
  //  "6:00 am"  or  "6:30am"
  // In the <ColumnContainerHeadings> below, we should map through this to display these times.
  // Ideally, we only want to display every other time, not every single time.
  let count = 1;
  // TODO: need to define the types once the backend structure is 100% ready
  const timeHeadings: any = [];
  // TODO: need to define the types once the backend structure is 100% ready
  sched[0].times.forEach((el: any) => {
    // TODO: here we should be converting el.unixTs to a time
    let theVal = DateTime.fromSeconds(parseInt(el.unixTs, 10)).toLocaleString({ timeStyle: 'short' });

    // skip every other time, so we can create a blank box between every other time
    if (count % 2 === 0) {
      theVal = '&nbsp;';
    }
    timeHeadings.push(theVal);
    count += 1;
  });

  // #endregion

  // #region build data for columns 2 - 8, the availability for next 7 days

  // Here we loop through the entire sched data and convert the unix timestamps to times in the
  // users timezone

  // #endregion

  // #region click events for modal

  /** 
  * name: handleOpenModal
  * this function is responsible for opening the modal.
  * this function will execute on following scenarios
  * 1- when user clicks on any available slots
  */

  const handleOpenModal = (el: any) => {
    // setting this state to false, so that if the alert in visible on UI, and user
    // selected any other slot then that alert will goes away.
    setSchedulingError('');
    // this state is responsible for opening and closing the modal, make it true will open the modal
    // and making it false will close the modal
    setOpenModal(true);
    // setting the selected slots in this state so that this can be used to make the mutation call
    // for scheduling the lesson in the modal.
    setSelectedSlot(el);
  };

  /** 
  * name: handleCloseModal
  * this function is responsible for closing the modal.
  * this function will execute on following scenarios
  * 1- when user clicks outside the modal
  * 2- when user clicks cross button on modal
  * 3- when user clicks confirm button
  * 4- when user clicks cancel button
  */

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedSlot(undefined);
  };

  // #endregion

  return (
    <>
      {/* availability grid */}
      <Grid2 xs={12} sx={{ mb: 3, display: 'flex', overflowX: 'auto' }}>
        {/* column 1, times headings */}
        <ColumnContainerHeadings>
          {/* first row is blank */}
          <PanelsSingleLessonScheduleAvTimIndividualBox />
          {/* second row is blank */}
          <PanelsSingleLessonScheduleAvTimIndividualBox />
          {/* TODO: need to define the types once the backend structure is 100% ready */}
          {timeHeadings.map((el: any) => (
            <PanelsSingleLessonScheduleAvTimGuidelinecolGridright>
              {/* TODO: === '&nbsp;' string comparison is not very reliable. use something
              that's more robust */}
              { el === '&nbsp;' ? <>&nbsp;</> : el }
            </PanelsSingleLessonScheduleAvTimGuidelinecolGridright>
          ))}
        </ColumnContainerHeadings>

        {
        sched.map((item: any) => (
          <ColumnContainerTimes>

            {/* displaying date */}
            <PanelsSingleLessonScheduleAvTimIndividualBox>
              {DateTime.fromSeconds(item.tsForDayDisplay).toLocaleString({ day: '2-digit' })}
            </PanelsSingleLessonScheduleAvTimIndividualBox>

            {/* displaying day */}
            <PanelsSingleLessonScheduleAvTimIndividualBox>
              {DateTime.fromSeconds(item.tsForDayDisplay).toLocaleString({ weekday: 'short' })}
            </PanelsSingleLessonScheduleAvTimIndividualBox>

            {/* displaying times */}
            {/* TODO: need to define the types once the backend structure is 100% ready */}
            {item.times.map((el: any, index: number) => (
              <PanelsSingleLessonScheduleAvTimIndividualBox
                times
                notAvailable={el.status === 2}
                islast={item.times.length === index + 1}
                onClick={() => handleOpenModal(el)}
              >
                {el.status !== 2 ? (
                  DateTime.fromSeconds(el.unixTs).toLocaleString({ timeStyle: 'short' })
                ) : ' '}
              </PanelsSingleLessonScheduleAvTimIndividualBox>
            ))}
          </ColumnContainerTimes>
        ))
      }
      </Grid2>

      {/* modal */}
      {openModal && selectedSlot && (
        <Step3ConfirmationModal
          openModal={openModal}
          selectedSlot={selectedSlot}
          handleCloseModal={handleCloseModal}
          mutationCallToGetAvailabilityData={mutationCallToGetAvailabilityData}
          setSchedData={setSchedData}
          setSchedulingError={setSchedulingError}
        />
      )}
    </>
  );
};
