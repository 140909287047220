export enum AiTutorError {
  // Occurs when user's is not a paying user and they have reached their free 
  // limit of asking questions
  LimitReached = 'LimitReached',
  // When question sent from frontend is empty
  InvalidInput = 'InvalidInput',
  // Any general error such as database failure, or we forgot to add
  // open AI assistant into database
  GeneralError = 'GeneralError'
}
