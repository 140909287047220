import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import { PurchaseRecieptQuery } from '../relay/PurchaseReciept';
import { PurchaseRecieptContainer } from './PurchaseReciept2';

export const PurchaseRecieptQueryComponent = () => {
  const [searchParams] = useSearchParams();
  // extracting the invoiceNumber from params
  const id = searchParams.get('id');

  const response: any = useLazyLoadQuery(
    PurchaseRecieptQuery,
    {
      invoiceNumber: id
    },
  );

  return (
    <PurchaseRecieptContainer
      fragmentRef={response}
    />
  );
};
