import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';
import {
  getRefreshCountAndCurrntTimeFromLC,
  resetAppVersionInLC, updateCurrentTimeStampeInLC,
  updateRefreshCountInLC
} from './localStore/versionValueManagerInLC';
// note: currentversion.ts does not exist in git, because it is created and built by our
// ci/cd pipeline. because of this, we have to ignore warnings by our lint system
// @ts-ignore
import { currentversion } from './currentversion'; // eslint-disable-line import/extensions
import { sitedata } from '../../../utils/sitedata';

/** Reads the local application version from a JSON file(from public/global/version.json)
  and compares it with the version retrieved from the backend.
 * 
 * IMPORTANT: if this errors, no error is thrown. it's hidden by a wrapping try/catch
 * 
 * **Purpose:**
 * function is designed to ensure that the application's local version is consistent 
 * with the backend version. It helps in identifying and handling version mismatches between 
 * the local application and the server,
 * 
 * **Key Actions:**
 * 1. **Read Local Version:** Retrieves the current application version from a local JSON file.
 * 2. **Validate Response:** Ensures the JSON file is read successfully and contains a 
      valid version.
 * 3. **Compare Versions:** Compares the local version with the backend version to determine if 
 *    a mismatch
 * 4. **Handle Mismatches:** Calls the `handleVersionMisMatch` function if a version mismatch 
 *    is detected, ensuring the application reloads to reflect the correct version.
 * 5. **Error Handling:** Throws and logs errors if the version data is invalid or if the 
 *    backend version is not updated, using Sentry for monitoring and alerts.
 * 
 * @param {number} versionFromBackEnd - The version of the application as 
 * retrieved from the backend.
 */
export const readLocalVersionAndDoComparisonWithDBVersion = async (versionFromBackEnd: number) => {
  try {
    // version from file, which gets set during build process
    const version = currentversion;

    // check that the placeholder value got replaced by our ci/cd pipeline. we must ignore ts error
    // because ts sees this value as a const which should always be equal to the placeholder
    // @ts-ignore
    if (version === 1000000000) {
      throw new Error('app version was version 1000000000, which means build process replacements failed');
    }

    // here we deal with the situation that the version our backend gave us is different than the
    // version our local file has. this should mean that a new version has been deployed
    if (versionFromBackEnd !== version) {
      try {
        // eslint-disable-next-line no-console
        console.log(`a new app version ${versionFromBackEnd} has been released, which this browser has not updated ${version}. attempting to update now`);

        // we are only hard reloading our app when user is not on group class room route.
        if (window.location.pathname === sitedata.url.app.groupclassroomDt.pathName) {
          // eslint-disable-next-line no-console
          console.log('Classroom is in progress not hard reloading the app');
        } else {
          handleVersionMisMatch(versionFromBackEnd);
        }
      } catch (e) {
        Sentry.captureException(e);
        throw new Error('handleVersionMisMatch failed, see previously logged err');
      }
    } else if (versionFromBackEnd < version) {
      throw new Error('Alarm! version value in the db was *less* than the version in a browser');
    }
  } catch (err) {
    try {
      Sentry.captureException(
        new Error('IMPORTANT - should never happen, app version Error.'),
        {
          extra: {
            err
          }
        }
      );
    } catch {
      // do nothing. this empty catch just ensures our readLocalVersionAndDoComparisonWithDBVersion
      // never throws any errors
    }
  }
};

/** Handles version mismatch by managing the refresh count and timestamp in local storage.
 * function ensures that the application reloads correctly based on the hard refresh 
 * count and the time elapsed since the last refresh. It performs the following tasks:
 * 
 * 1. Checks if the current timestamp and hard refresh count are present in local storage.
 * 2. Determines if 24 hours have passed since the last refresh.
 * 3. Updates the local storage with the new refresh count and timestamp if necessary.
 * 4. Reloads the application if the conditions are met to ensure the latest version is loaded.
 *
 * **Purpose:**
 * function ensures that the application properly reloads to the latest version based on 
 * how many times it has been refreshed and whether 24 hours have passed since the last refresh. 
 * It is designed to maintain the application state by enforcing a maximum number of hard refreshes
 * to 3 within a 24-hour period and to handle version mismatches effectively.
 */
export const handleVersionMisMatch = (bckversion: number) => {
  const { timeStampInLC, hardRefreshCount } = getRefreshCountAndCurrntTimeFromLC();
  const now = DateTime.now().toMillis();
  // this will hold a time which is equal to 24h + the value stored in localstorage (timeStampInLC)
  let timeFrame = 0;

  // #region don't refresh if version was updated very recently

  // if the db version is very recent, less than 8 minutes ago, do nothing. the reason being
  // during our build process the db version gets updated and then, some minutes later, the new app
  // version gets deployed. we need to wait for the new app version to be deployed before we
  // attempt to refresh
  const dtUpdated = DateTime.fromMillis(bckversion);
  // get the absolute value for ease of use (diff'ing in luxon produces negative numbers if the
  // dtUpdated is in the past)
  const updatedMinsAgo = Math.abs(dtUpdated.diffNow('minutes').as('minutes'));
  if (updatedMinsAgo < 8) {
    // eslint-disable-next-line no-console
    console.log('new app version was released less than X mins ago so we wont update yet', updatedMinsAgo);
    return;
  }

  // #endregion

  // on first load, or first time this will be null so, we are not calculating the timeFrame.
  if (timeStampInLC) {
    const formattedTSFromLC = DateTime.fromMillis(timeStampInLC);
    timeFrame = formattedTSFromLC.plus({ hours: 24 }).toMillis();
  }

  /* NOTE: this timeStampInLC === null executes only once, in the start 
    OR when user remove/clear localStorage.
    first time, this will be null so we are adding current timestamp and 
    storing hardRefresh count in var(hardRefreshCountInLc), 
    updating the hardRefreshCountInLc then read again and make sure, it is 
    updated then we perform  reload. 
  */
  let oldRefreshCount = hardRefreshCount;

  if (timeStampInLC === null) {
    // updating the currentTime in local storage will be used for comparison later
    updateCurrentTimeStampeInLC(now);

    // function making sure hardRefreshCountInLc is updated first then we are doing hardreload
    updateRefreshCountAndHandleErrors(oldRefreshCount);

    /**
     * if the timestamp in localstorage is more than 24 hours ago, we can start all 
     * over -- we can refresh the page even if the previous refresh count was above 3 
     * (because it has been more than 24 hours since we last refreshed). 
     * So we reset the timestamp in localstorage to null, and hardrefresh count to 0
     */
  } else if (now > timeFrame) {
    // reseting timeStampInLC and hardRefreshCount count in localStorage
    resetAppVersionInLC();

    oldRefreshCount = 0;

    const {
      timeStampInLC: timestamp,
      hardRefreshCount: count
    } = getRefreshCountAndCurrntTimeFromLC();

    // making sure local storage values are updated and we have updated values
    if (timestamp === null && count === 0) {
      // it is mandatory to update time in local store after reset.
      updateCurrentTimeStampeInLC(now);

      // making sure hardRefreshCountInLc is updated first then we are doing hardreload
      updateRefreshCountAndHandleErrors(oldRefreshCount);
    } else {
      Sentry.captureException(
        new Error('IMPORTANT - Failed to update local storge values'),
        {
          extra: {
            ts: timestamp,
            ct: count,
          }
        }
      );
    }

    // if currentTime is less than timeFrame(which is 24 hour more than last reload
    // time which is present in localStorage)
  } else if (now < timeFrame) {
    // function making sure hardRefreshCountInLc is updated first then we are doing hardreload
    updateRefreshCountAndHandleErrors(oldRefreshCount);
  } else {
    Sentry.captureException(
      new Error('handleVersionMisMatch Issue- Something went wrong. non of our condition match!'),
    );
  }
};

/** updates the refresh count in local storage, perform hard reload & handle error.
 * 
 * @param oldRefreshCount - The previous refresh count.
 * function increments the refresh count and retrieves the updated count, timeStamp 
 * from local storage. checks if the new count matches the expected value and 
 * is within an acceptable range (<= 3) and timeStampInLC not null and timeStampInLC is less
 * than time timeFrame(which is equal to 24h + the value stored in localstorage (timeStampInLC)). 
 * If all conditions are met, the page is reloaded to ensure the latest version of 
 * the app is loaded. 
 * If the conditions are not met, an error is logged to Sentry.
 */
const updateRefreshCountAndHandleErrors = (oldRefreshCount: number) => {
  const newRefreshCount = oldRefreshCount + 1;

  // updating refresh count in local storage to make sure localstorage values updated first then 
  // we perform our hard reload
  updateRefreshCountInLC(newRefreshCount);

  // getting the updated value of hardRefreshCount from local storage
  const {
    hardRefreshCount: hardRefreshCountInLc,
    timeStampInLC
  } = getRefreshCountAndCurrntTimeFromLC();

  // this will hold a time which is equal to 24h + the value stored in localstorage (timeStampInLC)
  let timeFrame = 0;

  if (timeStampInLC) {
    const formattedTSFromLC = DateTime.fromMillis(timeStampInLC);
    timeFrame = formattedTSFromLC.plus({ hours: 24 }).toMillis();

    /** making sure hardRefreshCountInLc is updated first and we have timeStamp in our 
     * localStorage and it is within 24hours then we are performing hard reload other wise we are
     * loging error to the sentry
     */
    if (hardRefreshCountInLc === newRefreshCount
      && hardRefreshCountInLc <= 5
      && timeStampInLC < timeFrame) {
      // reloading the page to get the latest version of app
      window.location.reload();

      // this would be a big problem, we seemingly did not update localstorage. log that problem
    } else if (hardRefreshCountInLc !== newRefreshCount) {
      Sentry.captureException(
        new Error('IMPORTANT - Failed to update local storge refresh count!'),
        {
          extra: {
            instorage: hardRefreshCountInLc,
            wewanted: newRefreshCount,
          }
        }
      );

      // this should also not happen, would be a bug. somewhere above we forgot or incorrectly
      // updated the timestamp in localstorage, or localstore just failed to update
    } else if (timeStampInLC >= timeFrame) {
      Sentry.captureException(
        new Error('IMPORTANT - timeStampInLC was >= timeFrame'),
        {
          extra: {
            tsinlc: timeStampInLC,
            tf: timeFrame,
          }
        }
      );

      // the only other possibility isn't a *big* problem, but for now at least we're still going to
      // log it. the user's browser has attempted to hard refresh 5 times within the last 24 hours
      // already. this either means the browser is, after these refreshes, not getting the newly
      // updated app version or it means we've deployed new app verions numerous times in the last
      // 24 hours
    } else {
      Sentry.captureException(
        new Error('This users browser has hard refreshed 5 times already in past 24 hours'),
        {
          extra: {
            count: hardRefreshCountInLc,
          }
        }
      );
    }
  } else {
    Sentry.captureException(
      new Error('IMPORTANT - Failed to get local storage timeStampInLC'),
    );
  }
};
