import React from 'react';
import * as Sentry from '@sentry/react';
import {
  Ty, Button, Grid2Ct, Grid2, CardStandard, IcSvgList,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import {
  ErrorsCapturePaymentMutation,
  ErrorsCreateOrderMutation,
  ErrorsVerifyActivateMutation
} from './Errors';
import { sitedata } from '../../../../../../utils/sitedata';

export const NonfixableError = ({ error }: any) => {
  // create order errors
  if (error === ErrorsCreateOrderMutation.NonFixablePackageNotFound) {
    return (
      <BacktoPurchasePage />
    );
  }

  // capture payment errors
  if (error === ErrorsCapturePaymentMutation.NonFixable_RESOURCE_NOT_FOUND
    || error === ErrorsCapturePaymentMutation.NonFixablePaypalPaymentNotFound
  ) {
    return (
      <BacktoPurchasePage />
    );
  }

  // verifyactivate error (that the user can correct by going back to the purchase page)
  if (error === ErrorsVerifyActivateMutation.NonFixableOrderNotFound) {
    return (
      <BacktoPurchasePage />
    );
  }

  // verifyactivate error (case that we do not know if the user completed their purchase)
  if (error === ErrorsVerifyActivateMutation.NonFixableUnsureOfPaymentStatus) {
    return (
      <MajorErrorUnsureOfPayment />
    );
  }

  // problem, log to sentry -- an error was passed to this component that was not expected
  Sentry.captureException(
    new Error('Important! An unexpeted error was passed to Nonfixable error. Should not occur!'),
    {
      extra: {
        theErr: error
      }
    }
  );
  return (
    <BacktoPurchasePage />
  );
};

// card with text that just says the user needs to go back to the purchase page
const BacktoPurchasePage = () => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <CardStandard
        titleText="An Error Occurred"
        titleIcon={IcSvgList.trafficcone1}
        titleIconRight={0}
        color="accentOrange1"
      >
        <Grid2Ct>
          {/* text */}
          <Grid2 xs={12}>
            <Ty>
              An unexpected error occurred, you were not charged.
            </Ty>
            <Ty>
              Please go back to the main Purchase page and click the Checkout button again. We
              apologize for the inconvenience; if you continue seeing this error PLEASE let
              us know!
            </Ty>
          </Grid2>

          {/* button */}
          <Grid2 xs={12}>
            <Button
              color="accentOrange1"
              to={sitedata.url.app.purchaseHomeDt.pathFull}
              component={NavLink}
              size="large"
            >
              Purchase Home Page
            </Button>
          </Grid2>
        </Grid2Ct>
      </CardStandard>
    </Grid2>
  </Grid2Ct>
);

// card with text that tells the user they should not purchase again until they are sure
// that the first purchase did not go through
const MajorErrorUnsureOfPayment = () => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <CardStandard
        titleText="An Error Occurred - Please Read!"
        titleIcon={IcSvgList.trafficcone1}
        titleIconRight={0}
        color="accentOrange1"
      >
        <Grid2Ct>
          {/* text */}
          <Grid2 xs={12}>
            <Ty>
              An unexpected error occurred, and we cannot be 100% certain whether you were
              charged or not. We apologize for the inconvenience; this is rare and only happens
              when there is an unrecoverable error with our payment processor.
            </Ty>
            <Ty>
              Please go back to the main Purchase page and check the Purchase History section.
              We suggest waiting 5 minutes, and refreshing the page there; if your purchase went
              through, it will likely be shown there. Once you are certain you were not already
              charged you can of course attempt the purchase again.
            </Ty>
            <Ty>
              You can also contact our customer service team (go to the More page to do that) and
              have them confirm whether or not you were charged.
            </Ty>
          </Grid2>

          {/* button */}
          <Grid2 xs={12}>
            <Button
              color="accentOrange1"
              to={sitedata.url.app.purchaseHomeDt.pathFull}
              component={NavLink}
              size="large"
            >
              Purchase Home Page
            </Button>
          </Grid2>
        </Grid2Ct>
      </CardStandard>
    </Grid2>
  </Grid2Ct>
);
