import { graphql } from 'babel-plugin-relay/macro';

export const StuSubsCreatecheckoutlink = graphql`
mutation StuSubsCreatecheckoutlinkMutation {
  stu_subs_createcheckoutlink {
    checkoutUrl
    errors
    success
  }
}
`;
