import React from 'react';
import { useFragment } from 'react-relay';
import {
  SwiperSlide,
} from '@languageconvo/wcl';
import { MyTeacherContainer } from './PrivateSchedLsn.style';
import { PrivateSchedLsnDataCardFragment } from '../../../../relay/posttrial/PrivateSchedLsnData';
import { PrivateSchedLsnDataCardFragment$key } from '../../../../relay/posttrial/__generated__/PrivateSchedLsnDataCardFragment.graphql';
import { sitedata } from '../../../../../../../utils/sitedata';
import { RelayFavoritedVals } from '../../../../../../../common/relay/resolvers/teachers/relayFavorited';

interface PrivateSchedLsnContentsComponentProps {
  fragmentRef: PrivateSchedLsnDataCardFragment$key
}

// a single slide within the slider. contains teacher name, image, link to schedule
export const PrivateSchedLsnSliderCard = ({
  fragmentRef
}: PrivateSchedLsnContentsComponentProps) => {
  const teacherData = useFragment(PrivateSchedLsnDataCardFragment, fragmentRef);

  // teacher name, url to schedule page, image (or a default if they don't have an image)
  const tchName = teacherData.title;
  let tchImage = `${process.env.REACT_APP_IMAGESCDNURL}${sitedata.assets.imgDefaultProfile}`;
  if (teacherData.resources_files_profileimgs?.length > 0) {
    tchImage = `${teacherData.resources_files_profileimgs[0].fuploads.file_cdn.base_url}${teacherData.resources_files_profileimgs[0].fuploads.file_path}`;
  }
  const tchUrl = `${sitedata.url.app.privatelessonsTeacherProfile}${teacherData.pk}`;

  // only display this teacher IF the teacher is a favorite
  const displayTeacher = teacherData.relayFavorited === RelayFavoritedVals.IsFavorite;

  return (
    displayTeacher ? (
      <SwiperSlide>
        <MyTeacherContainer
          teacherName={tchName}
          teacherImg={tchImage}
          to={tchUrl}
        />
      </SwiperSlide>
    ) : (null)
  );
};
