import { graphql } from 'babel-plugin-relay/macro';

export const UpdateUserGroupSettings = graphql`
  mutation GroupUserSettingsUpdateMutation(
    $groupName: String!
    $groupPassword: String!
  ) {
  stu_settings_groupcredscreate(
    groupName: $groupName
    groupPassword: $groupPassword
  ) {
      success
    errors
    user {
      id
      groupname
    }
  }
}
`;
