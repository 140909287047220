import React from 'react';
import {
  NavLink, useLocation,
} from 'react-router-dom';
import {
  Link,
} from '@languageconvo/wcl';
import {
  NavbarContainer, MobileItemContainer, MobileItemContainerIcon,
  MobileIcon, MobileItemContainerText,
} from './MobileBottomBar.style';
import { getActive } from '../../../navbar/navbarinner/NavbarInnerList';
import { sitedata } from '../../../../../utils/sitedata';

// fixed bottom navbar on mobile devices
export const MobileBottomBar = () => {
  const homeIcon = `${process.env.REACT_APP_IMAGESCDNURL}/uis/navbar/teacher.svg`;
  const practiceIcon = `${process.env.REACT_APP_IMAGESCDNURL}/uis/navbar/learn.svg`;
  const progressIcon = `${process.env.REACT_APP_IMAGESCDNURL}/uis/navbar/trophy1.svg`;
  const purchaseIcon = `${process.env.REACT_APP_IMAGESCDNURL}/uis/navbar/purchase.svg`;
  const moreIcon = `${process.env.REACT_APP_IMAGESCDNURL}/uis/navbar/settings.svg`;

  // we add bold text style to the active route, using the following
  const { pathname } = useLocation();
  const inactiveStyle = {
    active: 0,
  };
  const activeStyle = {
    active: 1,
  };

  return (
    // here we are passing pathname prop to hide navbar on small screen 
    // when student is on groupLesson route/page
    <NavbarContainer pathname={pathname}>
      {/* progress */}
      <Link
        to={sitedata.url.app.progressHomeDt.pathFull}
        component={NavLink}
        linkStyle="nostyle"
      >
        <MobileItemContainer>
          <MobileItemContainerIcon>
            <MobileIcon src={progressIcon} />
          </MobileItemContainerIcon>
          <MobileItemContainerText
            {...(getActive(
              sitedata.url.app.progressHomeDt.pathFull,
              pathname
            )
              ? activeStyle : inactiveStyle)}
          >
            Progress
          </MobileItemContainerText>
        </MobileItemContainer>
      </Link>

      {/* practice */}
      <Link
        to={sitedata.url.app.practiceHome}
        component={NavLink}
        linkStyle="nostyle"
      >
        <MobileItemContainer>
          <MobileItemContainerIcon>
            <MobileIcon src={practiceIcon} />
          </MobileItemContainerIcon>
          <MobileItemContainerText
            {...(getActive(sitedata.url.app.practiceHome, pathname)
              ? activeStyle : inactiveStyle)}
          >
            Practice
          </MobileItemContainerText>
        </MobileItemContainer>
      </Link>

      {/* home */}
      <Link
        to={sitedata.url.app.home}
        component={NavLink}
        linkStyle="nostyle"
      >
        <MobileItemContainer>
          <MobileItemContainerIcon>
            <MobileIcon src={homeIcon} />
          </MobileItemContainerIcon>
          <MobileItemContainerText
            {...(getActive(sitedata.url.app.home, pathname) ? activeStyle : inactiveStyle)}
          >
            Lessons
          </MobileItemContainerText>
        </MobileItemContainer>
      </Link>

      {/* purchase */}
      <Link
        to={sitedata.url.app.purchaseHome}
        component={NavLink}
        linkStyle="nostyle"
      >
        <MobileItemContainer>
          <MobileItemContainerIcon>
            <MobileIcon src={purchaseIcon} />
          </MobileItemContainerIcon>
          <MobileItemContainerText
            {...(getActive(sitedata.url.app.purchaseHome, pathname)
              ? activeStyle : inactiveStyle)}
          >
            Purchase
          </MobileItemContainerText>
        </MobileItemContainer>
      </Link>

      {/* more */}
      <Link
        to={sitedata.url.app.moreHome}
        component={NavLink}
        linkStyle="nostyle"
      >
        <MobileItemContainer>
          <MobileItemContainerIcon>
            <MobileIcon src={moreIcon} />
          </MobileItemContainerIcon>
          <MobileItemContainerText
            {...(getActive(sitedata.url.app.moreHome, pathname) ? activeStyle : inactiveStyle)}
          >
            More
          </MobileItemContainerText>
        </MobileItemContainer>
      </Link>
    </NavbarContainer>
  );
};
