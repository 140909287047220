// errors that can occur during create order api call
export enum ErrorsCreateOrderMutation {
  // Package is not found in _packs table for package id recieved from frontend
  NonFixablePackageNotFound = 'PackageNotFound',
  // the create order mutation did not return a valid paypal payment id
  FixableNoPaymentId = 'FixableNoPaymentId',
  // an unknown error occurred. we'll assume it's fixable so the user can try clicking the buy
  // button again
  FixableUnknown = 'FixableUnknown',
  // Price that user wants to pay for this package is not correct, this either means that price for
  // package on our frontend is invalid or user edited the price and are trying to pay wrong price
  FixablePriceNotVerified = 'PriceNotVerified',
  // Our code failed to create an inactive order in our database
  FixableFailedToCreateInactiveOrder = 'FailedToCreateInactiveOrder',
  // This is returned when our code failed to create order's payment in Paypal
  FixableExternalAPIFailure = 'ExternalAPIFailure',
  // This error occur when our backend failed to store Payment id from Paypal into _orders row
  // this should be extremely rare and when this happens, frontend needs to ask user to retry
  // entire purchase process
  FixableFailedToUpdatePaymentId = 'FailedToUpdatePaymentId'
}

// errors that can occur during capture payment api call
export enum ErrorsCapturePaymentMutation {
  // paypal order id from our database is not found in Paypal,
  // in this case frontend will need to ask user to go through purchase process again
  NonFixable_RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  // payment found in out system but is missing in payPal, need to tell user to go through
  // whole process again from choose page.
  NonFixablePaypalPaymentNotFound = 'PaypalPaymentNotFound',
  // this error will be returned from backend in any other case, which are not from
  // the below mentioned cases
  FixableGeneralError = 'GeneralError',
  // user’s credit card has expired, in frontend we show user
  // a message to use different payment method
  Fixable_CARD_EXPIRED = 'CARD_EXPIRED',
  // payment instrument such as credit or debit card was declined either by Paypal or by user’s bank
  // in frontend we show user a message to use different payment method
  Fixable_INSTRUMENT_DECLINED = 'INSTRUMENT_DECLINED',
  // order has not been approved by user, frontend needs to ask user to authorize this payment
  Fixable_ORDER_NOT_APPROVED = 'ORDER_NOT_APPROVED',
  // in frontend ask user to use an alternative payment method,
  // because their transaction failed for selected payment method
  Fixable_REDIRECT_PAYER_FOR_ALTERNATE_FUNDING = 'REDIRECT_PAYER_FOR_ALTERNATE_FUNDING',
  // user’s paypal account has been closed by paypal or have been locked for security reasons,
  // in frontend show user a message to check their account
  Fixable_PAYER_ACCOUNT_LOCKED_OR_CLOSED = 'PAYER_ACCOUNT_LOCKED_OR_CLOSED',
  // user’s account has been restricted by Paypal, in frontend
  // show user a message to check their account
  Fixable_PAYER_ACCOUNT_RESTRICTED = 'PAYER_ACCOUNT_RESTRICTED',
  // card used to make this purchase has been closed by card issuer,
  // frontend show user message to use alternative payment method
  Fixable_CARD_CLOSED = 'CARD_CLOSED',
  // user entered invalid cvc for their card and somehow our frontend API call didn't return
  // an error for invalid cvc in this case our backend capture payment API call will return
  // this error to our frontend
  Fixable_CARD_CVC_INVALID = 'CARD_CVC_INVALID',
  // This error is returned by our backend to our frontend when Paypal returns us any card
  // related errors other than "CVC check fails" error or "Card expired" error.
  Fixable_GENERAL_CARD_ERROR = 'GENERAL_CARD_ERROR',
}

// errors that can occur during the verifyactivate api call
export enum ErrorsVerifyActivateMutation {
  // This error is returned when user is trying to complete a purchase for an order invoice number
  // that either doesn't exist in our database
  NonFixableOrderNotFound = 'OrderNotFound',
  // This error can occur when paypal API is down or when Paypal API returned us an unexpected
  // response or when our base function bck_orders_verifyactivate throws an unexpected
  NonFixableUnsureOfPaymentStatus ='UnsureOfPaymentStatus',
  // verifyactivate told us that capturing the payment failed. the user will need to try again
  FixablePaymentfailed = 'FixablePaymentfailed',
}

export enum ErrorsClickingOnSubmitButton {
  // we are setting this error from .submit method of card fields
  // because we need to show user a fixable error message that there
  // card details are not valid
  FixableInvalidCardFields = 'InvalidCardFields',
}
