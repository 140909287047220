/**
 * @generated SignedSource<<6be066b1fd30033c1fbe02e65d1288b7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClassroomBasicDataTermsprivacy1Fragment$data = {
  readonly termsprivacy_grouplsn_id: number | null;
  readonly " $fragmentType": "ClassroomBasicDataTermsprivacy1Fragment";
};
export type ClassroomBasicDataTermsprivacy1Fragment$key = {
  readonly " $data"?: ClassroomBasicDataTermsprivacy1Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClassroomBasicDataTermsprivacy1Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClassroomBasicDataTermsprivacy1Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "termsprivacy_grouplsn_id",
      "storageKey": null
    }
  ],
  "type": "user_settings",
  "abstractKey": null
};

(node as any).hash = "9728988c7d5cb5cc5fb8b9ff352aacea";

export default node;
