//
// complete color set
//
// here we define *all* colors that can be used in our app. when adding or removing a color here, you should also
// go to /designsystem/colors.stories.mdx and update
const x = {
  app: {
    // appbar/navbar. this is white
    navbar: '0, 0%, 100%',
    // page background. this is a pale blueish color, not really related to any other color in our pallete. it just
    // gives good contrast for cards on the page
    page: '210, 31%, 95%',
    // card (main container) on page. this is white, but possible we change that in future
    container: '0, 0%, 100%',
  },

  // in light theme, text should be very dark
  text: {
    // very light, usually for text that denotes diabled. also reduce saturation so it gets close to a gray
    disabled: '157, 6%, 70%',
    // not quite as dark as base, this allows much more of the primary color to peak through
    sub: '157, 30%, 30%',
    // base is very close to black. most users won't be able to tell, but they will notice it feels harmonius. the
    // secret is, throughout the app, only use black and white for the very lightest and darkest needs
    base: '157, 44%, 13%',
    // for primary links in light mode, we lighten the text up so it stands out against the dark text
    linkPrimary: '157, 44%, 50%',
    linkSecondary: '16, 100%, 42%',
  },

  primary: {
    lightest: '157, 20%, 98%',
    lighter3: '157, 20%, 95%',
    lighter2: '157, 20%, 90%',
    lighter1: '157, 20%, 75%',
    base: '157, 44%, 36%',
    darker1: '157, 30%, 30%',
    darkest: '157, 44%, 10%',
  },

  secondary: {
    lightest: '16, 80%, 99%',
    lighter3: '16, 70%, 95%',
    lighter2: '16, 70%, 90%',
    lighter1: '16, 70%, 85%',
    base: '16, 100%, 42%',
    darker1: '16, 100%, 32%',
    darkest: '16, 100%, 10%',
  },

  info: {
    lightest: '188, 30%, 99%',
    lighter3: '188, 30%, 95%',
    lighter2: '188, 30%, 90%',
    lighter1: '188, 30%, 85%',
    base: '188, 100%, 32%',
    darker1: '188, 100%, 23%',
    darkest: '188, 100%, 11%',
  },

  error: {
    lightest: '343, 50%, 99%',
    lighter3: '343, 50%, 95%',
    lighter2: '343, 50%, 90%',
    lighter1: '343, 50%, 85%',
    base: '343, 79%, 47%',
    darker1: '343, 79%, 27%',
    darkest: '343, 79%, 11%',
  },

  warning: {
    lightest: '50, 75%, 99%',
    lighter3: '50, 75%, 95%',
    lighter2: '50, 75%, 90%',
    lighter1: '50, 75%, 85%',
    base: '50, 90%, 58%',
    darker1: '50, 90%, 28%',
    darkest: '50, 90%, 11%',
  },

  // neturals - RARELY USE!
  // instead of grays, you can often use one of the .lightest/.lighter or .darkest/darker aboves.
  // using one of those is usually better, because they are similar to grays, but give a small, almost
  // imperceptible hint of our app colors
  neutral: {
    // white
    white: '0, 0%, 100%',
    // very light gray
    lightest: '208, 7%, 97%',
    lighter3: '208, 7%, 85%',
    lighter2: '208, 7%, 72%',
    lighter1: '208, 7%, 57%',
    // mid gray
    base: '208, 7%, 46%',
    darker1: '208, 7%, 33%',
    darker2: '208, 7%, 24%',
    darker3: '208, 7%, 18%',
    // very dark gray
    darkest: '208, 7%, 12%',
    // black
    black: '0, 0%, 0%',
  },

  // #region accent colors

  // These colors should be used in *most* places in the app. As an example:
  //  - If an error occurs and you need to notify the user of the error, use the error color
  //  - But if you just want a red color to make something look nice, use accentRed

  accentGreen1: {
    lightest: '157, 20%, 98%',
    lighter3: '157, 20%, 95%',
    lighter2: '157, 20%, 90%',
    lighter1: '157, 20%, 75%',
    base: '157, 44%, 36%',
    darker1: '157, 30%, 30%',
    darkest: '157, 44%, 10%',
  },

  accentPurple1: {
    lightest: '270, 28%, 98%',
    lighter3: '270, 28%, 95%',
    lighter2: '270, 28%, 90%',
    lighter1: '270, 28%, 70%',
    base: '270, 48%, 40%',
    darker1: '270, 32%, 32%',
    darkest: '270, 48%, 10%',
  },

  accentBlue1: {
    lightest: '188, 30%, 99%',
    lighter3: '188, 30%, 95%',
    lighter2: '188, 30%, 90%',
    lighter1: '188, 30%, 85%',
    base: '188, 100%, 32%',
    darker1: '188, 100%, 23%',
    darkest: '188, 100%, 11%',
  },

  accentRed1: {
    lightest: '343, 50%, 99%',
    lighter3: '343, 50%, 95%',
    lighter2: '343, 50%, 90%',
    lighter1: '343, 50%, 85%',
    base: '343, 79%, 47%',
    darker1: '343, 79%, 27%',
    darkest: '343, 79%, 11%',
  },

  accentOrange1: {
    lightest: '16, 80%, 99%',
    lighter3: '16, 70%, 95%',
    lighter2: '16, 70%, 90%',
    lighter1: '16, 70%, 85%',
    base: '16, 100%, 42%',
    darker1: '16, 100%, 32%',
    darkest: '16, 100%, 10%',
  },

  accentYellow1: {
    lightest: '50, 75%, 99%',
    lighter3: '50, 75%, 95%',
    lighter2: '50, 75%, 90%',
    lighter1: '50, 75%, 85%',
    base: '50, 90%, 58%',
    darker1: '50, 90%, 28%',
    darkest: '50, 90%, 11%',
  },

  // #endregion
};

// here we set colors that are shared across multiple components, and we want to ensure they are always the
// same. a good example is divider. divider colors are used in the Divider component, but they're also used
// in the DataGrid component. we want them to all look the same, so we define it here and use these, rather than
// directly using the "raw" colors above.
const cp = {
  // dividers throughout our app. this includes the Divider component, but also things like the DataGrid which has
  // dividers between column headers and things like that
  divider: {
    thin: `hsla(${x.text.base}, 0.1)`,
    base: `hsla(${x.text.base}, 0.4)`,
    bold: `hsla(${x.text.base}, 1)`,
  },
  // lists throughout the app often we want to have the same appearance in regards to things like hover/selected
  // bg color
  itemlist: {
    hoverbg: {
      primary: `hsl(${x.primary.lighter2})`,
      secondary: `hsl(${x.secondary.lighter2})`,
      accentOrange1: `hsl(${x.accentOrange1.lighter2})`,
    },
  },
  // a very light bg color for small cards inside the main Card or CardStandard
  bgInnerCard: {
    accentGreen1: `hsla(${x.accentGreen1.lighter2}, 25%)`,
    accentPurple1: `hsla(${x.accentPurple1.lighter2}, 25%)`,
    accentBlue1: `hsla(${x.accentBlue1.lighter2}, 25%)`,
    accentRed1: `hsla(${x.accentRed1.lighter2}, 25%)`,
    accentOrange1: `hsla(${x.accentOrange1.lighter2}, 25%)`,
    accentYellow1: `hsla(${x.accentYellow1.lighter2}, 25%)`,
  },
};

export const light = {
  ...x,
  cp: {
    ...cp,
  },
};
