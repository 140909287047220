import styled from '@emotion/styled';
import { Popover as CustomPopover } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { VariantProps } from './interface';

export const CustomPopoverContainer = styled(CustomPopover)(({ theme, width, height }: WCLThemeProps & VariantProps) => ({
  '& .MuiPaper-root': {
    // if width was set explicitly, set it, otherwise it's auto
    width: width ? `${width}px` : 'auto',
    height: height ? `${height}px` : 'auto',
    boxShadow: 'none',
    backgroundColor: `hsl(${theme?.ourTheme.colors.app.container})`,
    margin: '-3px',
    pointerEvents: 'auto',
    // border
    borderStyle: theme?.ourTheme.borders.mainBorder.popoverStyle,
    borderWidth: theme?.ourTheme.borders.mainBorder.popoverWidth,
    borderRadius: `${theme?.ourTheme.borders.borderRadius.medium}px`,

    // border color, different in light and dark modes
    ...(theme?.themeMode === 'light' && {
      borderColor: `hsla(${theme?.ourTheme.colors.neutral.lighter2}, 1)`,
    }),
    ...(theme?.themeMode === 'dark' && {
      borderColor: `hsla(${theme?.ourTheme.colors.app.page}, 1)`,
    }),
  },
}));
