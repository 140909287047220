import styled from '@emotion/styled';
import {
  Card, Box, CardContent,
} from '@mui/material';
import { spacing } from '../../../ourTheme';
import { CardStyleProps } from './interface';

export const CustomCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'align' && prop !== 'growsOnHover' && prop !== 'cursor',
})<CardStyleProps>(({
  theme, align, growsOnHover, cursor,
}) => {
  return ({
    display: 'flex',
    backgroundColor: theme.ourTheme.colors.app.container,
    boxShadow: 'none',
    border: theme.ourTheme.borders.mainBorder.border,
    borderRadius: theme.ourTheme.borders.borderRadius.medium,
    justifyContent: `${align === 'justify' ? 'center' : ''}`,
    alignItems: `${align === 'justify' ? 'center' : ''}`,
    cursor: `${cursor ? 'pointer' : ''}`,
    // this is a page-level item that users don't really "interact with"
    transition: theme?.ourTheme.components.transition.page,
    ':hover': {
      transform: `${growsOnHover ? 'scale(1.05)' : ''}`,
    },
  });
});

// styled component of card content which will accept padding from ourTheme.
// padding and margin seems likewise, so I decide to use padding.
export const CustomCardContent = styled(CardContent)(() => {
  return ({
    padding: `${spacing.padding.cardContentPadding} !important`,
    width: '100%',
  });
});
// for storybook, to align the content vertically
export const FlexColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});
