import React from 'react';
import * as Sentry from '@sentry/react';

/** If the app errors before it gets to the router, this is what we will show.
 * Note that it's very possible our ThemeProvider component has not yet been loaded,
 * so we cannot use wcl components! This has to be very basic. You can test it by
 * throwing an error in any of the components that load in App.tsx before the router
 * Design: we decided to just make this a blank page, because #1 it will rarely happen,
 * and #2 it kind of makes it look like the problem was not on our end but with their
 * browser or something (which might be the case)
*/
export const AppLevelError = ({ e }: any) => {
  Sentry.captureMessage('App level error occurred!');
  Sentry.captureException(e);

  return (
    <div>
      &nbsp;
    </div>
  );
};
