import OT from '@opentok/client';
import * as Sentry from '@sentry/react';
import { z } from 'zod';
import { defaultGroupLesson } from '../../../../utils/lcStorage';
import { GroupLesson } from '../../../../utils/lcStorageInterface';

// #region publisher's microphone

//  Here we get all mics the user has available. Note that we filter out cameras,
//  and we ALSO filter out any devices that have a null deviceId (which can happen
//  if the user has denied access to his devices). It's also possible for a device to
//  not have a lable, so we set a fake label in that case to ensure it's never an empty string
export const getAvailableMics = () => new Promise((resolve, reject) => {
  OT.getDevices((error, devices) => {
    if (error) {
      // Handle error
      reject(error);
    } else {
      // eslint-disable-next-line no-console
      console.log('all devices raw: ', devices);

      // parse the devices, ensuring that we only get audio devices (microphones) which have
      // a deviceId. also format the label if label is an empty string
      const formattedArr: any = [];
      let micFakenameCount = 0;
      devices?.forEach((el) => {
        // only get audio devices (microphones) that have a deviceId. during testing, deviceId
        // was undefined on some browsers if the user denied access to their devices
        if (el.kind === 'audioInput' && el.deviceId) {
          // next, we ensure the label isn't an empty string (or a very short 1 char string).
          // if it is, then we'll create a fake name like "Mic 1", "Mic 2", etc. On some browsers
          // like firefox, the label is empty even if the user gives mic permissionsk if they don't
          // select "*always* allow access to my mic"
          let mlabel = el.label;
          if (el.label.length < 2) {
            micFakenameCount += 1;
            mlabel = `Mic ${micFakenameCount}`;
          }

          // now add the device to our return array
          formattedArr.push(
            {
              deviceId: el.deviceId,
              kind: el.kind,
              label: mlabel,
            }
          );
        }
      });

      // eslint-disable-next-line no-console
      console.log('all devices formatted: ', formattedArr);
      resolve(formattedArr);
    }
  });
});

// #endregion

// #region for groupLesson localstorage obj validation via ZOD

// Define the schema for groupLesson with the help of ZOD
const groupLessonSchema = z.object({
  isMute: z.boolean(),
  micId: z.union([z.string(), z.null()]),
});

// Function to validate localData
export const validateLocalData = (data: GroupLesson) => {
  // safeParse method returns an object containing either the successfully parsed
  // data or a ZodError instance containing detailed information about the validation problems.
  const result = groupLessonSchema.safeParse(data);
  if (result.success) {
    return result.data;
  }
  Sentry.captureMessage('LocalStored values for group lesson not valid.');

  // returning default groupLesson;
  return defaultGroupLesson;
};

// #endregion
