import React from 'react';
import { AlertTitle } from '@mui/material';
import { CustomAlert } from './index.style';
import { Button } from '../../inputs/Button';
import { Props } from './interface';

/**
 * Our main alert component. See:
 *
 * https://mui.com/material-ui/react-alert/
 *
 * https://mui.com/material-ui/api/alert/
 */

export const Alert: React.FunctionComponent<Props> = (props) => {
  const {
    title,
    onClick,
    severity = 'success',
    variant = 'standard',
    children,
    buttonText,
    cp,
  } = props;
  return (
    <CustomAlert
      severity={severity}
      variant={variant}
      action={buttonText && (
        <Button size="small" onClick={onClick}>
          {buttonText}
        </Button>
      )}
      {...cp}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </CustomAlert>
  );
};
