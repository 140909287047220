import React from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { Tips } from './Tips';

interface Props {
  pageSize: number,
}

const TipsHistoryTableQuery = graphql`
  query TipsHistoryTableQuery($after: String, $first: Int) {
    ...Tips @arguments(first: $first, after: $after)
  }
`;

export const TipsHistoryTable = ({ pageSize }: Props) => {
  // this query will get the data of all orders and tips to teacher
  const fragmentRef = useLazyLoadQuery(
    TipsHistoryTableQuery,
    {
      first: pageSize, // count
      after: null, // cursor
    },
  );

  return (
    <Tips fragmentRef={fragmentRef} pageSize={pageSize} />
  );
};
