import React from 'react';
import {
  Grid2Ct, Grid2, LottiePlayer, Ty,
} from '@languageconvo/wcl';
import { NoUpcomingAlertContainer } from './PrivateUpcoming.style';
import GiraffeTeacher from '../../../../../../../common/assets/lottie/giraffeteacher.json';

// no upcoming lessons
export const PrivateUpcomingEmpty = () => (
  <Grid2 xs={12}>
    <NoUpcomingAlertContainer>
      {/* height here, and display, alignItems below are to to vertically center the text */}
      <Grid2Ct sx={{ height: '100%' }}>
        {/* left side text */}
        <Grid2
          xs={8}
          sm={8}
          smOffset={1}
          md={5}
          mdOffset={2}
          lg={7}
          lgOffset={1}
          xl={6}
          xlOffset={1}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Ty align="center" removeMb>It looks like you don&apos;t have any upcoming lessons</Ty>
        </Grid2>

        {/* right side lottie animation. justifyContent to horizontally center
            alignItems to vertically push lottie down to the bottom of the container */}
        <Grid2
          xs={4}
          sm={3}
          md={4}
          lg={4}
          sx={{
            display: 'flex', alignItems: 'end', justifyContent: 'center'
          }}
        >
          <LottiePlayer animationSrc={GiraffeTeacher} animationHeight={150} />
        </Grid2>
      </Grid2Ct>
    </NoUpcomingAlertContainer>
  </Grid2>
);
