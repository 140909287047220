import React from 'react';
import { Props } from './interface';
import { ListItemButtonStyled } from './index.style';

/**
 * This is a custom ListItemButton designed to be used in conjunction with ListItemTextCustom, for styling
 * interior "lists". Mainly in popovers. This is used heavily in our appbar/navbar.
 */
export const ListItemButtonCustom: React.FunctionComponent<Props> = (props) => {
  const {
    children,
    size = 'medium',
    onClick = undefined,
    selected,
    key,
    color,
    component,
    to,
    href,
    rel,
    target,
  } = props;

  return (
    <ListItemButtonStyled
      size={size}
      onClick={onClick}
      selected={selected}
      key={key}
      color={color}
      component={component}
      to={to}
      href={href}
      rel={rel}
      target={target}
    >
      {children}
    </ListItemButtonStyled>
  );
};
