/**
 * @generated SignedSource<<e2aaa995914a00ba1e632fd5189ab3cf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PurchaseRecieptFragment$data = {
  readonly actual_purchase_price: any | null;
  readonly created_at: any | null;
  readonly duration: number;
  readonly locations: {
    readonly pk: number;
  };
  readonly paypal_invoice_number: string | null;
  readonly " $fragmentType": "PurchaseRecieptFragment";
};
export type PurchaseRecieptFragment$key = {
  readonly " $data"?: PurchaseRecieptFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PurchaseRecieptFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PurchaseRecieptFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actual_purchase_price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "locations",
      "kind": "LinkedField",
      "name": "locations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pk",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paypal_invoice_number",
      "storageKey": null
    }
  ],
  "type": "orders",
  "abstractKey": null
};

(node as any).hash = "ea962b0c68cab35a9b132b915f42c645";

export default node;
