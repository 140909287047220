// responsible for Header height.
export const HEADER = {
  MOBILE_HEIGHT: 50,
  MOBILE_BOTTOMBAR_HEIGHT: 70,
  DASHBOARD_DESKTOP_HEIGHT: 70,
};

// top and bottom padding inside the page main contents
export const INNER_PADDING = {
  TOP_PADDING_XS: 16,
  TOP_PADDING_LG: 24,
  BOTTOM_PADDING_XS: 16,
  BOTTOM_PADDING_LG: 24,
};

// responsible for Header width and elements height.
export const NAVBAR = {
  BASE_WIDTH: 260,
  // // comes from wcl now
  // DASHBOARD_WIDTH: 260,
  // comes from wcl now
  // DASHBOARD_COLLAPSE_WIDTH: 96,
  // comes from wcl now
  // DASHBOARD_ITEM_ROOT_HEIGHT: 54,
  DASHBOARD_ITEM_SUB_HEIGHT: 40, // 40
};
