/**
 * @generated SignedSource<<9653e149ed1014e300e3ddbdd1a2f2ef>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StuSettingsGettrialdataMutation$variables = {
  languageId: number;
};
export type StuSettingsGettrialdataMutation$data = {
  readonly stu_settings_gettrialdata: {
    readonly " $fragmentSpreads": FragmentRefs<"StuSettingsGettrialdataFragment">;
  } | null;
};
export type StuSettingsGettrialdataMutation = {
  response: StuSettingsGettrialdataMutation$data;
  variables: StuSettingsGettrialdataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "languageId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "languageId",
    "variableName": "languageId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StuSettingsGettrialdataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StuSettingsGettrialdataOutput",
        "kind": "LinkedField",
        "name": "stu_settings_gettrialdata",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StuSettingsGettrialdataFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StuSettingsGettrialdataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StuSettingsGettrialdataOutput",
        "kind": "LinkedField",
        "name": "stu_settings_gettrialdata",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasCredits",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StuSettingsGettrialdataOutputDataTrial",
            "kind": "LinkedField",
            "name": "trial",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "atleastOneTeacherGivesTrials",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasMissedTwo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasUpcoming",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pastCompleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StuSettingsGettrialdataOutputDataTrialPast",
                "kind": "LinkedField",
                "name": "pastTrial",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pastTrialExists",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StuSettingsGettrialdataOutputDataTrialSteps",
                "kind": "LinkedField",
                "name": "stepsToSchedule",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "step1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "step2",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef46a601940dd1623f7d75fd116d2bba",
    "id": null,
    "metadata": {},
    "name": "StuSettingsGettrialdataMutation",
    "operationKind": "mutation",
    "text": "mutation StuSettingsGettrialdataMutation(\n  $languageId: Int!\n) {\n  stu_settings_gettrialdata(languageId: $languageId) {\n    ...StuSettingsGettrialdataFragment\n  }\n}\n\nfragment StuSettingsGettrialdataFragment on StuSettingsGettrialdataOutput {\n  id\n  hasCredits\n  trial {\n    atleastOneTeacherGivesTrials\n    hasMissedTwo\n    hasUpcoming\n    id\n    pastCompleted\n    pastTrial {\n      id\n      status\n    }\n    pastTrialExists\n    stepsToSchedule {\n      id\n      step1\n      step2\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8682d6272ae2fe883d87cf2fb945a2f6";

export default node;
