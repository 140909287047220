import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { inputLabelClasses } from '@mui/material/InputLabel';

export const CustomTextField = styled(TextField)(({ theme }: WCLThemeProps) => (
  {
    fontFamily: `${theme?.ourTheme.typography.fontFamily} !important`,

    // #region styles applied to the root

    // bg colors
    ...(theme?.themeMode === 'light' && {
      [`& .${outlinedInputClasses.root}`]: {
        // you'll notice this is different than the bg color of the Select component. this is bc mui
        // applies some strange styles, I think a background to the input element, that "add to"
        // the darkness of this bg color here
        backgroundColor: `hsla(${theme?.ourTheme.colors.app.page}, .6)`,
        fontFamily: `${theme?.ourTheme.typography.fontFamily} !important`,
      },
    }),
    ...(theme?.themeMode === 'dark' && {
      [`& .${outlinedInputClasses.root}`]: {
        backgroundColor: `hsla(${theme?.ourTheme.colors.app.page}, .6)`,
        fontFamily: `${theme?.ourTheme.typography.fontFamily} !important`,
      },
    }),

    // #region regular, hover, focused, error states

    // light theme
    ...(theme?.themeMode === 'light' && {
      // regular border
      [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: '1px',
        borderColor: theme?.ourTheme.colors.cp.divider.thin,
      },
      // hovered border
      [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: '1px',
        borderColor: theme?.ourTheme.colors.cp.divider.base,
      },
      // focused border
      [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: '1px',
        borderColor: theme?.ourTheme.colors.cp.divider.base,
      },
      // error border
      [`& .${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: '1px',
        // important needed, to keep the border color red when hovered and focused states apply
        borderColor: `hsl(${theme?.ourTheme.colors.error.base}) !important`,
      },
    }),

    // dark theme
    ...(theme?.themeMode === 'dark' && {
      // regular border
      [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: '1px',
        borderColor: theme?.ourTheme.colors.cp.divider.thin,
      },
      // hovered border
      [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: '1px',
        borderColor: theme?.ourTheme.colors.cp.divider.base,
      },
      // focused border
      [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: '1px',
        borderColor: theme?.ourTheme.colors.cp.divider.base,
      },
      // error border
      [`& .${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: '1px',
        // important needed, to keep the border color red when hovered and focused states apply
        borderColor: `hsl(${theme?.ourTheme.colors.error.base}) !important`,
      },
    }),

    // #endregion

    // #endregion

    // #region styles applied to the input

    ...(theme?.themeMode === 'light' && {
      [`& .${outlinedInputClasses.input}`]: {
        color: `hsl(${theme?.ourTheme.colors.text.base})`,
        paddingTop: '15px',
        paddingBottom: '14px',
        fontFamily: `${theme?.ourTheme.typography.fontFamily} !important`,
        [theme?.breakpoints.up('md') as any]: {
          fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem`,
        },
        [theme?.breakpoints.down('md') as any]: {
          fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem`,
        },
      },
      // size small
      [`& .${outlinedInputClasses.inputSizeSmall}`]: {
        paddingTop: '9px !important',
        paddingBottom: '7px !important',
        [theme?.breakpoints.up('md') as any]: {
          fontSize: `${theme?.ourTheme.typography.small.fontSizeRem}rem`,
        },
        [theme?.breakpoints.down('md') as any]: {
          fontSize: `${theme?.ourTheme.typography.small.fontSizeRemSmallscrn}rem`,
        },
      },
      // remove the padding if multiline textarea, bc it already has padding applied
      [`& .${outlinedInputClasses.inputMultiline}`]: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
      },
    }),
    ...(theme?.themeMode === 'dark' && {
      [`& .${outlinedInputClasses.input}`]: {
        color: `hsl(${theme?.ourTheme.colors.text.base})`,
        paddingTop: '15px',
        paddingBottom: '14px',
        fontFamily: `${theme?.ourTheme.typography.fontFamily} !important`,
        [theme?.breakpoints.up('md') as any]: {
          fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem`,
        },
        [theme?.breakpoints.down('md') as any]: {
          fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem`,
        },
      },
      // size small
      [`& .${outlinedInputClasses.inputSizeSmall}`]: {
        paddingTop: '9px !important',
        paddingBottom: '7px !important',
        [theme?.breakpoints.up('md') as any]: {
          fontSize: `${theme?.ourTheme.typography.small.fontSizeRem}rem`,
        },
        [theme?.breakpoints.down('md') as any]: {
          fontSize: `${theme?.ourTheme.typography.small.fontSizeRemSmallscrn}rem`,
        },
      },
      // remove the padding if multiline textarea, bc it already has padding applied
      [`& .${outlinedInputClasses.inputMultiline}`]: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
      },
    }),

    // #endregion

    // #region styles applied to the input *label*

    // normally, text is the main text color
    [`& .${inputLabelClasses.root}`]: {
      color: `hsl(${theme?.ourTheme.colors.text.base})`,
      fontFamily: `${theme?.ourTheme.typography.fontFamily}`,
    },

    // focused state
    [`& .${inputLabelClasses.focused}`]: {
      // if you remove this, the label text will change color when focused. ALSO important is that this is above
      // the .error state below. otherwise this will overwrite the error state
      color: `hsl(${theme?.ourTheme.colors.text.base}) !important`,
    },

    // but if error state is true, then font color is our main error color
    [`& .${inputLabelClasses.error}`]: {
      color: `hsl(${theme?.ourTheme.colors.error.base}) !important`,
    },

    // #endregion
  }
));
