import React from 'react';
import {
  Grid2Ct, Grid2, Skeleton,
} from '@languageconvo/wcl';
import { boxHeight } from './Step3Availability.style';

// loading glimmer for trial availability
export const Step3AvailabilityLoading = () => (
  <Grid2 xs={12}>
    <Grid2Ct columns={9}>
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
      <SingleRow />
    </Grid2Ct>
  </Grid2>
);

const SingleRow = () => (
  <>
    <Grid2 xs={3} sm={1} smOffset={1}>
      <Skeleton animation="wave" variant="rectangular" width="100%" height={boxHeight} />
    </Grid2>
    <Grid2 xs={3} sm={1}>
      <Skeleton animation="wave" variant="rectangular" width="100%" height={boxHeight} />
    </Grid2>
    <Grid2 xs={3} sm={1}>
      <Skeleton animation="wave" variant="rectangular" width="100%" height={boxHeight} />
    </Grid2>
    <Grid2 xs={3} sm={1}>
      <Skeleton animation="wave" variant="rectangular" width="100%" height={boxHeight} />
    </Grid2>
    <Grid2 xs={3} sm={1}>
      <Skeleton animation="wave" variant="rectangular" width="100%" height={boxHeight} />
    </Grid2>
    <Grid2 xs={3} sm={1}>
      <Skeleton animation="wave" variant="rectangular" width="100%" height={boxHeight} />
    </Grid2>
    <Grid2 xs={3} sm={1}>
      <Skeleton animation="wave" variant="rectangular" width="100%" height={boxHeight} />
    </Grid2>
  </>
);
