// MUI recommends using 8px scaling factor.
// Matching values none, small, meduim, large
export const spacing = {
  spacingArray: [0, 8, 16, 24, 32, 40],
  // For the consistency in all our the website, we are defining
  // padding here in a centralized place
  padding: {
    cardContentPadding: '24px',
    dataGridHeaderPadding: '24px',
  },
};
