import { graphql } from 'babel-plugin-relay/macro';
import React, { Suspense } from 'react';
import {
  useFragment
} from 'react-relay';
import { TableLoader } from '../../../../common/loaders/TableLoader';
import { GiftPurchasesContainer } from './GiftPurchasesContainer';

// this fragment will only read the user's primary key, because we need
// this primary key for getting the user's gifts from backend.
const fragment = graphql`
  fragment GiftsHistoryTableFragment on users {
    pk  
  }
`;

export const GiftsHistoryTable = ({ pageSize, fragmentRefForUserId }: any) => {
  // reading the userData for above fragment from relay store, so we can
  // pass it to "GiftPurchasesContainer" where we need it.
  const userData = useFragment(fragment, fragmentRefForUserId);

  return (
    <>
      {/* gifts */}
      {/* this suspense is for gift table data which we will get from backend */}
      <Suspense fallback={<TableLoader />}>
        <GiftPurchasesContainer
          userData={userData}
          pageSize={pageSize}
        />
      </Suspense>
    </>
  );
};
