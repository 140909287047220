/**
 * @generated SignedSource<<3bfbd2be0228bbf1b16699e6f6e3879d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrivateSchedLsnDataQuery$variables = {};
export type PrivateSchedLsnDataQuery$data = {
  readonly resources_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"PrivateSchedLsnDataCardFragment">;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"PrivateSchedLsnDataCountteachersFragment">;
};
export type PrivateSchedLsnDataQuery = {
  response: PrivateSchedLsnDataQuery$data;
  variables: PrivateSchedLsnDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "_eq": "1"
},
v1 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "tutor_visibility": (v0/*: any*/),
      "working_status": {
        "_is_null": true
      }
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "name": "relayFavorited",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "user_favteachers",
        "kind": "LinkedField",
        "name": "user_favteachers",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "favorite",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "relayFavoritedMutated",
            "storageKey": null
          }
        ]
      }
    ],
    "type": "resources",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": false
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrivateSchedLsnDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "resourcesConnection",
        "kind": "LinkedField",
        "name": "resources_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resourcesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "resources",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PrivateSchedLsnDataCardFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "resources_connection(where:{\"tutor_visibility\":{\"_eq\":\"1\"},\"working_status\":{\"_is_null\":true}})"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PrivateSchedLsnDataCountteachersFragment"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PrivateSchedLsnDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "resourcesConnection",
        "kind": "LinkedField",
        "name": "resources_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resourcesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "resources",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pk",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "limit",
                        "value": 1
                      },
                      {
                        "kind": "Literal",
                        "name": "order_by",
                        "value": {
                          "sort_order": "asc"
                        }
                      },
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "archived": {
                            "_eq": "0"
                          },
                          "image_approved": (v0/*: any*/),
                          "sort_order": {
                            "_gt": -1
                          }
                        }
                      }
                    ],
                    "concreteType": "resources_files_profileimg",
                    "kind": "LinkedField",
                    "name": "resources_files_profileimgs",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "fuploads",
                        "kind": "LinkedField",
                        "name": "fuploads",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "file_path",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "file_cdn",
                            "kind": "LinkedField",
                            "name": "file_cdn",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "base_url",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": "resources_files_profileimgs(limit:1,order_by:{\"sort_order\":\"asc\"},where:{\"archived\":{\"_eq\":\"0\"},\"image_approved\":{\"_eq\":\"1\"},\"sort_order\":{\"_gt\":-1}})"
                  },
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "resources_connection(where:{\"tutor_visibility\":{\"_eq\":\"1\"},\"working_status\":{\"_is_null\":true}})"
      }
    ]
  },
  "params": {
    "cacheID": "3116c925258cc8f3e61c775882722c43",
    "id": null,
    "metadata": {},
    "name": "PrivateSchedLsnDataQuery",
    "operationKind": "query",
    "text": "query PrivateSchedLsnDataQuery {\n  resources_connection(where: {working_status: {_is_null: true}, tutor_visibility: {_eq: \"1\"}}) {\n    edges {\n      node {\n        ...PrivateSchedLsnDataCardFragment\n        id\n      }\n    }\n  }\n  ...PrivateSchedLsnDataCountteachersFragment\n}\n\nfragment PrivateSchedLsnDataCardFragment on resources {\n  ...relayFavoritedResolver\n  pk\n  title\n  resources_files_profileimgs(limit: 1, order_by: {sort_order: asc}, where: {image_approved: {_eq: \"1\"}, archived: {_eq: \"0\"}, sort_order: {_gt: -1}}) {\n    fuploads {\n      file_path\n      file_cdn {\n        base_url\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment PrivateSchedLsnDataCountteachersFragment on query_root {\n  resources_connection(where: {working_status: {_is_null: true}, tutor_visibility: {_eq: \"1\"}}) {\n    edges {\n      node {\n        ...relayFavoritedResolver\n        id\n      }\n    }\n  }\n}\n\nfragment relayFavoritedResolver on resources {\n  user_favteachers {\n    favorite\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "24c11d6583d2a54944bfa6eaae3b1f35";

export default node;
