/**
 * @generated SignedSource<<c49979d75dc9207ea493021a1be493d9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type relayAgeResolver$data = {
  readonly dob: any | null;
  readonly " $fragmentType": "relayAgeResolver";
};
export type relayAgeResolver$key = {
  readonly " $data"?: relayAgeResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"relayAgeResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "relayAgeResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dob",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "de1c3fc770ba484868dd28c601f9fea4";

export default node;
