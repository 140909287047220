/**
 * @generated SignedSource<<adb54e29a4753cf43c5afa91b9a4c32a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageloadTimesettingsFragment$data = {
  readonly hour_cycle: string;
  readonly " $fragmentType": "PageloadTimesettingsFragment";
};
export type PageloadTimesettingsFragment$key = {
  readonly " $data"?: PageloadTimesettingsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PageloadTimesettingsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PageloadTimesettingsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hour_cycle",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "cf449fc167d17de11efe4c7fbc10646c";

export default node;
