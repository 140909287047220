import { graphql } from 'babel-plugin-relay/macro';

export const GetPackDetails = graphql`
fragment GetPackDetails on query_root @argumentDefinitions(paypal_payment_id: {type: "Int!"}) {
  packs_connection(where: {pk: {_eq: $paypal_payment_id}}) {
    edges {
      node {
        location_id
        price
        duration
      }
    }
  }
}
`;
