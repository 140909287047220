import { graphql } from 'babel-plugin-relay/macro';

// TODO: dont think this is needed now that we have user explicitly choose language
//  they are learning. should remove this and cleanup wherever it's used
export const ExtractPreferredLanguageFromStore = graphql`
  fragment ExtractPreferredLanguageFromStore on query_root {
    users_connection {
      edges {
        node {
          preferredLanguageKey
          selectedLang
        }
      }
    }
  }
`;
