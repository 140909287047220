/**
 * @generated SignedSource<<ef677708de35b3fb1dba411846d84971>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClassroomBasicDataUserFragment$data = {
  readonly hour_cycle: string;
  readonly " $fragmentType": "ClassroomBasicDataUserFragment";
};
export type ClassroomBasicDataUserFragment$key = {
  readonly " $data"?: ClassroomBasicDataUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClassroomBasicDataUserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClassroomBasicDataUserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hour_cycle",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "b566c69d863b7533d113a60adc60da1e";

export default node;
