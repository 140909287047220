import React, { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { PayPalButtons } from '@paypal/react-paypal-js';

export const PayPalPayButton = ({ createOrderMutation, commitCaptureMutation }: any) => {
  const PayPalPayButtonMemo = useMemo(() => (
    <PayPalButtons
      style={{
        label: 'paypal',
        layout: 'horizontal',
        tagline: false,
        color: 'blue',
        shape: 'rect',
      }}
      createOrder={async () => {
        const paymentId = await createOrderMutation();
        return paymentId;
      }}
      onApprove={async () => {
        commitCaptureMutation();
      }}
      onError={(err) => {
        Sentry.captureException(err);
      }}
      fundingSource="paypal"
    />

  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return PayPalPayButtonMemo;
};
