import React from 'react';
import {
  Grid2Ct, Grid2, Ty, Box, Button, styled, WCLThemeProps,
  useTheme,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { UpcomingSlideMeasurements } from '../Common';

// dimensions of each swiper slide. used below (the actual swiper) and for loading glimmer
export const SwiperDimensions = {
  height: '250px',
  width: '190px',
};

// css for the swiper section. here we set css on each individual slide; all other css must
// be set on the main <Swiper> component
export const SwiperContainerSchedulelsn = styled(Box)<WCLThemeProps>(({ theme }) => ({
  'swiper-slide': {
    backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentPurple1}`,
    borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
    height: SwiperDimensions.height,
    width: SwiperDimensions.width,
  },
}));

// teacher image container
const ImageTeacher = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;  /* defining the aspect ratio of the image */
  object-fit: cover;    /* making sure the image isn't distorted */
`;

// inner section of the swiper slide. displays a single teacher's name, image, and button to
// get to their scheduling page
export const MyTeacherContainer = (props: any) => {
  const {
    teacherName,
    teacherImg,
    to,
  } = props;
  const theme = useTheme();
  const imgRadius = theme.ourTheme.borders.borderRadius.images;

  return (
    <Grid2Ct sx={{ mt: 1 }}>
      {/* name, language */}
      <Grid2 xs={12}>
        {/* nowrap so long teacher names don't create extra vertical spacing */}
        <Ty removeMb align="center" v="small" noWrap>{teacherName}</Ty>
      </Grid2>

      {/* image */}
      <Grid2 xs={12}>
        <Box display="flex" sx={{ justifyContent: 'center' }}>
          <ImageTeacher
            src={teacherImg}
            alt={teacherName}
            style={{
              maxWidth: '120px',
              borderRadius: imgRadius,
            }}
          />
        </Box>
      </Grid2>

      {/* button */}
      <Grid2 xs={12} sx={{ mb: 1 }}>
        <Box display="flex" sx={{ justifyContent: 'center' }}>
          <Button
            component={NavLink}
            to={to}
            fullWidth
            color="accentPurple1"
            cp={{ sx: { mt: 0, maxWidth: '130px' }, 'data-testid': 'scheduleBtn' }}
          >Schedule
          </Button>
        </Box>
      </Grid2>
    </Grid2Ct>
  );
};

// colored container "no favorited teachers"
export const NoFavoritesContainer = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentPurple1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '30px',
}));

// colored container for error getting data
export const ErrorAlertContainer = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentPurple1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  paddingLeft: '20px',
  paddingRight: '20px',
  height: UpcomingSlideMeasurements.height,
}));
