import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Grid2Ct, Grid2, Ty, Link, LottiePlayer,
} from '@languageconvo/wcl';
import { NoFavoritesContainer } from './PrivateSchedLsn.style';
import { sitedata } from '../../../../../../../utils/sitedata';
import Search from '../../../../../../../common/assets/lottie/search.json';

// if the student hasn't favorited any teachers yet, this will show
export const PrivateSchedLsnEmpty = () => (
  <Grid2Ct>
    {/* IMPORTANT: if you edit this Grid2 here, you should also edit it in PrivateUpcoming2Empty
        which is the "no upcoming lessons" alert, so that they have the same widths */}
    <Grid2 xs={12}>
      <NoFavoritesContainer>
        <Grid2Ct>
          <Grid2 xs={12} md={8} mdOffset={2} lg={12} lgOffset={0} sx={{ pb: 0 }}>
            <Ty removeMb align="center">
              Add a teacher as a &quot;Favorite&quot; to schedule with them!
              Go to the&nbsp;
              <Link
                to={sitedata.url.app.teachersDiscover}
                component={NavLink}
                color="accentPurple1"
              >Discover Teachers
              </Link>
              &nbsp;page. Browse and find one or more teachers, then
              click the &quot;Favorite&quot; button on their profile!
            </Ty>
          </Grid2>

          <Grid2 xs={12} sx={{ pb: 0, pt: 0 }}>
            <LottiePlayer animationSrc={Search} animationHeight={158} />
          </Grid2>
        </Grid2Ct>
      </NoFavoritesContainer>
    </Grid2>
  </Grid2Ct>
);
