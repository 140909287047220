import {
  Box,
} from '@mui/material';
import { vars } from '../../../utils/vars';
import { Props } from './interface';
import { CustomCardIcon } from './index.style';
import { IcSvg } from '../../datadisplay';

/**
 * A small, colored card similar to CardSmallColor (same height as that) but that *only* contains an icon.
 *
 * It can be given onClick functionality (often used for showing a modal), or wrapped in a Link component
 * (typically with hovercursor='cursor') to link to another page.
 */
export const CardSmallColorIcon = (props: Props) => {
  const {
    color,
    icon,
    cp,
    onClick,
    hovercursor = 'none',
  } = props;

  const baseComponent = (
    <CustomCardIcon
      color={color}
      onClick={onClick}
      hovercursor={hovercursor}
      {...cp}
    >
      <Box display="flex" justifyContent="center">
        <IcSvg icon={icon} width={vars.svgIconStandardSize} height={vars.svgIconStandardSize} />
      </Box>
    </CustomCardIcon>
  );

  return baseComponent;
};
