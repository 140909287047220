import styled from '@emotion/styled';
import { Avatar } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { AvatarIconContainerProps } from './interface';

export const AvatarIconContainer = styled(
  Avatar,
  {
  // second argument to the styled function is an options object, one of the things it accepts is
  // shouldForwardProp, which as the docs say, "Indicates whether the prop should be forwarded to
  // the Component". So to remove the unknown prop warning from the console, we tell it not to pass
  // our custom prop to the DOM element with shouldForwardProp: (prop) => prop !== "primary"
    shouldForwardProp: (prop) => prop !== 'backgroundColor',
  },
)(({ theme, backgroundColor }: WCLThemeProps & AvatarIconContainerProps) => (
  {
    // IMPORTANT
    // if you edit styles that apply to the info variant, you likely also should edit the QuestionIconModal component
    // as it is very similar to this component

    // these apply to all variants
    transition: theme?.ourTheme.components.transition.item,
    cursor: 'pointer',
    border: 'none',
    borderRadius: theme?.ourTheme.borders.borderRadius.medium,

    // primary - light
    ...(theme?.themeMode === 'light' && backgroundColor === 'primary' && {
      background: `hsl(${theme?.ourTheme.colors.primary.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.primary.darker1})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.primary.base})`,
        color: `hsl(${theme?.ourTheme.colors.primary.lightest})`,
      },
    }),
    // primary - dark
    ...(theme?.themeMode === 'dark' && backgroundColor === 'primary' && {
      background: `hsl(${theme?.ourTheme.colors.primary.lighter1})`,
      color: `hsl(${theme?.ourTheme.colors.primary.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.primary.base})`,
        color: `hsl(${theme?.ourTheme.colors.primary.lightest})`,
      },
    }),

    // secondary - light
    ...(theme?.themeMode === 'light' && backgroundColor === 'secondary' && {
      background: `hsl(${theme?.ourTheme.colors.secondary.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.secondary.darker1})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.secondary.base})`,
        color: `hsl(${theme?.ourTheme.colors.secondary.lightest})`,
      },
    }),
    // secondary - dark
    ...(theme?.themeMode === 'dark' && backgroundColor === 'secondary' && {
      background: `hsl(${theme?.ourTheme.colors.secondary.lighter1})`,
      color: `hsl(${theme?.ourTheme.colors.secondary.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.secondary.base})`,
        color: `hsl(${theme?.ourTheme.colors.secondary.lightest})`,
      },
    }),

    // info - light
    ...(theme?.themeMode === 'light' && backgroundColor === 'info' && {
      background: `hsl(${theme?.ourTheme.colors.info.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.info.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.info.base})`,
        color: `hsl(${theme?.ourTheme.colors.info.lightest})`,
      },
    }),
    // info - dark
    ...(theme?.themeMode === 'dark' && backgroundColor === 'info' && {
      background: `hsl(${theme?.ourTheme.colors.info.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.info.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.info.base})`,
        color: `hsl(${theme?.ourTheme.colors.info.lightest})`,
      },
    }),

    // error - light
    ...(theme?.themeMode === 'light' && backgroundColor === 'error' && {
      background: `hsl(${theme?.ourTheme.colors.error.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.error.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.error.base})`,
        color: `hsl(${theme?.ourTheme.colors.error.lightest})`,
      },
    }),
    // error - dark
    ...(theme?.themeMode === 'dark' && backgroundColor === 'error' && {
      background: `hsl(${theme?.ourTheme.colors.error.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.error.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.error.base})`,
        color: `hsl(${theme?.ourTheme.colors.error.lightest})`,
      },
    }),

    // warning - light
    ...(theme?.themeMode === 'light' && backgroundColor === 'warning' && {
      background: `hsl(${theme?.ourTheme.colors.warning.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.warning.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.warning.base})`,
        color: `hsl(${theme?.ourTheme.colors.warning.lightest})`,
      },
    }),
    // warning - dark
    ...(theme?.themeMode === 'dark' && backgroundColor === 'warning' && {
      background: `hsl(${theme?.ourTheme.colors.warning.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.warning.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.warning.base})`,
        color: `hsl(${theme?.ourTheme.colors.warning.lightest})`,
      },
    }),

    // neutral - light
    ...(theme?.themeMode === 'light' && backgroundColor === 'neutral' && {
      background: `hsl(${theme?.ourTheme.colors.neutral.lighter3})`,
      color: `hsl(${theme?.ourTheme.colors.neutral.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.neutral.base})`,
        color: `hsl(${theme?.ourTheme.colors.neutral.lightest})`,
      },
    }),
    // neutral - dark
    ...(theme?.themeMode === 'dark' && backgroundColor === 'neutral' && {
      background: `hsl(${theme?.ourTheme.colors.neutral.lighter3})`,
      color: `hsl(${theme?.ourTheme.colors.neutral.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.neutral.base})`,
        color: `hsl(${theme?.ourTheme.colors.neutral.lightest})`,
      },
    }),
  }
));
