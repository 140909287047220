import React, { Suspense, useState, useLayoutEffect } from 'react';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';
import {
  CardStandard, Grid2Ct, Grid2, Link, CardSmallColor, Skeleton, Swiper, SwiperSlide,
  Ic, Ty, IcSvgList, Box,
} from '@languageconvo/wcl';
import { GridEl, UpcomingSlideMeasurements } from '../Common';
import { sitedata } from '../../../../../../../utils/sitedata';
import { PrivateUpcomingGetdata } from './PrivateUpcoming1Getdata';
import { SwiperContainerUpcominglsn, ErrorAlertContainer } from './PrivateUpcoming.style';

// main component
export const PrivateUpcoming = () => {
  /**
   * currentTs holds the current unix timestamp, used by relay query to get
   * upcoming/ongoing lessons.
   * We are not lazily importing these components which causing these components
   * to re-render twice.
   * If we directly use DateTime.now() without using react state, it sends network
   * requests twice, that's why, it is always best practice to take advantage of
   * react states to tackle this situation.
   */
  const [currentTs, setCurrentTs] = useState(0);
  useLayoutEffect(() => {
    // the current unix timestamp, used by relay query to get upcoming/ongoing lessons
    setCurrentTs(Math.floor(DateTime.now().toSeconds()));
  }, []);

  return (
    <GridEl>
      <CardStandard
        titleText="Upcoming Private Lessons"
        titleIcon={IcSvgList.calendar2}
        color="accentOrange1"
        titleIconRight={0}
        cp={{ sx: { width: '100%' } }}
      >
        <Grid2Ct>
          {/* slider with upcoming lessons. or note that there are no upcoming lessons.
              min height is needed to stop page "jump" that was occurring immediately after
              suspense on some mobile devices */}
          <Box sx={{ minHeight: UpcomingSlideMeasurements.heightPlusPadding, width: '100%' }}>
            {currentTs !== 0 && (
            <Sentry.ErrorBoundary fallback={<Error />}>
              <Suspense fallback={<Loading />}>
                <PrivateUpcomingGetdata currentTmsp={currentTs} />
              </Suspense>
            </Sentry.ErrorBoundary>
            )}
          </Box>

          {/* small card - my full schedule */}
          <Grid2 xs={12}>
            <Grid2Ct>
              <Grid2 xs={12} sm={8} md={5} lg={12} xl={8} sx={{ mt: 1 }}>
                <Link
                  to={sitedata.url.app.privatelessonsMycalendar}
                  component={NavLink}
                  linkStyle="nostyle"
                >
                  <CardSmallColor
                    text="My Full Schedule"
                    icon={IcSvgList.calendar5}
                    color="accentOrange1"
                    showArrow
                    hovercursor="pointer"
                  />
                </Link>
              </Grid2>
            </Grid2Ct>
          </Grid2>
        </Grid2Ct>
      </CardStandard>
    </GridEl>
  );
};

// loading glimmer for upcoming lessons slider
const Loading = () => (
  <Grid2 xs={12}>
    <SwiperContainerUpcominglsn>
      <Swiper
        ourcustomcolor="accentOrange1"
        ourcustomwidth="100%"
          // number of slides shown is automatically determined by user's screen width
        slidesPerView="auto"
          // kind of a "smooth scrolling" through slides type of effect
        freeMode={{
          enabled: true,
          // this stops a "bounce back" effect which is especially annoying on mobile
          momentumBounce: false,
        }}
        spaceBetween="15"
      >
        <SwiperSlide>
          <Skeleton variant="rectangular" height={UpcomingSlideMeasurements.height} width={UpcomingSlideMeasurements.width} />
        </SwiperSlide>
        <SwiperSlide>
          <Skeleton variant="rectangular" height={UpcomingSlideMeasurements.height} width={UpcomingSlideMeasurements.width} />
        </SwiperSlide>
        <SwiperSlide>
          <Skeleton variant="rectangular" height={UpcomingSlideMeasurements.height} width={UpcomingSlideMeasurements.width} />
        </SwiperSlide>
        <SwiperSlide>
          <Skeleton variant="rectangular" height={UpcomingSlideMeasurements.height} width={UpcomingSlideMeasurements.width} />
        </SwiperSlide>
        <SwiperSlide>
          <Skeleton variant="rectangular" height={UpcomingSlideMeasurements.height} width={UpcomingSlideMeasurements.width} />
        </SwiperSlide>
        <SwiperSlide>
          <Skeleton variant="rectangular" height={UpcomingSlideMeasurements.height} width={UpcomingSlideMeasurements.width} />
        </SwiperSlide>
        <SwiperSlide>
          <Skeleton variant="rectangular" height={UpcomingSlideMeasurements.height} width={UpcomingSlideMeasurements.width} />
        </SwiperSlide>
        <SwiperSlide>
          <Skeleton variant="rectangular" height={UpcomingSlideMeasurements.height} width={UpcomingSlideMeasurements.width} />
        </SwiperSlide>
      </Swiper>
    </SwiperContainerUpcominglsn>
  </Grid2>
);

// error for upcoming lessons slider
const Error = () => (
  // IMPORTANT: if you edit this Grid2, you should also edit the PrivateSchedLns error container
  // Grid2. So that their widths match
  <Grid2 xs={12}>
    <ErrorAlertContainer>
      {/* height here, and display, alignItems below are to to vertically center the text */}
      <Grid2Ct sx={{ height: '100%' }}>
        {/* IMPORTANT: if you edit this Grid2, you should also edit the PrivateSchedLns error
        container Grid2. So that their widths match */}
        <Grid2
          xs={12}
          sm={10}
          smOffset={1}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Ty removeMb align="center">
            <Ic iconName="triangle-exclamation" color="accentOrange1" iconStyle="duotone" />&nbsp;&nbsp;Hmm, something
            went wrong. Please reload the page, and if you continue seeing this error *please*
            let us know!
          </Ty>
        </Grid2>
      </Grid2Ct>
    </ErrorAlertContainer>
  </Grid2>
);
