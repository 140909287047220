import { graphql } from 'babel-plugin-relay/macro';

export const PrivateSchedLsnDataQuery = graphql`
  query PrivateSchedLsnDataQuery {
    resources_connection(where: {working_status: {_is_null: true}, tutor_visibility: {_eq: "1"}}) {
      edges {
        node {
          ...PrivateSchedLsnDataCardFragment
        }
      }
    }
    ...PrivateSchedLsnDataCountteachersFragment
  }  
`;

/**
 * Here we get all available teachers, including whether the student has "favorited" each teacher.
 * Used by PrivateSchedLsn to determine if we should display a slider/carousel of favorited teachers
 *  - This will be used to simply get a *count* of how many teachers the user has favorited
 *  - Why get all teachers instead of only getting teachers the user has favorited? The
 *    relayFavorited value is a relay resolver field, it only exists in relay. And it is what
 *    tells us whether or not a teacher is a favorite. So, we can't query for this field from
 *    the db
 */
export const PrivateSchedLsnDataCountteachersFragment = graphql`
  fragment PrivateSchedLsnDataCountteachersFragment on query_root {
    resources_connection(where: {working_status: {_is_null: true}, tutor_visibility: {_eq: "1"}}) {
      edges {
        node {
          relayFavorited
        }
      }
    }
  }
`;

/**
 * Here we get the data that our PrivateSchedLsnSliderCard needs to display each favorited teacher
 */
export const PrivateSchedLsnDataCardFragment = graphql`
  fragment PrivateSchedLsnDataCardFragment on resources {
    relayFavorited
    pk
    title
    resources_files_profileimgs(limit: 1, order_by: {sort_order: asc}, where: {image_approved: {_eq: "1"}, archived: {_eq: "0"}, sort_order: {_gt: -1}}) {
      fuploads {
        file_path
        file_cdn {
          base_url
        }
      }
    }
  }
`;
