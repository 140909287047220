import { IcSvgList } from '@languageconvo/wcl';

// IMPORTANT
// The *order* of the array below matters; it's the order that the languages will be displayed
// in numerous components, like the "language I'm currently learning" component. In future
// we should add a sortOrder property and use that instaed

// each language we currently teach. a few notes:
//  - DONT use the "name" property. it's here just for reference, so that we know easily which
//    language id is which language
//  - we should use the translation file languageLocations to get the actual name of a lang
//    when displaying it to a user
//  - the order is not lowest to highest id; instead, it's ordered by popularity
export const languages = [
  {
    id: 1,
    name: 'spanish',
    flagIcon: IcSvgList.flagspain1,
  },
  {
    id: 4,
    name: 'french',
    flagIcon: IcSvgList.flagfrance1,
  },
  {
    id: 9,
    name: 'german',
    flagIcon: IcSvgList.flaggerman1,
  },
  {
    id: 3,
    name: 'italian',
    flagIcon: IcSvgList.flagitaly1,
  },
  {
    id: 5,
    name: 'portuguese',
    flagIcon: IcSvgList.flagportugal1,
  },
  {
    id: 10,
    name: 'mandarin',
    flagIcon: IcSvgList.flagchina1,
  },
  {
    id: 7,
    name: 'japanese',
    flagIcon: IcSvgList.flagjapan1,
  },
  {
    id: 11,
    name: 'arabic',
    flagIcon: IcSvgList.flagarabnation1,
  },
  {
    id: 8,
    name: 'latin',
    flagIcon: IcSvgList.flaglatin1,
  },
  {
    id: 6,
    name: 'russian',
    flagIcon: IcSvgList.flagrussia1,
  },
];
