/**
 * @generated SignedSource<<6dff7e9b3538ce25e83ce11f47db5d9a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuApptScheduletrialMutation$variables = {
  tchUuid: string;
  unixTs: number;
};
export type StuApptScheduletrialMutation$data = {
  readonly stu_appt_scheduletrial: {
    readonly data: {
      readonly trial: {
        readonly hasUpcoming: boolean | null;
        readonly id: string | null;
      } | null;
    } | null;
    readonly errors: ReadonlyArray<string | null> | null;
    readonly success: boolean;
  } | null;
};
export type StuApptScheduletrialMutation = {
  response: StuApptScheduletrialMutation$data;
  variables: StuApptScheduletrialMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tchUuid"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unixTs"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tchUuid",
        "variableName": "tchUuid"
      },
      {
        "kind": "Variable",
        "name": "unixTs",
        "variableName": "unixTs"
      }
    ],
    "concreteType": "StuApptScheduletrialOutput",
    "kind": "LinkedField",
    "name": "stu_appt_scheduletrial",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StuApptScheduletrialOutputData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StuApptScheduletrialOutputDatatrial",
            "kind": "LinkedField",
            "name": "trial",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasUpcoming",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StuApptScheduletrialMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StuApptScheduletrialMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "228e376b14ecd8fefad20bb33c349f72",
    "id": null,
    "metadata": {},
    "name": "StuApptScheduletrialMutation",
    "operationKind": "mutation",
    "text": "mutation StuApptScheduletrialMutation(\n  $unixTs: Int!\n  $tchUuid: String!\n) {\n  stu_appt_scheduletrial(unixTs: $unixTs, tchUuid: $tchUuid) {\n    success\n    errors\n    data {\n      trial {\n        id\n        hasUpcoming\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8382daffed0a645311250df007bacde4";

export default node;
