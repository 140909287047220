import { graphql } from 'babel-plugin-relay/macro';

// TODO: dont think this is needed now that we have user explicitly choose language
//  they are learning. should remove this and cleanup wherever it's used
export const GetLanguageFromAppointments = graphql`
fragment GetLanguageFromAppointments on query_root {
 appointments_connection(
    first: 1,
    order_by: {starts_at: desc}
) {
    edges {
     node {
      location_id
     }
    }
  }
}
`;
