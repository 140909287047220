import React from 'react';
import {
  MenuItem, FormControl, FormHelperText,
} from '@mui/material';
import { CustomSelect, CustomInputLabel } from './index.style';
import { Props } from './interface';

/**
 * Our main Select component. See:
 *
 * https://mui.com/material-ui/react-select/
 *
 * https://mui.com/material-ui/api/select/
 */

export const Select: React.FunctionComponent<Props> = (props) => {
  const {
    label,
    onChange,
    onBlur,
    value,
    options,
    fullWidth = true,
    disabled = false,
    error = false,
    size = 'medium',
    helperText,
    cp,
    selectCp,
  } = props;

  // build SelectDisplayProps value
  // this changes the inner padding, to effectively change the height of the select box
  const styleSmall = {
    style: { paddingTop: '8px', paddingBottom: '8px' },
  };
  const styleMedium = {
    style: { paddingTop: '15px', paddingBottom: '14px' },
  };
  let slcDisplayProps = styleMedium;
  if (size === 'small') {
    slcDisplayProps = styleSmall;
  }

  return (
    <FormControl
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
      size={size}
      {...cp}
    >
      <CustomInputLabel
        // this, along with three settings in the Select (displayEmpty={false}, notched, and label)
        // permanently puts the label at the top of the select box
        shrink
      >
        {label}
      </CustomInputLabel>
      <CustomSelect
        size={size}
        SelectDisplayProps={{ ...slcDisplayProps }}
        value={value}
        // these three permanently put the label at the top of the select box
        displayEmpty={false}
        notched
        label={label}
        // onchange function
        onChange={onChange}
        onBlur={onBlur}
        {...selectCp}
      >
        {
          options?.map((option) => (
            <MenuItem key={option.name} value={option.value}>{option.name}</MenuItem>
          ))
        }
      </CustomSelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
