import { graphql } from 'babel-plugin-relay/macro';

export const GetUserPreferencesQuery = graphql`
  query GetUserPreferencesQuery {
    user_settings_connection {
      edges {
        node {
          ...GetUserPreferencesFragment
        }
      }
    }

    users_connection{
      edges{
        node{
          ...GetUserPreferencesLangLearningFragment
        }
      }
    }
  }
`;

export const GetUserPreferencesFragment = graphql`
  fragment GetUserPreferencesFragment on user_settings{
        aic_level
        aic_setsettings
        aic_translate
      }
`;

export const GetUserPreferencesLangLearningFragment = graphql`
fragment GetUserPreferencesLangLearningFragment on users {
  lang_learning
} `;
