import { graphql } from 'babel-plugin-relay/macro';

export const ClassroomBasicDataQuery = graphql`
  query ClassroomBasicDataQuery($lessonUuid: uuid!) {
    # what version (row id) of our group lesson terms of use the user has accepted, if any
    user_settings_connection {
      edges {
        node {
          ...ClassroomBasicDataTermsprivacy1Fragment
        }
      }
    }

    # the current version (row id) of our group lesson terms of use
    terms_privacy_grouplsn_connection (
      where: {
        is_current: {_eq: 1}
      }
    ) {
      edges {
        node {
          ...ClassroomBasicDataTermsprivacy2Fragment
        }
      }
    }

    # lesson data, for the info modal
    appt_group_connection (
      where: {
        uuid: { _eq: $lessonUuid}
      }
    ) {
      edges {
        node {
          ...ClassroomBasicDataLessoninfoFragment
            # hand_raised data, for the info modal, need to highlight buttons
            appt_group_reservations {
              ...ClassroomBasicDataHandRaisValueFragment
            }
        }
      }
    
    }

    # user data needed to show lesson times correctly to the user
    users_connection {
      edges {
        node {
          ...ClassroomBasicDataUserFragment
        }
      }
    }
  }

`;

// fragments for group class terms/privacy the user has to accept
export const ClassroomBasicDataTermsprivacy1Fragment = graphql`
  fragment ClassroomBasicDataTermsprivacy1Fragment on user_settings {
    termsprivacy_grouplsn_id
  }
`;
export const ClassroomBasicDataTermsprivacy2Fragment = graphql`
  fragment ClassroomBasicDataTermsprivacy2Fragment on terms_privacy_grouplsn {
    pk
  }
`;

// fragment the lesson information modal needs
export const ClassroomBasicDataLessoninfoFragment = graphql`
  fragment ClassroomBasicDataLessoninfoFragment on appt_group {
    starts_at
    ends_at
    skills {
      description_en
      display_order
      title_en
      uuid
      skills_levels {
        display_order
        title_en
        skills_categories {
          title_en
        }
      }
    }
  }
`;

export const ClassroomBasicDataTermsprivacyMutation = graphql`
  mutation ClassroomBasicDataTermsprivacyMutation {
    stu_settings_acceptgrplsnterms {
      success
      errors
      user_settings {
        id
        termsprivacy_grouplsn_id
      }
    }
  }
`;

// hour_cycle needed in lessonInfo modal to display lesson times correctly to the user
export const ClassroomBasicDataUserFragment = graphql`
  fragment ClassroomBasicDataUserFragment on users {
    hour_cycle
  }
`;

// fragment to get hand_raised value from db to highlight button
export const ClassroomBasicDataHandRaisValueFragment = graphql`
fragment ClassroomBasicDataHandRaisValueFragment on appt_group_reservations {
  id
  hand_raised
}
`;
