import React from 'react';
import {
  Grid2Ct, Grid2, Ty, Button, Box,
} from '@languageconvo/wcl';
import { setHasInteractedTrue } from '../../../../../../common/relay/clientschema/relayappsettings/groupclassFunctionality';

// require user interact with the page, by clicking a button. this ensures autoplay
// will work as expected
export const V202 = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <Grid2Ct
      // this vertically centers the children
      sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
    >
      <Grid2 xs={12}>
        <Grid2Ct>
          {/* text */}
          <Grid2 xs={12}>
            <Ty v="h2New" align="center">Click here to enter the classroom:</Ty>
          </Grid2>

          {/* button */}
          <Grid2 xs={12}>
            <Box display="flex" justifyContent="center">
              {/* set relay client schema groupClass.hasInteracted = true when button clicked */}
              <Button size="large" color="accentOrange1" onClick={() => setHasInteractedTrue()}>Enter Classroom!</Button>
            </Box>
          </Grid2>
        </Grid2Ct>
      </Grid2>
    </Grid2Ct>
  );
};
