import {
  Box, styled, WCLThemeProps
} from '@languageconvo/wcl';
import { HEADER, INNER_PADDING } from '../../config';
import { sitedata } from '../../utils/sitedata';

type MainStyleProps = {
    collapseClick: boolean | undefined;
};

export const MainContentContainer = styled(Box, {
})(({ theme, pathname }: WCLThemeProps & any) => ({
  width: '100%',
  marginTop: HEADER.MOBILE_HEIGHT,

  /* height for xs, sm, md, lg (except in group classroom)
    simply subtract the top and bottom bar heights from 100%
    dvh for newer browsers. this solves problems on mobile
    browsers with our navbar not being visible when the url address bar is hidden while
    scrolling. on older browsers that don't support dvh, use vh
  */
  height: `calc(100vh - ${HEADER.MOBILE_HEIGHT}px - ${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px)`,
  // @ts-ignore
  height: `calc(100dvh - ${HEADER.MOBILE_HEIGHT}px - ${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px)`, // eslint-disable-line

  /* xs, sm, md in the group classroom
      on mobile, in the group classroom, we do not show the bottom bar. so the height calc in this
      case does not subtract the bottom bar height
  */
  [theme!.breakpoints.down('lg')]: {
    // for newer browsers that support "dvh" we want to use that. this solves problems on mobile
    // browsers with our navbar not being visible when the url address bar is hidden while
    // scrolling. on older browsers that don't support dvh, use vh
    height: pathname === sitedata.url.app.groupclassroomDt.pathName ? `calc(100vh - ${HEADER.MOBILE_HEIGHT}px)` : `calc(100vh - ${HEADER.MOBILE_HEIGHT}px - ${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px)`,
    // @ts-ignore
    height: pathname === sitedata.url.app.groupclassroomDt.pathName ? `calc(100dvh - ${HEADER.MOBILE_HEIGHT}px)` :`calc(100dvh - ${HEADER.MOBILE_HEIGHT}px - ${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px)`, // eslint-disable-line
  },

  // lg, xl, xxl
  [theme!.breakpoints.up('lg')]: {
    marginTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,

    /* height
      simply subtrack the header height from 100% (there is no bottom bar on desktop screens).
      dvh for newer browsers
    */
    height: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`,
    // @ts-ignore
    height: `calc(100dvh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`, // eslint-disable-line
  },
}));

export const MainContentBackground = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps & WCLThemeProps & any>(({ collapseClick, theme, pathname }) => ({
  flexGrow: 1,
  paddingBottom: 0,
  transition: theme?.ourTheme.components.transition.page,

  /* min height for xs, sm, mb, lg (except in group classroom)
    subtract the top and bottom bar heights from 100%
    dvh for newer browsers
  */
  minHeight: `calc(100dvh - ${HEADER.MOBILE_HEIGHT}px - ${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px)`,
  // @ts-ignore
  minHeight:`calc(100dvh - ${HEADER.MOBILE_HEIGHT}px - ${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px)`, // eslint-disable-line

  /* xs, sm, md in the group classroom
    there is no bottom bar in the classroom on mobile, so we don't include that height
  */
  [theme!.breakpoints.down('lg')]: {
    minHeight: pathname === sitedata.url.app.groupclassroomDt.pathName ? `calc(100vh - ${HEADER.MOBILE_HEIGHT}px)` : `calc(100dvh - ${HEADER.MOBILE_HEIGHT}px - ${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px)`,
    // @ts-ignore
    minHeight: pathname === sitedata.url.app.groupclassroomDt.pathName ? `calc(100dvh - ${HEADER.MOBILE_HEIGHT}px)` : `calc(100dvh - ${HEADER.MOBILE_HEIGHT}px - ${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px)`, // eslint-disable-line
  },

  /* lg, xl, xxl, desktop where the navbar is on the left */
  [theme!.breakpoints.up('lg')]: {
    paddingBottom: 0,
    /* min height
      we simply subtract the desktop header height from full height.
      dvh for newer browsers
    */
    minHeight: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`,
    // @ts-ignore
    minHeight: `calc(100dvh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`, // eslint-disable-line

    ...(collapseClick && {
      marginLeft: theme.ourTheme.components.navbar.navbarWidthCollapse,
    }),
    ...(!collapseClick && {
      marginLeft: theme?.ourTheme.components.navbar.navbarWidthExpanded,
    }),
  },
  borderTopLeftRadius: '8px',
  border: 'none',
  boxShadow: 'none',
  // main page background color
  background: `hsl(${theme?.ourTheme.colors.app.page})`,
}));

export const InnerContentContainer = styled('div')(({ theme }: WCLThemeProps) => ({
  paddingTop: `${INNER_PADDING.TOP_PADDING_XS}px`,
  [theme!.breakpoints.up('lg')]: {
    paddingTop: `${INNER_PADDING.TOP_PADDING_LG}px`
  }
}));

export const MaxWidthContainer = styled('div')(() => ({
  width: '100%',
  maxWidth: '1800px',
  margin: '0 auto',
}));
