/**
 * @generated SignedSource<<b66b90acc54a25555cae96fdd96d9d22>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeacherProfileFragment$data = {
  readonly resources_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly gets_new_students: any;
        readonly id: string;
        readonly instructor_languages: {
          readonly language_id: number;
        } | null;
        readonly pk: number;
        readonly provides_free_trial: any;
        readonly relayFavorited: ReturnType<typeof resourcesRelayFavoritedResolverType> | null;
        readonly resources_files_profileimgs: ReadonlyArray<{
          readonly fuploads: {
            readonly file_cdn: {
              readonly base_url: string;
            };
            readonly file_path: string;
          };
        }>;
        readonly stuprof_aboutme: string | null;
        readonly stuprof_city: string | null;
        readonly stuprof_country: {
          readonly common_name: string | null;
        } | null;
        readonly title: string;
        readonly user_favteachers: ReadonlyArray<{
          readonly favorite: any;
        }>;
        readonly uuid: any;
        readonly wants_new_students: any;
      };
    }>;
  };
  readonly " $fragmentType": "TeacherProfileFragment";
};
export type TeacherProfileFragment$key = {
  readonly " $data"?: TeacherProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeacherProfileFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "_eq": "1"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tchId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeacherProfileFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_eq",
                  "variableName": "tchId"
                }
              ],
              "kind": "ObjectValue",
              "name": "pk"
            },
            {
              "kind": "Literal",
              "name": "tutor_visibility",
              "value": (v0/*: any*/)
            },
            {
              "kind": "Literal",
              "name": "working_status",
              "value": {
                "_is_null": true
              }
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "resourcesConnection",
      "kind": "LinkedField",
      "name": "resources_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "resourcesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "resources",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gets_new_students",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "wants_new_students",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "provides_free_trial",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pk",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "uuid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stuprof_aboutme",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stuprof_city",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "relayFavoritedResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "relayFavorited",
                  "resolverModule": require('./../../../../../../common/relay/resolvers/teachers/relayFavorited').relayFavorited,
                  "path": "resources_connection.edges.node.relayFavorited"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user_favteachers",
                  "kind": "LinkedField",
                  "name": "user_favteachers",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "favorite",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "countries",
                  "kind": "LinkedField",
                  "name": "stuprof_country",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "common_name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "instructor_languages",
                  "kind": "LinkedField",
                  "name": "instructor_languages",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "language_id",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "limit",
                      "value": 10
                    },
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "sort_order": "asc"
                      }
                    },
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "archived": {
                          "_eq": "0"
                        },
                        "image_approved": (v0/*: any*/),
                        "sort_order": {
                          "_gt": -1
                        }
                      }
                    }
                  ],
                  "concreteType": "resources_files_profileimg",
                  "kind": "LinkedField",
                  "name": "resources_files_profileimgs",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "fuploads",
                      "kind": "LinkedField",
                      "name": "fuploads",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "file_path",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "file_cdn",
                          "kind": "LinkedField",
                          "name": "file_cdn",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "base_url",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "resources_files_profileimgs(limit:10,order_by:{\"sort_order\":\"asc\"},where:{\"archived\":{\"_eq\":\"0\"},\"image_approved\":{\"_eq\":\"1\"},\"sort_order\":{\"_gt\":-1}})"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "ef668c051db34931914e264b0609f1d7";

export default node;
