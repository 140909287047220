import { useMutation, commitLocalUpdate } from 'react-relay';
import * as Sentry from '@sentry/react';
import { StuTeachersFavoritecreateMutationNew } from '../../../relay/mutations/teachers/favorites/StuTeachersFavoritecreate';
import { StuTeachersFavoriteremoveMutationNew } from '../../../relay/mutations/teachers/favorites/StuTeachersFavoriteremove';
import AppEnvironment from '../../../../relay/AppEnvironment';
import { RelayFavoritedVals } from '../../../relay/resolvers/teachers/relayFavorited';
import { RelayFavoritedMutatedName, RelayFavoritedMutatedVals } from '../../../relay/clientschema/teachersTypes';

/**
 * useFavoriteTeacher
 * 
 * This hook should be used wherever a student wants to add or remove a teacher from their
 * favorites. It determines whether to call "add to favorites" mutation
 * (stu_teachers_favoritecreate) or "remove from favorites" (stu_teachers_favoriteremove)
 * based on the input params you provide. It optimistically updates relayFavoritedMutated,
 * a value on the resources node, which then triggers a relay resolver to update
 * relayFavorited. relayFavorited is the field which we use to display whether a teacher
 * is a favorite or not in the UI, and so this field updates optimistically. If the
 * mutation fails for some reason, note that we are currently *not* showing any error
 * message to the user, because failure of a mutation is very rare.
 * 
 * You'll need to pass three things:
 * 
 *  relayFavorited
 *    The current value of relayFavorited; this is a field on resources. This is used to
 *    determine whether to add the teacher as a favorite, or remove the teacher
 *  tchUuid
 *    The uuid value of the resources node
 *  tchRelayId
 *    The relay id of the resources node
 */
export const useFavoriteTeacher = () => {
  const [commitMutationCreate] = useMutation(StuTeachersFavoritecreateMutationNew);
  const [commitMutationRemove] = useMutation(StuTeachersFavoriteremoveMutationNew);

  const favoriteTeacher = (
    relayFavorited: RelayFavoritedVals,
    tchUuid: string,
    tchRelayId: string,
  ) => {
    // if this teacher is currently a favorite, attempt to remove them from favorites
    if (relayFavorited === RelayFavoritedVals.IsFavorite) {
      // optimistically update relayFavoritedMutated to 0 (no). this will cause a
      // relay resolver to update the value of relayFavorited, which is what we use to
      // display "is this teacher a favorite?" in the UI
      commitLocalUpdate(AppEnvironment, (store) => {
        const teacherRecord = store.get(tchRelayId);
        teacherRecord?.setValue(RelayFavoritedMutatedVals.NotFavorite, RelayFavoritedMutatedName);
      });

      commitMutationRemove({
        variables: {
          teacherUuid: tchUuid
        },
        onCompleted(res: any) {
          // here we check to ensure the value in the db got set correctly, and log if it did not.
          // note that we do NOT roll back our optimistic update of relayFavoritedMutated. why?
          // imagine the user clicks many times very fast on the "favorite" button, so that there
          // are many mutations in flight --- these api calls take different amounts of time, and
          // one of the earlier clicks may resolve after one of the later clicks
          if (res.stu_teachers_favoriteremove.user_favteachers.favorite !== 0) {
            Sentry.captureException(
              new Error('StuTeachersFavoriteremoveMutation did not return expected result'),
              { extra: { response: res } },
            );
          }
        },
        onError(err: any) {
          // note: this erroring will be rare, so we are choosing not to show the user a notif that
          // the mutation failed
          Sentry.captureException(err);
        },
      });

      // else the teacher is not currently a favorite, attempt to add them to favorites
    } else {
      // optimistically update relayFavoritedMutated to 1 (yes). this will cause a
      // relay resolver to update the value of relayFavorited, which is what we use to
      // display "is this teacher a favorite?" in the UI
      commitLocalUpdate(AppEnvironment, (store) => {
        const teacherRecord = store.get(tchRelayId);
        teacherRecord?.setValue(RelayFavoritedMutatedVals.IsFavorite, RelayFavoritedMutatedName);
      });

      commitMutationCreate({
        variables: {
          teacherUuid: tchUuid
        },
        onCompleted(res: any) {
          // here we check to ensure the value in the db got set correctly, and log if it did not.
          // note that we do NOT roll back our optimistic update of relayFavoritedMutated. why?
          // imagine the user clicks many times very fast on the "favorite" button, so that there
          // are many mutations in flight --- these api calls take different amounts of time, and
          // one of the earlier clicks may resolve after one of the later clicks
          if (res.stu_teachers_favoritecreate.user_favteachers.favorite !== 1) {
            Sentry.captureException(
              new Error('StuTeachersFavoritecreateMutation did not return expected result'),
              { extra: { response: res } },
            );
          }
        },
        onError(err: any) {
          // note: this erroring will be rare, so we are choosing not to show the user a notif that
          // the mutation failed
          Sentry.captureException(err);
        },
      });
    }
  };

  return { favoriteTeacher };
};
