import { graphql } from 'babel-plugin-relay/macro';

export const StuApptgroupHandraise = graphql`
  mutation StuApptgroupHandraiseMutation(
    $lessonUuid: String!
    $handVal: Int!
  ) {
    stu_apptgroup_handraise(
      lessonUuid: $lessonUuid
      handVal: $handVal
    ) {
      appt_group_reservations {
        id
        hand_raised
      }
    }
  }
`;
