/**
 * @generated SignedSource<<51f3ce4663af4f82386d21b070a4218e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrivateUpcomingDataLessonsFragment$data = {
  readonly ends_at: any | null;
  readonly id_random: string | null;
  readonly resources: {
    readonly title: string;
  };
  readonly starts_at: any;
  readonly " $fragmentType": "PrivateUpcomingDataLessonsFragment";
};
export type PrivateUpcomingDataLessonsFragment$key = {
  readonly " $data"?: PrivateUpcomingDataLessonsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrivateUpcomingDataLessonsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrivateUpcomingDataLessonsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ends_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "starts_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id_random",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "resources",
      "kind": "LinkedField",
      "name": "resources",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "appointments",
  "abstractKey": null
};

(node as any).hash = "176bb6aa15b98a076d36a9c54a1cd428";

export default node;
