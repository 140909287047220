import React, { useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryLoader, commitMutation } from 'react-relay';
import {
  Alert, ScrollArea, Skeleton,
  Box, Grid, Stack, useTheme
} from '@languageconvo/wcl';
import { Helmet } from 'react-helmet-async';
import AuthEnvironment from '../../relay/AuthEnvironment';
import * as Style from './index.style';
import EmailPreferences from './EmailPreferences';
// mutations...
import { AnonymousAuth } from './relay/AnonymousAuth';
// queries
import { GetEmailPreferences } from './relay/GetEmailPreferences';
import { setStuSecurityToLCStorage } from '../../utils/lcStorage';

export const EmailPrefs = () => {
  const [contentLoading, setContentLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [jwtFail, setJWTFail] = useState(false);
  const theme = useTheme();
  const PrimaryColor = theme.palette.primary.main;
  // This useQueryLoader hook is designed to be used with usePreloadedQuery to implement the
  // "render-as-you-fetch" pattern
  // queryReference: the query reference, or null.
  // loadQuery: a callback that, when executed, will load a query, which will be accessible as
  // queryReference. This queryReference, we are passing in our userpreferences component where
  // usePreloadedQuery is utlizing it and providing us already loaded data.
  const [queryReference, loadQuery] = useQueryLoader(GetEmailPreferences);
  const mutationExecuted = useRef(false);

  // here we attempt to get a jwt for the user visiting this page; we do so using the uuid in a
  // query param /emailprefs?uuid=abc123. if successfull, we use this jwt to make subsequent api
  // calls to mutate this user's email preferences
  useEffect(() => {
    if (!mutationExecuted.current) {
      mutationExecuted.current = true;
      commitMutation(AuthEnvironment, {
        mutation: AnonymousAuth,
        variables: { emailUuid: searchParams.get('uuid') || '' },
        onCompleted: (res: any) => {
          const { anon_auth_emailuuid } = res;
          // adding values to localStorage...
          // relay hooks (i-e mutations, lazyQuery, useQueryLoader and preloadedQuery) auto pick
          // configurations defined in environment file (i-e EmailUnsubEnvironment). This file is
          // not react component so we cannot pass JWT there that's why storing JWT in
          // localstorage to access it in environment file.
          setStuSecurityToLCStorage({ jwt: anon_auth_emailuuid?.jwt });
          // This function is designed to be used with the usePreloadedQuery() hook to implement the
          // "render-as-you-fetch". This will fetch data and data from the query will be written
          // to the store. Read above useQueryLoader comments for better understanding.
          loadQuery({});

          // hide the loading state and show email preferences
          setContentLoading(false);
        },
        onError() {
          setJWTFail(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Email Preferences</title>
      </Helmet>
      <Grid container>
        {/* left side of page, user's preferences */}
        <Style.GridWithNoPadding item xs={12} md={6} lg={5} xl={4}>
          <ScrollArea>
            <>
              <Style.LogoMain>
                <img src={`${process.env.REACT_APP_CDNURL}/common/logo/logo.svg`} data-testid="logo" alt="Logo" height="70" width="256" />
              </Style.LogoMain>
              { contentLoading
                ? (
                  // loading glimmer, as well as error message if user is not found in db
                  <Style.ContentLoader>
                    {jwtFail
                      ? (
                        <Box marginTop="43px">
                          <Alert
                            severity="error"
                          >We couldn&apos;t find your account. Please try clicking on
                            the Email Preferences link in the email again; if you continue
                            to see this message, please let our team know so that we can fix it.
                          </Alert>
                        </Box>
                      )
                      : (
                        <Stack width="inherit">
                          <Skeleton variant="text" height={100} />
                          <Skeleton variant="text" height={100} />
                          <Skeleton variant="rectangular" height={260} />
                          <Skeleton variant="text" height={100} />
                        </Stack>
                      )}

                  </Style.ContentLoader>
                )
                : (
                  // successfully found user, display their email preference options
                  <EmailPreferences
                    queryReference={queryReference || {}}
                    primaryColor={PrimaryColor}
                  />
                )}
            </>
          </ScrollArea>
        </Style.GridWithNoPadding>

        {/* right side of page, background image */}
        <Style.ImageGrid item xs={0} md={6} lg={7} xl={8}>
          <Style.BackgroundDiv />
        </Style.ImageGrid>
      </Grid>
    </>
  );
};
