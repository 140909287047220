import React from 'react';
import { useFragment } from 'react-relay';
import {
  Card, CardContent, Grid2, Ty
} from '@languageconvo/wcl';
import { TeacherProfileFragment } from '../relay/TeacherProfile';
import TeacherProfile from './TeacherProfile';
import { GetStdSettingsDataFragment } from '../relay/GetStdSettingsData';

export type TeacherProfileProps = {
  fragmentRef: any
  tchId: string
  tabToShow: number
  waitingForLoading: boolean
}

const TeacherNode = ({
  fragmentRef,
  tchId,
  tabToShow,
  waitingForLoading
}: TeacherProfileProps) => {
  // const { t } = useTranslation('pageTeachersProfile');
  const data = useFragment(TeacherProfileFragment, fragmentRef);
  // teacher information
  const teacherProfileData = data?.resources_connection?.edges[0];

  const userData: any = useFragment(GetStdSettingsDataFragment, fragmentRef);
  const userInfo = userData?.users_connection?.edges[0].node;

  return (
    teacherProfileData?.node
      ? (
        <TeacherProfile
          teacherProfileData={teacherProfileData.node}
          tchId={tchId}
          tabToShow={tabToShow}
          userInfo={userInfo}
          waitingForLoading={waitingForLoading}
        />
      )
      : (
        <Grid2 xs={12}>
          <Card>
            <CardContent>
              <Ty v="p" align="center" removeMb>Hmm, it looks like something went wrong. Try reloading the page.</Ty>
            </CardContent>
          </Card>
        </Grid2>
      )
  );
};

export default TeacherNode;
