import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { Props } from './interface';

export const CustomDivider = styled(Divider)(({ theme, strength }: WCLThemeProps & Props) => (
  {
    borderStyle: 'solid',

    // light mode
    ...(theme?.themeMode === 'light' && strength === 'thin' && {
      borderColor: theme?.ourTheme.colors.cp.divider.thin,
    }),
    ...(theme?.themeMode === 'light' && strength === 'base' && {
      borderColor: theme?.ourTheme.colors.cp.divider.base,
    }),
    ...(theme?.themeMode === 'light' && strength === 'bold' && {
      borderColor: theme?.ourTheme.colors.cp.divider.bold,
    }),

    // dark mode
    ...(theme?.themeMode === 'dark' && strength === 'thin' && {
      borderColor: theme?.ourTheme.colors.cp.divider.thin,
    }),
    ...(theme?.themeMode === 'dark' && strength === 'base' && {
      borderColor: theme?.ourTheme.colors.cp.divider.base,
    }),
    ...(theme?.themeMode === 'dark' && strength === 'bold' && {
      borderColor: theme?.ourTheme.colors.cp.divider.bold,
    }),
  }
));
