//
// complete color set
//
// here we define *all* colors that can be used in our app. when adding or removing a color here, you should also
// go to /designsystem/colors.stories.mdx and update
const x = {
  app: {
    // appbar/navbar. this is a light blueish/purpleish color
    navbar: '235, 23%, 30%',
    // page background. this is a very dark blueish color, not really related to any other color in our pallete. it just
    // gives good contrast for cards on the page
    page: '228, 40%, 17%',
    // card (main container) on page. this is the same as navbar, but possible we change that in future
    container: '235, 23%, 30%',
  },

  text: {
    // very close to gray, notice how we reduced the saturation a lot. usually for text that denotes diabled
    disabled: '157, 4%, 75%',
    // not quite as light as base
    sub: '157, 7%, 62%',
    // base is very close to white. most users won't be able to tell, but they will notice it feels harmonius. the
    // secret is, throughout the app, only use black and white for the very lightest and darkest needs
    base: '157, 10%, 70%',
    // for links, in dark mode we darken the base color so it stands out against the dark text
    linkPrimary: '157, 22%, 50%',
    linkSecondary: '16, 64%, 42%',
  },

  primary: {
    lightest: '157, 12%, 96%',
    lighter3: '157, 12%, 84%',
    lighter2: '157, 12%, 74%',
    lighter1: '157, 12%, 64%',
    base: '157, 22%, 44%',
    darker1: '157, 22%, 32%',
    darkest: '157, 22%, 14%',
  },

  secondary: {
    lightest: '16, 50%, 95%',
    lighter3: '16, 50%, 85%',
    lighter2: '16, 50%, 75%',
    lighter1: '16, 50%, 65%',
    base: '16, 64%, 42%',
    darker1: '16, 64%, 34%',
    darkest: '16, 64%, 14%',
  },

  info: {
    lightest: '188, 45%, 96%',
    lighter3: '188, 45%, 84%',
    lighter2: '188, 45%, 74%',
    lighter1: '188, 45%, 65%',
    base: '188, 80%, 35%',
    darker1: '188, 80%, 22%',
    darkest: '188, 80%, 12%',
  },

  error: {
    lightest: '343, 35%, 96%',
    lighter3: '343, 35%, 84%',
    lighter2: '343, 35%, 74%',
    lighter1: '343, 35%, 64%',
    base: '343, 60%, 57%',
    darker1: '343, 60%, 30%',
    darkest: '343, 60%, 15%',
  },

  warning: {
    lightest: '50, 55%, 96%',
    lighter3: '50, 55%, 90%',
    lighter2: '50, 55%, 82%',
    lighter1: '50, 55%, 74%',
    base: '50, 70%, 58%',
    darker1: '50, 70%, 28%',
    darkest: '50, 70%, 14%',
  },

  // neturals - RARELY USE!
  // instead of grays, you can often use one of the .lightest/.lighter or .darkest/darker aboves.
  // using one of those is usually better, because they are similar to grays, but give a small, almost
  // imperceptible hint of our app colors
  neutral: {
    // white
    white: '0, 0%, 100%',
    // very light gray
    lightest: '208, 7%, 97%',
    lighter3: '208, 7%, 85%',
    lighter2: '208, 7%, 72%',
    lighter1: '208, 7%, 57%',
    // mid gray
    base: '208, 7%, 46%',
    darker1: '208, 7%, 33%',
    darker2: '208, 7%, 24%',
    darker3: '208, 7%, 18%',
    // very dark gray
    darkest: '208, 7%, 12%',
    // black
    black: '0, 0%, 0%',
  },

  // #region accent colors

  // These colors should be used in *most* places in the app. As an example:
  //  - If an error occurs and you need to notify the user of the error, use the error color
  //  - But if you just want a red color to make something look nice, use accentRed

  accentGreen1: {
    lightest: '157, 12%, 96%',
    lighter3: '157, 12%, 84%',
    lighter2: '157, 12%, 74%',
    lighter1: '157, 12%, 64%',
    base: '157, 22%, 44%',
    darker1: '157, 22%, 32%',
    darkest: '157, 22%, 14%',
  },

  accentPurple1: {
    lightest: '270, 28%, 98%',
    lighter3: '270, 28%, 95%',
    lighter2: '270, 28%, 90%',
    lighter1: '270, 28%, 70%',
    base: '270, 48%, 40%',
    darker1: '270, 32%, 32%',
    darkest: '270, 48%, 10%',
  },

  accentBlue1: {
    lightest: '188, 45%, 96%',
    lighter3: '188, 45%, 84%',
    lighter2: '188, 45%, 74%',
    lighter1: '188, 45%, 65%',
    base: '188, 80%, 35%',
    darker1: '188, 80%, 22%',
    darkest: '188, 80%, 12%',
  },

  accentRed1: {
    lightest: '343, 35%, 96%',
    lighter3: '343, 35%, 84%',
    lighter2: '343, 35%, 74%',
    lighter1: '343, 35%, 64%',
    base: '343, 60%, 57%',
    darker1: '343, 60%, 30%',
    darkest: '343, 60%, 15%',
  },

  accentOrange1: {
    lightest: '16, 50%, 95%',
    lighter3: '16, 50%, 85%',
    lighter2: '16, 50%, 75%',
    lighter1: '16, 50%, 65%',
    base: '16, 64%, 42%',
    darker1: '16, 64%, 34%',
    darkest: '16, 64%, 14%',
  },

  accentYellow1: {
    lightest: '50, 55%, 96%',
    lighter3: '50, 55%, 90%',
    lighter2: '50, 55%, 82%',
    lighter1: '50, 55%, 74%',
    base: '50, 70%, 58%',
    darker1: '50, 70%, 28%',
    darkest: '50, 70%, 14%',
  },
  // #endregion
};

// here we set colors that are shared across multiple components, and we want to ensure they are always the
// same. a good example is divider. divider colors are used in the Divider component, but they're also used
// in the DataGrid component. we want them to all look the same, so we define it here and use these, rather than
// directly using the "raw" colors above.
const cp = {
  divider: {
    thin: `hsla(${x.text.base}, 0.2)`,
    base: `hsla(${x.text.base}, 0.4)`,
    bold: `hsla(${x.text.base}, 0.8)`,
  },
  itemlist: {
    hoverbg: {
      primary: `hsl(${x.primary.darker1})`,
      secondary: `hsl(${x.secondary.darker1})`,
      accentOrange1: `hsla(${x.accentOrange1.darker1}, 70%)`,
    },
  },
  bgInnerCard: {
    accentGreen1: `hsla(${x.accentGreen1.lighter2}, 20%)`,
    accentPurple1: `hsla(${x.accentPurple1.lighter2}, 20%)`,
    accentBlue1: `hsla(${x.accentBlue1.lighter2}, 20%)`,
    accentRed1: `hsla(${x.accentRed1.lighter2}, 20%)`,
    accentOrange1: `hsla(${x.accentOrange1.lighter2}, 20%)`,
    accentYellow1: `hsla(${x.accentYellow1.lighter2}, 20%)`,
  },
};

export const dark = {
  ...x,
  cp: {
    ...cp,
  },
};
