/**
 * @generated SignedSource<<665b34481980f5f20705a441cc877bd0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClassroomBasicDataLessoninfoFragment$data = {
  readonly ends_at: any;
  readonly skills: {
    readonly description_en: string;
    readonly display_order: number;
    readonly skills_levels: {
      readonly display_order: number;
      readonly skills_categories: {
        readonly title_en: string;
      };
      readonly title_en: string;
    };
    readonly title_en: string;
    readonly uuid: any;
  };
  readonly starts_at: any;
  readonly " $fragmentType": "ClassroomBasicDataLessoninfoFragment";
};
export type ClassroomBasicDataLessoninfoFragment$key = {
  readonly " $data"?: ClassroomBasicDataLessoninfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClassroomBasicDataLessoninfoFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display_order",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_en",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClassroomBasicDataLessoninfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "starts_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ends_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "skills",
      "kind": "LinkedField",
      "name": "skills",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description_en",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "skills_levels",
          "kind": "LinkedField",
          "name": "skills_levels",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "main_skills_categories",
              "kind": "LinkedField",
              "name": "skills_categories",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "appt_group",
  "abstractKey": null
};
})();

(node as any).hash = "309f1967540289abf6ff4ea8f9a46e4b";

export default node;
