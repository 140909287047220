/**
 * @generated SignedSource<<12ff030b5dcfa12441c348436dbee2b7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StuSettingsGettrialdataFragment$data = {
  readonly hasCredits: boolean;
  readonly id: string;
  readonly trial: {
    readonly atleastOneTeacherGivesTrials: boolean | null;
    readonly hasMissedTwo: boolean | null;
    readonly hasUpcoming: boolean | null;
    readonly id: string | null;
    readonly pastCompleted: boolean | null;
    readonly pastTrial: {
      readonly id: string | null;
      readonly status: number | null;
    } | null;
    readonly pastTrialExists: boolean | null;
    readonly stepsToSchedule: {
      readonly id: string | null;
      readonly step1: boolean | null;
      readonly step2: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "StuSettingsGettrialdataFragment";
};
export type StuSettingsGettrialdataFragment$key = {
  readonly " $data"?: StuSettingsGettrialdataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"StuSettingsGettrialdataFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StuSettingsGettrialdataFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCredits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StuSettingsGettrialdataOutputDataTrial",
      "kind": "LinkedField",
      "name": "trial",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "atleastOneTeacherGivesTrials",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasMissedTwo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasUpcoming",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pastCompleted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StuSettingsGettrialdataOutputDataTrialPast",
          "kind": "LinkedField",
          "name": "pastTrial",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pastTrialExists",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StuSettingsGettrialdataOutputDataTrialSteps",
          "kind": "LinkedField",
          "name": "stepsToSchedule",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "step1",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "step2",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StuSettingsGettrialdataOutput",
  "abstractKey": null
};
})();

(node as any).hash = "2c1c20639cd5b40a8e8af600ce26a7ba";

export default node;
