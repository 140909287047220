import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { vars } from '../../../utils/vars';
import { CustomCard, AlignTitle } from './index.style';
import { Props } from './interface';
import { CardContent } from '../Card/CardContent';
import { Grid2Ct } from '../Grid2Ct';
import {
  Ty, Ic, Link, IcSvg,
} from '../../datadisplay';

/**
 * Our most common Card component. It has a color, icon, title, etc.
 *
 * See:
 * https://mui.com/material-ui/react-card/
 * https://mui.com/material-ui/api/card/
 */
export const CardStandard: React.FunctionComponent<Props> = (props) => {
  const {
    cp,
    children,
    titleText,
    titleIcon,
    titleIconRight = 1,
    clickableLink1,
    clickableLink2,
    color,
    onClickEntireCard,
    onClickRightIcon,
    titleRemoveMb = false,
    hovercursor = 'none',
  } = props;

  // #region create builtHeader

  // if onClickRightIcon is set, that means the user wants some action to occur when the icon in the top right
  // corner is clicked. here we create the object that will do that
  let onclickRightIconBuilt = {};
  if (onClickRightIcon) {
    onclickRightIconBuilt = {
      onClick: onClickRightIcon,
      hovercursor: 'pointer' as const,
    };
  }

  // the builtHeader section of this component can be a clickable link (either clickableLink1 or clickableLink2)
  // in this region, we build it

  // if clickableLink2 is set, the user can choose to want the url to open in a new tab. here we create the html
  // that accomplishes that
  let clickableLink2Newtab = {};
  if (clickableLink2 && clickableLink2.newtab) {
    clickableLink2Newtab = {
      target: '_blank' as const,
      rel: 'noopener' as const,
    };
  }

  let mbTitle = 1;
  if (titleRemoveMb) {
    mbTitle = 0;
  }

  // icon, title, arrow base setup. if the user does not set clickableLink1 or clickableLink2, this is
  // the final header code
  const baseHeader = (
    <Grid2Ct columns={20}>
      {/* icon, title */}
      <Grid2 xs={18} sx={{ mb: mbTitle }}>
        <AlignTitle>
          { titleIcon
            ? (
              <>
                <IcSvg icon={titleIcon} width={vars.svgIconStandardSize} height={vars.svgIconStandardSize} />
                &nbsp;&nbsp;
              </>
            )
            : null }
          <Ty v="h2New" removeMb>
            {/* if an icon was provided, display an icon */}
            {/* if a title was provided, display a title */}
            { titleText || null }
          </Ty>
        </AlignTitle>
      </Grid2>

      {/* optional arrow to the right, indicating to the user they'll go somewhere if they click */}
      <Grid2 xs={2} display="flex" justifyContent="flex-end" alignItems="flex-start">
        { titleIconRight === 1
          ? <Ic iconName="angle-right" color={color} iconStyle="duotone" size="rel-xl" {...onclickRightIconBuilt} /> : null}
        { titleIconRight === 2
          ? <Ic iconName="seal-question" color={color} iconStyle="duotone" size="rel-xl" {...onclickRightIconBuilt} /> : null}
      </Grid2>
    </Grid2Ct>
  );

  // build the header if clickableLink1 is set
  // if clickableLink1 is set, that means the user wants the header section to be a Link that navigates the
  // user to another page within the react app
  let builtHeader = (
    clickableLink1 ? (
      <Link
        to={clickableLink1.to}
        component={clickableLink1.component}
        linkStyle="nostyle"
      >
        {baseHeader}
      </Link>
    ) : baseHeader
  );

  // build the header if if clickableLink2 is set
  builtHeader = (
    clickableLink2 ? (
      <Link
        href={clickableLink2.href}
        linkStyle="nostyle"
        {...clickableLink2Newtab}
      >
        {baseHeader}
      </Link>
    ) : builtHeader
  );

  // #endregion

  // compose the entire component
  const builtComponent = (
    <CustomCard
      {...cp}
      hovercursor={hovercursor}
      onClick={onClickEntireCard}
    >
      <CardContent>
        {/* icon, title arrow */}
        {builtHeader}

        {/* main content of the card (everything below the top bar) */}
        {children}
      </CardContent>
    </CustomCard>
  );

  return builtComponent;
};
