/**
 * @generated SignedSource<<63aa8f3c9c8b0be014327d700fad4c9c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientonlySchemaHasinteractedFragment$data = {
  readonly groupClass: {
    readonly hasInteracted: boolean | null;
  } | null;
  readonly " $fragmentType": "ClientonlySchemaHasinteractedFragment";
};
export type ClientonlySchemaHasinteractedFragment$key = {
  readonly " $data"?: ClientonlySchemaHasinteractedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientonlySchemaHasinteractedFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientonlySchemaHasinteractedFragment",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GroupClassDetail",
          "kind": "LinkedField",
          "name": "groupClass",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasInteracted",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "appsetting",
  "abstractKey": null
};

(node as any).hash = "6a08fcf19fe42b67ddd5a13ca667220e";

export default node;
