import React from 'react';
import * as Sentry from '@sentry/react';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { IcSvg } from '@languageconvo/wcl';
import { MobileLanglearningDataFragment, MobileLanglearningDataQuery } from '../relay/MobileLanglearningData';
import { MobileLanglearningDataFragment$key } from '../relay/__generated__/MobileLanglearningDataFragment.graphql';
import { sitedata } from '../../../../../../utils/sitedata';

// the flag icon in the top right corner of mobile top bar
export const MobileLanglearning = () => {
  // get the lang_learning value. we *should* already have this value, as it is gotten on pageload,
  // but as always we get it via relay with a query just in case it was garbage collected
  const response: any = useLazyLoadQuery(
    MobileLanglearningDataQuery,
    {}
  );
  const fragmentRef: MobileLanglearningDataFragment$key = response.users_connection.edges[0].node;
  const data = useFragment(MobileLanglearningDataFragment, fragmentRef);
  const studentLangLearnId = data.lang_learning;

  // from the list of all the languages we offer, get icon of the language the student is
  // currently learning
  let langlearnIcon;
  sitedata.languages.forEach((el) => {
    if (el.id === studentLangLearnId) {
      langlearnIcon = el.flagIcon;
    }
  });

  // log if we didn't find an icon, and set the flag to a default
  if (!langlearnIcon) {
    langlearnIcon = sitedata.languages[0].flagIcon;
    Sentry.captureException(
      new Error('IMPORTANT - should never happen. No icon was found in MobileLanglearning'),
      {
        extra: {
          icon: langlearnIcon,
        }
      }
    );
  }

  return (
    <IcSvg icon={langlearnIcon} width="32px" height="32px" />
  );
};
