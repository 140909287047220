/**
 * @generated SignedSource<<f4bc08395a559540da59c826bbcbd1ee>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PretrialDataStusettingsFragment$data = {
  readonly dob: any | null;
  readonly id: string;
  readonly password_set: number;
  readonly pref_teacher_gender: number | null;
  readonly relayAge: ReturnType<typeof usersRelayAgeResolverType> | null;
  readonly timezone_set: number;
  readonly user_settings: {
    readonly homework: any | null;
    readonly id: string;
    readonly interest_group: any | null;
    readonly interest_private: any | null;
    readonly teacher_style: any | null;
  } | null;
  readonly user_timezone: string;
  readonly " $fragmentType": "PretrialDataStusettingsFragment";
};
export type PretrialDataStusettingsFragment$key = {
  readonly " $data"?: PretrialDataStusettingsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PretrialDataStusettingsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PretrialDataStusettingsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pref_teacher_gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "user_timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone_set",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "password_set",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dob",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "fragment": {
        "args": null,
        "kind": "FragmentSpread",
        "name": "relayAgeResolver"
      },
      "kind": "RelayResolver",
      "name": "relayAge",
      "resolverModule": require('./../../../../../../common/relay/resolvers/users/relayAge').relayAge,
      "path": "relayAge"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "user_settings",
      "kind": "LinkedField",
      "name": "user_settings",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interest_group",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interest_private",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "homework",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teacher_style",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "b317f1184ca5a79e11e12c85049433d1";

export default node;
