import React, { Suspense } from 'react';
import {
  Box,
} from '@languageconvo/wcl';
import { DiscoverGetdata } from './components/Discover0Getdata';
import { TeachersDiscoverLoader } from './components/TeachersDiscoverLoader';
import { DiscoverHeader } from './components/Discover1Header';

export const TeachersDiscover = () => (
  <Box data-testid="dicoverTeacherContainer">
    {/* title, text, common questions */}
    <DiscoverHeader />

    {/* list of teachers */}
    <Suspense fallback={<TeachersDiscoverLoader />}>
      <DiscoverGetdata />
    </Suspense>
  </Box>
);
