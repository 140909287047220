import React, {
  useEffect, useState, useMemo,
} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
  Grid2, ScrollArea, Backdrop, LottiePlayer,
} from '@languageconvo/wcl';
import {
  MainContentContainer, MainContentBackground, InnerContentContainer, MaxWidthContainer,
} from './Dashboard.styles';
import { MobileBottomBar } from './header/components/mobilebottombar/MobileBottomBar';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from '../../hooks/useAuth';
import { NavbarContainer } from './navbar/NavbarContainer';
import { DashboardHeader } from './header/Header';
import { generateJwtFromRefreshTokenNew } from '../../utils/generateJwtFromRefreshToken';
import { FavoriteTeacherIframeEventFromLegacy } from '../../events/iframe/favoriteteacher/FavoriteTeacherIframeEventFromLegacy';
import RudderStackAnalyticsComponent from './RudderAnalyticsComponent';
import { handleLogoutEventFromIframe } from '../../events/iframe/IframUtills/handleLogoutFromApp';
import { handleLogout, logoutAllTabs } from '../../utils';
import SessionTimeoutAnimation from '../../common/assets/lottie/birdbye.json';
import { UserPreferredLangSetter } from '../../common/components/global/UserPreferredLangSetter';
import { UserTimeZoneSetter } from '../../common/components/global/UserTimeZoneSetter';
import { readLocalVersionAndDoComparisonWithDBVersion } from '../../common/utils/versionmanager/versionManager';
import { LangModalProvider } from '../../context/LangModalContext';
import { GetGroupClassroomFragment$data } from './relay/__generated__/GetGroupClassroomFragment.graphql';

interface Props {
  fragmentRef: any,
  groupClassroomFragmentRef: GetGroupClassroomFragment$data,
}

/* Lots of app-level functionalities: handling log out events, regenerating jwt on an interval, etc
*/
export const DashboardLayout3 = ({ fragmentRef, groupClassroomFragmentRef } : Props) => {
  const [noScroll, setNoScroll] = useState(false);
  const [open, setOpen] = useState(false);
  const { logoutInProgress, setLogoutInProgress } = useAuth();
  const { isCollapse } = useCollapseDrawer();
  const { pathname } = useLocation();

  // Handle log out and generating new jwt every x minutes
  useEffect(() => {
    /* When a user logs out, they should be logged out in all tabs they have open. 
      We've used broadcase channel APIs and listening to all messages, once from any tab, 
      user do logout, we post a logout message which we can receive here in our logoutAllTabs
      this calls the logout again in all opened tabs and close that channel.  */
    logoutAllTabs(setLogoutInProgress);

    // If user is logged out inside the iframe we will logedout him from the react app as well
    handleLogoutEventFromIframe({ handleLogout, setLogoutInProgress });

    // we attempt to get a new jwt every 6 minutes. reason being, it expires after 15 minutes,
    // so this gives us two tries to get a new one. we also check the current version of the
    // app and reload the page if the user has an outdated version
    const intervalCall = setInterval(async () => {
      // api call to get new jwt
      const result = await generateJwtFromRefreshTokenNew();

      // as long as our api call was successful, check app version and refresh page to get
      // the new version if the user has an outdated version
      if (result !== null) {
        readLocalVersionAndDoComparisonWithDBVersion(result.timeStamp);
      }
    }, 6 * 60 * 1000);
    return () => {
      // clean up, for clearing the interval
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Need to memoize app level fragments otherwise eveytime change occure in navbar will cause 
    outlet context to re-render all places where we have used this app level data fragment ref.
  */
  // eslint-disable-next-line react-hooks/rules-of-hooks 
  const outletContext = useMemo(() => ({
    setNoScroll,
    fragmentRefForUserData: fragmentRef,
    groupClassroomSubscriptionFragmentRef: groupClassroomFragmentRef
  }), [setNoScroll, fragmentRef, groupClassroomFragmentRef]);

  // Inner component
  const mainContent = (
    <MainContentBackground collapseClick={isCollapse} data-top="top" pathname={pathname}>
      <MaxWidthContainer>
        <InnerContentContainer>
          {/* driliing down the AppLevelFragment ref to routes
              whereever, we need AppLevelData, we pick this fragmentRef
              and use this data else do nothing....
          */}
          <Outlet context={outletContext} />
        </InnerContentContainer>
      </MaxWidthContainer>
    </MainContentBackground>
  );

  // App main content
  return (
    <LangModalProvider>
      <Grid2 container>
        {/* capturing iframe event (fav teacher) if user schedule any lesson */}
        <FavoriteTeacherIframeEventFromLegacy />

        {/* Here we are globaly setting user's `default_time_zone` and `preferred_language` which 
            will be later used in whole app,
          */}
        <UserTimeZoneSetter fragmentRef={fragmentRef} />

        {/* TODO: dont think this is needed anymore now that we have user explicitly set
            what lang they are learning */}
        {/* Here we are setting user's preferred language */}
        <UserPreferredLangSetter fragmentRef={fragmentRef} />

        {/* displaying a backdrop in case of logout reset cookie api call is inprogress. */}
        <Backdrop open={logoutInProgress}>
          <LottiePlayer animationSrc={SessionTimeoutAnimation} animationHeight={300} />
        </Backdrop>

        {/* RudderStack component */}
        {fragmentRef !== null && (
        <RudderStackAnalyticsComponent
          fragmentRefForRudderStackData={fragmentRef}
        />
        )}

        {/* header/appbar */}
        <DashboardHeader onOpenSidebar={() => setOpen(true)} fragmentRef={fragmentRef} />

        {/* sidebar, main content, bottom bar on mobile */}
        <Grid2 display="flex" width="100%">
          {/* on desktop, sidebar/drawer */}
          <NavbarContainer isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

          {/* main content */}
          {/* added pathname so that in the group classroom, we can change the height of
        the container to be larger since we don't show the bottom bar */}
          <MainContentContainer pathname={pathname}>
            {noScroll ? mainContent : (
              <ScrollArea>
                {mainContent}
              </ScrollArea>
            )}
          </MainContentContainer>

          {/* on mobile, fixed bottom navbar */}
          <MobileBottomBar />
        </Grid2>
      </Grid2>
    </LangModalProvider>
  );
};
