/**
 * @generated SignedSource<<bcbf838dac9f8ebd72f162025861eb29>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TrialLessonInfoFragment$data = {
  readonly ends_at: any | null;
  readonly id_random: string | null;
  readonly starts_at: any;
  readonly " $fragmentType": "TrialLessonInfoFragment";
};
export type TrialLessonInfoFragment$key = {
  readonly " $data"?: TrialLessonInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TrialLessonInfoFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrialLessonInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ends_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "starts_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id_random",
      "storageKey": null
    }
  ],
  "type": "appointments",
  "abstractKey": null
};

(node as any).hash = "5dd2035cc4542a5fcd0368aa6138ce1b";

export default node;
