import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { Props } from './interface';

export const CustomTypography = styled(
  Typography,
  {
    // second argument to the styled function is an options object, one of the things it accepts is
    // shouldForwardProp, which as the docs say, "Indicates whether the prop should be forwarded to
    // the Component". So to remove the unknown prop warning from the console, we tell it not to pass
    // our custom prop to the DOM element with shouldForwardProp: (prop) => prop !== "primary"
    shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'removeMb',
  },
)(({ theme, v, removeMb }: WCLThemeProps & Props) => (
  {
    // these apply to all variants
    fontFamily: theme?.ourTheme.typography.fontFamily,

    ...(v === 'p' && {
      fontWeight: theme?.ourTheme.typography.p.fontWeight,
      color: `hsl(${theme?.ourTheme.colors.text.base})`,
      marginBottom: '0.5em',
      // on xs and sm (small screens), we increase the font size to make it more readable
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem`,
      },
    }),

    ...(v === 'h1' && {
      fontWeight: theme?.ourTheme.typography.h1.fontWeight,
      color: `hsl(${theme?.ourTheme.colors.text.base})`,
      marginBottom: '0.35em',
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.h1.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.h1.fontSizeRemSmallscrn}rem`,
      },
    }),

    ...(v === 'h1New' && {
      fontWeight: theme?.ourTheme.typography.h1New.fontWeight,
      color: `hsl(${theme?.ourTheme.colors.text.base})`,
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.h1New.fontSizeRem}rem`,
        marginBottom: '0.35em',
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.h1New.fontSizeRemSmallscrn}rem`,
        lineHeight: '2.2rem',
        marginBottom: '0.2em',
      },
    }),

    ...(v === 'h2' && {
      fontWeight: theme?.ourTheme.typography.h2.fontWeight,
      color: `hsl(${theme?.ourTheme.colors.text.base})`,
      marginBottom: '0.35em',
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.h2.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.h2.fontSizeRemSmallscrn}rem`,
      },
    }),

    ...(v === 'h2New' && {
      fontWeight: theme?.ourTheme.typography.h2New.fontWeight,
      color: `hsl(${theme?.ourTheme.colors.text.base})`,
      marginBottom: '0.35em',
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.h2New.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.h2New.fontSizeRemSmallscrn}rem`,
      },
    }),

    ...(v === 'subheading' && {
      fontWeight: theme?.ourTheme.typography.subheading.fontWeight,
      color: `hsl(${theme?.ourTheme.colors.text.base})`,
      marginBottom: '0.35em',
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.subheading.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.subheading.fontSizeRemSmallscrn}rem`,
      },
    }),

    ...(v === 'small' && {
      fontWeight: theme?.ourTheme.typography.small.fontWeight,
      color: `hsl(${theme?.ourTheme.colors.text.base})`,
      marginBottom: '0.35em',
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.small.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.small.fontSizeRemSmallscrn}rem`,
      },
    }),

    // remove bottom margin, if removeMb is true
    ...(removeMb === true && {
      marginBottom: '0em !important',
    }),
  }
));
