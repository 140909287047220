/**
 * @generated SignedSource<<9f4f0d250763af932356620e20f50f78>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeacherProfileQuery$variables = {
  tchId?: number | null;
};
export type TeacherProfileQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GetStdSettingsDataFragment" | "TeacherProfileFragment">;
};
export type TeacherProfileQuery = {
  response: TeacherProfileQuery$data;
  variables: TeacherProfileQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tchId"
  }
],
v1 = {
  "_eq": "1"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "user_favteachers",
  "kind": "LinkedField",
  "name": "user_favteachers",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favorite",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dob",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeacherProfileQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "tchId",
            "variableName": "tchId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "TeacherProfileFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "GetStdSettingsDataFragment"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeacherProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "tchId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "pk"
              },
              {
                "kind": "Literal",
                "name": "tutor_visibility",
                "value": (v1/*: any*/)
              },
              {
                "kind": "Literal",
                "name": "working_status",
                "value": {
                  "_is_null": true
                }
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "resourcesConnection",
        "kind": "LinkedField",
        "name": "resources_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resourcesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "resources",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gets_new_students",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "wants_new_students",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "provides_free_trial",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pk",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stuprof_aboutme",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stuprof_city",
                    "storageKey": null
                  },
                  {
                    "name": "relayFavorited",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        (v3/*: any*/),
                        {
                          "kind": "ClientExtension",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "relayFavoritedMutated",
                              "storageKey": null
                            }
                          ]
                        }
                      ],
                      "type": "resources",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": false
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "countries",
                    "kind": "LinkedField",
                    "name": "stuprof_country",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "common_name",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "instructor_languages",
                    "kind": "LinkedField",
                    "name": "instructor_languages",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "language_id",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "limit",
                        "value": 10
                      },
                      {
                        "kind": "Literal",
                        "name": "order_by",
                        "value": {
                          "sort_order": "asc"
                        }
                      },
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "archived": {
                            "_eq": "0"
                          },
                          "image_approved": (v1/*: any*/),
                          "sort_order": {
                            "_gt": -1
                          }
                        }
                      }
                    ],
                    "concreteType": "resources_files_profileimg",
                    "kind": "LinkedField",
                    "name": "resources_files_profileimgs",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "fuploads",
                        "kind": "LinkedField",
                        "name": "fuploads",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "file_path",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "file_cdn",
                            "kind": "LinkedField",
                            "name": "file_cdn",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "base_url",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": "resources_files_profileimgs(limit:10,order_by:{\"sort_order\":\"asc\"},where:{\"archived\":{\"_eq\":\"0\"},\"image_approved\":{\"_eq\":\"1\"},\"sort_order\":{\"_gt\":-1}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "user_timezone",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "password_set",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timezone_set",
                    "storageKey": null
                  },
                  {
                    "name": "relayAge",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        (v4/*: any*/)
                      ],
                      "type": "users",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": false
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b4a030701fa4d2578217581ec0e0863b",
    "id": null,
    "metadata": {},
    "name": "TeacherProfileQuery",
    "operationKind": "query",
    "text": "query TeacherProfileQuery(\n  $tchId: Int\n) {\n  ...TeacherProfileFragment_4NzE9\n  ...GetStdSettingsDataFragment\n}\n\nfragment GetStdSettingsDataFragment on query_root {\n  users_connection {\n    edges {\n      node {\n        user_timezone\n        dob\n        password_set\n        timezone_set\n        ...relayAgeResolver\n        id\n      }\n    }\n  }\n}\n\nfragment TeacherProfileFragment_4NzE9 on query_root {\n  resources_connection(where: {pk: {_eq: $tchId}, working_status: {_is_null: true}, tutor_visibility: {_eq: \"1\"}}) {\n    edges {\n      node {\n        id\n        gets_new_students\n        wants_new_students\n        provides_free_trial\n        pk\n        uuid\n        title\n        stuprof_aboutme\n        stuprof_city\n        ...relayFavoritedResolver\n        user_favteachers {\n          favorite\n          id\n        }\n        stuprof_country {\n          common_name\n          id\n        }\n        instructor_languages {\n          language_id\n          id\n        }\n        resources_files_profileimgs(limit: 10, order_by: {sort_order: asc}, where: {image_approved: {_eq: \"1\"}, archived: {_eq: \"0\"}, sort_order: {_gt: -1}}) {\n          fuploads {\n            file_path\n            file_cdn {\n              base_url\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment relayAgeResolver on users {\n  dob\n}\n\nfragment relayFavoritedResolver on resources {\n  user_favteachers {\n    favorite\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1356c75546fb25c3f7f95c7c771b849";

export default node;
