import React from 'react';
import {
  Ty, Button, Grid2Ct, Grid2, Box, Ic, LottiePlayer, Card, CardContent,
} from '@languageconvo/wcl';
import PandaDeveloper from '../../../../../common/assets/lottie/pandadeveloper.json';

// TODO: add this erorr to IgnoreErrors, so that it is not logged to sentry. Also rename this
//  and the file to something like GrpclasspageErrors (see private lsn pay page for example)
export enum CustomErrBoundaries {
  UserEnteredTwice = 'UserEnteredTwice'
}

/* Custom error boundary for two main reasons:
    1. Our regular page-level error boundary has a button to go to the home page. While in
        a class, we don't want to show that. Here we just show a "refresh" button, that's it

    2. So that we can display specific errors when we throw, which might come in handy so we
        can tell the user what they need to fix.
        For example, you can: throw new Error('test);
        Then below, add an if statement with the text 'test' and that will allow you to
        display a specific message
*/
export const CustomErrBoundary = ({ err }: any) => {
  const sentryErrorPrepend = 'Error: ';

  if (err.toString() === `${sentryErrorPrepend}${CustomErrBoundaries.UserEnteredTwice}`) {
    return (
      <ErrContainer>
        <Ty align="center">
          It looks like you accidentally entered this class in two different browser
          tabs. Click the refresh button to reconnect here.
        </Ty>
      </ErrContainer>
    );
  }

  // general error, will be shown if one of the above specific errors does not hit
  return (
    <ErrContainer>
      <Ty align="center">
        We notified Patrick our Software Developer Panda. Please try reloading the
        page, that should fix things. If you continue seeing
        this error though <strong>please</strong> let us know so that we can get it fixed.
      </Ty>
    </ErrContainer>
  );
};

// container for all errors
const ErrContainer = ({ children }: any) => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <Card>
        <CardContent>
          <Grid2Ct>
            {/* text, buttons. flex here for equal height on xl */}
            <Grid2 xs={12} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              {/* this flex vertically aligns the content */}
              <Grid2Ct sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  {/* text - header */}
                  <Grid2 xs={12}>
                    <Ty v="h1New" align="center"><Ic color="accentRed1" iconStyle="duotone" iconName="traffic-cone" />&nbsp;&nbsp;Oops - An Error Occurred</Ty>
                  </Grid2>

                  {/* text */}
                  <Grid2 xs={12} sm={8} smOffset={2} lg={6} lgOffset={3} xl={10} xlOffset={1}>
                    {children}
                  </Grid2>

                  {/* button refresh the page */}
                  <Grid2 xs={12}>
                    <Box display="flex" justifyContent="center">
                      <Button
                        color="accentRed1"
                        size="large"
                        onClick={() => window.location.reload()}
                      >
                        Refresh the Page
                      </Button>
                    </Box>
                  </Grid2>
                </Box>
              </Grid2Ct>
            </Grid2>

            {/* lottie animation. flex here for equal height on xl */}
            <Grid2 xs={12} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Grid2Ct>
                {/* lottie panda (sm and above) */}
                <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <LottiePlayer
                      animationSrc={PandaDeveloper}
                      animationHeight={450}
                    />
                  </Box>
                </Grid2>
                {/* lottie panda (xs) */}
                <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <LottiePlayer
                      animationSrc={PandaDeveloper}
                      animationHeight={300}
                    />
                  </Box>
                </Grid2>
              </Grid2Ct>
            </Grid2>
          </Grid2Ct>
        </CardContent>
      </Card>
    </Grid2>
  </Grid2Ct>
);
