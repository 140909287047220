import * as yup from 'yup';
import { tz } from '../../../../utils/Timezones';
import { HrCycle } from '../../../../common/utils/DatesTimes/HourCycle';

const timeZonesNames = tz.map((a) => a.nameDb);

// we get hour cycle as an array from Enum because yup .onOf method used to validate
// this expects hour cycle to be an array
const hrCycleArray: string[] = Object.values(HrCycle);

export const userBasicSettingsFields = yup.object({
  timezone: yup.string().trim().required('fieldRequired').oneOf(timeZonesNames),
  age: yup.string().required('fieldRequired').matches(
    /^(100|101|102|103|104|105|106|107|108|109|[1-9][0-9]?|110)$/,
    'notValidAge',
  ),
  ageBackend: yup.number().min(1).max(110),
  firstName: yup.string().required('fieldRequired').test(
    'is-empty-or-valid-length',
    'notValidFirstName',
    (value) => (value.trim().length >= 1 && value.trim().length <= 60),
  ),
  lastName: yup.string().required('fieldRequired').test(
    'is-empty-or-valid-length',
    'notValidLastName',
    (value) => (value.trim().length >= 1 && value.trim().length <= 60),
  ),
  email: yup.string().required('fieldRequired').test(
    'is-empty-or-valid-length',
    'notValidEmail',
    (value) => (value.trim().length >= 3 && value.trim().length <= 200),
  ),
  countryIdNum: yup.string().required('fieldRequired'),
  countryIdNumBackend: yup.number().min(1),
  hourCycle: yup.string().trim().required('fieldRequired').oneOf(hrCycleArray),
  password: yup.string().test(
    'is-empty-or-valid-length',
    'notValidPassword',
    (value) => !value || (value.trim().length >= 5 && value.trim().length <= 50),
  ),
  passwordBackend: yup.string().trim().min(5).max(50),
});

export type userSettingsPassCodeProps = yup.InferType<typeof userBasicSettingsFields>

export const userGroupSettingsFields = yup.object({
  groupName: yup.string().required('fieldRequired').test(
    'is-empty-or-valid-length',
    'notValidGroupName',
    (value) => (value.trim().length >= 6 && value.trim().length <= 60)
  ),
  groupPassword: yup.string().required('fieldRequired').test(
    'is-empty-or-valid-length',
    'notValidGroupPassword',
    (value) => (value.trim().length >= 6 && value.trim().length <= 60)
  )
});

export type userSettingsGroupPassCodeProps = yup.InferType<typeof userGroupSettingsFields>
