/**
 * @generated SignedSource<<3e56f66758e67397417e1dbacf8140b7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavbarDataLanglearnFragment$data = {
  readonly lang_learning: number;
  readonly " $fragmentType": "NavbarDataLanglearnFragment";
};
export type NavbarDataLanglearnFragment$key = {
  readonly " $data"?: NavbarDataLanglearnFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavbarDataLanglearnFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavbarDataLanglearnFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lang_learning",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "8656196ef6d0e982ee15851ee13f9270";

export default node;
