/* eslint-disable */
import React, { useState, useContext, Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { useClientQuery, useFragment } from 'react-relay';
import {
  CardSmallColor, Grid2, Grid2Ct, Box, useTheme, useMediaQuery, Dialog,
  IcSvgList,
  Ty,
  Ic,
} from '@languageconvo/wcl';
import { MicModal } from './modals/MicModal';
import { ClassroomSetupContext } from '../../GroupClassroom';
import { LessonInfo1GetData } from './modals/lessoninfo/LessonInfo1GetData';
import { MicDetailsFragment, MicDetailsQuery } from '../../../../../common/relay/queries/MicDetails';
import { MicDetailsFragment$key } from '../../../../../common/relay/queries/__generated__/MicDetailsFragment.graphql';

// footer - mic and lesson info buttons
export const Footer = () => {
  // #region general

  // on xs screens, text for the boxes needs to be really small
  const theme = useTheme();
  const screensizeXs = useMediaQuery(theme.breakpoints.down('sm'));

  // reading mic details from relay
  const response: any = useClientQuery(MicDetailsQuery, {});
  const fragmentR: MicDetailsFragment$key = response.RelayAppSettings;
  const data = useFragment(MicDetailsFragment, fragmentR);

  // we use our "isFooterReady" context to not allow mic and info modals to be clickable
  // until csSetupContext.isFooterReady is true. That should indicate user has allowed
  // access to their devices and we've gotten a list of all their devices
  const csSetupContext = useContext(ClassroomSetupContext);

  // #endregion

  // #region mic small card display

  /* Here we determine:
      micName: this is the text we'll display in the mic card
      micAlign: whether to left or center align the text in the mic card
      micFooterBgColor: the bg color of the mic card (red or white)
      micIcon: the icon in the mic card (a microphone, or an X)
  */

  // defaults
  let micName = '';
  let micFooterBgColor: any = 'cardstandard' as const; // white bg color
  let micIcon: any = IcSvgList.microphone2; // microphone icon
  // alignment of text within the card. to avoid the component re-rendering and causing the
  // component to flash in normal use cases (when there is a non-muted mic available)
  // we must set the correct alignment here at the initial render. then once isFooterReady,
  // the component won't flash on rerender
  let micAlign: any = { align: 'left' as const };
  if (screensizeXs) {
    micAlign = { };
  }

  // only once the footer is ready (once the user has given device permissions) can we determine
  // what title/color/icon etc should be set. until then, we display the above defaults
  if (csSetupContext.isFooterReady) {
    // no mic found on the user's device, so we need to show a red error and X icon
    if (!data.microphone.current.micId) {
      micAlign = { }; // center the text
      micName = 'No Mic Found!';
      if (screensizeXs) {
        micName = 'Mic Error!';
      }
      micFooterBgColor = 'accentRed1' as const;
      micIcon = IcSvgList.cancel1;

      // mic is muted. show red error and X icon
    } else if (data.microphone.current.isMuted) {
      micAlign = { }; // center the text
      micName = 'Mic Muted!';
      if (screensizeXs) {
        micName = 'Muted!';
      }
      micFooterBgColor = 'accentRed1' as const;
      micIcon = IcSvgList.cancel1;

      // a mic exists, and its not muted!
    } else if (data.microphone.current?.micId) {
      // on large screens, we display the name of the mic left aligned so that the user can see as
      // much of the name of their mic as possible
      micAlign = { align: 'left' as const };
      // it's very remotely possible mic title is null (although our get devices function *should*
      // not allow that), we have this if just to be sure we don't get an error if it's null
      if (data.microphone.current?.title) {
        micName = data.microphone.current.title;
      }

      // on small screens we don't have room for the name of the mic so we just show "Mic" centered
      if (screensizeXs) {
        micName = 'Mic';
        micAlign = { }; // center the text
      }

      micFooterBgColor = 'cardstandard' as const;
      micIcon = IcSvgList.microphone2;
    }
  }

  // #endregion

  // #region class info card display

  // class info
  let infoText = 'Class Information';
  if (screensizeXs) {
    infoText = 'Info';
  }

  // if footer isn't enabled yet. we'll show blank text (and also disable clicking) so the user
  // knows the mic and class info data is not ready yet
  if (!csSetupContext.isFooterReady) {
    micName = '';
    infoText = '';
  }

  // #endregion

  // #region modals 

  // open/close mic popup
  // modal state. true=open, false=closed. closed by default
  const [modalStateMic, setModalStateMic] = useState<boolean>(false);
  const handleModalOpenMic = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStateMic(true);
  };
  const handleModalCloseMic = () => {
    setModalStateMic(false);
  };

  // lesson info modal
  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalState(true);
  };
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  // footer component
  return (
    <>
      {/* mic and lesson info small cards */}
      <Grid2Ct>
        {/* microphone */}
        <Grid2 xs={6}>
          {/* on md and up, we set a fixed width. and use flex to align correctly */}
          <Box
            sx={{
              display: {
                xs: 'block',
                md: 'flex',
              },
              justifyContent: {
                md: 'flex-end'
              }
            }}
          >
            <CardSmallColor
              {...micAlign}
              smallerfont
              icon={micIcon}
              text={micName}
              color={micFooterBgColor}
              hovercursor="pointer"
              // don't allow clicking until footer data is ready
              onClick={csSetupContext.isFooterReady ? handleModalOpenMic : undefined}
              cp={{ sx: { width: { md: '300px' } } }}
            />
          </Box>
        </Grid2>

        {/* lesson information */}
        <Grid2 xs={6}>
          {/* on md and up, we set a fixed width. and use flex to align correctly */}
          <Box
            sx={{
              display: {
                xs: 'block',
                md: 'flex',
              },
              justifyContent: {
                md: 'flex-start'
              }
            }}
          >
            <CardSmallColor
              smallerfont
              icon="knowledge2"
              text={infoText}
              color="cardstandard"
              hovercursor="pointer"
              // don't allow clicking until footer data is ready
              onClick={csSetupContext.isFooterReady ? handleModalOpen : undefined}
              cp={{ sx: { width: { md: '300px' } }, 'data-intercom': 'groupclassroominfo' }}
            />
          </Box>
        </Grid2>
      </Grid2Ct>

      {/* microphone modal */}
      <Dialog
        isOpen={modalStateMic}
        onClose={handleModalCloseMic}
        width="xs"
        color="accentPurple1"
      >
        <MicModal isOpen={modalStateMic} handleModalCloseMic={handleModalCloseMic} />
      </Dialog>

      {/* lesson info modal */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="sm"
        color="accentPurple1"
      >
        <Sentry.ErrorBoundary fallback={<Error />}>
          <Suspense>
            <LessonInfo1GetData />
          </Suspense>
        </Sentry.ErrorBoundary>
      </Dialog>
    </>
  );
};

// error for lesson info button
const Error = () => (

  <Grid2 xs={12}>
    <Grid2Ct sx={{ height: '100%' }}>
      <Grid2
        xs={12}
        sm={10}
        smOffset={1}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {/* TODO: John can update the error message. */}
        <Ty removeMb align="center">
          <Ic iconName="triangle-exclamation" color="accentOrange1" iconStyle="duotone" />&nbsp;&nbsp;Hmm, something
          went wrong. Please reload the page, and if you continue seeing this error *please*
          let us know!
        </Ty>
      </Grid2>
    </Grid2Ct>

  </Grid2>
);
