import * as React from 'react';
import { ScrollbarProps } from './interface';
import {
  ScrollArea as ScrollAreaRoot,
  ScrollAreaViewport,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaCorner,
} from './index.style';

/**
 * Horizontal, Transparent Scroll Area. Wrap any component within our app in this component and that will give it a
 * horizontal, invisible scrollbar. Used for things like mobile navbar horizontal scrolling.
 *
 * See: https://www.radix-ui.com/docs/primitives/components/scroll-area
 */

export const ScrollareaHorizontalNav: React.FunctionComponent<ScrollbarProps> = (props) => {
  const {
    children,
    cp,
  } = props;
  return (
    <ScrollAreaRoot {...cp}>
      <ScrollAreaViewport>
        {children}
      </ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="horizontal">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
      <ScrollAreaCorner />
    </ScrollAreaRoot>
  );
};
