import React from 'react';
import {
  Dialog, Ty, Grid2Ct, Grid2,
} from '@languageconvo/wcl';

export const OrdersHistoryTableModalNotes = (props :any) => {
  const { anchorEl, handleClose, noteToDisplay } = props;
  const open = Boolean(anchorEl);

  return (
    <Dialog
      isOpen={open}
      onClose={handleClose}
      width="xs"
      color="accentBlue1"
    >
      <Grid2Ct sx={{ mt: 1 }}>
        <Grid2 xs={12} sm={10} smOffset={1}>
          {noteToDisplay === 'admincreated' && <Ty align="center">This was a free credit provided by our customer service team!</Ty>}
          {noteToDisplay === 'gift' && <Ty align="center">This was a gift you received!</Ty>}
          {noteToDisplay === 'refunded' && <Ty align="center">This purchase was refunded.</Ty>}
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};
