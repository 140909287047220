import { graphql } from 'babel-plugin-relay/macro';
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import { RelayFavoritedMutatedVals } from '../../clientschema/teachersTypes';

// relayFavorited has these two possible values
export enum RelayFavoritedVals {
  IsFavorite = 1,
  NotFavorite = 0,
}

/**
 * @RelayResolver
 * @onType resources
 * @fieldName relayFavorited
 * @rootFragment relayFavoritedResolver
 */
export function relayFavorited(resourcesKey: any): RelayFavoritedVals {
  const data = readFragment(
    graphql`
      fragment relayFavoritedResolver on resources {
        relayFavoritedMutated
        user_favteachers {
          favorite
        }
      }
    `,
    resourcesKey
  );

  // default "not favorited"
  let favorited = RelayFavoritedVals.NotFavorite;

  // if the student has added/removed the teacher as a favorite during this session,
  // relayFavoritedMutated will have a value (not be undefined). In which case we use
  // that value because it is optimsitically updated
  if (data.relayFavoritedMutated !== undefined
    && data.relayFavoritedMutated === RelayFavoritedMutatedVals.IsFavorite) {
    favorited = RelayFavoritedVals.IsFavorite;
  } else if (data.relayFavoritedMutated !== undefined
    && data.relayFavoritedMutated === RelayFavoritedMutatedVals.NotFavorite) {
    favorited = RelayFavoritedVals.NotFavorite;

    // else the student has not added/removed this teacher as a favorite during this session, so
    // we use the db value. which is the row from user_favteachers...remember that a row in that
    // table will only exist if the user has at least once added the teacher as a favorite, so
    // we definitely need to use ?.favorite heres
  } else if (data.user_favteachers[0]?.favorite === 1) {
    favorited = RelayFavoritedVals.IsFavorite;
  }
  return favorited;
}
