/**
 * @generated SignedSource<<4c61a22702b2a856df0a08c2060d0b3b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetGroupClassroomSubscription$variables = {};
export type GetGroupClassroomSubscription$data = {
  readonly subs_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"GetGroupClassroomFragment">;
      };
    }>;
  };
};
export type GetGroupClassroomSubscription = {
  response: GetGroupClassroomSubscription$data;
  variables: GetGroupClassroomSubscription$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetGroupClassroomSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "subsConnection",
        "kind": "LinkedField",
        "name": "subs_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subs",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "GetGroupClassroomFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetGroupClassroomSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "subsConnection",
        "kind": "LinkedField",
        "name": "subs_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subs",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "groupcl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c7a9fad6cc3f895691023f79f7a56ac",
    "id": null,
    "metadata": {},
    "name": "GetGroupClassroomSubscription",
    "operationKind": "subscription",
    "text": "subscription GetGroupClassroomSubscription {\n  subs_connection {\n    edges {\n      node {\n        ...GetGroupClassroomFragment\n        id\n      }\n    }\n  }\n}\n\nfragment GetGroupClassroomFragment on subs {\n  groupcl\n}\n"
  }
};

(node as any).hash = "31fb938dd9c254f9339b738d89f63376";

export default node;
