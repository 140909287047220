import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Ic, Link, Button,
} from '@languageconvo/wcl';
import { useFragment } from 'react-relay';
import { BackbuttonDesktopDataFragment } from './relay/BackbuttonDesktopData';
import { BackbuttonDesktopDataFragment$key } from './relay/__generated__/BackbuttonDesktopDataFragment.graphql';
import { BackbuttonDesktopContainer } from './BackbuttonDesktop.style';

interface Props {
  backbtn: BackbuttonDesktopDataFragment$key
}

// back button in the top bar. conditionally rendeder; if the user is on a main page, no
// button is rendered. if they're on a subpage, button will be rendered
export const BackbuttonDesktop = ({ backbtn }: Props) => {
  const backButtonData = useFragment(BackbuttonDesktopDataFragment, backbtn);

  let displayButton = false;
  let linkTo = '';
  if (backButtonData?.backbutton) {
    displayButton = true;
    linkTo = backButtonData.backbutton;
  }

  return (
    <BackbuttonDesktopContainer shouldDisplay={displayButton}>
      <Link
        to={linkTo}
        component={NavLink}
        linkStyle="nostyle"
      >
        <Button size="small" color="accentPurple1"><Ic iconName="arrow-left" color="accentPurple1Dark" />&nbsp;&nbsp;back&nbsp;</Button>
      </Link>
    </BackbuttonDesktopContainer>
  );
};
