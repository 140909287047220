import { BordersTypes } from './interface';

export const borders: BordersTypes = {
  borderRadius: {
    small: 4,
    // for images, we want a slightly smaller radius than we have for most components
    images: 8,
    // this is the radius used by most of our components: Card, DataGrid, etc.
    medium: 11,
    large: 16,
  },
  mainBorder: {
    // this is the border used by most of our components: Card, etc.
    border: 'none',
    // popovers need a border so that the can stand out from other elements
    popoverStyle: 'solid',
    popoverWidth: '1px',
  },
};
