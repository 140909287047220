import React from 'react';
import {
  Card, Skeleton, Stack
} from '@languageconvo/wcl';

export const TableLoader = () => (
  <Card>
    <Stack spacing={1} sx={{ width: '100%', padding: '24px' }}>
      <Skeleton width="100%" height={30} variant="rectangular" />
      <br />
      <Skeleton width="100%" height={200} variant="rectangular" />
      <br />
      <Skeleton width="100%" height={30} variant="rectangular" />
    </Stack>
  </Card>
);
