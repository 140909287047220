import { graphql } from 'babel-plugin-relay/macro';

export const LangLearningDialogQuery = graphql`
  query LangLearningDialogQuery {
    users_connection {
      edges {
        node {
          ...LangLearningDialogFragment
        }
      }
    }
  }
`;

export const LangLearningDialogFragment = graphql`
  fragment LangLearningDialogFragment on users {
    # note: id is needed for optimistically mutating lang_learning
    id
    lang_learning
  }
`;
