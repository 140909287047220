/**
 * @generated SignedSource<<3eb790ff1677fc62349709f8112ffca8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HoursReaminingMutation$variables = {};
export type HoursReaminingMutation$data = {
  readonly stu_orders_totalhrsremaining: {
    readonly data: ReadonlyArray<{
      readonly langId: number;
      readonly maxExpiry: any;
      readonly totalHours: number;
    } | null>;
  } | null;
};
export type HoursReaminingMutation = {
  response: HoursReaminingMutation$data;
  variables: HoursReaminingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StuOrdersTotalhrsremainingOutput",
    "kind": "LinkedField",
    "name": "stu_orders_totalhrsremaining",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StuOrdersTotalhrsremainingOutputtData",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "langId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalHours",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxExpiry",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HoursReaminingMutation",
    "selections": (v0/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HoursReaminingMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1c4a3b1ffe69e8b77f4fa0994a8d916d",
    "id": null,
    "metadata": {},
    "name": "HoursReaminingMutation",
    "operationKind": "mutation",
    "text": "mutation HoursReaminingMutation {\n  stu_orders_totalhrsremaining {\n    data {\n      langId\n      totalHours\n      maxExpiry\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f200ebc9255513493e2c2789a993c0e";

export default node;
