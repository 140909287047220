import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Grid2Ct, CardStandard, Ty, Link, CardSmallColor, IcSvgList,
} from '@languageconvo/wcl';
import { FullwidthCol, LeftCol } from '../Common';
import { sitedata } from '../../../../../utils/sitedata';

export const EmailPrefs = (emailUuid: any) => {
  const emailUrl = `${sitedata.url.app.moreEmailprefs}${emailUuid.uuid}`;
  return (
    <CardStandard
      titleText="Email Preferences"
      titleIcon={IcSvgList.mail1}
      color="accentRed1"
      titleIconRight={0}
    >
      <Grid2Ct>
        <FullwidthCol>
          <Ty removeMb>Click here to open the email preferences page in a new tab:</Ty>
        </FullwidthCol>
        <LeftCol>
          <Link
            to={emailUrl}
            component={NavLink}
            linkStyle="nostyle"
            target="_blank"
            rel="noopener"
          >
            <CardSmallColor
              text="Edit Preferences"
              icon={IcSvgList.mailheart1}
              color="accentRed1"
              showArrow
              hovercursor="pointer"
            />
          </Link>
        </LeftCol>
      </Grid2Ct>
    </CardStandard>
  );
};
