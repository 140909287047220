/**
 * @generated SignedSource<<fc79df5e2d9d54589c236621549dd697>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeachersDiscoverDataQuery$variables = {};
export type TeachersDiscoverDataQuery$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"TeachersDiscoverDataLanglearnFragment">;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"TeachersDiscoverDataFragment">;
};
export type TeachersDiscoverDataQuery = {
  response: TeachersDiscoverDataQuery$data;
  variables: TeachersDiscoverDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "_eq": "1"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TeachersDiscoverDataQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TeachersDiscoverDataFragment"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TeachersDiscoverDataLanglearnFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TeachersDiscoverDataQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "tutor_visibility": (v0/*: any*/),
              "working_status": {
                "_is_null": true
              }
            }
          }
        ],
        "concreteType": "resourcesConnection",
        "kind": "LinkedField",
        "name": "resources_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "resourcesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "resources",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pk",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gets_new_students",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "wants_new_students",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "provides_free_trial",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stuprof_aboutme",
                    "storageKey": null
                  },
                  {
                    "name": "relayFavorited",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "user_favteachers",
                          "kind": "LinkedField",
                          "name": "user_favteachers",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "favorite",
                              "storageKey": null
                            },
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "kind": "ClientExtension",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "relayFavoritedMutated",
                              "storageKey": null
                            }
                          ]
                        }
                      ],
                      "type": "resources",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": false
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "instructor_languages",
                    "kind": "LinkedField",
                    "name": "instructor_languages",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "language_id",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "limit",
                        "value": 1
                      },
                      {
                        "kind": "Literal",
                        "name": "order_by",
                        "value": {
                          "sort_order": "asc"
                        }
                      },
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "archived": {
                            "_eq": "0"
                          },
                          "image_approved": (v0/*: any*/),
                          "sort_order": {
                            "_gt": -1
                          }
                        }
                      }
                    ],
                    "concreteType": "resources_files_profileimg",
                    "kind": "LinkedField",
                    "name": "resources_files_profileimgs",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "fuploads",
                        "kind": "LinkedField",
                        "name": "fuploads",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "file_path",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "file_cdn",
                            "kind": "LinkedField",
                            "name": "file_cdn",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "base_url",
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": "resources_files_profileimgs(limit:1,order_by:{\"sort_order\":\"asc\"},where:{\"archived\":{\"_eq\":\"0\"},\"image_approved\":{\"_eq\":\"1\"},\"sort_order\":{\"_gt\":-1}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "resources_connection(where:{\"tutor_visibility\":{\"_eq\":\"1\"},\"working_status\":{\"_is_null\":true}})"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lang_learning",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ebb291932108d16d94b0df582877514",
    "id": null,
    "metadata": {},
    "name": "TeachersDiscoverDataQuery",
    "operationKind": "query",
    "text": "query TeachersDiscoverDataQuery {\n  ...TeachersDiscoverDataFragment\n  users_connection {\n    edges {\n      node {\n        ...TeachersDiscoverDataLanglearnFragment\n        id\n      }\n    }\n  }\n}\n\nfragment TeachersDiscoverDataFragment on query_root {\n  resources_connection(where: {working_status: {_is_null: true}, tutor_visibility: {_eq: \"1\"}}) {\n    edges {\n      node {\n        id\n        pk\n        uuid\n        gets_new_students\n        wants_new_students\n        provides_free_trial\n        title\n        stuprof_aboutme\n        ...relayFavoritedResolver\n        instructor_languages {\n          language_id\n          id\n        }\n        resources_files_profileimgs(limit: 1, order_by: {sort_order: asc}, where: {image_approved: {_eq: \"1\"}, archived: {_eq: \"0\"}, sort_order: {_gt: -1}}) {\n          fuploads {\n            file_path\n            file_cdn {\n              base_url\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment TeachersDiscoverDataLanglearnFragment on users {\n  lang_learning\n}\n\nfragment relayFavoritedResolver on resources {\n  user_favteachers {\n    favorite\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "14cc6cc9e4b69a75d02b7316078f0c27";

export default node;
