import * as React from 'react';
import { Skeleton as CustomSkeleton } from '@mui/material';
import { SkeletonProps } from './interface';

/**
 * Our main Glimmer component. See:
 *
 * https://mui.com/material-ui/react-skeleton/
 *
 * https://mui.com/material-ui/api/skeleton/
 */

export const Skeleton: React.FunctionComponent<SkeletonProps> = (props) => {
  const {
    animation = 'pulse',
    variant = 'text',
    width,
    height,
    cp,
  } = props;
  return (
    <CustomSkeleton
      variant={variant}
      animation={animation}
      width={width}
      height={height}
      {...cp}
    />
  );
};
