import React, { Suspense, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  CardStandard, Grid2Ct, Grid2, Dialog, Ty, Ic, Skeleton, Swiper, SwiperSlide, IcSvgList,
} from '@languageconvo/wcl';
import {
  SwiperContainerSchedulelsn,
  ErrorAlertContainer,
  SwiperDimensions,
} from './PrivateSchedLsn.style';
import { GridEl } from '../Common';
import { PrivateSchedLsnGetdata } from './PrivateSchedLsn1Getdata';

// the "schedule a 1-on-1 lesson" card
export const PrivateSchedLsn = () => {
  // #region modal

  // modal state. true=open, false=closed. closed by default
  const [privatelsnModalState, setPrivatelsnModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handlePrivatelsnModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setPrivatelsnModalState(true);
  };

  // modal close action
  const handlePrivatelsnModalClose = () => {
    setPrivatelsnModalState(false);
  };

  // #endregion

  return (
    <>
      {/* "schedule a 1-on-1 lesson" card. will contain PrivateSchedLsnSlider if the student
      has favorted any teachers. will contain PrivateSchedLsnEmpty if no favorted teachers */}
      <GridEl>
        <CardStandard
          titleText="Schedule a Private Lesson"
          titleIcon={IcSvgList.desk1}
          color="accentPurple1"
          titleIconRight={2}
          onClickRightIcon={handlePrivatelsnModalOpen}
          cp={{ sx: { width: '100%' } }}
        >
          <Grid2Ct>
            <Grid2 xs={12}>
              <Sentry.ErrorBoundary fallback={<PrivateSchedLsnSliderError />}>
                <Suspense fallback={<PrivateSchedLsnSliderLoading />}>
                  <PrivateSchedLsnGetdata />
                </Suspense>
              </Sentry.ErrorBoundary>
            </Grid2>
          </Grid2Ct>
        </CardStandard>
      </GridEl>

      {/* modal */}
      <Dialog
        isOpen={privatelsnModalState}
        onClose={handlePrivatelsnModalClose}
        width="md"
        color="accentPurple1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty v="h2New"><Ic iconName="calendar-heart" iconStyle="duotone" color="accentYellow1" />&nbsp;&nbsp;How do I schedule a 1-on-1 lesson?</Ty>
            <Ty>
              First, add a teacher as a &quot;Favorite&quot; &mdash; you can do that by going
              to the Discover Teachers page. Find a teacher on that page and
              click the &quot;Favorite&quot;
              button on their profile. After a teacher is added as one of your favorites,
              they will show up here in this box, along with a button to get to their schedule!
            </Ty>
            <Ty v="h2New" cp={{ sx: { mt: 3 } }}><Ic iconName="folder-user" iconStyle="duotone" color="accentBlue1" />&nbsp;&nbsp;I can&apos;t see all of my favorited teachers here!</Ty>
            <Ty>
              If you have favorited multiple teachers, click and drag the list to the left and right
              to scroll through. On mobile devices, just &quot;swipe&quot; to
              the left and right to scroll through the list.
            </Ty>
            <Ty v="h2New" cp={{ sx: { mt: 3 } }}><Ic iconName="user-check" iconStyle="duotone" color="accentGreen1" />&nbsp;&nbsp;How can I add a teacher to this list?</Ty>
            <Ty>
              Go to the Discover Teachers page, and click the &quot;Favorite&quot;
              button on a teacher&apos;s profile!
            </Ty>
            <Ty v="h2New" cp={{ sx: { mt: 3 } }}><Ic iconName="user-xmark" iconStyle="duotone" color="accentRed1" />&nbsp;&nbsp;How can I remove a teacher from this list?</Ty>
            <Ty>
              Remove them from your favorites. To do that, go to their profile and you will see the
              &quot;Favorite&quot; button. Click on it to remove them from your favorites.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};

// loading glimmer
const PrivateSchedLsnSliderLoading = () => (
  <SwiperContainerSchedulelsn>
    <Swiper
      ourcustomcolor="accentPurple1"
      ourcustomwidth="100%"
      // number of slides shown is automatically determined by user's screen width
      slidesPerView="auto"
      // kind of a "smooth scrolling" through slides type of effect
      freeMode={{
        enabled: true,
        // this stops a "bounce back" effect which is especially annoying on mobile
        momentumBounce: false,
      }}
      spaceBetween="15"
    >
      <SwiperSlide>
        <Skeleton variant="rectangular" height={SwiperDimensions.height} width={SwiperDimensions.width} />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton variant="rectangular" height="260px" width="190px" />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton variant="rectangular" height="260px" width="190px" />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton variant="rectangular" height="260px" width="190px" />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton variant="rectangular" height="260px" width="190px" />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton variant="rectangular" height="260px" width="190px" />
      </SwiperSlide>
      <SwiperSlide>
        <Skeleton variant="rectangular" height="260px" width="190px" />
      </SwiperSlide>
    </Swiper>
  </SwiperContainerSchedulelsn>
);

// error fallback
const PrivateSchedLsnSliderError = () => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <ErrorAlertContainer>
        {/* height here, and display, alignItems below are to to vertically center the text */}
        <Grid2Ct sx={{ height: '100%' }}>
          {/* IMPORTANT: if you edit this Grid2, you should also edit the PrivateUpcoming error
          container Grid2. So that their widths match */}
          <Grid2
            xs={12}
            sm={10}
            smOffset={1}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Ty removeMb align="center">
              <Ic iconName="triangle-exclamation" color="accentPurple1" iconStyle="duotone" />&nbsp;&nbsp;Hmm, something
              went wrong. Please reload the page, and if you continue seeing this error *please*
              let us know!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </ErrorAlertContainer>
    </Grid2>
  </Grid2Ct>
);
