/**
 * @generated SignedSource<<f2a526bcce0f635e29c9e62acfac1db0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StuSettingsGettrialdataTrialFragment$data = {
  readonly trialData: {
    readonly hasCredits: boolean | null;
    readonly trial: {
      readonly atleastOneTeacherGivesTrials: boolean | null;
      readonly hasMissedTwo: boolean | null;
      readonly hasUpcoming: boolean | null;
      readonly pastCompleted: boolean | null;
      readonly pastTrial: {
        readonly status: number | null;
      } | null;
      readonly pastTrialExists: boolean | null;
      readonly stepsToSchedule: {
        readonly step1: boolean | null;
        readonly step2: boolean | null;
      } | null;
    } | null;
  } | null;
  readonly " $fragmentType": "StuSettingsGettrialdataTrialFragment";
};
export type StuSettingsGettrialdataTrialFragment$key = {
  readonly " $data"?: StuSettingsGettrialdataTrialFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"StuSettingsGettrialdataTrialFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StuSettingsGettrialdataTrialFragment",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TrialDataDetail",
          "kind": "LinkedField",
          "name": "trialData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasCredits",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TrialDetail",
              "kind": "LinkedField",
              "name": "trial",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pastCompleted",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasMissedTwo",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasUpcoming",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StepsToScheduleDetail",
                  "kind": "LinkedField",
                  "name": "stepsToSchedule",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "step1",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "step2",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "atleastOneTeacherGivesTrials",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pastTrialExists",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PastTrialDetail",
                  "kind": "LinkedField",
                  "name": "pastTrial",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "appsetting",
  "abstractKey": null
};

(node as any).hash = "e04e1ca249798f7a66828644bc388b5b";

export default node;
