import {
  styled, WCLThemeProps, Box,
} from '@languageconvo/wcl';
import { HEADER } from '../../../../../config';

export const MobileTopBarContainer = styled(Box)(({ theme }: WCLThemeProps) => ({
  // only show this bottom bar on xs, sm, md
  [theme!.breakpoints.up('lg')]: {
    display: 'none',
  },
  // position the navbar fixed to the top of the page
  position: 'absolute',
  top: 0,
  bottom: 0,
  height: `${HEADER.MOBILE_HEIGHT}px`,
  width: '100%',
  // overflow ensures there's no vertical scrollbar, but there is one vertically
  overflowX: 'auto',
  overflowY: 'hidden',
  // not sure why nowrap is needed. multiple tutorials online listed it
  whiteSpace: 'nowrap',
  // our appbar/navbar bg color
  backgroundColor: `hsl(${theme?.ourTheme.colors.app.navbar})`,
  // this removes a blue background color that was happening when clicking on
  // navbar items
  WebkitTapHighlightColor: 'transparent',
  // create a bottom border line
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderBottomColor: `hsl(${theme?.ourTheme.colors.app.page})`,
}));

export const MobileTopBarContainerInner = styled(Box)(() => ({
  width: '420px',
  maxWidth: '420px',
  // not sure why only left padding is needed, something strange going on with
  // flexbox and grid
  paddingLeft: '20px',
}));

export const MobileTopBarIconContainer = styled(Box)({
  textAlign: 'right',
});

export const MobileTopBarIcon = styled('img')(() => ({
  width: '22px',
  height: '22px',
}));
