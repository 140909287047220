import React from 'react';
import { useClientQuery } from 'react-relay';
import {
  Grid2Ct, Grid2,
} from '@languageconvo/wcl';
import {
  MobileTopBarContainer, MobileTopBarContainerInner, MobileTopBarIconContainer,
} from './MobileTopBar.style';
import { BackbuttonDataQuery } from './relay/BackbuttonData';
import { BackButton } from './components/BackButton';
import { LangLearningDialog } from '../../../components/langlearningdialog/LangLearningDialog0';
import { MobileLanglearning } from './components/MobileLanglearning';
import useLangModal from '../../../../../hooks/useLangModal';

// fixed top bar on mobile devices
export const MobileTopBar = () => {
  // get the backbutton value from the relay store. this is a client-only value in RelayAppSettings
  // which allows us to useClientQuery
  const response: any = useClientQuery(
    BackbuttonDataQuery,
    {}
  );
  const dt = response.RelayAppSettings;

  // handling a lang learning dialog
  const { openModal, setOpenModal } = useLangModal();

  return (
    <>
      <MobileTopBarContainer display="flex" justifyContent="center">
        <MobileTopBarContainerInner display="flex" alignItems="center">
          <Grid2Ct sx={{ width: '100%' }}>
            {/* back arrow. conditionally shown only on subpages */}
            <Grid2 xs={6} sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <BackButton backbtn={dt} />
            </Grid2>

            {/* lang_learning, the language the student is currently studying */}
            <Grid2 xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {/* flex aligns this to the right horizontally, center vertically */}
              <MobileTopBarIconContainer
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                onClick={() => setOpenModal(!openModal)}
              >
                <MobileLanglearning />
              </MobileTopBarIconContainer>
            </Grid2>
          </Grid2Ct>
        </MobileTopBarContainerInner>
      </MobileTopBarContainer>
      {/* Render the GetStdLearningLang component only when the modal open */}
      <LangLearningDialog openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};
