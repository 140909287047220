import React, {
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import {
  useMutation, useClientQuery, useFragment,
} from 'react-relay';
import {
  StuSettingsGettrialdataMutation,
  StuSettingsGettrialdataClientQuery,
  StuSettingsGettrialdataTrialFragment,
} from '../../common/relay/mutations/students/StuSettingsGettrialdata';
import { StuSettingsGettrialdataTrialFragment$key } from '../../common/relay/mutations/students/__generated__/StuSettingsGettrialdataTrialFragment.graphql';
import { DashboardLayout3 } from './DashboardLayout3';
import { GetAppLevelDataLanglearnFragment } from './header/components/relay/GetAppLevelData';
import { GetAppLevelDataLanglearnFragment$key } from './header/components/relay/__generated__/GetAppLevelDataLanglearnFragment.graphql';
import { GetGroupClassroomFragment$data } from './relay/__generated__/GetGroupClassroomFragment.graphql';

interface Props {
  lnglearn: GetAppLevelDataLanglearnFragment$key
  allfrags: any,
  groupClassroomFragmentRef: GetGroupClassroomFragment$data
}

/* Get custom trial data about the user, via an api call. We wait until this data is returned
  before mounting any more downstream components
*/
export const DashboardLayout2 = ({
  lnglearn, allfrags, groupClassroomFragmentRef
} : Props) => {
  // #region custom trial data

  const [error, setError] = useState();
  // Get trial related data. When this api call returns, it includes a relay id which causes
  // the data to automagically get put into RelayAppSettings.trialData
  const [TheTrialData] = useMutation(StuSettingsGettrialdataMutation);

  const langIdDt = useFragment(GetAppLevelDataLanglearnFragment, lnglearn);
  const langId = langIdDt.lang_learning;

  useLayoutEffect(() => {
    TheTrialData({
      variables: {
        languageId: langId,
      },
      onCompleted() {
        // success, don't need to do anything
      },
      onError(err: any) {
        Sentry.captureException(err);
        setError(err);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #region to throw page level error in case of mutation fails.

  useEffect(() => {
    if (error) {
      throw new Error(error);
    }
  }, [error]);

  const response: any = useClientQuery(
    StuSettingsGettrialdataClientQuery,
    {}
  );

  const fragmentR: StuSettingsGettrialdataTrialFragment$key = response.RelayAppSettings;
  const data = useFragment(StuSettingsGettrialdataTrialFragment, fragmentR);

  // #endregion

  return (
    /* We won't mount any downstream components until our mutation to get trial data has fully
        completed. Many pages/downstream components need trial data, thus we dont want them
        running until it is received.
    */
    data?.trialData?.hasCredits === true || data?.trialData?.hasCredits === false ? (
      <DashboardLayout3
        fragmentRef={allfrags}
        groupClassroomFragmentRef={groupClassroomFragmentRef}
      />
    ) : (<> </>)
  );
};
