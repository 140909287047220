// errors that can occur during create stripe checkout url
export enum ErrorsStuSubsCreatecheckoutlinkMutation {
  // Occurs when one or more Stripe variable required by this API call
  // are missing from our environment variables
  // This can also occur when our code failed to update Stripe customer id in subs table
  TryAgain = 'TryAgain',
  // Occurs when user's account is suspended
  NotAllowed = 'NotAllowed',
  // Occurs when API call timeout or something unexpected happend in backend code
  UnexpectedOrTimeout = 'UnexpectedOrTimeout'
}

// errors that can occur during create stripe portal url
export enum ErrorsStuSubsCreateportalurlMutation {
  // Occurs when one or more Stripe variable required by this API call
  // are missing from our environment variables
  // This can also occur when our code failed to update Stripe customer id in subs table
  TryAgain = 'TryAgain',
  // Occurs when user's account is suspended
  NotAllowed = 'NotAllowed',
  // Occurs when API call timeout or something unexpected happend in backend code
  UnexpectedOrTimeout = 'UnexpectedOrTimeout'
}
