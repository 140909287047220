/**
 * @generated SignedSource<<7b936f7961c2ee1e1447a879d030f0e7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClassroomBasicDataQuery$variables = {
  lessonUuid: any;
};
export type ClassroomBasicDataQuery$data = {
  readonly appt_group_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly appt_group_reservations: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"ClassroomBasicDataHandRaisValueFragment">;
        }>;
        readonly " $fragmentSpreads": FragmentRefs<"ClassroomBasicDataLessoninfoFragment">;
      };
    }>;
  };
  readonly terms_privacy_grouplsn_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"ClassroomBasicDataTermsprivacy2Fragment">;
      };
    }>;
  };
  readonly user_settings_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"ClassroomBasicDataTermsprivacy1Fragment">;
      };
    }>;
  };
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"ClassroomBasicDataUserFragment">;
      };
    }>;
  };
};
export type ClassroomBasicDataQuery = {
  response: ClassroomBasicDataQuery$data;
  variables: ClassroomBasicDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonUuid"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "is_current": {
        "_eq": 1
      }
    }
  }
],
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "lessonUuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "uuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display_order",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_en",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClassroomBasicDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "user_settingsConnection",
        "kind": "LinkedField",
        "name": "user_settings_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_settingsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user_settings",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ClassroomBasicDataTermsprivacy1Fragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "terms_privacy_grouplsnConnection",
        "kind": "LinkedField",
        "name": "terms_privacy_grouplsn_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "terms_privacy_grouplsnEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "terms_privacy_grouplsn",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ClassroomBasicDataTermsprivacy2Fragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "terms_privacy_grouplsn_connection(where:{\"is_current\":{\"_eq\":1}})"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "appt_groupConnection",
        "kind": "LinkedField",
        "name": "appt_group_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appt_groupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "appt_group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ClassroomBasicDataLessoninfoFragment"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "appt_group_reservations",
                    "kind": "LinkedField",
                    "name": "appt_group_reservations",
                    "plural": true,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ClassroomBasicDataHandRaisValueFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ClassroomBasicDataUserFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClassroomBasicDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "user_settingsConnection",
        "kind": "LinkedField",
        "name": "user_settings_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_settingsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user_settings",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "termsprivacy_grouplsn_id",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "terms_privacy_grouplsnConnection",
        "kind": "LinkedField",
        "name": "terms_privacy_grouplsn_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "terms_privacy_grouplsnEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "terms_privacy_grouplsn",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pk",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "terms_privacy_grouplsn_connection(where:{\"is_current\":{\"_eq\":1}})"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "appt_groupConnection",
        "kind": "LinkedField",
        "name": "appt_group_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appt_groupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "appt_group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "starts_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ends_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "skills",
                    "kind": "LinkedField",
                    "name": "skills",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description_en",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uuid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "skills_levels",
                        "kind": "LinkedField",
                        "name": "skills_levels",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "main_skills_categories",
                            "kind": "LinkedField",
                            "name": "skills_categories",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "appt_group_reservations",
                    "kind": "LinkedField",
                    "name": "appt_group_reservations",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hand_raised",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hour_cycle",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ad80cb4a0252d1add9278f83f64dc1d",
    "id": null,
    "metadata": {},
    "name": "ClassroomBasicDataQuery",
    "operationKind": "query",
    "text": "query ClassroomBasicDataQuery(\n  $lessonUuid: uuid!\n) {\n  user_settings_connection {\n    edges {\n      node {\n        ...ClassroomBasicDataTermsprivacy1Fragment\n        id\n      }\n    }\n  }\n  terms_privacy_grouplsn_connection(where: {is_current: {_eq: 1}}) {\n    edges {\n      node {\n        ...ClassroomBasicDataTermsprivacy2Fragment\n        id\n      }\n    }\n  }\n  appt_group_connection(where: {uuid: {_eq: $lessonUuid}}) {\n    edges {\n      node {\n        ...ClassroomBasicDataLessoninfoFragment\n        appt_group_reservations {\n          ...ClassroomBasicDataHandRaisValueFragment\n          id\n        }\n        id\n      }\n    }\n  }\n  users_connection {\n    edges {\n      node {\n        ...ClassroomBasicDataUserFragment\n        id\n      }\n    }\n  }\n}\n\nfragment ClassroomBasicDataHandRaisValueFragment on appt_group_reservations {\n  id\n  hand_raised\n}\n\nfragment ClassroomBasicDataLessoninfoFragment on appt_group {\n  starts_at\n  ends_at\n  skills {\n    description_en\n    display_order\n    title_en\n    uuid\n    skills_levels {\n      display_order\n      title_en\n      skills_categories {\n        title_en\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment ClassroomBasicDataTermsprivacy1Fragment on user_settings {\n  termsprivacy_grouplsn_id\n}\n\nfragment ClassroomBasicDataTermsprivacy2Fragment on terms_privacy_grouplsn {\n  pk\n}\n\nfragment ClassroomBasicDataUserFragment on users {\n  hour_cycle\n}\n"
  }
};
})();

(node as any).hash = "a553cde853e08c7e48d9191582931a8b";

export default node;
