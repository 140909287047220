import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';

// wrapper of DataGridHeader component
export const DataGridHeaderMain = styled(Box)(({ theme }: WCLThemeProps) => ({
  background: theme?.palette.background.paper,
  borderTopLeftRadius: theme?.ourTheme.borders.borderRadius.medium,
  borderTopRightRadius: theme?.ourTheme.borders.borderRadius.medium,
  padding: theme?.ourTheme.padding?.dataGridHeaderPadding,
  border: theme?.ourTheme.borders.mainBorder.border,
  borderBottom: 'none',
  boxShadow: 'none',
  // this is a page-level item that users don't really "interact with"
  transition: theme?.ourTheme.components.transition.page,
}));
