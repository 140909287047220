import styled from '@emotion/styled';
import {
  Card,
} from '@mui/material';
import { PropsStyle } from './interface';

export const CustomCard = styled(Card)<PropsStyle>(({
  theme, hovercursor,
}) => {
  return ({
    display: 'flex',
    backgroundColor: theme.ourTheme.colors.app.container,
    boxShadow: 'none',
    border: theme.ourTheme.borders.mainBorder.border,
    borderRadius: theme.ourTheme.borders.borderRadius.medium,
    // optionally show some type of cursor on hover
    ...(hovercursor === 'pointer' && {
      cursor: 'pointer',
    }),
  });
});

export const AlignTitle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));
