import React from 'react';
import {
  CardStandard, Ty, Grid2Ct, Grid2, CardSmallColor, IcSvgList, Link,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { sitedata } from '../../../../../../utils/sitedata';

export const GroupClassesNotsubscribed = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <>
      {/* main component */}
      <Grid2 xs={12} xl={6}>
        <CardStandard
          titleText="Group Classes"
          titleIcon={IcSvgList.group1}
          titleIconRight={0}
          color="accentBlue1"
          cp={{ sx: { width: '100%' }, 'data-intercom': 'homegroupclassNotsubscribed' }}
        >
          <Grid2Ct>
            <Grid2 xs={12}>
              <Ty removeMb>
                A great, affordable way to improve your language skills. You can even mix classes
                with private lessons.
                Try <strong>for free</strong> any time!
              </Ty>
            </Grid2>
            <Grid2 xs={12} sm={6}>
              <Link
                to={sitedata.url.app.grouplsnUpcomingDt.pathFull}
                component={NavLink}
                linkStyle="nostyle"
              >
                <CardSmallColor
                  text="View Schedule"
                  icon={IcSvgList.calendar1}
                  color="accentPurple1"
                  hovercursor="pointer"
                />
              </Link>
            </Grid2>
            <Grid2 xs={12} sm={6}>
              <Link
                to={sitedata.url.app.purchaseHomeDt.pathFull}
                component={NavLink}
                linkStyle="nostyle"
              >
                <CardSmallColor
                  text="Try For Free!"
                  icon={IcSvgList.cart2}
                  color="accentGreen1"
                  hovercursor="pointer"
                />
              </Link>
            </Grid2>
          </Grid2Ct>
        </CardStandard>
      </Grid2>
    </>
  );
};
