/**
 * @generated SignedSource<<55711818744c91df350266d919333288>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TrialLessonInfoQuery$variables = {
  currentTs: any;
};
export type TrialLessonInfoQuery$data = {
  readonly appointments_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"TrialLessonInfoFragment">;
      };
    }>;
  };
};
export type TrialLessonInfoQuery = {
  response: TrialLessonInfoQuery$data;
  variables: TrialLessonInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentTs"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "starts_at": "asc"
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_gt",
            "variableName": "currentTs"
          }
        ],
        "kind": "ObjectValue",
        "name": "ends_at"
      },
      {
        "kind": "Literal",
        "name": "package_type_id",
        "value": {
          "_eq": 4
        }
      },
      {
        "kind": "Literal",
        "name": "real_status",
        "value": {
          "_in": [
            1,
            8
          ]
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TrialLessonInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "appointmentsConnection",
        "kind": "LinkedField",
        "name": "appointments_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appointmentsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "appointments",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TrialLessonInfoFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TrialLessonInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "appointmentsConnection",
        "kind": "LinkedField",
        "name": "appointments_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appointmentsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "appointments",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ends_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "starts_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id_random",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bea3259539de3fbba533a0ab6facf704",
    "id": null,
    "metadata": {},
    "name": "TrialLessonInfoQuery",
    "operationKind": "query",
    "text": "query TrialLessonInfoQuery(\n  $currentTs: bigint!\n) {\n  appointments_connection(where: {package_type_id: {_eq: 4}, ends_at: {_gt: $currentTs}, real_status: {_in: [1, 8]}}, first: 1, order_by: {starts_at: asc}) {\n    edges {\n      node {\n        ...TrialLessonInfoFragment\n        id\n      }\n    }\n  }\n}\n\nfragment TrialLessonInfoFragment on appointments {\n  ends_at\n  starts_at\n  id_random\n}\n"
  }
};
})();

(node as any).hash = "03968f2bef322bab884d0a7de0ab78b1";

export default node;
