/**
 * @generated SignedSource<<c085e1a16ff9d24aa65ef29293ad518c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExtractPreferredLanguageFromStore$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly preferredLanguageKey: number | null;
        readonly selectedLang: string | null;
      };
    }>;
  };
  readonly " $fragmentType": "ExtractPreferredLanguageFromStore";
};
export type ExtractPreferredLanguageFromStore$key = {
  readonly " $data"?: ExtractPreferredLanguageFromStore$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExtractPreferredLanguageFromStore">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExtractPreferredLanguageFromStore",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "usersConnection",
      "kind": "LinkedField",
      "name": "users_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "usersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "users",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "preferredLanguageKey",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "selectedLang",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};

(node as any).hash = "ad74e1e6c154d62ee54541c980ac2b43";

export default node;
