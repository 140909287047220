import React from 'react';
import { useTheme } from '@mui/material';
import { DataGridProps } from './interface';
import { CustomDataGrid, DataGridContainer } from './DataGrid.Style';
import CustomPagination from './CustomPagination';

/**
 * Our Data Grid component. See:
 *
 * https://mui.com/x/react-data-grid/
 *
 * https://mui.com/x/api/data-grid/data-grid/
 */

export const DataGrid: React.FunctionComponent<DataGridProps> = (props) => {
  const {
    rows,
    columns,
    loading,
    currentPage,
    totalPages,
    handlePageChange,
    autoHeight = true,
    paginationMode = 'server',
    disableColumnMenu = true,
    cellsClassName,
    cp,
    rowCount = 0, // server side pagination requirements.
  } = props;

  const theme = useTheme();
  return (
    <DataGridContainer>
      <CustomDataGrid
        theme={theme}
        rows={rows}
        columns={columns}
        autoHeight={autoHeight}
        paginationMode={paginationMode}
        loading={loading}
        showCellVerticalBorder
        showColumnVerticalBorder
        disableColumnMenu={disableColumnMenu}
        rowCount={rowCount}
        hideFooterSelectedRowCount
        slots={{
          pagination: CustomPagination,
        }}
        getCellClassName={cellsClassName}
        slotProps={{
          pagination: {
            handlePageChange,
            currentPage,
            totalPages,
          },
        }}
        {...cp}
      />
    </DataGridContainer>
  );
};
