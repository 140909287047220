/** Extracts the Vonage session ID and token for connecting to a session 
 * based on the room layout data and initial page load data. This function
 *  determines which session to connect to based on the room view and the 
 * streaming status. It supports two modes: teacher-only mode and group mode.
 * @param roomLayoutData - Data representing the layout of the room.
 * @param initialPageLoadData - Data loaded initially when the page loads,
 *  including audio/video settings.
 * @param { string } studentId - The ID of the current logged-in student.
 * @returns { object|null } - An object containing the sessionId and 
 * token to connect with, or null if no valid session is found.
 */
export const getSessionToConnectWith = (
  roomLayoutData: any,
  initialPageLoadData: any,
  studentId: string
) => {
  const audioVideo = initialPageLoadData.av;
  const streamingTo = roomLayoutData.teacherStreamingTo[0];
  // here we are checking roomView is 1 and streamingTo entity 
  // is not 4 (streamingTo.entity 4 represent Speaking to Teacher Speaking To `Nobody`)
  if (roomLayoutData.roomView === 1 && streamingTo.entity !== 4) {
    const sessionId = audioVideo.room1.session;
    const { token } = audioVideo.room1;
    // here we are returing sesionId and token for roomView 1 which is our teacher only Mode.
    return { sessionId, token };
  }

  if (roomLayoutData.roomView === 2) {
    const { studentGroupRooms } = audioVideo;
    const { studentGroupRooms: stdRoomsFromLayout } = roomLayoutData.rooms;
    // checking the UUID of the first room in stdRoomsFromLayout matches
    // the UUID of the first room in studentGroupRooms.
    // This confirms that both arrays are referring to the same room.
    if (stdRoomsFromLayout[0].uuid === studentGroupRooms[0].uuid) {
      // If the current logged-in student's ID is found in the students array
      // of the first room in stdRoomsFromLayout, proceed to retrieve session info.
      if (stdRoomsFromLayout[0].students.includes(studentId)) {
        const sessionId = studentGroupRooms[0].session;
        const { token } = studentGroupRooms[0];
        // Returning the sessionId and token for the matching room (group 0).
        return { sessionId, token };
      }

      // checking the UUID of the first room in stdRoomsFromLayout matches
      // the UUID of the first room in studentGroupRooms.
      // This confirms that both arrays are referring to the same room.
      if (stdRoomsFromLayout[1].uuid === studentGroupRooms[1].uuid) {
        // here we are checking studentId(cuurent loged in student) is present
        //  in student at group 1 if student is found in group0 we are returing toke and session id 
        // of `studentGroupRooms[0]` present in initialPageLoadData
        if (stdRoomsFromLayout[1].students.includes(studentId)) {
          const sessionId = studentGroupRooms[1].session;
          const { token } = studentGroupRooms[1];
          // Returning the sessionId and token for the matching room (group 1).
          return { sessionId, token };
        }
      }
    }
  }

  return null;
};
