/**
 * @generated SignedSource<<3320e17a5f5fb3df7351b61ec2dab79e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Setup2GetClassroomDataMutation$variables = {
  lessonUuid: string;
};
export type Setup2GetClassroomDataMutation$data = {
  readonly stu_apptgroup_classroomenter: {
    readonly " $fragmentSpreads": FragmentRefs<"ClassroomViewFragment" | "InformationModalFragment" | "LessonDetailFragment" | "LessonTimeCalculationFragment" | "PusherInfoFragment">;
  } | null;
};
export type Setup2GetClassroomDataMutation = {
  response: Setup2GetClassroomDataMutation$data;
  variables: Setup2GetClassroomDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonUuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "lessonUuid",
    "variableName": "lessonUuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "session",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Setup2GetClassroomDataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StuApptgroupClassroomenterOutput",
        "kind": "LinkedField",
        "name": "stu_apptgroup_classroomenter",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LessonDetailFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LessonTimeCalculationFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InformationModalFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClassroomViewFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PusherInfoFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Setup2GetClassroomDataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StuApptgroupClassroomenterOutput",
        "kind": "LinkedField",
        "name": "stu_apptgroup_classroomenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StuApptgroupClassroomenterav",
            "kind": "LinkedField",
            "name": "av",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StuApptgroupClassroomenterroom",
                "kind": "LinkedField",
                "name": "room1",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StuApptgroupClassroomenterroom",
                "kind": "LinkedField",
                "name": "room2",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StuApptgroupClassroomenterroomstudgrp",
                "kind": "LinkedField",
                "name": "studentGroupRooms",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uuid",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skillId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lessonUuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pusherChannel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "teacherName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StuApptgroupClassroomenterdt",
            "kind": "LinkedField",
            "name": "dt",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "current",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondsUntilEnd",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondsUntilStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startsAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2bfc044f65e477630689df06a261c8b2",
    "id": null,
    "metadata": {},
    "name": "Setup2GetClassroomDataMutation",
    "operationKind": "mutation",
    "text": "mutation Setup2GetClassroomDataMutation(\n  $lessonUuid: String!\n) {\n  stu_apptgroup_classroomenter(lessonUuid: $lessonUuid) {\n    ...LessonDetailFragment\n    ...LessonTimeCalculationFragment\n    ...InformationModalFragment\n    ...ClassroomViewFragment\n    ...PusherInfoFragment\n  }\n}\n\nfragment ClassroomViewFragment on StuApptgroupClassroomenterOutput {\n  dt {\n    secondsUntilEnd\n    secondsUntilStart\n  }\n  pusherChannel\n}\n\nfragment InformationModalFragment on StuApptgroupClassroomenterOutput {\n  dt {\n    current\n    duration\n    endsAt\n    secondsUntilEnd\n    secondsUntilStart\n    startsAt\n  }\n  skillId\n  teacherName\n}\n\nfragment LessonDetailFragment on StuApptgroupClassroomenterOutput {\n  av {\n    room1 {\n      session\n      token\n    }\n    room2 {\n      session\n      token\n    }\n    studentGroupRooms {\n      session\n      token\n      uuid\n    }\n  }\n  skillId\n  lessonUuid\n  pusherChannel\n  teacherName\n}\n\nfragment LessonTimeCalculationFragment on StuApptgroupClassroomenterOutput {\n  dt {\n    current\n    duration\n    endsAt\n    secondsUntilEnd\n    secondsUntilStart\n    startsAt\n  }\n}\n\nfragment PusherInfoFragment on StuApptgroupClassroomenterOutput {\n  pusherChannel\n}\n"
  }
};
})();

(node as any).hash = "71e0342de4e828b94ee66d213051de45";

export default node;
