/**
 * @generated SignedSource<<5e85c8fe6b54a864ae5b8cc60bd0f758>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GroupUserSettingsUpdateMutation$variables = {
  groupName: string;
  groupPassword: string;
};
export type GroupUserSettingsUpdateMutation$data = {
  readonly stu_settings_groupcredscreate: {
    readonly errors: ReadonlyArray<string | null> | null;
    readonly success: boolean;
    readonly user: {
      readonly groupname: string | null;
      readonly id: string;
    };
  } | null;
};
export type GroupUserSettingsUpdateMutation = {
  response: GroupUserSettingsUpdateMutation$data;
  variables: GroupUserSettingsUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupPassword"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "groupName",
        "variableName": "groupName"
      },
      {
        "kind": "Variable",
        "name": "groupPassword",
        "variableName": "groupPassword"
      }
    ],
    "concreteType": "StuSettingsGroupCredsCreateOutput",
    "kind": "LinkedField",
    "name": "stu_settings_groupcredscreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groupname",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupUserSettingsUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GroupUserSettingsUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "82eb9f76c7a04408f58139b287e7d3bf",
    "id": null,
    "metadata": {},
    "name": "GroupUserSettingsUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation GroupUserSettingsUpdateMutation(\n  $groupName: String!\n  $groupPassword: String!\n) {\n  stu_settings_groupcredscreate(groupName: $groupName, groupPassword: $groupPassword) {\n    success\n    errors\n    user {\n      id\n      groupname\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "88a155fdab9814f5458910ebe48300c7";

export default node;
