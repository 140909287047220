/**
 * @generated SignedSource<<f178f69a5dc92f05031ac6e70fbdb1fa>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GiftsHistoryTableFragment$data = {
  readonly pk: any;
  readonly " $fragmentType": "GiftsHistoryTableFragment";
};
export type GiftsHistoryTableFragment$key = {
  readonly " $data"?: GiftsHistoryTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GiftsHistoryTableFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GiftsHistoryTableFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pk",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "885d9a3256675d995ff8b609b7e6f30e";

export default node;
