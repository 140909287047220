/**
 * @generated SignedSource<<8d89722f1e9b5f98729c6d1c6ece302a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BackbuttonDesktopDataFragment$data = {
  readonly backbutton: string | null;
  readonly " $fragmentType": "BackbuttonDesktopDataFragment";
};
export type BackbuttonDesktopDataFragment$key = {
  readonly " $data"?: BackbuttonDesktopDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BackbuttonDesktopDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BackbuttonDesktopDataFragment",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backbutton",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "appsetting",
  "abstractKey": null
};

(node as any).hash = "21bb2f7bd1e8313d11aa5ba7cecbb12c";

export default node;
