import React, {
  createContext,
  useState,
} from 'react';
import { AuthContextType } from '../types/auth.type';

const AuthContext = createContext<AuthContextType>({
  auth: {
    data: {}, isAuthenticated: false
  },
  setAuth: () => {},
  // for logout progress state...
  // displaying a backdrop in case of logout reset cookie api call is inprogress.
  logoutInProgress: false,
  setLogoutInProgress: () => {}
});

interface AuthProviderProps {
  children?: React.ReactNode;
}
const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    data: {}
  });
  const [logoutInProgress, setLogoutInProgress] = useState(false);
  const value = React.useMemo(
    () => ({
      auth,
      setAuth,
      logoutInProgress,
      setLogoutInProgress
    }),
    [auth, logoutInProgress]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
