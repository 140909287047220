import React, {
  useMemo, useState, Suspense
} from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import * as Sentry from '@sentry/react';
import { useSubscription } from 'react-relay';
import { Setup2GetClassroomDataMutation$data } from './__generated__/Setup2GetClassroomDataMutation.graphql';
import { Setup3RoomLayoutSubscription as SubscriptionType } from './__generated__/Setup3RoomLayoutSubscription.graphql';
import { Setup4ViewSetup1 } from './Setup4ViewSetup1';

interface Props {
  uuid: string
  initialClassroomDataRef: Setup2GetClassroomDataMutation$data
  setmodalviewContents: any
  setmodalviewState: any
}

// defining subscription
const RoomLayoutComponentSubscription = graphql`
  subscription Setup3RoomLayoutSubscription($lessonUuid: uuid) {
    ...RoomLayoutFragment
  }
`;

// here we are executing subscription to classroom `layout` data to get the updated data as there is
// change in layout data  
export const Setup3RoomLayoutSubscription = ({
  uuid,
  initialClassroomDataRef,
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  // this will hold the layout data changes fragment reference for later use.
  const [roomLayoutRef, setRoomLayoutRef] = useState(null);
  const [error, setError] = useState(false);

  // #region Executing Subscription

  // here we are subscribing/listening to `layoutData` to get the updated data as there is
  // change in layout data  
  const roomLayoutConfig = useMemo(() => ({
    subscription: RoomLayoutComponentSubscription,
    variables: {
      lessonUuid: uuid,
    },
    // a callback that is executed when a subscription payload is received
    onNext: (res: any) => {
      // eslint-disable-next-line no-console
      console.log('Subscription: Layout Data received');
      setRoomLayoutRef(res); // here we will get the fragment Reference
    },

    // a callback that is executed when the subscription error OR.
    // retries completed, in this cases, we are showing page level error via setError callback.
    onError: (err: any) => {
      Sentry.captureException(err);
      // eslint-disable-next-line no-console
      console.log('Subscription: onError Callback.... Here is the error detail:', err);
      setError(true);
    },

    // a callback that is executed when the server ends the subscription. in this case we throw
    // just as we do in onError, because the user needs to have an active subscription
    // in order for them to participate in the class
    onCompleted: () => {
      // eslint-disable-next-line no-console
      console.log('Subscription: completed: server ends the subscription');
      Sentry.captureMessage('UIS room layout subscription completed.');
      setError(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  // executing the subscription.
  useSubscription<SubscriptionType>(roomLayoutConfig);

  // #endregion

  // if the mutation call fails, then we will show a common error component.
  if (error) {
    throw new Error('Classroom layout subscription failed');
  }

  // show loader until subscription resolved and returned fragmentRef.
  if (!roomLayoutRef) {
    return <div />;
  }

  return (
    <Suspense>
      <Setup4ViewSetup1
        roomLayoutRef={roomLayoutRef}
        initialClassroomDataRef={initialClassroomDataRef}
        setmodalviewContents={setmodalviewContents}
        setmodalviewState={setmodalviewState}
      />
    </Suspense>
  );
};
