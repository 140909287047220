import { url } from './url';
import { languages } from './languages';
import { assets } from './assets';
import { countries } from './countries';
import { hourCycleOptions } from './hourCycle';
import { studentLocalStorageKeys } from './studentLocalStorageKeys';
import { generateJWTFromRefreshTokenErrors } from './generateJWTFromRefreshTokenErrors';

export const sitedata = {
  url,
  languages,
  assets,
  studentLocalStorageKeys,
  generateJWTFromRefreshTokenErrors,
  countries,
  hourCycleOptions
};
