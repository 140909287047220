/**
 * @generated SignedSource<<72d7afa2e7d94d03ac4bee8e4a21b650>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetLanguageFromOrders$data = {
  readonly orders_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly location_id: number;
      };
    }>;
  };
  readonly " $fragmentType": "GetLanguageFromOrders";
};
export type GetLanguageFromOrders$key = {
  readonly " $data"?: GetLanguageFromOrders$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetLanguageFromOrders">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetLanguageFromOrders",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "valid_from": "desc"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "is_active": {
              "_eq": 1
            }
          }
        }
      ],
      "concreteType": "ordersConnection",
      "kind": "LinkedField",
      "name": "orders_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ordersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "orders",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "location_id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "orders_connection(first:1,order_by:{\"valid_from\":\"desc\"},where:{\"is_active\":{\"_eq\":1}})"
    }
  ],
  "type": "query_root",
  "abstractKey": null
};

(node as any).hash = "7648aff9ea529c5c8c1ead0a85614c07";

export default node;
