import React, { useState, useRef } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import {
  List,
  Collapse,
  Popover,
} from '@languageconvo/wcl';
import { NavListProps, RenderListChildrenProps } from '../../../../types/nav-section.type';
import {
  NavbarInnerListItemParent,
  NavbarInnerListItemChildDropdown,
  NavbarInnerListItemChildPopover,
} from './NavbarInnerListItem';
import { getStuSettingsFromLCStorage, setStuSettingsToLCStorage } from '../../../../utils/lcStorage';
import { LcTchSettings } from '../../../../utils/lcStorageInterface';

export const NavbarInnerList = ({ list, isCollapse }: NavListProps) => {
  /** to show active states in sidebar, need pathname */
  const { pathname } = useLocation();
  const active = getActive(list.path, pathname);
  /** in case of drawer collapse, need hovered item reference to show its childs on popover */
  const navRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);
  // this variable responsible for opening and closing the sideNav items.
  const [open, setOpen] = useState(list.isOpen);
  const hasChildren = list.children;
  /** extract the lc_tchsettings object from localStorage and set the open and close
   * flag based on the user action.
   * After updating, store back again in localStorage.
   */
  const updateNavItemsInLocalStorage = (flag: boolean, item: any) => {
    const storedValues:LcTchSettings = getStuSettingsFromLCStorage();
    const newArray: any = [];
    storedValues.navbar.expandedItems.forEach((_item: any) => {
      if (_item.id === item.id) {
        // eslint-disable-next-line no-param-reassign
        _item.isOpen = flag;
      }
      newArray.push(_item);
    });
    const newSettings = {
      navbar: {
        ...storedValues.navbar,
        expandedItems: newArray,
      },
    };
    setStuSettingsToLCStorage(newSettings);
  };

  const handleOpen = () => {
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  // builds child pages, if we're *not* displaying them in a popover
  const RenderListChildrenDropdown = ({ isPopoverChild }: RenderListChildrenProps) => (
    <List component="div" disablePadding>
      {(list.children || []).map((item) => {
        const activeChild = getActive(item.path, pathname);
        return (
          <NavbarInnerListItemChildDropdown
            isPopoverChild={isPopoverChild}
            key={item.title}
            item={item}
            active={activeChild}
            parent={list.title}
            isCollapse={isCollapse}
            newtab={item.newtab}
          />
        );
      })}
    </List>
  );

  // builds child pages, if we are displaying them in a popover
  const RenderListChildrenPopover = ({ isPopoverChild }: RenderListChildrenProps) => (
    <List component="div" disablePadding>
      {(list.children || []).map((item) => {
        const activeChild = getActive(item.path, pathname);
        return (
          <NavbarInnerListItemChildPopover
            isPopoverChild={isPopoverChild}
            key={item.title}
            item={item}
            active={activeChild}
            parent={list.title}
            isCollapse={isCollapse}
            newtab={item.newtab}
          />
        );
      })}
    </List>
  );

  // if the item has children (subpages), this will display
  if (hasChildren) {
    return (
      <>
        {/* this is the parent page: Home, Purchase, Schedule, Classroom etc. */}
        <NavbarInnerListItemParent
          item={list}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => { setOpen(!open); updateNavItemsInLocalStorage(!open, list); }}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          navRef={navRef}
        />

        {/* these are the children pages: schedule a single lesson, schedule a group
          lesson, etc. */}
        {isCollapse ? (
          // on desktop if the drawer is collapsed, we show children pages via popover
          <Popover
            isOpen={openPopover}
            anchorEl={navRef.current}
            anchorH="right"
            anchorV="center"
            transformH="left"
            transformV="center"
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
            width={200}
          >
            <RenderListChildrenPopover isPopoverChild />
          </Popover>
        ) : (
          // else we display children pages in a dropdown list
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RenderListChildrenDropdown />
          </Collapse>
        )}
      </>
    );
  }

  // else there are no child subpages, this will display. for example the Home section
  // is just one single page, has no children
  return <NavbarInnerListItemParent item={list} active={active} isCollapse={isCollapse} />;
};

// If the route pathName matches the url path it return true else false.
export const getActive = (path: string, pathname: string) => (
  path ? !!matchPath({ path, end: false }, pathname) : false
);
