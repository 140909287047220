import { graphql } from 'babel-plugin-relay/macro';

export const WorldTourGetCountryDetailQuery = graphql`
  query WorldTourGetCountryDetailQuery($countryId: Int!) {
    practice_world_countries_connection (where: {pk: {_eq: $countryId}}) {
    edges {
      node {
          ...WorldTourGetCountryDetailFragment
        }
      }
    }
  }
`;

export const WorldTourGetCountryDetailFragment = graphql`
  fragment WorldTourGetCountryDetailFragment on practice_world_countries {
    overview
    country_name
    practice_world_radiostations (
      where: { is_displayed: {_eq: 1}}
    ) {
      playback_url
      city
      name
      practice_world_countries_id
      id
    }
    flag_image
    practice_world_funfacts (
      where: { is_displayed: {_eq: 1}}
    ) {
      funfact
    }
  }
`;
