import React from 'react';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { PretrialDataLanglearnQuery, PretrialDataLanglearnFragment } from '../../relay/posttrial/PretrialData';
import { PretrialDataLanglearnFragment$key } from '../../relay/posttrial/__generated__/PretrialDataLanglearnFragment.graphql';
import { LayoutNocredits2Gettrialdata } from './LayoutNocredits2Gettrialdata';

/* Get the language the user is learning. This is very likely already in the relay store
*/
export const LayoutNocredits1Getlangdata = () => {
  // get the lang_learning value. we *should* already have this value, as it is gotten on pageload,
  // but as always we get it via relay with a query just in case it was garbage collected
  const response: any = useLazyLoadQuery(
    PretrialDataLanglearnQuery,
    {}
  );
  const fragmentRef: PretrialDataLanglearnFragment$key = response.users_connection.edges[0].node;
  const data = useFragment(PretrialDataLanglearnFragment, fragmentRef);
  const langlearnId = data.lang_learning;

  return (
    // no suspense, see the next page for reasoning on why
    <LayoutNocredits2Gettrialdata langId={langlearnId} />
  );
};
