/**
 * @generated SignedSource<<bf0de688ca2b46cab7783425bcc65a70>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeachersDiscoverDataLanglearnFragment$data = {
  readonly lang_learning: number;
  readonly " $fragmentType": "TeachersDiscoverDataLanglearnFragment";
};
export type TeachersDiscoverDataLanglearnFragment$key = {
  readonly " $data"?: TeachersDiscoverDataLanglearnFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeachersDiscoverDataLanglearnFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeachersDiscoverDataLanglearnFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lang_learning",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "b2d81418ebbc3ac21c15aaf47f6f0ab2";

export default node;
