import {
  styled,
} from '@languageconvo/wcl';

export const ImageTeacher = styled('img')(() => ({
  width: '100%',
  aspectRatio: '1 / 1', /* defining the aspect ratio of the image */
  objectFit: 'cover', /* making sure the image isn't distorted */
  maxWidth: '200px',
}));
