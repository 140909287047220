import React from 'react';
import {
  Box, useTheme, styled,
} from '@languageconvo/wcl';
import { useVonage } from '../../hooks/useVonage';
import { WhichViewTy } from '../setup/Setup4ViewSetup1';
import { VideoDivId } from '../../context/Types';

interface Props {
  whichView: WhichViewTy;
}

// display the teacher's video and screen sharing, during lecture mode
export const TeacherOnly = ({ whichView } : Props) => {
  const { isTeacherScreensharing } = useVonage();
  const theme = useTheme();
  const bRad = `${theme?.ourTheme.borders.borderRadius.medium}px`;
  let isLecture = false;
  if (whichView === WhichViewTy.Lecture) {
    isLecture = true;
  }

  return (
    <LectureContainer isLecture={isLecture}>
      {/* screen share */}
      <Box
        id={VideoDivId.LectureScreenContainer}
        sx={{
          width: isTeacherScreensharing ? '100%' : '0px',
          height: isTeacherScreensharing ? '100%' : '0px',
          overflow: 'hidden',
          borderRadius: bRad,
        }}
      />

      {/* video */}
      <Box
        id={VideoDivId.LectureCameraContainer}
        sx={{
          position: isTeacherScreensharing ? 'absolute' : 'relative',
          bottom: isTeacherScreensharing ? 0 : 'none',
          width: isTeacherScreensharing ? '100px' : '100%',
          height: isTeacherScreensharing ? '100px' : '100%',
          overflow: 'hidden',
          borderRadius: bRad,
        }}
      />
    </LectureContainer>
  );
};

// this container allows us to show/hide the div depending on if we're in lecture or group chat mode
interface LectureContainerProps {
  isLecture: boolean
}
const LectureContainer = styled(
  Box,
  {
    shouldForwardProp: (prop) => prop !== 'isLecture'
  },
)(({ isLecture } : LectureContainerProps) => (
  {
    height: '100%',
    display: 'none',
    ...(isLecture && {
      display: 'block',
    }),
  }
));
