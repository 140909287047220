import React, {
  ReactNode, createContext, useCallback, useState
} from 'react';
import { getStuSettingsFromLCStorage, setStuSettingsToLCStorage } from '../utils/lcStorage';

type SettingsProviderProps = {
    children: ReactNode;
  };

type initialStateType = {
  themeMode: 'light' | 'dark',
  onToggleMode: () => void;
}

const initialState: initialStateType = {
  themeMode: 'light',
  onToggleMode: () => {}
};

const SettingsContext = createContext(initialState);

const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const storedValues = getStuSettingsFromLCStorage();
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>(storedValues?.theme?.mode || 'light');

  // this function will toggle the theme mode in localStorage.
  const toggleThemeModeInLocalStorage = (updatedMode: 'light' | 'dark') => {
    const newSettings = {
      theme: {
        mode: updatedMode,
      },
    };
    setStuSettingsToLCStorage(newSettings);
  };

  const onToggleMode = useCallback(() => {
    const mode = getStuSettingsFromLCStorage().theme.mode === 'light' ? 'dark' : 'light';
    setThemeMode(mode);
    toggleThemeModeInLocalStorage(mode);
  }, [setThemeMode]);

  const settingsProviderValue = React.useMemo(() => ({
    themeMode,
    onToggleMode,
  }), [themeMode, onToggleMode]);

  return (
    <SettingsContext.Provider
      value={settingsProviderValue}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };
