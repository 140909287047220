import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useFragment } from 'react-relay';
import * as Sentry from '@sentry/react';
import {
  Grid2,
  Grid2Ct, Ty,
} from '@languageconvo/wcl';
import { WorldTourGetCountriesFragment$key } from './relay/__generated__/WorldTourGetCountriesFragment.graphql';
import { WorldTourGetCountriesFragment, WorldTourGetCountriesLangLearningFragment } from './relay/WorldTourGetCountries';
import { Countries } from './GlobalTour2';
import { WorldTourGetCountriesLangLearningFragment$key } from './relay/__generated__/WorldTourGetCountriesLangLearningFragment.graphql';

interface GlobalTourDataReadingProps {
  langLearningFragmentRef: WorldTourGetCountriesLangLearningFragment$key
  fragmentRef: WorldTourGetCountriesFragment$key;
  setShowContinentPage: Dispatch<SetStateAction<boolean>>;
  setShouldStopRadio: Dispatch<SetStateAction<boolean>>;
  setShuffledFunFacts: any;
  setUserSelectedLangId: any;
  setAllCountries: any;
  setShowFullOverview: Dispatch<SetStateAction<boolean>>;
}

export const GlobalTourDataReading = (props: GlobalTourDataReadingProps) => {
  const {
    fragmentRef, setShowContinentPage, setShouldStopRadio,
    setShuffledFunFacts, langLearningFragmentRef, setUserSelectedLangId,
    setAllCountries, setShowFullOverview
  } = props;

  const continentsData = useFragment(WorldTourGetCountriesFragment, fragmentRef);

  const langLearningData: any = useFragment(
    WorldTourGetCountriesLangLearningFragment,
    langLearningFragmentRef
  );

  const languageLearningId = langLearningData.lang_learning;

  // setting all countries in allCountries state of GlobalTour0 component
  useEffect(() => {
    if (continentsData.practice_world_countries.length > 0) {
      setAllCountries((prev: any) => [...prev, ...continentsData.practice_world_countries]);
    }

    // eslint-disable-next-line
  }, [continentsData]);

  // setting the languageLearningId when it changes to immediately show new coutries
  useEffect(() => {
    setUserSelectedLangId(languageLearningId);

    // eslint-disable-next-line
  }, [languageLearningId]);

  // logging the error 
  if (continentsData.practice_world_countries.length === 0) {
    Sentry.captureException(
      new Error(`No Countries found in the following continent page for continent ${continentsData.continent_name}`),
      {
        extra: {
          response: continentsData,
          countries: continentsData.practice_world_countries
        }
      }
    );
  }

  return (
    <>
      {/* continent name */}
      <Grid2Ct>
        <Grid2>
          <Ty v="h1New">{continentsData.continent_name}</Ty>
        </Grid2>
      </Grid2Ct>

      {/* rendering the list of countries within the continent. */}
      <Grid2Ct sx={{ mb: 5 }}>
        {continentsData.practice_world_countries.length ? (
          <Countries
            countires={continentsData.practice_world_countries}
            setShowContinentPage={setShowContinentPage}
            setShouldStopRadio={setShouldStopRadio}
            setShuffledFunFacts={setShuffledFunFacts}
            setShowFullOverview={setShowFullOverview}
          />
        ) : (<Grid2 xs={12}><Ty v="h2New" align="center">Hmm, something went wrong. Please try refreshing the page.</Ty></Grid2>)}
      </Grid2Ct>
    </>
  );
};
