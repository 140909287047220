import React, { Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { TeacherProfileQuery } from '../relay/TeacherProfile';
import { TeacherProfileLoader } from './TeacherProfileLoader';
import TeacherNode from './TeacherNode';

export type TeacherProfileProps = {
  tchId: string
  tabToShow: number
  waitingForLoading: boolean
}

const TeacherCard = ({
  tchId,
  tabToShow,
  waitingForLoading
}: TeacherProfileProps) => {
  const fragmentRef = useLazyLoadQuery(
    TeacherProfileQuery,
    { tchId: tchId! },
  );

  return (
    <Suspense fallback={<TeacherProfileLoader />}>
      <TeacherNode
        tchId={tchId}
        tabToShow={tabToShow}
        fragmentRef={fragmentRef}
        waitingForLoading={waitingForLoading}
      />
    </Suspense>
  );
};

export default TeacherCard;
