import React, { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { useSubscription } from 'react-relay';
import { GetUnreadMessagesSubscription } from '../../relay/GetUnreadMessages';
import {
  GetUnreadMessagesSubscription as
  GetUnreadMessagesSubscriptionType
} from '../../relay/__generated__/GetUnreadMessagesSubscription.graphql';
import { setCurrentUnReadMessageCountInRelayStore } from '../../../../common/relay/clientschema/relayappsettings/unReadMessageFunctionality';

export const GetUnreadMessages = () => {
  // #region for `GetUnreadMessagesSubscription` subscription 

  // subsctiption configurations for GetUnreadMessagesSubscription
  const GetUnReadMsg: any = useMemo(() => ({
    // Define a subscription object
    subscription: GetUnreadMessagesSubscription,

    // a callback that is executed when a subscription payload is received
    onNext: (res: any) => {
      if (res.appmessaging_threads_connection.edges.length > 0) {
        let count: number = 0;
        // iterate over each thread in the connection's edges to 
        // identify which threads have unread messages. by checking 
        // each thread's `newest_receivedmessageid` against `read_messageid`, 
        // we can determine whether there are newer messages that haven't been read by the user.
        res.appmessaging_threads_connection.edges.forEach((item: any) => {
          const { newest_receivedmessageid, read_messageid } = item.node;

          if ((read_messageid === null
            && newest_receivedmessageid !== null)
            || (newest_receivedmessageid > read_messageid)
          ) {
            count += 1;
          } else {
            // do nothing as we always initialize our count to 0 and everytime we
            // get subscription notificaiton, we count all unread messages thread again.
          }
        });
        // updating the count of unread messages in the relay store
        setCurrentUnReadMessageCountInRelayStore(count);
      } else {
        // can happen when a new user joins the app, they won't have any
        // threads. So, I think we should not do anything in this case.
        // TODO: Not sure should we log this to sentry, this John will decide later
      }
    },

    // a callback that is executed when the subscription errors.
    onError: (err: any) => {
      // eslint-disable-next-line no-console
      console.log('err in GetUnreadMessages Subscription', err);
      Sentry.captureException(err);
    },

    // a callback that is executed when the server ends the subscription
    onCompleted: () => {
      Sentry.captureMessage('UIS Get Unread Messages Subsription completed!');
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  // executing the subscription for student reservation.
  useSubscription<GetUnreadMessagesSubscriptionType>(GetUnReadMsg);

  // #endregion

  return (
    <div />
  );
};
