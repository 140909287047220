import React, { useContext } from 'react';
import * as Sentry from '@sentry/react';
import {
  ScrollArea, Grid2Ct, Grid2, LottiePlayer, Ty,
} from '@languageconvo/wcl';
import { ClassroomSetupContext } from '../../GroupClassroom';
import { CardContentsPadded } from '../maincontainer/MainContainer.style';
import { V100 } from './components/V100';
import { V202 } from './components/V202';
import { V203 } from './components/V203';
import BirdBye from '../../../../../common/assets/lottie/birdbye.json';
import RabbitLaptop from '../../../../../common/assets/lottie/rabbit_laptop.json';

// various displays within the main card. not including countdown clock, lecture, group chat
export const Pageview = () => {
  const csSetupContext = useContext(ClassroomSetupContext);

  /*
    0
      Display nothing. This should be the value when the classroom is in lesson countdown
      timer mode, group chat mode, or lecture mode.

    1xx (errors)
      100: Mic/cam permissions denied during pageloading
      12x: StuApptgroupClassroomenter errors
        120: GeneralError
        121: LessonNoLongerExists
        122: LessonEnded
        123: LessonTooFarInAdvance
        124: NoReservationLessonEndsSoon
        125: FailedToCreateReservation

    2xx (non-errors)
      200: Initial classroom loading - waiting on device permission acceptance
      201: Initial classroom loading - connecting you to the lesson
      202: User needs to interact with the page so autoplay will work. Display a button
            "Enter Classroom" they can click to force an interaction
      203: Ask user to accept group classroom rules of conduct
      204: Class ended
  */
  if (csSetupContext.pageviewState === 0) {
    return (<div />);
  }

  // #region 1xx errors

  // 100: Mic/cam permissions denied during pageloading
  if (csSetupContext.pageviewState === 100) {
    return (<PageviewScrollcontainer><V100 /></PageviewScrollcontainer>);
  }

  // #region 12x errors for StuApptgroupClassroomenter

  // these occur on pageload, when we attempt to get classroom data via api call

  // GeneralError
  if (csSetupContext.pageviewState === 120) {
    return (
      <StandardLottieScreen
        topsection={(<PageloadRabbitLottie />)}
        bottomsection={(
          <Ty v="h2New" align="center">
            Hmm, something went wrong. Please try refreshing the page, or going back
            to the previous page.
          </Ty>
        )}
      />
    );
  }

  // LessonNoLongerExists
  if (csSetupContext.pageviewState === 121) {
    return (
      <StandardLottieScreen
        topsection={(<PageloadRabbitLottie />)}
        bottomsection={(
          <Ty v="h2New" align="center">
            Our apologies, it looks like this class was canceled due to a technical issue. You can
            go back and join another class at any convenient time.
          </Ty>
        )}
      />
    );
  }

  // LessonEnded
  if (csSetupContext.pageviewState === 122) {
    return (
      <StandardLottieScreen
        topsection={(<LessonendBirdLottie />)}
        bottomsection={(
          <Ty v="h2New" align="center">
            This class has ended
          </Ty>
        )}
      />
    );
  }

  // LessonTooFarInAdvance
  if (csSetupContext.pageviewState === 123) {
    return (
      <StandardLottieScreen
        topsection={(<PageloadRabbitLottie />)}
        bottomsection={(
          <Ty v="h2New" align="center">
            This class starts more than 5 minutes from now. Please return
            once it is starting within 5 minutes.
          </Ty>
        )}
      />
    );
  }

  // NoReservationLessonEndsSoon
  if (csSetupContext.pageviewState === 124) {
    return (
      <StandardLottieScreen
        topsection={(<PageloadRabbitLottie />)}
        bottomsection={(
          <Ty v="h2New" align="center">
            Unfortunately you don&apos;t have a reserved seat in this class, and it
            ends soon or has already ended.
          </Ty>
        )}
      />
    );
  }

  // FailedToCreateReservation
  if (csSetupContext.pageviewState === 125) {
    return (
      <StandardLottieScreen
        topsection={(<PageloadRabbitLottie />)}
        bottomsection={(
          <Ty v="h2New" align="center">
            We had a problem reserving a seat in this class for you. Do you have an
            active subscription? If so, try refreshing the page.
          </Ty>
        )}
      />
    );
  }
  // #endregion

  // #endregion

  // #region 2xx non-errors

  //  IMPORTANT: CoundownClock uses the exact same design, bc it needs to match in order for a
  //  student entering the classroom to see the same design as the class starts. If you edit
  //  the design of the text below, check the countdown clock design to ensure they match
  // 200: Initial classroom loading - waiting on device permission acceptance
  if (csSetupContext.pageviewState === 200) {
    return (
      <StandardLottieScreen
        topsection={(<PageloadRabbitLottie />)}
        bottomsection={(
          <Ty v="h2New" align="center">
            Getting device permissions...
          </Ty>
        )}
      />
    );
  }

  //  IMPORTANT: CoundownClock uses the exact same design, bc it needs to match in order for a
  //  student entering the classroom to see the same design as the class starts. If you edit
  //  the design of the text below, check the countdown clock design to ensure they match
  // 201: Initial classroom loading - connecting you to the lesson
  if (csSetupContext.pageviewState === 201) {
    return (
      <StandardLottieScreen
        topsection={(<PageloadRabbitLottie />)}
        bottomsection={(
          <Ty v="h2New" align="center">
            Connecting to the class...
          </Ty>
        )}
      />
    );
  }

  // 202: User needs to interact with the page so autoplay will work. Display a button
  // "Enter Classroom" they can click to force an interaction
  if (csSetupContext.pageviewState === 202) {
    return (<PageviewNoscrollContainer><V202 /></PageviewNoscrollContainer>);
  }

  // Ask user to accept group classroom rules of conduct
  if (csSetupContext.pageviewState === 203) {
    return (<PageviewScrollcontainer><V203 /></PageviewScrollcontainer>);
  }

  // 204: Class ended
  if (csSetupContext.pageviewState === 204) {
    return (
      <StandardLottieScreen
        topsection={(<LessonendBirdLottie />)}
        bottomsection={(
          <Ty v="h2New" align="center">
            This class has now ended, thank you for attending!
          </Ty>
        )}
      />
    );
  }

  // #endregion

  // log error, should not happen
  // eslint-disable-next-line no-console
  console.log(`Pageview got an unknown value for pageviewState: ${csSetupContext.pageviewState}`);
  Sentry.captureException(
    new Error('Pageview got an unknown value for pageviewState. Needs to be fixed'),
    {
      extra: {
        dt: csSetupContext.pageviewState,
      }
    }
  );
  return (<div />);
};

// #region subcomponents

const PageviewScrollcontainer = ({ children }: any) => (
  <CardContentsPadded>
    <ScrollArea>
      {children}
    </ScrollArea>
  </CardContentsPadded>
);

// the scrollarea doesn't allow us to center things vertically. so we have a version without
// scrolling. exported bc this is used by the class countdown timer (although we should fix
// that so we don't have these weird dependencies)
export const PageviewNoscrollContainer = ({ children }: any) => (
  <CardContentsPadded>
    {children}
  </CardContentsPadded>
);

// This is our commonly used design, with a lottie on top and text on bottom
// exported because this is used in the coundown clock component, to ensure it has the exact
// same design as the "getting device permissions..." and "connecting to the class..."
export const StandardLottieScreen = ({ topsection, bottomsection }: any) => (
  <CardContentsPadded>
    <Grid2Ct
      // this vertically centers the children
      sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
    >
      <Grid2 xs={12}>
        <Grid2Ct>
          {/* lottie */}
          <Grid2 xs={12} sm={10} smOffset={1}>
            {topsection}
          </Grid2>

          {/* text */}
          <Grid2 xs={12} sm={10} smOffset={1} xl={8} xlOffset={2}>
            {bottomsection}
          </Grid2>
        </Grid2Ct>
      </Grid2>
    </Grid2Ct>
  </CardContentsPadded>
);

//  IMPORTANT: this is exported because it's used in CoundownClock. Be sure that if you edit
//  design here, particularly the text of the loading screen, you check there to ensure
//  that it looks the same
// the rabbit lottie used for pageload stuff. we define it here so it can be reused in the many
// places it's needed, which ensures the design (height mainly) is the same everywhere
export const PageloadRabbitLottie = () => (
  <LottiePlayer
    animationSrc={RabbitLaptop}
    animationHeight={250}
  />
);

// the bird lottie used for "lesson ended". we definite it here so it can be reused in all the
// places it's needed, which ensures the deisgn (height and playback speed) is the same everywhere
const LessonendBirdLottie = () => (
  <LottiePlayer
    animationSrc={BirdBye}
    animationHeight={250}
    playbackSpeed={0.4}
  />
);

// #endregion
