import React from 'react';
import {
  Card, CardContent, Grid2, Grid2Ct, Skeleton, Button,
} from '@languageconvo/wcl';

export const TeachersDiscoverLoader = () => (
  <Grid2Ct sx={{ mt: 2 }}>
    <SingleCard />
    <SingleCard />
    <SingleCard />
    <SingleCard />
    <SingleCard />
    <SingleCard />
  </Grid2Ct>
);

// a single card element that represents teacher cards
const SingleCard = () => (
  <Grid2 xs={12} xxl={6}>
    <Card>
      <CardContent>
        <Grid2Ct>
          {/* Skeleton for image */}
          <Grid2 xs={6}>
            <Skeleton variant="rectangular" height={160} />
          </Grid2>

          {/* Skeletons for about, language, and buttons */}
          <Grid2 xs={6}>
            <Skeleton variant="text" />
            <Skeleton variant="text" cp={{ sx: { mt: 1 } }} />
            <Skeleton variant="text" cp={{ sx: { mt: 1 } }} />

            {/* Skeletons for buttons */}
            <Grid2Ct sx={{ pt: 3 }}>
              <Grid2 xs={6}>
                <Button color="accentGreen1" fullWidth disabled>
                &nbsp;
                </Button>
              </Grid2>
              <Grid2 xs={6}>
                <Button color="accentPurple1" fullWidth disabled>
                &nbsp;
                </Button>
              </Grid2>
            </Grid2Ct>
          </Grid2>
        </Grid2Ct>
      </CardContent>
    </Card>
  </Grid2>
);
