import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ScrollArea, Drawer, useTheme, Ic,
} from '@languageconvo/wcl';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// config
import { HEADER } from '../../../config';
import { getStuSettingsFromLCStorage } from '../../../utils/lcStorage';
import navConfig from './NavbarConfig';
// components
import {
  RootStyles, DrawerOverrides, OpencloseAppbarContainer,
} from './NavbarContainer.styles';
import { NavbarInner } from './navbarinner/NavbarInner';

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
};

export const NavbarContainer = ({ isOpenSidebar, onCloseSidebar }: Props) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  /** updatedSideNav react state variable contains info related to sideNav items.
   * if there is no record stored in localStorage, this variable initialized with navConfig object.
   * if localStorage already contains navConfig/sideNav open and close states then we
   * reinitialzed these states by comparing localStorage values with navConfig and put
   * updated sideNav content to updatedSideNav react variable where later we loop and display
   * our sideNav data.
  */
  const [updatedSideNav, setUpdatedSideNav] = useState<any>({ items: [] });

  const { isCollapse, onToggleCollapse } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isDesktop]);

  useEffect(() => {
    const storedValues = getStuSettingsFromLCStorage();

    // Need to persist user lc_tchsettings.
    // if we already have any item in navbar and its previous state was open then
    // we need to show that navbar item as open
    // so, we are updating new updatedNavConfig with the previous saved values
    const storedItems = storedValues?.navbar?.expandedItems;
    const updatedNavConfig = navConfig.items.map((item, index) => ({
      ...item,
      isOpen: (storedItems && storedItems.length && storedItems[index])
        ? storedItems[index].isOpen : item.isOpen
    }));
    setUpdatedSideNav({ items: updatedNavConfig });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSidebar, isDesktop]);

  const renderContent = (
    <>
      {/* wrapping content to our custom scrollbar present in wcl. */}
      <ScrollArea>
        {/* the main content of our left-side navigation */}
        <NavbarInner
          navConfig={updatedSideNav}
          isCollapse={isCollapse}
        />
      </ScrollArea>

      {/* open/close appbar */}
      <OpencloseAppbarContainer>
        {/* 
            onClick will trigger the "onToggleCollapse" function returned from useCollpaseDrawer
            which will toggle the isCollapse state and also store the state's value localStorage.
            So, whenever the user reloads the page he/she will see the same drawer behaviour as
            they set. if they set it to minimized then they will see the minimized version or
            vise versa
        */}
        { isCollapse ? <Ic iconName="circle-caret-right" iconStyle="duotone" color="accentPurple1" size="rel-lg" hovercursor="pointer" onClick={onToggleCollapse} /> : <Ic iconName="circle-caret-left" iconStyle="duotone" color="accentPurple1" size="rel-lg" hovercursor="pointer" onClick={onToggleCollapse} /> }
      </OpencloseAppbarContainer>
    </>
  );

  return (
    // this styled component applying transition and dynamic width
    // based on the collapse prop on desktop screens.
    // In case of drawer collapse, we show icons only view with absolute position.
    <RootStyles
      sx={{
        width: {
          // eslint-disable-next-line max-len
          lg: isCollapse ? theme.ourTheme.components.navbar.navbarWidthCollapse : theme?.ourTheme.components.navbar.navbarWidthExpanded,
        },
        ...(isCollapse && {
          position: 'absolute',
        }),
      }}
    >
      {/* mobile drawer: its default variant is "temporary" which
          auto hide drawer if click outside */}
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: theme?.ourTheme.components.navbar.navbarWidthExpanded,
              backgroundColor: `hsl(${theme.ourTheme.colors.app.navbar})`,
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
      {/* desktop drawer */}
      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              paddingTop: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px`,
              borderRight: 'none',
              ...DrawerOverrides,
              ...(isCollapse && {
                width: theme.ourTheme.components.navbar.navbarWidthCollapse,
              }),
              backgroundColor: `hsl(${theme.ourTheme.colors.app.navbar})`,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyles>
  );
};
