/**
 * @generated SignedSource<<fb28f411ed1d1bbc424c89644d9cee92>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Setup3RoomLayoutSubscription$variables = {
  lessonUuid?: any | null;
};
export type Setup3RoomLayoutSubscription$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RoomLayoutFragment">;
};
export type Setup3RoomLayoutSubscription = {
  response: Setup3RoomLayoutSubscription$data;
  variables: Setup3RoomLayoutSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonUuid"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Setup3RoomLayoutSubscription",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RoomLayoutFragment"
      }
    ],
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Setup3RoomLayoutSubscription",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "lessonUuid"
                  }
                ],
                "kind": "ObjectValue",
                "name": "uuid"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "appt_groupConnection",
        "kind": "LinkedField",
        "name": "appt_group_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appt_groupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "appt_group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "appt_group_layout",
                    "kind": "LinkedField",
                    "name": "appt_group_layout",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "layout",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "appt_group_reservations",
                    "kind": "LinkedField",
                    "name": "appt_group_reservations",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "users",
                        "kind": "LinkedField",
                        "name": "users",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "user_publicinfo",
                            "kind": "LinkedField",
                            "name": "user_publicinfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "uuid",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "display_firstname",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "display_lastname",
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "577931cda6a2ba72bfdb92d762f2d1ca",
    "id": null,
    "metadata": {},
    "name": "Setup3RoomLayoutSubscription",
    "operationKind": "subscription",
    "text": "subscription Setup3RoomLayoutSubscription(\n  $lessonUuid: uuid\n) {\n  ...RoomLayoutFragment\n}\n\nfragment RoomLayoutFragment on subscription_root {\n  appt_group_connection(where: {uuid: {_eq: $lessonUuid}}) {\n    edges {\n      node {\n        appt_group_layout {\n          layout\n          id\n        }\n        appt_group_reservations {\n          users {\n            user_publicinfo {\n              uuid\n              display_firstname\n              display_lastname\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ca076e8008047961090f363d0ef7fab";

export default node;
