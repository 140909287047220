import { commitLocalUpdate } from 'react-relay';
import AppEnvironment from '../../../../relay/AppEnvironment';
import { RelayAppSettingsVals } from '../RelayAppSettingsTypes';

// setting mic states (micName, micId) in relay store.
export const setCurrentMicInRelayStore = (
  micID: string | null,
  micName: string | null,
) => {
  commitLocalUpdate(AppEnvironment, (store) => {
    const currenMicRecord = store.get(RelayAppSettingsVals.currentMicIdVal);
    if (currenMicRecord) {
      currenMicRecord.setValue(
        micID,
        RelayAppSettingsVals.currenMicrophoneIdName
      );
      currenMicRecord.setValue(
        micName,
        RelayAppSettingsVals.currentMicrophoneTitleName
      );
    }
  });
};

// setting mic muted state (mute: true/false) in relay store.
export const setCurrentMicMuteStateInRelayStore = (
  isMuted: boolean
) => {
  commitLocalUpdate(AppEnvironment, (store) => {
    const currenMicRecord = store.get(RelayAppSettingsVals.currentMicIdVal);
    if (currenMicRecord) {
      currenMicRecord.setValue(
        isMuted,
        RelayAppSettingsVals.currentMicrohponeMutedName
      );
    }
  });
};

// setting all available mics in relay store.
export const setAllAvailableMicsInRelayStore = (allMicroPhones: any) => {
  commitLocalUpdate(AppEnvironment, (store) => {
    const allMicrophonesFromRelay = store.get(RelayAppSettingsVals.allMicIdVal);
    if (allMicrophonesFromRelay) {
      const arr = allMicroPhones.map((mic: any) => {
        let rec = store.get(mic.deviceId);
        if (!rec) {
          rec = store.create(mic.deviceId, RelayAppSettingsVals.allMicType);
        }

        rec.setValue(mic.deviceId, 'id');
        rec.setValue(mic.label, 'title');

        return rec;
      });

      allMicrophonesFromRelay.setLinkedRecords(arr, 'edges');
    }
  });
};
