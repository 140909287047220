/**
 * @generated SignedSource<<d4c457ae45d6edcf377a8f932f7aa041>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrivateSchedLsnDataCountteachersFragment$data = {
  readonly resources_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly relayFavorited: ReturnType<typeof resourcesRelayFavoritedResolverType> | null;
      };
    }>;
  };
  readonly " $fragmentType": "PrivateSchedLsnDataCountteachersFragment";
};
export type PrivateSchedLsnDataCountteachersFragment$key = {
  readonly " $data"?: PrivateSchedLsnDataCountteachersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrivateSchedLsnDataCountteachersFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrivateSchedLsnDataCountteachersFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "tutor_visibility": {
              "_eq": "1"
            },
            "working_status": {
              "_is_null": true
            }
          }
        }
      ],
      "concreteType": "resourcesConnection",
      "kind": "LinkedField",
      "name": "resources_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "resourcesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "resources",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "relayFavoritedResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "relayFavorited",
                  "resolverModule": require('./../../../../../../common/relay/resolvers/teachers/relayFavorited').relayFavorited,
                  "path": "resources_connection.edges.node.relayFavorited"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "resources_connection(where:{\"tutor_visibility\":{\"_eq\":\"1\"},\"working_status\":{\"_is_null\":true}})"
    }
  ],
  "type": "query_root",
  "abstractKey": null
};

(node as any).hash = "241c91fff57bbba7d06d52686f9d8f20";

export default node;
