/**
 * @generated SignedSource<<bf44aee38003d21de16b926aa15cd3b2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupLessonsFragment$data = {
  readonly appt_group_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly appt_group_reservations: ReadonlyArray<{
          readonly reserved_at: any;
        }>;
        readonly duration_minutes: number;
        readonly ends_at: any;
        readonly max_students: number;
        readonly num_reservations: number;
        readonly resources: {
          readonly title: string;
        };
        readonly skills: {
          readonly description_en: string;
          readonly display_order: number;
          readonly skills_levels: {
            readonly display_order: number;
            readonly skills_categories: {
              readonly title_en: string;
            };
            readonly title_en: string;
          };
          readonly title_en: string;
          readonly uuid: any;
        };
        readonly starts_at: any;
        readonly uuid: any;
      };
    }>;
  };
  readonly " $fragmentType": "GroupLessonsFragment";
};
export type GroupLessonsFragment$key = {
  readonly " $data"?: GroupLessonsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupLessonsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display_order",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_en",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "currentTime"
    },
    {
      "kind": "RootArgument",
      "name": "endTime"
    },
    {
      "kind": "RootArgument",
      "name": "langId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupLessonsFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "starts_at": "asc"
          }
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_gt",
                  "variableName": "currentTime"
                }
              ],
              "kind": "ObjectValue",
              "name": "ends_at"
            },
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_eq",
                  "variableName": "langId"
                }
              ],
              "kind": "ObjectValue",
              "name": "language_id"
            },
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_lt",
                  "variableName": "endTime"
                }
              ],
              "kind": "ObjectValue",
              "name": "starts_at"
            },
            {
              "kind": "Literal",
              "name": "status",
              "value": {
                "_eq": "0"
              }
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "appt_groupConnection",
      "kind": "LinkedField",
      "name": "appt_group_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "appt_groupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "appt_group",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "starts_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ends_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "duration_minutes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "max_students",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "num_reservations",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "skills",
                  "kind": "LinkedField",
                  "name": "skills",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description_en",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "skills_levels",
                      "kind": "LinkedField",
                      "name": "skills_levels",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "main_skills_categories",
                          "kind": "LinkedField",
                          "name": "skills_categories",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "canceled_at": {
                          "_is_null": true
                        }
                      }
                    }
                  ],
                  "concreteType": "appt_group_reservations",
                  "kind": "LinkedField",
                  "name": "appt_group_reservations",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reserved_at",
                      "storageKey": null
                    }
                  ],
                  "storageKey": "appt_group_reservations(where:{\"canceled_at\":{\"_is_null\":true}})"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "resources",
                  "kind": "LinkedField",
                  "name": "resources",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "subscription_root",
  "abstractKey": null
};
})();

(node as any).hash = "fbe3d296ab372ef3b766e413dc359580";

export default node;
