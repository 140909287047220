import React, { Dispatch, SetStateAction, Suspense } from 'react';
import {
  Dialog,
  Container
} from '@languageconvo/wcl';
import { TableLoader } from '../../../../common/loaders/TableLoader';
import { LangLearningDialog1 } from './LangLearningDialog1';

export interface ModalState {
  openModal: boolean,
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

// Getting user lang which he/she currently learning and higlighting the user lang 
// in a dialog where we are showing list of all language.
export const LangLearningDialog = ({ openModal, setOpenModal }: ModalState) => (
  <Dialog
    isOpen={openModal}
    onClose={() => setOpenModal(!openModal)}
    width="md"
    color="accentOrange1"
  >
    {/* TODO: design suspense */}
    <Suspense fallback={<Container maxWidth="md"> <TableLoader /> </Container>}>
      <LangLearningDialog1 openModal={openModal} setOpenModal={setOpenModal} />
    </Suspense>
  </Dialog>
);
