import { ReactNode } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import palette from './palette';
// import typography from './typography';
import {
  spacing, typography as ourTypography, colors, borders, components,
} from '../ourTheme';
import { ThemeProps } from '../ourTheme/interface';
// import shadows from './shadows';
import breakpoints from './breakpoints';
import { ThemeProvider as CustomThemeProvider } from '../ourTheme/theme';

type Props = {
  children: ReactNode,
  themeMode?: 'light' | 'dark',
  themeDirection?: 'rtl' | 'ltr',
};

// if we want to add extra props to theme other than MUI's default theme
// then we need to do module augmentation, as we are doing below. we need to
// define the interface of our extra prop for both Theme and ThemeOptions.
// interface ThemeOption is used for createTheme method to allow extra prop inside it.
// interface Theme is used for styled components to have that theme type inside it.
declare module '@mui/system' {
  interface BreakpointOverrides {
    // Your custom breakpoints
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    themeMode: string;
    ourTheme: ThemeProps;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    themeMode: string;
    ourTheme: ThemeProps;
  }
}

export function ThemeProvider({ children, themeMode = 'light', themeDirection = 'rtl' }: Props) {
  const isLight = themeMode === 'light';

  const theme = createTheme({
    themeMode: isLight ? 'light' : 'dark',
    // ourTheme will have all the values that are present in our own theme.
    ourTheme: {
      colors: isLight ? colors.light : colors.dark,
      typography: ourTypography,
      borders,
      padding: spacing.padding,
      components,
    },
    palette: isLight ? palette.light : palette.dark,
    breakpoints,
    // typography,
    // This will add border radius to components that don’t have one by default. For example,
    // Button does but Container and Box do not.
    shape: {
      // choose medium because this is the radius used by most of our components
      borderRadius: borders.borderRadius.medium,
    },
    direction: themeDirection,
    // Mui use spacing array to apply margin/padding in numeric syntax
    // e-g: padding: 1 (this will pick the padding from 1st index of spacing array).
    // we have applied this in UIT, so it's important to pass it from here.
    spacing: spacing.spacingArray,
  });

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        {/* to access emotion's theme we need to intialize it's provider here. */}
        <EmotionThemeProvider theme={theme}>
          <CssBaseline />
          <CustomThemeProvider themeMode={themeMode}>
            {children}
          </CustomThemeProvider>
        </EmotionThemeProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
