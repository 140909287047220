import React from 'react';
import {
  CardStandard, Ty, Grid2Ct, Grid2, Link, IcSvgList,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { GridEl } from './Common';
import { sitedata } from '../../../../../../utils/sitedata';

interface Props {
  numunread: number,
}
export const Messages = ({ numunread }: Props) => {
  // link to messages page
  const messagesUrl = sitedata.url.app.messages;
  const clickableTypeObjMessages = {
    to: messagesUrl,
    component: NavLink,
  };

  // change the icon if an unread message
  let icon = IcSvgList.mail1;
  if (numunread > 0) {
    icon = IcSvgList.mailunread1;
  }

  // text, for unread messages
  let msgstr = 'message';
  let tchstr = 'teacher';
  if (numunread > 1) {
    msgstr = 'messages';
    tchstr = 'teachers';
  }

  return (
    <GridEl>
      <CardStandard
        titleText="Messages"
        titleIcon={icon}
        color="accentYellow1"
        cp={{ sx: { width: '100%' } }}
        clickableLink1={{ ...clickableTypeObjMessages }}
      >
        <Link
          to={messagesUrl}
          component={NavLink}
          linkStyle="nostyle"
        >
          <Grid2Ct>
            <Grid2 xs={12} display="flex">
              { numunread > 0 ? (
                <Grid2 xs={12}>
                  <Ty>You have <strong>{numunread} unread {msgstr}</strong> from your {tchstr}!</Ty>
                </Grid2>
              ) : <Ty>Message your teacher 24/7 if you have any questions</Ty>}
            </Grid2>
          </Grid2Ct>
        </Link>
      </CardStandard>
    </GridEl>
  );
};
