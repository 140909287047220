import styled from '@emotion/styled';
import { Select, InputLabel } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { Props } from './interface';

export const CustomSelect = styled(Select)(({ theme, size }: WCLThemeProps & Props) => (
  {
    fontFamily: theme?.ourTheme.typography.fontFamily,
    fontWeight: theme?.ourTheme.typography.p.fontWeight,

    // font color
    // note that this is the color of the fonts inside the dropdown. the label text color is
    // defined separately in InputLabel
    color: `hsl(${theme?.ourTheme.colors.text.base}) !important`,

    // background color
    ...(theme?.themeMode === 'light' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.app.page}, .7)`,
    }),
    ...(theme?.themeMode === 'dark' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.app.page}, .6)`,
    }),

    // sizes
    ...(size === 'small' && {
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.small.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.small.fontSizeRemSmallscrn}rem`,
      },
    }),
    ...(size === 'medium' && {
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem`,
      },
    }),

    // arrow icon color
    ...(theme?.themeMode === 'light' && {
      '.MuiSvgIcon-root': {
        fill: `hsl(${theme?.ourTheme.colors.text.base})`,
      },
    }),
    ...(theme?.themeMode === 'dark' && {
      '.MuiSvgIcon-root': {
        fill: `hsl(${theme?.ourTheme.colors.text.base})`,
      },
    }),

    // #region border outline

    // light theme
    ...(theme?.themeMode === 'light' && {
      // regular border color (not hovered or selected)
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.ourTheme.colors.cp.divider.thin,
      },

      // hovered color
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.ourTheme.colors.cp.divider.base,
      },

      // selected color
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.ourTheme.colors.cp.divider.base,
        // mui by default increases the border width to 2px when the select element is clicked on. this
        // stops that
        borderWidth: '1px',
      },
    }),

    // dark theme
    ...(theme?.themeMode === 'dark' && {
      // regular border color (not hovered or selected)
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.ourTheme.colors.cp.divider.thin,
      },

      // hovered color
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.ourTheme.colors.cp.divider.base,
      },

      // selected color
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.ourTheme.colors.cp.divider.base,
        // mui by default increases the border width to 2px when the select element is clicked on. this
        // stops that
        borderWidth: '1px',
      },
    }),
    // #endregion
  }
));

export const CustomInputLabel = styled(InputLabel)(({ theme }: WCLThemeProps) => (
  {
    fontFamily: theme?.ourTheme.typography.fontFamily,

    // font color
    color: `hsl(${theme?.ourTheme.colors.text.base})`,
    // note that mui changes the font color whenever the select is focused. to stop that from occurring, this
    // is needed
    '&.Mui-focused': {
      color: `hsl(${theme?.ourTheme.colors.text.base})`,
    },
  }
));
