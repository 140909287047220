import { ReactNode } from 'react';
import {
  List,
  LinkProps,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  ListItemButtonProps,
  styled,
  WCLThemeProps,
} from '@languageconvo/wcl';
// config
import { NavLink as RouterLink } from 'react-router-dom';
import { NAVBAR } from '../../../../config';

// #region List

export const ListStyleParent = styled(List)<any>(({
  theme,
}) => ({
  // this centers the icons in the navbar
  paddingLeft: theme?.ourTheme.components.navbar.listPadding,
  // note that padding right doesn't affect anything when the navbar is collapsed, because an
  // explicit width is set for that. but when the navbar is expanded, this gives right padding.
  paddingRight: theme?.ourTheme.components.navbar.listPadding,
}));

// #endregion

// #region ListItemStyle

interface ListItemStyleProps {
  component?: ReactNode | typeof RouterLink;
  to?: string;
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
  isPopoverChild?: boolean;
  isCollapse?: boolean | undefined;
  bgsecondary?: boolean;
}

// styling the listitem for parents. this is Lessons, Practice, Purchase, etc.
export const ListItemStyleParent = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'activeRoot' && prop !== 'activeSub'
  && prop !== 'subItem' && prop !== 'isPopoverChild' && prop !== 'bgsecondary'
})<ListItemStyleProps & LinkProps & ListItemButtonProps & any>(({
  activeRoot, theme, isCollapse, bgsecondary,
}) => ({
  // applies to all elements
  marginTop: theme?.ourTheme.components.navbar.itemMarginTop,
  position: 'relative',
  borderRadius: theme?.ourTheme.borders.borderRadius.medium,
  color: `hsl(${theme?.ourTheme.colors.text.base})`,
  height: theme.ourTheme.components.navbar.itemHeight,
  // sets the padding of the icon inside the green listitem
  paddingLeft: theme?.ourTheme.components.navbar.itemPaddingLeft,
  width: isCollapse ? theme?.ourTheme.components.navbar.itemWidthCollapsed : '',
  fontSize: '18px',

  // hover
  '&:hover': {
    backgroundColor: theme?.ourTheme.colors.cp.itemlist.hoverbg.primary,
  },

  // for the "language learning" item
  ...(bgsecondary && {
    '&:hover': {
      backgroundColor: theme?.ourTheme.colors.cp.itemlist.hoverbg.accentOrange1,
    },
  }),

  // active
  ...(activeRoot && {
    // the active icon is highlighted, same color as the avataricons in the navbar
    backgroundColor: theme?.ourTheme.colors.cp.itemlist.hoverbg.primary,
  }),
}));

// styling the listeitem for the child pages that are *not* in the popover. so this
// is for when the drawer is expanded and you can see child pages via a dropdown list
export const ListItemStyleChild = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem'
  && prop !== 'isPopoverChild'
})<ListItemStyleProps & LinkProps & ListItemButtonProps & any>(({
  activeSub, theme,
}) => ({
  marginTop: theme?.ourTheme.components.navbar.itemExpandedMarginTop,
  position: 'relative',
  borderRadius: theme?.ourTheme.borders.borderRadius.medium,
  color: `hsl(${theme?.ourTheme.colors.text.base})`,
  height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(1.5),
  // marginBottom: theme.spacing(0.5),

  // if this specific child page is the one the user is currently on, it's "active". here
  // we define some styles for that
  ...(activeSub && {
    fontWeight: 600,
  }),

  // hover
  '&:hover': {
    backgroundColor: theme?.ourTheme.colors.cp.itemlist.hoverbg.primary,
  },
}));

// #endregion

// #region ListItemTextStyle

interface ListItemTextStyleProps extends ListItemButtonProps {
  isCollapse?: boolean;
}

// stying the text for parents: the main section headings (Home, Purchase, Schedule, etc.)
export const ListItemTextStyleParent = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isdrawerText',
})<ListItemTextStyleProps & WCLThemeProps>(({ isCollapse }) => ({
  whiteSpace: 'nowrap',
  paddingLeft: '5px',
  paddingRight: '5px',

  // if the drawer is collapsed, we don't show anything
  ...(isCollapse && {
    display: 'none',
    width: 0,
    opacity: 0,
  }),
}));

// styling the text for the child pages that are *not* in the popover. so this
// is for when the drawer is expanded and you can see child pages via a dropdown list
export const ListItemTextStyleChild = styled(ListItemText)<ListItemTextStyleProps
& WCLThemeProps & any>(({ theme }) => ({
  whiteSpace: 'nowrap',
  paddingLeft: theme?.ourTheme.components.navbar.itemExpandedPaddingLeft,
}));

// #endregion

// #region ListItemIconStyle

// drawer icon styling. each of the header icons (Home, Purchase, Schedule, etc)
export const ListItemIconStyle = styled(ListItemIcon)(({ theme }: WCLThemeProps) => ({
  color: `hsl(${theme?.ourTheme.colors.text.base})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme?.ourTheme.components.navbar.iconSize,
  maxWidth: theme?.ourTheme.components.navbar.iconSize,
  minWidth: theme?.ourTheme.components.navbar.iconSize,
  height: theme?.ourTheme.components.navbar.iconSize,
  '& svg': { width: '100%', height: '100%' },
  // pushes the main heading text so that it's not right next to the icon when drawer is expande
  marginRight: theme?.ourTheme.components.navbar.itemMarginRight,
}));

export const ListItemSvgStyle = styled('img')(({ theme }: WCLThemeProps) => ({
  position: 'relative',
  width: theme?.ourTheme.components.navbar.iconSize,
  height: theme?.ourTheme.components.navbar.iconSize,
}));

// #endregion
