import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import {
  Container, Grid2Ct, Card, CardContent,
  Grid2,
  Box,
  Ty,
  Ic,
  Button,
  LottiePlayer,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { AIChatPartnerLoadQuery } from './AIChatPartner0';
import { sitedata } from '../../../utils/sitedata';
import SessionTimeoutAnimation from '../../../common/assets/lottie/pandadeveloper.json';

export const AIChatPartnerContainer = () => {
  // dont allow access to this page in prod
  if (process.env.REACT_APP_ENV === 'prod') {
    throw new Error('no prod access');
  }

  return (
    <Suspense fallback={<div />}>
      {/* TODO: can remove this custom error boundary. remember that we only need a custom
      one if we really must display a custom error message to the user. if we don't need a custom
      message (which we don't here), just let the regular pagelevel error be shown */}
      <Sentry.ErrorBoundary fallback={<AIChatPartnerErrorBoundry />}>
        <AIChatPartnerLoadQuery />
      </Sentry.ErrorBoundary>
    </Suspense>
  );
};

// error boundry in case query fail instead showing page level we are showing 
// this custom error boundry
export const AIChatPartnerErrorBoundry = () => (
  <Container maxWidth="pageContainer">
    <Grid2Ct>
      <Grid2 xs={12}>
        <Card>
          <CardContent>
            <Grid2Ct>
              {/* text, buttons. flex here for equal height on xl */}
              <Grid2 xs={12} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                {/* this flex vertically aligns the content */}
                <Grid2Ct sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    {/* text - header */}
                    <Grid2 xs={12}>
                      <Ty v="h1New" align="center"><Ic color="accentRed1" iconStyle="duotone" iconName="traffic-cone" />&nbsp;&nbsp;Oops - An Error Occurred</Ty>
                    </Grid2>

                    {/* text - we notified the team */}
                    <Grid2 xs={12} sm={8} smOffset={2} lg={6} lgOffset={3} xl={10} xlOffset={1}>
                      <Ty align="center">Something went wrong wrong</Ty>
                    </Grid2>

                    {/* buttons refresh the page, go to home (sm and above) */}
                    <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
                      <Box display="flex" justifyContent="center">
                        <Button
                          color="accentRed1"
                          size="large"
                          onClick={() => window.location.reload()}
                        >
                          Refresh the Page
                        </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          color="accentRed1"
                          size="large"
                          to={sitedata.url.app.home}
                          component={NavLink}
                        >
                          Navigate to Home
                        </Button>
                      </Box>
                    </Grid2>
                    {/* buttons refresh the page, go to home (xs) */}
                    <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                      <Grid2Ct>
                        <Grid2 xs={12}>
                          <Box display="flex" justifyContent="center">
                            <Button
                              color="accentRed1"
                              onClick={() => window.location.reload()}
                            >
                              Refresh the Page
                            </Button>
                          </Box>
                        </Grid2>
                        <Grid2 xs={12}>
                          <Box display="flex" justifyContent="center">
                            <Button
                              color="accentRed1"
                              to={sitedata.url.app.home}
                              component={NavLink}
                            >
                              Navigate to Home
                            </Button>
                          </Box>
                        </Grid2>
                      </Grid2Ct>
                    </Grid2>
                  </Box>
                </Grid2Ct>
              </Grid2>

              {/* lottie animation. flex here for equal height on xl */}
              <Grid2 xs={12} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid2Ct>
                  {/* lottie panda (sm and above) */}
                  <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                      <LottiePlayer
                        animationSrc={SessionTimeoutAnimation}
                        animationHeight={450}
                      />
                    </Box>
                  </Grid2>
                  {/* lottie panda (xs) */}
                  <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                      <LottiePlayer
                        animationSrc={SessionTimeoutAnimation}
                        animationHeight={300}
                      />
                    </Box>
                  </Grid2>
                </Grid2Ct>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2Ct>
  </Container>
);
