/**
 * @generated SignedSource<<dceba8936ffdec22cb6610df55598680>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GetPkgDetailsMutation$variables = {
  invoiceNumber: string;
};
export type GetPkgDetailsMutation$data = {
  readonly stu_orders_getindividualpkgdetails: {
    readonly data: {
      readonly hoursPurchased: number;
      readonly hoursRemaining: number;
      readonly hoursUsed: number;
      readonly invoiceNumber: string;
    };
    readonly success: boolean;
  } | null;
};
export type GetPkgDetailsMutation = {
  response: GetPkgDetailsMutation$data;
  variables: GetPkgDetailsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "invoiceNumber"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "invoiceNumber",
        "variableName": "invoiceNumber"
      }
    ],
    "concreteType": "StuOrdersGetindividualpkgdetailsOutput",
    "kind": "LinkedField",
    "name": "stu_orders_getindividualpkgdetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StuOrdersGetindividualpkgdetailsOutputData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hoursPurchased",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hoursRemaining",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hoursUsed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetPkgDetailsMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetPkgDetailsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9ac3878569d2a6f7ddbea36496724df4",
    "id": null,
    "metadata": {},
    "name": "GetPkgDetailsMutation",
    "operationKind": "mutation",
    "text": "mutation GetPkgDetailsMutation(\n  $invoiceNumber: String!\n) {\n  stu_orders_getindividualpkgdetails(invoiceNumber: $invoiceNumber) {\n    success\n    data {\n      hoursPurchased\n      hoursRemaining\n      hoursUsed\n      invoiceNumber\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5afe2baf2f4fc7741fee853da19f7981";

export default node;
