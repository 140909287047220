import React from 'react';
import * as Sentry from '@sentry/react';
import { Button, Grid2 } from '@languageconvo/wcl';
import { useMutation } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import { StuApptgroupHandraise } from '../../../../relay/StuApptgroupHandraise';
import { ClassroomBasicDataHandRaisValueFragment$data } from '../../../../relay/__generated__/ClassroomBasicDataHandRaisValueFragment.graphql';

interface Props {
  handRaisedData: ClassroomBasicDataHandRaisValueFragment$data
}

// component for students to provide audio feedback during the lesson,
// allowing them to report if they can hear their teacher well or if there are audio issues.
export const AudioFeedback = ({ handRaisedData }: Props) => {
  const [searchParams] = useSearchParams();
  const lsnUuid = searchParams.get('uuid');

  const { id, hand_raised } = handRaisedData;

  // mutation call to submit student feedback related to audio, this will update our db
  // so we can notify the teacher 
  const [commitMutation] = useMutation(StuApptgroupHandraise);

  /**
   * Submits audio feedback from a student regarding their audio experience with the teacher.
   * 
   * This function performs the following steps:
   * 1. Calls a GraphQL mutation to update the feedback data in the backend.
   * 2. Utilizes an optimistic response to update the UI immediately, assuming the 
   * mutation will succeed.
   * 3. Logs any errors that occur during the mutation process to Sentry for further investigation.
   * 
   * @param handRaisValue - The feedback value indicating the student's audio experience:
   *   1 - The student can hear the teacher clearly.
   *   2 - The student cannot hear the teacher at all.
   *   3 - The student can hear the teacher, but the audio is very low.
   */
  const submitAudioFeedback = (handRaisValue: number) => {
    commitMutation({
      variables: {
        lessonUuid: lsnUuid,
        handVal: handRaisValue
      },
      // this creates our optimistic update. we provide the the data we
      // *expect* to be returned by the mutation. whether the mutation succeeds or
      // fails this gets overwritten once the response is received.
      optimisticResponse: {
        stu_apptgroup_handraise: {
          appt_group_reservations: {
            id,
            hand_raised: handRaisValue
          }
        }
      },
      onCompleted() {
        // nothing needs to be done if mutation is successful
      },
      onError(err: any) {
        // note: this erroring will be rare, so we are choosing not to show the user a notif that
        // the mutation failed
        Sentry.captureException(err);
      },
    });
  };

  return (
    <>
      {/* Can't hear at all */}
      <Grid2 xs={12}>
        <Button
          color={hand_raised === 2 ? 'accentPurple1Dark' : 'accentPurple1'}
          fullWidth
          onClick={() => submitAudioFeedback(2)}
        >
          I cannot hear my teacher at all
        </Button>
      </Grid2>

      {/* Can hear, but audio is not good */}
      <Grid2 xs={12}>
        <Button
          color={hand_raised === 3 ? 'accentPurple1Dark' : 'accentPurple1'}
          fullWidth
          onClick={() => submitAudioFeedback(3)}
        >
          I can hear my teacher, but the audio is poor
        </Button>
      </Grid2>

      {/* Can hear well */}
      <Grid2 xs={12}>
        <Button
          color={hand_raised === 1 ? 'accentPurple1Dark' : 'accentPurple1'}
          fullWidth
          onClick={() => submitAudioFeedback(1)}
        >
          I CAN hear my teacher well, great!
        </Button>
      </Grid2>
    </>
  );
};
