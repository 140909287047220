import React, { useState } from 'react';
import {
  Grid2Ct, Grid2, Ty, Button, Box
} from '@languageconvo/wcl';

interface SelectedCountryFunFacts {
  funFactsOfSelectedCountry: any;
}
export const CountryFacts = ({
  funFactsOfSelectedCountry,
}: SelectedCountryFunFacts) => {
  // track the index of the currently displayed fact, we are showing funfact
  // via the help of this state
  const [currentFactIndex, setCurrentFactIndex] = useState(0);

  // state to control loading animation while transitioning between facts
  const [isLoadingNextFunFact, setIsLoadingNextFunFact] = useState(false);

  // #region for fact navigation

  // function to show the next fun fact
  const showNextFunFact = () => {
    // enable loading state to show a transition effect as user click on
    // prev button
    setIsLoadingNextFunFact(true);

    // delay for the transition effect
    setTimeout(() => {
      // this will keep tract of array bounds. we have to ensure we don't ever set this to
      // the lenght of the array (as that index in the array does not exist). like this:
      // (0+1) % 48 = 1
      // (1+1) % 48 = 2
      // .....
      // .....
      //  (47+1) % 48 = 0
      setCurrentFactIndex((prev) => (prev < funFactsOfSelectedCountry.length - 1 ? (prev + 1) : 0));

      // disabled loading state after transition
      setIsLoadingNextFunFact(false);
    }, 200);
  };

  // function to show the previous fun fact
  const showPrevFunFact = () => {
    // enable loading state to show a transition effect as user click on
    // prev button
    setIsLoadingNextFunFact(true);

    // delay for the transition effect
    setTimeout(() => {
      // updating the index to show the previous fact. if on the first fact, the previous
      // one is the one at the end of the list
      setCurrentFactIndex((prev) => (
        prev === 0 ? (funFactsOfSelectedCountry.length - 1) : (prev - 1)
      ));

      // disabled loading state after transition
      setIsLoadingNextFunFact(false);
    }, 200);
  };

  // #endregion

  return (
    // set a min height on this so that the page doesn't "jump" when a fact longer than the
    // previous one is moved to
    <Box sx={{
      minHeight: {
        xs: '580px', sm: '440px', lg: '350px', xl: '300px'
      }
    }}
    >
      {/* title, buttons on small screens, individual fact text */}
      <Grid2Ct>
        {/* title */}
        <Grid2 xs={12} sx={{ mt: 2 }}>
          <Ty v="h2New">Fun Facts</Ty>
        </Grid2>

        {/* next/previous, small screens */}
        <Grid2
          xs={12}
          sx={{
            display: { xs: 'block', sm: 'none' }
          }}
        >
          <Grid2Ct>
            <Grid2 xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* previous */}
              <Button
                onClick={showPrevFunFact}
                color="accentBlue1"
              >
                Previous
              </Button>

              {/* next */}
              <Button
                onClick={showNextFunFact}
                color="accentBlue1"
              >
                Next
              </Button>
            </Grid2>
          </Grid2Ct>
        </Grid2>

        {/* individual fact */}
        <Grid2 xs={12} md={10} mdOffset={1}>
          <Box
            sx={isLoadingNextFunFact ? {
              transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
              transform: 'translateX(100%)',
              opacity: 0,
            } : {
              transition: 'transform 0.2s ease-in, opacity 0.2s ease-in',
              transform: 'translateX(0)',
              opacity: 1,
            }}
          >
            <Ty>
              {' '}
              {funFactsOfSelectedCountry[currentFactIndex].funfact}
            </Ty>
          </Box>
        </Grid2>
      </Grid2Ct>

      {/* next/previous buttons, on large screens */}
      <Grid2Ct
        sx={{
          display: { xs: 'none', sm: 'flex' },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* previous */}
        <Grid2>
          <Button
            onClick={showPrevFunFact}
            color="accentBlue1"
          >
            Previous
          </Button>
        </Grid2>

        {/* next */}
        <Grid2>
          <Button
            onClick={showNextFunFact}
            color="accentBlue1"
          >
            Next
          </Button>
        </Grid2>
      </Grid2Ct>
    </Box>
  );
};
