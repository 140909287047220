import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  Ty, Button, Grid2Ct, Grid2, Box, Ic, LottiePlayer, Card, CardContent, Container,
} from '@languageconvo/wcl';
import { useRouteError, NavLink } from 'react-router-dom';
import { sitedata } from '../../../utils/sitedata';
import SessionTimeoutAnimation from '../../assets/lottie/pandadeveloper.json';

export const PageLevelError = () => {
  const error: any = useRouteError();
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <Container maxWidth="pageContainer">
      <Grid2Ct>
        <Grid2 xs={12}>
          <Card>
            <CardContent>
              <Grid2Ct>
                {/* text, buttons. flex here for equal height on xl */}
                <Grid2 xs={12} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                  {/* this flex vertically aligns the content */}
                  <Grid2Ct sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                      {/* text - header */}
                      <Grid2 xs={12}>
                        <Ty v="h1New" align="center"><Ic color="accentRed1" iconStyle="duotone" iconName="traffic-cone" />&nbsp;&nbsp;Oops - An Error Occurred</Ty>
                      </Grid2>

                      {/* text - we notified the team */}
                      <Grid2 xs={12} sm={8} smOffset={2} lg={6} lgOffset={3} xl={10} xlOffset={1}>
                        <Ty align="center">Patrick our Software Developer Panda made a bit of a mistake. Not to worry, we have notified Pat about the problem.</Ty>
                      </Grid2>

                      {/* buttons refresh the page, go to home (sm and above) */}
                      <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Box display="flex" justifyContent="center">
                          <Button
                            color="accentRed1"
                            size="large"
                            onClick={() => window.location.reload()}
                          >
                            Refresh the Page
                          </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                          <Button
                            color="accentRed1"
                            size="large"
                            to={sitedata.url.app.home}
                            component={NavLink}
                          >
                            Navigate to Home
                          </Button>
                        </Box>
                      </Grid2>
                      {/* buttons refresh the page, go to home (xs) */}
                      <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <Grid2Ct>
                          <Grid2 xs={12}>
                            <Box display="flex" justifyContent="center">
                              <Button
                                color="accentRed1"
                                onClick={() => window.location.reload()}
                              >
                                Refresh the Page
                              </Button>
                            </Box>
                          </Grid2>
                          <Grid2 xs={12}>
                            <Box display="flex" justifyContent="center">
                              <Button
                                color="accentRed1"
                                to={sitedata.url.app.home}
                                component={NavLink}
                              >
                                Navigate to Home
                              </Button>
                            </Box>
                          </Grid2>
                        </Grid2Ct>
                      </Grid2>
                    </Box>
                  </Grid2Ct>
                </Grid2>

                {/* lottie animation. flex here for equal height on xl */}
                <Grid2 xs={12} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Grid2Ct>
                    {/* lottie panda (sm and above) */}
                    <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
                      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                        <LottiePlayer
                          animationSrc={SessionTimeoutAnimation}
                          animationHeight={450}
                        />
                      </Box>
                    </Grid2>
                    {/* lottie panda (xs) */}
                    <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                        <LottiePlayer
                          animationSrc={SessionTimeoutAnimation}
                          animationHeight={300}
                        />
                      </Box>
                    </Grid2>
                  </Grid2Ct>
                </Grid2>
              </Grid2Ct>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2Ct>
    </Container>
  );
};
