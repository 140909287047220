import { graphql } from 'babel-plugin-relay/macro';

// #region mutation to get the data from api call

/* This "mutation" is used to make an api call to hasura, to get trial related data. 
    It doesn't actually mutate anything, but mutation is needed due to Hasura v2
    restrictions.
*/
export const StuSettingsGettrialdataMutation = graphql`
  mutation StuSettingsGettrialdataMutation($languageId: Int!) {
    stu_settings_gettrialdata(languageId: $languageId) {
        ...StuSettingsGettrialdataFragment
      }
    }
`;

export const StuSettingsGettrialdataFragment = graphql`
  fragment StuSettingsGettrialdataFragment on StuSettingsGettrialdataOutput {
    id
    hasCredits
    trial {
      atleastOneTeacherGivesTrials
      hasMissedTwo
      hasUpcoming
      id
      pastCompleted
      pastTrial {
        id
        status
      }
      pastTrialExists
      stepsToSchedule {
        id
        step1
        step2
      }
    }
  }
`;

// #endregion

// #region query to get the data from relay store

/* Use this query to get trial data from the relay store. Note that the mutation above must
    have completed for this to work.
*/
export const StuSettingsGettrialdataClientQuery = graphql`
  query StuSettingsGettrialdataClientQuery {
    RelayAppSettings {
      ...StuSettingsGettrialdataTrialFragment
      ...StuSettingsGettrialdataTeachersAvailibilityFragment
    }
  }
`;

export const StuSettingsGettrialdataTrialFragment = graphql`
  fragment StuSettingsGettrialdataTrialFragment on appsetting {
    trialData {
      hasCredits
      trial {
        pastCompleted
        hasMissedTwo
        hasUpcoming
        stepsToSchedule {
          step1 
          step2
        }
        atleastOneTeacherGivesTrials
        pastTrialExists
        pastTrial {
          status
        }
      }
    }
  }
`;

export const TeachersAvailibilityFragment = graphql`
  fragment StuSettingsGettrialdataTeachersAvailibilityFragment on appsetting {
    trialData {
      trial {
        atleastOneTeacherGivesTrials
      }
    }
  }
`;

// #endregion
