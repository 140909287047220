import React from 'react';
import { useClientQuery } from 'react-relay';
import {
  Grid2Ct, Grid2,
} from '@languageconvo/wcl';
import { BackbuttonDesktop } from './components/BackbuttonDesktop';
import { BackbuttonDesktopDataQuery } from './components/relay/BackbuttonDesktopData';

// left side of the center portion of the top bar. currently contains the back button
export const DesktopTopBarCenterleft = () => {
  // get the backbutton value from the relay store. this is a client-only value in RelayAppSettings
  // which allows us to useClientQuery
  const response: any = useClientQuery(
    BackbuttonDesktopDataQuery,
    {}
  );
  const dt = response.RelayAppSettings;

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        <BackbuttonDesktop backbtn={dt} />
      </Grid2>
    </Grid2Ct>
  );
};
