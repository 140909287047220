import React, { useLayoutEffect, Suspense, useContext } from 'react';
import { Setup2GetClassroomData } from './Setup2GetClassroomData';
import { useVonage } from '../../hooks/useVonage';
import { ClassroomSetupContext } from '../../GroupClassroom';

interface Props {
  setmodalviewContents: any
  setmodalviewState: any
}

// this is our main page which will load and ask for camera and microphone permission
// on permission granted our api call will execute to get the classroom data. 
export const Setup1MicAccess = ({
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  const csSetupContext = useContext(ClassroomSetupContext);

  // via glabal state vairables, checking for permissions
  const {
    accessGranted, demoPublish, vonageFirstInitialized,
  } = useVonage();

  // this useEffect will trigger on pageLoad and ask for camera and microphone permissions.
  // we also set the pageview state to the initial "classroom loading", notes below
  useLayoutEffect(() => {
    // this sets the view to the initial "classroom loading...", even though we already did
    // the same just a few files up. reason being, in Setup0 we may display a different view
    // such as the "click this button to join class" or the "accept group classroom rules"
    csSetupContext.setPageviewState(200);

    // attempt to publish so that the user is prompted to accept cam/mic permissions. note that
    // if there's a general error, we are going to allow the user to continue into the lesson
    // even though, in group chat, they may not be able to publish to other students
    demoPublish();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // display pagelevel error: cam/mic access was denied and then return so that
  // code does not continue
  useLayoutEffect(() => {
    if (!accessGranted && vonageFirstInitialized) {
      csSetupContext.setPageviewState(100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessGranted, vonageFirstInitialized]);

  // don't let the code continue if cam/mic access was denied
  if (!accessGranted && vonageFirstInitialized) {
    return <div />;
  }

  // continue once access to cam/mic has been allowed by the user
  return (
    accessGranted ? (
      <Suspense>
        <Setup2GetClassroomData
          setmodalviewContents={setmodalviewContents}
          setmodalviewState={setmodalviewState}
        />
      </Suspense>
    ) : (null)
  );
};
