import compNavbar from './compNavbar.json';
import pageHome from './pageHome.json';
import pageEarningsHistory from './pageEarningsHistory.json';
import pageSetting from './pageSetting.json';
import accountPopover from './accountPopover.json';
import languagePopover from './languagePopover.json';
import languageLocations from './languageLocations.json';
import purchaseChoose from './purchaseChoose.json';
import purchasePayPage from './purchasePayPage.json';
import groupLesson from './groupLesson.json';
import groupLessonLevel from './groupLessonLevel.json';
import pageTeachersDiscover from './pageTeachersDiscover.json';
import pageTeachersProfile from './pageTeachersProfile.json';
import settingsPageErrorCodes from './settingsPageErrorCodes.json';

export default {
  compNavbar,
  pageHome,
  pageEarningsHistory,
  pageSetting,
  accountPopover,
  languagePopover,
  languageLocations,
  purchaseChoose,
  purchasePayPage,
  groupLesson,
  groupLessonLevel,
  pageTeachersDiscover,
  pageTeachersProfile,
  settingsPageErrorCodes
};
