/**
 * @generated SignedSource<<8cf20fe461086f8ce2dca35863d2d94c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PurchaseChooseFragment$data = {
  readonly lang_learning: number;
  readonly " $fragmentType": "PurchaseChooseFragment";
};
export type PurchaseChooseFragment$key = {
  readonly " $data"?: PurchaseChooseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PurchaseChooseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PurchaseChooseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lang_learning",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "d6eebacc9a1b706c53f9acf5308b7888";

export default node;
