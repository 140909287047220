import styled from '@emotion/styled';
import { Link } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { Props } from './interface';

export const CustomLinkContainer = styled(
  Link,
  {
    // second argument to the styled function is an options object, one of the things it accepts is
    // shouldForwardProp, which as the docs say, "Indicates whether the prop should be forwarded to
    // the Component". So to remove the unknown prop warning from the console, we tell it not to pass
    // our custom prop to the DOM element with shouldForwardProp: (prop) => prop !== "primary"
    shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'linkStyle',
  },
)(({ theme, color, linkStyle }: WCLThemeProps & Props) => (
  {
    // these apply to all variants
    fontFamily: theme?.ourTheme.typography.fontFamily,
    cursor: 'pointer',
    fontWeight: '600',

    // #region colors

    ...(color === 'accentGreen1' && {
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.darker1})`,
    }),
    ...(color === 'accentPurple1' && {
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.darker1})`,
    }),
    ...(color === 'accentBlue1' && {
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.darker1})`,
    }),
    ...(color === 'accentRed1' && {
      color: `hsl(${theme?.ourTheme.colors.accentRed1.darker1})`,
    }),
    ...(color === 'accentOrange1' && {
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.darker1})`,
    }),
    ...(color === 'accentYellow1' && {
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.darker1})`,
    }),

    // #endregion

    // #region colors - deprecated mui colors

    ...(color === 'primary' && {
      color: `hsl(${theme?.ourTheme.colors.text.linkPrimary})`,
    }),
    ...(color === 'secondary' && {
      color: `hsl(${theme?.ourTheme.colors.text.linkSecondary})`,
    }),

    // #endregion

    // if style = 'nostyle'
    ...(linkStyle === 'nostyle' && {
      color: 'inherit',
      fontWeight: 'inherit',
    }),
  }
));
