import { graphql } from 'babel-plugin-relay/macro';

// This fragment contains lesson info i-e audio, video session tokens to connect with streaming
// library
export const LessonDetailFragment = graphql`
  fragment LessonDetailFragment on StuApptgroupClassroomenterOutput {
    av {
      room1 {
        session
        token
      }
      room2 {
        session
        token
      }
      studentGroupRooms {
        session
        token
        uuid
      }
    }
    skillId
    lessonUuid
    pusherChannel
    teacherName
  }
`;
