import styled from '@emotion/styled';
import {
  Card,
} from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { PropsStyle } from './interface';

export const CustomCard = styled(
  Card,
  {
    shouldForwardProp: (prop) => prop !== 'color' && prop !== 'hovercursor' && prop !== 'smallerfont',
  },
)(({
  theme, color, hovercursor, smallerfont,
}: PropsStyle & WCLThemeProps) => (
  {
    boxShadow: 'none',
    border: theme?.ourTheme.borders.mainBorder.border,
    borderRadius: theme?.ourTheme.borders.borderRadius.medium,
    padding: '14px 16px 14px 16px',
    [theme?.breakpoints.up('md') as any]: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem`,
      lineHeight: `${theme?.ourTheme.typography.p.fontSizeRem}rem`,
    },
    [theme?.breakpoints.down('md') as any]: {
      fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem`,
      lineHeight: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem`,
    },
    // here we override the normal p font weight, since we want the text in these cards
    // to stand out
    ...(smallerfont === false && {
      fontWeight: '600',
    }),
    // optionally show some type of cursor on hover
    ...(hovercursor === 'pointer' && {
      cursor: 'pointer',
    }),

    // #region colors

    // card standard color
    ...(color === 'cardstandard' && {
      backgroundColor: theme?.ourTheme.colors.app.container,
    }),

    // accent colors
    ...(theme?.themeMode === 'light' && color === 'accentGreen1' && {
      background: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter3})`,
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.darker1})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentGreen1' && {
      background: `hsla(${theme?.ourTheme.colors.accentGreen1.lighter3}, 75%)`,
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.darker1})`,
    }),

    ...(theme?.themeMode === 'light' && color === 'accentPurple1' && {
      background: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter3})`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.darker1})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentPurple1' && {
      background: `hsla(${theme?.ourTheme.colors.accentPurple1.lighter3}, 75%)`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.darker1})`,
    }),

    ...(theme?.themeMode === 'light' && color === 'accentBlue1' && {
      background: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter3})`,
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.darker1})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentBlue1' && {
      background: `hsla(${theme?.ourTheme.colors.accentBlue1.lighter3}, 75%)`,
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.darker1})`,
    }),

    ...(theme?.themeMode === 'light' && color === 'accentRed1' && {
      background: `hsl(${theme?.ourTheme.colors.accentRed1.lighter3})`,
      color: `hsl(${theme?.ourTheme.colors.accentRed1.darker1})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentRed1' && {
      background: `hsla(${theme?.ourTheme.colors.accentRed1.lighter3}, 75%)`,
      color: `hsl(${theme?.ourTheme.colors.accentRed1.darker1})`,
    }),

    ...(theme?.themeMode === 'light' && color === 'accentOrange1' && {
      background: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter3})`,
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.darker1})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentOrange1' && {
      background: `hsla(${theme?.ourTheme.colors.accentOrange1.lighter3}, 75%)`,
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.darker1})`,
    }),

    ...(theme?.themeMode === 'light' && color === 'accentYellow1' && {
      background: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter3})`,
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.darker1})`,
    }),
    ...(theme?.themeMode === 'dark' && color === 'accentYellow1' && {
      background: `hsla(${theme?.ourTheme.colors.accentYellow1.lighter3}, 75%)`,
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.darker1})`,
    }),

    // #endregion
  }
));

export const SubtextSpan = styled('div')(({ theme }: WCLThemeProps) => ({
  fontWeight: theme?.ourTheme.typography.small.fontWeight,
  [theme?.breakpoints.up('md') as any]: {
    fontSize: `${theme?.ourTheme.typography.small.fontSizeRem}rem`,
  },
  [theme?.breakpoints.down('md') as any]: {
    fontSize: `${theme?.ourTheme.typography.small.fontSizeRemSmallscrn}rem`,
  },
  marginTop: '4px',
  display: 'inline-block',
}));
