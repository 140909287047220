import React, { Suspense, useState, useEffect } from 'react';
import {
  Link, Box, FormControlLabel, CircularProgress,
  TextField, Alert, Checkbox, Button, Ty
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { usePreloadedQuery, useMutation } from 'react-relay';
import { toast } from 'react-toastify';
import * as Style from './index.style';

import { GetEmailPreferences } from './relay/GetEmailPreferences';
// mutations...
import { UpdateEmailPreferences } from './relay/UpdateEmailPreferences';

const EmailPreferences = ({ queryReference, primaryColor }: any) => {
  const [isLoading, setiIsLoading] = useState(false);
  const [UpdateEmailPreferencesMutation] = useMutation(UpdateEmailPreferences);
  // usePreloadedQuery hook used to access data fetched by an earlier call to "loadQuery" or with
  // the help of "useQueryLoader".
  // Call the "loadQuery" callback returned from useQueryLoader. This will store a query reference
  // in React state.
  // Then, in your render method, consume the query reference with usePreloadedQuery(). This call
  // will suspend if the query is still pending, throw an error if it failed, and otherwise return
  // the query results.
  const data = usePreloadedQuery<any>(GetEmailPreferences, queryReference);
  const content = data?.users_connection?.edges;
  const userID = content[0]?.node?.random_id_public;
  const emailPref = content && content[0]?.node?.users_emailunsub;
  const emptyPref = emailPref === null;
  const [state, setState] = useState({
    update_announcements: emptyPref ? true : !!emailPref?.update_announcements,
    getting_started_tips: emptyPref ? true : !!emailPref?.getting_started_tips,
    messages_teacher: emptyPref ? true : !!emailPref?.messages_teacher,
    messages_coach: emptyPref ? true : !!emailPref?.messages_coach,
    account_notices: emptyPref ? true : !!emailPref?.account_notices,
    new_teacher_available: emptyPref ? true : !!emailPref?.new_teacher_available,
    unsubscribed_all: emptyPref ? false : !!emailPref?.unsubscribed_all,
  });

  useEffect(() => {
    if (emailPref?.unsubscribed_all) {
      unsubscribeClick();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailPref]);

  const unsubscribeClick = () => {
    setState({
      update_announcements: false,
      getting_started_tips: false,
      messages_teacher: false,
      messages_coach: false,
      account_notices: false,
      new_teacher_available: false,
      unsubscribed_all: true
    });
  };

  const otherOptionsClick = (key: string) => {
    switch (key) {
      case 'update_announcements':
        setState({
          ...state,
          unsubscribed_all: false,
          update_announcements: !state.update_announcements,
        });
        return;
      case 'getting_started_tips':
        setState({
          ...state,
          unsubscribed_all: false,
          getting_started_tips: !state.getting_started_tips,
        });
        return;
      case 'messages_teacher':
        setState({
          ...state,
          unsubscribed_all: false,
          messages_teacher: !state.messages_teacher,
        });
        return;
      case 'account_notices':
        setState({
          ...state,
          unsubscribed_all: false,
          account_notices: !state.account_notices,
        });
        return;
      case 'new_teacher_available':
        setState({
          ...state,
          unsubscribed_all: false,
          new_teacher_available: !state.new_teacher_available,
        });
        return;
      default:
        setState({
          ...state,
          unsubscribed_all: false,
          messages_coach: !state.messages_coach,
        });
    }
  };

  const handleSave = () => {
    setiIsLoading(true);
    // if unsubscribedAll = false there can be 2 cases.
    // 1- user does not select any checkbox, all checkboxes are not checked.
    // 2- user selected other options...
    let allEmpty = false;
    if (!state.unsubscribed_all) {
      const temp: {unsubscribed_all?: boolean} = { ...state };
      delete temp.unsubscribed_all;
      const areFalsy = Object.values(temp).every((value) => !value);
      if (areFalsy) {
        allEmpty = true;
        unsubscribeClick();
      }
    }
    UpdateEmailPreferencesMutation({
      variables: {
        userRandomId: userID,
        updateAnnouncements: Number(state.update_announcements),
        messagesTeacher: Number(state.messages_teacher),
        messagesCoach: Number(state.messages_coach),
        gettingStartedTips: Number(state.getting_started_tips),
        accountNotices: Number(state.account_notices),
        newTeacherAvailable: Number(state.new_teacher_available),
        unsubscribedAll: allEmpty ? 1 : Number(state.unsubscribed_all),
      },
      onCompleted() {
        setiIsLoading(false);
        toast.success('Changes saved!');
      },
      onError(err: any) {
        setiIsLoading(false);
        toast.error('Hmm, something went wrong, please reload the page and try again.');
        Sentry.captureException(err);
      }
    });
  };

  return (
    // Show this suspense state or wait for the usePreloadedQuery data if query is still pending.
    <Suspense fallback={(
      <Style.ContentLoader>
        <CircularProgress data-testid="circularprogress" sx={{ color: primaryColor }} />
      </Style.ContentLoader>
      )}
    >
      <Style.ContentMain>
        <Box marginTop="40px">
          <Ty v="h2">
            Email Preferences
          </Ty>
          <Style.EmailBox>
            <TextField
              id="user-email"
              type="email"
              label="Email Address"
              value={content[0]?.node?.email}
              disabled
            />
            <Style.AlertBox>
              <Alert severity="info">Not you? <Link href="https://www.languageconvo.com">Click here</Link> to log into your account to update your preferences.
              </Alert>
            </Style.AlertBox>
          </Style.EmailBox>

        </Box>
        <>
          <Style.CheckboxMain>
            <Ty v="h2">Announcement Emails</Ty>
            <FormControlLabel control={<Checkbox checked={state.update_announcements} cp={{ 'data-testid': 'announcements' }} onChange={() => otherOptionsClick('update_announcements')} />} label="Updates and Announcements" />
            <FormControlLabel control={<Checkbox checked={state.getting_started_tips} cp={{ 'data-testid': 'getting-started' }} onChange={() => otherOptionsClick('getting_started_tips')} />} label="Getting Started Resources" />
          </Style.CheckboxMain>
          <Style.CheckboxMain>
            <Ty v="h2">Personal Emails</Ty>
            <FormControlLabel control={<Checkbox checked={state.messages_teacher} cp={{ 'data-testid': 'message_teacher' }} onChange={() => otherOptionsClick('messages_teacher')} />} label="Messages From Your Teacher" />
            <FormControlLabel control={<Checkbox checked={state.messages_coach} cp={{ 'data-testid': 'message_coach' }} onChange={() => otherOptionsClick('messages_coach')} />} label="Learning Coach Messages" />
          </Style.CheckboxMain>
          <Style.CheckboxMain>
            <Ty v="h2">Miscellaneous</Ty>
            <FormControlLabel control={<Checkbox checked={state.account_notices} cp={{ 'data-testid': 'accountNotices' }} onChange={() => otherOptionsClick('account_notices')} />} label="Important Account Notices" />
            <FormControlLabel control={<Checkbox checked={state.new_teacher_available} cp={{ 'data-testid': 'teacherAvaiable' }} onChange={() => otherOptionsClick('new_teacher_available')} />} label="New Teacher Available" />
          </Style.CheckboxMain>
          <Style.UnsubscribeBox>
            <Ty v="h2">Unsubscribe From Everything</Ty>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={state.unsubscribed_all}
                  cp={{ 'data-testid': 'unsubscribe' }}
                  onChange={() => unsubscribeClick()}
                />
              )}
              label="Unsubscribe Me From Everything"
            />
          </Style.UnsubscribeBox>
          <Style.CheckboxMain>
            <Box sx={{ marginBottom: '30px' }}>
              <Button
                size="large"
                variant="contained"
                disabled={isLoading}
                isLoading={isLoading}
                fullWidth
                cp={{ 'data-testid': 'saveBtn' }}
                onClick={handleSave}
              >Save Changes
              </Button>
            </Box>
          </Style.CheckboxMain>
        </>
      </Style.ContentMain>
    </Suspense>
  );
};

export default EmailPreferences;
