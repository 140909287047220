import React from 'react';
import { QuestionIconContainerProps } from './interface';
import { Dialog } from '../../feedback/Dialog';
import { CustomCard } from './index.style';
import { Ic } from '../../datadisplay';

/**
 * DEPRECATED, DO NOT USE
 * Instead, use an AvatarIcon + Dialog component. That allows you the full range of colors and other options
 * that this specialized component did not
* */
export const QuestionIconContainer: React.FunctionComponent<QuestionIconContainerProps> = (props) => {
  const {
    children,
    isOpen = false,
    setIsOpen,
    onClose,
    cp,
    color,
    modalWidth,
  } = props;

  return (
    <>
      <CustomCard onClick={() => setIsOpen(true)}>
        <Ic iconName="question" size="rel-sm" iconStyle="solid" />
      </CustomCard>
      <Dialog
        isOpen={isOpen}
        onClose={onClose!}
        width={modalWidth}
        color={color}
        {...cp}
      >
        {children}
      </Dialog>
    </>
  );
};
