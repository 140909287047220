import React, { ReactNode } from 'react';
import {
  StyledEngineProvider,
  CssBaseline,
  ThemeProvider as LibraryThemeProvider
} from '@languageconvo/wcl';
import useSettings from '../hooks/useSettings';

type Props = {
  children: ReactNode;
};

export const ThemeProvider = ({ children }: Props) => {
  const { themeMode } = useSettings();
  return (
    <StyledEngineProvider injectFirst>
      <LibraryThemeProvider themeMode={themeMode} themeDirection="ltr">
        <CssBaseline />
        {children}
      </LibraryThemeProvider>
    </StyledEngineProvider>
  );
};
