import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { PurchaseChoosePage2 } from './PurchaseChoosePage2';
import { PurchaseChooseQuery } from './relay/PurchaseChoose';

export const PurchaseChoosePage1 = ({ setPaypageLoading }: any) => {
  // dont allow access to this page in prod
  // if (process.env.REACT_APP_ENV === 'prod') {
  //   throw new Error('no prod access');
  // }

  const response: any = useLazyLoadQuery(
    PurchaseChooseQuery,
    { },
  );

  return (
    <PurchaseChoosePage2
      fragmentReference={response}
      setPaypageLoading={setPaypageLoading}
    />
  );
};
