import React, { useState, useEffect } from 'react';
import {
  Box, Card, CardContent, Grid2, Grid2Ct, ScrollArea, Ty, IcSvg,
  IcSvgList, LottiePlayer,
} from '@languageconvo/wcl';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';
import scrollIntoView from 'scroll-into-view-if-needed';
import { InputComponent } from './PracticeWithAITutor1';
import { AITutorLimitReached } from './AITutorLimitReached';
import { AlmostFullInnerHeightMostPages } from '../../../common/components/containers/AlmostFullInnerHeightMostPages';
import { MarkdownContainer, MyQuestionContainer } from './Aitutor.style';
import { AlignIconText } from '../../../common/components/styling/AlignIconText';
import Alice from '../../../common/assets/lottie/characters_alice1.json';

export const PracticeWithAITutor = () => {
  // #region general for variables
  // state variable to hold student's query/question which he/she wants to ask from ai
  // we will send this to our BE end point. 
  const [userInput, setUserInput] = useState<string>('');

  // state to hold all the user's asked question and AI Response, here userType 1
  //  means student's question userType2 show AI response
  const [messages, setMessages] = useState<{ userType: number, content: string }[]>([]);

  // state to hold the threadId, because we have to send it when user ask another question
  // this is because we wanted to let backend know, they should not create a new thread now
  const [threadId, setThreadId] = useState<string | null>(null);

  // state to disable ask question button when our AI modal is giving answer
  const [disableAskQuestionBtn, setdisableAskQuestionBtn] = useState<boolean>(false);

  // state that tells us if/when the user has asked their first question. we'll use this
  // to show/hide some components on the page
  const [firstQuestionAsked, setFirstQuestionAsked] = useState<boolean>(false);

  // state to show streaming message,  
  const [streamingMessage, setStreamingMessage] = useState<string>('');

  // error state for limit reach
  const [isLimitReached, setIsLimitReached] = useState<boolean>(false);

  // #endregion

  // this useEffect will scro/l the user's view to the latest question
  useEffect(() => {
    // getting the last index here, because i have to check if the last entered message
    // has type question, because this useEffect will run for questions and answers.
    // but we only want to scroll when the question is inserted in the UI.
    const lastMessageIndex = messages.length - 1;

    // getting the element to which we have to scroll
    const element = document.getElementById('scroll-to-bottom');

    // this condition is for making sure that the message should not be empty and the userType === 1
    // which means this is a question, also the element to which we have to scroll should not be
    // null or undefined
    if (
      messages.length > 0
      && messages[lastMessageIndex].userType === 1
      && element
    ) {
      scrollIntoView(element, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'end',
      });
    }
  }, [messages]);

  return (
    <Grid2Ct>
      <Grid2 xs={12} xl={10} xlOffset={1}>
        <AlmostFullInnerHeightMostPages minHeight={300}>
          <Card cp={{ sx: { height: '100%' } }}>
            <CardContent>
              {/* area that displays questions/answers */}
              <Box sx={{ height: 'calc(100% - 55px)' }}>
                <ScrollArea height="100%">
                  <Grid2Ct>
                    {/* explain what this is/how to use it. hide this after the user asks
                      their first question */}
                    <Grid2 xs={12} sx={{ display: (firstQuestionAsked ? 'none' : 'block') }}>
                      <Grid2Ct>
                        {/* lottie, different heights for different screens */}
                        <Grid2 xs={12} sx={{ display: { xs: 'none', xl: 'block' }, pb: 0, pt: 0 }}>
                          <LottiePlayer animationSrc={Alice} animationHeight={300} />
                        </Grid2>
                        <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block', xl: 'none' }, pb: 0, pt: 0 }}>
                          <LottiePlayer animationSrc={Alice} animationHeight={250} />
                        </Grid2>
                        <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' }, pb: 0, pt: 0 }}>
                          <LottiePlayer animationSrc={Alice} animationHeight={260} />
                        </Grid2>

                        {/* text */}
                        <Grid2
                          xs={12}
                          sm={8}
                          smOffset={2}
                          lg={8}
                          lgOffset={2}
                          xl={6}
                          xlOffset={3}
                          sx={{ pt: 0 }}
                        >
                          <Ty align="center">
                            I&apos;m Alice, our AI language tutor. Ask me any language-related
                            question, 24/7 ... write your questions in any language!
                          </Ty>
                        </Grid2>
                      </Grid2Ct>
                    </Grid2>

                    {messages.map((msg) => (
                      msg.content.trim()
                        && msg.userType === 1 ? (
                          // user question
                          <Grid2 xs={12} xl={9} xlOffset={3}>
                            <MyQuestionContainer>
                              <Ty>
                                <strong>My Question</strong>
                              </Ty>
                              <Ty removeMb>
                                {msg.content}
                              </Ty>
                            </MyQuestionContainer>
                          </Grid2>
                        ) : msg.content.trim() && (
                          // ai response
                          <ResponseContainer msg={msg.content} />
                        )
                    ))}

                    {/* Render the streaming message only while the assistant is responding */}
                    {!isLimitReached && disableAskQuestionBtn && (
                      <ResponseContainer msg={streamingMessage} />
                    )}

                    {isLimitReached && (
                      <AITutorLimitReached />
                    )}

                    {/* an empty div that has a height (well, has 0 height until user asks their
                      first question). this allows us to scroll the user down to the bottom of the
                      page when they ask a question */}
                    <Grid2 xs={12}>
                      <Box id="scroll-to-bottom" sx={{ height: (firstQuestionAsked ? '150px' : '0px') }}> </Box>
                    </Grid2>
                  </Grid2Ct>
                </ScrollArea>
              </Box>

              {/* user input/ask question */}
              <InputComponent
                userInput={userInput}
                setUserInput={setUserInput}
                setThreadId={setThreadId}
                threadId={threadId}
                setStreamingMessage={setStreamingMessage}
                disableAskQuestionBtn={disableAskQuestionBtn}
                setdisableAskQuestionBtn={setdisableAskQuestionBtn}
                setIsLimitReached={setIsLimitReached}
                setMessages={setMessages}
                setFirstQuestionAsked={setFirstQuestionAsked}
              />
            </CardContent>
          </Card>
        </AlmostFullInnerHeightMostPages>
      </Grid2>
    </Grid2Ct>
  );
};

// container for each ai response
export const ResponseContainer = ({ msg } : any) => (
  <Grid2 xs={12}>
    <AlignIconText>
      <IcSvg icon={IcSvgList.characters_alice1} width="24px" height="24px" />&nbsp;&nbsp;&nbsp;
      <Ty><strong>Alice</strong></Ty>
    </AlignIconText>
    <MarkdownContainer>
      <Markdown remarkPlugins={[remarkGfm]}>{msg}</Markdown>
    </MarkdownContainer>
  </Grid2>
);

// elephant lottie, used for both pageload (when user has not asked a question yet) and
// when the user reaches their usage limit
export const AliceLottie = () => (
  <>
    <Grid2 xs={12} sx={{ display: { xs: 'none', xl: 'block' }, pb: 0, pt: 0 }}>
      <LottiePlayer animationSrc={Alice} animationHeight={300} />
    </Grid2>
    <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block', xl: 'none' }, pb: 0, pt: 0 }}>
      <LottiePlayer animationSrc={Alice} animationHeight={250} />
    </Grid2>
    <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' }, pb: 0, pt: 0 }}>
      <LottiePlayer animationSrc={Alice} animationHeight={260} />
    </Grid2>
  </>
);
