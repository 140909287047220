import {
  Grid2, Grid2Ct, TextField, CardSmallColorIcon, Box,
} from '@languageconvo/wcl';
import React, {
  Dispatch, SetStateAction, useState
} from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { isJsonString } from '../../../utils/isJSONString';
import { getStuSecurityFromLCStorage } from '../../../utils/lcStorage';
import { AiTutorError } from './enum/Errors';
import { Messages } from './enum/Messages';

interface InputComponentProps {
  userInput: string;
  setUserInput: Dispatch<SetStateAction<string>>;
  setThreadId: Dispatch<SetStateAction<string | null>>;
  threadId: string | null;
  setStreamingMessage: Dispatch<SetStateAction<string>>;
  disableAskQuestionBtn: boolean;
  setdisableAskQuestionBtn: Dispatch<SetStateAction<boolean>>;
  setIsLimitReached: Dispatch<SetStateAction<boolean>>;
  setMessages: Dispatch<SetStateAction<any>>;
  setFirstQuestionAsked: Dispatch<SetStateAction<any>>;
}

export const InputComponent = (props: InputComponentProps) => {
  const {
    userInput,
    setUserInput,
    setThreadId,
    threadId,
    setStreamingMessage,
    disableAskQuestionBtn,
    setdisableAskQuestionBtn,
    setIsLimitReached,
    setMessages,
    setFirstQuestionAsked,
  } = props;

  // state to track number of questions user has asked based on it we 
  // send threadId null when user's has asked 15 question in a session
  const [noOfQuestionUserAsked, setNoOfQuestionUserAsked] = useState<number>(0);

  // retrieve the stored student security details to get jwt 
  // token for authentication in later requests.
  const lcData = getStuSecurityFromLCStorage();
  const jwtToken = lcData.jwt;

  // Function to process each line
  const processLine = (line: string) => {
    let returnedValue = '';
    if (line.startsWith('data: ')) {
      // removing 'data: ' prefix recived from BE,as we don't want to show the user's
      const actualData = line.slice(6);

      // checking if the actual data is Json String
      const jsonString = isJsonString(actualData);

      if (jsonString) {
        // if data is in valid json form we are parsing it and extracting message from it
        const jsonData = JSON.parse(actualData);
        // setting threadId which will used when user's asked next question, it will
        // help BE to not always create new thread for each question
        setThreadId(jsonData.threadId);
        if (jsonData.message.content && jsonData.message.content !== '[DONE!]') {
          // storing the response receiving from BE and showing it on the UI in the 
          // form of streaming
          setStreamingMessage((prevMsg: any) => prevMsg + jsonData.message.content);

          returnedValue = jsonData.message.content;
        }
      } else {
        Sentry.captureException(
          new Error('ai tutor - invalid json provided by backend'),
          {
            extra: {
              dt: actualData,
            }
          }
        );
      }
    }

    return returnedValue;
  };

  const handleAskQuestion = async () => {
    if (userInput.trim() !== '' && !disableAskQuestionBtn) {
      // helps us know if the user has asked at least 1 question
      setFirstQuestionAsked(true);

      // clearing input field
      setUserInput('');

      if (noOfQuestionUserAsked >= 15) {
        setNoOfQuestionUserAsked(0);
        setThreadId(null);
      } else {
        setNoOfQuestionUserAsked(noOfQuestionUserAsked + 1);
      }
      // disabled the `Send` button to prevent multiple submissions.
      setdisableAskQuestionBtn(true);

      // hide the message that we have showed to the user's when user 
      // exceed  the limit
      setIsLimitReached(false);

      // maintaining an array of user's question
      setMessages((prevMessages: any) => [
        ...prevMessages,
        { userType: 1, content: userInput },
      ]);
      try {
        // Send POST request and handle the streaming response
        const response = await fetch(`${process.env.REACT_APP_CHATGPT_URL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/event-stream',
            Authorization: `Bearer ${jwtToken}`
          },
          body: JSON.stringify({
            userQuestion: userInput,
            currentThreadId: noOfQuestionUserAsked >= 15 ? null : threadId
          }),
        });

        // we are storing our AI response 
        let streamingResponse = '';

        if (response.ok) {
        // Get reader to read the stream
          const reader = response!.body!.getReader();
          const decoder = new TextDecoder();

          // Loop to continuously read data from the stream
          // eslint-disable-next-line no-constant-condition
          while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader!.read();

            if (done) {
              if (streamingResponse !== '') {
              // eslint-disable-next-line no-loop-func
                setMessages((prevMessages: any) => [
                  ...prevMessages,
                  { userType: 2, content: streamingResponse },
                ]);

                // when BE completes it's response it give us [DONE!] flag
                // as we received [DONE!] we setStreaming message to ' ', so when user asked next 
                // question previous response does not shown on the UI.
                setStreamingMessage('');
              } else {
              // displaying toast to user
                toast.error(Messages.UnExpected);
              }

              // enabling the ask question when response is shown to the user
              // so they can ask next question
              setdisableAskQuestionBtn(false);
              break; // Stop looping when done is true
            }

            // Decode and process the chunk of data
            const chunk = decoder.decode(value, { stream: true });

            // The chunk may have multiple lines; split them
            const lines = chunk.split('\n');

            // Process each line safely outside the loop
            for (let i = 0; i < lines.length; i += 1) {
              streamingResponse += processLine(lines[i]);
            }
          }
        } else {
          // in case of api call failure OR any error occur we are handling here
          const jsonRes = await response.json();

          if (jsonRes.extensions.code === AiTutorError.LimitReached) {
            // making this state true so we can show a  message to inform user
            // that their free limit is exceed
            setIsLimitReached(true);

            // disabled the `Send` button to avoid multiple submissions
            setdisableAskQuestionBtn(false);
          } else if (jsonRes.extensions.code === AiTutorError.InvalidInput) {
          // showing error message to the user
            toast.error(Messages.InvalidInput);
            // enabling the `Send` button so user can again ask qusestion
            setdisableAskQuestionBtn(false);
          } else {
            // showing error message to the user
            toast.error(Messages.UnExpected);
            // enabling the `Send` button so user can again ask qusestion
            setdisableAskQuestionBtn(false);
          }
        }
      } catch (err) {
        Sentry.captureException(err);
        toast.error(Messages.UnExpected);

        // enabling the `Send` button so user can again ask qusestion
        setdisableAskQuestionBtn(false);
      }
    }
  };

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        <Box sx={{ display: 'flex' }}>
          <TextField
            id="user-input"
            placeholder="Ask Alice"
            value={userInput}
            inputCp={{
              maxLength: 300,
              autoComplete: 'off',
            }}
            onChange={(e) => setUserInput(e.target.value)}
            // this is because we want to send the api call even if user pressed enter button
            cp={{
              onKeyDown: (e: any) => {
                if (e.key === 'Enter') {
                  handleAskQuestion();
                }
              }
            }}
          />

          <CardSmallColorIcon
            color="accentGreen1"
            hovercursor="pointer"
            icon={disableAskQuestionBtn ? 'hulahoop1' : 'characters_alice1'}
            onClick={handleAskQuestion}
            cp={{ sx: { ml: 2 } }}
          />
        </Box>
      </Grid2>
    </Grid2Ct>
  );
};
