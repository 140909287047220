import {
  Grid2, Ty, Grid2Ct,
} from '@languageconvo/wcl';
import React from 'react';

interface Props {
  pkgInfo: any
  error: boolean
}

export const OrdersHistoryTableModalPurchdetails2: React.FC<Props> = ({ pkgInfo, error }) => {
  if (error) {
    return null;
  }

  return (
    <>
      {/* sm and larger */}
      <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Grid2Ct>
          <Grid2 xs={6} xsOffset={1}>
            <Ty><strong>Hours Used</strong></Ty>
            <Ty><strong>Hours Remaining</strong></Ty>
          </Grid2>
          <Grid2 xs={5}>
            <Ty>{pkgInfo && pkgInfo.hoursUsed}</Ty>
            <Ty>{pkgInfo && pkgInfo.hoursRemaining}</Ty>
          </Grid2>
        </Grid2Ct>
      </Grid2>

      {/* xs */}
      <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <Grid2Ct>
          {/* hours used */}
          <Grid2 xs={12}>
            <Ty removeMb><strong>Hours Used</strong></Ty>
            <Ty>{pkgInfo && pkgInfo.hoursUsed}</Ty>
          </Grid2>

          {/* hours remaining */}
          <Grid2 xs={12}>
            <Ty removeMb><strong>Hours Remaining</strong></Ty>
            <Ty>{pkgInfo && pkgInfo.hoursRemaining}</Ty>
          </Grid2>
        </Grid2Ct>
      </Grid2>
    </>
  );
};
