import React from 'react';
import {
  CardStandard, Ty, IcSvgList,
} from '@languageconvo/wcl';

interface Props {
  timeInHour: string;
  totalPrice: number;
  purchasePkgLang: any;
}

export const CartDetails = ({
  timeInHour, totalPrice, purchasePkgLang
}: Props) => (
  <CardStandard
    titleText="Shopping Cart"
    titleIcon={IcSvgList.cart2}
    color="accentGreen1"
    titleIconRight={0}
  >
    <Ty align="center" cp={{ sx: { mt: 2 } }}>{purchasePkgLang} Lessons</Ty>
    <Ty align="center">{timeInHour} Hour Package</Ty>
    <Ty align="center">Total Price: ${totalPrice}</Ty>
  </CardStandard>
);
