import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  Ty, Grid2Ct, Grid2, Skeleton,
} from '@languageconvo/wcl';
import { useMutation } from 'react-relay';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { HoursReaminingMutation } from './relay/HoursReaminingMutation';

export const PrivateCredits = () => {
  // #region general setup, react state variables, etc

  // mutation call for getting hours remaining
  const [GetHoursRemaining, isInFlight] = useMutation(HoursReaminingMutation);

  // state to store hoursRemainging after mutation successfully completed 
  // we are showing hoursRemainging on the UI
  const [hoursRemainging, setHoursRemaining] = useState([]);

  const { t } = useTranslation('languageLocations');

  // #endregion

  // #region for mutation call and mutation error handling

  // in case mutation failure of we capturing error and throwing page level error 
  const [error, setError] = useState(false);

  // this useEffect will trigger in case of mutataion failure and we are throwing 
  // error from here beacuse by default mutation does not throw error. 
  useEffect(() => {
    if (error) {
      throw new Error('Something went wrong while trying to get remaining hours');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // here we are executing our mutation to get student orders totalhrsremaining
  useLayoutEffect(() => {
    GetHoursRemaining({
      variables: {},

      // on success, we'll display the hours remaining of each language that the user has
      onCompleted(response: any) {
        setHoursRemaining(response!.stu_orders_totalhrsremaining!.data);
      },

      // for now, we're logging all errors that can occur. especially we're worried about
      // timeouts, because this is calling old php code which is kind of slow. we are worried
      // that it might take more than 5 seconds to run, in which case the user is going to
      // see an error
      onError(err: any) {
        Sentry.captureException(err);
        setError(err);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if the api call is in progress, show loading glimmer
  if (isInFlight) {
    return (
      <Skeleton variant="text" width="100%" cp={{ sx: { mt: 1 } }} />
    );
  }

  // if the user doesnt have any packages at all, display a note about that
  if (hoursRemainging.length === 0) {
    return (
      <Grid2Ct>
        <Grid2 xs={12}>
          <Ty removeMb>
            It looks like you don&apos;t have any private lesson hours available yet
          </Ty>
        </Grid2>
      </Grid2Ct>
    );
  }

  return (
    <Grid2Ct sx={{ mt: 2 }}>
      <Grid2 xs={12}>
        <Grid2Ct>
          {hoursRemainging.map((item: any) => {
            let timeDifference;
            if (item.maxExpiry !== null) {
              // current time is required to caluate the difference with package purchaseTime,
              const currentTime = DateTime.now();

              // purchaseTime is derived from item.maxExpiry. we use it to later check how much
              // time has passed since the package was purchased.
              const purchaseTime = DateTime.fromSeconds(item.maxExpiry);

              // we are calculating the difference in months between the current time 
              // and the purchase time to see if the package was purchased within the
              // last 24 months.
              timeDifference = Math.abs(purchaseTime.diff(currentTime, ['months']).months).toFixed(0);

              // we convert timeDifference from a string to an integer so we 
              // can compare it numerically. If the time difference is 24 months 
              // or less, the language and its total hours remaining 
              // are displayed on the UI.
              if (parseInt(timeDifference, 10) <= 24) {
                return (
                  <>
                    <Grid2 xs={6}>
                      <Ty removeMb>{t(item.langId)}</Ty>
                    </Grid2>
                    <Grid2 xs={6}>
                      <Ty removeMb>{item.totalHours} Hours</Ty>
                    </Grid2>
                  </>
                );
              }
            }

            // we have to render those languages on UI, which has maxExpiry either null
            // or less than 2 years ago, that's why we have this condition here, which checks
            // if maxExpiry is null the display this language and it's hours remaining
            if (item.maxExpiry === null) {
              return (
                <>
                  <Grid2 xs={6}>
                    <Ty removeMb>{t(item.langId)}</Ty>
                  </Grid2>
                  <Grid2 xs={6}>
                    <Ty removeMb>{item.totalHours} Hours</Ty>
                  </Grid2>
                </>
              );
            }

            // we are returing null because map expects a value to be returned at the end, if 
            // we don't add return here, we will get ts errors.
            return null;
          }) }
        </Grid2Ct>
      </Grid2>
    </Grid2Ct>
  );
};
