/**
 * @generated SignedSource<<62a0e862aad9878aefdbdd785582c391>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageloadRequiredsettingsFragment$data = {
  readonly timezone_set: number;
  readonly " $fragmentType": "PageloadRequiredsettingsFragment";
};
export type PageloadRequiredsettingsFragment$key = {
  readonly " $data"?: PageloadRequiredsettingsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PageloadRequiredsettingsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PageloadRequiredsettingsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone_set",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "1988f2fcc16832be630a1e171fb7ab8f";

export default node;
