import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { PretrialDataStusettingsQuery } from '../../../../relay/posttrial/PretrialData';
import { StepsToSchedule3Scenario } from './StepsToSchedule3Scenario';

export const StepsToSchedule2Getdata = () => {
  /* note: we don't get this data in the applevel query, which means this query will very likely run
      when the user opens the Lessons page and is in this trial process. the reasons are:
        1. there's already a query that runs every single time the Lessons page loads and the user
            is in this trial process. this query will run in parallel with that one, so this query
            does not slow the pageload down much
        2. for users that are not in the trial process, which is the majority of users, we dont want
            to burden their browser with more data that's not needed
  */
  const response: any = useLazyLoadQuery(
    PretrialDataStusettingsQuery,
    { }
  );
  const userDt = response.users_connection.edges[0].node;

  return (
    <StepsToSchedule3Scenario usr={userDt} />
  );
};
