import React from 'react';
import {
  Grid2, Ty,
} from '@languageconvo/wcl';
import { AliceLottie } from './PracticeWithAITutor0';

export const AITutorLimitReached = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <>
      <AliceLottie />
      <Grid2 xs={12} sm={10} smOffset={1} lg={8} lgOffset={2}>
        <Ty align="center">
          <strong>Free Usage Limit Reached</strong>
        </Ty>
        <Ty align="center">
          It looks like you have reached the free usage limit for this month. Students
          who have purchased private lessons in the past year, or are subscribed to
          group classes, get unlimited use!
        </Ty>
      </Grid2>
    </>
  );
};
