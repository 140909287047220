import React from 'react';
import {
  Grid2Ct, Grid2, Card, CardContent, Skeleton, Box,
} from '@languageconvo/wcl';
import { LoadingBox } from './Loading.style';

// loading state
export const Loading = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <Grid2Ct sx={{ mt: 1 }}>
      <IndividualCard theKey={1} />
      <IndividualCard theKey={2} />
      <IndividualCard theKey={3} />
      <IndividualCard theKey={4} />
      <IndividualCard theKey={5} />
      <IndividualCard theKey={6} />
      <IndividualCard theKey={7} />
      <IndividualCard theKey={8} />
    </Grid2Ct>
  );
};

const IndividualCard = (props: any) => {
  const keyId = props.theKey;

  return (
    <Grid2 xs={12} key={keyId}>
      <Card>
        <CardContent>
          <LoadingBox>
            <Grid2Ct>
              <Grid2 xs={12} md={5}>
                <Box>
                  <Skeleton />
                </Box>
                <Box sx={{ mt: '10px' }}>
                  <Skeleton />
                </Box>
                <Box sx={{ mt: '10px' }}>
                  <Skeleton />
                </Box>
              </Grid2>
              <Grid2 xs={12} md={3} mdOffset={4}>
                <Skeleton
                  variant="rectangular"
                  height="42px"
                />
              </Grid2>
            </Grid2Ct>
          </LoadingBox>
        </CardContent>
      </Card>
    </Grid2>
  );
};
