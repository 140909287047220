import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import {
  Grid2, Grid2Ct,
} from '@languageconvo/wcl';
import { TeachersDiscoverDataQuery } from '../relay/TeachersDiscoverData';
import { DiscoverTeacherlist } from './Discover1Teacherlist';

export const DiscoverGetdata = () => {
  const response: any = useLazyLoadQuery(
    TeachersDiscoverDataQuery,
    { }
  );

  return (
    <>
      {/* list of teachers */}
      <Grid2Ct sx={{ mt: 2 }}>
        <Grid2 xs={12}>
          <Grid2Ct>
            <DiscoverTeacherlist
              tchrs={response}
              langlearn={response.users_connection.edges[0].node}
            />
          </Grid2Ct>
        </Grid2>
      </Grid2Ct>
    </>
  );
};
