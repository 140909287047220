import { graphql } from 'babel-plugin-relay/macro';

export const MobileLanglearningDataQuery = graphql`
  query MobileLanglearningDataQuery {
    users_connection {
      edges {
        node {
          ...MobileLanglearningDataFragment
        }
      }
    }
  }
`;

export const MobileLanglearningDataFragment = graphql`
  fragment MobileLanglearningDataFragment on users {
    lang_learning
  }
`;
