/**
 * @generated SignedSource<<84ae8789cb28a235bbff5715f5e06430>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuSubsCreateportalurlMutation$variables = {};
export type StuSubsCreateportalurlMutation$data = {
  readonly stu_subs_createportalurl: {
    readonly errors: ReadonlyArray<string | null>;
    readonly portalUrl: string;
    readonly success: boolean;
  } | null;
};
export type StuSubsCreateportalurlMutation = {
  response: StuSubsCreateportalurlMutation$data;
  variables: StuSubsCreateportalurlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StuSubsCreateportalurlOutput",
    "kind": "LinkedField",
    "name": "stu_subs_createportalurl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "portalUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StuSubsCreateportalurlMutation",
    "selections": (v0/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StuSubsCreateportalurlMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8ec269d9b183f45a3b2dba773393516d",
    "id": null,
    "metadata": {},
    "name": "StuSubsCreateportalurlMutation",
    "operationKind": "mutation",
    "text": "mutation StuSubsCreateportalurlMutation {\n  stu_subs_createportalurl {\n    portalUrl\n    errors\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "f39d4cc3984f7b4c48c0431b63023523";

export default node;
