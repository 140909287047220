import { graphql } from 'babel-plugin-relay/macro';

export const StuSettingsUpdatetrialstep3GetavailabilityMutation = graphql`
  mutation StuSettingsUpdatetrialstep3GetavailabilityMutation (
    $languageId: Int!
  ) {
    stu_appt_gettrialavailability(
      languageId: $languageId
    ) {
      teacherIsAvailable
      schedule
    }
  }
`;
