import {
  styled, Box, WCLThemeProps,
} from '@languageconvo/wcl';

// xs
// styling the items in our navtabs component
export const NavtabItemContainerXs = styled(Box)<WCLThemeProps>(({ theme }) => ({
  paddingTop: '6px',
  paddingBottom: '6px',
  paddingLeft: '8px',
  paddingRight: '8px',
  borderRadius: '11px',
  fontSize: '13px',
  textAlign: 'center',
  '&.selected': {
    backgroundColor: theme.ourTheme.colors.cp.itemlist.hoverbg.primary,
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.ourTheme.colors.cp.itemlist.hoverbg.primary,
  },
}));

// sm and larger
// styling the items in our navtabs component
export const NavtabItemContainer = styled(Box)<WCLThemeProps>(({ theme }) => ({
  paddingTop: '12px',
  paddingBottom: '12px',
  [theme!.breakpoints.down('md')]: {
    paddingTop: '8px',
    paddingBottom: '8px',
    fontSize: '14px',
  },
  paddingLeft: '20px',
  paddingRight: '20px',
  borderRadius: '11px',
  fontSize: '16px',
  textAlign: 'center',
  '&.selected': {
    backgroundColor: theme.ourTheme.colors.cp.itemlist.hoverbg.primary,
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.ourTheme.colors.cp.itemlist.hoverbg.primary,
  },
}));

// our wcl CardContent component has larger padding than we want for our navtabs. here we recreate
// the CardContent component just with smaller padding
export const NavtabCardContent = styled(Box)(({ theme }: WCLThemeProps) => ({
  padding: '6px',
  [theme!.breakpoints.only('sm')]: {
    padding: '9px',
  },
  [theme!.breakpoints.up('md')]: {
    padding: '12px',
  },
  width: '100%',
}));
