import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import {
  Grid2, Grid2Ct, useTheme, Card,
} from '@languageconvo/wcl';
import { CardContentNopadding } from '../../../common/components/card/Card';

export const LegacyTipteacher = () => {
  const theme = useTheme();
  const br = theme?.ourTheme.borders.borderRadius.medium;

  return (
    <Grid2Ct>
      {/* main content */}
      <Grid2 xs={12}>
        <Grid2Ct>
          <Grid2 xs={12}>
            <Card>
              <CardContentNopadding>
                <IframeResizer
                  id="tipTeacherFrame"
                  src={`${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=customer/purchase/tipteacher/`}
                  style={{
                    width: '1px',
                    minWidth: '100%',
                    minHeight: '90vh',
                    borderRadius: `${br}px`,
                    border: 'none',
                  }}
                />
              </CardContentNopadding>
            </Card>
          </Grid2>
        </Grid2Ct>
      </Grid2>
    </Grid2Ct>
  );
};
