import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useClientQuery, useLazyLoadQuery } from 'react-relay';
import { Setup0BasicData1Termsprivacy } from './Setup0BasicData1Termsprivacy';
import { ClientonlySchemaQuery } from '../../relay/ClientonlySchema';
import { ClassroomBasicDataQuery } from '../../relay/ClassroomBasicData';

interface Props {
  setmodalviewContents: any
  setmodalviewState: any
}

// make standard api call to get some basic data needed for the group classroom, as well
// as some client-only data that's needed
export const Setup0BasicData = ({
  setmodalviewContents,
  setmodalviewState,
}: Props) => {
  // get client schema data - has the user interacted with the app?
  const response: any = useClientQuery(
    ClientonlySchemaQuery,
    {}
  );
  const clientDt = response.RelayAppSettings;

  // get basic data via api call
  const [searchParams] = useSearchParams();
  const lsnUuid = searchParams.get('uuid');
  const res: any = useLazyLoadQuery(
    ClassroomBasicDataQuery,
    { lessonUuid: lsnUuid },
  );

  return (
    <Setup0BasicData1Termsprivacy
      clientdt={clientDt}
      termsprivacy={res}
      setmodalviewContents={setmodalviewContents}
      setmodalviewState={setmodalviewState}
    />
  );
};
