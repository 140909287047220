import { AxiosWrapper } from '../common/utils/AxiosWrapper/AxiosWrapper';

export const handleJWTCookie = async (payload: {action: string, data?: {jwt: string}}) => {
  const response = await fetch(`${process.env.REACT_APP_LEGACYURL}/studenthome/external/`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      domain: 'external',
      ...payload
    }),
  });

  const jsonResponse = await response.json();

  if (Array.isArray(jsonResponse.errors)) {
    throw new Error(
      `Error setting Cookie: ${JSON.stringify(
        jsonResponse.errors,
      )}`,
    );
  }

  return jsonResponse;
};

/**
 * This will make an API call to our legacy app endpoint which will delete user's ntssess_ cookie
 * hence logging user out of our legacy app
 * Note: This function needs be called everywhere we log user out of our react app so that user is
 * logged out of both legacy app and react app
 */
export const handleLogoutAndRemoveRefreshToken = async () => {
  const params = JSON.stringify({
    domain: 'external',
    action: 'unsetStudentLoginCookie',
  });

  const axiosWrapper = new AxiosWrapper();
  await axiosWrapper.postApiRequest(
    'studenthome/external/',
    {
      baseURL: `${process.env.REACT_APP_LEGACYURL}`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
    params,
  );
};
