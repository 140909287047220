import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';
import { Props } from './interface';

export const CustomButton = styled(Button)(({
  theme, color, variant, disabled, size,
}: Props & WCLThemeProps) => {
  return ({
    // TODO: see accentPurple1, the hover styles in that color. it fixes a "feature" on
    // mobile devices that hover color is applied when an item is clicked. that looks weird, so
    // we are *not* adding hover color on smaller devices. we need to do this to ALL colors

    // applies to all variants
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    fontFamily: theme?.ourTheme.typography.fontFamily,
    transition: theme?.ourTheme.components.transition.item,

    // font sizes
    ...(size === 'large' && {
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.subheading.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.subheading.fontSizeRemSmallscrn}rem`,
      },
    }),
    ...(size === 'medium' && {
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.p.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.p.fontSizeRemSmallscrn}rem`,
      },
    }),
    ...(size === 'small' && {
      [theme?.breakpoints.up('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.small.fontSizeRem}rem`,
      },
      [theme?.breakpoints.down('md') as any]: {
        fontSize: `${theme?.ourTheme.typography.small.fontSizeRemSmallscrn}rem`,
      },
    }),

    // padding
    // the outlined version have a border which is 0.8px on each side. to get our contained button
    // to be the *exact* same size, we add 0.8 to the padding
    ...(size === 'small' && variant === 'outlined' && {
      padding: '6px 10px 6px 10px',
    }),
    ...(size === 'small' && variant === 'contained' && {
      padding: '6.8px 10.8px 6.8px 10.8px',
    }),
    ...(size === 'medium' && variant === 'outlined' && {
      padding: '8px 16px 8px 16px',
    }),
    ...(size === 'medium' && variant === 'contained' && {
      padding: '8.8px 16.8px 8.8px 16.8px',
    }),
    ...(size === 'large' && variant === 'outlined' && {
      padding: '10px 22px 10px 22px',
    }),
    ...(size === 'large' && variant === 'contained' && {
      padding: '10.8px 22.8px 10.8px 22.8px',
    }),

    //
    // style customization - every variant+color combination needed
    //
    // below, we style *every* theme+color+variant combination. why take the time to do this? two reasons. one, it
    // ensures we're using our custom colors instead of mui taking one of our base colors and ligtening or darkening it.
    // two, in future we can convert the button to a headless componenet and we'll have most of our styles ready

    // #region legacy MUI colors
    // #region contained

    // #region contained - light

    ...(theme?.themeMode === 'light' && disabled === false && color === 'primary' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.primary.base})`,
      color: `hsl(${theme?.ourTheme.colors.primary.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.primary.darker1})`,
        color: `hsl(${theme?.ourTheme.colors.primary.lightest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'secondary' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.secondary.base})`,
      color: `hsl(${theme?.ourTheme.colors.secondary.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.secondary.darker1})`,
        color: `hsl(${theme?.ourTheme.colors.secondary.lightest})`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'info' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.info.base})`,
      color: `hsl(${theme?.ourTheme.colors.info.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.info.darker1})`,
        color: `hsl(${theme?.ourTheme.colors.info.lightest})`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'error' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.error.base})`,
      color: `hsl(${theme?.ourTheme.colors.error.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.error.darker1})`,
        color: `hsl(${theme?.ourTheme.colors.error.lightest})`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'warning' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.warning.base})`,
      color: `hsl(${theme?.ourTheme.colors.warning.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.warning.darker1})`,
        color: `hsl(${theme?.ourTheme.colors.warning.lightest})`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'neutral' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.neutral.base})`,
      color: `hsl(${theme?.ourTheme.colors.neutral.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.neutral.darker1})`,
        color: `hsl(${theme?.ourTheme.colors.neutral.lightest})`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region contained - dark

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'primary' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.primary.base})`,
      color: `hsl(${theme?.ourTheme.colors.primary.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.primary.darker1})`,
        color: `hsl(${theme?.ourTheme.colors.primary.lightest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'secondary' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.secondary.base})`,
      color: `hsl(${theme?.ourTheme.colors.secondary.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.secondary.darker1})`,
        color: `hsl(${theme?.ourTheme.colors.secondary.lightest})`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'info' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.info.base})`,
      color: `hsl(${theme?.ourTheme.colors.info.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.info.darkest})`,
        color: `hsl(${theme?.ourTheme.colors.info.lightest})`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'error' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.error.base})`,
      color: `hsl(${theme?.ourTheme.colors.error.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.error.darkest})`,
        color: `hsl(${theme?.ourTheme.colors.error.lightest})`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'warning' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.warning.base})`,
      color: `hsl(${theme?.ourTheme.colors.warning.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.warning.darkest})`,
        color: `hsl(${theme?.ourTheme.colors.warning.lightest})`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'neutral' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.neutral.base})`,
      color: `hsl(${theme?.ourTheme.colors.neutral.lightest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.neutral.darkest})`,
        color: `hsl(${theme?.ourTheme.colors.neutral.lightest})`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region contained - disabled

    // #region contained - light - disabled

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'primary' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'secondary' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.secondary.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.secondary.lighter1})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.secondary.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.secondary.lighter1})  !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'info' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.info.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.info.lighter1})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.info.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.info.lighter1})  !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'error' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.error.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.error.lighter1})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.info.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.error.lighter1})  !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'warning' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.warning.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.warning.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.warning.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.warning.base})  !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'neutral' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.neutral.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.neutral.lighter1})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.neutral.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.neutral.lighter1})  !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region contained - dark - disabled

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'primary' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.primary.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.primary.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.primary.base})  !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'secondary' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.secondary.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.secondary.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.secondary.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.secondary.base})  !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'info' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.info.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.info.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.info.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.info.base})  !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'error' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.error.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.error.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.info.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.error.base})  !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'warning' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.warning.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.warning.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.warning.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.warning.base})  !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'neutral' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.neutral.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.neutral.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.neutral.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.neutral.base})  !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #endregion

    // #endregion

    // #region outlined

    // #region outlined - light

    ...(theme?.themeMode === 'light' && disabled === false && color === 'primary' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.primary.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.primary.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.primary.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.primary.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.primary.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'secondary' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.secondary.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.secondary.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.secondary.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.secondary.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.secondary.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'info' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.info.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.info.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.info.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.info.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.info.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'error' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.error.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.error.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.error.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.error.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.error.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'warning' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.warning.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.warning.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.warning.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.warning.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.warning.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'neutral' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.neutral.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.neutral.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.neutral.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.neutral.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.neutral.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region outlined - dark

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'primary' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.primary.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.primary.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.primary.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'secondary' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.secondary.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.secondary.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.secondary.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.secondary.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.secondary.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'info' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.info.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.info.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.info.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.info.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.info.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'error' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.error.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.error.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.error.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.error.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.error.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'warning' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.warning.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.warning.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.warning.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.warning.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.warning.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'neutral' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.neutral.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.neutral.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.neutral.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.neutral.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.neutral.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region outlined - disabled

    // #region outlined - disabled - light

    ...(theme?.themeMode === 'light' && disabled === true && color === 'primary' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'secondary' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.secondary.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.secondary.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.secondary.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.secondary.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'info' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.info.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.info.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.info.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.info.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'error' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.error.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.error.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.error.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.error.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'warning' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.warning.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.warning.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.warning.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.warning.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'neutral' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.neutral.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.neutral.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.neutral.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.neutral.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region outlined - disabled - dark

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'primary' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.primary.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'secondary' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.secondary.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.secondary.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.secondary.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.secondary.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'info' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.info.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.info.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.info.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.info.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'error' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.error.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.error.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.error.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.error.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'warning' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.warning.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.warning.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.warning.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.warning.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'neutral' && variant === 'outlined' && {
      borderColor: `hsl(${theme?.ourTheme.colors.neutral.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.neutral.lighter1}) !important`,
      '&:hover': {
        borderColor: `hsl(${theme?.ourTheme.colors.neutral.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.neutral.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #endregion

    // #endregion
    // #endregion

    // #region accentGreen1

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentGreen1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.darker1})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1})`,
        color: `hsl(${theme?.ourTheme.colors.accentGreen1.darkest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentGreen1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1})`,
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentGreen1.base})`,
        color: `hsl(${theme?.ourTheme.colors.accentGreen1.darkest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'accentGreen1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'accentGreen1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentGreen1.base})  !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentGreen1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentGreen1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentGreen1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentGreen1.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentGreen1.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentGreen1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentGreen1.base}, 0.2) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentGreen1.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentGreen1.base}, 0.3) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'accentGreen1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentGreen1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentGreen1.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'accentGreen1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentGreen1.base}, 0.1) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentGreen1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentGreen1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region accentPurple1

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentPurple1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.darker1})`,
      // TODO: this same hover setup should be applied to ALL our buttons. this fixes a "feature" on
      // mobile devices that hover color is applied when an item is clicked. that looks weird, so here
      // we are *not* adding hover color on smaller devices
      '&:hover': {
        boxShadow: 'none',
        background: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter2})`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.darker1})`,
      },
      [theme?.breakpoints.up('lg') as any]: {
        '&:hover': {
          background: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1})`,
          color: `hsl(${theme?.ourTheme.colors.accentPurple1.darkest})`,
          // note: for some reason to override mui defaults, we do have to put this here even though we have it above
          // supposedly applying to *all* variants
          boxShadow: 'none',
        },
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentPurple1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1})`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.darkest})`,
      '&:hover': {
        boxShadow: 'none',
        background: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1})`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.darkest})`,
      },
      [theme?.breakpoints.up('lg') as any]: {
        '&:hover': {
        // IMPORTANT: this is different than other accent colors, because our dark background is purple. if
        //  we didn't customize this, it's too hard to see
          background: `hsla(${theme?.ourTheme.colors.accentPurple1.lighter1}, .7)`,
          color: `hsl(${theme?.ourTheme.colors.accentPurple1.darkest})`,
          // note: for some reason to override mui defaults, we do have to put this here even though we have it above
          // supposedly applying to *all* variants
          boxShadow: 'none',
        },
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'accentPurple1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      },
      [theme?.breakpoints.up('lg') as any]: {
        '&:hover': {
          backgroundColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter2}) !important`,
          color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
          // note: for some reason to override mui defaults, we do have to put this here even though we have it above
          // supposedly applying to *all* variants
          boxShadow: 'none',
        },
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'accentPurple1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.base})  !important`,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.base})  !important`,
      },
      [theme?.breakpoints.up('lg') as any]: {
        '&:hover': {
          backgroundColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
          color: `hsl(${theme?.ourTheme.colors.accentPurple1.base})  !important`,
          // note: for some reason to override mui defaults, we do have to put this here even though we have it above
          // supposedly applying to *all* variants
          boxShadow: 'none',
        },
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentPurple1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.base}) !important`,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.base}) !important`,
      },
      [theme?.breakpoints.up('lg') as any]: {
        '&:hover': {
          backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.1) !important`,
          borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.darker1}) !important`,
          color: `hsl(${theme?.ourTheme.colors.accentPurple1.darker1}) !important`,
          boxShadow: 'none',
        },
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentPurple1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.3) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.3) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      },
      [theme?.breakpoints.up('lg') as any]: {
        '&:hover': {
          backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.3) !important`,
          borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter2}) !important`,
          color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter2}) !important`,
          boxShadow: 'none',
        },
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'accentPurple1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      },
      [theme?.breakpoints.up('lg') as any]: {
        '&:hover': {
          backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.05) !important`,
          borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
          color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
          boxShadow: 'none',
        },
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'accentPurple1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.1) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      },
      [theme?.breakpoints.up('lg') as any]: {
        '&:hover': {
          backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.1) !important`,
          borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
          color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
          boxShadow: 'none',
        },
      },
    }),

    // #endregion

    // #region accentPurple1Dark

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentPurple1Dark' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentPurple1.darker1})`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter3})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1})`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.lightest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentPurple1Dark' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentPurple1.base})`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter2})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1})`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.lightest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'accentPurple1Dark' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.base}) !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'accentPurple1Dark' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.base})  !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    // TODO: outlined variant for accentPurple1Dark is NOT done
    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentPurple1Dark' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentPurple1Dark' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.3) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.3) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter2}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'accentPurple1Dark' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'accentPurple1Dark' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.1) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentPurple1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentPurple1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region accentBlue1

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentBlue1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.darker1})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1})`,
        color: `hsl(${theme?.ourTheme.colors.accentBlue1.darkest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentBlue1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1})`,
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentBlue1.base})`,
        color: `hsl(${theme?.ourTheme.colors.accentBlue1.darkest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'accentBlue1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'accentBlue1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentBlue1.base})  !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentBlue1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentBlue1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentBlue1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentBlue1.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentBlue1.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentBlue1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentBlue1.base}, 0.2) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentBlue1.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentBlue1.base}, 0.3) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'accentBlue1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentBlue1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentBlue1.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'accentBlue1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentBlue1.base}, 0.1) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentBlue1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentBlue1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region accentRed1

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentRed1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentRed1.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.accentRed1.darker1})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1})`,
        color: `hsl(${theme?.ourTheme.colors.accentRed1.darkest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentRed1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1})`,
      color: `hsl(${theme?.ourTheme.colors.accentRed1.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentRed1.base})`,
        color: `hsl(${theme?.ourTheme.colors.accentRed1.darkest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'accentRed1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentRed1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentRed1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'accentRed1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentRed1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentRed1.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentRed1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentRed1.base})  !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentRed1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentRed1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentRed1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentRed1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentRed1.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentRed1.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentRed1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentRed1.base}, 0.2) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentRed1.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentRed1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentRed1.base}, 0.3) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'accentRed1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentRed1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentRed1.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'accentRed1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentRed1.base}, 0.1) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentRed1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentRed1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region accentOrange1

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentOrange1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.darker1})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1})`,
        color: `hsl(${theme?.ourTheme.colors.accentOrange1.darkest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentOrange1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1})`,
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentOrange1.base})`,
        color: `hsl(${theme?.ourTheme.colors.accentOrange1.darkest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'accentOrange1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'accentOrange1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentOrange1.base})  !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentOrange1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentOrange1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentOrange1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentOrange1.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentOrange1.darker1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentOrange1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentOrange1.base}, 0.2) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentOrange1.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentOrange1.base}, 0.3) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'accentOrange1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentOrange1.base}, 0.05) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentOrange1.base}, 0.05) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'accentOrange1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentOrange1.base}, 0.1) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentOrange1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentOrange1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion

    // #region accentYellow1

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentYellow1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter2})`,
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.darker1})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1})`,
        color: `hsl(${theme?.ourTheme.colors.accentYellow1.darkest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentYellow1' && variant === 'contained' && {
      background: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1})`,
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.darkest})`,
      '&:hover': {
        background: `hsl(${theme?.ourTheme.colors.accentYellow1.base})`,
        color: `hsl(${theme?.ourTheme.colors.accentYellow1.darkest})`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && variant === 'contained' && color === 'accentYellow1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentYellow1.base}) !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && variant === 'contained' && color === 'accentYellow1' && {
      backgroundColor: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter2}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.base})  !important`,
      '&:hover': {
        backgroundColor: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter2}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentYellow1.base})  !important`,
        // note: for some reason to override mui defaults, we do have to put this here even though we have it above
        // supposedly applying to *all* variants
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === false && color === 'accentYellow1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentYellow1.base}, 0.1) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.darker1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentYellow1.base}, 0.2) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentYellow1.darker1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentYellow1.darkest}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === false && color === 'accentYellow1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentYellow1.base}, 0.2) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentYellow1.base}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentYellow1.base}, 0.3) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'light' && disabled === true && color === 'accentYellow1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentYellow1.base}, 0.1) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.base}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentYellow1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentYellow1.base}) !important`,
        boxShadow: 'none',
      },
    }),

    ...(theme?.themeMode === 'dark' && disabled === true && color === 'accentYellow1' && variant === 'outlined' && {
      backgroundColor: `hsla(${theme?.ourTheme.colors.accentYellow1.base}, 0.1) !important`,
      borderColor: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1}) !important`,
      color: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1}) !important`,
      '&:hover': {
        backgroundColor: `hsla(${theme?.ourTheme.colors.accentYellow1.base}, 0.1) !important`,
        borderColor: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1}) !important`,
        color: `hsl(${theme?.ourTheme.colors.accentYellow1.lighter1}) !important`,
        boxShadow: 'none',
      },
    }),

    // #endregion
  });
});
