import { graphql } from 'babel-plugin-relay/macro';

export const TrialLessonInfoQuery = graphql`
  query TrialLessonInfoQuery($currentTs: bigint!) {
    # details of an upcoming trial, if one exists. gets the trial that's coming up soonest
     appointments_connection(where: {package_type_id: {_eq: 4}, ends_at: {_gt: $currentTs}, real_status: {_in: [1, 8]}}, first: 1, order_by: {starts_at: asc}) {
      edges {
        node {
          ...TrialLessonInfoFragment
        }
      }
    }
  }
`;

// details of an upcoming trial
export const TrialLessonInfoFragment = graphql`
  fragment TrialLessonInfoFragment on appointments {
    ends_at
    starts_at
    id_random
  }
`;
