/**
   * sleep for X miliseconds before executing next statement
   * Note: this function takes in miliseconds as input and 
   * that is what should be provided when calling this function
   * @param ms
   * @returns
*/
export const sleep = async (ms: number) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});
