import { graphql } from 'babel-plugin-relay/macro';

export const PretrialDataLanglearnQuery = graphql`
  query PretrialDataLanglearnQuery {
    users_connection {
      edges {
        node {
          ...PretrialDataLanglearnFragment
        }
      }
    }
  }
`;

export const PretrialDataLanglearnFragment = graphql`
  fragment PretrialDataLanglearnFragment on users {
    lang_learning
  }
`;

export const PretrialDataStusettingsQuery = graphql`
  query PretrialDataStusettingsQuery {
    users_connection {
      edges {
        node {
          ...PretrialDataStusettingsFragment
        }
      }
    }
  }
`;

export const PretrialDataStusettingsFragment = graphql`
  fragment PretrialDataStusettingsFragment on users {
    pref_teacher_gender
    user_timezone
    timezone_set
    password_set
    dob
    id
    relayAge
    user_settings {
      id
      interest_group
      interest_private
      homework
      teacher_style
    }
  }
`;
