import styled from '@emotion/styled';
import { Alert } from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';

export const CustomAlert = styled(Alert)(({ theme }: WCLThemeProps) => (
  {
    // TODO: -> john will add styling according to system design
    color: theme?.ourTheme.colors.primary.base,
  }
));
