/**
 * @generated SignedSource<<28d819691a8147ba410b42ce7684e852>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClassroomBasicDataTermsprivacy2Fragment$data = {
  readonly pk: number;
  readonly " $fragmentType": "ClassroomBasicDataTermsprivacy2Fragment";
};
export type ClassroomBasicDataTermsprivacy2Fragment$key = {
  readonly " $data"?: ClassroomBasicDataTermsprivacy2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClassroomBasicDataTermsprivacy2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClassroomBasicDataTermsprivacy2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pk",
      "storageKey": null
    }
  ],
  "type": "terms_privacy_grouplsn",
  "abstractKey": null
};

(node as any).hash = "c4125d8ea9e7a407551e04fdcaa55559";

export default node;
