import React, { ReactNode } from 'react';
import { Page } from './Page';

// this container funciton will take 4 arguments.
// title: title of the page.
// testId: datatestId of the page.
// children: these are ReactNode or component content that need to render on page.
// gllimmer: fallback ui, it's default value is 'Loading' but it can also have the
// customise ReactNode.
// noDefaultScroll: React application have some weird scrolling behaviour, which is explained below 
// Due to single page (SPA)
// To change the content, we chnage the routes and page does not refresh, in this case browser
// things I am on the same page and here the issue arises. Let's say, we have 1 route, where 
// content is larger, so, we need to scroll on this page to see last available content of this
// route. Till now, everything is ok, but one we change route and go to 2nd route, this route should
// not show any already scrolled and it should show content from top of the page but this is not 
// happening, we can see the scroll in this 2nd page too. which is really wiered. To prevent this
// behaviour, we are using noDefaultScroll flag, and if it is true, we always show page top level 
// content instead of some intermediatery content of the page.
// In the pageWrapper component, we are assigning its default value to true so, all pages
// show there content from top and if we don't need this behaviour on any page, we can set its
// value to false from routes, you may see the discover teacher page as an example.
export const PreLoadedContainer = (
  title: string,
  testId: string,
  children: ReactNode,
  backbutton: string | null,
  noDefaultScroll?: boolean
) => (
  // All routes/pages should be wrapped inside containers, so the content inside these
  // routes/pages have some padding/margins and these looks better, no matter, either,
  //  we are displaying error boundaries or glimmers or actual page content.
  <Page title={title} dataTestId={testId} noDefaultScroll={noDefaultScroll} backButton={backbutton}>
    {children}
  </Page>
);
